import { MinusCircleFilled, MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import { additionalColors } from "constants/colors";

import { useStyles } from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    hide: (hidden: boolean) => `insight ${hidden ? "hidden" : "not hidden"}`,
};

const Hide = ({
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const styles = useStyles();

    const IconComponent = currentFeedback?.hide
        ? MinusCircleFilled
        : MinusCircleOutlined;

    return (
        <div className={styles.checkboxContainer}>
            <span style={{ marginRight: "5px" }}>Hide</span>
            <Tooltip
                title="Hide"
                {...(!allowFeedback && {
                    open: false,
                })}
            >
                <IconComponent
                    className={[
                        allowFeedback
                            ? styles.feedbackIconEnabledStyle
                            : styles.feedbackIconDisabledStyle,
                    ].join(" ")}
                    style={{ fontSize: "24px", color: additionalColors.grey600 }}
                    onClick={(event) => handleFeedbackClicked(event, "hide")}
                    data-testid={testIds.hide(currentFeedback?.hide ? true : false)}
                />
            </Tooltip>
        </div>
    );
};

export default Hide;
