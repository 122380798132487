import { Empty, Skeleton } from "antd";
import React, { Fragment, useEffect, useState } from "react";

import { DashboardLink, ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import {
    useGetSingleTeam,
    useGetWhoami,
} from "reactQuery/hooks/pages/useSidebarLayout";

export type DashboardViewProps = {
    teamId: number;
    productTypes?: ProductTypeEnum[];
    productIds?: number[];
    style?: React.CSSProperties;
};

const DashboardView = ({
    teamId,
    productTypes,
    productIds,
    style,
}: DashboardViewProps) => {
    const getWhoamiQuery = useGetWhoami();

    const [showSkeleton, setShowSkeleton] = useState(true);

    const singleTeamQuery = useGetSingleTeam(teamId);
    const team = singleTeamQuery?.data;

    const dashboardLinksByProductTypes = team?.dashboard_links?.filter(
        (dashboard_link) => {
            return productTypes
                ? productTypes
                      .map(
                          (productType) =>
                              new RegExp(productType, "i").test(
                                  dashboard_link.product.product_type
                              ) && dashboard_link.url
                      )
                      .some(Boolean)
                : false;
        }
    );

    const dashboardLinksByProductIds = team?.dashboard_links?.filter(
        (dashboard_link) => {
            const dashboardProductId = dashboard_link.product.id;
            return productIds?.includes(dashboardProductId);
        }
    );

    const dashboardLinks = []
        .concat(dashboardLinksByProductTypes, dashboardLinksByProductIds)
        .filter(Boolean);

    useEffect(() => {
        if (dashboardLinks?.length === 0) setShowSkeleton(false);
    }, [dashboardLinks]);

    const dashboardView = (
        <>
            {dashboardLinks?.length > 0 ? (
                dashboardLinks.map((dashboardLink: DashboardLink, index: number) => {
                    let dashboardUrl = new URL(dashboardLink.url);
                    dashboardUrl.searchParams.set(
                        "user_id",
                        getWhoamiQuery.data.user.id.toString()
                    );

                    return (
                        <Fragment key={index}>
                            <iframe
                                onLoad={() => setShowSkeleton(false)}
                                title="data-studio"
                                src={dashboardUrl.toString()}
                                data-testid={dashboardUrl.toString()}
                                style={{
                                    border: "0",
                                    width: "100%",
                                    height: "250vh",
                                    padding: "0",
                                }}
                                onMouseOver={() => {
                                    (window as any).heap &&
                                        (window as any).heap.track(
                                            "Hover Over Dashboard"
                                        );
                                }}
                            ></iframe>
                        </Fragment>
                    );
                })
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Dashboard not found"}
                />
            )}
        </>
    );

    return (
        <div style={style}>
            <Skeleton
                style={{ padding: "20px" }}
                active
                loading={
                    showSkeleton ||
                    singleTeamQuery.isLoading ||
                    getWhoamiQuery.isLoading
                }
            />

            {singleTeamQuery.isSuccess && getWhoamiQuery.isSuccess
                ? dashboardView
                : undefined}
        </div>
    );
};

export default DashboardView;
