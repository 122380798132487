import { EyeOutlined, EyeTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

import { additionalColors, brandColors } from "constants/colors";

import { useStyles } from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    addToFutureList: (added: boolean) =>
        `add to future list ${added ? "added" : "not added"}`,
};

const AddToFutureList = ({
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const styles = useStyles();

    const IconComponent = currentFeedback?.add_to_future_list
        ? EyeTwoTone
        : EyeOutlined;

    return (
        <div className={styles.checkboxContainer}>
            <span style={{ marginRight: "5px" }}>Add to Future List</span>
            <Tooltip
                title="Add to Future List"
                {...(!allowFeedback && {
                    open: false,
                })}
            >
                <IconComponent
                    className={[
                        allowFeedback
                            ? styles.feedbackIconEnabledStyle
                            : styles.feedbackIconDisabledStyle,
                    ].join(" ")}
                    style={{ fontSize: "24px", color: additionalColors.grey600 }}
                    onClick={(event) =>
                        handleFeedbackClicked(event, "add_to_future_list")
                    }
                    data-testid={testIds.addToFutureList(
                        currentFeedback?.add_to_future_list ? true : false
                    )}
                    twoToneColor={
                        currentFeedback?.add_to_future_list
                            ? brandColors.lightBlue
                            : undefined
                    }
                />
            </Tooltip>
        </div>
    );
};

export default AddToFutureList;
