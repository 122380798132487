import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

import { CombinedIntelligenceScope } from "..";
import { getCombinedIntelligenceScopes } from "../getCombinedIntelligenceScopes";
import { pdfStyle } from "../pdfStyle";
import RenderGeographyConstraints from "../RenderGeographyConstraints";
import RenderIndicatorConstraints from "../RenderIndicatorConstraints";
import RenderIntelligenceScopeDetails from "../RenderIntelligenceScopeDetails";
import { RankSection } from "../RenderIntelligenceScopes";
import RenderOtherConstraints from "../RenderOtherConstraints";

const IntelligenceScopesSection = (
    allIntelligenceScopes: IntelligenceScope[],
    rank: RankSection
) => {
    const intelligenceScopes: CombinedIntelligenceScope[] =
        getCombinedIntelligenceScopes(allIntelligenceScopes);

    return (
        <PDFView style={pdfStyle.sectionContainer}>
            <PDFText style={pdfStyle.sectionTitle}>Intelligence Scope - {rank}</PDFText>
            {intelligenceScopes.map((intelligenceScope, index) => (
                <PDFView
                    style={[
                        pdfStyle.innerSectionContainer,
                        index === 0 ? { borderTop: "none", marginTop: 0 } : {},
                    ]}
                    key={intelligenceScope.id}
                >
                    {intelligenceScope.combinedIntelligenceScopes
                        ? intelligenceScope.combinedIntelligenceScopes.map(
                              (combinedIntelligenceScope) =>
                                  RenderIntelligenceScopeDetails(
                                      combinedIntelligenceScope
                                  )
                          )
                        : RenderIntelligenceScopeDetails(intelligenceScope)}
                    {intelligenceScope.country_constraints.length ||
                    intelligenceScope.county_constraints.length ||
                    intelligenceScope.population_constraints.length ||
                    intelligenceScope.state_constraints.length ||
                    intelligenceScope.owner_constraints.length ||
                    intelligenceScope.owner_type_constraints.length
                        ? RenderGeographyConstraints(intelligenceScope)
                        : null}
                    {intelligenceScope.initiativetype_constraints.length ||
                    intelligenceScope.timing_status_constraints.length
                        ? RenderIndicatorConstraints(intelligenceScope)
                        : null}
                    {intelligenceScope.mgd_constraints.length ||
                    intelligenceScope.project_budget_constraints.length ||
                    intelligenceScope.source_timeframe_constraints.length
                        ? RenderOtherConstraints(intelligenceScope)
                        : null}
                </PDFView>
            ))}
        </PDFView>
    );
};

export default IntelligenceScopesSection;
