import PDFLink from "components/atoms/PDFLink";
import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { formatPhoneNumber } from "constants/formatters";

import { pdfStyle } from "../pdfStyle";

const RenderContact = (contact) => (
    <PDFView>
        <PDFView style={pdfStyle.contactTitle}>
            <PDFText style={{ fontWeight: "bold" }}>{`${contact.first_name} ${
                contact.last_name
            }${contact.linkedin ? " - " : ""}`}</PDFText>
            {contact.linkedin ? (
                <PDFLink style={{ fontWeight: "normal" }} src={contact.linkedin}>
                    LinkedIn
                </PDFLink>
            ) : null}
        </PDFView>
        <PDFText>
            {contact.company?.name
                ? contact.company.name
                : contact.owner?.legal_name
                ? contact.owner.legal_name
                : "Unknown"}
        </PDFText>
        {contact.position ? <PDFText>{contact.position}</PDFText> : null}
        {contact.phone_primary ? (
            <PDFText>{formatPhoneNumber(contact.phone_primary)}</PDFText>
        ) : null}
        {contact.email ? (
            <PDFLink src={`mailto:${contact.email}`}>{contact.email}</PDFLink>
        ) : null}
        {contact.phone_secondary ? (
            <PDFText>{formatPhoneNumber(contact.phone_secondary)}</PDFText>
        ) : null}
        {contact.email_alternate ? (
            <PDFLink src={`mailto:${contact.email_alternate}`}>
                {contact.email_alternate}
            </PDFLink>
        ) : null}
    </PDFView>
);

export default RenderContact;
