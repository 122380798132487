import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Form, withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import AutocompleteDropdownLabelAndValue from "components/molecules/AutocompleteDropdownLabelAndValue";
import FormSearchField from "components/molecules/FormSearchField";
import { FormModal } from "components/molecules/Modal";
import { InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import {
    activeInsightSelector,
    selectDemographicsForActiveOpportunity,
} from "stores/demographics/demographicSlice";
import { isUpdatingInsightSelector, patchInsight } from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { searchForLocation } from "stores/universeStore/actionTypes";
import {
    locationSearchIsSearchingSelector,
    locationSearchResultsSelector,
} from "stores/universeStore/selectors";

export const ERROR_MESSAGES = {
    locationMissing: "A location is required",
};

const AddLocationSchema = Yup.object().shape({
    selectedLocationId: Yup.number()
        .nullable()
        .required(ERROR_MESSAGES.locationMissing),
});

const formStyle = makeStyles((theme) => ({
    separatingLine: {
        border: "solid 1px #d6d7d9",
        height: "1px",
        width: "100%",
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

export const getLocationOptionLabel = (option) =>
    (option.name ? option.name : "") +
    (option.state_code ? ", " + option.state_code : "");

export const UnconnectedAddLocationModal = ({
    linkedLocations,
    locationSearchIsSearching,
    locationSearchResults,
    insight,
    isLoading,
    isVisible,
    reports,
    searchForLocation,
    toggleOverlay,
    ...formikProps
}) => {
    const styles = formStyle();
    return (
        <FormModal
            handleClose={() =>
                toggleOverlay({ overlay: overlayNames.addLocationModal })
            }
            handleSubmit={formikProps.handleSubmit}
            isSubmitDisabled={isLoading}
            isSubmitLoading={isLoading}
            isVisible={isVisible}
            submitLabel="Submit"
            title="Add Location"
        >
            <FormSearchField
                name="selectedLocationId"
                label="Location"
                value={null}
                initialOptions={linkedLocations}
                onChange={(e, v) => {
                    formikProps.setFieldValue("selectedLocationId", v ? v.id : "");
                }}
                onInputDelayComplete={(searchText) => {
                    searchForLocation(searchText);
                }}
                errors={formikProps.errors}
                allOptions={locationSearchResults || []}
                helperText="Try searching by county, town, city, or state code"
                renderOption={(option) => {
                    return (
                        <Grid
                            container
                            spacing={3}
                            data-testid={`location-${option.id}`}
                        >
                            <AutocompleteDropdownLabelAndValue
                                label="Location"
                                value={option.name}
                            />
                            <AutocompleteDropdownLabelAndValue
                                label="State"
                                value={option.state_code}
                            />
                            <div className={styles.separatingLine} />
                        </Grid>
                    );
                }}
            />
        </FormModal>
    );
};

export interface FormProps {
    selectedLocationId: any;
}
export interface EnhancedProps {
    insight: InsightInOpportunity;
    patchInsight: any;
}

export const EnhancedAddLocationModal = withFormik<EnhancedProps, FormProps>({
    enableReinitialize: true,
    handleSubmit: (form, { props: { patchInsight, insight } }) => {
        const demographics = insight.demographics.map((demographic) => demographic.id);
        patchInsight({
            demographics: [...demographics, form.selectedLocationId],
            insightId: insight.id,
        });
    },
    mapPropsToValues: (props) => ({
        selectedLocationId: null,
    }),
    validationSchema: () => AddLocationSchema,
    validateOnBlur: false,
    validateOnChange: false,
})(UnconnectedAddLocationModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        linkedLocations: selectDemographicsForActiveOpportunity(state),
        locationSearchIsSearching: locationSearchIsSearchingSelector(state as never),
        locationSearchResults: locationSearchResultsSelector(state as never),
        isVisible: isShowing.addLocationModal,
        isLoading: isUpdatingInsightSelector(state as never),
        insight: activeInsightSelector(state as never),
    };
};

const ConnectedAddLocationModal = connect(mapStateToProps, {
    searchForLocation,
    toggleOverlay: toggleOverlay,
    patchInsight,
})(EnhancedAddLocationModal);

export default ConnectedAddLocationModal;
