import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusAsyncExhaustPaginatedGetRequest } from "network";
import { initiativeTypeKeys } from "reactQuery/keys";

import { InitiativeType } from "./apiTypes";

export const useGetAllInitiativeTypes = (): UseQueryResult<InitiativeType[]> => {
    const queryKey = initiativeTypeKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<InitiativeType[]> =>
            await nautilusAsyncExhaustPaginatedGetRequest(
                "/api/target_reports/initiative_types/"
            ),
    });
};
