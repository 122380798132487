import { useMutation, useQueryClient } from "@tanstack/react-query";

import { nautilusPostRequest } from "network";
import { sharedOpportunitiesKeys } from "reactQuery/keys";

interface sharedOpportunitiesType {
    id: number;
}

const getOpportunitiesKey = (opportunityIds: number[]): string => {
    return JSON.stringify([...new Set(opportunityIds)]);
};

export function useCreateSharedOpportunities({
    reportId,
    teamId,
}: {
    reportId?: number;
    teamId: number;
}) {
    const queryClient = useQueryClient();
    return useMutation(
        async (opportunityIds: number[]) => {
            const { data } = await nautilusPostRequest(
                `/api/target_reports/shared_opportunities/`,
                {
                    ...(!!reportId ? { report: reportId } : {}),
                    team: teamId,
                    opportunities: opportunityIds,
                }
            );
            return data;
        },
        {
            onMutate: (opportunityIds: number[]) => {
                const existingSharedOpportunities: sharedOpportunitiesType[] =
                    queryClient.getQueryData(
                        !!reportId
                            ? sharedOpportunitiesKeys.report({
                                  reportId,
                                  opportunityIdsKey:
                                      getOpportunitiesKey(opportunityIds),
                              })
                            : !!teamId
                            ? sharedOpportunitiesKeys.team({
                                  teamId,
                                  opportunityIdsKey:
                                      getOpportunitiesKey(opportunityIds),
                              })
                            : null
                    );
                if (existingSharedOpportunities) {
                    return existingSharedOpportunities;
                }
            },
            onSuccess: (data) => {
                queryClient.setQueryData(
                    !!reportId
                        ? sharedOpportunitiesKeys.report({
                              reportId,
                              opportunityIdsKey: getOpportunitiesKey(
                                  data.opportunities
                              ),
                          })
                        : !!teamId
                        ? sharedOpportunitiesKeys.team({
                              teamId,
                              opportunityIdsKey: getOpportunitiesKey(
                                  data.opportunities
                              ),
                          })
                        : null,
                    data
                );
            },
        }
    );
}
