import { useLocation } from "react-router-dom";

import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import {
    FEEDBACK_REACTIONS,
    FEEDBACK_REACTIONS_PAST_TENSE,
} from "constants/namingSchemeTranslations";
import { AllFeedbackSingleFeedbackPathname } from "routes/IntelligenceFeeds";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";
import { allFeedbackBreadcrumbs } from "../AllFeedback";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    let feedback = new URLSearchParams(search).get("feedback");

    const teamId = teamIdString ? Number(teamIdString) : undefined;

    const feedbackName = FEEDBACK_REACTIONS[feedback];
    const feedbackNamePastTense = FEEDBACK_REACTIONS_PAST_TENSE[feedback];

    const queryParams = queryParamBuilder({
        excludeParams: [
            "feedback",
            "indicator_id",
            "opportunity_id",
            "owner_id",
            "user_id",
        ],
        includeBaseParams: true,
        includeExistingParams: false,
        startChar: "&",
    });

    return { feedback, feedbackName, feedbackNamePastTense, queryParams, teamId };
};

export const singleFeedbackBreadcrumbs = () => {
    const { feedback, feedbackName, queryParams } = pageParams();

    const breadcrumbs = allFeedbackBreadcrumbs().concat({
        name: feedbackName,
        url: `${AllFeedbackSingleFeedbackPathname}?feedback=${feedback}${queryParams}`,
    });

    return breadcrumbs;
};

const SingleFeedback = () => {
    const { feedbackNamePastTense, teamId } = pageParams();

    const title = `All ${feedbackNamePastTense} Insights`;

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title={title}
                    breadcrumbs={singleFeedbackBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.SINGLE_FEEDBACK}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default SingleFeedback;
