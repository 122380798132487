import { createSelector } from "@reduxjs/toolkit";

import { newOpportunitiesAdapter } from "stores/opportunities/adapter";
import { opportunityReducerName } from "stores/opportunities/constants";

export const opportunityStoreSelector = (state) => state[opportunityReducerName];

export const opportunitiesSelectors = newOpportunitiesAdapter.getSelectors(
    opportunityStoreSelector
);

export const activeOpportunityIdSelector = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.activeOpportunity
);

export const activeOpportunitySelector = createSelector(
    [opportunitiesSelectors.selectEntities, activeOpportunityIdSelector],
    (entities, activeOpportunityId) => {
        return entities[activeOpportunityId];
    }
);

export const assigneesFromActiveOpportunitySelector = createSelector(
    [activeOpportunitySelector],
    (activeOpportunity) => {
        return activeOpportunity && activeOpportunity.assignees
            ? activeOpportunity.assignees
            : [];
    }
);

export const activeOpportunityOwnerSelector = createSelector(
    [activeOpportunitySelector],
    (activeOpportunity) => {
        return activeOpportunity && activeOpportunity.owner
            ? activeOpportunity.owner
            : null;
    }
);

export const selectFetchErrorMap = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.errorFetchingMap
);

export const selectIsFetchingMap = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.isFetchingMap
);

export const isChangingOpportunitySalesStageSelector = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.salesStage.isChanging
);

export const opportunitySalesStageSelectorList = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.salesStageHistory.salesStagesForOpportunity
);

export const isChangingOpportunityActionsTakenSelector = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.actionsTaken.isChanging
);

export const opportunityActionTakenSelectorList = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) =>
        opportunityStore.actionsTakenHistory.actionsTakenForOpportunity
);

export const editSalesStageSelector = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.editSalesStage
);

export const salesStageBeingEditedSelector = createSelector(
    [editSalesStageSelector, opportunitiesSelectors.selectEntities],
    (editSalesStage, salesStageMap) => salesStageMap[editSalesStage.saleStageId]
);

export const editActionTakenSelector = createSelector(
    [opportunityStoreSelector],
    (opportunityStore) => opportunityStore.editActionsTaken.actionTaken
);
