import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { userNameKeys, staffUserKeys, userProfileKeys } from "reactQuery/keys";

import { Profile, User, UserName } from "./apiTypes";

export const useGetUserNameById = (
    id: number,
    enabled: boolean
): UseQueryResult<UserName> => {
    const queryKey = userNameKeys.single(id);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<UserName> =>
            (await nautilusGetRequest(`/api/user_data/get_username/${id}`)).data,
        enabled,
    });
};

export const useGetStaffUsers = (enabled: boolean = true): UseQueryResult<any> => {
    const queryKey = staffUserKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<User> =>
            (await nautilusGetRequest("/api/user_data/staff_users/")).data,
        enabled,
    });
};

export const useGetUsersByQuery = (
    teamId: number,
    query: string,
    enabled: boolean
): UseQueryResult<Profile[]> => {
    const queryKey = userProfileKeys.search(teamId, query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Profile[]> =>
            (
                await nautilusGetRequest(
                    "/api/user_data/user_profiles/",
                    `?limit=100&query=${query}&team=${teamId}`
                )
            ).data?.results,
        enabled,
    });
};
