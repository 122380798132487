import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";

import { FormModal } from "components/molecules/Modal";
import { additionalColors } from "constants/colors";
import { removeLocationSelector } from "stores/demographics/demographicSlice";
import {
    insightToRemoveDemographicFromSelector,
    isUpdatingInsightSelector,
    patchInsight,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

const removeLocationStyles = makeStyles(() => ({
    label: {
        color: additionalColors.body54,
    },
    locationInfo: {
        paddingTop: "30px",
    },
}));

export const UnconnectedRemoveLocationModal = ({
    isShowingModal,
    isSubmitLoading,
    relevantInsight,
    locationToRemove,
    patchInsight,
    toggleOverlay,
}) => {
    const styles = removeLocationStyles();

    const demographicToRemove =
        relevantInsight && locationToRemove
            ? relevantInsight.demographics.find(
                  (demographic) => demographic.id === locationToRemove.demographicId
              )
            : null;

    const removeDemographicAndDispatchPatch = () => {
        const demographicIds = relevantInsight.demographics
            .reduce((ids, demographic) => {
                ids.push(demographic.id);
                return ids;
            }, [])
            .filter((id) => id !== demographicToRemove.id);
        patchInsight({
            demographics: demographicIds,
            insightId: relevantInsight.id,
        });
    };

    const isMissingDemographicOrInsight = !demographicToRemove || !relevantInsight;

    const body = isMissingDemographicOrInsight ? (
        <LinearProgress />
    ) : (
        <>
            <Typography>
                Are you sure you would like to remove this location from this insight?
                Once removed, this action cannot be reversed.
            </Typography>
            <Grid container className={styles.locationInfo}>
                <Grid className={styles.label} item xs={3}>
                    Location:
                </Grid>
                <Grid item xs={9}>
                    {demographicToRemove.name}
                    {demographicToRemove.state_code
                        ? ", " + demographicToRemove.state_code
                        : null}
                </Grid>
            </Grid>
        </>
    );
    return (
        <FormModal
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeLocationModal });
            }}
            handleSubmit={removeDemographicAndDispatchPatch}
            hasSecondaryButton={true}
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.removeLocationModal });
            }}
            isSubmitDisabled={isMissingDemographicOrInsight}
            isSubmitLoading={isSubmitLoading}
            isVisible={isShowingModal}
            title="Remove Location"
            isEditModeActive={true}
            isRemoveModeActive={true}
        >
            {body}
        </FormModal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);

    return {
        isSubmitLoading: isUpdatingInsightSelector(state),
        isShowingModal: isShowing.removeLocationModal,
        relevantInsight: insightToRemoveDemographicFromSelector(state),
        locationToRemove: removeLocationSelector(state),
    };
};

const ConnectedRemoveLocationModal = connect(mapStateToProps, {
    patchInsight,
    toggleOverlay: toggleOverlay,
})(UnconnectedRemoveLocationModal);

export default ConnectedRemoveLocationModal;
