import { createReducer } from "@reduxjs/toolkit";

import {
    addActionsTaken,
    addSalesStage,
    deleteActionsTaken,
    deleteSalesStage,
    fetchOpportunitiesForReport,
    fetchOpportunityActionsTakenByFilter,
    fetchOpportunityById,
    fetchOpportunitySalesStagesByFilter,
    patchActionsTaken,
    patchSalesStage,
    setActiveOpportunity,
    setSalesStageForEdit,
} from "stores/opportunities/actions";
import { newOpportunitiesAdapter } from "stores/opportunities/adapter";

export const initialState = newOpportunitiesAdapter.getInitialState({
    errorFetchingMap: {},
    isFetchingMap: {},
    activeOpportunity: null,
    salesStage: {
        error: null,
        isChanging: false,
        salesStage: null,
    },
    salesStageHistory: {
        error: null,
        isChanging: false,
        salesStagesForOpportunity: null,
    },
    actionsTaken: {
        error: null,
        isChanging: false,
        actionsTaken: null,
    },
    actionsTakenHistory: {
        error: null,
        isChanging: false,
        actionsTakenForOpportunity: null,
    },
    editSalesStage: {
        error: null,
        isChanging: false,
        saleStageId: null,
    },
    editActionsTaken: {
        error: null,
        isChanging: false,
        actionTaken: null,
    },
});

const opportunitiesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setActiveOpportunity, (state, { payload: { opportunityId } }) => {
            state.activeOpportunity = opportunityId;
        })
        .addCase(setSalesStageForEdit, (state, { payload }) => {
            newOpportunitiesAdapter.upsertOne(state, payload.sales_stage);
            state.editSalesStage.saleStageId = payload && payload.sales_stage.id;
        })
        .addCase(fetchOpportunityById.pending, (state, action) => {
            state.errorFetchingMap[action.meta.arg.opportunityId] = null;
            state.isFetchingMap[action.meta.arg.opportunityId] = true;
        })
        .addCase(fetchOpportunityById.fulfilled, (state, action) => {
            state.errorFetchingMap[action.meta.arg.opportunityId] = null;
            state.isFetchingMap[action.meta.arg.opportunityId] = false;
            newOpportunitiesAdapter.upsertOne(state, action.payload);
        })
        .addCase(fetchOpportunityById.rejected, (state, action) => {
            state.errorFetchingMap[action.meta.arg.opportunityId] = action.payload;
            state.isFetchingMap[action.meta.arg.opportunityId] = false;
        })
        .addCase(fetchOpportunitiesForReport.pending, (state) => {
            state.error = null;
            state.isLoading = true;
        })
        .addCase(fetchOpportunitiesForReport.fulfilled, (state, action) => {
            newOpportunitiesAdapter.upsertMany(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchOpportunitiesForReport.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        })
        .addCase(addSalesStage.pending, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = true;
        })
        .addCase(addSalesStage.fulfilled, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = false;
        })
        .addCase(addSalesStage.rejected, (state, { payload }) => {
            state.salesStage.error = payload;
            state.salesStage.isChanging = false;
        })

        .addCase(patchSalesStage.pending, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = true;
        })
        .addCase(patchSalesStage.fulfilled, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = false;
        })
        .addCase(patchSalesStage.rejected, (state, { payload }) => {
            state.salesStage.error = payload;
            state.salesStage.isChanging = false;
        })

        .addCase(deleteSalesStage.pending, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = true;
        })
        .addCase(deleteSalesStage.fulfilled, (state) => {
            state.salesStage.error = null;
            state.salesStage.isChanging = false;
        })
        .addCase(deleteSalesStage.rejected, (state, { payload }) => {
            state.salesStage.error = payload;
            state.salesStage.isChanging = false;
        })

        .addCase(fetchOpportunitySalesStagesByFilter.pending, (state) => {
            state.salesStageHistory.error = null;
            state.salesStageHistory.isChanging = true;
        })
        .addCase(
            fetchOpportunitySalesStagesByFilter.fulfilled,
            (state, { payload }) => {
                state.salesStageHistory.error = null;
                state.salesStageHistory.isChanging = false;
                state.salesStageHistory.salesStagesForOpportunity = payload;
            }
        )
        .addCase(fetchOpportunitySalesStagesByFilter.rejected, (state, { payload }) => {
            state.salesStageHistory.error = payload;
            state.salesStageHistory.isChanging = false;
        })

        .addCase(addActionsTaken.pending, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = true;
        })
        .addCase(addActionsTaken.fulfilled, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = false;
        })
        .addCase(addActionsTaken.rejected, (state, { payload }) => {
            state.actionsTaken.error = payload;
            state.actionsTaken.isChanging = false;
        })

        .addCase(fetchOpportunityActionsTakenByFilter.pending, (state) => {
            state.actionsTakenHistory.error = null;
            state.actionsTakenHistory.isChanging = true;
        })
        .addCase(
            fetchOpportunityActionsTakenByFilter.fulfilled,
            (state, { payload }) => {
                state.actionsTakenHistory.error = null;
                state.actionsTakenHistory.isChanging = false;
                state.actionsTakenHistory.actionsTakenForOpportunity = payload;
            }
        )
        .addCase(
            fetchOpportunityActionsTakenByFilter.rejected,
            (state, { payload }) => {
                state.actionsTakenHistory.error = payload;
                state.actionsTakenHistory.isChanging = false;
            }
        )

        .addCase(patchActionsTaken.pending, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = true;
        })
        .addCase(patchActionsTaken.fulfilled, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = false;
        })
        .addCase(patchActionsTaken.rejected, (state, { payload }) => {
            state.actionsTaken.error = payload;
            state.actionsTaken.isChanging = false;
        })

        .addCase(deleteActionsTaken.pending, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = true;
        })
        .addCase(deleteActionsTaken.fulfilled, (state) => {
            state.actionsTaken.error = null;
            state.actionsTaken.isChanging = false;
        })
        .addCase(deleteActionsTaken.rejected, (state, { payload }) => {
            state.actionsTaken.error = payload;
            state.actionsTaken.isChanging = false;
        });
});

export default opportunitiesReducer;
