import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import StyledButton from "components/atoms/StyledButton";
import { authFormTheme } from "constants/themes";
import { passwordReset } from "stores/userStore/actionTypes";
import {
    passwordResetErrorsSelector,
    passwordResetIsSubmittingSelector,
} from "stores/userStore/selectors";

export const ERROR_MESSAGES = {
    currentPasswordMissing: "Current password is required",
    newPasswordMissing: "New password is required",
    confirmPasswordMissing: "Password confirmation is required",
    passwordsDoNotMatch: "Passwords do not match",
};

const passwordRequirements = [
    "Must contain at least 9 characters",
    "Must include at least 1 letter",
    "Must not contain your name or work email address",
    "Must not be a commonly used password",
];

const passwordRequirementsListItems = passwordRequirements.map((item, index) => (
    <li key={index}>{item}</li>
));

let passwordResetFormSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required(ERROR_MESSAGES.passwordsDoNotMatch)
        .min(9, passwordRequirements),
    confirmPassword: Yup.string()
        .required(ERROR_MESSAGES.confirmPasswordMissing)
        .oneOf([Yup.ref("newPassword")], ERROR_MESSAGES.passwordsDoNotMatch),
});

const useStyles = makeStyles(authFormTheme);

export const PasswordResetForm = ({
    errors,
    handleChange,
    handleSubmit,
    isLoading,
    responseErrors,
    values: { newPassword, confirmPassword },
}) => {
    const classes = useStyles();

    return (
        <Form className={classes.root}>
            <div className={classes.fullWidth}>
                <TextField
                    className={classes.textField}
                    error={"newPassword" in errors || "newPassword" in responseErrors}
                    helperText={passwordRequirementsListItems}
                    FormHelperTextProps={{
                        classes: { root: classes.helperText },
                    }}
                    id="newPassword-input"
                    label="Enter new password"
                    name="newPassword"
                    onChange={handleChange}
                    type="password"
                    value={newPassword}
                    variant="outlined"
                />
            </div>
            <div className={classes.fullWidth}>
                <TextField
                    className={classes.textField}
                    error={
                        "confirmPassword" in errors ||
                        "confirmPassword" in responseErrors
                    }
                    helperText={
                        errors.confirmPassword || responseErrors.confirmPassword || null
                    }
                    id="confirmPassword-input"
                    label="Confirm new password"
                    name="confirmPassword"
                    onChange={handleChange}
                    type="password"
                    value={confirmPassword}
                    variant="outlined"
                />
            </div>
            <StyledButton
                color="primary"
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                data-testid="reset-password-form-btn"
            >
                <Typography>Reset Password</Typography>
                {isLoading ? (
                    <CircularProgress
                        className={classes.loadingSpinner}
                        id="loading-spinner"
                        size={24}
                    />
                ) : null}
            </StyledButton>
        </Form>
    );
};

export const UnconnectedEnhancedPasswordResetForm = ({
    passwordReset,
    responseErrors = {},
    isLoading,
    token,
    uid,
}) => {
    const submitForm = ({ confirmPassword, newPassword }) => {
        let ga_event_name = "Reset Password - Reset Password";
        passwordReset({
            confirmPassword,
            newPassword,
            token,
            uid,
            ga_event_name,
        });
    };
    return (
        <Formik
            initialValues={{
                newPassword: "",
                confirmPassword: "",
            }}
            onSubmit={submitForm}
            validationSchema={passwordResetFormSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formikProps) => (
                <PasswordResetForm
                    {...formikProps}
                    isLoading={isLoading}
                    responseErrors={responseErrors}
                />
            )}
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        responseErrors: passwordResetErrorsSelector(state as never),
        isLoading: passwordResetIsSubmittingSelector(state as never),
    };
};

const ConnectedEnhancedPasswordResetForm = connect(mapStateToProps, {
    passwordReset,
})(UnconnectedEnhancedPasswordResetForm);

export default ConnectedEnhancedPasswordResetForm;
