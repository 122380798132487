import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const rowStyles = makeStyles((theme) => {
    return {
        container: {
            alignItems: "center",
            justifyContent: "center",
            padding: theme.spacing(1.5, 0),
            borderBottom: `1px ${theme.palette.grey[300]} solid`,
        },
        button: {
            alignItems: "center",
            padding: theme.spacing(1.5, 0),
            borderBottom: `1px ${theme.palette.grey[300]} solid`,
            justifyContent: "flex-end",
        },
    };
});
export const StyledCardRow = ({ variant = "", children }) => {
    const styles = rowStyles();
    if (variant === "button") {
        return (
            <Grid container direction="row" className={styles.button} spacing={1}>
                {children}
            </Grid>
        );
    } else {
        return (
            <Grid container direction="row" className={styles.container} spacing={1}>
                {children}
            </Grid>
        );
    }
};

export default StyledCardRow;
