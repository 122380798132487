// TODO: change wasteWaterFacility variables to facility
import { actionTypes as universeActions } from "./actionTypes";

export const universeReducerName = "universe";

export const initialState = {
    companySearch: {
        error: null,
        isSearching: false,
        results: null,
    },
    contactSearch: {
        error: null,
        isSearching: false,
        results: null,
    },
    drinkingWaterSystemSearch: {
        error: null,
        isSearching: false,
        results: null,
    },
    locationSearch: {
        error: null,
        isSearching: false,
        results: null,
    },
    ownerSearch: {
        error: null,
        isSearching: false,
        results: null,
    },
    wasteWaterFacilitySearch: {
        error: null,
        isSearching: false,
        results: null,
    },
};

const universe = (state = initialState, action) => {
    switch (action.type) {
        case universeActions.SEARCH_FOR_COMPANY:
            return {
                ...state,
                companySearch: {
                    ...state.companySearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_COMPANY_FAILED:
            return {
                ...state,
                companySearch: {
                    ...state.companySearch,
                    error: true,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_COMPANY_RESULTS:
            return {
                ...state,
                companySearch: {
                    ...state.companySearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.companies,
                },
            };
        case universeActions.CLEAR_COMPANY_SEARCH_RESULTS:
            return {
                ...state,
                companySearch: {
                    ...state.companySearch,
                    results: [],
                },
            };
        case universeActions.SEARCH_FOR_CONTACT:
            return {
                ...state,
                contactSearch: {
                    ...state.contactSearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_CONTACT_FAILED:
            return {
                ...state,
                contactSearch: {
                    ...state.contactSearch,
                    error: true,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_CONTACT_RESULTS:
            return {
                ...state,
                contactSearch: {
                    ...state.contactSearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.contacts,
                },
            };
        case universeActions.CLEAR_CONTACT_SEARCH_RESULTS:
            return {
                ...state,
                contactSearch: {
                    ...state.contactSearch,
                    results: [],
                },
            };
        case universeActions.SEARCH_FOR_OWNER:
            return {
                ...state,
                ownerSearch: {
                    ...state.ownerSearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_OWNER_FAILED:
            return {
                ...state,
                ownerSearch: {
                    ...state.ownerSearch,
                    error: true,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_OWNER_RESULTS:
            return {
                ...state,
                ownerSearch: {
                    ...state.ownerSearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.owners,
                },
            };
        case universeActions.SEARCH_FOR_LOCATION:
            return {
                ...state,
                locationSearch: {
                    ...state.locationSearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_LOCATION_FAILED:
            return {
                ...state,
                locationSearch: {
                    ...state.locationSearch,
                    error: true,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_LOCATION_RESULTS:
            return {
                ...state,
                locationSearch: {
                    ...state.locationSearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.locations,
                },
            };
        case universeActions.SEARCH_FOR_DRINKING_WATER_SYSTEM:
            return {
                ...state,
                drinkingWaterSystemSearch: {
                    ...state.drinkingWaterSystemSearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_DRINKING_WATER_SYSTEM_FAILED:
            return {
                ...state,
                drinkingWaterSystemSearch: {
                    ...state.drinkingWaterSystemSearch,
                    error: null,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_DRINKING_WATER_SYSTEM_RESULTS:
            return {
                ...state,
                drinkingWaterSystemSearch: {
                    ...state.drinkingWaterSystemSearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.waterSystems,
                },
            };
        case universeActions.CLEAR_DRINKING_WATER_SYSTEM_RESULTS:
            return {
                ...state,
                drinkingWaterSystemSearch: {
                    ...state.drinkingWaterSystemSearch,
                    results: [],
                },
            };
        case universeActions.SEARCH_FOR_WASTE_WATER_FACILITY:
            return {
                ...state,
                wasteWaterFacilitySearch: {
                    ...state.wasteWaterFacilitySearch,
                    error: null,
                    isSearching: true,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_WASTE_WATER_FACILITY_FAILED:
            return {
                ...state,
                wasteWaterFacilitySearch: {
                    ...state.wasteWaterFacilitySearch,
                    error: null,
                    isSearching: false,
                    results: null,
                },
            };
        case universeActions.SEARCH_FOR_WASTE_WATER_FACILITY_RESULTS:
            return {
                ...state,
                wasteWaterFacilitySearch: {
                    ...state.wasteWaterFacilitySearch,
                    error: null,
                    isSearching: false,
                    results: action.payload.waterSystems,
                },
            };
        case universeActions.CLEAR_WASTE_WATER_FACILITY_SEARCH_RESULTS:
            return {
                ...state,
                wasteWaterFacilitySearch: {
                    ...state.wasteWaterFacilitySearch,
                    results: [],
                },
            };
        default:
            return state;
    }
};

export default universe;
