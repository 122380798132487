import { createReducer } from "@reduxjs/toolkit";

import {
    createInsight,
    fetchInsightsByFilter,
    patchInsight,
    patchNote,
    removeInsight,
    removeInsightsById,
    removeNote,
    setInsightForContactRemoval,
    setInsightForEdit,
    setInsightForModification,
    setOpportunityForInsightCreation,
} from "stores/insights/actions";
import { insightsAdapter } from "stores/insights/adapter";

export const initialState = insightsAdapter.getInitialState({
    editInsight: {
        error: null,
        isUpdating: false,
        insightId: null,
    },
    error: null,
    isLoading: false,
    insightForModification: null,
    insightsForReportView: {
        error: null,
        insights: null,
        isChanging: null,
    },
    note: {
        note: null,
        error: null,
        isChanging: false,
    },
    newInsight: {
        error: null,
        isCreating: false,
        opportunityId: null,
    },
    removeInsight: {
        error: null,
        isRemoving: false,
    },
    removeContact: {
        contactId: null,
        insightId: null,
    },
});

const insightsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeInsightsById, (state, { payload }) => {
            insightsAdapter.removeMany(state, payload);
        })
        .addCase(setInsightForContactRemoval, (state, { payload }) => {
            state.removeContact.insightId = payload.insightId;
            state.removeContact.contactId = payload.contactId;
        })
        .addCase(setInsightForEdit, (state, { payload }) => {
            state.editInsight.insightId = payload && payload.insightId;
        })
        .addCase(setInsightForModification, (state, { payload }) => {
            state.insightForModification = payload && payload.insight;
        })
        .addCase(setOpportunityForInsightCreation, (state, { payload }) => {
            state.newInsight.opportunityId = payload.opportunityId;
        })
        .addCase(patchInsight.pending, (state) => {
            state.editInsight.error = null;
            state.editInsight.isUpdating = true;
        })
        .addCase(patchInsight.fulfilled, (state, { payload }) => {
            insightsAdapter.upsertOne(state, payload);
            state.editInsight.error = null;
            state.editInsight.isUpdating = false;
        })
        .addCase(patchInsight.rejected, (state, { payload }) => {
            state.editInsight.error = payload;
            state.editInsight.isUpdating = false;
        })
        .addCase(fetchInsightsByFilter.pending, (state) => {
            state.error = null;
            state.isLoading = true;
        })
        .addCase(fetchInsightsByFilter.fulfilled, (state, action) => {
            insightsAdapter.upsertMany(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchInsightsByFilter.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        })
        .addCase(createInsight.pending, (state) => {
            state.newInsight.error = null;
            state.newInsight.isCreating = true;
        })
        .addCase(createInsight.fulfilled, (state, { payload }) => {
            state.newInsight.error = null;
            state.newInsight.isCreating = false;
            // Close the modal
            state.newInsight.opportunityId = null;
            insightsAdapter.upsertOne(state, payload);
        })
        .addCase(createInsight.rejected, (state, { payload }) => {
            state.newInsight.error = payload;
            state.newInsight.isCreating = false;
        })
        .addCase(removeInsight.pending, (state) => {
            state.removeInsight.error = null;
            state.removeInsight.isRemoving = true;
        })
        .addCase(
            removeInsight.fulfilled,
            (
                state,
                {
                    meta: {
                        arg: { insightId },
                    },
                }
            ) => {
                state.removeInsight.error = null;
                state.removeInsight.isRemoving = false;
                insightsAdapter.removeOne(state, insightId);
            }
        )
        .addCase(removeInsight.rejected, (state, { payload }) => {
            state.removeInsight.error = payload;
            state.removeInsight.isRemoving = false;
        })
        .addCase(patchNote.pending, (state) => {
            state.note.error = null;
            state.note.isChanging = true;
        })
        .addCase(patchNote.fulfilled, (state, { payload }) => {
            state.note.error = null;
            state.note.isChanging = false;
        })
        .addCase(patchNote.rejected, (state, { payload }) => {
            state.note.error = payload;
            state.note.isChanging = false;
        })
        .addCase(removeNote.pending, (state) => {
            state.note.error = null;
            state.note.isChanging = true;
        })
        .addCase(removeNote.fulfilled, (state, { payload }) => {
            state.note.error = null;
            state.note.isChanging = false;
        })
        .addCase(removeNote.rejected, (state, { payload }) => {
            state.note.error = payload;
            state.note.isChanging = false;
        });
});

export default insightsReducer;
