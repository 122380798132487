import { withRouter } from "react-router-dom";

import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import ReportDetailsPage from "pages/ReportDetails";

export const IntelligenceReportsSingleReportPathname = "/intrpt/report";

const IntelligenceReportsSingleReport = () => {
    const AuthenticatedReportDetailsPage = withRouter(
        withAuthenticationCheck(ReportDetailsPage)
    );
    return <AuthenticatedReportDetailsPage />;
};

export default IntelligenceReportsSingleReport;
