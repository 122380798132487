import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Collapse, Space, Row, Col, Spin } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import IndicatorSelect from "pages/SearchPlanForm/IndicatorSelect";
import IndustrySelect from "pages/SearchPlanForm/IndustrySelect";
import {
    BlankEnum,
    ConstraintTypeEnum,
    FilterTypeEnum,
    Profile,
    SearchPlanFormIntelligenceScopeListItem,
} from "reactQuery/hooks/apiTypes";
import {
    useGetIntelligenceScopeDetail,
    usePatchIntelligenceScope,
} from "reactQuery/hooks/useSearchPlanForm";
import { searchPlanFormKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";

import AdditionalNotesInput from "../AdditionalNotesInput";
import ConstraintRow from "../ConstraintRow";
import RankSelect from "../RankSelect";

const { Panel } = Collapse;

interface IntelligenceScopeFormProps {
    intelligenceScope: SearchPlanFormIntelligenceScopeListItem;
}

export enum ConstraintNameEnum {
    COUNTRIES = "countries",
    STATES = "states",
    COUNTIES = "counties",
    OWNERS = "owners",
    OWNER_TYPES = "owner_types",
    POPULATION = "population",
    INITIATIVE_TYPES = "initiative_types",
    TIMING_STATUSES = "timing_statuses",
    INDICATOR_TAGS = "indicator_tags",
    PROJECT_BUDGET = "project_budget",
    SOURCE_DOCUMENT_DATE = "source_document_date",
    DESIGN_FLOW_MGD = "design_flow_mgd",
    DISPLAY_START_DATE = "display_start_date",
}

const IntelligenceScopeForm = ({ intelligenceScope }: IntelligenceScopeFormProps) => {
    const [form] = Form.useForm();
    const { search_plan_id: searchPlanId } = useParams();

    const formId = `intelligence-scopes-form-${intelligenceScope.id}`;

    const intelligenceScopeDetailQuery = useGetIntelligenceScopeDetail(
        intelligenceScope.id
    );

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const [trackedChanges, setTrackedChanges] = useState<any>({});
    const valuesChanged = (changed: any) => {
        const deepMerge = _.merge({}, trackedChanges, changed);
        setTrackedChanges(deepMerge);
    };

    const patchIntelligenceScope = usePatchIntelligenceScope(intelligenceScope.id);
    const queryClient = useQueryClient();

    const performMutate = () => {
        if (intelligenceScope.id < 0) {
            trackedChanges.search_plan_id = searchPlanId;
        }

        patchIntelligenceScope.mutate(
            { ...trackedChanges },
            {
                onSuccess: (response) => {
                    queryClient.setQueryData(
                        searchPlanFormKeys.intelligenceScopeDetail(
                            intelligenceScope.id
                        ),
                        response
                    );
                    setTrackedChanges({});
                    displaySuccessSnack({
                        message: "Form submitted successfull",
                    });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Error submitting form",
                    });
                },
            }
        );
    };

    const submitForm = () => {
        form.validateFields()
            .then((_) => {
                performMutate();
            })
            .catch((errorInfo) => {
                displayErrorSnack({
                    message: errorInfo,
                });
            });
    };

    const intelligenceScopeDetail = intelligenceScopeDetailQuery.data;
    useEffect(() => {
        if (intelligenceScopeDetailQuery.isSuccess) {
            form.setFieldsValue({
                industry: intelligenceScopeDetail?.industry,
                indicator: intelligenceScopeDetail?.indicator,
                rank: intelligenceScopeDetail?.rank,
                additional_notes: intelligenceScopeDetail?.additional_notes,
            });

            const {
                country_constraints: countryConstraints,
                state_constraints: stateConstraints,
                county_constraints: countyConstraints,
                owner_constraints: ownerConstraints,
                owner_type_constraints: ownerTypeConstraints,
                population_constraints: populationConstraints,
                initiativetype_constraints: initiativeTypeConstraints,
                timing_status_constraints: timingStatusConstraints,
                indicatortag_constraints: indicatorTagConstraints,
                project_budget_constraints: projectBudgetConstraints,
                source_timeframe_constraints: sourceTimeframeConstraints,
                mgd_constraints: mgdConstraints,
                display_start_date_constraints: displayStartDateConstraints,
            } = intelligenceScopeDetail;

            const setConstraintValues = ({
                constraintName,
                id,
                number,
                constraintType,
                filterType,
                profiles,
            }: {
                constraintName: ConstraintNameEnum;
                id: number;
                number: number;
                constraintType: ConstraintTypeEnum | BlankEnum;
                filterType: FilterTypeEnum | BlankEnum;
                profiles: Profile[];
            }): void => {
                form.setFieldValue(
                    [
                        constraintName,
                        `constraint_id_${id}`,
                        `number_${number}`,
                        "constraint_type",
                    ],
                    constraintType ?? "Include"
                );
                form.setFieldValue(
                    [
                        constraintName,
                        `constraint_id_${id}`,
                        `number_${number}`,
                        "filter_type",
                    ],
                    filterType
                );
                form.setFieldValue(
                    [
                        constraintName,
                        `constraint_id_${id}`,
                        `number_${number}`,
                        "profiles",
                    ],
                    profiles.map((profile: Profile) => ({
                        value: profile.id,
                        label: `${profile.user.first_name} ${profile.user.last_name} (${profile.team.name})`,
                    }))
                );
            };

            countryConstraints.forEach(
                (
                    { id, countries, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.COUNTRIES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "countries",
                        ],
                        countries.map((country) => country.code)
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.COUNTRIES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            stateConstraints.forEach(
                (
                    { id, states, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.STATES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "states",
                        ],
                        states.map((state) => state.code)
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.STATES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            countyConstraints.forEach(
                (
                    { id, demographics, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.COUNTIES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "demographics",
                        ],
                        demographics.map((demographic) => ({
                            value: demographic.id,
                            label: `${demographic.name} (${demographic.state_code})`,
                        }))
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.COUNTIES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            ownerConstraints.forEach(
                (
                    { id, owners, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.OWNERS,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "owners",
                        ],
                        owners.map((owner) => ({
                            value: owner.id,
                            label: `${owner.legal_name} | ${owner.state_code} | ${owner.whid}`,
                        }))
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.OWNERS,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            ownerTypeConstraints.forEach(
                (
                    { id, owner_types, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.OWNER_TYPES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "owner_types",
                        ],
                        owner_types.map((ownerType) => ({
                            value: ownerType.id,
                            label: ownerType.owner_type,
                        }))
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.OWNER_TYPES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            populationConstraints.forEach(
                (
                    { id, minimum, maximum, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.POPULATION,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "minimum",
                        ],
                        minimum
                    );
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.POPULATION,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "maximum",
                        ],
                        maximum
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.POPULATION,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            initiativeTypeConstraints.forEach(
                (
                    { id, initiative_types, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.INITIATIVE_TYPES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "initiative_types",
                        ],
                        initiative_types.map((initiativeType) => initiativeType.id)
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.INITIATIVE_TYPES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            timingStatusConstraints.forEach(
                (
                    { id, statuses, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.TIMING_STATUSES,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "timing_statuses",
                        ],
                        statuses.map((timingStatus) => timingStatus.id)
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.TIMING_STATUSES,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            indicatorTagConstraints.forEach(
                (
                    { id, indicator_tags, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.INDICATOR_TAGS,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "indicator_tags",
                        ],
                        indicator_tags.map((indicatorTag) => ({
                            value: indicatorTag.id,
                            label: indicatorTag.value,
                        }))
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.INDICATOR_TAGS,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            projectBudgetConstraints.forEach(
                (
                    { id, minimum, maximum, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.PROJECT_BUDGET,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "minimum",
                        ],
                        minimum
                    );
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.PROJECT_BUDGET,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "maximum",
                        ],
                        maximum
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.PROJECT_BUDGET,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            sourceTimeframeConstraints.forEach(
                (
                    { id, maximum, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.SOURCE_DOCUMENT_DATE,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "maximum",
                        ],
                        maximum
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.SOURCE_DOCUMENT_DATE,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            mgdConstraints.forEach(
                (
                    { id, minimum, maximum, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.DESIGN_FLOW_MGD,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "minimum",
                        ],
                        minimum
                    );
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.DESIGN_FLOW_MGD,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "maximum",
                        ],
                        maximum
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.DESIGN_FLOW_MGD,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
            displayStartDateConstraints.forEach(
                (
                    { id, display_start_date, constraint_type, filter_type, profiles },
                    index: number
                ) => {
                    const number = index + 1;
                    form.setFieldValue(
                        [
                            ConstraintNameEnum.DISPLAY_START_DATE,
                            `constraint_id_${id}`,
                            `number_${number}`,
                            "display_start_date",
                        ],
                        display_start_date
                    );
                    setConstraintValues({
                        constraintName: ConstraintNameEnum.DISPLAY_START_DATE,
                        id,
                        number,
                        constraintType: constraint_type,
                        filterType: filter_type,
                        profiles,
                    });
                }
            );
        }
    }, [intelligenceScopeDetailQuery.isSuccess]);

    if (intelligenceScopeDetailQuery.isLoading) {
        return <Spin />;
    }

    return (
        <Form
            form={form}
            id={formId}
            name={formId}
            onFinish={submitForm}
            onValuesChange={valuesChanged}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col span={6}>
                    <IndustrySelect />
                </Col>

                <Col span={6}>
                    <IndicatorSelect />
                </Col>

                <Col span={6}>
                    <RankSelect />
                </Col>

                <Col span={6}>
                    <AdditionalNotesInput />
                </Col>
            </Row>

            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Collapse>
                    <Panel header="Filters" key="filters">
                        <Space
                            direction="vertical"
                            size="large"
                            style={{ width: "100%" }}
                        >
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.COUNTRIES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.STATES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.COUNTIES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.OWNERS}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.OWNER_TYPES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.POPULATION}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.INITIATIVE_TYPES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.TIMING_STATUSES}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.INDICATOR_TAGS}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.PROJECT_BUDGET}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.SOURCE_DOCUMENT_DATE}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.DESIGN_FLOW_MGD}
                            />
                            <ConstraintRow
                                intelligenceScopeId={intelligenceScope.id}
                                constraintName={ConstraintNameEnum.DISPLAY_START_DATE}
                            />
                        </Space>
                    </Panel>
                </Collapse>

                <Button key="link" type="primary" htmlType="submit" form={formId}>
                    Submit
                </Button>
            </Space>
        </Form>
    );
};

export default IntelligenceScopeForm;
