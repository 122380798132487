import { useQuery } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { skinnyAPIKeys } from "reactQuery/keys";

import {
    Product,
    SidebarLayoutProfile,
    SidebarLayoutSingleTeam,
    SidebarLayoutAllTeams,
} from "../apiTypes";

export const SYNC_TO_CRM_ENABLED_TIER_NAME = "SyncToCRMEnabled";
export const AI_EMAIL_ASSISTANT_TIER_NAME = "AIEmailAssistantEnabled";

export const useGetIsTierEnabledForTeam = ({
    teamId,
    tierName,
}: {
    teamId: number;
    tierName: string;
}): boolean => {
    const getTeamQuery = useGetSingleTeam(teamId);

    if (!getTeamQuery.isSuccess) return undefined;

    const teamTiers = getTeamQuery.data.tiers ?? [];
    return !!teamTiers.find((tier) => tier.name === tierName);
};

export const useGetAllTeams = (enabled: boolean = true) => {
    const queryKey = skinnyAPIKeys.sidebarLayoutTeams();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SidebarLayoutAllTeams[]> =>
            (await nautilusGetRequest(`/api/user_data/sidebar_layout/teams`)).data,
        enabled,
    });
};

export const useGetSingleTeam = (id: number, enabled: boolean = true) => {
    const queryKey = skinnyAPIKeys.sidebarLayoutSingleTeam(id);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SidebarLayoutSingleTeam> =>
            (await nautilusGetRequest(`/api/user_data/sidebar_layout/teams/${id}`))
                .data,
        enabled,
    });
};

export const useGetWhoami = (enabled: boolean = true) => {
    const queryKey = skinnyAPIKeys.sidebarLayoutWhoami();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SidebarLayoutProfile> =>
            (await nautilusGetRequest(`/api/user_data/sidebar_layout/whoami`)).data,
        enabled,
        retry: 1, // retry once only on failure
        //refetchOnWindowFocus: "always",
    });
};

export const useGetAllProducts = (enabled: boolean = true) => {
    const queryKey = skinnyAPIKeys.sidebarLayoutProducts();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Product[]> =>
            (await nautilusGetRequest(`/api/user_data/sidebar_layout/products`)).data,
        enabled,
    });
};
