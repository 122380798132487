import {
    LeftCircleFilled,
    LeftCircleOutlined,
    RightCircleFilled,
    RightCircleOutlined,
    WarningFilled,
    LoadingOutlined,
} from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";

import { StyledButton } from "components/atoms";
import FormDropdownObjectsField from "components/atoms/FormDropdownObjectsField";
import classes from "components/molecules/ViewDetailsModal/style.module.css";
import { additionalColors } from "constants/colors";
import { FEED_TYPES } from "constants/feedTypes";
import { openNewInternalLink } from "constants/navigation";
import { useUpdateIPLResults } from "reactQuery/hooks/useIPLResults";
import { useGetIPLResultAssigneeActionedStatusChoices } from "reactQuery/hooks/useTableAssociatedData";

export const IPLResultInteractionBar = ({
    defaultPageSize,
    feedType,
    itemIds,
    itemIdsLoading,
    result,
    selectedItemId,
    setSelectedItemId,
}: {
    defaultPageSize?: number;
    feedType: string;
    itemIds: number[];
    itemIdsLoading?: boolean;
    result: any;
    selectedItemId: number;
    setSelectedItemId: (number) => void;
}) => {
    const actionOnSuccessRef = useRef<string>(null);
    const [changeItemError, setChangeItemError] = useState<boolean>(false);
    const selectedItemIndex = itemIds.indexOf(selectedItemId);

    const assigneeActionedStatusChoicesQuery =
        useGetIPLResultAssigneeActionedStatusChoices();
    const update_ipl_result_status_query = useUpdateIPLResults({
        feedType,
        defaultPageSize: defaultPageSize,
    });

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
                changeId("PREVIOUS");
            } else if (event.key === "ArrowRight") {
                changeId("NEXT");
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (selectedItemIndex === -1) {
            setChangeItemError(true);
        } else {
            setChangeItemError(false);
        }
    }, [selectedItemIndex]);

    useEffect(() => {
        if (update_ipl_result_status_query.isSuccess) {
            if (actionOnSuccessRef.current === "NEXT") {
                changeId("NEXT");
                actionOnSuccessRef.current = null;
            } else if (actionOnSuccessRef.current === "ADD INSIGHT") {
                openNewInternalLink(
                    `/target_reports/add_insight/ipl/${selectedItemId}/`,
                    false
                );
                actionOnSuccessRef.current = null;
            }
        }
    }, [update_ipl_result_status_query.isSuccess]);

    function changeId(direction) {
        setSelectedItemId((prevSelectedItemId) => {
            const currentIndex = itemIds.indexOf(prevSelectedItemId);
            if (
                currentIndex === -1 ||
                (currentIndex === 0 && direction === "PREVIOUS") ||
                (currentIndex === itemIds.length - 1 && direction === "NEXT")
            ) {
                direction = null;
            }
            switch (direction) {
                case "PREVIOUS":
                    return itemIds[
                        (currentIndex - 1 + itemIds.length) % itemIds.length
                    ];
                case "NEXT":
                    return itemIds[(currentIndex + 1) % itemIds.length];
                default:
                    return prevSelectedItemId;
            }
        });
    }

    return (
        <>
            <br />
            <div className={classes["interactive-section"]}>
                <div className={classes["prev-next-icons"]}>
                    {itemIdsLoading ? (
                        <div className={classes["prev-next-error"]}>
                            <LoadingOutlined />
                            &nbsp;&nbsp;
                            <span>Loading Previous/Next</span>
                        </div>
                    ) : changeItemError ? (
                        <div className={classes["prev-next-error"]}>
                            <WarningFilled />
                            &nbsp;&nbsp;
                            <span>
                                Previous/Next will be available on page refresh.
                            </span>
                        </div>
                    ) : (
                        <>
                            {selectedItemIndex > 0 ? (
                                <LeftCircleFilled
                                    style={{ color: additionalColors.grey500 }}
                                    onClick={() => changeId("PREVIOUS")}
                                />
                            ) : (
                                <LeftCircleOutlined
                                    style={{ color: additionalColors.grey500 }}
                                />
                            )}
                            {selectedItemIndex < itemIds.length - 1 ? (
                                <RightCircleFilled
                                    onClick={() => changeId("NEXT")}
                                    style={{ color: additionalColors.grey500 }}
                                />
                            ) : (
                                <RightCircleOutlined
                                    style={{ color: additionalColors.grey500 }}
                                />
                            )}
                        </>
                    )}
                </div>
                <div className={classes["right-buttons"]}>
                    <div className={classes["not-a-match-container"]}>
                        <StyledButton
                            disabled={result.status === "Not a Match"}
                            variant={
                                result.status === "Not a Match"
                                    ? "not-a-match-disabled"
                                    : "not-a-match"
                            }
                            handleClick={() => {
                                actionOnSuccessRef.current = "NEXT";
                                update_ipl_result_status_query.mutate({
                                    field: "status",
                                    status: "Not a Match",
                                    id: selectedItemId,
                                });
                            }}
                        >
                            Not a Match
                        </StyledButton>
                    </div>
                    <div>
                        <StyledButton
                            disabled={result.status === "Confirmed Match"}
                            variant={
                                result.status === "Confirmed Match"
                                    ? "confirmed-match-disabled"
                                    : "confirmed-match"
                            }
                            handleClick={() => {
                                actionOnSuccessRef.current = "ADD INSIGHT";
                                update_ipl_result_status_query.mutate({
                                    field: "status",
                                    status: "Confirmed Match",
                                    id: selectedItemId,
                                });
                            }}
                        >
                            Confirmed Match
                        </StyledButton>
                    </div>
                    {feedType === FEED_TYPES.LW_READY_FOR_QC ? (
                        <div className={classes["review-status-dropdown-container"]}>
                            <FormDropdownObjectsField
                                selectedOption={{
                                    key: result.review_status,
                                    value: result.review_status,
                                }}
                                allOptions={
                                    assigneeActionedStatusChoicesQuery.data
                                        ? Object.entries(
                                              assigneeActionedStatusChoicesQuery.data
                                          ).map(([key, value]) => ({ key, value }))
                                        : []
                                }
                                setSelectedOption={(selectedOption) => {
                                    update_ipl_result_status_query.mutate({
                                        field: "review_status",
                                        review_status: selectedOption,
                                        id: selectedItemId,
                                    });
                                }}
                            ></FormDropdownObjectsField>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
