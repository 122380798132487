import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { nautilusGetRequest } from "network";
import { activeOpportunitySelector } from "stores/opportunities/selectors";
import { displayErrorSnack } from "stores/uiStore/actionTypes";

export const demographicsAdapter = createEntityAdapter({});

export const demographicReducerName = "demographics";

export const initialState = demographicsAdapter.getInitialState({
    error: null,
    isLoading: false,
    addLocation: {
        activeInsight: null,
    },
    removeLocation: {
        insightId: null,
        demographicId: null,
    },
});

export const fetchDemographicsForOwner = createAsyncThunk(
    `${demographicReducerName}/fetchDemographicsForOwner`,
    async ({ ownerId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest(
                "/api/universe/demographics/",
                `?owner=${ownerId}`
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch demographics for owner ${ownerId}`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

const demographicSlice = createSlice({
    name: demographicReducerName,
    initialState,
    reducers: {
        setInsightForLocationLinkage: (state, { payload }) => {
            state.addLocation.activeInsight = payload.insight;
        },
        setInsightForLocationRemoval: (state, { payload }) => {
            state.removeLocation.demographicId = payload.demographicId;
            state.removeLocation.insightId = payload.insightId;
        },
    },
    extraReducers: {
        [fetchDemographicsForOwner.pending]: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        [fetchDemographicsForOwner.fulfilled]: (state, action) => {
            demographicsAdapter.upsertMany(state, action.payload);
            state.isLoading = false;
        },
        [fetchDemographicsForOwner.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});
const { actions, reducer } = demographicSlice;

export default reducer;

export const { setInsightForLocationLinkage, setInsightForLocationRemoval } = actions;

// Selectors
export const demographicStoreSelector = (state) => state[demographicReducerName];

export const demographicsSelectors = demographicsAdapter.getSelectors(
    demographicStoreSelector
);

export const selectActiveOpportunityOwnerPopulation = createSelector(
    [demographicsSelectors.selectAll, activeOpportunitySelector],
    (demographics, activeOpportunity) => {
        return activeOpportunity &&
            activeOpportunity.owner &&
            activeOpportunity.owner.demographics
            ? demographics
                  .filter((demographic) =>
                      activeOpportunity.owner.demographics
                          .map((owner_demographics) => owner_demographics.id)
                          .includes(demographic.id)
                  )
                  .reduce((total, demographic) => {
                      total += demographic.population;
                      return total;
                  }, 0)
            : null;
    }
);

export const selectDemographicsForActiveOpportunity = createSelector(
    [demographicsSelectors.selectAll, activeOpportunitySelector],
    (demographics, activeOpportunity) => {
        if (!activeOpportunity) {
            return [];
        }
        return demographics.filter((demographic) =>
            activeOpportunity.owner.demographics.includes(demographic.id)
        );
    }
);

export const addLocationSelector = createSelector(
    [demographicStoreSelector],
    (demographicStore) => demographicStore.addLocation
);

export const activeInsightSelector = createSelector(
    [addLocationSelector],
    (addLocation) => addLocation.activeInsight
);

export const removeLocationSelector = createSelector(
    [demographicStoreSelector],
    (demographicStore) => demographicStore.removeLocation
);

export const demographicToRemoveSelector = createSelector(
    [demographicsSelectors.selectEntities, removeLocationSelector],
    (allDemos, removeLocation) => allDemos[removeLocation.demographicId]
);

export const insightIdToRemoveDemographicFromSelector = createSelector(
    [removeLocationSelector],
    (removeLocations) => removeLocations.insightId
);

export const isLoadingDemographicsSelector = createSelector(
    [demographicStoreSelector],
    (demographicStore) => demographicStore.isLoading
);

export const demographicsErrorSelector = createSelector(
    [demographicStoreSelector],
    (demographicStore) => demographicStore.error
);
