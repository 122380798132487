import { AssistantOutlined } from "@material-ui/icons";
import { Tooltip } from "antd";

import { StyledButton } from "components/atoms";
import {
    AI_EMAIL_ASSISTANT_TIER_NAME,
    useGetIsTierEnabledForTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";

export interface AIEmailAssistantButtonProps {
    teamId: number;
    onClick: () => void;
    text?: string;
}

export const AIEmailAssistantButton = ({
    teamId,
    onClick,
    text = "AI Assistant",
}: AIEmailAssistantButtonProps) => {
    const isAIAssistantEnabled = useGetIsTierEnabledForTeam({
        teamId: teamId,
        tierName: AI_EMAIL_ASSISTANT_TIER_NAME,
    });

    if (!isAIAssistantEnabled) return null;

    return (
        <Tooltip title="Ask AI to Generate an Email" placement="top">
            <div>
                <StyledButton
                    variant="page-action"
                    handleClick={onClick}
                    icon={<AssistantOutlined />}
                >
                    {text}
                </StyledButton>
            </div>
        </Tooltip>
    );
};
