import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import {
    StyledButton,
    Text,
    StyledCard,
    StyledCardRow,
    StyledCardSection,
} from "components/atoms";
import { repOrDistributor } from "constants/assignees";
import {
    formatDayHyphenShortenedMonthHyphenYear,
    formatTimeAgoDayHyphenShortenedMonthHyphenYearWithTime,
} from "constants/formatters";
import {
    Profile,
    OpportunityActionsTakenRead,
    OpportunityRead,
} from "reactQuery/hooks/apiTypes";
import { useModalStore, ModalModeOptions } from "stores/zustandStore";

export interface AssigneeButtonProps {
    isEditButton: boolean;
}

const AssigneeButton = ({ isEditButton }: AssigneeButtonProps) => {
    const setOpportunityAssigneeModalVisibility = useModalStore(
        (state) => state.setOpportunityAssigneeModalVisibility
    );

    return isEditButton ? (
        <StyledButton
            variant="edit-secondary"
            handleClick={() => {
                setOpportunityAssigneeModalVisibility(true);
            }}
        >
            Edit
        </StyledButton>
    ) : (
        <StyledButton
            variant="add-secondary"
            handleClick={() => {
                setOpportunityAssigneeModalVisibility(true);
            }}
        >
            Add Assignee
        </StyledButton>
    );
};

export interface AssigneeRowProps {
    assignees?: Profile[];
}

const AssigneeRow = ({ assignees }: AssigneeRowProps) => {
    const assigneeNames = assignees.map(
        (assignee) => assignee.user.first_name + " " + assignee.user.last_name
    );
    return (
        <StyledCardRow variant="text-with-button">
            <Grid item xs={6} md={assigneeNames?.length > 0 ? 7 : false}>
                <Text variant="labelHeader">
                    {(assigneeNames?.length > 1
                        ? "Sales Team Owners"
                        : "Sales Team Owner") +
                        ": " +
                        assigneeNames?.map((assignee) => " " + assignee)}
                </Text>
            </Grid>
            <Grid item xs={assigneeNames?.length > 0 ? 5 : 6}>
                <AssigneeButton isEditButton={assigneeNames.length > 0} />
            </Grid>
        </StyledCardRow>
    );
};

export interface LogActivityRowProps {
    action_taken: OpportunityActionsTakenRead;
}

const LogActivityRow = ({ action_taken }: LogActivityRowProps) => {
    const setLogProgressModalVisibility = useModalStore(
        (state) => state.setLogProgressModalVisibility
    );
    const setLogProgressModalActionTaken = useModalStore(
        (state) => state.setLogProgressModalActionTaken
    );

    const setLogProgressModalMode = useModalStore(
        (state) => state.setLogProgressModalMode
    );
    return (
        <StyledCardRow>
            <Grid item xs={5}>
                <Text variant="labelDate">
                    {formatDayHyphenShortenedMonthHyphenYear(action_taken.action_date)}
                </Text>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <StyledButton
                        variant="edit-secondary"
                        handleClick={() => {
                            setLogProgressModalActionTaken(action_taken);
                            setLogProgressModalVisibility(true);
                            setLogProgressModalMode(ModalModeOptions.Edit);
                        }}
                    >
                        Edit
                    </StyledButton>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Text variant="mainText">
                    {action_taken.actions.label.split("-")[1]}
                </Text>
                <Text variant="value">{action_taken.notes?.notes ?? ""}</Text>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="label">
                        {action_taken.author.user.first_name +
                            " " +
                            action_taken.author.user.last_name}
                    </Text>
                    <Text variant="label">
                        {formatTimeAgoDayHyphenShortenedMonthHyphenYearWithTime(
                            action_taken.last_updated
                        )}
                    </Text>
                </Box>
            </Grid>
        </StyledCardRow>
    );
};

export interface ActivityLogPanelProps {
    actionsTaken?: OpportunityActionsTakenRead[];
    assignees: Profile[];
    hasOtherAssignee: boolean;
}

export const ActivityLogPanel = ({
    assignees,
    actionsTaken,
    hasOtherAssignee,
}: ActivityLogPanelProps) => {
    if (
        hasOtherAssignee &&
        !assignees.some((assignee) => assignee === repOrDistributor)
    ) {
        assignees.push(repOrDistributor);
    }
    const setLogProgressModalVisibility = useModalStore(
        (state) => state.setLogProgressModalVisibility
    );
    const setLogProgressModalActionTaken = useModalStore(
        (state) => state.setLogProgressModalActionTaken
    );

    const setLogProgressModalMode = useModalStore(
        (state) => state.setLogProgressModalMode
    );

    return (
        <StyledCard>
            <AssigneeRow assignees={assignees} />
            <StyledCardSection maxHeight="200px">
                {actionsTaken?.map((action_taken, index) => (
                    <LogActivityRow key={index} action_taken={action_taken} />
                ))}
            </StyledCardSection>
            <StyledCardRow variant="button">
                <StyledButton
                    variant="edit-primary"
                    handleClick={() => {
                        setLogProgressModalActionTaken(null);
                        setLogProgressModalMode(ModalModeOptions.Add);
                        setLogProgressModalVisibility(true);
                    }}
                >
                    Log Progress
                </StyledButton>
            </StyledCardRow>
        </StyledCard>
    );
};
