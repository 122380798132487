import { Button, Form, Input, Modal, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { usePatchOpportunity } from "reactQuery/hooks/useOpportunity";
import { ModalNames, useModalStore, useSnackStore } from "stores/zustandStore";

export const EditOpportunityModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    const opportunity = modalInitialValues?.opportunity;
    const patchOpportunityQuery = usePatchOpportunity();

    useEffect(() => {
        if (opportunity) {
            form.setFieldsValue({
                name: opportunity.name,
            });
        }
    }, [opportunity]);

    const { search } = useLocation();
    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? parseInt(teamIdString) : null;

    const [form] = Form.useForm();
    const formId = "edit-opportunity-modal-form";

    const onModalCancel = () => {
        setModalHidden();
    };

    const onFinish = async () => {
        patchOpportunityQuery.mutate(
            {
                opportunityPayload: {
                    name: form.getFieldValue("name"),
                },
                opportunityId: opportunity?.id,
                teamId: teamId,
            },
            {
                onSuccess: () => {
                    setModalHidden();
                    displaySuccessSnack({
                        message: "Opportunity updated successfully",
                    });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Failed to update opportunity",
                    });
                },
            }
        );
    };

    const ownerLabel = `${opportunity?.owner?.legal_name} (${opportunity?.owner?.state_code})`;

    return (
        <Modal
            open={openModal === ModalNames.EditOpportunity}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={
                <Row justify="space-between">
                    <Button
                        key="remove"
                        onClick={() => {
                            onModalCancel();
                            setModalVisible(ModalNames.RemoveOpportunity, {
                                opportunity,
                            });
                        }}
                        type="default"
                    >
                        Remove
                    </Button>

                    <div>
                        <Button key="cancel" type="default" onClick={onModalCancel}>
                            Cancel
                        </Button>
                        <Button
                            loading={patchOpportunityQuery.isLoading}
                            disabled={patchOpportunityQuery.isLoading}
                            form={formId}
                            key="submit"
                            htmlType="submit"
                            type="primary"
                        >
                            Submit
                        </Button>
                    </div>
                </Row>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="vertical"
                style={{ width: "80%", margin: "auto" }}
                preserve={false}
                onFinish={onFinish}
            >
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <Space>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            Edit Opportunity
                        </Typography.Title>
                    </Space>
                </div>

                <Space size={20} direction="vertical" style={{ width: "100%" }}>
                    <div>
                        <Typography.Text style={{ marginRight: "10px" }} strong>
                            Owner:
                        </Typography.Text>
                        <Typography.Text>{ownerLabel}</Typography.Text>
                    </div>

                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a name for the opportunity",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default EditOpportunityModal;
