import { useState, useEffect } from "react";

type UseCountdownTimerProps = {
    initialTime: number;
    startTimer: boolean;
};

const useCountdownTimer = ({ initialTime, startTimer }: UseCountdownTimerProps) => {
    const [remainingTime, setRemainingTime] = useState<number>(initialTime);
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout | null = null;

        if (startTimer && !isActive) {
            setIsActive(true);
            setRemainingTime(initialTime);
        } else if (!startTimer && isActive) {
            setIsActive(false);
        }

        if (isActive) {
            timerId = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timerId!);
                        setIsActive(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [startTimer, isActive, initialTime]);

    return remainingTime;
};

export default useCountdownTimer;
