import { makeStyles } from "@material-ui/core/styles";

import { additionalColors, brandColors } from "constants/colors";

export const targetSurfacerTableStyles = makeStyles((theme) => ({
    text: {
        fontSize: "0.875rem",
    },
    alert: {
        marginTop: "4px",
        width: "95%",
    },
    copyButton: {
        height: "20px",
        width: "20px",
    },
    hoverable: {
        cursor: "pointer",
    },
    loadingInfo: {
        paddingBottom: "20px",
    },
    mark: {
        background: additionalColors.reallyLightOrange,
        color: additionalColors.black,
        border: `1px solid ${brandColors.orange}`,
        fontSize: "0.875rem",
    },
    table: {
        maxWidth: "95%",
        paddingTop: "2vw",
        paddingBottom: "2vw",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    tableButtonGroup: {
        maxWidth: "95%",
        marginTop: theme.spacing(1),
    },
    columnHeading: {
        fontSize: "0.875rem",
        cursor: "pointer",
    },
    columnData: {
        fontSize: "0.875rem",
    },
    expandableRow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    expandableRowContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    expandableRowDataTitle: {
        width: "10%",
    },
    expandableRowData: {
        width: "100%",
    },
    divider: {
        margin: "16px 0px",
    },
}));
