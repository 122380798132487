import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CombinedTableInsight } from "components/organisms/PaginatedTable/types";
import { FEED_TYPES } from "constants/feedTypes";
import { nautilusPostRequest } from "network";
import {
    tableDataKeys,
    opportunityKeys,
    opportunityFeedbackUsersKeys,
} from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import {
    FeedbackWrite,
    OpportunityRead,
    AggregateFeedbackRead,
    ReactionEnum,
    PaginatedIndicatorFeedPageInsightsList,
    PaginatedAllInsightsPageInsightsList,
    PaginatedSingleReportPageInsightsList,
    AllInsightsPageInsights,
    IndicatorFeedPageInsights,
    SingleReportPageInsights,
} from "./apiTypes";

type PaginatedInsights =
    | PaginatedAllInsightsPageInsightsList
    | PaginatedIndicatorFeedPageInsightsList
    | PaginatedSingleReportPageInsightsList;

type Insights =
    | AllInsightsPageInsights
    | IndicatorFeedPageInsights
    | SingleReportPageInsights;

async function postFeedback(requestData: FeedbackWrite): Promise<FeedbackWrite> {
    const { data } = await nautilusPostRequest(
        `/api/target_reports/feedback/`,
        requestData
    );
    return data;
}

function modifyFeedback({ existingFeedback, reaction }) {
    var newFeedback = { ...existingFeedback };
    switch (reaction) {
        case ReactionEnum.Like:
            if (newFeedback.is_liked) {
                newFeedback.total_likes--;
                newFeedback.is_liked = !newFeedback.is_liked;
            } else {
                newFeedback.total_likes++;
                newFeedback.is_liked = !newFeedback.is_liked;
            }
            break;
        case ReactionEnum.Dislike:
            if (newFeedback.is_disliked) {
                newFeedback.total_dislikes--;
                newFeedback.is_disliked = !newFeedback.is_disliked;
            } else {
                newFeedback.total_dislikes++;
                newFeedback.is_disliked = !newFeedback.is_disliked;
            }
            break;
        case ReactionEnum.Bullseye:
            if (newFeedback.is_bullseyed) {
                newFeedback.total_bullseye--;
                newFeedback.is_bullseyed = !newFeedback.is_bullseyed;
            } else {
                newFeedback.total_bullseye++;
                newFeedback.is_bullseyed = !newFeedback.is_bullseyed;
            }
            break;
        case ReactionEnum.AddToActionList:
            newFeedback.add_to_action_list = !newFeedback.add_to_action_list;
            break;
        case ReactionEnum.AddToCrm:
            newFeedback.add_to_crm = !newFeedback.add_to_crm;
            break;
        case ReactionEnum.AddToFutureList:
            newFeedback.add_to_future_list = !newFeedback.add_to_future_list;
            break;
        case ReactionEnum.Hide:
            newFeedback.hide = !newFeedback.hide;
            break;
    }
    return newFeedback;
}

function getNewFeedbackResult({
    insight,
    opportunityId,
    reaction,
    feedback,
}: {
    insight: CombinedTableInsight;
    opportunityId: number;
    feedback?: AggregateFeedbackRead;
    reaction?: ReactionEnum;
}): CombinedTableInsight {
    if (opportunityId === insight.opportunity_id) {
        let newInsight = { ...insight };
        if (reaction) {
            newInsight.feedback = modifyFeedback({
                existingFeedback: insight.feedback,
                reaction,
            });
        } else if (feedback) {
            newInsight.feedback = feedback;
        }
        return newInsight;
    }
    return insight;
}

export function usePostFeedback({ viewType }: { viewType: string }) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
    });
    const queryClient = useQueryClient();
    return useMutation(
        (requestData: FeedbackWrite) => {
            return postFeedback(requestData);
        },
        {
            onMutate: async (
                requestData
            ): Promise<{
                currentPaginatedInsights?: PaginatedInsights;
                opportunityId?: number;
                currentOpportunity?: OpportunityRead;
            }> => {
                if (Object.values(FEED_TYPES).includes(viewType)) {
                    await queryClient.cancelQueries(
                        tableDataKeys.createKey(viewType, queryParams)
                    );

                    let currentPaginatedInsights;

                    const paginatedInsights: PaginatedAllInsightsPageInsightsList =
                        queryClient.getQueryData(
                            tableDataKeys.createKey(viewType, queryParams)
                        );
                    currentPaginatedInsights = paginatedInsights;

                    const currentInsights: Insights[] =
                        currentPaginatedInsights.results;

                    const newAllInsights = currentInsights.map((insight: Insights) => {
                        if (insight.opportunity_id === requestData.opportunity) {
                            return getNewFeedbackResult({
                                reaction: requestData.reaction,
                                insight: insight,
                                opportunityId: requestData.opportunity,
                            });
                        } else {
                            return insight;
                        }
                    });

                    const newPaginatedAllInsights = {
                        ...currentPaginatedInsights,
                        results: newAllInsights,
                    };
                    const opportunityId = requestData.opportunity;

                    queryClient.setQueryData(
                        tableDataKeys.createKey(viewType, queryParams),
                        newPaginatedAllInsights
                    );

                    return {
                        currentPaginatedInsights,
                        opportunityId,
                    };
                } else if (viewType.includes("Opportunity")) {
                    await queryClient.cancelQueries({
                        queryKey: opportunityKeys.single_opportunity(
                            requestData.opportunity
                        ),
                    });
                    const currentOpportunity: OpportunityRead =
                        queryClient.getQueryData(
                            opportunityKeys.single_opportunity(requestData.opportunity)
                        );

                    const newOpportunity = {
                        ...currentOpportunity,
                        feedback: modifyFeedback({
                            existingFeedback: currentOpportunity.feedback,
                            reaction: requestData.reaction,
                        }),
                    };
                    queryClient.setQueryData(
                        opportunityKeys.single_opportunity(requestData.opportunity),
                        newOpportunity
                    );
                    return { currentOpportunity };
                }
            },
            onError: (
                err,
                requestData,
                context: {
                    currentPaginatedInsights: PaginatedInsights;
                    opportunityId: number;
                    currentOpportunity: OpportunityRead;
                }
            ) => {
                if (Object.values(FEED_TYPES).includes(viewType)) {
                    queryClient.setQueryData(
                        tableDataKeys.createKey(viewType, queryParams),
                        context.currentPaginatedInsights
                    );
                } else if (viewType.includes("Opportunity")) {
                    queryClient.setQueryData(
                        opportunityKeys.single_opportunity(requestData.opportunity),
                        context.currentOpportunity
                    );
                }
            },
            onSuccess: (
                responseData: FeedbackWrite,
                variables,
                context: {
                    currentPaginatedInsights: PaginatedInsights;
                    opportunityId: number;
                    currentOpportunity: OpportunityRead;
                }
            ) => {
                if (Object.values(FEED_TYPES).includes(viewType)) {
                    queryClient.invalidateQueries(
                        opportunityFeedbackUsersKeys.single(
                            context.opportunityId,
                            queryParams
                        )
                    );
                    const newAllInsights = context.currentPaginatedInsights.results.map(
                        (insight) => {
                            if (insight.opportunity_id === context.opportunityId) {
                                return getNewFeedbackResult({
                                    feedback: responseData.feedback,
                                    insight: insight,
                                    opportunityId: context.opportunityId,
                                });
                            } else {
                                return insight;
                            }
                        }
                    );

                    const newPaginatedAllInsights = {
                        ...context.currentPaginatedInsights,
                        results: newAllInsights,
                    };
                    queryClient.setQueryData(
                        tableDataKeys.createKey(viewType, queryParams),
                        newPaginatedAllInsights
                    );
                } else if (viewType.includes("Opportunity")) {
                    queryClient.invalidateQueries(opportunityFeedbackUsersKeys.all());
                    const newOpportunity = {
                        ...context.currentOpportunity,
                        feedback: responseData.feedback,
                    };
                    queryClient.setQueryData(
                        opportunityKeys.single_opportunity(
                            context.currentOpportunity.id
                        ),
                        newOpportunity
                    );
                }
            },
        }
    );
}
