import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { SearchPlanSummaryRead } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";

const RenderDetails = (searchPlanSummary: SearchPlanSummaryRead) => (
    <PDFView style={pdfStyle.sectionContainer} wrap={false}>
        <PDFText style={pdfStyle.sectionTitle}>Product Details</PDFText>
        {searchPlanSummary.team?.name ? (
            <PDFView style={pdfStyle.itemContainer}>
                <PDFView style={pdfStyle.itemTitleContainer}>
                    <PDFText style={pdfStyle.itemTitle}>Team</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.itemValueContainer}>
                    <PDFText style={pdfStyle.itemValue}>
                        {searchPlanSummary.team.name}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {searchPlanSummary.product?.name ? (
            <PDFView style={pdfStyle.itemContainer}>
                <PDFView style={pdfStyle.itemTitleContainer}>
                    <PDFText style={pdfStyle.itemTitle}>Product</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.itemValueContainer}>
                    <PDFText style={pdfStyle.itemValue}>
                        {searchPlanSummary.product.name}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {searchPlanSummary.plan_type ? (
            <PDFView style={pdfStyle.itemContainer}>
                <PDFView style={pdfStyle.itemTitleContainer}>
                    <PDFText style={pdfStyle.itemTitle}>Plan</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.itemValueContainer}>
                    <PDFText style={pdfStyle.itemValue}>
                        {searchPlanSummary.plan_type}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {searchPlanSummary.delivery_cadence ? (
            <PDFView style={pdfStyle.itemContainer}>
                <PDFView style={pdfStyle.itemTitleContainer}>
                    <PDFText style={pdfStyle.itemTitle}>Delivery Cadence</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.itemValueContainer}>
                    <PDFText style={pdfStyle.itemValue}>
                        {searchPlanSummary.delivery_cadence}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {searchPlanSummary.minimum_insights || searchPlanSummary.maximum_insights ? (
            <PDFView style={pdfStyle.itemContainer}>
                <PDFView style={pdfStyle.itemTitleContainer}>
                    <PDFText style={pdfStyle.itemTitle}>Expected Insights</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.itemValueContainer}>
                    <PDFText style={pdfStyle.itemValue}>{`${
                        searchPlanSummary.minimum_insights ?? ""
                    }${
                        typeof searchPlanSummary.maximum_insights === "number" &&
                        typeof searchPlanSummary.minimum_insights === "number"
                            ? " - "
                            : typeof searchPlanSummary.maximum_insights === "number"
                            ? "<="
                            : typeof searchPlanSummary.minimum_insights === "number"
                            ? "+"
                            : ""
                    }${searchPlanSummary.maximum_insights ?? ""}`}</PDFText>
                </PDFView>
            </PDFView>
        ) : null}
    </PDFView>
);

export default RenderDetails;
