import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { additionalColors } from "constants/colors";

const useStyle = makeStyles(() => ({
    blue: {
        color: additionalColors.aquaBlue,
        textDecoration: "none",
    },
    bold: {
        fontWeight: "bold",
    },
    boldAndUnderlined: {
        fontWeight: "bold",
        textDecoration: "underline",
    },
    codeBlock: {
        fontFamily: "Courier New",
        border: "1px solid grey",
        backgroundColor: "lightgrey",
    },
    panel: {
        margin: "20px 0",
    },
}));

function createRow(field_name, sample_value) {
    return { field_name, sample_value };
}

const rows = [
    createRow("utility_whid", "WH-UYO7WSIG"),
    createRow("host", "www.muni.org"),
    createRow("city_name", "Anchorage"),
    createRow("state_code", "AK"),
    createRow("population", "298695"),
    createRow("geo_whid	", "WH-PK2IKQ6M"),
    createRow("title", "Welcoming Anchorage"),
];

const HelpPanel = () => {
    const classes = useStyle();
    return (
        <Accordion className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.blue}>
                    Help constructing queries
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <span>
                        <Typography display="inline">
                            If you are interested in understanding the syntax for
                            building queries to search document text - including how to
                            exclude results or search for nearby words, for example -
                            please refer to this
                        </Typography>{" "}
                        <a
                            href="https://www.cheatography.com/jelle/cheat-sheets/elasticsearch-query-string-syntax/"
                            className={classes.blue}
                        >
                            webpage
                        </a>
                        {". "}
                        <Typography className={classes.bold} display="inline">
                            Please note that wildcard and regex searching is not
                            supported due to performance constraints.
                        </Typography>
                    </span>
                    <Typography paragraph={true}>
                        The core component that is always searched is the text content
                        of the Document or Website. In addition to the text content,
                        there are a number of additional optional filters.
                    </Typography>
                    <Typography paragraph={true}>
                        An important caveat - not all of the Documents and Websites we
                        crawl contain the data required for a filter to select it. When
                        you search just text, you will receive all documents that match
                        the text. You can think of adding filters as being more precise
                        at the expense of excluding documents that "should" have been
                        included but did not because of lacking metadata on the Document
                        itself.
                    </Typography>
                    <Typography className={classes.boldAndUnderlined}>
                        Available fields to include
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Field Name</TableCell>
                                    <TableCell>Sample Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.field_name}>
                                        <TableCell component="th" scope="row">
                                            {row.field_name}
                                        </TableCell>
                                        <TableCell>{row.sample_value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography className={classes.bold} paragraph={true}>
                        It is important to note that Documents and Websites can be
                        missing these values from them - or - potentially have incorrect
                        information associated with them.
                    </Typography>
                    <Typography paragraph={true}>
                        An example could be a site like American Water - it comprises a
                        number of locations across state lines and demographics. Due to
                        this, documents relevant to one state may be miscategorized to
                        be included with the original state that initiated the crawl.
                    </Typography>
                    <Typography paragraph={true}>
                        Sites also can be lacking the metadata like regional information
                        such as population size or state code.
                    </Typography>
                    <Typography paragraph={true}>
                        With that caveat out of the way, here are some examples on how
                        to leverage the above fields.
                    </Typography>
                    <span>
                        <Typography className={classes.codeBlock}>
                            "pipe replacement" AND state_code:(AK OR WA) AND population:
                            [1 TO 298695]
                        </Typography>
                        <Typography>
                            {" "}
                            will filter the results down to be only results that are
                            identified as being in Alaska or Washington and that also
                            have populations in the specified range.
                        </Typography>
                        <Typography className={classes.bold}>
                            Please note that WHIDs must be enclosed in quotations - this
                            goes for both Utility and Geo WHIDs.
                        </Typography>
                    </span>
                    <span>
                        <Typography className={classes.codeBlock}>
                            "Anchorage" AND host:("www.muni.org" OR
                            "townofdauphinisland.org")
                        </Typography>{" "}
                        <Typography>
                            will filter results down to only those Documents associated
                            with the provided hosts. A host is the website that a
                            document or page lives on. It usually comprises either an
                            optional "www.", followed by a domain name (like
                            townofdauphinisland), and then a domain (like .com or .org).
                            You can derive what the host is from the CSV report to find
                            more from a particular host.
                        </Typography>
                        <Typography className={classes.bold}>
                            The separating words - AND and OR - must be capitalized.
                        </Typography>
                    </span>
                    <Typography>
                        If you have any questions that are left unanswered, please reach
                        out to @Dev Team on Slack so that this guide can be expanded.
                    </Typography>
                    <Typography className={classes.bold}>More Examples:</Typography>
                    <Typography paragraph={true} className={classes.codeBlock}>
                        "wtp upgrade" ~3 AND url:("2021" OR "21" OR "2022" OR "22" OR
                        "2023" OR "2024" OR "2025")
                    </Typography>
                    <Typography paragraph={true} className={classes.codeBlock}>
                        "pipe assessment" ~3 AND url:("2021" OR "21" OR "2022" OR "22"
                        OR "2023" OR "2024" OR "2025") AND "budget"
                    </Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default HelpPanel;
