import { Space, Layout, Typography } from "antd";
import { ReactNode } from "react";

import { BreadcrumbProps, Breadcrumbs } from "components/molecules/Breadcrumbs";

const { Content } = Layout;
const { Title } = Typography;

export const IntelligenceFeedsTemplate = ({
    title = null,
    breadcrumbs,
    children,
}: {
    title?: string;
    breadcrumbs: BreadcrumbProps[];
    children: ReactNode;
}) => {
    return (
        <div>
            <Content style={{ padding: "30px" }}>
                <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} data-testid="breadcrumb" />
                    {title ? <Title level={3}>{title}</Title> : null}

                    {children}
                </Space>
            </Content>
        </div>
    );
};
