import { StyleSheet } from "@react-pdf/renderer";

import { additionalColors } from "constants/colors";

export const pdfStyle = StyleSheet.create({
    page: {
        flexDirection: "column",
        color: "#000000",
        backgroundColor: "#FFFFFF",
        padding: 25,
        fontSize: 10,
    },
    logo: {
        width: "25%",
    },
    externalLinkIcon: {
        marginLeft: 35,
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: 15,
    },
    separatorContainer: {
        borderBottom: `1pt solid ${additionalColors.grey300}`,
        margin: "0 0 10 0",
        padding: "0 0 5 0",
        width: "100%",
    },
    infoChart: {
        display: "flex",
        flexDirection: "row",
    },
    infoChartTitle: {
        color: additionalColors.grey500,
        width: "20%",
        marginRight: 10,
    },
    insightContainer: {
        borderBottom: `1pt solid ${additionalColors.grey300}`,
        margin: "0 0 10 0",
        padding: "0 0 5 0",
    },
    insightItemContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
    },
    insightDateTitle: {
        width: "20%",
        marginRight: 10,
    },
    insightItemTitle: {
        color: additionalColors.grey500,
        width: "20%",
        marginRight: 10,
    },
    insightItemValue: {
        width: "80%",
    },
    insightMultiItemContainer: {
        width: "80%",
    },
    sourceContainer: {
        padding: "10 0 0 0",
        width: "100%",
    },
    itemSeparator: {
        borderTop: `1pt solid ${additionalColors.grey300}`,
    },
    contactsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    contactItem: {
        width: "40%",
        marginRight: 10,
        marginBottom: 10,
    },
    contactTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
});
