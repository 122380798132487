import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { indicatorTagKeys } from "reactQuery/keys";

import { IndicatorTag } from "./apiTypes";

export const useGetIndicatorTagsByQuery = (
    query: string,
    enabled: boolean
): UseQueryResult<IndicatorTag[]> => {
    const queryKey = indicatorTagKeys.search(query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<IndicatorTag[]> =>
            (
                await nautilusGetRequest(
                    "/api/target_reports/indicator_tags/",
                    `?limit=100&value=${query}`
                )
            ).data?.results,
        enabled,
    });
};
