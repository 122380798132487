import { createAction } from "@reduxjs/toolkit";

import { companyReducerName } from "stores/companies/constants";

const formatName = (name) => `${companyReducerName}/${name}`;

// these actions are used for both consultant + competitor linkage/removal
export const setInsightForCompanyLinkage = createAction(
    formatName("setInsightForCompanyLinkage")
);

export const setInsightForCompanyRemoval = createAction(
    formatName("setInsightForCompanyRemoval")
);
