import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export const DatePicker = ({ formikProps }) => {
    const { setFieldValue, values, errors } = formikProps;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl
                id="date-form-control"
                style={{ paddingLeft: "17px", width: "100%" }}
            >
                <InputLabel shrink style={{ paddingLeft: "30px" }}>
                    Date
                </InputLabel>
                <KeyboardDatePicker
                    disableToolbar
                    format="yyyy-MM-dd"
                    id="date-picker-inline"
                    inputProps={{
                        "data-testid": "test-date-picker",
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    margin="normal"
                    name="selectedDate"
                    onChange={(e) => {
                        setFieldValue ? setFieldValue("selectedDate", e) : null;
                    }}
                    value={values?.selectedDate}
                    variant="inline"
                    inputVariant="outlined"
                />
                <FormHelperText error>{errors?.selectedDate}</FormHelperText>
            </FormControl>
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
