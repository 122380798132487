import "antd/dist/antd.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import AddOpportunityModal from "components/cdv/AddOpportunityModal";
import SidebarLayout from "components/organisms/SidebarLayout";
import ReportDetailsTemplate from "components/templates/ReportDetailsTemplate";
import { FEED_TYPES } from "constants/feedTypes";
import { IntelligenceReportsAllInsightsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";
import { useGetSingleTeam } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetIndicatorGroupById } from "reactQuery/hooks/useIndicatorGroups";
import { useGetReport } from "reactQuery/hooks/useReports";
import { IndicatorFeedsSingleFeedPathname } from "routes/IndicatorFeeds";
import {
    AllIndicatorsPathname,
    AllInsightsPathname,
    PriorityInsightsPathname,
} from "routes/IntelligenceFeeds";
import { redirectIfTeamHasIntelligenceFeedsProduct } from "utils/redirect";

import { useStyles } from "./styles.js";
import CreateInsightModal from "../../components/cdv/CreateInsightModal";
import EditReportModal from "../../components/cdv/EditReportModal";
import {
    fetchReports,
    setActiveReport,
    setActiveTeam,
} from "../../stores/reports/reportSlice";
import { toggleEditMode, toggleOverlay } from "../../stores/uiStore/actionTypes";
import { isEditModeActiveSelector } from "../../stores/uiStore/selectors";
import { isStaffSelector } from "../../stores/userStore/selectors";

interface UnconnectedReportDetailsPageProps {
    feedType: string;
    isEditModeActive: boolean;
    isUpdatingEditOpportunity: boolean;
    isStaff: boolean;
    reportId: number;
    toggleEditMode: Function;
    toggleOverlay: Function;
    teamId: number;
    setActiveReport: Function;
    setActiveTeam: Function;
    fetchReports: Function;
}

export const UnconnectedReportDetailsPage = ({
    feedType,
    isEditModeActive,
    isStaff,
    toggleEditMode,
    toggleOverlay,
    setActiveReport,
    setActiveTeam,
    fetchReports,
}: UnconnectedReportDetailsPageProps) => {
    const styles = useStyles();

    const { search } = useLocation();

    const teamIdParam = new URLSearchParams(search).get("team_id");
    const teamId = teamIdParam ? parseInt(teamIdParam) : null;

    const reportIdParam = new URLSearchParams(search).get("report_id");
    const reportId = reportIdParam ? parseInt(reportIdParam) : null;

    const indicatorGroupIdParam = new URLSearchParams(search).get("indicator_group_id");
    const indicatorGroupId = indicatorGroupIdParam
        ? parseInt(indicatorGroupIdParam)
        : null;

    const reportQuery = useGetReport(
        reportId,
        reportId && feedType === FEED_TYPES.SINGLE_REPORT ? true : false
    );
    const reportData = reportQuery?.data || null;

    const indicatorGroupQuery = useGetIndicatorGroupById(
        indicatorGroupId,
        indicatorGroupId && feedType === FEED_TYPES.INDICATOR_FEED ? true : false
    );
    const indicatorGroupQueryData = indicatorGroupQuery?.data || null;

    const teamQuery = useGetSingleTeam(teamId);
    const team = teamQuery?.data;

    if (feedType === FEED_TYPES.SINGLE_REPORT) {
        redirectIfTeamHasIntelligenceFeedsProduct(PriorityInsightsPathname, teamId);
    } else if (feedType === FEED_TYPES.ALL_INSIGHTS) {
        redirectIfTeamHasIntelligenceFeedsProduct(AllInsightsPathname, teamId);
    } else if (feedType === FEED_TYPES.INDICATOR_FEED) {
        redirectIfTeamHasIntelligenceFeedsProduct(
            AllIndicatorsPathname,
            teamId,
            indicatorGroupId
        );
    }

    useEffect(() => {
        if (reportQuery.isSuccess) {
            fetchReports({ reportId });
            setActiveReport({ reportId: reportId });
            setActiveTeam({ teamId: teamId });
        }
    }, [reportQuery]);

    const body =
        (feedType === FEED_TYPES.SINGLE_REPORT && reportQuery.isSuccess) ||
        feedType === FEED_TYPES.ALL_INSIGHTS ||
        (feedType === FEED_TYPES.INDICATOR_FEED && indicatorGroupQuery.isSuccess) ? (
            <div className={styles.reportDetailsBody}>
                <>
                    <AddOpportunityModal />
                    <EditReportModal />
                    <CreateInsightModal />
                    <ReportDetailsTemplate
                        feedType={feedType}
                        indicatorGroupData={indicatorGroupQueryData}
                        isEditModeActive={isEditModeActive}
                        isStaff={isStaff}
                        reportData={reportData}
                        team={team}
                        teamId={teamId}
                        toggleEditMode={toggleEditMode}
                        toggleOverlay={toggleOverlay}
                    />
                </>
            </div>
        ) : (feedType === FEED_TYPES.SINGLE_REPORT && reportQuery.isError) ||
          !reportId ? (
            <div className={styles.reportDetailsBody}>
                <p>Specific report not found</p>
            </div>
        ) : null;

    return (
        <div>
            <SidebarLayout content={body} />
        </div>
    );
};

const mapStateToProps = (state: any, props: any) => {
    const feedType = props.location.pathname.includes(IndicatorFeedsSingleFeedPathname)
        ? FEED_TYPES.INDICATOR_FEED
        : props.location.pathname.includes(IntelligenceReportsSingleReportPathname)
        ? FEED_TYPES.SINGLE_REPORT
        : props.location.pathname.includes(IntelligenceReportsAllInsightsPathname)
        ? FEED_TYPES.ALL_INSIGHTS
        : null;

    return {
        feedType: feedType,
        isEditModeActive: isEditModeActiveSelector(state),
        isStaff: isStaffSelector(state as never),
    };
};

const ConnectedReportDetailsPage = connect(mapStateToProps, {
    toggleEditMode,
    toggleOverlay: toggleOverlay,
    setActiveReport,
    setActiveTeam,
    fetchReports,
})(UnconnectedReportDetailsPage);

export default ConnectedReportDetailsPage;
