import { StyledButton } from "components/atoms";
import { ModalNames, useModalStore } from "stores/zustandStore";

import { buttonTestIds } from "..";

const EditOpportunityButton = ({ isEditModeActive, opportunity }) => {
    if (!isEditModeActive) {
        return null;
    }

    const setModalVisible = useModalStore((state) => state.setModalVisible);

    return (
        <StyledButton
            data-testid={buttonTestIds.editOpportunity}
            handleClick={() => {
                setModalVisible(ModalNames.EditOpportunity, {
                    opportunity,
                });
            }}
            variant="edit-secondary"
        >
            Edit
        </StyledButton>
    );
};

export default EditOpportunityButton;
