import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { formatDayHyphenShortenedMonthHyphenYearWithTime } from "constants/formatters";

import { pdfStyle } from "../pdfStyle";

const RenderTimelineItem = (item, index) => (
    <PDFView
        style={[
            pdfStyle.timelineItemContainer,
            item.category === "comment" ? pdfStyle.timelineComment : {},
        ]}
        key={index}
        wrap={false}
    >
        <PDFView style={pdfStyle.timelineTitleContainer}>
            <PDFText>{item.title}</PDFText>
            <PDFText style={pdfStyle.timelineTitleTime}>
                {formatDayHyphenShortenedMonthHyphenYearWithTime(item.date)}
            </PDFText>
        </PDFView>
        <PDFView style={pdfStyle.timelineContentContainer}>
            {item.entries.map((entry) => (
                <PDFText
                    style={[
                        pdfStyle.timelineContentText,
                        entry.important ? pdfStyle.timelineContentTextImportant : {},
                    ]}
                >
                    {entry.title}
                </PDFText>
            ))}
        </PDFView>
    </PDFView>
);

export default RenderTimelineItem;
