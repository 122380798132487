import LinearProgress from "@material-ui/core/LinearProgress";
import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { createLink } from "utils/createLink";

import RenderDocument from "./RenderDocument";

const SharedOpportunitiesPage = () => {
    const [state, setState] = useState({
        error: false,
        loading: true,
        sharedOpportunities: null,
    });

    const { id: pdfUUID } = useParams<{ id: string }>();
    const { search } = useLocation();

    useEffect(() => {
        if (pdfUUID) {
            dataRequest(pdfUUID);
        }

        const searchParams = new URLSearchParams(search);
        const pageNumber = searchParams.get("page");
        const link = searchParams.get("link");

        if (link) {
            const linkURL = createLink({
                url: link,
                ...(pageNumber ? { hash: `page=${pageNumber}` } : {}),
            });

            window.open(linkURL.toString(), "_blank");
            window.open(location.pathname, "_self");
        }
    }, []);

    const dataRequest = async (pdfUUID: string) => {
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/api/target_reports/shared_opportunities/${pdfUUID}/`
            )
            .then((response) => {
                setState({
                    ...state,
                    loading: false,
                    sharedOpportunities: response.data,
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    error: true,
                    loading: false,
                });
            });
    };

    if (state.loading) {
        return (
            <div style={{ padding: "20px 0" }}>
                <LinearProgress />
            </div>
        );
    } else if (state.sharedOpportunities) {
        return (
            <PDFViewer
                style={{ height: "100vh", width: "100vw", border: "0" }}
                showToolbar={true}
            >
                <RenderDocument sharedOpportunities={state.sharedOpportunities} />
            </PDFViewer>
        );
    } else {
        // This should be replaced with an error page
        return (
            <div style={{ padding: "20px 0" }}>
                <LinearProgress />
            </div>
        );
    }
};

export default SharedOpportunitiesPage;
