import Chip from "@material-ui/core/Chip";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { additionalColors, brandColors } from "constants/colors";

const steps = [
    "Prospecting",
    "Utility Response",
    "Opportunity Development",
    "Approval",
    "Closed",
];

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 5,
        left: "calc(-50% + 2px)",
        right: "calc(50% + 2px)",
    },
    active: {
        "& $line": {
            backgroundColor: brandColors.green,
        },
    },
    completed: {
        "& $line": {
            backgroundColor: brandColors.green,
        },
    },
    line: {
        height: 4,
        border: 0,
        backgroundColor: additionalColors.grey300,
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: additionalColors.mediumGrey,
        zIndex: 1,
        color: additionalColors.white,
        width: 15,
        height: 15,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundColor: brandColors.darkBlue,
    },
    completed: {
        backgroundColor: brandColors.green,
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {};

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    current_chip: {
        backgroundColor: brandColors.darkBlue,
        color: additionalColors.white,
        width: 100,
        fontSize: "10px",
    },
    invisible_chip: {
        backgroundColor: "transparent",
        color: additionalColors.white,
        width: 100,
        fontSize: "10px",
    },
}));

function getCurrentStep(current_stage) {
    return steps.indexOf(current_stage);
}

export const SalesStageTimelineModule = ({ current_stage }) => {
    const classes = useStyles();
    const stage = getCurrentStep(current_stage);
    const position = (stage * 18 + 3).toString() + "%";
    return (
        <div className={classes.root}>
            <Stepper
                alternativeLabel
                activeStep={stage}
                connector={<ColorlibConnector />}
                style={{ paddingBottom: 0 }}
            >
                {steps.map((_, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div style={{ paddingLeft: position }}>
                <Chip
                    size="small"
                    label={steps[stage]}
                    className={classes.current_chip}
                />
            </div>
        </div>
    );
};

export default SalesStageTimelineModule;
