import { createSelector } from "@reduxjs/toolkit";

import { companyReducerName } from "stores/companies/constants";

export const companyStoreSelector = (state) => state[companyReducerName];

export const insightForCompanyLinkageSelector = createSelector(
    [companyStoreSelector],
    (companyStore) => companyStore.insight
);

export const removeCompanySelector = createSelector(
    [companyStoreSelector],
    (companyStore) => companyStore.removeCompany
);
