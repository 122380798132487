export const testIds = {
    from: "from-date-picker",
    to: "to-date-picker",
    fromError: "test-from-date-error",
    toError: "test-to-date-error",
    query: "query",
    includeStateTags: "include-state-tags",
    includeCountyTags: "include-county-tags",
    includeOtherTags: "include-other-tags",
    excludeStateTags: "exclude-state-tags",
    excludeCountyTags: "exclude-county-tags",
    excludeOtherTags: "exclude-other-tags",
};

export const ERROR_MESSAGES = {
    universeMissing: "Search universe is required",
    fromDateMissing: "From date is required",
    teamMissing: "A team must be selected.",
    toDateMissing: "To date is required",
    toDateLessThan: "Must be after from date",
    queryMissing: "Query required, examples shown",
    dateInvalid: "Invalid date",
};

export const externalUniverses = [
    {
        value: "us-indices",
        text: "United States",
    },
    {
        value: "canadian-indices",
        text: "Canada",
    },
];

const usCanadaSearchUniverse = {
    value: "us-canada-indices",
    text: "United States & Canada",
};

const ukSearchUniverse = {
    value: "uk-indices",
    text: "United Kingdom",
};

export const staffUniverses = [
    ...externalUniverses,
    usCanadaSearchUniverse,
    ukSearchUniverse,
];

export const loadingText = "Processing results.";
