import { DownloadOutlined } from "@ant-design/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Button, Input, Space, Table, Tooltip } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { openNewExternalLink } from "constants/navigation";
import { generateStaticLink, setSelectedSearchRecord } from "stores/targets/actions";
import {
    enqueuedCountSelector,
    pendingQueriesSelector,
    resultsSelector,
    uniqueUtilitiesCountSelector,
} from "stores/targets/selectors";
import { displaySuccessSnack, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";

import ColumnData from "./components/ColumnData";
import TableDetailPanel from "./components/TableDetailPanel";
import TableHeader from "./components/TableHeader";
import { DateFilterProps } from "./CustomFilters/DateFilter";
import { RangeFilterProps } from "./CustomFilters/RangeFilter";
import { formatResultsCsv } from "./resultsTable";
import { targetSurfacerTableStyles } from "./styles";

export function populationFilter(term, rowData, field) {
    if (!rowData[field]) return false;
    const formattedData = rowData[field].toLocaleString();
    return (
        formattedData.startsWith(term) ||
        formattedData.replace(/,/g, "").startsWith(term.replace(/,/g, ""))
    );
}

export function formatContactPhoneNumber(contact) {
    const areaCode = contact.area_code_primary ? `(${contact.area_code_primary})` : "";
    const phone = contact.phone_primary ? contact.phone_primary.toString() : "";
    return areaCode && phone
        ? `${areaCode} ${phone.slice(0, 3)}-${phone.slice(3)}`
        : "";
}

export const loadingText = "Processing results.";

export const UnconnectedTargetSurfacerResultsTable = ({
    displaySuccessSnack,
    enqueuedCount,
    generateStaticLink,
    numPendingQueries,
    results,
    setSelectedSearchRecord,
    toggleOverlay,
    unique_utilities_count,
}) => {
    const tableData = results.map((data, index) => ({
        ...data,
        key: index,
    }));
    const rowKeys = [...tableData.keys()];
    const [filteredInfo, setFilteredInfo] = useState({});
    const [filterTable, setFilterTable] = useState(null);
    const [populationMinValue, setPopulationMinValue] = useState(0);
    const [populationMaxValue, setPopulationMaxValue] = useState(0);
    const [populationKeys, setPopulationKeys] = useState({});
    const [municipalPopulationMinValue, setMunicipalPopulationMinValue] = useState(0);
    const [municipalPopulationMaxValue, setMunicipalPopulationMaxValue] = useState(0);
    const [municipalPopulationKeys, setMunicipalPopulationKeys] = useState({});
    const [mgdMinValue, setMgdMinValue] = useState(0);
    const [mgdMaxValue, setMgdMaxValue] = useState(0);
    const [mgdKeys, setMgdKeys] = useState({});
    const [connectionCountMinValue, setConnectionCountMinValue] = useState(0);
    const [connectionCountMaxValue, setConnectionCountMaxValue] = useState(0);
    const [connectionCountKeys, setConnectionCountKeys] = useState({});
    const [createdDateRange, setCreatedDateRange] = useState([]);
    const [createdDateRangeKeys, setCreatedDateRangeKeys] = useState({});
    const [lastModifiedRange, setLastModifiedRange] = useState([]);
    const [lastModifiedRangeKeys, setLastModifiedRangeKeys] = useState({});
    const [extractedDateRange, setExtractedDateRange] = useState([]);
    const [extractedDateRangeKeys, setExtractedDateRangeKeys] = useState({});
    const [searchTextValue, setSearchTextValue] = useState(null);
    const [sortedInfo, setSortedInfo] = useState({});
    const [expandedRows, setExpandedRows] = useState(rowKeys);

    const styles = targetSurfacerTableStyles();

    const exportData =
        filterTable === null
            ? formatResultsCsv(tableData)
            : formatResultsCsv(filterTable);

    const exportCSVFileName = `Citylitics-Search-${new Date().getFullYear()}-${
        new Date().getMonth() + 1
    }-${new Date().getDate()}.csv`;

    const getValues = useCallback(
        (fieldName) => {
            return results.map((result) => {
                return result[fieldName];
            });
        },
        [results]
    );

    const getMinValue = useCallback(
        (fieldName) => {
            if (getValues(fieldName).length === 0) {
                return 0;
            }
            return Math.min(...getValues(fieldName));
        },
        [getValues]
    );

    const getMaxValue = useCallback(
        (fieldName) => {
            if (getValues(fieldName).length === 0) {
                return Infinity;
            }
            return Math.max(...getValues(fieldName));
        },
        [getValues]
    );

    useEffect(() => {
        setExpandedRows([
            ...results
                .map((data, index) => ({
                    ...data,
                    key: index,
                }))
                .keys(),
        ]);

        if (results.length !== 0) {
            setPopulationMaxValue(getMaxValue("population"));
            setMunicipalPopulationMaxValue(getMaxValue("municipal_population"));
            setMgdMaxValue(getMaxValue("facility_size"));
            setConnectionCountMaxValue(getMaxValue("connection_count"));
        }
    }, [results, getMaxValue]);

    useEffect(() => {
        setPopulationKeys({
            min: getMinValue("population"),
            max: getMaxValue("population"),
        });
        setMunicipalPopulationKeys({
            min: getMinValue("municipal_population"),
            max: getMaxValue("municipal_population"),
        });
        setMgdKeys({
            min: getMinValue("facility_size"),
            max: getMaxValue("facility_size"),
        });
        setConnectionCountKeys({
            min: getMinValue("connection_count"),
            max: getMaxValue("connection_count"),
        });
    }, [getMinValue, getMaxValue]);

    const handleExpandRows = () => {
        setExpandedRows(rowKeys);
    };

    const handleCollapseRows = () => {
        setExpandedRows([]);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const clearFilters = () => {
        setFilteredInfo({});

        setPopulationMinValue(getMinValue("population"));
        setPopulationMaxValue(getMaxValue("population"));
        setPopulationKeys({
            min: getMinValue("population"),
            max: getMaxValue("population"),
        });

        setMunicipalPopulationMinValue(getMinValue("municipal_population"));
        setMunicipalPopulationMaxValue(getMaxValue("municipal_population"));
        setMunicipalPopulationKeys({
            min: getMinValue("municipal_population"),
            max: getMaxValue("municipal_population"),
        });

        setMgdMinValue(getMinValue("facility_size"));
        setMgdMaxValue(getMaxValue("facility_size"));
        setMgdKeys({
            min: getMinValue("facility_size"),
            max: getMaxValue("facility_size"),
        });

        setConnectionCountMinValue(getMinValue("connection_count"));
        setConnectionCountMaxValue(getMaxValue("connection_count"));
        setConnectionCountKeys({
            min: getMinValue("connection_count"),
            max: getMaxValue("connection_count"),
        });

        setCreatedDateRange([]);
        setCreatedDateRangeKeys({});
        setLastModifiedRange([]);
        setLastModifiedRangeKeys({});
        setExtractedDateRange([]);
        setExtractedDateRangeKeys({});
    };
    const clearSorters = () => {
        setSortedInfo({});
    };

    const clearSearch = () => {
        setFilterTable(null);
        setSearchTextValue(null);
    };

    const handleMultiSelectFilterReset = (clearFilters, setSelectedKeys, dataIndex) => {
        clearFilters();

        if (dataIndex === "population") {
            setPopulationMinValue(getMinValue("population"));
            setPopulationMaxValue(getMaxValue("population"));
        } else if (dataIndex === "municipal_population") {
            setMunicipalPopulationMinValue(getMinValue("municipal_population"));
            setMunicipalPopulationMaxValue(getMaxValue("municipal_population"));
        } else if (dataIndex === "facility_size") {
            setMgdMinValue(getMinValue("facility_size"));
            setMgdMaxValue(getMaxValue("facility_size"));
        } else if (dataIndex === "connection_count") {
            setConnectionCountMinValue(getMinValue("connection_count"));
            setConnectionCountMaxValue(getMaxValue("connection_count"));
        }

        setSelectedKeys([]);
    };

    const getFilters = (fieldName) => {
        return results
            .map((result) => {
                return {
                    text: result[fieldName],
                    value: result[fieldName],
                };
            })
            .sort((a, b) => a.text && a.text.localeCompare(b.text));
    };

    const tagFilters = () => {
        var final = [];
        results.map(
            (result) =>
                result.tags &&
                result.tags.map((tag) => {
                    if (final.indexOf(tag) === -1) {
                        final.push(tag);
                    }
                    return null;
                })
        );
        return final
            .map((f) => {
                return {
                    text: f,
                    value: f,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    };

    const stateFilters = () => {
        var final = [];
        results.map(
            (result) =>
                result.target_state &&
                result.target_state.map((state) => {
                    if (final.indexOf(state) === -1) {
                        final.push(state);
                    }
                    return null;
                })
        );
        return final
            .map((f) => {
                return {
                    text: f,
                    value: f,
                };
            })
            .sort((a, b) => a.text && b.text && a.text.localeCompare(b.text));
    };

    const columns = [
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Shows if a target has been delivered from the
                        corresponding source file before. A checkmark
                        will appear if the doc was used as any of the
                        3 source URLs for a past target"
                    >
                        <div className={styles.columnHeading}>Verified</div>
                    </Tooltip>
                );
            },
            dataIndex: "watrhub_verified",
            key: "watrhub_verified",
            render: (watrhub_verified) => {
                return watrhub_verified === "1" ? <VerifiedUserIcon /> : "-";
            },
            sorter: (a, b) => {
                return a.watrhub_verified > b.watrhub_verified ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "watrhub_verified" && sortedInfo.order,
            filters: [
                { text: "Verified", value: "1" },
                { text: "Unverified", value: "-" },
            ],
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.watrhub_verified || null,
            onFilter: (value, record) => record.watrhub_verified.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="The name of the owner associated with the source
                    - according to our owner database and the associated
                    source file's URL."
                    >
                        <div className={styles.columnHeading}>Utility Name</div>
                    </Tooltip>
                );
            },
            dataIndex: "target_name",
            key: "target_name",
            render: (target_name) => (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={target_name}
                />
            ),
            sorter: (a, b) => {
                return a.target_name > b.target_name ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "target_name" && sortedInfo.order,
            filters: _.sortedUniqBy(getFilters("target_name"), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.target_name || null,
            onFilter: (value, record) => record.target_name.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="What U.S. state or Canadian province the
                        Owner is associated with"
                    >
                        <div className={styles.columnHeading}>State</div>
                    </Tooltip>
                );
            },
            dataIndex: "target_state",
            key: "target_state",
            render: (target_state) => {
                return target_state
                    ? target_state.map((state) => (
                          <ColumnData
                              searchTextValue={searchTextValue}
                              fieldValue={state}
                          />
                      ))
                    : "-";
            },
            sorter: (a, b) => {
                let target_state_a = a.target_state.reduce(
                    (combined_str, final) => (combined_str = combined_str + final),
                    ""
                );

                let target_state_b = b.target_state.reduce(
                    (combined_str, final) => (combined_str = combined_str + final),
                    ""
                );

                return target_state_a > target_state_b ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "target_state" && sortedInfo.order,
            filters: _.sortedUniqBy(stateFilters(), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.target_state || null,
            onFilter: (value, record) => record.target_state.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="If the Owner is associated with a Drinking
                        Water System in our database, this shows the
                        value we have for Population Served for that
                        Drinking Water System. It will say 0 if the
                        Owner is not associated to any Drinking Water
                        System in our database."
                    >
                        <div className={styles.columnHeading}>
                            Drinking Water Population Served
                        </div>
                    </Tooltip>
                );
            },
            dataIndex: "population",
            key: "population",
            sorter: (a, b) => {
                return a.population > b.population ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "population" && sortedInfo.order,
            ...RangeFilterProps(
                "population",
                getMinValue("population"),
                getMaxValue("population"),
                populationMinValue,
                populationMaxValue,
                populationKeys,
                setPopulationMinValue,
                setPopulationMaxValue,
                setPopulationKeys,
                searchTextValue,
                filteredInfo,
                handleMultiSelectFilterReset
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Shows the population of the municipality.
                        This comes from the Demographic that's associated
                        with the Owner. The source of this is from the
                        US/Canadian Census."
                    >
                        <div className={styles.columnHeading}>Municipal Population</div>
                    </Tooltip>
                );
            },
            dataIndex: "municipal_population",
            key: "municipal_population",
            sorter: (a, b) => {
                return a.municipal_population > b.municipal_population ? 1 : -1;
            },
            sortOrder:
                sortedInfo.columnKey === "municipal_population" && sortedInfo.order,
            ...RangeFilterProps(
                "municipal_population",
                getMinValue("municipal_population"),
                getMaxValue("municipal_population"),
                municipalPopulationMinValue,
                municipalPopulationMaxValue,
                municipalPopulationKeys,
                setMunicipalPopulationMinValue,
                setMunicipalPopulationMaxValue,
                setMunicipalPopulationKeys,
                searchTextValue,
                filteredInfo,
                handleMultiSelectFilterReset
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="If the Owner is associated with a Wastewater
                        Facility in our database, this shows the value we
                        have stored in the Design Flow for that Wastewater Facility.
                        It shows 0 if the Owner is not associated to any Wastewater
                        Facility or if the Wastewater Facility is missing a value
                        in the Design Flow column."
                    >
                        <div>Facility MGD</div>
                    </Tooltip>
                );
            },
            dataIndex: "facility_size",
            key: "facility_size",
            sorter: (a, b) => {
                return a.facility_size > b.facility_size ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "facility_size" && sortedInfo.order,
            ...RangeFilterProps(
                "facility_size",
                getMinValue("facility_size"),
                getMaxValue("facility_size"),
                mgdMinValue,
                mgdMaxValue,
                mgdKeys,
                setMgdMinValue,
                setMgdMaxValue,
                setMgdKeys,
                searchTextValue,
                filteredInfo,
                handleMultiSelectFilterReset
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Comes from the Drinking Water Connection that's
                        associated with the Drinking Water System that's associated
                        with the Owner. The source of this is from EPA data."
                    >
                        <div className={styles.columnHeading}>Connection Count</div>
                    </Tooltip>
                );
            },
            dataIndex: "connection_count",
            key: "connection_count",
            render: (connection_count) => (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={connection_count}
                />
            ),
            sorter: (a, b) => {
                return a.connection_count > b.connection_count ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "connection_count" && sortedInfo.order,
            ...RangeFilterProps(
                "connection_count",
                getMinValue("connection_count"),
                getMaxValue("connection_count"),
                connectionCountMinValue,
                connectionCountMaxValue,
                connectionCountKeys,
                setConnectionCountMinValue,
                setConnectionCountMaxValue,
                setConnectionCountKeys,
                searchTextValue,
                filteredInfo,
                handleMultiSelectFilterReset
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Shows which of the ElasticSearch queries in the
                        batch of queries you submitted yielded this result"
                    >
                        <div className={styles.columnHeading}>Query Searched</div>
                    </Tooltip>
                );
            },
            dataIndex: "es_query",
            key: "es_query",
            render: (es_query) => (
                <ColumnData searchTextValue={searchTextValue} fieldValue={es_query} />
            ),
            sorter: (a, b) => {
                return a.es_query > b.es_query ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "es_query" && sortedInfo.order,
            filters: _.sortedUniqBy(getFilters("es_query"), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.es_query || null,
            onFilter: (value, record) => record.es_query.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Shows the date our crawler first found this doc.
                        If you searched against old ES, it shows the date
                        the old crawler first found the doc. If you searched
                        against new ES, it shows the date autobahn first found the doc"
                    >
                        <div className={styles.columnHeading}>Date Found</div>
                    </Tooltip>
                );
            },
            dataIndex: "created_date",
            key: "created_date",
            render: (created_date) => (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={created_date}
                />
            ),
            sorter: (a, b) => {
                return a.created_date > b.created_date ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "created_date" && sortedInfo.order,
            ...DateFilterProps(
                "created_date",
                searchTextValue,
                filteredInfo,
                createdDateRange,
                setCreatedDateRange,
                createdDateRangeKeys,
                setCreatedDateRangeKeys
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="This is a link to the source document online"
                    >
                        <div className={styles.columnHeading}>URL</div>
                    </Tooltip>
                );
            },
            dataIndex: "url",
            key: "url",
            render: (url) => {
                return (
                    <div className={styles.expandableRowContent}>
                        <div>
                            <Link
                                color="primary"
                                onClick={() => openNewExternalLink(url)}
                                className={styles.hoverable}
                            >
                                Source File
                            </Link>
                        </div>
                        <div item>
                            <IconButton
                                aria-label="copy"
                                style={{ padding: "10px" }}
                                onClick={() => {
                                    displaySuccessSnack({
                                        message: "Copied source file url to clipboard",
                                    });
                                    navigator.clipboard &&
                                        navigator.clipboard.writeText(url);
                                }}
                            >
                                <FileCopyIcon className={styles.copyButton} />
                            </IconButton>
                        </div>
                    </div>
                );
            },
            width: "140px",
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="In case the Owner took the doc offline since
                        we found it, we have the ability to generate a static
                        link to the copy of the doc we stored in our data warehouse
                        so you can still include it as a source in a customer report"
                    >
                        <div className={styles.columnHeading}>Generate Static Link</div>
                    </Tooltip>
                );
            },
            dataIndex: "storage_uri",
            key: "storage_uri",
            render: (storage_uri) => {
                if (!storage_uri) return <Typography>-</Typography>;
                return (
                    <Link
                        color="primary"
                        onClick={() => {
                            const decomposedUri = storage_uri.slice(5).split("/");
                            const bucket = decomposedUri[0];
                            const path = decomposedUri.slice(1).join("/");
                            generateStaticLink({
                                bucket,
                                path,
                            });
                            toggleOverlay({
                                overlay: overlayNames.staticLinkModal,
                            });
                        }}
                        className={styles.hoverable}
                        data-testid={`static-link-0`}
                    >
                        Static Link
                    </Link>
                );
            },
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="When searching against new ES, this shows the
                        Last Modified Date autobahn extracted from the HTTP
                        header of the document. Note that this is not always
                        the same as the Last Modified Date from the document
                        metadata. The HTTP header last modified date is when
                        the document was uploaded to the web whereas the
                        document metadata last modified date is when a person
                        last modified the contents of the file. These are
                        occasionally the same if someone edits a document
                        and uploads it to the web the same day they edited
                        it but they can also be different."
                    >
                        <div className={styles.columnHeading}>Last Modified Date</div>
                    </Tooltip>
                );
            },
            dataIndex: "last_modified",
            key: "last_modified",
            render: (last_modified) => {
                return (
                    <ColumnData
                        searchTextValue={searchTextValue}
                        fieldValue={last_modified}
                    />
                );
            },
            sorter: (a, b) => {
                return a.last_modified > b.last_modified ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "last_modified" && sortedInfo.order,
            ...DateFilterProps(
                "last_modified",
                searchTextValue,
                filteredInfo,
                lastModifiedRange,
                setLastModifiedRange,
                lastModifiedRangeKeys,
                setLastModifiedRangeKeys
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="We have automation that tries to get a date from
                        the URL/filename of the document if possible.If it
                        detects a date in there, this date is shown in this field.
                        Sometimes it just detects a month or year in which case you
                        might see a value here like: 2020-?-?. Sometimes there is no
                        date detected in the URL/filename and then this is blank.Note
                        that it doesn’t look at any of the content of the document."
                    >
                        <div className={styles.columnHeading}>Extracted Date</div>
                    </Tooltip>
                );
            },
            dataIndex: "extracted_date",
            key: "extracted_date",
            render: (extracted_date) => (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={extracted_date}
                />
            ),
            sorter: (a, b) => {
                return a.extracted_date > b.extracted_date ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "extracted_date" && sortedInfo.order,
            ...DateFilterProps(
                "extracted_date",
                searchTextValue,
                filteredInfo,
                extractedDateRange,
                setExtractedDateRange,
                extractedDateRangeKeys,
                setExtractedDateRangeKeys
            ),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="In Bounds if the Extracted Date is between the From
                        and To date of your query. Future if the Extracted Date is
                        after the To date of your query. Outdated if the Extracted
                        Date is before the From date of your query. Unknown if
                        Extracted Date is blank. In practice, filtering out Outdated
                        results is a good noise reduction measure that doesn’t lose
                        many, if any, targets but greatly reduces the number of results
                        to look through."
                    >
                        <div className={styles.columnHeading}>
                            Within Search Date Range
                        </div>
                    </Tooltip>
                );
            },
            dataIndex: "extracted_date_inbound_search_date",
            key: "extracted_date_inbound_search_date",
            render: (extracted_date_inbound_search_date) => (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={extracted_date_inbound_search_date}
                />
            ),
            sorter: (a, b) => {
                return a.extracted_date_inbound_search_date >
                    b.extracted_date_inbound_search_date
                    ? 1
                    : -1;
            },
            sortOrder:
                sortedInfo.columnKey === "extracted_date_inbound_search_date" &&
                sortedInfo.order,
            filters: _.sortedUniqBy(
                getFilters("extracted_date_inbound_search_date"),
                "text"
            ),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.extracted_date_inbound_search_date || null,
            onFilter: (value, record) =>
                record.extracted_date_inbound_search_date.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="Blank when searching old ES but, when searching
                        new ES, this either shows CIP (if the doc matches
                        something found by our CIP pipeline) or Unknown otherwise."
                    >
                        <div className={styles.columnHeading}>Document Type</div>
                    </Tooltip>
                );
            },
            dataIndex: "cip_classification_code",
            key: "cip_classification_code",
            render: (cip_classification_code) => {
                return (
                    <ColumnData
                        searchTextValue={searchTextValue}
                        fieldValue={cip_classification_code}
                    />
                );
            },
            sorter: (a, b) => {
                return a.cip_classification_code > b.cip_classification_code ? 1 : -1;
            },
            sortOrder:
                sortedInfo.columnKey === "cip_classification_code" && sortedInfo.order,
            filters: _.sortedUniqBy(getFilters("cip_classification_code"), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.cip_classification_code || null,
            onFilter: (value, record) => record.cip_classification_code.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="The county tags associated with the website the
                        source document comes from."
                    >
                        <div className={styles.columnHeading}>Counties</div>
                    </Tooltip>
                );
            },
            dataIndex: "tags",
            key: "tags",
            render: (tags) => {
                return tags
                    ? tags.map((tag) => (
                          <ColumnData
                              searchTextValue={searchTextValue}
                              fieldValue={tag}
                          />
                      ))
                    : "-";
            },
            sorter: (a, b) => {
                let tag_a = a.tags.reduce(
                    (combined_str, final) => (combined_str = combined_str + final),
                    ""
                );

                let tag_b = b.tags.reduce(
                    (combined_str, final) => (combined_str = combined_str + final),
                    ""
                );

                return tag_a > tag_b ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "tags" && sortedInfo.order,
            filters: _.sortedUniqBy(tagFilters(), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.tags || null,
            onFilter: (value, record) => record.tags.includes(value),
        },
        {
            title: () => {
                return (
                    <Tooltip
                        placement="bottom"
                        title="The name of the Index in ElasticSearch
                        that the document was stored in. This can be
                        helpful for development team troubleshooting."
                    >
                        <div className={styles.columnHeading}>ES Index</div>
                    </Tooltip>
                );
            },
            dataIndex: "es_index",
            key: "es_index",
            render: (es_index) => (
                <ColumnData searchTextValue={searchTextValue} fieldValue={es_index} />
            ),
            sorter: (a, b) => {
                return a.es_index > b.es_index ? 1 : -1;
            },
            sortOrder: sortedInfo.columnKey === "es_index" && sortedInfo.order,
            filters: _.sortedUniqBy(getFilters("es_index"), "text"),
            filterSearch: true,
            filterMode: "tree",
            filteredValue: filteredInfo.es_index || null,
            onFilter: (value, record) => record.es_index.includes(value),
        },
    ];

    const search = (value) => {
        const filterTable =
            tableData.length > 0 &&
            tableData.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(value.toLowerCase())
                )
            );
        setSearchTextValue(value);
        setFilterTable(filterTable);
    };

    return (
        <Grid container justifyContent="center">
            <TableHeader
                handleExpandRows={handleExpandRows}
                handleCollapseRows={handleCollapseRows}
                numResults={results.length}
                setSelectedSearchRecord={setSelectedSearchRecord}
                toggleOverlay={toggleOverlay}
                unique_utilities_count={unique_utilities_count}
            />

            <Grid className={styles.table}>
                {numPendingQueries > 0 ? (
                    <>
                        <LinearProgress
                            variant="determinate"
                            value={parseInt(
                                ((enqueuedCount - numPendingQueries) / enqueuedCount) *
                                    100
                            )}
                        />
                        <Grid
                            alignItems="center"
                            className={styles.loadingInfo}
                            container
                            direction="column"
                            justifyContent="center"
                        >
                            <Typography style={{ marginTop: "10px" }}>
                                {loadingText}
                            </Typography>
                            <Typography>{`Pending queries: ${numPendingQueries}`}</Typography>
                            <CircularProgress />
                        </Grid>
                    </>
                ) : null}
                <Space style={{ marginBottom: 16 }}>
                    <Button onClick={clearFilters}>Clear filters</Button>
                    <Button onClick={clearSorters}>Clear Sorters</Button>
                </Space>
                <a
                    style={{ float: "right", marginLeft: 5 }}
                    href={URL.createObjectURL(
                        new Blob([exportData], { type: "application/octet-stream" })
                    )}
                    download={exportCSVFileName}
                >
                    <Button icon={<DownloadOutlined />}>Download CSV</Button>
                </a>
                <Button onClick={clearSearch} style={{ float: "right", marginLeft: 5 }}>
                    Clear Search
                </Button>
                <Input.Search
                    style={{ margin: "0 0 10px 0", width: 304, float: "right" }}
                    placeholder="Search by..."
                    enterButton
                    allowClear
                    onSearch={search}
                />
                <Table
                    columns={columns}
                    data-testid={"table-info"}
                    dataSource={filterTable == null ? tableData : filterTable}
                    onChange={handleTableChange}
                    tableLayout={"auto"}
                    pagination={{
                        showQuickJumper: true,
                        pageSizeOptions: [5, 10, 20, 50, 100, results.length],
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    expandable={{
                        expandedRowRender: (rowData) => {
                            return (
                                <div data-testid={"table-detail-info"}>
                                    <TableDetailPanel
                                        rowData={rowData}
                                        searchTextValue={searchTextValue}
                                    />
                                </div>
                            );
                        },
                        expandedRowKeys: expandedRows,
                        onExpand: (expanded, rowData) => {
                            if (expanded) {
                                expandedRows.push(rowData.key);
                            } else {
                                setExpandedRows(
                                    expandedRows.filter((key) => {
                                        return key !== rowData.key;
                                    })
                                );
                            }
                        },
                    }}
                    scroll={{ x: true }}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        enqueuedCount: enqueuedCountSelector(state),
        numPendingQueries: Object.keys(pendingQueriesSelector(state)).length,
        results: resultsSelector(state),
        unique_utilities_count: uniqueUtilitiesCountSelector(state),
    };
};

const ConnectedTargetSurfacerResultsTable = connect(mapStateToProps, {
    displaySuccessSnack,
    generateStaticLink,
    setSelectedSearchRecord,
    toggleOverlay,
})(UnconnectedTargetSurfacerResultsTable);

export default ConnectedTargetSurfacerResultsTable;
