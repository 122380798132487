import PDFImage from "components/atoms/PDFImage";
import PDFLink from "components/atoms/PDFLink";
import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import externalLinkIcon from "icons/external_link_icon.png";
import { createLink } from "utils/createLink";

import { pdfStyle } from "../pdfStyle";

const RenderSource = (source) => {
    const linkURL = createLink({
        url: window.location.href,
        query_param_obj: {
            ...(source.page_number ? { page: source.page_number } : {}),
            link: source.url,
        },
    });

    return (
        <PDFView style={pdfStyle.sourceContainer} key={source.id}>
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle} />
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFView style={pdfStyle.itemSeparator} />
                </PDFView>
            </PDFView>
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Source</PDFText>
                </PDFView>
                <PDFView
                    style={[
                        pdfStyle.insightItemValue,
                        { display: "flex", flexDirection: "row" },
                    ]}
                >
                    <PDFText>
                        <PDFLink
                            src={`${source.url}${
                                source.page_number ? "#page=" + source.page_number : ""
                            }`}
                        >
                            {source.title}
                        </PDFLink>
                    </PDFText>

                    <PDFText style={{ paddingLeft: 5 }}>
                        <PDFLink style={pdfStyle.externalLinkIcon} src={linkURL}>
                            <PDFImage src={externalLinkIcon} />
                        </PDFLink>
                    </PDFText>
                </PDFView>
            </PDFView>
            {source.extract ? (
                <PDFView style={pdfStyle.insightItemContainer}>
                    <PDFView style={pdfStyle.insightItemTitle}>
                        <PDFText>Trigger Extract</PDFText>
                    </PDFView>
                    <PDFView style={pdfStyle.insightItemValue}>
                        <PDFText>{source.extract}</PDFText>
                    </PDFView>
                </PDFView>
            ) : null}
            {source.date ? (
                <PDFView style={pdfStyle.insightItemContainer}>
                    <PDFView style={pdfStyle.insightItemTitle}>
                        <PDFText>Source Date</PDFText>
                    </PDFView>
                    <PDFView style={pdfStyle.insightItemValue}>
                        <PDFText>
                            {formatDayHyphenShortenedMonthHyphenYear(source.date)}
                        </PDFText>
                    </PDFView>
                </PDFView>
            ) : null}
        </PDFView>
    );
};

export default RenderSource;
