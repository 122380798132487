export const getNestedValue = (obj, path) => {
    const objKeys = path.split(".");
    let currentValue = obj;

    for (const key of objKeys) {
        if (!currentValue[key]) return undefined;
        currentValue = currentValue[key];
    }

    return currentValue;
};
