import { useQuery } from "@tanstack/react-query";

import { FEED_TYPES } from "constants/feedTypes";
import { nautilusGetRequest } from "network";
import { tableFiltersKeys, tableOpportunityKeys, tableDataKeys } from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { PaginatedAllInsightsPageInsightsList } from "../apiTypes";

const feedType = FEED_TYPES.ALL_INSIGHTS;

async function getAllInsights(
    queryParams?: string,
    urlAction: string = ""
): Promise<PaginatedAllInsightsPageInsightsList> {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/all_insights/${urlAction}`,
        queryParams
    );
    return data;
}

export function useGetAllInsights({ enabled = true }: { enabled?: boolean }) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
    });
    return useQuery({
        queryKey: tableDataKeys.createKey(feedType, queryParams),
        queryFn: (): Promise<PaginatedAllInsightsPageInsightsList> =>
            getAllInsights(queryParams),
        enabled,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
}

export function useGetAllInsightsFilters({
    team_id,
    enabled = true,
}: {
    team_id?: number;
    enabled?: boolean;
}) {
    return useQuery({
        queryKey: tableFiltersKeys.allInsights(team_id),
        queryFn: async () => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/all_insights/${team_id}/filters/`
            );
            return data;
        },
        enabled,
    });
}

export function useGetAllInsightsOpportunities({
    team_id,
    enabled = true,
}: {
    team_id?: number;
    enabled?: boolean;
}) {
    const queryParams = queryParamBuilder({ includeBaseParams: true });
    return useQuery({
        queryKey: [tableOpportunityKeys.allInsightsOpportunities(team_id), queryParams],
        queryFn: (): Promise<number[]> =>
            // @ts-ignore
            getAllInsights(queryParams, "opportunities/"),
        enabled,
    });
}
