import { Form, Select } from "antd";
import { useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { DemographicInInsight } from "reactQuery/hooks/apiTypes";
import { useGetCountyDemographicsByQuery } from "reactQuery/hooks/useCounties";

import { RecordProps } from "../columnInfo";

const CountiesSelect = ({ constraintId, constraintName, number }: RecordProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryMinChars = 1;
    const enterQueryPrompt = `Enter at least ${queryMinChars} characters to search for a county`;

    const countiesQueryEnabled = searchQuery.length >= queryMinChars;
    const countiesQuery = useGetCountyDemographicsByQuery(
        searchQuery,
        countiesQueryEnabled
    );
    const debounceSearch = useSearchDebounced(setSearchQuery);

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[
                constraintName,
                `constraint_id_${constraintId}`,
                `number_${number}`,
                "demographics",
            ]}
        >
            <Select
                placeholder="Select one or more counties"
                showSearch
                filterOption={false}
                allowClear={true}
                mode="multiple"
                style={{ width: "100%" }}
                onSearch={(value) => {
                    debounceSearch(value);
                }}
                onClear={() => {
                    debounceSearch("");
                }}
            >
                {!countiesQueryEnabled ? (
                    <Select.Option value="disabled" disabled>
                        {enterQueryPrompt}
                    </Select.Option>
                ) : countiesQuery.isSuccess ? (
                    (countiesQuery.data || []).map((county: DemographicInInsight) => {
                        const countyName = `${county.name} (${county.state_code})`;

                        return (
                            <Select.Option
                                key={county.id}
                                value={county.id}
                                title={countyName}
                            >
                                {countyName}
                            </Select.Option>
                        );
                    })
                ) : countiesQuery.isError ? (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading county options
                    </Select.Option>
                ) : (
                    <Select.Option value="disabled" disabled>
                        Loading counties...
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default CountiesSelect;
