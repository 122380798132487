import "antd/dist/antd.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { FEED_TYPES } from "constants/feedTypes";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";

import {
    fetchReports,
    setActiveReport,
    setActiveTeam,
    specificReportFromMatchPropsSelector,
} from "../../stores/reports/reportSlice";
import { selectIdFromUrl } from "../../stores/shared/browserSelectors";
interface ReportType {
    id: number;
    date: string;
    notes?: string;
    status: string;
    team: {
        id: number;
        name: string;
        whid: string;
    };
    [propName: string]: any;
}

export interface UnconnectedReportDetailsPageProps {
    feedType: string;
    fetchReports: Function;
    idFromURL: number;
    specificReport: ReportType;
    teamIdFromUrl: number;
}

export const UnconnectedOldReportDetailsPage = ({
    feedType,
    fetchReports,
    idFromURL,
    specificReport,
    teamIdFromUrl,
}: UnconnectedReportDetailsPageProps) => {
    const teamId =
        feedType === FEED_TYPES.SINGLE_REPORT
            ? specificReport?.team?.id
            : feedType === FEED_TYPES.ALL_INSIGHTS
            ? teamIdFromUrl
            : null;

    const { push } = useHistory();

    useEffect(() => {
        if (teamId)
            push(
                `${IntelligenceReportsSingleReportPathname}?team_id=${teamId}&report_id=${idFromURL}`
            );
    }, [teamId]);

    useEffect(() => {
        if (feedType === FEED_TYPES.SINGLE_REPORT && !specificReport) {
            fetchReports({ reportId: idFromURL });
        }
    }, [feedType, fetchReports, idFromURL, specificReport]);
    return null;
};

const mapStateToProps = (state: any, props: any) => {
    const feedType = props.location.pathname.includes("/reports/feed/")
        ? FEED_TYPES.INDICATOR_FEED
        : props.location.pathname.includes("/reports/")
        ? FEED_TYPES.SINGLE_REPORT
        : props.location.pathname.includes("/opportunities")
        ? FEED_TYPES.ALL_INSIGHTS
        : null;

    const teamIdParam = new URLSearchParams(props.location.search).get("teamId");
    const teamIdFromUrl = teamIdParam ? parseInt(teamIdParam) : null;

    if (feedType === FEED_TYPES.ALL_INSIGHTS && !teamIdFromUrl) {
        props.history.push("/");
    }

    return {
        feedType: feedType,
        idFromURL: selectIdFromUrl(state, props),
        // @ts-ignore
        specificReport: specificReportFromMatchPropsSelector(state, props),
        teamIdFromUrl: teamIdFromUrl,
    };
};

const ConnectedOldReportDetailsPage = connect(mapStateToProps, {
    fetchReports,
    setActiveReport,
    setActiveTeam,
})(UnconnectedOldReportDetailsPage);

export default ConnectedOldReportDetailsPage;
