import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import StyledButton from "components/atoms/StyledButton";

const cardStyle = makeStyles((theme) => ({
    content: {
        minHeight: "75px",
    },
}));

const HomepageCard = ({ description, href, title }) => {
    const classes = cardStyle();

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card raised={true}>
                <CardHeader title={title} style={{ textAlign: "left" }} />
                <CardContent className={classes.content}>
                    <Typography variant="body2" color="textPrimary" component="p">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <StyledButton
                            variant="add-primary"
                            data-testid={href}
                            href={href}
                        >
                            Visit
                        </StyledButton>
                    </div>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default HomepageCard;
