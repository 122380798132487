import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { opportunityFeedbackUsersKeys } from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

export interface FeedbackUsersType {
    [key: string]: string[];
}

export function useGetFeedbackUsers({
    opportunityId,
    teamId,
    enabled = false,
}: {
    opportunityId: number;
    teamId: number;
    enabled?: boolean;
}): UseQueryResult<FeedbackUsersType> {
    const queryParams = queryParamBuilder({ includeBaseParams: true });
    const queryKey = opportunityFeedbackUsersKeys.single(opportunityId, queryParams);
    return useQuery({
        queryKey: queryKey,
        queryFn: async (): Promise<FeedbackUsersType> => {
            const queryString = teamId ? `?team_id=${teamId}` : "";
            const { data } = await nautilusGetRequest(
                `/api/target_reports/report_opportunities/${opportunityId}/retrieve_feedback_users/`,
                queryString
            );
            return data?.feedback;
        },
        enabled,
    });
}
