import { useLocation } from "react-router-dom";

import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { useGetOwnerById } from "reactQuery/hooks/useOwners";
import { AllOwnersSingleOwnerPathname } from "routes/IntelligenceFeeds";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";
import { allOwnersBreadcrumbs } from "../AllOwners";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    let ownerIdString = new URLSearchParams(search).get("owner_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;
    const ownerId = ownerIdString ? Number(ownerIdString) : undefined;

    const ownerQuery = useGetOwnerById(ownerId, !!ownerId);
    const owner = ownerQuery.data || null;
    const ownerName = owner
        ? `${owner.legal_name}${
              owner.demographics?.[0]?.state_code
                  ? `, ${owner.demographics[0].state_code}`
                  : ""
          }`
        : undefined;

    return { teamId, ownerId, ownerName };
};

export const singleOwnerBreadcrumbs = () => {
    const { ownerId, ownerName } = pageParams();

    const queryParams = queryParamBuilder({
        excludeParams: [
            "feedback",
            "indicator_id",
            "opportunity_id",
            "owner_id",
            "user_id",
        ],
        includeBaseParams: true,
        includeExistingParams: false,
        startChar: "&",
    });

    const breadcrumbs = allOwnersBreadcrumbs().concat({
        name: ownerName,
        url: `${AllOwnersSingleOwnerPathname}?owner_id=${ownerId}${queryParams}`,
    });

    return breadcrumbs;
};

const SingleOwner = () => {
    const { teamId, ownerName } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title={ownerName}
                    breadcrumbs={singleOwnerBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.SINGLE_OWNER}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default SingleOwner;
