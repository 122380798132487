// TODO: rename modal to "AddFacilityModal" and make necessary changes to other files
// TODO: change wasteWaterFacility variables to facility
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { StyledButton } from "components/atoms";
import AutocompleteDropdownLabelAndValue from "components/molecules/AutocompleteDropdownLabelAndValue";
import FormSearchField from "components/molecules/FormSearchField";
import { FormModal } from "components/molecules/Modal";
import {
    InsightInOpportunity,
    ShallowWastewaterFacilityRead,
} from "reactQuery/hooks/apiTypes";
import { isUpdatingInsightSelector, patchInsight } from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import {
    clearWasteWaterFacilitySearchResults,
    searchForWasteWaterFacility,
} from "stores/universeStore/actionTypes";
import {
    wasteWaterFacilitySearchIsSearchingSelector,
    wasteWaterFacilitySearchResultsSelector,
} from "stores/universeStore/selectors";
import {
    insightToCreateWastewaterFacilityLinkageSelector,
    selectWastewaterFacilitiesForActiveOpportunity,
} from "stores/wastewaterFacilities/selectors";

const formStyle = makeStyles((theme) => ({
    separatingLine: {
        border: "solid 1px #d6d7d9",
        height: "1px",
        width: "100%",
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

export const ERROR_MESSAGES = {
    selectedFacilityMissing: "A facility is required.",
};

const AddFacilitySchema = Yup.object().shape({
    selectedWastewaterFacility: Yup.object()
        .nullable()
        .required(ERROR_MESSAGES.selectedFacilityMissing),
});

export const ADD_FACILITY_LINKAGE_MODAL_TITLE = "Add Facility";
export const UnconnectedAddWastewaterFacilityModal = ({
    clearWasteWaterFacilitySearchResults,
    isShowingNewWastewaterFacilityLinkageModal,
    isLoading,
    isSearching,
    linkedWastewaterFacilities,
    searchForWasteWaterFacility,
    toggleOverlay,
    errors,
    wasteWaterFacilitySearchResults,
    ...formikProps
}) => {
    const formattedLinkedWasteWaterFacilities = linkedWastewaterFacilities.map((item) =>
        item.design_flow
            ? { ...item, design_flow: parseFloat(item.design_flow).toFixed(2) }
            : item
    );
    const styles = formStyle();

    return isShowingNewWastewaterFacilityLinkageModal ? (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.addWasteWaterFacilityModal });
                clearWasteWaterFacilitySearchResults();
                formikProps.resetForm();
            }}
            handleSubmit={formikProps.handleSubmit}
            hasSecondaryButton={false}
            isVisible={isShowingNewWastewaterFacilityLinkageModal}
            isSubmitDisabled={isLoading}
            isSubmitLoading={isLoading}
            title={ADD_FACILITY_LINKAGE_MODAL_TITLE}
            isRemoveModeActive={false}
            isEditModeActive={false}
        >
            <FormSearchField
                name="selectedWastewaterFacility"
                label="Facility"
                isFetchingResults={isSearching}
                value={formikProps.values.selectedWastewaterFacility}
                getOptionLabel={(option) => option?.facility_name || ""}
                initialOptions={formattedLinkedWasteWaterFacilities}
                onChange={(e, v) => {
                    formikProps.setFieldValue("selectedWastewaterFacility", v ? v : "");
                }}
                onInputDelayComplete={(searchText) => {
                    searchForWasteWaterFacility(searchText);
                }}
                errors={errors}
                allOptions={wasteWaterFacilitySearchResults || []}
                noOptions={
                    <StyledButton
                        variant="admin-link"
                        url="admin/universe/facility/add/"
                        target="_blank"
                    >
                        Add New Facility
                    </StyledButton>
                }
                renderOption={(option) => {
                    return (
                        <Grid
                            container
                            spacing={3}
                            data-testid={`facility-${option.id}`}
                        >
                            <AutocompleteDropdownLabelAndValue
                                label="Facility"
                                value={option.facility_name}
                            />
                            <AutocompleteDropdownLabelAndValue
                                label="MGD"
                                value={
                                    option.design_flow
                                        ? parseFloat(option.design_flow).toFixed(2)
                                        : "Unknown"
                                }
                            />
                            <div className={styles.separatingLine} />
                        </Grid>
                    );
                }}
            />
        </FormModal>
    ) : null;
};

export interface EnhancedProps {
    clearWasteWaterFacilitySearchResults: any;
    insight: InsightInOpportunity;
    patchInsight: any;
}
export interface FormProps {
    selectedWastewaterFacility: any;
}

export const EnhancedAddWastewaterFacilityModal = withFormik<EnhancedProps, FormProps>({
    handleSubmit: (
        form,
        {
            props: { clearWasteWaterFacilitySearchResults, patchInsight, insight },
            resetForm,
        }
    ) => {
        const currentFacilityIds = insight["facilities"].map((facility) => facility.id);
        patchInsight({
            insightId: insight.id,
            facilities: [...currentFacilityIds, form.selectedWastewaterFacility.id],
        });
        clearWasteWaterFacilitySearchResults();
        resetForm();
    },
    mapPropsToValues: () => ({
        selectedWastewaterFacility: null,
    }),
    validationSchema: () => AddFacilitySchema,
    validateOnBlur: false,
    validateOnChange: false,
})(UnconnectedAddWastewaterFacilityModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isShowingNewWastewaterFacilityLinkageModal:
            isShowing.addWasteWaterFacilityModal,
        insight: insightToCreateWastewaterFacilityLinkageSelector(state as never),
        isLoading: isUpdatingInsightSelector(state as never),
        isSearching: wasteWaterFacilitySearchIsSearchingSelector(state as never),
        linkedWastewaterFacilities:
            selectWastewaterFacilitiesForActiveOpportunity(state),
        wasteWaterFacilitySearchResults: wasteWaterFacilitySearchResultsSelector(
            state as never
        ),
    };
};

export const ConnectedAddWastewaterFacilityModal = connect(mapStateToProps, {
    clearWasteWaterFacilitySearchResults,
    searchForWasteWaterFacility,
    patchInsight,
    toggleOverlay: toggleOverlay,
})(EnhancedAddWastewaterFacilityModal);

export default ConnectedAddWastewaterFacilityModal;
