import { push } from "connected-react-router";
import ReactGA from "react-ga";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { ERROR_MESSAGES as CHANGE_PASSWORD_ERROR_MESSAGES } from "components/nautilus/PasswordChangeForm";
import { displayErrorSnack, displaySuccessSnack } from "stores/uiStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import { extractNextUrlParameter } from "utils/redirect";

import actionTypes, {
    analyticsEvent,
    analyticsPage,
    fetchProfilesFailed,
    fetchTeamByIdFailed,
    fetchTeamsFailed,
    logInFailure,
    logInSuccess,
    logOutSuccess,
    passwordChangeFailure,
    passwordChangeSuccess,
    passwordResetFailure,
    passwordResetSuccess,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    setIsAuthenticated,
    setProfilesData,
    setTeamsData,
    setUserData,
    signupLinkVerificationFailure,
    signupLinkVerificationSuccess,
} from "./actionTypes";
import {
    loginRedirect,
    nautilusGetRequest,
    nautilusPostRequest,
    refreshConfig,
} from "../../network";

export function requestUserData() {
    return nautilusGetRequest("/api/user_data/whoami/");
}

function* yieldUserData(action) {
    try {
        const response = yield call(requestUserData);
        yield put(setUserData(response.data));
    } catch (e) {
        if (e.response && e.response.status === 401 && action.payload.shouldRedirect) {
            loginRedirect();
        }
        yield put(setIsAuthenticated(false));
    }
}

export function* userDataSaga() {
    yield takeLatest(actionTypes.FETCH_USER_DATA, yieldUserData);
}

export function requestTeamByIdData(teamId) {
    return nautilusGetRequest(`/api/user_data/teams/${teamId}`);
}

function* yieldTeamByIdData(action) {
    try {
        const response = yield call(requestTeamByIdData, action.payload.teamId);
        yield put(setTeamsData([response.data]));
        yield put(setProfilesData(response.data.profiles));
    } catch (e) {
        yield put(fetchTeamByIdFailed());
    }
}

export function* teamByIdDataSaga() {
    yield takeLatest(actionTypes.FETCH_TEAM_BY_ID, yieldTeamByIdData);
}

export function requestTeamsData() {
    return nautilusGetRequest("/api/user_data/teams/");
}

function* yieldTeamsData(action) {
    try {
        const response = yield call(requestTeamsData);
        yield put(setTeamsData(response.data));
    } catch (e) {
        yield put(fetchTeamsFailed());
    }
}

export function* teamDataSaga() {
    yield takeLatest(actionTypes.FETCH_TEAMS_DATA, yieldTeamsData);
}

export function requestProfilesData() {
    return nautilusGetRequest("/api/user_data/users/");
}

function* yieldProfilesData(action) {
    try {
        const response = yield call(requestProfilesData);
        yield put(setProfilesData(response.data.results));
    } catch (e) {
        yield put(fetchProfilesFailed());
    }
}

export function* ProfileDataSaga() {
    yield takeLatest(actionTypes.FETCH_PROFILES_DATA, yieldProfilesData);
}

export function logInRequest(logInInformation) {
    return nautilusPostRequest("/api/rest-auth/login/", logInInformation);
}

function* yieldLogInAttempt(action) {
    try {
        yield put(
            analyticsEvent({
                action: eventActions.CLICKED,
                category: eventCategories.FORMS,
                label: "Sign in - Sign in",
            })
        );
        yield call(logInRequest, action.payload);
        yield put(logInSuccess());
        refreshConfig();
        yield put(push(extractNextUrlParameter()));
    } catch (e) {
        if (e.response.status === 400) {
            yield put(logInFailure({ providedInvalidCredentials: true }));
        } else if (e.response.status === 429) {
            yield put(
                logInFailure({
                    providedInvalidCredentials: false,
                    retryLoginAfterSeconds: parseInt(e.response.headers["retry-after"]),
                })
            );
        } else {
            yield put(logInFailure({ providedInvalidCredentials: false }));
        }
    }
}

export function* logInSaga() {
    yield takeLatest(actionTypes.LOG_IN, yieldLogInAttempt);
}

export function logOutRequest() {
    return nautilusPostRequest("/api/rest-auth/logout");
}

function* yieldLogOutAttempt(action) {
    try {
        yield put(
            analyticsEvent({
                action: eventActions.CLICKED,
                category: eventCategories.FORMS,
                label: "Sign out - Sign out",
            })
        );

        yield call(logOutRequest);
        yield put(logOutSuccess());
        yield put(push("/login"));
        yield put(
            displaySuccessSnack({
                message: "You have successfully logged out",
            })
        );
    } catch (e) {
        yield put(
            displayErrorSnack({
                message: "Log out failed, please try again",
            })
        );
    }
}

export function* logOutSaga() {
    yield takeLatest(actionTypes.LOG_OUT, yieldLogOutAttempt);
}

export function requestForPasswordReset(resetInformation) {
    return nautilusPostRequest("/api/rest-auth/password/reset/", resetInformation);
}

function* yieldRequestPasswordReset(action) {
    try {
        yield put(
            analyticsEvent({
                action: eventActions.CLICKED,
                category: eventCategories.FORMS,
                label: "Send Email - Sign in",
            })
        );
        yield call(requestForPasswordReset, action.payload);
        yield put(
            analyticsPage({
                path: "/forgot_password",
                title: "Forgot Password Email Sent",
            })
        );
        yield put(requestPasswordResetSuccess());
    } catch (e) {
        const isEmailError =
            e.response &&
            e.response.data &&
            e.response.data.email &&
            typeof e.response.data.email === "object" &&
            e.response.data.email[0] === "Enter a valid email address.";
        yield put(
            requestPasswordResetFailure({ isUnknownError: !Boolean(isEmailError) })
        );
    }
}

export function* requestPasswordResetSaga() {
    yield takeLatest(actionTypes.REQUEST_PASSWORD_RESET, yieldRequestPasswordReset);
}

export function requestPasswordChange(changeInformation) {
    return nautilusPostRequest("/api/rest-auth/password/change/", changeInformation);
}

function* yieldPasswordChangeRequest(action) {
    try {
        yield call(requestPasswordChange, action.payload);
        yield put(passwordChangeSuccess());
    } catch (e) {
        const errors = {
            ...("old_password" in e.response.data && {
                currentPassword:
                    CHANGE_PASSWORD_ERROR_MESSAGES.currentPasswordIncorrect,
            }),
            ...("new_password2" in e.response.data && {
                newPassword: CHANGE_PASSWORD_ERROR_MESSAGES.passwordRequirements,
            }),
        };
        yield put(passwordChangeFailure(errors));
    }
}

export function* passwordChangeRequestSaga() {
    yield takeLatest(actionTypes.PASSWORD_CHANGE_REQUEST, yieldPasswordChangeRequest);
}

export function passwordResetRequest(resetConfirmation) {
    return nautilusPostRequest(
        "/api/rest-auth/password/reset/confirm/",
        resetConfirmation
    );
}

function* yieldPasswordReset(action) {
    try {
        yield put(
            analyticsEvent({
                action: eventActions.CLICKED,
                category: eventCategories.FORMS,
                label: action.payload.ga_event_name,
            })
        );
        yield call(passwordResetRequest, action.payload);
        yield put(
            analyticsPage({
                path: "/password_reset",
                title: "Reset Password Success",
            })
        );
        yield put(passwordResetSuccess());
    } catch (e) {
        const data = e.response.data;
        const errors = {
            ...(("uid" in data || "token" in data) && {
                invalidSecret: true,
            }),
            ...("new_password2" in data && {
                newPassword: true,
            }),
        };
        yield put(passwordResetFailure(errors));
    }
}

export function* passwordResetConfirmRequestSaga(action) {
    yield takeLatest(actionTypes.PASSWORD_RESET, yieldPasswordReset);
}

function* yieldAnalyticsEvent(action) {
    try {
        yield call(ReactGA.event, {
            action: action.payload.action,
            category: action.payload.category,
            label: action.payload.label,
            value: action.payload.value,
        });
    } catch (e) {
    } finally {
        if (action.payload.executeOnceComplete) {
            action.payload.executeOnceComplete();
        }
    }
}

export function* analyticsEventSaga(action) {
    yield takeEvery(actionTypes.ANALYTICS_EVENT, yieldAnalyticsEvent);
}

function* yieldAnalyticsPage(action) {
    try {
        yield call(ReactGA.pageview, action.payload.path, [], action.payload.title);
    } catch (e) {}
}

export function* analyticsPageSaga(action) {
    yield takeEvery(actionTypes.ANALYTICS_PAGE, yieldAnalyticsPage);
}

export function signupLinkVerificationRequest(verificationConfirmation) {
    return nautilusPostRequest(
        "/api/rest-auth/signup-link/verification/",
        verificationConfirmation
    );
}

function* yieldSignupLinkVerification(action) {
    try {
        yield call(signupLinkVerificationRequest, action.payload);
        yield put(signupLinkVerificationSuccess());
    } catch (e) {
        const data = e.response.data;
        const errors = {
            ...(("uid" in data || "token" in data) && {
                invalidSecret: true,
            }),
            ...("password" in data && {
                passwordAlreadySet: true,
            }),
        };
        if (errors?.passwordAlreadySet && action.payload.shouldRedirect) {
            yield put(push("/login"));
            yield put(
                displayErrorSnack({
                    message: "Account already signed up, redirected to login page",
                })
            );
        }
        yield put(signupLinkVerificationFailure(errors));
    }
}

export function* signupLinkVerificationSaga(action) {
    yield takeLatest(actionTypes.SIGNUP_LINK_VERIFICATION, yieldSignupLinkVerification);
}
