import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import PDFTagsList from "components/molecules/PDFTagsList";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { InsightInOpportunity } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderDrinkingWaterSystems from "../RenderDrinkingWaterSystems";
import RenderFacility from "../RenderFacility";
import RenderSource from "../RenderSource";

const RenderInsight = (insight: InsightInOpportunity, isIntelligenceFeeds: boolean) => (
    <PDFView style={pdfStyle.insightContainer} key={insight.id}>
        {insight.display_date || insight.report?.date ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightDateTitle}>
                    <PDFText>
                        {formatDayHyphenShortenedMonthHyphenYear(
                            insight.display_date ?? insight.report.date
                        )}
                    </PDFText>
                </PDFView>
                {insight.status || insight.stage ? (
                    <PDFView style={pdfStyle.insightItemValue}>
                        <PDFText>
                            {[insight.status, insight.stage]
                                .filter(Boolean)
                                .join(" - ")}
                        </PDFText>
                    </PDFView>
                ) : null}
            </PDFView>
        ) : null}
        {insight.summary ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Trigger Summary</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>{insight.summary}</PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {insight.indicator_groups?.map((indicator_group, index) => (
            <PDFView style={pdfStyle.insightItemContainer} key={index}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>{`Indicator Group${
                        insight.indicator_groups.length > 1 ? ` ${index + 1}` : ""
                    }`}</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFTagsList
                        group={indicator_group}
                        indicatorType="indicatorGroup"
                    />
                </PDFView>
            </PDFView>
        ))}
        {!!insight.team_customer_tags?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Tags</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFTagsList
                        indicatorType="teamCustomerTags"
                        tags={insight.team_customer_tags.map((tag) => tag.name)}
                    />
                </PDFView>
            </PDFView>
        ) : null}
        {insight.demographics?.[0] ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Locations</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>{`${insight.demographics[0].name}${
                        insight.demographics[0].state_code
                            ? `, ${insight.demographics[0].state_code}`
                            : ""
                    }`}</PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {!!insight.facilities?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Facilities</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightMultiItemContainer}>
                    {insight.facilities.map((facility, index) => (
                        <PDFView
                            key={index}
                            style={{ marginTop: index === 0 ? 0 : 10 }}
                        >
                            {RenderFacility(facility)}
                        </PDFView>
                    ))}
                </PDFView>
            </PDFView>
        ) : null}
        {!!insight.drinking_water_systems?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Drinking Water Systems</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightMultiItemContainer}>
                    {insight.drinking_water_systems.map(
                        (drinking_water_system, index) => (
                            <PDFView
                                key={index}
                                style={{ marginTop: index === 0 ? 0 : 10 }}
                            >
                                {RenderDrinkingWaterSystems(drinking_water_system)}
                            </PDFView>
                        )
                    )}
                </PDFView>
            </PDFView>
        ) : null}
        {!!insight.insight_notes?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Intelligence Notes</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightMultiItemContainer}>
                    {insight.insight_notes.map((insight_note, index) => (
                        <PDFText
                            key={index}
                            style={{ marginTop: index === 0 ? 0 : 10 }}
                        >
                            {insight_note.notes}
                        </PDFText>
                    ))}
                </PDFView>
            </PDFView>
        ) : null}
        {isIntelligenceFeeds && !!insight.spd_consultants?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Consultants</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>
                        {insight.spd_consultants
                            .map((consultant) => consultant.name)
                            .join(", ")}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : !!insight.consultants?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Consultants</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>
                        {insight.consultants
                            .map((consultant) => consultant.name)
                            .join(", ")}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {isIntelligenceFeeds && !!insight.spd_competitors?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Competitors</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>
                        {insight.spd_competitors
                            .map((competitor) => competitor.name)
                            .join(", ")}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : !!insight.competitors?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Competitors</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFText>
                        {insight.competitors
                            .map((competitor) => competitor.name)
                            .join(", ")}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}
        {!!insight.sources?.length
            ? insight.sources.map((source) => RenderSource(source))
            : null}
    </PDFView>
);

export default RenderInsight;
