import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { profileKeys } from "reactQuery/keys";

import { Profile } from "./apiTypes";

export const useGetAllTeamProfiles = (
    teamId: number,
    enabled: boolean
): UseQueryResult<Profile[]> => {
    const queryKey = profileKeys.single(teamId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Profile[]> => {
            const response = await nautilusGetRequest(`/api/user_data/teams/${teamId}`);
            const profiles = response.data?.profiles;
            return profiles;
        },
        enabled,
    });
};
