import Typography from "@material-ui/core/Typography";

const Copyright = (props) => {
    return (
        <a href="https://www.citylitics.com" target="_blank" rel="noopener noreferrer">
            <Typography
                className={props.className}
                variant="body2"
                color="textSecondary"
                align="center"
            >
                {"Copyright © Citylitics Inc. " + new Date().getFullYear()}
            </Typography>
        </a>
    );
};

export default Copyright;
