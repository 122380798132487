import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";

import { pdfStyle } from "../pdfStyle";
import RenderConstraint from "../RenderConstraint";

const RenderConstraints = ({ constraints, name }) =>
    constraints.length ? (
        <PDFView style={pdfStyle.itemContainer} wrap={false}>
            <PDFView style={pdfStyle.itemTitleContainer}>
                <PDFText style={pdfStyle.itemTitle}>{name}</PDFText>
            </PDFView>
            {constraints.map((constraint, index) =>
                RenderConstraint({
                    constraint: constraint,
                    key: index,
                    name,
                })
            )}
        </PDFView>
    ) : null;

export default RenderConstraints;
