import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

import logo from "citylitics-logo-dark.png";
import Copyright from "components/nautilus/Copyright";
import HelpEmail from "components/nautilus/HelpEmail";
import RequestPasswordResetForm from "components/nautilus/RequestPasswordResetForm";
import { authPageTheme } from "constants/themes";
import {
    analyticsEvent,
    fetchProfilesData,
    fetchUserData,
} from "stores/userStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import {
    requestPasswordResetEmailSelector,
    requestPasswordResetHasSentEmailSelector,
} from "stores/userStore/selectors";
const useStyles = makeStyles(authPageTheme);

export const RequestPasswordResetConfirmation = ({ email }) => {
    const classes = useStyles();
    //@ts-ignore
    window.heap && window.heap.identify(email);
    return (
        <>
            <Typography
                data-testid="request-password-reset-confirmation"
                className={classes.descriptionText}
            >
                {email} has been sent a password reset email. Please check your inbox
                and spam folders.
            </Typography>
        </>
    );
};

export const UnconnectedRequestPasswordReset = ({
    analyticsEvent,
    history,
    sentEmail,
    submittedEmail,
}) => {
    const classes = useStyles();
    const helpEmailLabel = sentEmail
        ? "Need Help - Forgot Password Email Sent"
        : "Need Help - Forgot Password";
    const helpEmailTrackClick = () => {
        analyticsEvent({
            action: eventActions.CLICKED,
            category: eventCategories.FORMS,
            label: helpEmailLabel,
        });
    };
    return (
        <Container maxWidth="xs">
            <div className={classes.container}>
                <a href="/login">
                    <img alt="WatrHub" className={classes.logo} src={logo} />
                </a>

                <Typography className={classes.title} component="h1" variant="h5">
                    Forgot Password
                </Typography>

                {sentEmail ? (
                    <RequestPasswordResetConfirmation email={submittedEmail} />
                ) : (
                    <>
                        <Typography className={classes.descriptionText}>
                            Please enter your work email address. We will send you an
                            email to reset your password.
                        </Typography>
                        <RequestPasswordResetForm />
                    </>
                )}
            </div>
            <Grid container className={classes.actionLinks}>
                {sentEmail ? null : (
                    <Grid item xs>
                        <Link
                            component="button"
                            data-testid="auth-go-back"
                            onClick={() => {
                                analyticsEvent({
                                    action: eventActions.CLICKED,
                                    executeOnceComplete: () => {
                                        history.goBack();
                                    },
                                    category: eventCategories.FORMS,
                                    label: "Cancel - Forgot Password",
                                });
                            }}
                            variant="body2"
                        >
                            Cancel
                        </Link>
                    </Grid>
                )}
                <Grid item>
                    <HelpEmail
                        emailSubject="Forgot Password"
                        helpText="Need help?"
                        onClick={helpEmailTrackClick}
                    />
                </Grid>
            </Grid>
            <Copyright className={classes.copyright} />
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        submittedEmail: requestPasswordResetEmailSelector(state as never),
        sentEmail: requestPasswordResetHasSentEmailSelector(state as never),
    };
};

const ConnectedRequestPasswordReset = connect(mapStateToProps, {
    analyticsEvent,
    fetchUserData,
    fetchProfilesData,
})(UnconnectedRequestPasswordReset);

export default ConnectedRequestPasswordReset;
