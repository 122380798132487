import { FormHelperText } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form } from "formik";
import React, { useState } from "react";

const formStyle = makeStyles((theme) => ({
    tooManyResultsMessage: {
        marginTop: "-4px",
        marginBottom: "4px",
    },
    autocomplete: {
        width: "100%",
    },
    separatingLine: {
        border: "solid 1px #d6d7d9",
        height: "1px",
        width: "100%",
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    helperText: {
        margin: "0 14px",
    },
}));
export const TEST_IDS = {
    autocomplete: "autocomplete-test-id",
    dropdown: "dropdown-test-id",
    loadingSpinner: "async-search-autocomplete-loading-spinner",
};

const FormSearchField = ({
    label,
    name,
    allOptions,
    noOptions = null,
    isFetchingResults = false,
    value,
    initialOptions = [],
    onChange,
    onInputDelayComplete,
    errors,
    helperText = null,
    renderOption = null,
    getOptionLabel = null,
    multiple = false,
    disabled = false,
    loading = false,
}) => {
    const styles = formStyle();

    const tooManyOptionsMessage = allOptions ? (
        allOptions.length >= 100 ? (
            <Typography className={styles.tooManyResultsMessage} variant="body1">
                `Too many results found {allOptions.length} - please continue searching`
            </Typography>
        ) : null
    ) : null;
    return (
        <Form>
            {tooManyOptionsMessage}
            <FormControl className={styles.autocomplete} error={name in errors}>
                <AsyncSearchAutocomplete
                    adminButton={null}
                    errors={errors}
                    isFetchingResults={isFetchingResults}
                    label={label}
                    name={name}
                    value={value}
                    onInputDelayComplete={onInputDelayComplete}
                    initialOptions={initialOptions}
                    options={allOptions}
                    data-testid={TEST_IDS.autocomplete}
                    filterOptions={(options, state) => options}
                    getOptionLabel={getOptionLabel ?? ((option) => option?.name || "")}
                    getOptionSelected={(option, value) => {
                        return value && option.id === value.id;
                    }}
                    ListboxProps={{ "data-testid": TEST_IDS.dropdown }}
                    multiple={multiple}
                    onChange={onChange}
                    renderOption={renderOption ?? ((option) => option.name)}
                    noOptions={noOptions}
                    disabled={disabled}
                    loading={loading}
                />
                <FormHelperText className={styles.helperText}>
                    {helperText}
                </FormHelperText>
            </FormControl>
        </Form>
    );
};

const AsyncSearchAutocomplete = ({
    adminButton = null,
    isSearchDisabled = false,
    errors,
    label,
    initialOptions = [],
    isFetchingResults = false,
    name,
    noOptions = null,
    onInputDelayComplete,
    options = [],
    value,
    ...props
}) => {
    const [searchTimeoutId, setSearchTimeoutId] = useState(null);
    const [searchText, setSearchText] = useState("");
    const isLoading = Boolean(isFetchingResults || searchTimeoutId);
    const searchResults =
        initialOptions.length > 0 && !searchText ? initialOptions : options;

    return (
        <Autocomplete
            debug
            value={value}
            loading={isLoading}
            options={searchResults}
            blurOnSelect={false}
            noOptionsText={
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography>No results found</Typography>
                    </Grid>
                    {noOptions ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {noOptions}
                        </Grid>
                    ) : null}
                </>
            }
            renderInput={(params) => (
                <TextField
                    onBlurCapture={(e) => {
                        if (noOptions && searchResults.length === 0) {
                            e.stopPropagation();
                        }
                    }}
                    {...params}
                    error={name in errors}
                    helperText={errors[name]}
                    value={searchText}
                    onChange={(e) => {
                        if (isSearchDisabled) return;
                        if (searchTimeoutId) {
                            clearTimeout(searchTimeoutId);
                        }
                        setSearchText(e.target.value);
                        const whatToSearch = e.target.value;
                        const newTimeout = setTimeout(() => {
                            onInputDelayComplete(whatToSearch);
                            setSearchTimeoutId(null);
                        }, 1000);
                        setSearchTimeoutId(newTimeout);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading && (
                                    <CircularProgress
                                        data-testid={TEST_IDS.loadingSpinner}
                                        color="inherit"
                                        size={20}
                                    />
                                )}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    label={label}
                    variant="outlined"
                />
            )}
            {...props}
        />
    );
};

export default FormSearchField;
