import LinearProgress from "@material-ui/core/LinearProgress";

import HiddenElement from "components/molecules/HiddenElement";
import classes from "components/molecules/ViewDetailsModal/style.module.css";
import { IPLResultWarning } from "components/molecules/ViewDetailsModal/warnings/IPLResultWarning";
import { useGetIPLResultDetailsAISummary } from "reactQuery/hooks/useIPLResults";

import { InsightSummaryTemplate } from "./InsightSummaryTemplate";

export const IPLResultTemplate = ({ result }: { result: any }) => {
    const aiSummaryQuery = useGetIPLResultDetailsAISummary(result.id);

    const summarysnippet = (
        <>
            <hr className={classes.line} />
            <p className={classes["snippet-title"]}>Snippet</p>
            {aiSummaryQuery.isLoading ? (
                <>
                    <LinearProgress />
                    <p>Loading Snippet...</p>
                </>
            ) : (
                <p
                    dangerouslySetInnerHTML={{
                        __html: aiSummaryQuery.data?.ai_summary,
                    }}
                />
            )}
        </>
    );
    const fullSnippet = (
        <>
            {summarysnippet}
            <hr className={classes.line} />
            <p className={classes["snippet-title"]}>Original Snippet</p>
            <p>{result.show_longer_snippet}</p>
        </>
    );
    return (
        <div style={{ fontSize: "17px" }}>
            <p>
                <a
                    target="_blank"
                    className={classes["no-underline"]}
                    href={`/admin/target_reports/intelligencepipelineresult/${result.id}/change/`}
                >
                    {result.id}
                </a>
            </p>
            {result.warning_labels ? (
                <p>
                    {result.warning_labels.map((warning) => (
                        <IPLResultWarning warning={warning} />
                    ))}
                </p>
            ) : null}
            <p style={{ margin: "4px 0" }}>
                <strong>{result.owner_text}</strong>
            </p>
            <p style={{ margin: "4px 0" }}>{result.industry_text}</p>
            <p style={{ margin: "4px 0" }}>{result.indicator_text}</p>
            <p>
                <strong>{result.query_text}</strong>
            </p>
            {result.indicator ? (
                <>
                    {result.indicator.description ? (
                        <p>
                            <HiddenElement
                                fullElement={
                                    <>
                                        <p>Tutorial</p>
                                        <p>{result.indicator.description}</p>
                                        {result.indicator.tutorial_link ? (
                                            <p>{result.indicator.tutorial_link}</p>
                                        ) : null}
                                    </>
                                }
                                expandText="Tutorial"
                                retractText="Hide Tutorial"
                            />
                        </p>
                    ) : null}
                </>
            ) : null}
            <p>
                <HiddenElement
                    fullElement={fullSnippet}
                    hiddenElement={summarysnippet}
                    expandText="Show Original"
                    retractText="Hide"
                />
            </p>
            <hr className={classes.line} />
            <p>
                <a
                    className={classes["no-underline"]}
                    href={result.url}
                    target="_blank"
                >
                    {result.title}
                </a>
                <p>
                    Created on{" "}
                    <strong>
                        {new Date(result.date_created).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </strong>
                </p>
            </p>
            {result.insight ? <InsightSummaryTemplate iplResult={result} /> : null}
        </div>
    );
};
