import { Form, Input } from "antd";

const AdditionalNotesInput = ({}) => {
    return (
        <Form.Item label="Additional notes" name="additional_notes">
            <Input />
        </Form.Item>
    );
};

export default AdditionalNotesInput;
