import logo from "citylitics-logo-dark.png";
import PDFImage from "components/atoms/PDFImage";
import PDFPage from "components/atoms/PDFPage";
import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { SearchPlanSummaryRead } from "reactQuery/hooks/apiTypes";

import { getTimeline } from "../getTimeline";
import { pdfStyle } from "../pdfStyle";
import RenderDetails from "../RenderDetails";
import RenderIntelligenceScopes from "../RenderIntelligenceScopes";
import RenderSubscriptionDetails from "../RenderSubscriptionDetails";
import RenderTimeline from "../RenderTimeline";

const RenderSummary = (searchPlanSummary: SearchPlanSummaryRead) => (
    // @ts-ignore
    <PDFPage size="LETTER" style={pdfStyle.page}>
        <PDFView style={pdfStyle.headerContainer} fixed>
            <PDFImage src={logo} style={pdfStyle.logo} />
            <PDFText
                render={({ pageNumber, totalPages }) =>
                    pageNumber === 1
                        ? `Document Generated: ${formatDayHyphenShortenedMonthHyphenYear(
                              new Date()
                          )}`
                        : `${pageNumber} / ${totalPages}`
                }
            />
        </PDFView>
        <PDFView style={pdfStyle.pageTitle}>
            <PDFText>{searchPlanSummary.product?.name ?? ""} Search Plan</PDFText>
        </PDFView>
        {searchPlanSummary.account_manager?.user ? (
            <PDFView style={pdfStyle.accountManagerContainer}>
                <PDFText>{`Updated: ${formatDayHyphenShortenedMonthHyphenYear(
                    searchPlanSummary.last_updated
                )}`}</PDFText>
            </PDFView>
        ) : null}
        {RenderDetails(searchPlanSummary)}
        {RenderIntelligenceScopes(searchPlanSummary.intelligence_scopes)}
        {RenderSubscriptionDetails(searchPlanSummary.subscription)}
        {RenderTimeline(getTimeline(searchPlanSummary))}
    </PDFPage>
);

export default RenderSummary;
