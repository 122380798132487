import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    PaginatedTableFilteredInfoType,
    paginatedInfoType,
    PaginatedTableSortedInfoType,
} from "types/additionalTypes";

interface queryParamBuilderTypes {
    defaultPageSize?: number;
    excludeParams?: string[];
    filters?: Partial<PaginatedTableFilteredInfoType>;
    pagination?: paginatedInfoType;
    requestedPage?: number;
    sorter?: Partial<PaginatedTableSortedInfoType>;
    searchParams?: URLSearchParams;
    startChar?: string;
    includeBaseParams?: boolean;
    includeExistingParams?: boolean;
    includeDefaultPagination?: boolean;
    includePagination?: boolean;
}

interface updateQueryTypes {
    includeBaseParams?: boolean;
    includeExistingParams?: boolean;
    updatedFilters?: Partial<PaginatedTableFilteredInfoType>;
    updatedPagination?: any;
    updatedSearchText?: string;
    updatedSorters?: Partial<PaginatedTableSortedInfoType>;
}

const baseParams = [
    "team_id",
    "user_id",
    "report_id",
    "indicator_group_id",
    "indicator_id",
    "owner_id",
    "opportunity_id",
    "feedback",
];

const paginationParams = ["limit", "offset"];

const queryParamOrder = {
    beginning: baseParams,
    end: ["ordering", ...paginationParams],
};

const defaultPagination = {
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50, 100],
};

export const hasCustomParams = (
    defaultFilters: any = {},
    defaultSorter: any = {},
    excludeSorter = false
) => {
    const searchParams = new URLSearchParams(window.location.search);
    const defaultFilterKeys = Object.keys(defaultFilters);

    return Array.from(searchParams.entries()).some(([key, value]) => {
        if (!baseParams.includes(key)) {
            if (key === "ordering") {
                if (!excludeSorter) {
                    const sortField = value.slice(1) === "-" ? "descend" : "ascend";
                    const sortType = value.charAt(0);
                    if (
                        sortField !== defaultSorter.field ||
                        sortType !== defaultSorter.order
                    ) {
                        return true;
                    }
                }
            } else if (
                !defaultFilterKeys.includes(key) ||
                defaultFilters[key] !== value
            ) {
                return true;
            }
        }
        return false;
    });
};

export const queryParamBuilder = ({
    defaultPageSize = null,
    excludeParams = [],
    includeBaseParams = false,
    includeExistingParams = true,
    includeDefaultPagination = false,
    includePagination = true,
    filters = {},
    pagination,
    requestedPage,
    searchParams,
    startChar = "?",
    sorter = {},
}: queryParamBuilderTypes) => {
    /*
    This function builds a query string based on the provided parameters
    */

    const contextSensitiveDefaultPagination = {
        ...defaultPagination,
        pageSize: defaultPageSize ? defaultPageSize : defaultPagination.pageSize,
    };

    if (!searchParams) {
        // If no searchParams are provided, use the current URL search params
        searchParams = new URLSearchParams(window.location.search);
    }
    const newParams = new URLSearchParams();

    if (includeBaseParams) {
        // Adds base params to the newParams object, and eventually, to the new URL
        baseParams.forEach((key) => {
            if (searchParams.has(key) && !excludeParams.includes(key)) {
                newParams.set(key, searchParams.get(key));
            }
        });
    }

    if (includeExistingParams) {
        // Adds existing URL params to the newParams object, and eventually, to the new URL
        // This excludes ordering (added via the sorter param), baseParams, and excludeParams (both of which are added via their own params)
        if (Object.keys(filters).length === 0) {
            Array.from(searchParams.entries() as Iterable<[string, string]>).map(
                ([key, value]) =>
                    key !== "ordering" &&
                    !baseParams.includes(key) &&
                    !excludeParams.includes(key) &&
                    (includePagination || !paginationParams.includes(key)) &&
                    newParams.set(key, value)
            );
        }
        if (Object.keys(sorter).length === 0 && searchParams.has("ordering")) {
            // If the current URL has an ordering param, but the sorter param is not provided, add the ordering param to the new URL
            newParams.set("ordering", searchParams.get("ordering"));
        }
    }

    Object.entries(filters).forEach(([key, value]) => {
        // Return "All" values, since they are not necessary in the URL
        if (value === "All") {
            return;
        }
        if (!excludeParams.includes(key)) {
            // Adds filters to the newParams object, and eventually, to the new URL
            // Each filter is added as a key-value pair, where the value depends on the type of the filter
            if (key.endsWith("_updated") && !key.startsWith("date_")) {
                if (value?.[0] !== undefined) {
                    newParams.set(key, value);
                }
            } else if (Array.isArray(value) && value.length > 0) {
                if (key.startsWith("date_") || key.endsWith("_date")) {
                    if (moment.isMoment(value[0])) {
                        newParams.set(`${key}_after`, value[0].format("YYYY-MM-DD"));
                    }
                    if (moment.isMoment(value[1])) {
                        newParams.set(`${key}_before`, value[1].format("YYYY-MM-DD"));
                    }
                } else if (value[0]?.min !== undefined || value[0]?.max !== undefined) {
                    if (value[0]?.min !== undefined) {
                        newParams.set(`min_${key}`, value[0].min);
                    }
                    if (value[0]?.max !== undefined) {
                        newParams.set(`max_${key}`, value[0].max);
                    }
                } else {
                    const filteredValues = value.filter(
                        (item) => typeof item === "string" || typeof item === "number"
                    );
                    if (filteredValues.length) {
                        newParams.set(key, filteredValues.join(","));
                    }
                }
            } else if (typeof value === "string" && value !== "") {
                newParams.set(key, value);
            } else if (typeof value === "number" || typeof value === "boolean") {
                newParams.set(key, value.toString());
            }
        }
    });

    if (sorter?.field && sorter.order) {
        // Adds sorting to the newParams object, and eventually, to the new URL
        // descending ordering is prefixed with a "-"
        const orderPrefix = sorter.order === "descend" ? "-" : "";
        newParams.set("ordering", `${orderPrefix}${sorter.field}`);
    }

    if (
        includeDefaultPagination ||
        (pagination &&
            ((pagination.current !== undefined &&
                pagination.current !== contextSensitiveDefaultPagination.current) ||
                (pagination.pageSize !== undefined &&
                    pagination.pageSize !==
                        contextSensitiveDefaultPagination.pageSize)))
    ) {
        // If includeDefaultPagination is true, or if the pagination param is provided and differs from the default pagination values, add pagination to the new URL
        const currentSizeDefault =
            includeDefaultPagination && searchParams.has("limit")
                ? parseInt(searchParams.get("limit"))
                : contextSensitiveDefaultPagination.pageSize;
        const currentPageDefault =
            includeDefaultPagination && searchParams.has("offset")
                ? Math.floor(
                      parseInt(searchParams.get("offset")) / currentSizeDefault
                  ) + 1
                : contextSensitiveDefaultPagination.current;

        const currentPage = requestedPage ?? pagination?.current ?? currentPageDefault;
        const pageSize = pagination?.pageSize ?? currentSizeDefault;
        const offset = pageSize * (currentPage - 1);
        newParams.set("limit", String(pageSize));
        newParams.set("offset", String(offset));
    }

    // Enforce specific query param order
    const orderedParams = new URLSearchParams();
    queryParamOrder.beginning.forEach((key) => {
        if (newParams.has(key)) {
            orderedParams.set(key, newParams.get(key));
        }
    });
    Array.from(newParams.keys()).forEach((key) => {
        if (
            !queryParamOrder.beginning.includes(key) &&
            !queryParamOrder.end.includes(key)
        ) {
            orderedParams.set(key, newParams.get(key));
        }
    });
    queryParamOrder.end.forEach((key) => {
        if (newParams.has(key)) {
            orderedParams.set(key, newParams.get(key));
        }
    });

    return orderedParams.toString() ? `${startChar}${orderedParams.toString()}` : "";
};

export const queryObjBuilder = (
    searchParams = null,
    pagination = null,
    defaultPageSize = null,
    forceIncludePagination = false,
    pageSizeOptions = null
) => {
    /*
    This function builds an object with the following keys:
    - baseInfo: an object with base parameters (e.g. team_id, etc.)
    - filteredInfo: an object with filters (e.g. date_updated, indicator_id, etc.)
    - paginatedInfo: an object with pagination information (e.g. current page, page size)
    - searchText: a string with the search text
    - sortedInfo: an object with sorting information (e.g. columnKey, field, order)
    */
    let baseInfo = {};
    let filteredInfo = {};
    let paginatedInfo = {} as paginatedInfoType;
    let searchText = null;
    let sortedInfo = {};

    const contextSensitiveDefaultPagination = {
        ...(pagination || defaultPagination),
        pageSize: pagination?.pageSize ?? defaultPageSize ?? defaultPagination.pageSize,
        pageSizeOptions:
            pagination?.pageSizeOptions ??
            pageSizeOptions ??
            defaultPagination.pageSizeOptions,
    };

    if (!searchParams) {
        searchParams = new URLSearchParams(window.location.search);
    }

    Array.from(searchParams.entries() as Iterable<[string, string]>).map(
        ([key, value]) => {
            if (key === "limit" || key === "offset") {
                if (Object.keys(paginatedInfo).length === 0) {
                    paginatedInfo = {
                        current: contextSensitiveDefaultPagination.current,
                        pageSize: contextSensitiveDefaultPagination.pageSize,
                    };
                }
                if (key === "limit") {
                    if (
                        contextSensitiveDefaultPagination.pageSizeOptions.includes(
                            parseInt(value)
                        )
                    ) {
                        paginatedInfo.pageSize = parseInt(value);
                    } else {
                        paginatedInfo.pageSize =
                            contextSensitiveDefaultPagination.pageSize;
                        searchParams.set(
                            "limit",
                            contextSensitiveDefaultPagination.pageSize
                        );
                    }
                } else if (key === "offset") {
                    const limit =
                        searchParams.get("limit") ??
                        contextSensitiveDefaultPagination.pageSize;
                    const currentPage = Math.floor(parseInt(value) / limit) + 1;
                    paginatedInfo.current = currentPage;
                }
            } else if (key === "ordering") {
                const order = value[0] === "-" ? "descend" : "ascend";
                sortedInfo = {
                    columnKey: order === "ascend" ? value : value.slice(1),
                    field: order === "ascend" ? value : value.slice(1),
                    order,
                };
            } else if (key === "search_text") {
                if (value !== "") {
                    searchText = value;
                }
            } else if (/_(after|before)$/.test(key)) {
                const [_, dateKey, dateType] = key.match(/(.+?)_(after|before)$/);
                if (!filteredInfo[dateKey]) {
                    filteredInfo[dateKey] = [null, null];
                }
                filteredInfo[dateKey][dateType === "before" ? 1 : 0] = moment(value);
            } else if (/_updated$/.test(key)) {
                filteredInfo[key] = [value === "true" ? String(true) : String(false)];
            } else if (/^(min|max)_/.test(key)) {
                const keyCategory = key.match(/^(min|max)_/)[1];
                const keyName = key.substring(4);
                let existingValue = filteredInfo[keyName] || [{}];
                existingValue[0][keyCategory] = parseInt(value);
                filteredInfo[keyName] = existingValue;
            } else if (!baseParams.includes(key)) {
                if (Number.isInteger(Number(value))) {
                    filteredInfo[key] = parseInt(value);
                } else {
                    const valuesArray = value.split(",").map((str) => {
                        const num = Number(str);
                        return isNaN(num) ? str : num;
                    });
                    filteredInfo[key] = valuesArray;
                }
            } else if (baseParams.includes(key)) {
                baseInfo[key] = value;
            }
        }
    );

    if (forceIncludePagination && Object.keys(paginatedInfo).length === 0) {
        paginatedInfo = contextSensitiveDefaultPagination;
    }

    return {
        baseInfo,
        filteredInfo,
        paginatedInfo,
        searchText,
        sortedInfo,
    };
};

export const useManagedQueryParams = (
    defaultPageSize = null,
    pageSizeOptions = null
) => {
    /*
    This hook manages filters, pagination, text, etc. including their states, as well as updating the URL with the new query parameters
    */

    const contextSensitiveDefaultPagination = {
        ...defaultPagination,
        pageSize: defaultPageSize ? defaultPageSize : defaultPagination.pageSize,
        pageSizeOptions: pageSizeOptions
            ? pageSizeOptions
            : defaultPagination.pageSizeOptions,
    };

    const history = useHistory();

    const [filteredInfo, setFilteredInfo] = useState<
        Partial<PaginatedTableFilteredInfoType>
    >({});
    const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);
    const [currentPaginatedInfo, setCurrentPaginatedInfo] = useState<paginatedInfoType>(
        {
            current: contextSensitiveDefaultPagination.current,
            pageSize: contextSensitiveDefaultPagination.pageSize,
        }
    );
    const [searchTextValue, setSearchTextValue] = useState<string>(null);
    const [sortedInfo, setSortedInfo] = useState<Partial<PaginatedTableSortedInfoType>>(
        {}
    );

    const initializeHook = () => {
        const { filteredInfo, paginatedInfo, searchText, sortedInfo } = queryObjBuilder(
            new URLSearchParams(window.location.search),
            contextSensitiveDefaultPagination,
            null,
            null,
            pageSizeOptions
        );
        setFilteredInfo(filteredInfo);
        setHasActiveFilters(hasCustomParams({}, {}, true));
        setCurrentPaginatedInfo(paginatedInfo);
        setSearchTextValue(searchText);
        setSortedInfo(sortedInfo);
    };

    useEffect(() => {
        initializeHook();
    }, []);

    const updateQuery = ({
        includeBaseParams = true,
        includeExistingParams = true,
        updatedFilters,
        updatedPagination,
        updatedSearchText,
        updatedSorters,
    }: updateQueryTypes) => {
        const newQueryParams = queryParamBuilder({
            defaultPageSize: contextSensitiveDefaultPagination.pageSize,
            filters: {
                ...(updatedFilters ?? filteredInfo),
                search_text: updatedSearchText ?? searchTextValue,
            },
            includeBaseParams,
            includeExistingParams,
            pagination: updatedPagination ?? currentPaginatedInfo,
            searchParams: new URLSearchParams(window.location.search),
            sorter: updatedSorters ?? sortedInfo,
        });

        history.push({ search: newQueryParams });
        setFilteredInfo(updatedFilters ?? filteredInfo);
        setHasActiveFilters(hasCustomParams({}, {}, true));
        setSearchTextValue(updatedSearchText ?? searchTextValue);
        setSortedInfo(updatedSorters ?? sortedInfo);
        setCurrentPaginatedInfo(updatedPagination ?? currentPaginatedInfo);
    };

    return {
        defaultPagination: contextSensitiveDefaultPagination,
        filteredInfo,
        hasActiveFilters,
        paginatedInfo: currentPaginatedInfo,
        searchTextValue,
        sortedInfo,
        initializeHook,
        updateQuery,
    };
};
