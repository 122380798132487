import FormDropdownObjectsField from "components/atoms/FormDropdownObjectsField";
import { openNewInternalLink } from "constants/navigation";

interface DropdownColumnProps {
    feedType: string;
    isLoading: boolean;
    key: string;
    title: string;
    dropdownOptions: any[];
}

const dropdownColumn = ({
    feedType,
    key,
    title,
    dropdownOptions,
}: DropdownColumnProps) => {
    const mutateDataQueries = dropdownOptions.map((dropdownOption) => {
        if (dropdownOption.mutateDataQuery) {
            return dropdownOption.mutateDataQuery({
                feedType,
                defaultPageSize: dropdownOption.defaultPageSize,
            });
        }
    });

    return {
        title: title,
        dataIndex: key,
        key: key,
        render: (_, record) => {
            return (
                <div>
                    {dropdownOptions.map((dropdownParams: any, index: number) => (
                        <div style={{ marginTop: index > 0 ? "10px" : 0 }}>
                            {dropdownParams.optionsQuery?.isLoading ? (
                                <FormDropdownObjectsField
                                    selectedOption={{
                                        key: "Loading...",
                                        value: "Loading...",
                                    }}
                                    allOptions={[
                                        { key: "Loading...", value: "Loading..." },
                                    ]}
                                    setSelectedOption={() => {}}
                                    disabled={true}
                                ></FormDropdownObjectsField>
                            ) : (
                                <FormDropdownObjectsField
                                    selectedOption={
                                        dropdownParams.key
                                            ? {
                                                  key: record[dropdownParams.key],
                                                  value: record[dropdownParams.key],
                                              }
                                            : dropdownParams.defaultSelectedOptionValue || {
                                                  key: "",
                                                  value: "",
                                              }
                                    }
                                    allOptions={
                                        dropdownParams.optionsQuery?.data
                                            ? Object.entries(
                                                  dropdownParams.optionsQuery?.data
                                              ).map(([key, value]) => ({ key, value }))
                                            : []
                                    }
                                    setSelectedOption={(selectedOption) => {
                                        if (dropdownParams.mutateDataQuery) {
                                            mutateDataQueries[index].mutate({
                                                field: dropdownParams.key,
                                                [dropdownParams.key]: selectedOption,
                                                id: record.id,
                                            });
                                        }
                                        if (
                                            dropdownParams.goToLinks?.[selectedOption]
                                        ) {
                                            openNewInternalLink(
                                                `${dropdownParams.goToLinks?.[selectedOption]}${record.id}/`,
                                                false
                                            );
                                        }
                                    }}
                                ></FormDropdownObjectsField>
                            )}
                        </div>
                    ))}
                </div>
            );
        },
    };
};

export default dropdownColumn;
