export const FEEDBACK_REACTIONS = {
    add_to_action_list: "Add to Action List",
    add_to_crm: "Add to CRM",
    add_to_future_list: "Add to Future List",
    bullseye: "Bullseye",
    dislike: "Dislike",
    like: "Like",
    hide: "Hide",
};

export const FEEDBACK_REACTIONS_PAST_TENSE = {
    add_to_action_list: "Added to Action List",
    add_to_crm: "Added to CRM",
    add_to_future_list: "Added to Future List",
    bullseye: "Bullseyed",
    dislike: "Disliked",
    like: "Liked",
    hide: "Hidden",
};
