// TODO: change wasteWaterFacility variables to facility
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { insightIdToRemoveDemographicFromSelector } from "stores/demographics/demographicSlice";
import { insightIdToRemoveDrinkingWaterSystemFromSelector } from "stores/drinkingWaterSystems/selectors";
import { insightsAdapter } from "stores/insights/adapter";
import { insightReducerName } from "stores/insights/constants";
import { activeOpportunityIdSelector } from "stores/opportunities/selectors";
import { activeReportIdSelector } from "stores/reports/reportSlice";
import { insightIdToUnlinkWastewaterFacilityFromSelector } from "stores/wastewaterFacilities/selectors";

export const insightStoreSelector = (state) => state[insightReducerName];

export const insightsSelectors = insightsAdapter.getSelectors(insightStoreSelector);

export const newInsightSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.newInsight
);

export const selectContactsForActiveOpportunity = createSelector(
    [activeOpportunityIdSelector, insightsSelectors.selectAll],
    (activeOpportunityId, insights) => {
        return insights
            .filter((insight) => insight.opportunity.id === activeOpportunityId)
            .reduce((result, insight) => result.concat(insight.contacts), []);
    }
);

export const editInsightSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.editInsight
);

export const removeInsightSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.removeInsight
);

export const insightBeingEditedSelector = createSelector(
    [editInsightSelector, insightsSelectors.selectEntities],
    (editInsight, insightMap) => insightMap[editInsight.insightId]
);

export const selectInsightsForReportSelector = createSelector(
    [insightsSelectors.selectAll, activeReportIdSelector],
    (allInsights, reportId) => {
        return allInsights.filter((insight) => insight.report === parseInt(reportId));
    }
);

export const selectInsightsForActiveOpportunity = createSelector(
    [insightsSelectors.selectAll, activeOpportunityIdSelector],
    (allInsights, activeOpportunityId) =>
        allInsights.filter(
            (insight) => insight.opportunity.id === parseInt(activeOpportunityId)
        )
);

export const waterSystemIDsFromActiveInsightsSelector = createSelector(
    [selectInsightsForActiveOpportunity],
    (activeInsights) =>
        activeInsights.map((insight) => insight.drinking_water_systems).flat()
);

export const insightToRemoveDemographicFromSelector = createSelector(
    [insightsSelectors.selectEntities, insightIdToRemoveDemographicFromSelector],
    (allInsights, insightId) => allInsights[insightId]
);

export const insightToRemoveDrinkingWaterSystemFromSelector = createSelector(
    [
        insightsSelectors.selectEntities,
        insightIdToRemoveDrinkingWaterSystemFromSelector,
    ],
    (allInsights, insightId) => allInsights[insightId]
);

export const isLoadingInsightSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => {
        const insights = _.get(insightStore, ["insightsForReportView", "insights"], []);

        if (insights) {
            return insights.length > 0;
        }
    }
);

export const isUpdatingInsightSelector = createSelector(
    [editInsightSelector],
    (editInsight) => editInsight.isUpdating
);

export const removeContactSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.removeContact
);

export const contactIdToRemoveSelector = createSelector(
    [removeContactSelector],
    (removeContact) => removeContact.contactId
);

export const insightIdToRemoveContactFromSelector = createSelector(
    [removeContactSelector],
    (removeContact) => removeContact.insightId
);

export const insightToRemoveContactFromSelector = createSelector(
    [insightsSelectors.selectEntities, insightIdToRemoveContactFromSelector],
    (allInsights, insightId) => allInsights[insightId]
);

export const insightToUnlinkWastewaterFacilityFromSelector = createSelector(
    [insightsSelectors.selectEntities, insightIdToUnlinkWastewaterFacilityFromSelector],
    (allInsights, insightId) => allInsights[insightId]
);

export const insightForModificationSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.insightForModification
);

export const intelligenceNotesSelector = createSelector(
    [insightStoreSelector],
    (insightStore) => insightStore.note
);

export const insightsForReportViewSelector = createSelector(
    [insightStoreSelector, activeReportIdSelector],
    (insightStore, reportId) => {
        const results = _.map(
            insightStore.insightsForReportView.insights,
            function (insight) {
                return _.filter(insight, { is_deleted: false, report: reportId });
            }
        );
        return results.filter((d) => d.length);
    }
);
