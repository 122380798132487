import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const sectionStyles = makeStyles((theme) => {
    return {
        container: {
            overflow: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    };
});

export const StyledCardSection = ({ children, maxHeight = "none" }) => {
    const styles = sectionStyles();
    return (
        <CardContent className={styles.container} style={{ maxHeight: maxHeight }}>
            {children}
        </CardContent>
    );
};

export default StyledCardSection;
