import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import logo from "citylitics-logo-dark.png";
import StyledButton from "components/atoms/StyledButton";
import Copyright from "components/nautilus/Copyright";
import HelpEmail from "components/nautilus/HelpEmail";
import PasswordResetForm from "components/nautilus/PasswordResetForm";
import { authPageTheme } from "constants/themes";
import { analyticsEvent } from "stores/userStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import {
    passwordResetCompletedSelector,
    passwordResetErrorsSelector,
} from "stores/userStore/selectors";

const useStyles = makeStyles(authPageTheme);

export const InvalidTokenMessage = () => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.descriptionText}>
                It appears that you have followed an invalid link - please confirm the
                address listed in your email.
            </Typography>
        </>
    );
};

export const PasswordResetConfirmation = ({ analyticsEvent, push }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.descriptionText}>
                Your password has been successfully reset.
            </Typography>
            <StyledButton
                className={classes.button}
                color="primary"
                onClick={() => {
                    analyticsEvent({
                        action: eventActions.CLICKED,
                        category: eventCategories.FORMS,
                        executeOnceComplete: () => {
                            push("/login?next=/");
                        },
                        label: "Sign In - Reset Password Success",
                    });
                }}
                variant="contained"
            >
                Go to dashboard
            </StyledButton>
        </>
    );
};

export const UnconnectedPasswordReset = ({
    analyticsEvent,
    responseErrors,
    hasCompleted,
    location,
    push,
}) => {
    const split_location = location.pathname.split("/");
    const uid = split_location[2];
    const token = split_location[3];
    const classes = useStyles();
    const hasInvalidToken = responseErrors.invalidSecret || !uid || !token;
    const invalidTokenMessage = hasInvalidToken && <InvalidTokenMessage />;
    const passwordChangedMessage = hasCompleted && (
        <PasswordResetConfirmation analyticsEvent={analyticsEvent} push={push} />
    );
    const helpEmailLabel = hasCompleted
        ? "Need Help - Reset Password Success"
        : "Need Help - Reset Password";
    const helpEmailTrackClick = () => {
        analyticsEvent({
            action: eventActions.CLICKED,
            category: eventCategories.FORMS,
            label: helpEmailLabel,
        });
    };
    return (
        <Container maxWidth="xs">
            <div className={classes.container}>
                <img alt="WatrHub" className={classes.logo} src={logo} />
                <Typography className={classes.title} component="h1" variant="h5">
                    Reset Password
                </Typography>
                {invalidTokenMessage}
                {passwordChangedMessage}
                {!hasInvalidToken && !hasCompleted ? (
                    <PasswordResetForm uid={uid} token={token} />
                ) : null}
            </div>
            <Grid container className={classes.actionLinks}>
                <Grid item>
                    <HelpEmail
                        onClick={helpEmailTrackClick}
                        emailSubject="Reset Password"
                        helpText="Need help?"
                    />
                </Grid>
            </Grid>
            <Copyright className={classes.copyright} />
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        hasCompleted: passwordResetCompletedSelector(state as never),
        responseErrors: passwordResetErrorsSelector(state as never),
    };
};

const ConnectedPasswordReset = connect(mapStateToProps, { analyticsEvent, push })(
    UnconnectedPasswordReset
);

export default ConnectedPasswordReset;
