import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { deliveryStatusKeys } from "reactQuery/keys";

export interface DeliveryStatusType {
    [key: string]: string;
}

export const useGetAllDeliveryStatuses = (): Partial<
    UseQueryResult<DeliveryStatusType>
> => {
    const queryKey = deliveryStatusKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<DeliveryStatusType> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/delivery_statuses/`
            );
            return data;
        },
    });
};
