import { UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";

import { getCsrfCookie, nautilusGetRequest, nautilusPostRequest } from "network";
import { documentCompareKeys } from "reactQuery/keys";

import { ComparisonResponse } from "./apiTypes";

interface ComparisonPostRequest {
    leftSideDocument: File;
    rightSideDocument: File;
    teamId: string;
}

async function postDocumentCompare(
    requestData: Partial<ComparisonPostRequest>
): Promise<ComparisonResponse> {
    const formData = new FormData();

    const { leftSideDocument, rightSideDocument, teamId } = requestData;

    formData.append("left_side_document", leftSideDocument);
    formData.append("right_side_document", rightSideDocument);
    formData.append("team_id", teamId);

    const customRequestConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": getCsrfCookie(),
        },
        withCredentials: true,
    };

    const { data } = await nautilusPostRequest(
        "/api/target_reports/document_compare/",
        formData,
        customRequestConfig
    );
    return data;
}

export function usePostDocumentCompare(): Partial<
    UseMutationResult<ComparisonResponse>
> {
    return useMutation((requestData: ComparisonPostRequest) => {
        return postDocumentCompare(requestData);
    });
}

async function getDocumentCompare(
    identifier: string,
    teamId: number
): Promise<ComparisonResponse> {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/document_compare/${identifier}/`,
        `?team_id=${teamId}`
    );
    return data;
}

export function useGetDocumentCompare(identifier: string, teamId: number) {
    return useQuery({
        queryKey: documentCompareKeys.single(identifier, teamId),
        queryFn: async () => await getDocumentCompare(identifier, teamId),
        refetchOnWindowFocus: false,
        retry: 1,
    });
}
