import { AllInsightsPathname } from "routes/IntelligenceFeeds";

const styles = {
    emptyMessageContainer: {
        width: "100%",
    },
    emptyMessageSectionContainer: {
        marginBottom: "20px",
    },
    emptyMessageTextElement: {
        display: "inline",
        marginRight: "3px",
    },
};

const emptyData = (initialMessage: string, supportEmailMessage: string = null) => {
    return (
        <div style={styles.emptyMessageContainer}>
            <div style={styles.emptyMessageSectionContainer}>
                {initialMessage ? (
                    <p style={styles.emptyMessageTextElement}>{initialMessage}</p>
                ) : null}
                {supportEmailMessage ? (
                    <p style={styles.emptyMessageTextElement}>
                        {supportEmailMessage}{" "}
                        <a href="mailto:support@citylitics.com">
                            support@citylitics.com
                        </a>
                        .
                    </p>
                ) : null}
            </div>
            <div style={styles.emptyMessageSectionContainer}>
                <p style={styles.emptyMessageTextElement}>In the meantime,</p>
                <p style={styles.emptyMessageTextElement}>
                    <a href={AllInsightsPathname}>
                        check out your team's most recent insights here
                    </a>
                </p>
            </div>
        </div>
    );
};

export default emptyData;
