import Typography from "@material-ui/core/Typography";

import { headerStyles } from "components/cdv/OpportunityCard/styles";

import EditOpportunityButton from "../EditOpportunityButton";

const OpportunityCardHeader = ({ isEditModeActive, opportunityTitle, opportunity }) => {
    const styles = headerStyles();

    return (
        <div>
            <div className={styles.headerContainer}>
                <div>
                    <Typography style={{ paddingRight: "8px" }} variant="h5">
                        {opportunityTitle}
                    </Typography>
                </div>

                <EditOpportunityButton
                    isEditModeActive={isEditModeActive}
                    opportunity={opportunity}
                />
            </div>
        </div>
    );
};

export default OpportunityCardHeader;
