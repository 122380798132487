import { ReactNode } from "react";
import tinycolor from "tinycolor2";

import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";

export const lightenByLevel = (color: string, level: number) => {
    return tinycolor(color)
        .brighten(level * 8)
        .lighten(level * 8)
        .toHexString();
};

const PDFTag = ({
    color,
    children: title,
    level = 0,
    textColorMatchesBorder = false,
}: {
    color: string;
    children: ReactNode;
    level?: number;
    textColorMatchesBorder?: boolean;
}) => {
    const borderColor = color && lightenByLevel(color, level);

    return (
        <PDFView
            style={{
                border: `1px solid ${borderColor}`,
                borderRadius: 3,
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "2 4",
                marginRight: 5,
                marginBottom: 5,
            }}
        >
            <PDFText style={{ color: textColorMatchesBorder ? borderColor : "black" }}>
                {title}
            </PDFText>
        </PDFView>
    );
};

export default PDFTag;
