// TODO: change wasteWaterFacility variables to facility
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
    nautilusDeleteRequest,
    nautilusGetRequest,
    nautilusPatchRequest,
    nautilusPostRequest,
} from "network";
import { insightReducerName } from "stores/insights/constants";
import {
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
} from "stores/uiStore/actionTypes";
import { isShowingSelector } from "stores/uiStore/selectors";
const formatName = (name) => `${insightReducerName}/${name}`;

export const removeInsightsById = createAction(formatName("removeInsightsById"));

export const setInsightForContactRemoval = createAction(
    formatName("setInsightForContactRemoval")
);

export const setInsightForEdit = createAction(formatName("setInsightForEdit"));

export const setInsightForModification = createAction(
    formatName("setInsightForIntelligenceAddition")
);

export const setOpportunityForInsightCreation = createAction(
    formatName("setOpportunityForInsightCreation")
);

export const patchInsight = createAsyncThunk(
    `${insightReducerName}/patchInsight`,
    async (
        {
            competitors = null,
            consultants = null,
            contacts = null,
            demographics = null,
            drinking_water_systems = null,
            facilities = null,
            indicators = null,
            indicator_tags = null,
            initiative_types = null,
            insightId,
            summary = null,
            delivery_status = null,
            stage = null,
            team_customer_tag_ids = null,
            timeline = null,
            status = null,
            is_deleted = null,
        },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const reqBody = {};
            if (contacts) reqBody.contacts = contacts;
            if (is_deleted != null) reqBody.is_deleted = is_deleted;
            if (indicators) reqBody.indicators = indicators;
            if (indicator_tags) reqBody.indicator_tags = indicator_tags;
            if (initiative_types) reqBody.initiative_types = initiative_types;
            if (summary || summary === "") reqBody.summary = summary;
            if (demographics) reqBody.demographics = demographics;
            if (drinking_water_systems)
                reqBody.drinking_water_systems = drinking_water_systems;
            if (facilities) reqBody.facilities = facilities;
            if (consultants) reqBody.consultants = consultants;
            if (competitors) reqBody.competitors = competitors;
            if (delivery_status) reqBody.delivery_status = delivery_status;
            if (team_customer_tag_ids)
                reqBody.team_customer_tags = team_customer_tag_ids;
            if (stage) {
                reqBody.stage = stage;
            } else if (stage === "") {
                reqBody.stage = null;
            }
            if (status) {
                reqBody.status = status;
            } else if (status === "") {
                reqBody.status = null;
            }
            const response = await nautilusPatchRequest(
                `/api/target_reports/insights/${insightId}`,
                reqBody
            );
            const state = getState();
            const isShowing = isShowingSelector(state);
            let msg;
            if (isShowing.competitorModal) {
                msg = "Competitors successfully updated";
            } else if (isShowing.addContactModal) {
                msg = "Contact successfully linked";
            } else if (isShowing.addDrinkingWaterSystemModal) {
                msg = "Drinking water system was successfully added";
            } else if (isShowing.addIndicatorModal) {
                msg = "Indicator was successfully added";
            } else if (isShowing.addLocationModal) {
                msg = "Location was successfully added";
            } else if (isShowing.addWasteWaterFacilityModal) {
                msg = "Facility was successfully added";
            } else if (isShowing.addTimelineModal) {
                msg = "Timeline was successfully added";
            } else if (isShowing.addTeamCustomerTagsModal) {
                msg = "Tags successfully added";
            } else if (isShowing.editTimelineModal) {
                msg = "Timeline was successfully updated";
            } else if (isShowing.consultantModal) {
                msg = "Consultant was successfully updated";
            } else if (isShowing.editInsightModal) {
                msg = "Insight was successfully updated";
            } else if (isShowing.editIndicatorModal) {
                msg = "Indicator was successfully updated";
            } else if (isShowing.teamCustomerTagsModal) {
                msg = "Tags successfully updated";
            } else if (isShowing.removeTimelineModal) {
                msg = "Timeline was successfully removed";
            } else if (isShowing.removeCompetitorModal) {
                msg = "Competitor was successfully removed";
            } else if (isShowing.removeConsultantModal) {
                msg = "Consultant was successfully removed";
            } else if (isShowing.removeContactModal) {
                msg = "Contact was successfully removed";
            } else if (isShowing.removeDrinkingWaterSystemModal) {
                msg = "Drinking water system was successfully removed";
            } else if (isShowing.removeLocationModal) {
                msg = "Location was successfully removed";
            } else if (isShowing.removeWasteWaterFacilityModal) {
                msg = "Facility was successfully removed";
            }
            dispatch(
                displaySuccessSnack({
                    message: msg,
                })
            );
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to update insight",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchInsightsByFilter = createAsyncThunk(
    `${insightReducerName}/fetchInsightsByFilter`,
    async ({ opportunityId, teamId }, { dispatch, rejectWithValue }) => {
        let queryString = "?";

        if (opportunityId) {
            queryString += `opportunity=${opportunityId}`;
            if (teamId) queryString += "&";
        }

        if (teamId) {
            queryString += `team_id=${teamId}`;
        }

        try {
            const response = await nautilusGetRequest(
                "/api/target_reports/insights/",
                queryString
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch insights for opportunity ${opportunityId}`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const createInsight = createAsyncThunk(
    `${insightReducerName}/createInsight`,
    async (
        {
            opportunityId,
            reportId,
            triggerSummary,
            indicatorIds,
            indicatorTagIds,
            initiativeTypeIds,
        },
        { dispatch, rejectWithValue, getState }
    ) => {
        const requestBody = {
            opportunity: opportunityId,
            report: reportId,
            summary: triggerSummary,
            indicators: indicatorIds,
            indicator_tags: indicatorTagIds,
            initiative_types: initiativeTypeIds,
        };

        try {
            const response = await nautilusPostRequest(
                "/api/target_reports/insights/",
                requestBody
            );

            dispatch(
                displaySuccessSnack({
                    message: "Insight was successfully created.",
                })
            );
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to create insight" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const removeInsight = createAsyncThunk(
    `${insightReducerName}/removeInsight`,
    async ({ insightId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusDeleteRequest(
                `/api/target_reports/insights/${insightId}`
            );
            dispatch(
                displaySuccessSnack({ message: "Insight was successfully deleted" })
            );
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to delete insight" }));
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const patchNote = createAsyncThunk(
    `${insightReducerName}/patchNote`,
    async ({ noteId, notes, sourceId }, { dispatch, rejectWithValue }) => {
        const reqBody = {};
        if (notes) reqBody.notes = notes;
        if (sourceId) reqBody.source = sourceId;
        try {
            const response = await nautilusPatchRequest(
                `/api/target_reports/notes/${noteId}`,
                reqBody
            );

            dispatch(displaySuccessSnack({ message: "Note was successfully changed" }));
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to change a note" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const removeNote = createAsyncThunk(
    `${insightReducerName}/removeNote`,
    async ({ noteId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusDeleteRequest(
                `/api/target_reports/notes/${noteId}`
            );

            dispatch(displaySuccessSnack({ message: "Note was successfully deleted" }));
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to delete a note" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);
