import { Button, DatePicker, Divider } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

import Text from "components/atoms/Text";

import HeaderIcons from "./HeaderIcons";
import { PaginatedTableCommonColumnProps } from "../types";

const { RangePicker } = DatePicker;

const dateColumn = ({
    allowFiltering = true,
    allowSorting = true,
    filteredInfo,
    filters,
    isLoading,
    key,
    searchTextValue,
    sortedInfo,
    styles,
    title,
}: PaginatedTableCommonColumnProps) => {
    const [currentDate, setCurrentDate] = useState<{
        max: Moment;
        min: Moment;
    }>({
        max: undefined,
        min: undefined,
    });

    useEffect(() => {
        if (filteredInfo) {
            const dateFilter = filteredInfo[key];
            if (dateFilter) {
                setCurrentDate({
                    max: moment(dateFilter[1]),
                    min: moment(dateFilter[0]),
                });
            }
        }
    }, [filteredInfo]);

    useEffect(() => {
        if (
            (filters?.max !== undefined || filters?.min !== undefined) &&
            currentDate.max === undefined &&
            currentDate.min === undefined
        ) {
            setCurrentDate({
                max: moment(filters.max),
                min: moment(filters.min),
            });
        }
    }, [filters]);

    return {
        title,
        dataIndex: key,
        key: key,
        render: (date: string) => <Text searchTextValue={searchTextValue}>{date}</Text>,
        filterDropdown: isLoading ? (
            <></>
        ) : allowFiltering ? (
            ({ setSelectedKeys, confirm }) => (
                <>
                    <RangePicker
                        data-testid="date"
                        allowEmpty={[false, false]}
                        format={"DD-MMM-YYYY"}
                        value={[currentDate.min, currentDate.max]}
                        onChange={(dates) => {
                            setSelectedKeys(dates);
                            setCurrentDate({
                                max: dates?.[1],
                                min: dates?.[0],
                            });
                        }}
                    />
                    <Divider />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            type="primary"
                            style={{ margin: "0 16px 12px" }}
                            onClick={() => {
                                confirm({ closeDropdown: true });
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </>
            )
        ) : null,
        filterIcon: (
            <HeaderIcons
                styles={styles}
                isLoading={isLoading}
                showFilteringIcon={true}
                showSortingIcon={true}
            />
        ),
        filteredValue: filteredInfo[key],
        sorter: isLoading ? undefined : allowSorting ? () => 0 : null,
        sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
    };
};

export default dateColumn;
