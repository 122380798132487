import Grid from "@material-ui/core/Grid";
import { push } from "connected-react-router";
import { useEffect } from "react";
import { connect } from "react-redux";

import Header from "components/nautilus/Header";
import TargetSurfacerClearResultsModal from "components/target_surfacer/ClearQueryResultsModal";
import ResultsTable from "components/target_surfacer/ResultsTable";
import SearchModal from "components/target_surfacer/SearchModal";
import StaticLinkModal from "components/target_surfacer/StaticLinkModal";
import { fetchTags } from "stores/tags/tagSlice";
import { fetchSearchRecords, registerQueryWatcher } from "stores/targets/actions";
import { isAuthenticatedSelector, isStaffSelector } from "stores/userStore/selectors";

export const UnconnectedTargetSurfacerPage = ({
    fetchSearchRecords,
    fetchTags,
    isAuthenticated,
    isStaff,
    push,
    registerQueryWatcher,
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            if (isStaff) {
                fetchTags({});
                fetchSearchRecords({});
                registerQueryWatcher({
                    watcherId: Math.random(),
                });
            } else {
                push("/");
            }
        }
    }, [
        isAuthenticated,
        isStaff,
        fetchSearchRecords,
        fetchTags,
        push,
        registerQueryWatcher,
    ]);

    return (
        <div style={{ overflowX: "hidden" }}>
            <Header />
            <SearchModal />
            <StaticLinkModal />
            <TargetSurfacerClearResultsModal />
            <Grid container justifyContent="center">
                <Grid container item justifyContent="center">
                    <h1>Citylitics Municipal Search Engine</h1>
                </Grid>
                <ResultsTable />
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticatedSelector(state),
        isStaff: isStaffSelector(state as never),
    };
};

const ConnectedTargetSurfacerPage = connect(mapStateToProps, {
    fetchSearchRecords,
    fetchTags,
    push,
    registerQueryWatcher,
})(UnconnectedTargetSurfacerPage);

export default ConnectedTargetSurfacerPage;
