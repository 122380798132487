import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import cityliticsLogoLogomarkInverse from "logos/citylitics-logo-logomark-inverse.png";
import cityliticsLogoPrimaryInverse from "logos/citylitics-logo-primary-inverse.png";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";

const CityliticsLogoPrimary = () => (
    <img
        alt="citylitics-logo"
        data-testid="citylitics-logo-primary"
        src={cityliticsLogoPrimaryInverse}
        style={{ maxWidth: "100%", height: "100%" }}
    />
);

const CityliticsLogoLogomark = () => (
    <img
        alt="citylitics-logo"
        data-testid="citylitics-logo-logomark"
        src={cityliticsLogoLogomarkInverse}
        style={{
            margin: "auto",
            height: "100%",
            padding: "12px",
            maxWidth: "100%",
        }}
    />
);

const CityliticsLogo = ({ collapsed }: { collapsed: boolean }) => {
    const [showFullLogo, setShowFullLogo] = useState(true);

    const getWhoami = useGetWhoami();
    const isStaff = getWhoami.isSuccess ? getWhoami.data.user.is_staff : false;

    useEffect(() => {
        if (collapsed) {
            setShowFullLogo(false);
        } else {
            setTimeout(() => {
                setShowFullLogo(true);
            }, 120);
        }
    }, [collapsed]);

    return (
        <div style={{ display: "flex", justifyItems: "center", height: "64px" }}>
            {showFullLogo ? (
                isStaff ? (
                    <Link to="/" aria-label="primary">
                        <CityliticsLogoPrimary />
                    </Link>
                ) : (
                    <CityliticsLogoPrimary />
                )
            ) : isStaff ? (
                <Link
                    to="/"
                    aria-label="logomark"
                    style={{ margin: "auto", maxWidth: "100%", height: "100%" }}
                >
                    <CityliticsLogoLogomark />
                </Link>
            ) : (
                <CityliticsLogoLogomark />
            )}
        </div>
    );
};

export default CityliticsLogo;
