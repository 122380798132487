import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, FormikProps, withFormik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import * as Yup from "yup";

import { EditModalBody } from "components/molecules/EditModalBody";
import { FormModal } from "components/molecules/Modal";
import { SidebarLayoutAllTeams } from "reactQuery/hooks/apiTypes";
import { useGetReport } from "reactQuery/hooks/useReports";
import {
    deleteReport,
    isCreatingReportSelector,
    patchReport,
} from "stores/reports/reportSlice";
import { closeAllOverlays, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const ERROR_MESSAGES = {
    teamMissing: "A team is required",
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
};

export const CreateReportSchema = Yup.object().shape({
    selectedTeam: Yup.string().required(ERROR_MESSAGES.teamMissing).nullable(),
    reportDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
});

const formStyle = makeStyles((theme) => ({
    teamSelect: {
        paddingBottom: theme.spacing(2),
        width: "100%",
    },
    fullWidth: {
        width: "100%",
    },
}));

interface FormProps {
    reportDate: Date;
    selectedTeam: string;
    isRemoveModeActive: any;
    reportId: number;
}

export const EditReportForm = ({
    errors,
    setFieldValue,
    values: { selectedTeam, reportDate },
}: FormikProps<FormProps>) => {
    const classes = formStyle();

    return (
        <Form>
            <TextField
                className={classes.teamSelect}
                disabled={true}
                id="selected-team"
                label="Team"
                multiline
                name="selectedTeam"
                value={selectedTeam}
                variant="outlined"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"reportDate" in errors}
                    className={classes.fullWidth}
                >
                    <InputLabel shrink>Report date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="date-picker-inline"
                        inputProps={{ "data-testid": "test-date-picker" }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="reportDate"
                        onChange={(e) => setFieldValue("reportDate", e)}
                        value={reportDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid="test-report-date-error">
                        {errors.reportDate ? ERROR_MESSAGES.dateMissing : null}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
        </Form>
    );
};

export const UnconnectedEditReportModal = ({
    isLoading,
    isVisible,
    isRemoveModeActive,
    toggleOverlay,
    closeAllOverlays,
    ...formikProps
}: {
    isLoading: boolean;
    isVisible: boolean;
    isRemoveModeActive: boolean;
    closeAllOverlays: any;
    toggleOverlay: (_: {}) => void;
} & FormikProps<FormProps>) => {
    const teamIdParam = new URLSearchParams(window.location.search).get("team_id");
    const teamId = teamIdParam ? parseInt(teamIdParam) : null;

    const reportIdParam = new URLSearchParams(window.location.search).get("report_id");
    const reportId = reportIdParam ? parseInt(reportIdParam) : null;

    const reportQuery = useGetReport(reportId, reportId && teamId ? true : false);
    const reportData = reportQuery?.data ?? null;

    const teamName = reportData?.team?.name;
    const reportDate = new Date(reportData?.date + " 00:00:00");
    const numberOfInsights = reportData?.insights?.length;

    if (!formikProps.initialValues.reportId) {
        formikProps.values.reportId = reportId;
    }

    if (!formikProps.initialValues.reportDate) {
        formikProps.values.reportDate = reportDate;
    }

    if (!formikProps.initialValues.selectedTeam) {
        formikProps.values.selectedTeam = teamName;
    }

    const bodyItems = [
        {
            label: "Team:",
            value: teamName ? teamName : "Unknown",
        },
        {
            label: "Report Date:",
            value: reportDate ? moment(reportDate).format("DD-MMMM-YYYY") : "Unknown",
        },
        {
            label: "Number of Insights:",
            value: numberOfInsights >= 0 ? numberOfInsights : "Unknown",
        },
    ];
    return (
        <FormModal
            handleClose={() => {
                closeAllOverlays({});
            }}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeReportModal });
                toggleOverlay({ overlay: overlayNames.editReportModal });
            }}
            handleSubmit={formikProps.handleSubmit}
            isSubmitDisabled={isLoading}
            isSubmitLoading={isLoading}
            hasSecondaryButton={true}
            isVisible={isVisible || isRemoveModeActive}
            title={isRemoveModeActive ? "Remove report " : "Edit report"}
            extraActions={null}
            isSecondaryDisabled={null}
            isEditModeActive={true}
            isRemoveModeActive={isRemoveModeActive}
        >
            {isRemoveModeActive ? (
                <EditModalBody
                    isRemoveModeActive={true}
                    removalMessage="Are you sure that you would like to remove this report? All insights associated to this report will also be removed. Once removed, this action cannot be reversed."
                    items={bodyItems}
                />
            ) : (
                <EditReportForm {...formikProps} />
            )}
        </FormModal>
    );
};

export const EnhancedEditReportModal = withFormik<
    { patchReport?: any; deleteReport?: any },
    FormProps
>({
    enableReinitialize: true,
    handleSubmit: (
        { reportId, reportDate, isRemoveModeActive },
        { props: { patchReport, deleteReport } }
    ) => {
        const date = moment(reportDate).format("YYYY-MM-DD");
        if (!isRemoveModeActive) {
            patchReport({
                reportId,
                date,
            });
        } else {
            deleteReport({ reportId });
        }
    },
    mapPropsToValues: (props: any) => ({
        isRemoveModeActive: props.isRemoveModeActive,
        reportId: undefined,
        reportDate: undefined,
        selectedTeam: undefined,
    }),
    validationSchema: () => CreateReportSchema,
    validateOnChange: false,
    validateOnBlur: false,
})(UnconnectedEditReportModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isLoading: isCreatingReportSelector(state as never),
        isVisible: isShowing.editReportModal,
        isRemoveModeActive: isShowing.removeReportModal,
    };
};

export const ConnectedEditReportModal = connect(mapStateToProps, {
    patchReport,
    deleteReport,
    toggleOverlay: toggleOverlay,
    closeAllOverlays,
})(EnhancedEditReportModal);

export default ConnectedEditReportModal;
