import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";

import { nautilusGetRequest, nautilusPatchRequest } from "network";
import { insightsKeys, insightStatusKeys } from "reactQuery/keys";

import { DeepInsight, PatchedInsightWrite } from "./apiTypes";

export const useGetSingleInsight = (
    insightId: number,
    enabled: boolean = true,
    teamId?: number
): UseQueryResult<DeepInsight> => {
    const queryKey = insightsKeys.single(insightId);
    const params = teamId ? `?team_id=${teamId}` : undefined;
    return useQuery({
        queryKey,
        queryFn: async (): Promise<DeepInsight> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/insights/${insightId}`,
                params
            );
            return data;
        },
        enabled,
    });
};

interface InsightPatchQueryPayloadType {
    insightId: number;
    payload: PatchedInsightWrite;
}

async function patchInsight({
    payload,
    insightId,
}: InsightPatchQueryPayloadType): Promise<DeepInsight> {
    const { data } = await nautilusPatchRequest(
        `/api/target_reports/insights/${insightId}`,
        payload
    );
    return data;
}

export function usePatchInsight(): Partial<UseMutationResult<DeepInsight>> {
    return useMutation((insightPatchQueryPayload: InsightPatchQueryPayloadType) => {
        return patchInsight(insightPatchQueryPayload);
    });
}

export const useGetInsightStatuses = (
    enabled: boolean = true
): Partial<UseQueryResult<any>> => {
    const queryKey = insightStatusKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/insight_statuses/`
            );
            return data;
        },
        enabled,
    });
};
