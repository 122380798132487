import { splitQuerySnippet, summarizeQuerySnippet } from "./components/QuerySnippet";

export const csvFields = [
    {
        key: "watrhub_verified",
        label: "Verified",
    },
    {
        key: "highlight_snippet",
        label: "Query Snippet",
    },
    { key: "target_name", label: "Utility Name" },
    { key: "target_state", label: "State Code" },
    {
        key: "population",
        label: "Drinking Water Population Served",
    },
    {
        key: "municipal_population",
        label: "Municipal Population",
    },
    {
        key: "facility_size",
        label: "Facility MGD",
    },
    {
        key: "connection_count",
        label: "Connection Count",
    },
    {
        key: "contact_info",
        label: "Contact Information",
    },
    {
        key: "es_query",
        label: "Query Searched",
    },
    {
        key: "title",
        label: "Title",
    },
    {
        key: "created_date",
        label: "Date Found",
    },
    {
        key: "url",
        label: "URL",
    },
    { key: "last_modified", label: "Last Modified Date" },
    {
        key: "extracted_date",
        label: "Extracted Date",
    },
    {
        key: "extracted_date_inbound_search_date",
        label: "Within Search Date Range",
    },
    { key: "cip_classification_code", label: "Document Type" },
    { key: "tags", label: "Counties" },
    {
        key: "es_index",
        label: "ES Index",
    },
];

const columnDataIndexes = {
    watrhub_verified: 0,
    highlight_snippet: 1,
    target_name: 2,
    target_state: 3,
    population: 4,
    municipal_population: 5,
    facility_size: 6,
    connection_count: 7,
    contact_info: 8,
    es_query: 9,
    title: 10,
    created_date: 11,
    url: 12,
    comments: 13,
    extracted_date: 14,
    extracted_date_inbound_search_date: 15,
    found_multiple_demographics: 16,
    cip_classification_code: 17,
    tags: 18,
    es_index: 19,
};

export const detailPanelColumns = [
    csvFields[columnDataIndexes.title],
    csvFields[columnDataIndexes.highlight_snippet],
    csvFields[columnDataIndexes.contact_info],
];

export function cleanStringForCsv(string) {
    let reOpenMark = new RegExp("<mark>", "g");
    let reCloseMark = new RegExp("</mark>", "g");
    return (
        '"' +
        string
            .replace(reOpenMark, "*")
            .replace(reCloseMark, "*")
            .replace(/\n/g, " ")
            .replace(/\r/g, " ")
            .replace(/"/g, '""')
            .replace(/\s+/g, " ") +
        '"'
    );
}

export function formatResultsCsv(allData) {
    const headers = csvFields.map((header) => header.key);
    const lineArray = allData.map((rowData) =>
        headers
            .map((column) => {
                if (column === "highlight_snippet") {
                    const arr = splitQuerySnippet(rowData[column]);
                    const snippetArr = summarizeQuerySnippet(arr);
                    const query_snippet_text = snippetArr.reduce((final_text, item) => {
                        final_text += item.isHighlighted
                            ? `<mark>${item.text}</mark>`
                            : item.text;
                        return final_text;
                    }, "");
                    return cleanStringForCsv(query_snippet_text.toString());
                } else {
                    if (rowData[column]) {
                        return cleanStringForCsv(rowData[column].toString());
                    } else {
                        return cleanStringForCsv(" ");
                    }
                }
            })
            .join(",")
    );

    return (
        csvFields.map((header) => header.label).join(",") + "\n" + lineArray.join("\n")
    );
}

export function downloadCsv(content) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
        new Blob([content], { type: "application/octet-stream" })
    );
    const d = new Date();
    link.value = "download";
    link.download = `Citylitics-Search-${d.getFullYear()}-${
        d.getMonth() + 1
    }-${d.getDate()}.csv`;
    link.click();
}
