import { useLocation } from "react-router-dom";

import { BreadcrumbProps } from "components/molecules/Breadcrumbs";
import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { LWReadyForQCPathname } from "routes/LW";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? Number(teamIdString) : undefined;

    return { teamId };
};

export const readyForQCBreadcrumbs = () => {
    const { teamId } = pageParams();

    const breadcrumbs: BreadcrumbProps[] = [
        { name: "Labelling Workflow Feeds" },
        { name: "Ready For QC", url: `${LWReadyForQCPathname}?team_id=${teamId}` },
    ];

    return breadcrumbs;
};

const MyQueue = () => {
    const { teamId } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title="Ready For QC"
                    breadcrumbs={readyForQCBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.LW_READY_FOR_QC}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default MyQueue;
