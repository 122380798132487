import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { push } from "connected-react-router";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "citylitics-logo-dark.png";
import StyledButton from "components/atoms/StyledButton";
import Copyright from "components/nautilus/Copyright";
import HelpEmail from "components/nautilus/HelpEmail";
import PasswordChangeForm from "components/nautilus/PasswordChangeForm";
import { authPageTheme } from "constants/themes";
import { fetchUserData, analyticsEvent } from "stores/userStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import {
    passwordChangeSuccessSelector,
    isAuthenticatedSelector,
} from "stores/userStore/selectors";

//@ts-ignore
const useStyles = makeStyles(authPageTheme);

const PasswordChangeConfirmation = ({ push }) => {
    //@ts-ignore
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.descriptionText}>
                Your password has been successfully changed.
            </Typography>
            <StyledButton
                variant="edit-secondary"
                handleClick={() => {
                    push("/");
                }}
            >
                Go to dashboard
            </StyledButton>
        </>
    );
};

export const UnconnectedPasswordChange = ({
    analyticsEvent,
    success,
    push,
    isAuthenticated,
    fetchUserData,
}) => {
    //@ts-ignore
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) fetchUserData();
    }, [isAuthenticated, fetchUserData]);

    return (
        <Container maxWidth="xs">
            <div className={classes.container}>
                <img alt="WatrHub" className={classes.logo} src={logo} />
                <Typography className={classes.title} component="h1" variant="h5">
                    Change Password
                </Typography>

                {success ? (
                    <PasswordChangeConfirmation push={push} />
                ) : (
                    <PasswordChangeForm />
                )}
            </div>
            {!success && (
                <Grid container className={classes.actionLinks}>
                    <Grid item xs>
                        <Link
                            component="button"
                            data-testid="change-password-cancel-link"
                            onClick={() => {
                                analyticsEvent({
                                    action: eventActions.CLICKED,
                                    category: eventCategories.FORMS,
                                    label: "Cancel - Change Password",
                                });
                                history.goBack();
                            }}
                            variant="body2"
                        >
                            Cancel
                        </Link>
                    </Grid>
                    <Grid item>
                        <HelpEmail
                            emailSubject="Change Password"
                            helpText="Need help?"
                            onClick={() => {
                                analyticsEvent({
                                    action: eventActions.CLICKED,
                                    category: eventCategories.FORMS,
                                    label: "Need Help - Change Password",
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            <Copyright className={classes.copyright} />
        </Container>
    );
};

const mapStateToProps = (state) => ({
    success: passwordChangeSuccessSelector(state as never),
    isAuthenticated: isAuthenticatedSelector(state),
});

const ConnectedPasswordChange = connect(mapStateToProps, {
    analyticsEvent,
    push,
    fetchUserData,
})(UnconnectedPasswordChange);

export default ConnectedPasswordChange;
