import PDFDocument from "components/atoms/PDFDocument";
import { SearchPlanSummaryRead } from "reactQuery/hooks/apiTypes";

import RenderSummary from "../RenderSummary";

const RenderDocument = ({
    searchPlanSummary,
}: {
    searchPlanSummary: SearchPlanSummaryRead;
}) => {
    return (
        // @ts-ignore
        <PDFDocument title="Citylitics Search Plan Summary">
            {RenderSummary(searchPlanSummary)}
        </PDFDocument>
    );
};

export default RenderDocument;
