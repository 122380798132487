import axios, { AxiosResponse } from "axios";

const enforceTrailingSlash = (url: string) => {
    return url.endsWith("/") ? url : url + "/";
};

export function getCsrfCookie() {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";");
        for (let cookie of cookies) {
            const trimmedCookie = cookie.trim();
            if (trimmedCookie.startsWith("csrftoken=")) {
                cookieValue = decodeURIComponent(trimmedCookie.substring(10));
                break;
            }
        }
    }
    return cookieValue;
}

export const isErrorDueTo404 = (error: { status: number }) => error?.status === 404;

export const serverUrl = process.env.REACT_APP_API_ENDPOINT;

export const defaultConfig = {
    headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfCookie(),
    },
    withCredentials: true,
};

export function refreshConfig() {
    defaultConfig.headers["X-CSRFToken"] = getCsrfCookie();
}

export function nautilusGetRequest(
    uri: string,
    searchParams?: string,
    config: Object = defaultConfig
) {
    const params = searchParams ? searchParams : "";
    return axios.get(enforceTrailingSlash(`${serverUrl}${uri}`) + params, config);
}

export function nautilusDeleteRequest(uri: string, config: Object = defaultConfig) {
    return axios.delete(enforceTrailingSlash(`${serverUrl}${uri}`), config);
}

export function nautilusPatchRequest(
    uri: string,
    data?: Object,
    trailingSlash = true,
    config: Object = defaultConfig
) {
    return axios.patch(
        trailingSlash
            ? enforceTrailingSlash(`${serverUrl}${uri}`)
            : `${serverUrl}${uri}`,
        data,
        config
    );
}

export function nautilusPostRequest(
    uri: string,
    data?: Object,
    config: Object = defaultConfig
) {
    return axios.post(enforceTrailingSlash(`${serverUrl}${uri}`), data, config);
}

export function searchNautilusGetRequest(
    uri: string,
    searchText: string,
    config: Object = defaultConfig
) {
    const params = "?search=" + encodeURI(searchText);
    return nautilusGetRequest(uri, params);
}

export async function nautilusAsyncExhaustPaginatedGetRequest(
    uri: string,
    searchParams = ""
) {
    let results = [];
    let response: AxiosResponse;

    do {
        response = await nautilusGetRequest(uri, searchParams);
        results = results.concat(response.data.results);

        if (!response.data.next) break;

        const next_url = new URL(response.data.next);
        uri = next_url.pathname;
        searchParams = next_url.search;
    } while (response.data.next !== null);

    return results;
}

export function loginRedirect() {
    if (process.env.NODE_ENV === "development") {
        window.location.assign(`${serverUrl}:3000/login`);
    } else {
        window.location.assign(`${serverUrl}/login`);
    }
}
