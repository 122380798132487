import { IntelligenceScope, SearchPlanSummaryRead } from "reactQuery/hooks/apiTypes";

import { CombinedIntelligenceScope } from ".";

const canCombineIntelligenceScopes = (
    intelligenceScopeA: IntelligenceScope | CombinedIntelligenceScope,
    intelligenceScopeB: IntelligenceScope | CombinedIntelligenceScope
) => {
    if (!intelligenceScopeA || !intelligenceScopeB) {
        return false;
    }

    for (let key in intelligenceScopeA) {
        if (key.endsWith("_constraints") && intelligenceScopeB[key]) {
            if (
                JSON.stringify(intelligenceScopeA[key]) !==
                JSON.stringify(intelligenceScopeB[key])
            ) {
                return false;
            }
        }
    }
    return true;
};

const combineIntelligenceScopes = (
    intelligenceScopeA: IntelligenceScope | CombinedIntelligenceScope,
    intelligenceScopeB: IntelligenceScope | CombinedIntelligenceScope
) => {
    const combinedIntelligenceScope = {
        ...intelligenceScopeA,
        ...intelligenceScopeB,
        combinedIntelligenceScopes: (intelligenceScopeA as CombinedIntelligenceScope)
            .combinedIntelligenceScopes
            ? [
                  ...(intelligenceScopeA as CombinedIntelligenceScope)
                      .combinedIntelligenceScopes,
                  intelligenceScopeB,
              ]
            : [intelligenceScopeA, intelligenceScopeB],
    };

    for (let key in combinedIntelligenceScope) {
        if (!key.endsWith("_constraints") && key !== "combinedIntelligenceScopes") {
            delete combinedIntelligenceScope[key];
        }
    }

    return combinedIntelligenceScope;
};

export const getCombinedIntelligenceScopes = (
    intelligenceScopes: CombinedIntelligenceScope[] | IntelligenceScope[]
): CombinedIntelligenceScope[] => {
    const combinedIntelligenceScopes = [];

    intelligenceScopes.forEach((outerObj, outerIndex) => {
        let OuterIntelligenceScope = outerObj;
        intelligenceScopes.forEach((InnerIntelligenceScope, innerIndex) => {
            if (
                outerIndex !== innerIndex &&
                canCombineIntelligenceScopes(
                    OuterIntelligenceScope,
                    InnerIntelligenceScope
                )
            ) {
                OuterIntelligenceScope = combineIntelligenceScopes(
                    OuterIntelligenceScope,
                    InnerIntelligenceScope
                );
                intelligenceScopes[innerIndex] = null;
            }
        });
        intelligenceScopes[outerIndex] = OuterIntelligenceScope;
    });

    intelligenceScopes.forEach((intelligenceScope) => {
        if (intelligenceScope) combinedIntelligenceScopes.push(intelligenceScope);
    });

    return combinedIntelligenceScopes;
};
