import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons//Check";
import { Divider } from "antd";
import React from "react";

import ColumnData from "./ColumnData";
import QuerySnippet from "./QuerySnippet";
import { detailPanelColumns } from "../resultsTable";
import { targetSurfacerTableStyles } from "../styles";

function TableDetailPanel({ rowData, searchTextValue }) {
    const styles = targetSurfacerTableStyles();

    function formatContactPhoneNumber(contact) {
        const areaCode = contact.area_code_primary
            ? `(${contact.area_code_primary})`
            : "";
        const phone = contact.phone_primary ? contact.phone_primary.toString() : "";
        return areaCode && phone
            ? `${areaCode} ${phone.slice(0, 3)}-${phone.slice(3)}`
            : "";
    }

    const renderData = (key, data) => {
        if (data[key] === "{}") {
            return "-";
        } else if (key === "comments" && rowData.watrhub_verified === "1") {
            return "This is a Citylitics verified result.";
        } else if (key === "contact_info") {
            const contact = JSON.parse(data[key].replace(/&quot;/g, '"'));
            //for some reason \n doesn't create a new line when rendered in a TableCell
            //so we have to split up the text into multiple elements
            const contactName = contact.first_name
                ? contact.first_name
                : "" + contact.last_name
                ? contact.first_name
                : "";
            return (
                <div>
                    <Typography>
                        {" "}
                        <ColumnData
                            className={"hello"}
                            searchTextValue={searchTextValue}
                            fieldValue={contactName}
                        />{" "}
                    </Typography>
                    <Typography>
                        <ColumnData
                            searchTextValue={searchTextValue}
                            fieldValue={contact.email ? contact.email : ""}
                        />
                    </Typography>
                    <Typography>
                        <ColumnData
                            searchTextValue={searchTextValue}
                            fieldValue={formatContactPhoneNumber(contact)}
                        />
                    </Typography>
                </div>
            );
        } else if (key === "found_multiple_demographics") {
            return <Typography> {data[key] ? <CheckIcon /> : "-"}</Typography>;
        } else if (key === "title") {
            return (
                <ColumnData
                    searchTextValue={searchTextValue}
                    fieldValue={
                        !data[key] || data[key].trim() === "" ? data["url"] : data[key]
                    }
                />
            );
        } else if (key !== "highlight_snippet") {
            return (
                <ColumnData searchTextValue={searchTextValue} fieldValue={data[key]} />
            );
        }
        return (
            <QuerySnippet
                highlight_snippet={data[key]}
                searchTextValue={searchTextValue}
            />
        );
    };

    return (
        <div className={styles.expandableRow}>
            {detailPanelColumns.map((col, index) => {
                return (
                    <div key={index}>
                        <div className={styles.expandableRowContent} key={index}>
                            <div className={styles.expandableRowDataTitle}>
                                {col.label}
                            </div>

                            <div className={styles.expandableRowData}>
                                {renderData(col.key, rowData)}
                            </div>
                        </div>
                        {index !== detailPanelColumns.length - 1 && (
                            <Divider className={styles.divider}></Divider>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default TableDetailPanel;
