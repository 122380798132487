import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import logo from "citylitics-logo-dark.png";
import Copyright from "components/nautilus/Copyright";
import LoginForm from "components/nautilus/LoginForm";
import { authPageTheme } from "constants/themes";
import { analyticsEvent } from "network/tracking";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import { extractNextUrlParameter } from "utils/redirect";

const useStyles = makeStyles(authPageTheme);

const LoginPage = () => {
    const whoamiQuery = useGetWhoami();
    const { push } = useHistory();
    const [otpRequired, setOtpRequired] = useState<boolean>(false);

    useEffect(() => {
        if (whoamiQuery.isSuccess) push(extractNextUrlParameter());
    }, [whoamiQuery.isSuccess]);

    const classes = useStyles();
    const forgotPasswordClick = (event) => {
        event.preventDefault();
        analyticsEvent({
            action: eventActions.CLICKED,
            category: eventCategories.FORMS,
            executeOnceComplete: () => {
                push("/forgot_password");
            },
            label: "Forgot Password - Sign In",
        });
    };

    return (
        <Container maxWidth="xs">
            <div className={classes.container}>
                <a href="https://www.citylitics.com">
                    <img alt="WatrHub" className={classes.logo} src={logo} />
                </a>
                <Typography.Title level={3} style={{ margin: "10px 0 25px" }}>
                    Sign In
                </Typography.Title>
                <LoginForm otpRequired={otpRequired} setOtpRequired={setOtpRequired} />
            </div>
            <div className={classes.actionLinks}>
                {otpRequired ? (
                    <Link
                        href="mailto:help@citylitics.com?subject=2FA Login Help"
                        variant="body2"
                    >
                        Need Help Logging In?
                    </Link>
                ) : (
                    <Link onClick={forgotPasswordClick} href="" variant="body2">
                        Forgot password?
                    </Link>
                )}
            </div>
            <Copyright className={classes.copyright} />
        </Container>
    );
};

export default LoginPage;
