// TODO: rename folder to "facilities" and make necessary changes to other files
// TODO: change wasteWaterFacility variables to facility
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { nautilusGetRequest } from "network";
import { displayErrorSnack } from "stores/uiStore/actionTypes";
import { wastewaterFacilitiesReducerName } from "stores/wastewaterFacilities/constants";

const formatName = (name) => `${wastewaterFacilitiesReducerName}/${name}`;

export const setInsightForWasteWaterSystemLinkage = createAction(
    formatName("setInsightForWasteWaterSystemLinkage")
);

export const setInsightForWasteWaterSystemRemoval = createAction(
    formatName("setInsightForWasteWaterSystemRemoval")
);

export const fetchWastewaterFacilitiesForOwner = createAsyncThunk(
    formatName("fetchWastewaterSystemsForOwner"),
    async ({ ownerId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest(
                "/api/universe/wastewater_facilities/",
                `?owner=${ownerId}`
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch Wastewater Facilities`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);
