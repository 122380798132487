import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { useLocation } from "react-router-dom";

import Text from "components/atoms/Text";
import { OverviewCard } from "components/molecules/OverviewCard";
import { useGetAllSearchPlans } from "reactQuery/hooks/useSearchPlansList";

const SubscriptionsSection = ({}) => {
    let { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));

    const subscriptionsQuery = useGetAllSearchPlans(teamId);
    const subscriptionCards = subscriptionsQuery?.data?.results.map((subscription) => {
        const emailSubject = `${subscription.team_name} - ${subscription.product_name} `;

        const emailLink = `mailto:subscriptions@citylitics.com?subject=${encodeURIComponent(
            emailSubject
        )}`;

        return (
            <OverviewCard
                title={subscription.product_name}
                subtitle={
                    "Last Updated: " +
                    moment(subscription.last_updated).format("YYYY-MM-DD")
                }
                primaryActionText="View"
                primaryActionUrl={subscription.link}
                secondaryActionText="Request Update"
                secondaryActionUrl={emailLink}
            />
        );
    });

    const subscriptionsSection = (
        <div>
            <Text variant="pageHeader">Subscriptions</Text>
            <Grid container spacing={4} style={{ marginBottom: "50px" }}>
                {subscriptionCards}
            </Grid>
        </div>
    );

    if (!subscriptionsQuery.isSuccess) return null;

    return subscriptionsQuery.data.results.length > 0 ? subscriptionsSection : null;
};

export default SubscriptionsSection;
