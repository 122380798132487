import { StyleSheet } from "@react-pdf/renderer";

import { additionalColors } from "constants/colors";

const fontSizes = {
    title: 15,
    header: 12,
    body: 10,
    subtext: 8,
};

export const pdfStyle = StyleSheet.create({
    page: {
        flexDirection: "column",
        color: "#000000",
        backgroundColor: "#FFFFFF",
        padding: 25,
        fontSize: fontSizes.body,
    },
    pageTitle: {
        fontSize: fontSizes.title,
    },
    logo: {
        padding: 1,
        width: "25%",
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: 25,
        color: additionalColors.grey500,
    },
    accountManagerContainer: {
        color: additionalColors.grey600,
        fontSize: fontSizes.body,
        marginTop: 5,
    },
    sectionContainer: {
        marginTop: 25,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    sectionTitle: {
        fontSize: fontSizes.title,
        borderBottom: `1pt solid ${additionalColors.grey300}`,
        paddingBottom: 5,
        width: "100%",
    },
    innerSectionContainer: {
        borderTop: `1pt solid ${additionalColors.grey300}`,
        marginTop: 5,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    innerSectionTitleContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 15,
    },
    innerSectionTagContainer: {
        fontSize: fontSizes.subtext,
        marginLeft: 5,
    },
    innerSectionTitle: {
        fontSize: fontSizes.header,
    },
    innerSectionTitleDescriptionText: {
        marginTop: 5,
        width: "100%",
        fontSize: fontSizes.body,
        color: additionalColors.grey500,
        wordBreak: "normal",
    },
    additionalNotesText: {
        marginTop: 15,
        fontSize: fontSizes.body,
        wordBreak: "normal",
    },
    itemContainer: {
        width: "33%",
        marginTop: 15,
        paddingRight: 10,
    },
    itemTitleContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 3,
    },
    itemTitle: {
        color: additionalColors.grey500,
        fontSize: fontSizes.body,
    },
    itemValueContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: fontSizes.body,
    },
    itemValue: { marginRight: 5, marginBottom: 5 },
    itemValuePriority: {
        color: additionalColors.grey600,
    },
    itemValueTag: {
        fontSize: fontSizes.subtext,
    },
    constraintContainer: {
        marginTop: 15,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    constraintTitleContainer: {
        width: "100%",
        marginBottom: -5,
        display: "flex",
        alignItems: "flex-start",
    },
    constraintTitle: {
        fontSize: fontSizes.body,
        color: additionalColors.grey600,
        borderBottom: `1pt solid black`,
    },
    checkItemContainer: {
        marginTop: 15,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    checkItemIconContainer: {
        marginRight: 10,
    },
    checkItemIcon: {
        width: 15,
    },
    subItemContainer: {
        marginTop: 5,
        marginLeft: 26,
    },
    subItemText: {
        marginTop: 2,
        color: additionalColors.grey600,
    },
    timelineContainer: {},
    timelineItemContainer: {
        marginTop: 20,
    },
    timelineComment: {
        color: "black",
    },
    timelineTitleContainer: {
        fontSize: fontSizes.header,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        color: additionalColors.grey600,
    },
    timelineTitleTime: {
        fontSize: fontSizes.subtext,
        marginLeft: 5,
        color: additionalColors.grey500,
    },
    timelineContentContainer: {
        marginTop: 2,
    },
    timelineContentText: {
        marginTop: 2,
        fontSize: fontSizes.body,
        color: additionalColors.grey500,
    },
    timelineContentTextImportant: {
        fontSize: fontSizes.body,
        color: "black",
    },
});
