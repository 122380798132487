import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

const roundDateToNearestSecond = (date) => {
    return new Date(Math.round(new Date(date).getTime() / 1000) * 1000);
};

export const getTimeline = (searchPlanSummary) => {
    let timelineEntries = [];
    let timeline = [];

    const processObject = ({
        actionTitles,
        category,
        itemTitle,
        obj,
        valueKey,
    }: {
        actionTitles?: {
            created: string;
            updated?: string;
        };
        category: string;
        itemTitle: string;
        obj: any;
        valueKey?: string;
    }) => {
        let added_by_name = obj.added_by?.first_name + " " + obj.added_by?.last_name;
        let updated_by_name =
            obj.updated_by?.first_name + " " + obj.updated_by?.last_name;

        let date_created = roundDateToNearestSecond(obj.date_created);
        let last_updated = roundDateToNearestSecond(obj.last_updated);

        let actionTitleCreated = actionTitles?.created || "created";
        let actionTitleUpdated = actionTitles?.updated || "updated";

        timelineEntries.push({
            action:
                category === "search plan" && itemTitle !== "Search Plan"
                    ? actionTitleUpdated
                    : actionTitleCreated,
            author: added_by_name,
            category,
            date: date_created,
            title: valueKey ? obj[valueKey] : `${itemTitle} ${actionTitleCreated}`,
        });

        if (
            last_updated.toISOString() !== date_created.toISOString() ||
            updated_by_name !== added_by_name
        ) {
            timelineEntries.push({
                action: actionTitleUpdated,
                author: updated_by_name,
                category,
                date: last_updated,
                title: valueKey ? obj[valueKey] : `${itemTitle} ${actionTitleUpdated}`,
            });
        }
    };

    processObject({
        itemTitle: "Search Plan",
        obj: searchPlanSummary,
        category: "search plan",
    });
    searchPlanSummary.comments.forEach((comment) =>
        processObject({
            actionTitles: { created: "added" },
            itemTitle: "Comment",
            obj: comment,
            category: "comment",
            valueKey: "text",
        })
    );
    searchPlanSummary.intelligence_scopes.forEach(
        (intelligence_scope: IntelligenceScope) =>
            processObject({
                itemTitle: "Intelligence Scope",
                obj: intelligence_scope,
                category: "search plan",
            })
    );
    searchPlanSummary.subscription &&
        processObject({
            itemTitle: "Subscription",
            obj: searchPlanSummary.subscription,
            category: "search plan",
        });

    timelineEntries.sort((a, b) => {
        let dateA = new Date(a.date),
            dateB = new Date(b.date);
        if (dateB.getTime() - dateA.getTime() === 0) {
            return a.category === "comment" ? -1 : a.event === "updated" ? -1 : 1;
        } else {
            return dateB.getTime() - dateA.getTime();
        }
    });

    timelineEntries.forEach((entry) => {
        const lastEntry = timeline.at(-1);
        if (
            lastEntry?.date?.toISOString() === entry.date.toISOString() &&
            lastEntry?.category === entry.category &&
            lastEntry?.author === entry.author
        ) {
            let entryExists = lastEntry.entries.some(
                (obj) => obj.title === entry.title
            );
            if (!entryExists) {
                lastEntry.entries.push({
                    important: false,
                    title: entry.title,
                });
            }
        } else {
            timeline.push({
                author: entry.author,
                category: entry.category,
                date: entry.date,
                title: `${entry.author} ${entry.action} ${
                    entry.category === "comment"
                        ? `a ${entry.category}`
                        : `the ${entry.category}`
                }`,
                entries: [
                    {
                        important: entry.category === "comment" ? true : false,
                        title: entry.title,
                    },
                ],
            });
        }
    });

    return timeline;
};
