import { Alert, Button, Divider, InputNumber, Slider } from "antd";
import { useEffect, useState } from "react";

import Text from "components/atoms/Text";
import { formatNumber } from "constants/formatters";

import HeaderIcons from "./HeaderIcons";
import { PaginatedTableCommonColumnProps } from "../types";

interface NumberColumnProps extends PaginatedTableCommonColumnProps {
    format?: "currency";
}

const numberColumn = ({
    allowFiltering = true,
    allowSorting = true,
    filteredInfo,
    filters,
    format,
    isLoading,
    key,
    searchTextValue,
    sortedInfo,
    styles,
    title,
}: NumberColumnProps) => {
    const [currentValue, setCurrentValue] = useState<{
        max: number;
        min: number;
    }>({
        max: undefined,
        min: undefined,
    });

    useEffect(() => {
        if (filteredInfo) {
            if (
                filteredInfo[key]?.[0]?.min !== undefined ||
                filteredInfo[key]?.[0]?.max !== undefined
            ) {
                setCurrentValue({
                    max: filteredInfo[key]?.[0]?.max,
                    min: filteredInfo[key]?.[0]?.min,
                });
            }
        }
    }, [filteredInfo]);

    useEffect(() => {
        if (
            (filters?.max !== undefined || filters?.min !== undefined) &&
            currentValue.max === undefined &&
            currentValue.min === undefined
        ) {
            setCurrentValue({
                max: filters.max,
                min: filters.min,
            });
        }
    }, [filters]);

    return {
        title: title,
        dataIndex: key,
        key: key,
        render: (_, record) => (
            <Text searchTextValue={searchTextValue}>
                {format
                    ? record[key]
                        ? `$${record[key].toLocaleString()}`
                        : ""
                    : formatNumber(record[key])}
            </Text>
        ),
        filterIcon: (
            <HeaderIcons
                styles={styles}
                isLoading={isLoading}
                showFilteringIcon={true}
                showSortingIcon={true}
            />
        ),
        filterDropdown: isLoading ? (
            <></>
        ) : allowFiltering ? (
            ({ setSelectedKeys, confirm, clearFilters }) => (
                <>
                    <Slider
                        range
                        defaultValue={[filters?.min, filters?.max]}
                        value={[currentValue.min, currentValue.max]}
                        onChange={(value) => {
                            setCurrentValue({ min: value[0], max: value[1] });
                        }}
                        min={0}
                        max={filters?.max}
                        step={1}
                        tooltip={{ formatter: formatNumber }}
                    />
                    <InputNumber
                        data-testid={"min"}
                        min={0}
                        addonBefore={"min"}
                        max={filters?.max}
                        style={{ margin: "0 16px" }}
                        value={currentValue.min}
                        onChange={(value) => {
                            setCurrentValue({ ...currentValue, min: value });
                        }}
                        formatter={formatNumber}
                    />
                    <InputNumber
                        data-testid={"max"}
                        min={0}
                        addonBefore={"max"}
                        max={filters?.max}
                        style={{ margin: "0 16px" }}
                        value={currentValue.max}
                        onChange={(value) => {
                            setCurrentValue({ ...currentValue, max: value });
                        }}
                        formatter={formatNumber}
                    />
                    <Divider />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            type="link"
                            onClick={() => {
                                setCurrentValue({
                                    min: filters?.min,
                                    max: filters?.max,
                                });
                                clearFilters();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                        {currentValue.min > currentValue.max ? (
                            <Alert
                                message="Max number needs to be higher than min number"
                                type="error"
                                style={{ height: 32 }}
                            />
                        ) : null}
                        <Button
                            type="primary"
                            style={{ margin: "0 16px 12px" }}
                            onClick={() => {
                                // setSelectedKeys necessary to retrigger onFilter function
                                // if the FilterDropdown is closed and reopened before clicking 'OK'
                                setSelectedKeys([
                                    {
                                        min: currentValue.min,
                                        max: currentValue.max,
                                    },
                                ]);
                                confirm({ closeDropdown: true });
                            }}
                            disabled={currentValue.min > currentValue.max}
                        >
                            OK
                        </Button>
                    </div>
                </>
            )
        ) : null,
        filteredValue:
            filteredInfo[key] &&
            (currentValue.min !== filters?.min || currentValue.max !== filters?.max)
                ? filteredInfo[key]
                : null,
        sorter: isLoading ? undefined : allowSorting ? () => 0 : null,
        sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
    };
};

export default numberColumn;
