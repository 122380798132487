import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ReactMarkdown from "react-markdown";

import { factoidStyles } from "components/cdv/OpportunityCard/styles";

import { useGetFilteredNotes } from "../../../reactQuery/hooks/useNotes";
import StyledButton from "../../atoms/StyledButton";

export const buttonTestIds = {
    editIntelligenceNotes: (insightId, noteId) =>
        `insight ${insightId} intelligence notes ${noteId}`,
};

const IntelligenceNotesList = ({ insight, isEditModeActive, onEditClick }) => {
    const styles = factoidStyles();
    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const intelligenceNotesQuery = useGetFilteredNotes({
        insight: insight.id,
    });
    if (!intelligenceNotesQuery.isSuccess) return null;

    if (!isEditModeActive && intelligenceNotesQuery?.data?.results?.length === 0) {
        return null;
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={3}>
                <Typography className={styles.label}>Intelligence Notes</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Grid container>
                    {intelligenceNotesQuery.data.results.map((note, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={10}>
                                    <div className={styles.value}>
                                        <ReactMarkdown>{note.notes}</ReactMarkdown>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div
                                        style={{
                                            marginRight: isNotMobileScreen
                                                ? null
                                                : "-80px",
                                        }}
                                    >
                                        {" "}
                                        {isEditModeActive ? (
                                            <StyledButton
                                                data-testid={buttonTestIds.editIntelligenceNotes(
                                                    insight.id,
                                                    note.id
                                                )}
                                                onClick={() => {
                                                    onEditClick(insight, note);
                                                }}
                                                variant="edit-secondary"
                                            >
                                                Edit
                                            </StyledButton>
                                        ) : null}
                                    </div>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};

export default IntelligenceNotesList;
