import LinearProgress from "@material-ui/core/LinearProgress";
import { Alert } from "antd";
import { useEffect, useState } from "react";

import { Modal } from "components/molecules/Modal";
import ModalButton from "components/molecules/ModalButton";
import { additionalColors } from "constants/colors";
import { useCreateSharedOpportunities } from "reactQuery/hooks/useSharedOpportunities";

import classes from "./style.module.css";

interface ShareOpportunitiesModalProps {
    handleClose: () => void;
    indicatorGroupId?: number;
    opportunityIds: number[];
    opportunityCount: number;
    reportId?: number;
    teamId: number;
    visible: boolean;
}

const ShareOpportunitiesModal = ({
    handleClose,
    opportunityIds,
    opportunityCount,
    reportId,
    teamId,
    visible,
}: ShareOpportunitiesModalProps) => {
    const [linkCopiedAlertVisible, setLinkCopiedAlertVisible] = useState(false);
    const {
        data: newSharedOpportunities,
        isLoading,
        isSuccess,
        mutate: createSharedOpportunity,
    } = useCreateSharedOpportunities({
        reportId,
        teamId,
    });
    useEffect(() => {
        if (visible) {
            setLinkCopiedAlertVisible(false);
            createSharedOpportunity(opportunityIds);
        }
    }, [visible]);

    return (
        <Modal
            actions={null}
            isVisible={visible}
            extraActions={null}
            handleClose={() => {
                handleClose();
            }}
            title={
                isLoading
                    ? "Generating Shared Opportunities Link..."
                    : "Share Opportunities"
            }
            width={600}
        >
            {isSuccess ? (
                <>
                    {linkCopiedAlertVisible ? (
                        <div className={classes.notify}>
                            <Alert
                                message="Link copied to clipboard"
                                type="success"
                                showIcon
                            />
                        </div>
                    ) : null}
                    <span
                        className={classes.description}
                    >{`You have requested to share ${
                        opportunityCount > 1
                            ? `${opportunityCount} Opportunities`
                            : "an Opportunity"
                    }.`}</span>
                    <div className={classes.section}>
                        <div className={classes.title}>Share Direct Link</div>
                        <div>You can share the direct link to your PDF</div>
                        <div className={classes.row}>
                            <input
                                className={classes.input}
                                value={newSharedOpportunities.link}
                                style={{
                                    borderColor: additionalColors.grey600,
                                    color: additionalColors.grey600,
                                }}
                                readOnly
                            />
                            <ModalButton
                                color="primary"
                                isLoading={isLoading}
                                isVisible={true}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        newSharedOpportunities.link
                                    );
                                    setLinkCopiedAlertVisible(true);
                                }}
                                variant="contained"
                            >
                                Copy
                            </ModalButton>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <div className={classes.title}>Preview Your PDF</div>
                        <div
                            className={[classes.row, classes["preview-row"]].join(" ")}
                        >
                            <div className={classes["preview-text"]}>
                                You can view your PDF, and download your file directly
                            </div>
                            <ModalButton
                                color="primary"
                                isLoading={isLoading}
                                isVisible={true}
                                onClick={() =>
                                    window.open(
                                        /^https?:\/\//.test(newSharedOpportunities.link)
                                            ? newSharedOpportunities.link
                                            : `//${newSharedOpportunities.link}`,
                                        "_blank"
                                    )
                                }
                                variant="outlined"
                            >
                                Preview PDF
                            </ModalButton>
                        </div>
                    </div>
                </>
            ) : (
                <LinearProgress />
            )}
        </Modal>
    );
};

export default ShareOpportunitiesModal;
