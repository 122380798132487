import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import React from "react";
import { connect } from "react-redux";

import { Modal } from "components/molecules/Modal";
import { generatedLinkSelector } from "stores/targets/selectors";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const UnconnectedStaticLinkModal = ({
    generatedLink,
    isVisible,
    toggleOverlay,
}) => {
    return (
        <Modal
            isVisible={isVisible && generatedLink}
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.staticLinkModal });
            }}
            title="Static Link Generated"
        >
            <div>
                <Divider />
                <Link data-testid="static-link" href={generatedLink} target="_blank">
                    {generatedLink}
                </Link>
                <Divider />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isVisible: isShowing.staticLinkModal,
        generatedLink: generatedLinkSelector(state),
    };
};

const ConnectedStaticLinkModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
})(UnconnectedStaticLinkModal);

export default ConnectedStaticLinkModal;
