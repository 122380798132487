import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import React from "react";

import StyledButton from "components/atoms/StyledButton";
import { overlayNames } from "stores/uiStore/constants";

import { targetSurfacerTableStyles } from "../styles";

function TableHeader({
    numResults,
    handleExpandRows,
    handleCollapseRows,
    setSelectedSearchRecord,
    toggleOverlay,
    unique_utilities_count,
}) {
    const styles = targetSurfacerTableStyles();
    return (
        <>
            {" "}
            {!unique_utilities_count ? null : (
                <Alert variant="filled" severity="success" className={styles.alert}>
                    Unique utilities in result set: {unique_utilities_count}
                </Alert>
            )}
            <Grid
                container
                justifyContent="flex-start"
                className={styles.tableButtonGroup}
                spacing={2}
            >
                <Grid item>
                    <StyledButton
                        color="primary"
                        onClick={() => {
                            setSelectedSearchRecord({ selectedSearchRecord: null });
                            toggleOverlay({
                                overlay: overlayNames.targetSurfacerSearchModal,
                            });
                        }}
                        variant="contained"
                    >
                        Generate New Query
                    </StyledButton>
                </Grid>
                <Grid item>
                    <StyledButton
                        color="secondary"
                        onClick={() => {
                            handleExpandRows();
                        }}
                        variant="contained"
                    >
                        Expand All
                    </StyledButton>
                </Grid>
                <Grid item>
                    <StyledButton
                        color="secondary"
                        onClick={() => {
                            handleCollapseRows();
                        }}
                        variant="contained"
                    >
                        Collapse All
                    </StyledButton>
                </Grid>
                <Grid item>
                    <StyledButton
                        data-testid="clearResultsBtn"
                        disabled={numResults === 0}
                        onClick={() =>
                            toggleOverlay({
                                overlay: overlayNames.clearQueryResultsModal,
                            })
                        }
                        variant="contained"
                    >
                        Clear Results
                    </StyledButton>
                </Grid>
            </Grid>
        </>
    );
}

export default TableHeader;
