import { Button, DatePicker } from "antd";
import { isWithinInterval } from "date-fns";
import _ from "lodash";
import moment from "moment";
import React from "react";
import Highlighter from "react-highlight-words";

import { additionalColors } from "constants/colors";

const { RangePicker } = DatePicker;

export const DateFilterProps = (
    dataIndex,
    searchTextValue,
    filteredInfo,
    dateRange,
    setDateRange,
    dateRangeKeys,
    setDateRangeKeys
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <>
            <RangePicker
                allowEmpty={false}
                format={"YYYY-MM-DD"}
                value={dateRange}
                onChange={(value) => {
                    let keys = [];
                    if (value) {
                        const start = _.get(value[0], ["_d"], null);
                        const end = _.get(value[1], ["_d"], null);
                        setDateRange([moment(start), moment(end)]);
                        keys = [
                            {
                                start: start,
                                end: end,
                            },
                        ];
                    } else {
                        setDateRange([]);
                    }
                    setSelectedKeys(value ? keys : []);
                }}
            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: "0 16px 12px" }}
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        setDateRangeKeys({ start: dateRange[0], end: dateRange[1] });
                    }}
                >
                    OK
                </Button>
            </div>
        </>
    ),
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            setDateRange([
                dateRangeKeys["start"] ? moment(dateRangeKeys["start"]) : null,
                dateRangeKeys["end"] ? moment(dateRangeKeys["end"]) : null,
            ]);
        }
    },
    filteredValue: filteredInfo[dataIndex] || null,
    onFilter: (value, record) => {
        return record[dataIndex] === "Unknown" || record[dataIndex]
            ? isWithinInterval(new Date(record[dataIndex].replaceAll("?", "01")), {
                  start: new Date(value.start),
                  end: new Date(value.end),
              })
            : "";
    },
    render: (report_date) => (
        <div style={{ maxWidth: "90px", fontSize: "10pt" }}>
            {searchTextValue ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: additionalColors.lightOrange,
                        padding: 0,
                    }}
                    searchWords={[searchTextValue]}
                    autoEscape
                    textToHighlight={report_date ? report_date.toString() : ""}
                />
            ) : report_date ? (
                report_date
            ) : (
                ""
            )}
        </div>
    ),
});
