import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

import IntelligenceScopesSection from "../RenderIntelligenceScopesSection";

export enum RankSection {
    Feeds = "Feeds",
    Priority = "Priority",
}

const RenderIntelligenceScopes = (allIntelligenceScopes: IntelligenceScope[]) => {
    const scopeSections = [];

    const priorityIntelligenceScopes = allIntelligenceScopes.filter(
        (intelligenceScope) => intelligenceScope.rank.startsWith(RankSection.Priority)
    );
    if (priorityIntelligenceScopes.length) {
        scopeSections.push(
            IntelligenceScopesSection(priorityIntelligenceScopes, RankSection.Priority)
        );
    }

    const feedsIntelligenceScopes = allIntelligenceScopes.filter((intelligenceScope) =>
        intelligenceScope.rank.startsWith(RankSection.Feeds)
    );
    if (feedsIntelligenceScopes.length) {
        scopeSections.push(
            IntelligenceScopesSection(feedsIntelligenceScopes, RankSection.Feeds)
        );
    }

    return scopeSections;
};

export default RenderIntelligenceScopes;
