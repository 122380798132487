// TODO: change WASTE_WATER_FACILITY variables to FACILITY
const storeName = "universeStore";

export const actionTypes = {
    SEARCH_FOR_COMPANY: `${storeName}/SEARCH_FOR_COMPANY`,
    SEARCH_FOR_COMPANY_FAILED: `${storeName}/SEARCH_FOR_COMPANY_FAILED`,
    SEARCH_FOR_COMPANY_RESULTS: `${storeName}/SEARCH_FOR_COMPANY_RESULTS`,
    CLEAR_COMPANY_SEARCH_RESULTS: `${storeName}/CLEAR_COMPANY_SEARCH_RESULTS`,
    SEARCH_FOR_CONTACT: `${storeName}/SEARCH_FOR_CONTACT`,
    SEARCH_FOR_CONTACT_FAILED: `${storeName}/SEARCH_FOR_CONTACT_FAILED`,
    SEARCH_FOR_CONTACT_RESULTS: `${storeName}/SEARCH_FOR_CONTACT_RESULTS`,
    CLEAR_CONTACT_SEARCH_RESULTS: `${storeName}/CLEAR_CONTACT_SEARCH_RESULTS`,
    SEARCH_FOR_DRINKING_WATER_SYSTEM: `${storeName}/SEARCH_FOR_DRINKING_WATER_SYSTEM`,
    SEARCH_FOR_DRINKING_WATER_SYSTEM_FAILED: `${storeName}/SEARCH_FOR_DRINKING_WATER_SYSTEM_FAILED`,
    SEARCH_FOR_DRINKING_WATER_SYSTEM_RESULTS: `${storeName}/SEARCH_FOR_DRINKING_WATER_SYSTEM_RESULTS`,
    CLEAR_DRINKING_WATER_SYSTEM_RESULTS: `${storeName}/CLEAR_DRINKING_WATER_SYSTEM_RESULTS`,
    SEARCH_FOR_OWNER: `${storeName}/SEARCH_FOR_OWNER`,
    SEARCH_FOR_OWNER_FAILED: `${storeName}/SEARCH_FOR_OWNER_FAILED`,
    SEARCH_FOR_OWNER_RESULTS: `${storeName}/SEARCH_FOR_OWNER_RESULTS`,
    CLEAR_OWNER_SEARCH_RESULTS: `${storeName}/CLEAR_OWNER_SEARCH_RESULTS`,
    SEARCH_FOR_LOCATION: `${storeName}/SEARCH_FOR_LOCATION`,
    SEARCH_FOR_LOCATION_FAILED: `${storeName}/SEARCH_FOR_LOCATION_FAILED`,
    SEARCH_FOR_LOCATION_RESULTS: `${storeName}/SEARCH_FOR_LOCATION_RESULTS`,
    CLEAR_LOCATION_SEARCH_RESULTS: `${storeName}/CLEAR_LOCATION_SEARCH_RESULTS`,
    SEARCH_FOR_WASTE_WATER_FACILITY: `${storeName}/SEARCH_FOR_WASTE_WATER_FACILITY`,
    SEARCH_FOR_WASTE_WATER_FACILITY_FAILED: `${storeName}/SEARCH_FOR_WASTE_WATER_FACILITY_FAILED`,
    SEARCH_FOR_WASTE_WATER_FACILITY_RESULTS: `${storeName}/SEARCH_FOR_WASTE_WATER_FACILITY_RESULTS`,
    CLEAR_WASTE_WATER_FACILITY_SEARCH_RESULTS: `${storeName}/CLEAR_WASTE_WATER_FACILITY_SEARCH_RESULTS`,
};

export const searchForCompany = (searchTerm) => ({
    type: actionTypes.SEARCH_FOR_COMPANY,
    payload: {
        searchTerm,
    },
});

export const searchForCompanyResults = (companies) => ({
    type: actionTypes.SEARCH_FOR_COMPANY_RESULTS,
    payload: {
        companies,
    },
});

export const searchForCompanyFailed = () => ({
    type: actionTypes.SEARCH_FOR_COMPANY_FAILED,
    payload: {},
});

export const clearCompanySearchResults = () => ({
    type: actionTypes.CLEAR_COMPANY_SEARCH_RESULTS,
    payload: {},
});

export const searchForContact = (searchTerm) => ({
    type: actionTypes.SEARCH_FOR_CONTACT,
    payload: {
        searchTerm,
    },
});

export const searchForContactResults = (contacts) => ({
    type: actionTypes.SEARCH_FOR_CONTACT_RESULTS,
    payload: {
        contacts,
    },
});

export const searchForContactFailed = () => ({
    type: actionTypes.SEARCH_FOR_CONTACT_FAILED,
    payload: {},
});

export const clearContactSearchResults = () => ({
    type: actionTypes.CLEAR_CONTACT_SEARCH_RESULTS,
    payload: {},
});

export const searchForLocation = (searchTerm) => ({
    type: actionTypes.SEARCH_FOR_LOCATION,
    payload: {
        searchTerm,
    },
});

export const searchForLocationResults = (locations) => ({
    type: actionTypes.SEARCH_FOR_LOCATION_RESULTS,
    payload: {
        locations,
    },
});

export const searchForLocationFailed = () => ({
    type: actionTypes.SEARCH_FOR_LOCATION_FAILED,
    payload: {},
});

export const searchForDrinkingWaterSystem = (searchTerm) => ({
    type: actionTypes.SEARCH_FOR_DRINKING_WATER_SYSTEM,
    payload: {
        searchTerm,
    },
});

export const searchForDrinkingWaterSystemResults = (waterSystems) => ({
    type: actionTypes.SEARCH_FOR_DRINKING_WATER_SYSTEM_RESULTS,
    payload: {
        waterSystems,
    },
});

export const searchForDrinkingWaterSystemFailed = () => ({
    type: actionTypes.SEARCH_FOR_DRINKING_WATER_SYSTEM_FAILED,
    payload: {},
});

export const clearDrinkingWaterSystemSearchResults = () => ({
    type: actionTypes.CLEAR_DRINKING_WATER_SYSTEM_RESULTS,
    payload: {},
});

export const searchForWasteWaterFacility = (searchTerm) => ({
    type: actionTypes.SEARCH_FOR_WASTE_WATER_FACILITY,
    payload: {
        searchTerm,
    },
});

export const searchForWasteWaterFacilityResults = (waterSystems) => ({
    type: actionTypes.SEARCH_FOR_WASTE_WATER_FACILITY_RESULTS,
    payload: {
        waterSystems,
    },
});

export const searchForWasteWaterFacilityFailed = () => ({
    type: actionTypes.SEARCH_FOR_WASTE_WATER_FACILITY_FAILED,
    payload: {},
});

export const clearWasteWaterFacilitySearchResults = () => ({
    type: actionTypes.CLEAR_WASTE_WATER_FACILITY_SEARCH_RESULTS,
    payload: {},
});

export default actionTypes;
