import { createReducer } from "@reduxjs/toolkit";

import {
    clearBatchId,
    clearQueryResults,
    createBatch,
    enqueueTargetSurfacerQuery,
    fetchSearchRecords,
    generateStaticLink,
    registerQueryWatcher,
    searchTargetSurfacer,
    setPreviousQuery,
    setSelectedSearchRecord,
    setSelectedTeamSearchHistory,
    setSelectedUserSearchHistory,
} from "stores/targets/actions";
import { targetsAdapter } from "stores/targets/adapter";

const _ = require("lodash");
export const initialState = targetsAdapter.getInitialState({
    enqueuedCount: 0,
    unique_utilities_count: 0,
    isQuerying: false,
    queryWatcherId: null,
    previousQuery: null,
    selectedSearchRecord: null,
    results: [],
    completedQueries: {},
    errorQueries: {},
    pendingQueries: {},
    newBatch: {
        isCreating: false,
        error: null,
        batchId: null,
    },
    searchRecords: {
        error: null,
        isLoading: false,
        results: [],
        selectedUser: null,
        selectedTeam: null,
    },
    staticLink: {
        error: null,
        isGenerating: false,
        url: "",
    },
});

const targetsReducer = createReducer(initialState, (builder) => {
    builder.addCase(registerQueryWatcher.fulfilled, (state, { payload }) => {
        state.queryWatcherId = payload.watcherId;
    });

    builder.addCase(clearBatchId, (state, { payload }) => {
        state.newBatch.batchId = null;
        state.newBatch.isCreating = false;
    });

    builder.addCase(clearQueryResults, (state) => {
        state.completedQueries = {};
        state.results = [];
        state.unique_utilities_count = 0;
    });
    builder.addCase(enqueueTargetSurfacerQuery, (state, { payload }) => {
        state.enqueuedCount += 1;
        state.pendingQueries[Math.random()] = payload;
    });
    builder.addCase(
        searchTargetSurfacer.pending,
        (
            state,
            {
                meta: {
                    arg: { queryId },
                },
            }
        ) => {
            state.isQuerying = true;
            delete state.pendingQueries[queryId];
        }
    );
    builder.addCase(setPreviousQuery, (state, { payload }) => {
        state.previousQuery = payload && payload.previousQuery;
    });
    builder.addCase(setSelectedSearchRecord, (state, { payload }) => {
        state.selectedSearchRecord = payload && payload.selectedSearchRecord;
    });
    builder.addCase(setSelectedUserSearchHistory, (state, { payload }) => {
        state.searchRecords.selectedUser = payload.user;
    });
    builder.addCase(setSelectedTeamSearchHistory, (state, { payload }) => {
        state.searchRecords.selectedTeam = payload.team;
    });
    builder.addCase(searchTargetSurfacer.fulfilled, (state, { payload }) => {
        state.completedQueries[payload.queryId] = payload;
        // the enqueuedCount is reset only when all pending queries are done
        // so that the total enqueued in a batch can be used for the progress bar
        if (Object.keys(state.pendingQueries).length === 0) {
            state.enqueuedCount = 0;
        }

        const querySnippets = state.results.map((result) => result.highlight_snippet);
        const uniqueResults = [];

        payload.results.forEach((result) => {
            uniqueResults.push(result);
            querySnippets.push(result.highlight_snippet);
        });

        const newResults = [...state.results];
        newResults.push(...uniqueResults);

        state.results = _.uniqBy(newResults, "highlight_snippet");

        const uniqueUtilities = [];
        state.results.forEach((result) => {
            let isUnique = true;
            uniqueUtilities.forEach((utility) => {
                if (
                    utility.target_name === result.target_name &&
                    utility.target_state === result.target_state
                ) {
                    isUnique = false;
                }
            });
            if (isUnique) {
                uniqueUtilities.push({
                    target_name: result.target_name,
                    target_state: result.target_state,
                });
            }
        });
        state.unique_utilities_count = uniqueUtilities.length;
        state.isQuerying = false;
    });
    builder.addCase(searchTargetSurfacer.rejected, (state, { payload }) => {
        state.errorQueries[payload.queryId] = payload;
        state.isQuerying = false;
    });
    builder.addCase(generateStaticLink.pending, (state) => {
        state.staticLink.isGenerating = true;
    });
    builder.addCase(generateStaticLink.fulfilled, (state, { payload }) => {
        state.staticLink.url = payload.url;
        state.staticLink.isGenerating = false;
    });
    builder.addCase(generateStaticLink.rejected, (state, { payload }) => {
        state.staticLink.error = payload;
        state.staticLink.isGenerating = false;
    });
    builder.addCase(fetchSearchRecords.pending, (state) => {
        state.searchRecords.isLoading = true;
    });
    builder.addCase(fetchSearchRecords.fulfilled, (state, { payload }) => {
        targetsAdapter.upsertMany(state, payload);
        state.searchRecords.isLoading = false;
    });
    builder.addCase(fetchSearchRecords.rejected, (state, { payload }) => {
        state.searchRecords.error = payload;
        state.searchRecords.isLoading = false;
    });
    builder.addCase(createBatch.pending, (state) => {
        state.newBatch.isCreating = true;
    });
    builder.addCase(createBatch.fulfilled, (state, { payload }) => {
        state.newBatch.batchId = payload.pk;
        state.newBatch.isCreating = false;
    });
    builder.addCase(createBatch.rejected, (state, { payload }) => {
        state.newBatch.error = payload;
        state.newBatch.isLoading = false;
    });
});

export default targetsReducer;
