// TODO: change wasteWaterFacility variables to facility
export const overlayNames = {
    addContactModal: "addContactModal",
    addTimelineModal: "addTimelineModal",
    addDrinkingWaterSystemModal: "addDrinkingWaterSystemModal",
    addIntelligenceModal: "addIntelligenceModal",
    addIndicatorModal: "addIndicatorModal",
    addLocationModal: "addLocationModal",
    addWasteWaterFacilityModal: "addWasteWaterFacilityModal",
    addIntelligenceNotesModal: "addIntelligenceNotesModal",
    addSalesStageModal: "addSalesStageModal",
    clearQueryResultsModal: "clearQueryResultsModal",
    createInsightModal: "createInsightModal",
    createReportModal: "createReportModal",
    createSourceModal: "createSourceModal",
    assigneeModal: "assigneeModal",
    competitorModal: "competitorModal",
    consultantModal: "consultantModal",
    editContactModal: "editContactModal",
    editDrinkingWaterSystemModal: "editDrinkingWaterSystemModal",
    editInsightModal: "editInsightModal",
    editIndicatorModal: "editIndicatorModal",
    editOpportunityOwnerModal: "editOpportunityOwnerModal",
    editSalesStageModal: "editSalesStageModal",
    editTimelineModal: "editTimelineModal",
    editSourceModal: "editSourceModal",
    editWasteWaterFacilityModal: "editWasteWaterFacilityModal",
    editIntelligenceNotesModal: "editIntelligenceNotesModal",
    editReportModal: "editReportModal",
    teamCustomerTagsModal: "teamCustomerTagsModal",
    queryHistory: "queryHistory",
    removeCompetitorModal: "removeCompetitorModal",
    removeConsultantModal: "removeConsultantModal",
    removeContactModal: "removeContactModal",
    removeDrinkingWaterSystemModal: "removeDrinkingWaterSystemModal",
    removeLocationModal: "removeLocationModal",
    removeTimelineModal: "removeTimelineModal",
    removeIntelligenceNotesModal: "removeIntelligenceNotesModal",
    removeWasteWaterFacilityModal: "removeWasteWaterFacilityModal",
    removeSalesStageModal: "removeSalesStageModal",
    removeReportModal: "removeReportModal",
    staticLinkModal: "staticLinkModal",
    targetSurfacerSearchModal: "targetSurfacerSearchModal",
};
