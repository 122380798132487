import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusAsyncExhaustPaginatedGetRequest } from "network";
import { teamCustomerTagKeys } from "reactQuery/keys";

import { BaseTeamCustomerTag } from "./apiTypes";

export const useGetAllTeamCustomerTags = (
    teamId?: number
): UseQueryResult<BaseTeamCustomerTag[]> => {
    const queryKey = teamCustomerTagKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<BaseTeamCustomerTag[]> =>
            await nautilusAsyncExhaustPaginatedGetRequest(
                "/api/target_reports/team_customer_tags/",
                `?team=${teamId}`
            ),
        enabled: !!teamId,
    });
};
