import { createSelector } from "reselect";

import { userReducerName } from "./reducer";

const userStoreSelector = (state) => state[userReducerName];

export const userDataSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.userData
);

export const isAuthenticatedSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.isAuthenticated
);

export const defaultExcludedTagsSelector = createSelector(
    userDataSelector,
    (userData) => (userData ? userData.default_excluded_tags : [])
);

export const userTeamSelector = createSelector(userDataSelector, (userData) =>
    userData ? userData.team : null
);

export const isStaffSelector = createSelector(userDataSelector, (userData) =>
    userData ? userData.user.is_staff : false
);

export const teamDataSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.teams
);

export const profileDataSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.profiles
);

export const logInSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.login
);

export const isAttemptingToLogInSelector = createSelector(
    logInSelector,
    (login) => login.isAttemptingToLogIn
);

export const providedInvalidCredentialsSelector = createSelector(
    logInSelector,
    (login) => login.providedInvalidCredentials
);

export const retryLoginAfterSecondsSelector = createSelector(
    logInSelector,
    (login) => login.retryLoginAfterSeconds
);

export const requestPasswordResetSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.requestPasswordReset
);

export const requestPasswordResetIsAttemptingSelector = createSelector(
    requestPasswordResetSelector,
    (requestPasswordReset) => requestPasswordReset.isAttemptingToReset
);

export const requestPasswordResetEmailSelector = createSelector(
    requestPasswordResetSelector,
    (requestPasswordReset) => requestPasswordReset.email
);

export const requestPasswordFailedServerValidationSelector = createSelector(
    requestPasswordResetSelector,
    (requestPasswordReset) => requestPasswordReset.failedServerValidation
);

export const requestPasswordResetHasErrorSelector = createSelector(
    requestPasswordResetSelector,
    (requestPasswordReset) => requestPasswordReset.hasUnknownError
);

export const requestPasswordResetHasSentEmailSelector = createSelector(
    requestPasswordResetSelector,
    (requestPasswordReset) => requestPasswordReset.hasSentEmail
);

export const passwordChangeSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.passwordChange
);

export const passwordChangeSuccessSelector = createSelector(
    passwordChangeSelector,
    (passwordChange) => passwordChange.changedSuccessfully
);

export const passwordChangeErrorsSelector = createSelector(
    passwordChangeSelector,
    (passwordChange) => passwordChange.errors
);

export const passwordChangeIsAttemptingSelector = createSelector(
    passwordChangeSelector,
    (passwordChange) => passwordChange.isAttemptingToChange
);

export const passwordResetSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.passwordReset
);

export const passwordResetIsSubmittingSelector = createSelector(
    passwordResetSelector,
    (passwordReset) => passwordReset.isSubmitting
);

export const passwordResetCompletedSelector = createSelector(
    passwordResetSelector,
    (passwordReset) => passwordReset.completed
);

export const passwordResetErrorsSelector = createSelector(
    passwordResetSelector,
    (passwordReset) => passwordReset.errors
);

export const signupLinkVerificationSelector = createSelector(
    userStoreSelector,
    (userStore) => userStore.signupLinkVerification
);

export const signupLinkVerificationIsVerifyingSelector = createSelector(
    signupLinkVerificationSelector,
    (signupLinkVerification) => signupLinkVerification.isVerifying
);

export const signupLinkVerificationCompletedSelector = createSelector(
    signupLinkVerificationSelector,
    (signupLinkVerification) => signupLinkVerification.completed
);

export const signupLinkVerificationErrorsSelector = createSelector(
    signupLinkVerificationSelector,
    (signupLinkVerification) => signupLinkVerification.errors
);
