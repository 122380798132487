import Grid from "@material-ui/core/Grid";
import { UseMutationResult } from "@tanstack/react-query";
import { Form, withFormik, FormikProps } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { FormDropdownField, FormTextField } from "components/atoms";
import { DatePicker } from "components/molecules/DatePicker";
import { EditModalBody } from "components/molecules/EditModalBody";
import { FormModal } from "components/molecules/Modal";
import {
    OpportunityActionsTakenWrite,
    OpportunityActionsTakenRead,
    PatchedOpportunityActionsTakenWrite,
    Actions,
} from "reactQuery/hooks/apiTypes";
import {
    useRemoveActionsTaken,
    usePatchActionsTaken,
    usePostActionsTaken,
} from "reactQuery/hooks/useActionsTaken";
import { useModalStore, ModalModeOptions } from "stores/zustandStore";
export interface UnconnectedProps {
    allActions: Actions[];
    opportunityId: number;
}

export interface LogActivityFormProps {
    allActions: Actions[];
}

const LogActivityForm = ({
    allActions,
    ...formikProps
}: LogActivityFormProps & FormikProps<FormProps>) => {
    const { setFieldValue, values } = formikProps;
    const [currentAction, setCurrentAction] = useState(null);
    let isLegacyAction = true;
    const actionOptions = allActions
        ? allActions.map((action) => {
              if (values.selectedAction && action.id === values.selectedAction.id) {
                  isLegacyAction = false;
              }
              return action.action_outcome;
          })
        : [];

    useEffect(() => {
        if (allActions?.length > 0) {
            if (values.action_taken) {
                setCurrentAction(values.action_taken.actions.action_outcome);
            } else {
                setCurrentAction(allActions[0].action_outcome);
            }
            setFieldValue("allActions", allActions);
            if (isLegacyAction) {
                setFieldValue("selectedAction", actionOptions[0]);
            }
        }
    }, [allActions]);
    useEffect(() => {
        if (allActions) {
            allActions.forEach((action) => {
                if (action.action_outcome === currentAction) {
                    setFieldValue("selectedAction", action);
                    return;
                }
            });
            if (!currentAction) {
                setFieldValue("selectedAction", allActions[0]);
            }
        }
    }, [allActions, currentAction]);
    return (
        <Form>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                alignContent="center"
                spacing={1}
            >
                <Grid item xs={6}>
                    <FormDropdownField
                        selectedOption={currentAction}
                        allOptions={actionOptions}
                        setSelectedOption={setCurrentAction}
                    ></FormDropdownField>
                </Grid>
                <Grid item xs={6}>
                    <DatePicker formikProps={formikProps}></DatePicker>
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        name="actionNotes"
                        labelText="Add a description (optional)"
                        helperText="The information you provide above is only visible to you and your team."
                        formikProps={formikProps}
                    ></FormTextField>
                </Grid>
            </Grid>
        </Form>
    );
};

export const LogActivityModalNoFormik = ({
    allActions,
    opportunityId,
    ...formikProps
}: UnconnectedProps & FormikProps<FormProps>) => {
    const { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));

    const isVisible = useModalStore((state) => state.logProgressModalVisibility);
    const logProgressModalMode = useModalStore((state) => state.logProgressModalMode);
    const setLogProgressModalMode = useModalStore(
        (state) => state.setLogProgressModalMode
    );
    const setLogProgressModalVisibility = useModalStore(
        (state) => state.setLogProgressModalVisibility
    );
    const action_taken = useModalStore((state) => state.logProgressModalActionTaken);
    const { selectedAction, actionNotes, selectedDate } = formikProps.values;
    const isRemoveModeActive = logProgressModalMode === ModalModeOptions.Remove;
    formikProps.values.useRemoveActionsTakenQuery = useRemoveActionsTaken();
    formikProps.values.usePatchActionsTakenQuery = usePatchActionsTaken();
    formikProps.values.usePostActionsTakenQuery = usePostActionsTaken();
    formikProps.values.setLogProgressModalVisibility = setLogProgressModalVisibility;
    formikProps.values.teamId = teamId;
    formikProps.values.action_taken = action_taken;
    formikProps.values.opportunityId = opportunityId;
    formikProps.values.logProgressModalMode = logProgressModalMode;

    useEffect(() => {
        if (logProgressModalMode === ModalModeOptions.Edit) {
            formikProps.values.actionNotes = action_taken.notes?.notes;
            formikProps.values.selectedAction = action_taken.actions;
            formikProps.values.selectedDate = moment(action_taken.action_date).format(
                "YYYY-MM-DD HH:mm:ss"
            );
        } else {
            formikProps.values.selectedDate = new Date();
            formikProps.values.actionNotes = null;
        }
    }, [action_taken]);

    const bodyItems = [
        {
            label: "Action",
            value: selectedAction ? selectedAction.action_outcome : "",
        },
        {
            label: "Date",
            value: selectedDate ? moment(selectedDate).format("MMM-DD-YYYY") : "",
        },
        {
            label: "Note",
            value: actionNotes ?? "",
        },
    ];

    return (
        <FormModal
            handleClose={() => {
                setLogProgressModalVisibility(false);
                formikProps.resetForm();
            }}
            handleSecondaryClick={() => {
                setLogProgressModalMode(ModalModeOptions.Remove);
            }}
            handleSubmit={formikProps.handleSubmit}
            isVisible={isVisible}
            hasSecondaryButton={action_taken ? true : false}
            title={action_taken ? "Edit Progress" : "Log Progress"}
            extraActions={null}
            isSecondaryDisabled={null}
            isEditModeActive={action_taken ? true : false}
            isRemoveModeActive={isRemoveModeActive}
        >
            {isRemoveModeActive ? (
                <EditModalBody
                    isRemoveModeActive={true}
                    removalMessage="Are you sure that you would like to remove this action from this opportunity? Once removed, this action cannot be reversed."
                    items={bodyItems}
                />
            ) : (
                <LogActivityForm allActions={allActions} {...formikProps} />
            )}
        </FormModal>
    );
};
export interface EnhancedProps {
    allActions: Actions[];
    opportunityId: number;
}
export interface FormProps {
    action_taken: OpportunityActionsTakenRead;
    selectedAction: Actions;
    actionNotes: string;
    selectedDate: string | Date;
    useRemoveActionsTakenQuery: UseMutationResult<OpportunityActionsTakenWrite>;
    usePostActionsTakenQuery: UseMutationResult<OpportunityActionsTakenWrite>;
    usePatchActionsTakenQuery: UseMutationResult<PatchedOpportunityActionsTakenWrite>;
    teamId: number;
    setLogProgressModalVisibility: (visibility: boolean) => void;
    logProgressModalMode: ModalModeOptions;
    opportunityId: number;
}

export const LogActivityModal = withFormik<EnhancedProps, FormProps>({
    enableReinitialize: true,
    handleSubmit: ({
        selectedAction,
        actionNotes,
        selectedDate,
        usePatchActionsTakenQuery,
        usePostActionsTakenQuery,
        useRemoveActionsTakenQuery,
        teamId,
        action_taken,
        logProgressModalMode,
        setLogProgressModalVisibility,
        opportunityId,
    }) => {
        if (logProgressModalMode === ModalModeOptions.Edit) {
            const patchData = {
                id: action_taken ? action_taken.id : null,
                opportunity: opportunityId,
                actions: selectedAction.id,
                notes: { notes: actionNotes },
                contact: "",
                action_date: moment(selectedDate).format("YYYY-MM-DD"),
                team: teamId,
            };
            usePatchActionsTakenQuery.mutate(
                patchData as PatchedOpportunityActionsTakenWrite
            );
        } else if (logProgressModalMode === ModalModeOptions.Remove) {
            useRemoveActionsTakenQuery.mutate({
                id: action_taken.id,
                opportunityId: opportunityId,
                teamId: teamId,
            });
        } else if (logProgressModalMode === ModalModeOptions.Add) {
            const postBody = {
                opportunity: opportunityId,
                actions: selectedAction.id,
                notes: { notes: actionNotes },
                contact: "",
                action_date: moment(selectedDate).format("YYYY-MM-DD"),
                team: teamId,
            };
            usePostActionsTakenQuery.mutate(postBody as OpportunityActionsTakenWrite);
        }
        setLogProgressModalVisibility(false);
    },
    mapPropsToValues: () => ({
        selectedAction: null,
        selectedDate: new Date(),
        action_taken: null,
        actionNotes: null,
        useRemoveActionsTakenQuery: null,
        usePostActionsTakenQuery: null,
        usePatchActionsTakenQuery: null,
        teamId: null,
        setLogProgressModalVisibility: null,
        logProgressModalMode: null,
        opportunityId: null,
    }),

    validateOnChange: false,
    validateOnBlur: false,
})(LogActivityModalNoFormik);
