const OpportunityFeedbackUserTooltip = ({
    title,
    users,
}: {
    title: string;
    users?: string[];
}) => {
    return (
        <div>
            {users?.length > 0 ? (
                users.map((user, index) => <div key={index}>{user}</div>)
            ) : (
                <div>{title}</div>
            )}
        </div>
    );
};

export default OpportunityFeedbackUserTooltip;
