import React from "react";
import { connect } from "react-redux";

import { EditModalBody } from "components/molecules/EditModalBody";
import { FormModal } from "components/molecules/Modal";
import { openNewInternalLink } from "constants/navigation";
import { removeDrinkingWaterSystemSelector } from "stores/drinkingWaterSystems/selectors";
import { patchInsight } from "stores/insights/actions";
import {
    isUpdatingInsightSelector,
    insightToRemoveDrinkingWaterSystemFromSelector,
} from "stores/insights/selectors";
import { closeAllOverlays, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const UnconnectedEditDrinkingWaterSystemModal = ({
    closeAllOverlays,
    isEditModeActive,
    isRemoving,
    isRemoveModeActive,
    patchInsight,
    relevantInsight,
    toggleOverlay,
    waterSystemToRemove,
}) => {
    if (!isEditModeActive) {
        return null;
    }
    const removeWaterSystemAndDispatchPatch = () => {
        const updatedSystemIds = relevantInsight.drinking_water_systems
            .reduce((ids, waterSystem) => {
                ids.push(waterSystem.id);
                return ids;
            }, [])
            .filter((id) => id !== waterSystemToRemove.id);
        patchInsight({
            drinking_water_systems: updatedSystemIds,
            insightId: relevantInsight.id,
        });
    };

    const bodyItems = [
        {
            label: "Drinking Water System:",
            value:
                waterSystemToRemove && waterSystemToRemove.name
                    ? waterSystemToRemove.name
                    : "Unknown",
        },
        {
            label: "Pop Served:",
            value:
                waterSystemToRemove && waterSystemToRemove.population_served
                    ? waterSystemToRemove.population_served.toLocaleString()
                    : "Unknown",
        },
    ];

    return (
        <FormModal
            handleClose={closeAllOverlays}
            handleSubmit={() => {
                if (isRemoveModeActive) {
                    removeWaterSystemAndDispatchPatch();
                } else {
                    openNewInternalLink(
                        `/admin/universe/drinkingwatersystem/${waterSystemToRemove.id}/change/`
                    );
                    closeAllOverlays();
                }
            }}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeDrinkingWaterSystemModal });
            }}
            hasSecondaryButton={true}
            isSubmitDisabled={isRemoving}
            isSubmitLoading={isRemoving}
            isVisible={isEditModeActive || isRemoveModeActive}
            submitLabel={
                !isRemoveModeActive ? "Edit Drinking Water System Details" : "Remove"
            }
            title={
                isRemoveModeActive
                    ? "Remove Drinking Water System"
                    : "Edit Drinking Water System"
            }
            isRemoveModeActive={isRemoveModeActive}
            isEditModeActive={isEditModeActive}
        >
            <EditModalBody
                isRemoveModeActive={isRemoveModeActive}
                removalMessage="Are you sure that you would like to remove this drinking water system from this insight? 
                    Once removed, this action cannot be reversed."
                items={bodyItems}
            />
        </FormModal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    const removeWaterSystem = removeDrinkingWaterSystemSelector(state);
    return {
        isEditModeActive: isShowing.editDrinkingWaterSystemModal,
        isRemoving: isUpdatingInsightSelector(state),
        isRemoveModeActive: isShowing.removeDrinkingWaterSystemModal,
        relevantInsight: insightToRemoveDrinkingWaterSystemFromSelector(state),
        waterSystemToRemove: removeWaterSystem.waterSystem,
    };
};

const ConnectedEditDrinkingWaterSystemModal = connect(mapStateToProps, {
    closeAllOverlays,
    patchInsight,
    toggleOverlay,
})(UnconnectedEditDrinkingWaterSystemModal);

export default ConnectedEditDrinkingWaterSystemModal;
