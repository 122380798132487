// TODO: change wasteWaterFacility variables to facility
import { call, put, takeLatest } from "redux-saga/effects";

import { searchNautilusGetRequest } from "network";

import actionTypes, {
    searchForCompanyFailed,
    searchForCompanyResults,
    searchForContactFailed,
    searchForContactResults,
    searchForDrinkingWaterSystemFailed,
    searchForDrinkingWaterSystemResults,
    searchForLocationFailed,
    searchForLocationResults,
    searchForWasteWaterFacilityFailed,
    searchForWasteWaterFacilityResults,
} from "./actionTypes";

export const COMPANY_URL = "/api/universe/companies/";

export function searchForCompanyRequest(searchTerm) {
    return searchNautilusGetRequest(COMPANY_URL, searchTerm);
}

function* yieldSearchForCompany(action) {
    try {
        const response = yield call(searchForCompanyRequest, action.payload.searchTerm);
        yield put(searchForCompanyResults(response.data.results));
    } catch (e) {
        yield put(searchForCompanyFailed());
    }
}

export function* searchForCompanySaga() {
    yield takeLatest(actionTypes.SEARCH_FOR_COMPANY, yieldSearchForCompany);
}

export const CONTACT_URL = "/api/universe/contacts";

export function searchForContactRequest(searchTerm) {
    return searchNautilusGetRequest(CONTACT_URL, searchTerm);
}

function* yieldSearchForContact(action) {
    try {
        const response = yield call(searchForContactRequest, action.payload.searchTerm);
        yield put(searchForContactResults(response.data.results));
    } catch (e) {
        yield put(searchForContactFailed());
    }
}

export function* searchForContactSaga() {
    yield takeLatest(actionTypes.SEARCH_FOR_CONTACT, yieldSearchForContact);
}

export const DRINKING_WATER_SYSTEMS_URL = "/api/universe/drinking_water_systems/";

export function searchForDrinkingWaterSystemRequest(searchTerm) {
    return searchNautilusGetRequest(DRINKING_WATER_SYSTEMS_URL, searchTerm);
}

function* yieldSearchForDrinkingWaterSystem(action) {
    try {
        const response = yield call(
            searchForDrinkingWaterSystemRequest,
            action.payload.searchTerm
        );
        yield put(searchForDrinkingWaterSystemResults(response.data.results));
    } catch (e) {
        yield put(searchForDrinkingWaterSystemFailed());
    }
}

export function* searchForDrinkingWaterSystemSaga() {
    yield takeLatest(
        actionTypes.SEARCH_FOR_DRINKING_WATER_SYSTEM,
        yieldSearchForDrinkingWaterSystem
    );
}

export const LOCATION_URL = "/api/universe/demographics";

export function searchForLocationRequest(searchTerm) {
    return searchNautilusGetRequest(LOCATION_URL, searchTerm);
}

function* yieldSearchForLocation(action) {
    try {
        const response = yield call(
            searchForLocationRequest,
            action.payload.searchTerm
        );
        yield put(searchForLocationResults(response.data.results));
    } catch (e) {
        yield put(searchForLocationFailed());
    }
}

export function* searchForLocationSaga() {
    yield takeLatest(actionTypes.SEARCH_FOR_LOCATION, yieldSearchForLocation);
}

export const WASTE_WATER_FACILITIES_URL = "/api/universe/wastewater_facilities/";

export function searchForWasteWaterFacilityRequest(searchTerm) {
    return searchNautilusGetRequest(WASTE_WATER_FACILITIES_URL, searchTerm);
}

function* yieldSearchForWasteWaterFacility(action) {
    try {
        const response = yield call(
            searchForWasteWaterFacilityRequest,
            action.payload.searchTerm
        );
        yield put(searchForWasteWaterFacilityResults(response.data.results));
    } catch (e) {
        yield put(searchForWasteWaterFacilityFailed());
    }
}

export function* searchForWasteWaterFacilitySaga() {
    yield takeLatest(
        actionTypes.SEARCH_FOR_WASTE_WATER_FACILITY,
        yieldSearchForWasteWaterFacility
    );
}
