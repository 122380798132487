import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Layout, Space } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SearchPlanFormIntelligenceScopeListItem } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetSearchPlanOverview } from "reactQuery/hooks/useSearchPlanForm";

import IntelligenceScopeForm from "../IntelligenceScopeForm";
export const IntelligenceScopesPathname = "/intelligence_scopes/:search_plan_id";

const { Content } = Layout;
const { Panel } = Collapse;

const IntelligenceScopesPage = () => {
    const { search_plan_id: searchPlanId } = useParams();
    const searchPlanOverviewQuery = useGetSearchPlanOverview(searchPlanId);

    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user.is_staff ?? false;

    const [intelligenceScopeForms, setIntelligenceScopeForms] = useState<
        SearchPlanFormIntelligenceScopeListItem[]
    >([]);

    useEffect(() => {
        setIntelligenceScopeForms(
            searchPlanOverviewQuery.data?.intelligence_scopes ?? []
        );
    }, [searchPlanOverviewQuery.isSuccess]);

    const addIntelligenceScope = () => {
        const index = intelligenceScopeForms.length + 1;
        setIntelligenceScopeForms([
            ...intelligenceScopeForms,
            { id: -index, industry: "Intelligence Scope", indicator: "New" },
        ]);
    };

    const page = (
        <Layout>
            <Content style={{ margin: "20px" }}>
                {searchPlanOverviewQuery.isSuccess ? (
                    <h1>
                        Intelligence Scopes for {searchPlanOverviewQuery.data.team.name}
                    </h1>
                ) : (
                    <h1>...</h1>
                )}

                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    {intelligenceScopeForms.map((intelligenceScope) => {
                        const header = `${intelligenceScope.industry} - ${intelligenceScope.indicator}`;

                        return (
                            <Collapse>
                                <Panel header={header} key={intelligenceScope.id}>
                                    <IntelligenceScopeForm
                                        key={intelligenceScope.id}
                                        intelligenceScope={intelligenceScope}
                                    />
                                </Panel>
                            </Collapse>
                        );
                    })}

                    <Button
                        type="dashed"
                        style={{ width: "100%" }}
                        icon={<PlusOutlined />}
                        onClick={() => addIntelligenceScope()}
                    >
                        Add Intelligence Scope
                    </Button>
                </Space>
            </Content>
        </Layout>
    );

    if (!whoamiQuery.isSuccess) return null;

    return isStaff ? page : <h1>Unauthorized</h1>;
};

export default IntelligenceScopesPage;
