import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";

import {
    nautilusDeleteRequest,
    nautilusGetRequest,
    nautilusPatchRequest,
    nautilusPostRequest,
} from "network";
import { opportunityReducerName } from "stores/opportunities/constants";
import {
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
} from "stores/uiStore/actionTypes";

const formatName = (name) => `${opportunityReducerName}/${name}`;

export const setActiveOpportunity = createAction(formatName("setActiveOpportunity"));

export const setSalesStageForEdit = createAction(formatName("setSalesStageForEdit"));

export const fetchOpportunityById = createAsyncThunk(
    formatName("fetchOpportunityById"),
    async (
        { opportunityId, teamId, indicatorGroupId },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const searchParams =
                `${opportunityId}/?team_id=${teamId}` +
                (indicatorGroupId ? `&indicator_group_id=${indicatorGroupId}` : "");

            const response = await nautilusGetRequest(
                "/api/target_reports/report_opportunities/",
                searchParams
            );
            return response.data;
        } catch (e) {
            const browserHistory = createBrowserHistory({ forceRefresh: true });
            browserHistory.push("/");
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchOpportunitiesForReport = createAsyncThunk(
    formatName("fetchOpportunities"),
    async ({ reportId, teamId }, { dispatch, rejectWithValue }) => {
        let queryString = "?";
        if (reportId) {
            queryString += `report=${reportId}`;
            if (teamId) queryString += "&";
        }

        if (teamId) {
            queryString += `team=${teamId}`;
        }
        try {
            const response = await nautilusGetRequest(
                "/api/target_reports/opportunities/",
                queryString
            );
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch opportunities for report ${reportId}`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const addSalesStage = createAsyncThunk(
    formatName("addSalesStage"),
    async (
        { opportunityId, salesStageId, notes, userId, stage_date },
        { dispatch, rejectWithValue }
    ) => {
        const requestBody = {};

        if (opportunityId) requestBody.opportunity = opportunityId;
        if (stage_date) requestBody.stage_date = stage_date;
        if (notes === "") requestBody.notes = { notes: null };
        if (notes !== "") requestBody.notes = { notes };
        if (userId) requestBody.author = userId;
        if (salesStageId) requestBody.sales_stage = salesStageId;

        try {
            const response = await nautilusPostRequest(
                "/api/target_reports/sales_stages/",
                requestBody
            );
            dispatch(closeAllOverlays());

            dispatch(displaySuccessSnack({ message: "Sales stage has been updated" }));
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to update sales stage" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const patchSalesStage = createAsyncThunk(
    formatName("patchSalesStage"),
    async (
        { opportunityId, salesStageId, notes, userId, stage_date, oppSalesStageId },
        { dispatch, rejectWithValue }
    ) => {
        const requestBody = {};

        if (opportunityId) requestBody.opportunity = opportunityId;
        if (stage_date) requestBody.stage_date = stage_date;
        if (notes) requestBody.notes = { notes };
        if (userId) requestBody.author = userId;
        if (salesStageId) requestBody.sales_stage = salesStageId;

        try {
            const response = await nautilusPatchRequest(
                `/api/target_reports/sales_stages/${oppSalesStageId}`,
                requestBody
            );
            dispatch(closeAllOverlays());
            // dispatch(toggleOverlay({ overlay: overlayNames.addSalesStageModal }));
            dispatch(displaySuccessSnack({ message: "Sales stage has been updated" }));
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to update sales stage" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const deleteSalesStage = createAsyncThunk(
    formatName("deleteSalesStage"),
    async ({ oppSalesStageId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusDeleteRequest(
                `/api/target_reports/sales_stages/${oppSalesStageId}`
            );
            dispatch(closeAllOverlays());
            // dispatch(toggleOverlay({ overlay: overlayNames.addSalesStageModal }));
            dispatch(displaySuccessSnack({ message: "Sales stage has been deleted" }));
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to delete sales stage" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const fetchOpportunitySalesStagesByFilter = createAsyncThunk(
    formatName("fetchOpportunitySalesStagesByFilter"),
    async ({ opportunityId }, { dispatch, rejectWithValue }) => {
        let queryString = "?";
        if (opportunityId) {
            queryString += `opportunity=${opportunityId}`;
            try {
                const response = await nautilusGetRequest(
                    "/api/target_reports/sales_stages/",
                    queryString
                );
                return response.data.results;
            } catch (e) {
                dispatch(
                    displayErrorSnack({
                        message: `Failed to fetch Opp Sales Stages for report ${opportunityId}`,
                    })
                );
                return rejectWithValue({
                    status: e.response && e.response.status,
                    message: e.response && e.response.data,
                });
            }
        }
    }
);

export const addActionsTaken = createAsyncThunk(
    formatName("addActionsTaken"),
    async (
        { opportunityId, actionId, notes, userId, action_date, contact, team },
        { dispatch, rejectWithValue }
    ) => {
        const requestBody = {};

        if (opportunityId) requestBody.opportunity = opportunityId;
        if (action_date) requestBody.action_date = action_date;
        if (notes === "") requestBody.notes = { notes: null };
        if (notes !== "") requestBody.notes = { notes };
        if (userId) requestBody.author = userId;
        if (actionId) requestBody.actions = actionId;
        if (contact) requestBody.contact = contact;
        if (team) requestBody.team = team;
        try {
            const response = await nautilusPostRequest(
                "/api/target_reports/actions_taken/",
                requestBody
            );
            dispatch(closeAllOverlays());

            dispatch(displaySuccessSnack({ message: "Activity has been logged" }));
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to add action" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const fetchOpportunityActionsTakenByFilter = createAsyncThunk(
    formatName("fetchOpportunityActionsTakenByFilter"),
    async ({ opportunityId, teamId }, { dispatch, rejectWithValue }) => {
        let queryString = "?";
        if (opportunityId) queryString += `opportunity=${opportunityId}`;
        if (teamId) queryString += `&team=${teamId}`;
        try {
            const response = await nautilusGetRequest(
                "/api/target_reports/actions_taken/",
                queryString
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch Opportunity Actions Taken for report ${opportunityId}`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);
export const patchActionsTaken = createAsyncThunk(
    formatName("patchActionsTaken"),
    async (
        {
            opportunityId,
            actionId,
            notes,
            userId,
            action_date,
            contact,
            oppActionTakenId,
            team,
        },
        { dispatch, rejectWithValue }
    ) => {
        const requestBody = {};

        if (opportunityId) requestBody.opportunity = opportunityId;
        if (action_date) requestBody.action_date = action_date;
        if (notes === "") requestBody.notes = { notes: null };
        if (notes !== "") requestBody.notes = { notes };
        if (userId) requestBody.author = userId;
        if (actionId) requestBody.actions = actionId;
        if (contact) requestBody.contact = contact;
        if (team) requestBody.team = team;

        try {
            const response = await nautilusPatchRequest(
                `/api/target_reports/actions_taken/${oppActionTakenId}`,
                requestBody
            );
            dispatch(closeAllOverlays());
            dispatch(
                displaySuccessSnack({ message: "Logged activity has been updated" })
            );
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to update sales stage" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const deleteActionsTaken = createAsyncThunk(
    formatName("deleteActionsTaken"),
    async ({ oppActionTakenId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusDeleteRequest(
                `/api/target_reports/actions_taken/${oppActionTakenId}`
            );
            dispatch(closeAllOverlays());
            dispatch(
                displayErrorSnack({ message: "Logged activity has been removed" })
            );
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({ message: "Failed to delete logged activity" })
            );
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);
