import { DislikeFilled, DislikeOutlined, DislikeTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

import { additionalColors, brandColors } from "constants/colors";
import { useGetFeedbackUsers } from "reactQuery/hooks/useOpportunityFeedbackUsers";

import OpportunityFeedbackUserTooltip from "../../molecules/OpportunityFeedback/OpportunityFeedbackUserTooltip";
import { useStyles } from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    dislikeButton: (disliked: boolean) =>
        `dislike button ${disliked ? "disliked" : "not disliked"}`,
    dislikeCount: "dislike count",
};

const Dislike = ({
    teamId,
    opportunityId,
    retrieveFeedbackUsers,
    setRetrieveFeedbackUsers,
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    teamId: number;
    opportunityId: number;
    retrieveFeedbackUsers: boolean;
    setRetrieveFeedbackUsers: (_: boolean) => void;
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const getOpportunityFeedbackUsers = useGetFeedbackUsers({
        opportunityId,
        teamId,
        enabled: retrieveFeedbackUsers,
    });
    const styles = useStyles();

    const IconComponent = currentFeedback?.is_disliked
        ? DislikeFilled
        : currentFeedback?.total_dislikes > 0
        ? DislikeTwoTone
        : DislikeOutlined;

    return (
        <>
            <Tooltip
                title={
                    <OpportunityFeedbackUserTooltip
                        title="Dislike"
                        users={getOpportunityFeedbackUsers.data?.dislike}
                    />
                }
                onOpenChange={(open) =>
                    open && !retrieveFeedbackUsers && setRetrieveFeedbackUsers(true)
                }
            >
                <IconComponent
                    className={[
                        styles.feedbackIconFontSize,
                        allowFeedback
                            ? styles.feedbackIconEnabledStyle
                            : styles.feedbackIconDisabledStyle,
                    ].join(" ")}
                    onClick={(event) => handleFeedbackClicked(event, "dislike")}
                    style={
                        currentFeedback?.is_disliked
                            ? { color: brandColors.darkBlue }
                            : { color: additionalColors.grey600 }
                    }
                    data-testid={testIds.dislikeButton(
                        currentFeedback?.is_disliked ? true : false
                    )}
                    twoToneColor={
                        currentFeedback?.total_dislikes > 0
                            ? brandColors.darkBlue
                            : undefined
                    }
                />
            </Tooltip>
            <span data-testid={testIds.dislikeCount}>
                {currentFeedback?.total_dislikes}
            </span>
        </>
    );
};

export default Dislike;
