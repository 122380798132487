import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";

import {
    nautilusDeleteRequest,
    nautilusGetRequest,
    nautilusPatchRequest,
    nautilusPostRequest,
} from "network";
import { noteKeys } from "reactQuery/keys";

import { PatchedNotes, Notes } from "./apiTypes";

const patchNoteRequest = async (note: PatchedNotes, id: number): Promise<Notes> => {
    return (await nautilusPatchRequest(`/api/target_reports/notes/${id}/`, note)).data;
};

export function usePatchNote(): UseMutationResult<Notes> {
    const queryClient = useQueryClient();
    return useMutation(
        (modifyNote: Notes): Promise<Notes> => {
            const { id, ...note } = modifyNote;
            return patchNoteRequest(note, id);
        },
        {
            onSuccess: (responseData: Notes) => {
                const queryKey = noteKeys.filter({
                    insightId: responseData.insight,
                });
                queryClient.invalidateQueries(queryKey);
            },
        }
    );
}

const removeNoteRequest = async (id: number) => {
    return (await nautilusDeleteRequest(`/api/target_reports/notes/${id}/`)).data;
};

export function useRemoveNote() {
    return useMutation((removeNoteId: number) => {
        return removeNoteRequest(removeNoteId);
    });
}

const getFilteredNotes = async ({ insight }: PatchedNotes) => {
    const queryString = `?insight=${insight}`;
    return (await nautilusGetRequest("/api/target_reports/notes/", queryString)).data;
};

export const useGetFilteredNotes = (
    { insight }: PatchedNotes,
    enabled: boolean = true
) => {
    const queryKey = noteKeys.filter({
        insightId: insight,
    });
    return useQuery({
        queryKey,
        queryFn: () => getFilteredNotes({ insight }),
        enabled,
    });
};

async function postNote(requestData: Notes): Promise<Notes> {
    const { data } = await nautilusPostRequest(
        "/api/target_reports/notes/",
        requestData
    );
    return data;
}
export const usePostNote = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (requestData: Notes) => {
            return postNote(requestData);
        },
        {
            onSuccess: (responseData: Notes) => {
                const queryKey = noteKeys.filter({
                    insightId: responseData.insight,
                });
                queryClient.invalidateQueries(queryKey);
            },
        }
    );
};
