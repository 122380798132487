import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

import { searchPlanKeys } from "reactQuery/keys";

import { SearchPlanSummaryRead } from "./apiTypes";

export const useGetSearchPlan = (
    searchPlanId?: number
): UseQueryResult<SearchPlanSummaryRead> => {
    const queryKey = searchPlanKeys.single(searchPlanId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SearchPlanSummaryRead> => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/api/target_reports/search_plan_summary/${searchPlanId}/`
            );
            return data;
        },
        enabled: !!searchPlanId,
    });
};
