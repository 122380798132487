import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useState } from "react";
const HiddenElement = ({
    fullElement = <></>,
    hiddenElement = <></>,
    expandText = "Show More",
    retractText = "Show Less",
}) => {
    const [fullElementVisibility, setFullElementVisibility] = useState<boolean>(false);
    return (
        <>
            {fullElementVisibility ? (
                <div>
                    <div>{fullElement}</div>
                </div>
            ) : (
                <div>
                    <div>{hiddenElement}</div>
                </div>
            )}
            <div>
                <span
                    style={{
                        fontSize: "17px",
                        color: "rgb(24, 144, 255)",
                        cursor: "pointer",
                    }}
                    onClick={() => setFullElementVisibility(!fullElementVisibility)}
                >
                    <span>{fullElementVisibility ? retractText : expandText}</span>
                    &nbsp;
                    {fullElementVisibility ? <UpOutlined /> : <DownOutlined />}
                </span>
            </div>
        </>
    );
};

export default HiddenElement;
