import { withRouter } from "react-router-dom";

import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import ReportDetailsPage from "pages/ReportDetails";

export const IntelligenceReportsAllInsightsPathname = "/intrpt/all_insights";

const IntelligenceReportsAllInsights = () => {
    const AuthenticatedReportDetailsPage = withRouter(
        withAuthenticationCheck(ReportDetailsPage)
    );
    return <AuthenticatedReportDetailsPage />;
};

export default IntelligenceReportsAllInsights;
