import { retrieveNestedField } from "./index";

export const compareFieldByAlpha = (fields = ["name"]) => {
    return function (a, b) {
        const firstName = retrieveNestedField(fields, a).toLowerCase();
        const secondName = retrieveNestedField(fields, b).toLowerCase();
        if (firstName < secondName) {
            return -1;
        }
        if (firstName > secondName) {
            return 1;
        }
        return 0;
    };
};

export const compareFieldByNumericDesc = (fields = ["id"]) => {
    return function (a, b) {
        const firstName = retrieveNestedField(fields, a);
        const secondName = retrieveNestedField(fields, b);
        if (firstName < secondName) {
            return 1;
        }
        if (firstName > secondName) {
            return -1;
        }
        return 0;
    };
};

export const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
};
