import { Button, Modal, Typography, Input, Space, Tooltip, Skeleton } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import AIAssistantIcon from "icons/AI Assistant_icon.png";
import { EmailGenerationOutputPostResponse } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import {
    usePatchEmailGenerationOutputForm,
    usePostEmailGenerationOutputForm,
} from "reactQuery/hooks/useAIGenEmail";
import { useSnackStore } from "stores/zustandStore";

export interface AIEmailAssistantOutputModalProps {
    setAIEmailAssistantInputModalVisibility: Function;
    setAIEmailAssistantOutputModalVisibility: Function;
    visible: boolean;
    inputId: number;
    opportunityId: number;
}

export const AIEmailAssistantOutputModal = ({
    setAIEmailAssistantInputModalVisibility,
    setAIEmailAssistantOutputModalVisibility,
    visible,
    inputId,
    opportunityId,
}: AIEmailAssistantOutputModalProps) => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const [generatedText, setGeneratedText] = useState<string>();
    const [generatedId, setGeneratedId] = useState<number>();
    const [isEdited, setIsEdited] = useState<boolean>();

    const { search } = useLocation();
    const whoamiQuery = useGetWhoami();
    let teamIdString = new URLSearchParams(search).get("team_id");

    const postEmailGenerationOutputForm = usePostEmailGenerationOutputForm();
    const patchEmailGenerationOutputForm = usePatchEmailGenerationOutputForm();

    const onGenerateSuccess = (response: EmailGenerationOutputPostResponse) => {
        displaySuccessSnack({ message: "Generated text successfully" });
        setGeneratedText(response.email_output_text);
        setGeneratedId(response.output_id);
    };
    const onGenerateError = () => {
        displayErrorSnack({ message: "Problem generating text" });
    };
    const onCopySuccess = () => {
        displaySuccessSnack({ message: "Copied to Clipboard" });
    };

    useEffect(() => {
        if (!inputId || !visible) return;

        if (isEdited) {
            handleGenerate({ edited_from_id: generatedId });
            setIsEdited(false);
        } else {
            handleGenerate();
        }
    }, [inputId]);

    const editInputForm = () => {
        setAIEmailAssistantInputModalVisibility(true);
        setAIEmailAssistantOutputModalVisibility(false);
        setIsEdited(true);
    };

    const handleGenerate = (additionalArgs?: {
        original_email?: string;
        regenerated_from_id?: number;
        edited_from_id?: number;
    }) => {
        postEmailGenerationOutputForm.mutate(
            {
                input_id: inputId,
                opportunity_id: opportunityId,
                team_id: teamIdString
                    ? Number(teamIdString)
                    : whoamiQuery?.data?.team.id,
                ...additionalArgs,
            },
            { onSuccess: onGenerateSuccess, onError: onGenerateError }
        );
    };
    const handleShorten = () => {
        displaySuccessSnack({ message: "Shortening output now..." });
        handleGenerate({
            regenerated_from_id: generatedId,
            original_email: generatedText,
        });
    };
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(generatedText);

        patchEmailGenerationOutputForm.mutate(
            {
                copied: true,
                output_id: generatedId,
            },
            { onSuccess: onCopySuccess }
        );
    };

    const [editable, setEditable] = useState(false);

    const defaultFooter = (
        <div style={{ margin: "auto", textAlign: "center" }}>
            <Space size="large">
                <Button
                    key="shorten"
                    type="default"
                    disabled={postEmailGenerationOutputForm.isLoading}
                    onClick={() => handleShorten()}
                >
                    Shorten
                </Button>
                <Button
                    key="copytoclipboard"
                    type="primary"
                    disabled={
                        postEmailGenerationOutputForm.isError ||
                        postEmailGenerationOutputForm.isLoading
                    }
                    onClick={() => handleCopyToClipboard()}
                >
                    Copy to Clipboard
                </Button>
            </Space>
        </div>
    );

    const editingFooter = (
        <div style={{ margin: "auto", textAlign: "center" }}>
            <Space size="large">
                <Button
                    key="donediting"
                    type="primary"
                    onClick={() => {
                        setEditable(false);
                    }}
                >
                    Done Editing
                </Button>
            </Space>
        </div>
    );

    const preformattedTextElement = useRef<HTMLPreElement>();
    const [offsetHeight, setOffsetHeight] = useState<number>();

    // used to set the editable textarea wrapped in a <pre> to be the same as
    // the non-editable <pre>
    useEffect(() => {
        if (!editable && preformattedTextElement.current?.offsetHeight) {
            setOffsetHeight(preformattedTextElement.current.offsetHeight);
        }
    }, [editable, preformattedTextElement?.current]);

    return (
        <Modal
            open={visible}
            width={1000}
            onCancel={() => setAIEmailAssistantOutputModalVisibility(false)}
            forceRender
            footer={editable ? editingFooter : defaultFooter}
        >
            <div style={{ textAlign: "center", marginBottom: "25px" }}>
                <Space>
                    <img width="40px" height="40px" src={AIAssistantIcon} />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        Email Recommendation
                    </Typography.Title>
                </Space>
            </div>

            {postEmailGenerationOutputForm.isLoading ? (
                <Skeleton active />
            ) : (
                <div style={{ marginTop: "20px" }}>
                    <Typography.Link
                        style={{ marginBottom: "20px" }}
                        onClick={editInputForm}
                    >
                        Edit Input Form
                    </Typography.Link>

                    {postEmailGenerationOutputForm.isSuccess ? (
                        <>
                            <Typography.Paragraph
                                onDoubleClick={() => setEditable(true)}
                            >
                                {editable ? (
                                    <pre>
                                        <Input.TextArea
                                            onChange={(e) =>
                                                setGeneratedText(e.target.value)
                                            }
                                            style={{ height: `${offsetHeight}px` }}
                                            defaultValue={generatedText}
                                        />
                                    </pre>
                                ) : (
                                    <Tooltip title="Double click to edit text">
                                        <pre
                                            ref={preformattedTextElement}
                                            data-testid="generated-text"
                                            style={{
                                                cursor: "pointer",
                                                padding: "0.4em 1.3em",
                                            }}
                                        >
                                            {generatedText}
                                        </pre>
                                    </Tooltip>
                                )}
                            </Typography.Paragraph>
                            <Typography.Paragraph
                                style={{ marginTop: "20px" }}
                                type="secondary"
                            >
                                Disclaimer - AI Assistant may provide information that
                                could be inaccurate. Users are advised to verify factual
                                content before relying on the provided information. In
                                addition, successful users, have added their own
                                professional tone and personality, with their your own
                                salutation, introduction, sign off and signature.
                            </Typography.Paragraph>
                        </>
                    ) : (
                        <Typography.Paragraph
                            style={{ marginTop: "10px" }}
                            type="danger"
                        >
                            There was a problem communicating with the server. Our team
                            has been notified, and we're working hard to fix the issue.
                            In the meantime, please try again later. We apologize for
                            any inconvenience and appreciate your patience.
                        </Typography.Paragraph>
                    )}
                </div>
            )}
        </Modal>
    );
};
