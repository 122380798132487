import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";

import { additionalColors } from "constants/colors";

import { targetSurfacerTableStyles } from "../styles";

export const WORDLIMIT = 200;

export const splitQuerySnippet = (snippet) => {
    const arr = [];
    let text = snippet;
    let i = 0;
    let start = text.slice(i).indexOf("<mark>");

    while (start > -1) {
        arr.push({
            text: text.slice(i, start),
            isHighlighted: false,
        });
        i = text.slice(i).indexOf("</mark>");
        arr.push({
            text: text.slice(start + 6, i),
            isHighlighted: true,
        });
        text = text.slice(i + 7);
        i = 0;
        start = text.indexOf("<mark>");
    }

    if (text) {
        arr.push({
            text,
            isHighlighted: false,
        });
    }
    return arr;
};

export function summarizeQuerySnippet(textArr) {
    let arr = [];
    let wordCount = 0;
    let i;
    for (i = 0; i < textArr.length; i++) {
        const splitSegment = textArr[i].text.replace(/ +(?= )/g, "").split(" ");
        const newText = splitSegment.slice(0, WORDLIMIT - wordCount).join(" ");
        arr.push({
            ...textArr[i],
            text: newText,
        });
        if (newText.length > 1) wordCount += splitSegment.length;
        if (wordCount >= WORDLIMIT) {
            return arr;
        }
    }
    return arr;
}

function QuerySnippet({ highlight_snippet, searchTextValue }) {
    const styles = targetSurfacerTableStyles();

    const [isExpanded, setIsExpanded] = useState(false);
    const arr = splitQuerySnippet(highlight_snippet);
    const snippetArr = isExpanded ? arr : summarizeQuerySnippet(arr);
    return (
        <Typography onClick={() => setIsExpanded(!isExpanded)}>
            {snippetArr.map((item, index) => (
                <Typography
                    display="inline"
                    key={index}
                    className={item.isHighlighted ? styles.mark : styles.text}
                >
                    {searchTextValue ? (
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: additionalColors.lightOrange,
                                padding: 0,
                            }}
                            searchWords={[searchTextValue]}
                            autoEscape
                            textToHighlight={item.text}
                        />
                    ) : (
                        item.text
                    )}
                </Typography>
            ))}
        </Typography>
    );
}

export default QuerySnippet;
