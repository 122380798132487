import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import StyledButton from "components/atoms/StyledButton";
import { additionalColors } from "constants/colors";

const buttonStyle = makeStyles((theme) => ({
    loadingSpinner: {
        color: theme.palette.text.disabled,
        marginLeft: "16px",
        height: "16px !important",
        width: "16px !important",
    },
    danger: {
        background: additionalColors.orangeyRed,
        borderColor: additionalColors.orangeyRed,
        color: "white",
        "&:hover": {
            borderColor: additionalColors.orangeyRed,
            background: additionalColors.orangeyRed,
            color: "white",
        },
    },
    grey: {
        background: "white",
        border: "2px solid",
        color: additionalColors.grey999,
        "&:hover": {
            background: "white",
            border: "2px solid",
            color: additionalColors.grey999,
        },
    },
}));

const ModalButton = ({ children, isLoading, isVisible, colorScheme = "", ...rest }) => {
    const styles = buttonStyle();

    const loadingSpinner = isLoading ? (
        <CircularProgress className={styles.loadingSpinner} />
    ) : null;
    const isLoadingDisabled = isLoading || rest.disabled;

    return isVisible ? (
        colorScheme === "grey" ? (
            <StyledButton
                {...rest}
                disabled={isLoadingDisabled}
                className={styles.grey}
            >
                {children}
                {loadingSpinner}
            </StyledButton>
        ) : colorScheme === "danger" ? (
            <StyledButton
                {...rest}
                disabled={isLoadingDisabled}
                className={styles.danger}
            >
                {children}
                {loadingSpinner}
            </StyledButton>
        ) : (
            <StyledButton {...rest} variant="add-primary" disabled={isLoadingDisabled}>
                {children}
                {loadingSpinner}
            </StyledButton>
        )
    ) : null;
};

export default ModalButton;
