import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import StyledButton from "components/atoms/StyledButton";
import {
    InsightInOpportunity,
    ShallowContactRead,
    OpportunityRead,
} from "reactQuery/hooks/apiTypes";
import { useModalStore, ModalNames } from "stores/zustandStore";

import { ContactsList } from "./ContactsList";

const NoContactsMessage = ({ isEditModeActive }: { isEditModeActive: boolean }) => {
    return isEditModeActive ? (
        <Typography>Please add contacts to this opportunity.</Typography>
    ) : null;
};

const panelStyle = makeStyles((theme) => ({
    content: {
        marginTop: 24,
    },
    header: {
        color: theme.palette.grey[54],
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

export const ContactPanel = ({
    insights,
    opportunity,
    isEditModeActive,
    setAIEmailAssistantInputModalVisibility,
    setAIEmailAssistantInputModalContactId,
}: {
    insights: InsightInOpportunity[];
    opportunity: OpportunityRead;
    isEditModeActive: boolean;
    setAIEmailAssistantInputModalVisibility: Function;
    setAIEmailAssistantInputModalContactId: Function;
}) => {
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const insightAndContacts: {
        contact: ShallowContactRead;
        insight: InsightInOpportunity;
    }[] = insights
        .map((insight: InsightInOpportunity) => {
            return insight.contacts.map((contact) => {
                return {
                    contact,
                    insight,
                };
            });
        })
        .flat();

    const styles = panelStyle();
    const content = insightAndContacts.length ? (
        <ContactsList
            opportunityId={opportunity.id}
            insightsAndContacts={insightAndContacts}
            isEditModeActive={isEditModeActive}
            setAIEmailAssistantInputModalVisibility={
                setAIEmailAssistantInputModalVisibility
            }
            setAIEmailAssistantInputModalContactId={
                setAIEmailAssistantInputModalContactId
            }
        />
    ) : (
        <NoContactsMessage isEditModeActive={isEditModeActive} />
    );

    const addContactButton = isEditModeActive ? (
        <StyledButton
            variant="add-primary"
            handleClick={() => {
                setModalVisible(ModalNames.AddContact, {
                    insights: insights,
                    contactOptions: opportunity?.owner?.contacts.reduce(
                        (unique, contact) => {
                            return unique.findIndex(
                                (c) =>
                                    c.first_name === contact.first_name &&
                                    c.last_name === contact.last_name
                            ) < 0
                                ? [...unique, contact]
                                : unique;
                        },
                        []
                    ),
                    opportunityId: opportunity?.id,
                });
            }}
        >
            Add Contact
        </StyledButton>
    ) : null;

    return (
        <>
            <div className={styles.row}>
                {(isEditModeActive ? isEditModeActive : insightAndContacts.length) ? (
                    <Typography className={styles.header}>CONTACTS</Typography>
                ) : null}
                {addContactButton}
            </div>
            <div className={styles.content}>{content}</div>
        </>
    );
};

export default ContactPanel;
