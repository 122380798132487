import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import MarkdownTextField from "components/atoms/MarkdownTextField";
import { FormModal } from "components/molecules/Modal";
import {
    editSourceSelector,
    patchSource,
    removeSource,
    removeSourceSelector,
    sourceBeingEditedSelector,
} from "stores/sources/sourceSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const ERROR_MESSAGES = {
    urlMissing: "A url is required",
    urlInvalid: "Please enter a valid website URL, starting with http",
    titleMissing: "A title is required",
    //extractMissing: "A trigger extract is required",
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
};

export const EditSourceSchema = Yup.object().shape({
    sourceUrl: Yup.string()
        .url(ERROR_MESSAGES.urlInvalid)
        .required(ERROR_MESSAGES.urlMissing),
    sourceTitle: Yup.string().required(ERROR_MESSAGES.titleMissing),
    //triggerExtract: Yup.string().required(ERROR_MESSAGES.extractMissing),
    sourceDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
});

const removeSourceStyle = makeStyles(() => ({
    label: {
        color: "grey",
    },
}));

const formStyle = makeStyles(() => ({
    formControl: {
        width: "100%",
        marginBottom: "20px",
    },
    textInput: {
        width: "100%",
    },
}));

export const RemoveSource = ({ source }) => {
    const classes = removeSourceStyle();

    return (
        <div>
            <p>
                Are you sure that you would like to remove this source from this
                insight? Once removed, this action cannot be reversed.
            </p>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Source Url:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{source ? source.url : ""}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <p className={classes.label}> Source Title:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{source ? source.title : ""}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <p className={classes.label}> Trigger Extract:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{source ? source.extract : ""}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Source Date:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{source ? source.date : ""}</p>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const EditSourceForm = ({
    errors,
    setFieldValue,
    handleChange,
    values: { sourceUrl, sourceTitle, triggerExtract, sourceDate },
}) => {
    const classes = formStyle();

    return (
        <Form>
            <FormControl error={"sourceUrl" in errors} className={classes.formControl}>
                <TextField
                    className={classes.textInput}
                    id="source-url"
                    label="Source URL"
                    name="sourceUrl"
                    onChange={handleChange}
                    value={sourceUrl}
                    variant="outlined"
                    error={"sourceUrl" in errors}
                    helperText={errors.sourceUrl || null}
                />
            </FormControl>
            <FormControl
                error={"sourceTitle" in errors}
                className={classes.formControl}
            >
                <TextField
                    className={classes.textInput}
                    id="source-title"
                    label="Source Title"
                    name="sourceTitle"
                    onChange={handleChange}
                    value={sourceTitle}
                    variant="outlined"
                    error={"sourceTitle" in errors}
                    helperText={errors.sourceTitle || null}
                />
            </FormControl>
            <MarkdownTextField
                errors={errors}
                id="trigger-extract"
                name="triggerExtract"
                onChange={handleChange}
                label="Trigger Extract"
                value={triggerExtract}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"sourceDate" in errors}
                    className={classes.formControl}
                >
                    <InputLabel shrink>Source date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="date-picker-inline"
                        inputProps={{ "data-testid": "test-date-picker" }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="sourceDate"
                        onChange={(e) => setFieldValue("sourceDate", e)}
                        value={sourceDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid="test-source-date-error">
                        {errors.sourceDate ? ERROR_MESSAGES.dateMissing : null}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
        </Form>
    );
};

export const UnconnectedEditSourceModal = ({
    source,
    isShowingModal,
    isUpdating,
    isRemoving,
    toggleOverlay,
    ...formikProps
}) => {
    const showRemove =
        formikProps && formikProps.values ? formikProps.values.isShowingRemove : false;

    if (!isShowingModal) {
        return null;
    }
    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.editSourceModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSecondaryDisabled={isUpdating || isRemoving}
            isSubmitDisabled={isUpdating || isRemoving}
            isSubmitLoading={isUpdating || isRemoving}
            isVisible={isShowingModal}
            hasSecondaryButton={true}
            handleSecondaryClick={() => {
                formikProps.setFieldValue("isShowingRemove", !showRemove);
            }}
            isEditModeActive={true}
            isRemoveModeActive={showRemove}
            title={!showRemove ? "Edit Source" : "Remove Source"}
        >
            {showRemove && <RemoveSource source={source} />}
            {!showRemove && <EditSourceForm {...formikProps} />}
        </FormModal>
    );
};

export const EnhancedEditSourceModal = withFormik({
    enableReinitialize: true,
    handleSubmit: (
        { sourceUrl, sourceTitle, triggerExtract, sourceDate, isShowingRemove },
        { props: { editSourceId, patchSource, removeSource, source } }
    ) => {
        const date = moment(sourceDate).format("YYYY-MM-DD");
        if (isShowingRemove) {
            removeSource({
                sourceId: source.id,
            });
        } else {
            patchSource({
                sourceId: editSourceId,
                sourceUrl: sourceUrl,
                sourceTitle: sourceTitle,
                triggerExtract: triggerExtract,
                sourceDate: date,
            });
        }
    },
    mapPropsToValues: ({ source }) => ({
        sourceUrl: source ? source.url : "",
        sourceTitle: source ? source.title : "",
        triggerExtract: source ? source.extract : "",
        sourceDate: source ? source.date : new Date(),
        isShowingRemove: false,
    }),
    validationSchema: () => EditSourceSchema,
    validateOnChange: false,
    validateOnBlur: false,
})(UnconnectedEditSourceModal);

const mapStateToProps = (state) => {
    const editSource = editSourceSelector(state);
    const removeSource = removeSourceSelector(state);
    const isShowing = isShowingSelector(state);
    return {
        editSourceId: editSource.sourceId,
        source: sourceBeingEditedSelector(state),
        isShowingModal: isShowing.editSourceModal,
        isUpdating: editSource.isUpdating,
        isRemoving: removeSource.isRemoving,
    };
};

export const ConnectedEditSourceModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    patchSource,
    removeSource,
})(EnhancedEditSourceModal);

export default ConnectedEditSourceModal;
