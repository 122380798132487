import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import PDFTag from "components/molecules/PDFTag";
import { additionalColors } from "constants/colors";
import { formatNumber } from "constants/formatters";
import {
    CountryConstraint,
    CountyConstraint,
    IndicatorTagConstraint,
    InitiativeTypeConstraint,
    MGDConstraint,
    OwnerConstraint,
    OwnerTypeConstraint,
    PopulationConstraint,
    ProjectBudgetConstraint,
    SourceTimeframeConstraint,
    StateConstraint,
    TimingStatusConstraint,
} from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";

type Constraint =
    | OwnerConstraint
    | CountryConstraint
    | StateConstraint
    | PopulationConstraint
    | CountyConstraint
    | MGDConstraint
    | InitiativeTypeConstraint
    | IndicatorTagConstraint
    | OwnerTypeConstraint
    | MGDConstraint
    | ProjectBudgetConstraint
    | PopulationConstraint
    | SourceTimeframeConstraint
    | TimingStatusConstraint;

export interface RenderConstraintType {
    constraint: Constraint;
    key: string;
    name: string;
}

const RenderConstraint = ({ constraint, key, name }: RenderConstraintType) => {
    let value: string | number | null = null;
    switch (name) {
        case "County":
            let countyStates = {};
            let countiesWithUnknownState = [];
            let CountiesArray = [];
            (constraint as CountyConstraint).demographics.forEach((county) => {
                county.state_code
                    ? countyStates[county.state_code]
                        ? countyStates[county.state_code].push(county.name)
                        : (countyStates[county.state_code] = [county.name])
                    : countiesWithUnknownState.push(county.name);
            });
            Object.keys(countyStates).forEach((state_code) => {
                CountiesArray.push(
                    `${state_code} (${countyStates[state_code].join(", ")})`
                );
            });
            value = [...CountiesArray, ...countiesWithUnknownState].join(", ");
            break;
        case "Country":
            value = (constraint as CountryConstraint).countries
                ?.map((country) => country.name)
                .join(", ");
            break;
        case "Indicator Tags":
            value = (constraint as IndicatorTagConstraint).indicator_tags
                ?.map((indicator_tag) => indicator_tag.value)
                .join(", ");
            break;
        case "Initiative Type":
            value = (constraint as InitiativeTypeConstraint).initiative_types
                ?.map((initiative_type) => initiative_type.value)
                .join(", ");
            break;
        case "Owner Mention":
            value = (constraint as OwnerConstraint).owners
                ?.map((owner) => owner.legal_name)
                .join(", ");
            break;
        case "Owner Types":
            value = (constraint as OwnerTypeConstraint).owner_types.join(", ");
            break;
        case "Facility Size":
        case "Opportunity Value":
        case "Population":
            let cnst = constraint as
                | PopulationConstraint
                | MGDConstraint
                | ProjectBudgetConstraint;
            value = `${
                typeof cnst.minimum === "number" ? formatNumber(cnst.minimum) : ""
            }${
                typeof cnst.maximum === "number" && typeof cnst.minimum === "number"
                    ? " - "
                    : typeof cnst.maximum === "number"
                    ? "<="
                    : typeof cnst.minimum === "number"
                    ? "+"
                    : ""
            }${typeof cnst.maximum === "number" ? formatNumber(cnst.maximum) : ""}`;
            break;
        case "Source Document Date":
            value = `${
                typeof (constraint as SourceTimeframeConstraint).maximum === "number"
                    ? `${(constraint as SourceTimeframeConstraint).maximum} months`
                    : ""
            }`;
            break;
        case "State":
            value = (constraint as StateConstraint).states
                ?.map((state) => state.name)
                .join(", ");
            break;
        case "Timing Status":
            value = (constraint as TimingStatusConstraint).statuses
                ?.map((status) => status.value)
                .join(", ");
            break;
    }

    // don't render constraint if it has profiles
    if (constraint.has_profiles) return null;

    return value || value === 0 ? (
        <PDFView style={pdfStyle.itemValueContainer} key={key}>
            <PDFText
                style={[
                    pdfStyle.itemValue,
                    constraint.filter_type !== "Requirement"
                        ? pdfStyle.itemValuePriority
                        : {},
                ]}
            >
                {value}
            </PDFText>
            {constraint.constraint_type === "Exclude" ? (
                <PDFView style={pdfStyle.itemValueTag}>
                    <PDFTag
                        color={additionalColors.orangeyRed}
                        level={0}
                        textColorMatchesBorder={true}
                    >
                        Exclude
                    </PDFTag>
                </PDFView>
            ) : null}
            {constraint.filter_type && constraint.filter_type !== "Requirement" ? (
                <PDFView style={pdfStyle.itemValueTag}>
                    <PDFTag
                        color={
                            constraint.filter_type === "Priority - High"
                                ? additionalColors.green500
                                : constraint.filter_type === "Priority - Medium"
                                ? additionalColors.blue500Primary
                                : constraint.filter_type === "Priority - Low"
                                ? additionalColors.lightOrange
                                : "black"
                        }
                        level={0}
                        textColorMatchesBorder={true}
                    >
                        {constraint.filter_type.split("-").pop().trim()}
                    </PDFTag>
                </PDFView>
            ) : null}
        </PDFView>
    ) : null;
};

export default RenderConstraint;
