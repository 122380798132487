// This is available as an assignee that is present by default on all teams, via frontend magic. Due to limitations of having one user to one team per
// the `user_data_profile` table, at the time that this was created, it was too difficult to alter the database to have this user present on all teams.

import { createProfile, createUser } from "testing/objectCreationHelpers";

// It was decided to instead add the `other_assignee` boolean field to the Opportunity model in the backend combined with frontend logic.
export const repOrDistributor = createProfile({
    id: null,
    user: createUser({
        email: "test@citylitics.com",
        first_name: "Rep or",
        last_name: "Distributor",
        is_staff: false,
    }),
});
