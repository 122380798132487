import { withSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { useSnackStore } from "stores/zustandStore";

export const ZustandSnackMiddleman = ({ enqueueSnackbar }) => {
    const [displayedSnacks, setSnackDisplayed] = useState({});
    const snacks = useSnackStore((state) => state.snacks);

    useEffect(() => {
        snacks.forEach((snack) => {
            if (displayedSnacks[snack.key]) return;
            displayedSnacks[snack.key] = snack;
            setSnackDisplayed(displayedSnacks);
            enqueueSnackbar(snack.message, {
                ...snack.options,
                key: snack.key,
            });
        });
    }, [snacks, displayedSnacks, enqueueSnackbar]);
    return null;
};

export default withSnackbar(ZustandSnackMiddleman);
