import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";

import InsightFactoidList from "components/molecules/InsightFactoidList";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { DeepInsight } from "reactQuery/hooks/apiTypes";
import { useGetSingleTeam } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetSingleInsight } from "reactQuery/hooks/useInsights";
import { overlayNames } from "stores/uiStore/constants";
import { isIntelligenceFeedsRoute } from "utils/redirect";

import { buttonTestIds } from "..";

export interface CompetitorsConsultantsFactoidsProps {
    insight: DeepInsight;
    isEditModeActive: boolean;
    setInsightForCompanyRemoval: Function;
    toggleOverlay: Function;
    opportunityType: OpportunityTypeEnum;
}

const CompetitorsConsultantsFactoids = ({
    insight,
    isEditModeActive,
    setInsightForCompanyRemoval,
    toggleOverlay,
    opportunityType,
}: CompetitorsConsultantsFactoidsProps) => {
    const { search } = useLocation();
    const teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? Number(teamIdString) : undefined;
    const isIntelligenceFeeds = isIntelligenceFeedsRoute();

    const singleTeamQuery = useGetSingleTeam(teamId);
    const singleInsightQuery = useGetSingleInsight(
        insight.id,
        singleTeamQuery.isSuccess,
        isIntelligenceFeeds ? teamId : undefined
    );

    if (!singleTeamQuery.isSuccess || !singleInsightQuery.isSuccess) {
        return null;
    }

    if (isIntelligenceFeeds) {
        return (
            <>
                <InsightFactoidList
                    insight={insight}
                    isEditModeActive={false}
                    label="Competitors"
                    renderItem={(competitor) => {
                        return (
                            <div>
                                <Typography>{competitor.name}</Typography>
                            </div>
                        );
                    }}
                    values={singleInsightQuery.data?.spd_competitors}
                />
                <InsightFactoidList
                    insight={insight}
                    isEditModeActive={false}
                    label="Consultants"
                    renderItem={(consultant) => {
                        return (
                            <div>
                                <Typography>{consultant.name}</Typography>
                            </div>
                        );
                    }}
                    values={singleInsightQuery.data?.spd_consultants}
                />
            </>
        );
    } else {
        return (
            <>
                {opportunityType !== OpportunityTypeEnum.INDICATOR ? (
                    <InsightFactoidList
                        getEditTestId={() => buttonTestIds.competitor}
                        onEditClick={(insight, competitor) => {
                            setInsightForCompanyRemoval({
                                insight,
                                company: competitor,
                            });
                            toggleOverlay({
                                overlay: overlayNames.competitorModal,
                            });
                        }}
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        label="Competitors"
                        renderItem={(competitor) => {
                            return (
                                <div>
                                    <Typography>{competitor.name}</Typography>
                                </div>
                            );
                        }}
                        values={insight.competitors}
                    />
                ) : null}
                <InsightFactoidList
                    getEditTestId={() => buttonTestIds.consultant}
                    onEditClick={(insight, consultant) => {
                        setInsightForCompanyRemoval({
                            insight,
                            company: consultant,
                        });
                        toggleOverlay({
                            overlay: overlayNames.consultantModal,
                        });
                    }}
                    insight={insight}
                    isEditModeActive={isEditModeActive}
                    label="Consultants"
                    renderItem={(consultant) => {
                        return (
                            <div>
                                <Typography>{consultant.name}</Typography>
                            </div>
                        );
                    }}
                    values={insight.consultants}
                />
            </>
        );
    }
};

export default CompetitorsConsultantsFactoids;
