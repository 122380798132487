import { Button, Form, Modal, Row, Space, Typography } from "antd";
import { useLocation, useHistory } from "react-router-dom";

import { usePatchOpportunity } from "reactQuery/hooks/useOpportunity";
import { ModalNames, useModalStore, useSnackStore } from "stores/zustandStore";
import { useGetBasePath } from "utils/useGetBasePath";

export const RemoveOpportunityModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    const opportunity = modalInitialValues?.opportunity;
    const patchOpportunityQuery = usePatchOpportunity();

    const { search } = useLocation();
    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? parseInt(teamIdString) : null;

    const [form] = Form.useForm();
    const formId = "remove-opportunity-modal-form";

    const onModalCancel = () => {
        setModalHidden();
    };

    const { push } = useHistory();
    const basepath = useGetBasePath();

    const onFinish = async () => {
        patchOpportunityQuery.mutate(
            {
                opportunityPayload: {
                    is_deleted: true,
                },
                opportunityId: opportunity?.id,
                teamId: teamId,
            },
            {
                onSuccess: () => {
                    setModalHidden();
                    displaySuccessSnack({
                        message: "Opportunity removed successfully. Redirecting...",
                    });

                    setTimeout(() => {
                        push(`${basepath}${search}`);
                    }, 2000);
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Failed to remove opportunity",
                    });
                },
            }
        );
    };

    const ownerLabel = `${opportunity?.owner?.legal_name} (${opportunity?.owner?.state_code})`;

    return (
        <Modal
            open={openModal === ModalNames.RemoveOpportunity}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={
                <Row justify="end">
                    <Button key="cancel" type="default" onClick={onModalCancel}>
                        Cancel
                    </Button>
                    <Button
                        loading={patchOpportunityQuery.isLoading}
                        disabled={patchOpportunityQuery.isLoading}
                        form={formId}
                        key="submit"
                        htmlType="submit"
                        type="primary"
                    >
                        Remove
                    </Button>
                </Row>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="vertical"
                style={{ width: "80%", margin: "auto" }}
                preserve={false}
                onFinish={onFinish}
            >
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <Space>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            Remove Opportunity
                        </Typography.Title>
                    </Space>
                </div>

                <Space size={20} direction="vertical" style={{ width: "100%" }}>
                    <div>
                        <Typography.Text style={{ marginRight: "10px" }} strong>
                            Owner:
                        </Typography.Text>
                        <Typography.Text>{ownerLabel}</Typography.Text>
                    </div>

                    <div>
                        <Typography.Text style={{ marginRight: "10px" }} strong>
                            Opportunity Name:
                        </Typography.Text>
                        <Typography.Text>{opportunity?.name}</Typography.Text>
                    </div>
                </Space>
            </Form>
        </Modal>
    );
};

export default RemoveOpportunityModal;
