import DateFnsUtils from "@date-io/date-fns";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import SalesStageTimeline from "components/cdv/SalesStageTimeline";
import { FormModal } from "components/molecules/Modal";
import { activeInsightSelector } from "stores/demographics/demographicSlice";
import { addSalesStage } from "stores/opportunities/actions";
import { activeOpportunityIdSelector } from "stores/opportunities/selectors";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { userDataSelector } from "stores/userStore/selectors";

export const stageOptions = [
    { label: "Prospecting - Research", value: 1 },
    { label: "Prospecting - Qualification", value: 2 },
    { label: "Prospecting - Other", value: 3 },
    { label: "Utility Response - Needs Analysis", value: 4 },
    { label: "Utility Response - Value Proposition", value: 5 },
    { label: "Utility Response - Sent Product Materials", value: 6 },
    { label: "Utility Response - Webinar or Demo Presentation", value: 7 },
    { label: "Utility Response - Other", value: 8 },
    { label: "Opportunity Development - Id. Decision Makers", value: 9 },
    { label: "Opportunity Development - Perception Analysis", value: 10 },
    { label: "Opportunity Development - Pilot Project", value: 11 },
    { label: "Opportunity Development - Other", value: 12 },
    { label: "Approval - Proposal or Price Quote", value: 13 },
    { label: "Approval - Negotiation or Review", value: 14 },
    { label: "Approval - Other", value: 15 },
    { label: "Closed - Won", value: 16 },
    { label: "Closed - Lost", value: 17 },
];

export const TEST_IDS = {
    autocomplete: "autocomplete-test-id",
    dropdown: "dropdown-test-id",
};

export const ERROR_MESSAGES = {
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
    stageMissing: "A stage option is required",
};

export const AddSalesStageSchema = Yup.object().shape({
    stageDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
    selectedStage: Yup.object().nullable().required(ERROR_MESSAGES.stageMissing),
    stageNotes: Yup.string(),
});

const formStyle = makeStyles(() => ({
    formControl: {
        // width: "100%",
        // display: "block"
    },
    autocomplete: {
        width: "100%",
    },
}));

export const AddSalesStageModalForm = ({
    errors,
    setFieldValue,
    handleChange,
    values: { stageDate },
}) => {
    const classes = formStyle();
    return (
        <Form>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                alignContent="center"
                spacing={1}
            >
                <Grid item xs={12} style={{ marginRight: "65px" }}>
                    <SalesStageTimeline />
                </Grid>
                <Grid item xs={6}>
                    <FormHelperText data-testid="test-stage-date-error">
                        {errors.selectedStage}
                    </FormHelperText>
                    <FormControl fullWidth>
                        <Autocomplete
                            blurOnSelect={false}
                            className={classes.autocomplete}
                            data-testid={TEST_IDS.autocomplete}
                            debug
                            getOptionLabel={(option) => option.label}
                            label="Select Sales Stage"
                            ListboxProps={{ "data-testid": TEST_IDS.dropdown }}
                            multiple={false}
                            name="selectedStage"
                            options={stageOptions}
                            onChange={(e, v) => {
                                setFieldValue("selectedStage", v ? v : null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sales Stage Options"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingBottom: "9px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl
                            id="date-form-control"
                            error={"stageDate" in errors}
                            className={classes.formControl}
                            style={{ paddingLeft: "17px" }}
                        >
                            <InputLabel shrink style={{ paddingLeft: "30px" }}>
                                Date
                            </InputLabel>
                            <KeyboardDatePicker
                                disableToolbar
                                format="yyyy-MM-dd"
                                id="date-picker-inline"
                                inputProps={{
                                    "data-testid": "test-date-picker",
                                }}
                                invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                margin="normal"
                                name="stageDate"
                                onChange={(e) => setFieldValue("stageDate", e)}
                                value={stageDate}
                                variant="inline"
                                inputVariant="outlined"
                            />
                            <FormHelperText data-testid="test-stage-date-error">
                                {errors.stageDate ? ERROR_MESSAGES.dateMissing : null}
                            </FormHelperText>
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="stage-notes"
                        label="Add a Note (Optional)"
                        multiline
                        name="stageNotes"
                        onChange={handleChange}
                        defaultValue=""
                        error={"stageNotes" in errors}
                        helperText={errors.stageNotes || null}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

export const UnconnectedAddSalesStageModal = ({
    insight,
    isLoading,
    isVisible,
    toggleOverlay,
    ...formikProps
}) => (
    <FormModal
        handleClose={() => toggleOverlay({ overlay: overlayNames.addSalesStageModal })}
        handleSubmit={formikProps.handleSubmit}
        isSubmitDisabled={isLoading}
        isSubmitLoading={isLoading}
        isVisible={isVisible}
        submitLabel="Submit"
        title="Update Sales Stage"
    >
        <AddSalesStageModalForm {...formikProps} />
    </FormModal>
);

export const EnhancedAddSalesStageModal = withFormik({
    enabledReinitialize: true,
    handleSubmit: (
        { stageDate, stageNotes, selectedStage },
        { props: { addSalesStage, opportunityId, user } }
    ) => {
        addSalesStage({
            opportunityId,
            salesStageId: selectedStage.value,
            notes: stageNotes ? stageNotes : "",
            userId: user.id,
            stage_date: moment(stageDate).format("YYYY-MM-DD HH:mm:ss"),
        });
    },

    mapPropsToValues: () => ({
        selectedStage: "",
        stageDate: new Date(),
        stageNotes: "",
    }),

    validationSchema: () => AddSalesStageSchema,
    validateOnBlur: false,
    validateOnChange: false,
})(UnconnectedAddSalesStageModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isVisible: isShowing.addSalesStageModal,
        insight: activeInsightSelector(state),
        opportunityId: activeOpportunityIdSelector(state),
        user: userDataSelector(state),
    };
};

const ConnectedSalesStageModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    addSalesStage,
})(EnhancedAddSalesStageModal);

export default ConnectedSalesStageModal;
