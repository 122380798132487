import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, useHistory } from "react-router-dom";

import logo from "citylitics-logo.png";
import StyledButton from "components/atoms/StyledButton";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { logOut, useAuthLogout } from "reactQuery/hooks/userAuth/useLogout";

const headerStyle = makeStyles((theme) => ({
    image: {
        height: "25px",
        marginTop: "25px",
        marginBottom: "15px",
    },
    actions: {
        marginLeft: "auto",
    },
    bar: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: "25px",
        height: "80px",
    },
}));

export const Header = () => {
    const classes = headerStyle();
    const { push } = useHistory();
    const whoamiQuery = useGetWhoami();
    const logOutMutation = useAuthLogout();

    const actionBody = whoamiQuery.isSuccess ? (
        <span>
            <StyledButton
                variant="logout-button"
                handleClick={() => {
                    logOut(logOutMutation, () => (window.location.href = "/"));
                }}
            >
                SIGN OUT
            </StyledButton>
        </span>
    ) : null;

    return (
        <div>
            <CssBaseline />
            <AppBar className={classes.bar} position="static">
                <Toolbar>
                    <Link to="/">
                        <img alt="logo" className={classes.image} src={logo} />
                    </Link>
                    <div className={classes.actions}>{actionBody}</div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
