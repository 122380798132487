// TODO: change wasteWaterFacility variables to facility
import { createSelector } from "reselect";

import { universeReducerName } from "./reducer";

const universeStoreSelector = (state) => state[universeReducerName];

const companySearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.companySearch
);

export const companySearchErrorSelector = createSelector(
    companySearchSelector,
    (companySearch) => companySearch.error
);

export const companySearchIsSearchingSelector = createSelector(
    companySearchSelector,
    (companySearch) => companySearch.isSearching
);

export const companySearchResultsSelector = createSelector(
    companySearchSelector,
    (companySearch) => companySearch.results
);

const contactSearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.contactSearch
);

export const contactSearchErrorSelector = createSelector(
    contactSearchSelector,
    (contactSearch) => contactSearch.error
);

export const contactSearchIsSearchingSelector = createSelector(
    contactSearchSelector,
    (contactSearch) => contactSearch.isSearching
);

export const contactSearchResultsSelector = createSelector(
    contactSearchSelector,
    (contactSearch) => contactSearch.results
);

const drinkingWaterSystemSearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.drinkingWaterSystemSearch
);

export const drinkingWaterSystemSearchErrorSelector = createSelector(
    drinkingWaterSystemSearchSelector,
    (drinkingWaterSystemSearch) => drinkingWaterSystemSearch.error
);

export const drinkingWaterSystemSearchIsSearchingSelector = createSelector(
    drinkingWaterSystemSearchSelector,
    (drinkingWaterSystemSearch) => drinkingWaterSystemSearch.isSearching
);

export const drinkingWaterSystemSearchResultsSelector = createSelector(
    drinkingWaterSystemSearchSelector,
    (drinkingWaterSystemSearch) => drinkingWaterSystemSearch.results
);

const locationSearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.locationSearch
);

export const locationSearchErrorSelector = createSelector(
    locationSearchSelector,
    (locationSearch) => locationSearch.error
);

export const locationSearchIsSearchingSelector = createSelector(
    locationSearchSelector,
    (locationSearch) => locationSearch.isSearching
);

export const locationSearchResultsSelector = createSelector(
    locationSearchSelector,
    (locationSearch) => locationSearch.results
);

const ownerSearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.ownerSearch
);

export const ownerSearchErrorSelector = createSelector(
    ownerSearchSelector,
    (ownerSearch) => ownerSearch.error
);

const wasteWaterFacilitySearchSelector = createSelector(
    universeStoreSelector,
    (universeStore) => universeStore.wasteWaterFacilitySearch
);

export const wasteWaterFacilitySearchErrorSelector = createSelector(
    wasteWaterFacilitySearchSelector,
    (wasteWaterFacilitySearch) => wasteWaterFacilitySearch.error
);

export const wasteWaterFacilitySearchIsSearchingSelector = createSelector(
    wasteWaterFacilitySearchSelector,
    (wasteWaterFacilitySearch) => wasteWaterFacilitySearch.isSearching
);

export const wasteWaterFacilitySearchResultsSelector = createSelector(
    wasteWaterFacilitySearchSelector,
    (wasteWaterFacilitySearch) => wasteWaterFacilitySearch.results
);
