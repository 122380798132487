import { createReducer } from "@reduxjs/toolkit";

import {
    createDrinkingWaterSystem,
    fetchDrinkingWaterSystemsForOwner,
    setInsightForDrinkingWaterSystemLinkage,
    setInsightForDrinkingWaterSystemRemoval,
} from "stores/drinkingWaterSystems/actions";
import { drinkingWaterSystemsAdapter } from "stores/drinkingWaterSystems/adapter";

export const initialState = drinkingWaterSystemsAdapter.getInitialState({
    error: null,
    isLoading: false,
    isFetchingForOwner: false,
    hasErrorForOwner: null,
    newWaterSystem: {
        error: null,
        isCreating: false,
        insight: null,
    },
    removeWaterSystem: {
        error: false,
        insightId: null,
        isRemoving: false,
        waterSystem: null,
    },
});

const drinkingWaterSystemsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setInsightForDrinkingWaterSystemLinkage, (state, { payload }) => {
            state.newWaterSystem.insight = payload && payload.insight;
        })
        .addCase(setInsightForDrinkingWaterSystemRemoval, (state, { payload }) => {
            state.removeWaterSystem.insightId = payload.insightId;
            state.removeWaterSystem.waterSystem = payload.waterSystem;
        })
        .addCase(createDrinkingWaterSystem.pending, (state) => {
            state.newWaterSystem.error = null;
            state.newWaterSystem.isCreating = true;
        })
        .addCase(createDrinkingWaterSystem.fulfilled, (state, { payload }) => {
            state.newWaterSystem.error = null;
            state.newWaterSystem.isCreating = false;
            drinkingWaterSystemsAdapter.upsertOne(state, payload);
            state.newWaterSystem.isCreateModeActive = false;
            state.newWaterSystem.opportunityId = null;
        })
        .addCase(createDrinkingWaterSystem.rejected, (state, { payload }) => {
            state.newWaterSystem.error = payload;
            state.newWaterSystem.isCreating = false;
        })
        .addCase(fetchDrinkingWaterSystemsForOwner.pending, (state) => {
            state.hasErrorForOwner = null;
            state.isFetchingForOwner = true;
        })
        .addCase(fetchDrinkingWaterSystemsForOwner.fulfilled, (state, { payload }) => {
            drinkingWaterSystemsAdapter.upsertMany(state, payload);
            state.hasErrorForOwner = false;
            state.isFetchingForOwner = false;
        })
        .addCase(fetchDrinkingWaterSystemsForOwner.rejected, (state, { payload }) => {
            state.hasErrorForOwner = payload;
            state.isFetchingForOwner = false;
        });
});

export default drinkingWaterSystemsReducer;
