import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, withFormik, FormikProps } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { FormModal } from "components/molecules/Modal";
import { BaseTeamCustomerTag, DeepInsight } from "reactQuery/hooks/apiTypes";
import { useGetAllTeamCustomerTags } from "reactQuery/hooks/useTeamCustomerTags";
import {
    editInsightSelector,
    insightBeingEditedSelector,
    patchInsight,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

const teamCustomerTagsSchema = Yup.object().shape({
    teamCustomerTags: Yup.array().nullable(),
});

interface FormProps {
    selectedTeamCustomerTags?: BaseTeamCustomerTag[];
    teamId: number;
}

const TeamCustomerTagsForm = (props: FormikProps<FormProps>) => {
    const { setFieldValue, values } = props;
    const { selectedTeamCustomerTags, teamId } = values;
    const teamCustomerTagsQuery = useGetAllTeamCustomerTags(teamId);

    return (
        <Form>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Autocomplete
                        value={selectedTeamCustomerTags || []}
                        multiple={true}
                        loading={teamCustomerTagsQuery.isLoading}
                        options={teamCustomerTagsQuery.data || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => option?.name || ""}
                        getOptionSelected={(option, value) =>
                            option["id"] === value["id"]
                        }
                        onChange={(e, value) => {
                            setFieldValue("selectedTeamCustomerTags", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-team-customer-tags"
                                label="Tags"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

interface UnconnectedProps {
    insight: DeepInsight;
    isShowingModal: boolean;
    isUpdatingInsight: boolean;
    teamId: number;
    toggleOverlay: ({ overlay }: { overlay: string }) => void;
}

export const UnconnectedTeamCustomerTagsModal = ({
    insight,
    isShowingModal,
    isUpdatingInsight,
    teamId,
    toggleOverlay,
    ...formikProps
}: UnconnectedProps & FormikProps<FormProps>) => {
    formikProps.values.teamId = teamId;

    if (insight && isShowingModal && !isUpdatingInsight) {
        if (!formikProps.initialValues.selectedTeamCustomerTags) {
            formikProps.values.selectedTeamCustomerTags = insight.team_customer_tags;
        }
    }

    if (!isShowingModal) {
        return null;
    }

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.teamCustomerTagsModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSubmitDisabled={isUpdatingInsight}
            isSubmitLoading={isUpdatingInsight}
            isVisible={isShowingModal}
            title="Insight Tags"
            extraActions={null}
            isSecondaryDisabled={null}
            hasSecondaryButton={false}
            handleSecondaryClick={null}
        >
            <TeamCustomerTagsForm {...formikProps} />
        </FormModal>
    );
};

interface EnhancedProps {
    insight: DeepInsight;
    teamCustomerTags?: BaseTeamCustomerTag[];
    patchInsight: ({
        insightId,
        team_customer_tag_ids,
    }: {
        insightId: number;
        team_customer_tag_ids?: number[];
    }) => void;
}

export const EnhancedTeamCustomerTagsModal = withFormik<EnhancedProps, FormProps>({
    enableReinitialize: true,
    handleSubmit: (
        { selectedTeamCustomerTags },
        { props: { insight, patchInsight } }
    ) => {
        const teamCustomerTagIds = selectedTeamCustomerTags.map(
            (selectedTeamCustomerTag) => selectedTeamCustomerTag.id
        );
        patchInsight({
            insightId: insight.id,
            team_customer_tag_ids: teamCustomerTagIds,
        });
    },
    mapPropsToValues: () => ({
        teamId: null,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: teamCustomerTagsSchema,
})(UnconnectedTeamCustomerTagsModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    const editInsight = editInsightSelector(state);

    return {
        insight: insightBeingEditedSelector(state as never),
        isShowingModal: isShowing.teamCustomerTagsModal,
        isUpdatingInsight: editInsight.isUpdating,
    };
};
export const ConnectedTeamCustomerTagsModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    patchInsight,
})(EnhancedTeamCustomerTagsModal);

export default ConnectedTeamCustomerTagsModal;
