import { useQuery } from "@tanstack/react-query";

import { FEED_TYPES } from "constants/feedTypes";
import { nautilusGetRequest } from "network";
import { tableDataKeys, tableFiltersKeys, tableOpportunityKeys } from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { PaginatedIndicatorFeedPageInsightsList } from "../apiTypes";

const feedType = FEED_TYPES.INDICATOR_FEED;

async function getIndicatorFeed(
    queryParams?: string,
    urlAction: string = ""
): Promise<PaginatedIndicatorFeedPageInsightsList> {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/indicator_feed/${urlAction}`,
        queryParams
    );
    return data;
}

export function useGetIndicatorFeed({ enabled = true }: { enabled?: boolean }) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
    });
    return useQuery({
        queryKey: tableDataKeys.createKey(feedType, queryParams),
        queryFn: (): Promise<PaginatedIndicatorFeedPageInsightsList> =>
            getIndicatorFeed(queryParams),
        enabled,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
}

export function useGetIndicatorFeedFilters({
    indicator_group_id,
    enabled = true,
}: {
    indicator_group_id?: number;
    enabled?: boolean;
}) {
    return useQuery({
        queryKey: tableFiltersKeys.indicatorFeed(indicator_group_id),
        queryFn: async () => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/indicator_feed/${indicator_group_id}/filters/`
            );
            return data;
        },
        enabled,
    });
}

export function useGetIndicatorFeedOpportunities({
    indicator_group_id,
    enabled = true,
}: {
    indicator_group_id?: number;
    enabled?: boolean;
}) {
    const queryParams = queryParamBuilder({ includeBaseParams: true });
    return useQuery({
        queryKey: [
            tableOpportunityKeys.indicatorFeedOpportunities(indicator_group_id),
            queryParams,
        ],
        queryFn: (): Promise<number[]> =>
            // @ts-ignore
            getIndicatorFeed(queryParams, "opportunities/"),
        enabled,
    });
}
