import { CaretUpFilled, CaretDownFilled, FilterFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const HeaderIcons = ({
    isLoading,
    styles,
    showSortingIcon = false,
    showFilteringIcon = false,
}: {
    styles: any;
    isLoading: boolean;
    showSortingIcon?: boolean;
    showFilteringIcon?: boolean;
}) => {
    const message =
        showSortingIcon && showFilteringIcon
            ? "Filters and sorters are still loading"
            : showSortingIcon
            ? "Sorters are still loading"
            : showFilteringIcon
            ? "Filters are still loading"
            : undefined;

    return isLoading && (showFilteringIcon || showSortingIcon) ? (
        <div>
            <Tooltip className={styles.iconsContainer} title={message}>
                {showSortingIcon ? (
                    <div className={styles.sortingIcon}>
                        <CaretUpFilled />
                        <CaretDownFilled className={styles.caretDown} />
                    </div>
                ) : null}
                {showSortingIcon && showFilteringIcon ? (
                    <span style={{ width: "7px" }} />
                ) : null}
                {showFilteringIcon ? <FilterFilled /> : null}
            </Tooltip>
        </div>
    ) : showFilteringIcon ? (
        <div>
            <Tooltip title="Click to filter column">
                <FilterFilled />
            </Tooltip>
        </div>
    ) : null;
};

export default HeaderIcons;
