import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { UseMutationResult } from "@tanstack/react-query";
import { withFormik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import FormSearchField from "components/molecules/FormSearchField";
import { FormModal } from "components/molecules/Modal";
import { repOrDistributor } from "constants/assignees";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { ShallowProfile, OpportunityRead } from "reactQuery/hooks/apiTypes";
import { usePatchOpportunity } from "reactQuery/hooks/useOpportunity";
import { useGetAllTeamProfiles } from "reactQuery/hooks/useProfiles";
import { useModalStore, useSnackStore } from "stores/zustandStore";
export const renderOpportunityAssigneeName = ({ user }) =>
    `${user.first_name} ${user.last_name}`;

const OpportunityAssigneeSchema = Yup.object().shape({
    selectedOpportunityAssignees: Yup.array(),
});
const formStyle = makeStyles((theme) => ({
    helperText: {
        marginBottom: "20px",
    },
}));

const OpportunityAssigneeForm = ({
    teamProfiles,
    errors,
    opportunityType,
    ...formikProps
}: {
    teamProfiles: ShallowProfile[];
    errors: any;
    opportunityType: OpportunityTypeEnum;
} & Partial<FormikProps<FormProps>>) => {
    const styles = formStyle();
    const [teamProfilesOptions, setTeamProfilesOptions] = useState([]);
    //   Exclude all citylitics users from the list of possible assignee options
    teamProfiles = teamProfiles.filter((profile) => {
        return !profile.user.email.includes("citylitics");
    });
    const teamProfilesNames = teamProfiles.sort((a, b) => {
        return a.user.first_name.localeCompare(b.user.first_name);
    });

    if (opportunityType === OpportunityTypeEnum.REPORT) {
        if (!teamProfilesNames.some((name) => name.id === null)) {
            teamProfilesNames.push(repOrDistributor);
        }
    }

    return (
        <>
            <Typography className={styles.helperText}>
                Please select the sales team members you would like to assign this
                opportunity to
            </Typography>
            <FormSearchField
                name="selectedOpportunityAssignees"
                label="Sales Team Members"
                value={formikProps.values.selectedOpportunityAssignees}
                initialOptions={
                    teamProfilesNames.filter(
                        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                    ) || []
                }
                onChange={(e, v) => {
                    formikProps.setFieldValue("selectedOpportunityAssignees", v);
                    setTeamProfilesOptions(teamProfilesNames);
                }}
                getOptionLabel={renderOpportunityAssigneeName}
                multiple={true}
                helperText="Try searching by company name"
                renderOption={renderOpportunityAssigneeName}
                onInputDelayComplete={(searchText) => {
                    setTeamProfilesOptions(
                        teamProfilesNames.filter((assignee) => {
                            return (
                                assignee.user.first_name
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase()) ||
                                assignee.user.last_name
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                            );
                        })
                    );
                }}
                errors={errors}
                allOptions={teamProfilesOptions}
            />
        </>
    );
};

export interface OpportunityAssigneeModalProps {
    opportunity: OpportunityRead;
    opportunityType: OpportunityTypeEnum;
}

export const OpportunityAssigneeModalNoFormik = ({
    opportunity,
    opportunityType,
    ...formikProps
}: OpportunityAssigneeModalProps & FormikProps<FormProps>) => {
    const { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const teamProfiles = useGetAllTeamProfiles(teamId, true).data;

    const isVisible = useModalStore(
        (state) => state.opportunityAssigneeModalVisibility
    );
    const setVisibility = useModalStore(
        (state) => state.setOpportunityAssigneeModalVisibility
    );
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    formikProps.values.usePatchOpportunityQuery = usePatchOpportunity();
    formikProps.values.teamId = teamId;
    formikProps.values.setVisibility = setVisibility;

    useEffect(() => {
        if (formikProps.values.usePatchOpportunityQuery.isSuccess) {
            displaySuccessSnack({ message: "Assignees were successfully updated" });
        } else if (formikProps.values.usePatchOpportunityQuery.isError) {
            displayErrorSnack({ message: "Failed to update assignees" });
        }
    }, [formikProps.values.usePatchOpportunityQuery.isLoading]);

    return teamProfiles ? (
        <FormModal
            handleClose={() => {
                setVisibility(false);
            }}
            handleSubmit={formikProps.handleSubmit}
            isVisible={isVisible}
            submitLabel="Submit"
            title={"Update Assignees"}
            handleSecondaryClick={null}
            hasSecondaryButton={false}
        >
            <OpportunityAssigneeForm
                teamProfiles={teamProfiles}
                opportunityType={opportunityType}
                errors={formikProps.errors}
                {...formikProps}
            />
        </FormModal>
    ) : null;
};

export interface EnhancedProps {
    opportunity: OpportunityRead;
    opportunityType: OpportunityTypeEnum;
}
export interface FormProps {
    selectedOpportunityAssignees: ShallowProfile[];
    teamId: number;
    usePatchOpportunityQuery: UseMutationResult<OpportunityRead>;
    setVisibility: (visibility: boolean) => void;
}
export const OpportunityAssigneeModal = withFormik<EnhancedProps, FormProps>({
    enableReinitialize: true,
    handleSubmit: (
        {
            selectedOpportunityAssignees,
            teamId,
            usePatchOpportunityQuery,
            setVisibility,
        },
        { props: { opportunity } }
    ) => {
        const assignees = selectedOpportunityAssignees.reduce((a, c) => {
            if (c.id !== null) {
                a.push(c.id);
            }
            return a;
        }, []);
        usePatchOpportunityQuery.mutate({
            opportunityPayload: {
                assignees: assignees,
                other_assignee: selectedOpportunityAssignees.some(
                    (assignee) => assignee.id === null
                ),
            },
            opportunityId: opportunity?.id,
            teamId: teamId,
        });
        setVisibility(false);
    },
    mapPropsToValues: (props) => ({
        selectedOpportunityAssignees: props.opportunity?.assignees,
        teamId: null,
        usePatchOpportunityQuery: null,
        setVisibility: null,
    }),
    validationSchema: () => OpportunityAssigneeSchema,
    validateOnBlur: false,
    validateOnChange: false,
})(OpportunityAssigneeModalNoFormik);
