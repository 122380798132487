import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { nautilusGetRequest } from "network";
import { displayErrorSnack } from "stores/uiStore/actionTypes";

export const tagsAdapter = createEntityAdapter({});

export const tagReducerName = "tags";

const formatName = (name) => `${tagReducerName}/${name}`;

export const initialState = tagsAdapter.getInitialState({
    error: null,
    isLoading: false,
});

// Thunks
export const fetchTags = createAsyncThunk(
    formatName("fetchTags"),
    async (arg, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest("/api/universe/tags/");
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to fetch tags",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

// Slice

const tagSlice = createSlice({
    name: tagReducerName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchTags.pending]: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        [fetchTags.fulfilled]: (state, action) => {
            tagsAdapter.upsertMany(state, action.payload);
            state.error = null;
            state.isLoading = false;
        },
        [fetchTags.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

const { reducer } = tagSlice;

export default reducer;

// Selectors

export const tagStoreSelector = (state) => state[tagReducerName];

export const tagsSelectors = tagsAdapter.getSelectors(tagStoreSelector);

export const isLoadingTagsSelector = createSelector(
    tagStoreSelector,
    (tagStore) => tagStore.isLoading
);
