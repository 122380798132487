import Chip from "@material-ui/core/Chip";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { additionalColors, brandColors } from "constants/colors";

const current_stage = "Prospecting";
const steps = [
    "Prospecting",
    "Utility Response",
    "Opp. Development",
    "Approved",
    "Closed",
];

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 6,
        left: "calc(-50% + 2px)",
        right: "calc(50% + 2px)",
    },
    active: {
        "& $line": {
            backgroundColor: brandColors.green,
        },
    },
    completed: {
        "& $line": {
            backgroundColor: brandColors.green,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: additionalColors.grey300,
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: additionalColors.mediumGrey,
        zIndex: 1,
        color: additionalColors.white,
        width: 15,
        height: 15,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundColor: brandColors.darkBlue,
    },
    completed: {
        backgroundColor: brandColors.green,
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {};

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    complete_chip: {
        backgroundColor: brandColors.green,
        color: brandColors.darkBlue,
        width: 95,
        fontSize: 10,
    },
    incomplete_chip: {
        backgroundColor: additionalColors.grey300,
        color: additionalColors.grey900,
        width: 95,
        fontSize: 10,
    },
    current_chip: {
        backgroundColor: brandColors.darkBlue,
        color: additionalColors.white,
        width: 95,
        fontSize: 10,
    },
}));

function getCurrentStep() {
    return steps.indexOf(current_stage);
}

export const SalesStageTimeline = () => {
    const classes = useStyles();
    const stage = getCurrentStep();
    const [activeStep] = React.useState(stage);

    return (
        <div className={classes.root}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <Chip
                                size="small"
                                label={label}
                                className={
                                    index < stage
                                        ? classes.complete_chip
                                        : index === stage
                                        ? classes.current_chip
                                        : classes.incomplete_chip
                                }
                            />
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

export default SalesStageTimeline;
