// TODO: change wasteWaterFacility variables to facility
import { actionTypes as userActionTypes } from "stores/userStore/actionTypes";

import actionTypes from "./actionTypes";

const _ = require("lodash");
export const uiReducerName = "ui";
export const initialState = {
    snacks: [],
    isEditModeActive: false,
    isShowing: {
        addContactModal: false,
        addTimelineModal: false,
        addDrinkingWaterSystemModal: false,
        addIntelligenceModal: false,
        addIndicatorModal: false,
        addLocationModal: false,
        addWasteWaterFacilityModal: false,
        addIntelligenceNotesModal: false,
        addSalesStageModal: false,
        createInsightModal: false,
        createReportModal: false,
        createSourceModal: false,
        consultantModal: false,
        competitorModal: false,
        editContactModal: false,
        editDrinkingWaterSystemModal: false,
        editInsightModal: false,
        editIndicatorModal: false,
        editOpportunityOwnerModal: false,
        editSalesStageModal: false,
        editTimelineModal: false,
        editSourceModal: false,
        editWasteWaterFacilityModal: false,
        editIntelligenceNotesModal: false,
        editReportModal: false,
        teamCustomerTagsModal: false,
        queryHistory: false,
        removeConsultantModal: false,
        removeContactModal: false,
        removeDrinkingWaterSystemModal: false,
        removeLocationModal: false,
        removeWasteWaterFacilityModal: false,
        removeTimelineModal: false,
        removeIntelligenceNotesModal: false,
        removeSalesStageModal: false,
        removeReportModal: false,
        staticLinkModal: false,
        targetSurfacerSearchModal: false,
    },
    error: null,
};

const ui = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.SET_USER_DATA:
            return {
                ...state,
                isEditModeActive: action.payload.user.is_staff,
                isAllowedToChangeOpportunity:
                    action.payload.user.is_staff ||
                    _.includes(
                        action.payload.permissions,
                        "target_reports.change_opportunity"
                    ),
            };
        case actionTypes.DISPLAY_SNACK:
            return {
                ...state,
                snacks: [...state.snacks, { ...action.payload, dismissed: false }],
            };
        case actionTypes.CLOSE_SNACK:
            return {
                ...state,
                snacks: state.snacks.map((snack) =>
                    snack.key === action.payload.key
                        ? { ...snack, dismissed: true }
                        : snack
                ),
            };
        case actionTypes.CLOSE_ALL_SNACKS:
            return {
                ...state,
                snacks: state.snacks.map((snack) => ({ ...snack, dismissed: true })),
            };
        case actionTypes.TOGGLE_EDIT_MODE:
            return {
                ...state,
                isEditModeActive: !state.isEditModeActive,
            };
        case actionTypes.TOGGLE_OVERLAY:
            const overlay = action.payload.overlay;
            return {
                ...state,
                isShowing: {
                    ...state.isShowing,
                    [overlay]: !state.isShowing[overlay],
                },
            };
        case actionTypes.CLOSE_ALL_OVERLAYS:
            return {
                ...state,
                isShowing: {
                    ...initialState.isShowing,
                },
            };
        default:
            return state;
    }
};

export default ui;
