import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { additionalColors } from "constants/colors";

const labelAndValueStyles = makeStyles((theme) => ({
    gridCell: {
        paddingLeft: "12px !important",
        paddingRight: "12px !important",
        paddingBottom: "0px !important",
        paddingTop: "0px !important",
    },
    optionLabel: {
        color: additionalColors.grey600,
    },
}));

type AutocompleteDropdownLabelAndValueProps = {
    shouldRightAlign?: boolean;
    label: string;
    value: string | number;
};

export const AutocompleteDropdownLabelAndValue = ({
    shouldRightAlign = false,
    label,
    value,
}: AutocompleteDropdownLabelAndValueProps) => {
    const styles = labelAndValueStyles();
    const valueOrUnknown = (value) => (value ? value : "Unknown");
    return shouldRightAlign ? (
        <>
            <Grid className={styles.gridCell} item xs={8} />
            <Grid className={styles.gridCell} item xs={2}>
                <Typography
                    className={styles.optionLabel}
                    data-testid={`${label}-label`}
                >
                    {label}:
                </Typography>
            </Grid>
            <Grid className={styles.gridCell} item xs={2}>
                <Typography data-testid={`${label}-value`}>
                    {valueOrUnknown(value)}
                </Typography>
            </Grid>
        </>
    ) : (
        <>
            <Grid className={styles.gridCell} item xs={2}>
                <Typography
                    className={styles.optionLabel}
                    data-testid={`${label}-label`}
                >
                    {label}:
                </Typography>
            </Grid>
            <Grid className={styles.gridCell} item xs={10}>
                <Typography data-testid={`${label}-value`}>
                    {valueOrUnknown(value)}
                </Typography>
            </Grid>
        </>
    );
};

export default AutocompleteDropdownLabelAndValue;
