import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Button, Layout, Space, Typography, Grid, Tooltip } from "antd";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { SettingsPathname } from "pages/Settings";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { logOut, useAuthLogout } from "reactQuery/hooks/userAuth/useLogout";

import Sidebar from "./Sidebar";

const { Header, Content } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const CollapseSidebarButton = ({
    collapsed,
    setCollapsed,
}: {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
}) => (
    <Button
        data-testid="collapse-sidebar-button"
        type="text"
        onClick={() => setCollapsed(!collapsed)}
    >
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            style: {
                fontSize: "24px",
            },
        })}
    </Button>
);

const HeaderBar = ({
    collapsed,
    setCollapsed,
    teamId,
}: {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    teamId: number;
}) => {
    const { xs: xsBreakpoint } = useBreakpoint();
    const logOutMutation = useAuthLogout();
    const whoamiQuery = useGetWhoami();

    let greeting: string, hasFirstName: boolean;
    if (whoamiQuery.isSuccess) {
        hasFirstName = !!whoamiQuery.data.user.first_name;
        greeting = `Hi ${
            hasFirstName
                ? whoamiQuery.data.user.first_name
                : whoamiQuery.data.user.username
        }`;
    }

    return (
        <Header
            style={{
                padding: "0 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
        >
            <CollapseSidebarButton collapsed={collapsed} setCollapsed={setCollapsed} />
            <Space size="large">
                {!xsBreakpoint ? (
                    <div style={{ gap: "6px", display: "flex", alignItems: "center" }}>
                        <Text data-testid="greeting">{greeting}</Text>
                        <Tooltip title="Settings">
                            <Link
                                to={`${SettingsPathname}?team_id=${teamId}`}
                                aria-label="primary"
                            >
                                <SettingOutlined data-testid="settings" />
                            </Link>
                        </Tooltip>
                    </div>
                ) : undefined}
                <Button
                    onClick={() =>
                        logOut(logOutMutation, () => (window.location.href = "/"))
                    }
                >
                    Sign out
                </Button>
            </Space>
        </Header>
    );
};

const SidebarLayout = ({ content }) => {
    const [collapsed, setCollapsed] = useState(false);
    let { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const [selectedTeamId, setSelectedTeamId] = useState(teamId ?? undefined);

    return (
        <Layout style={{ height: "100vh" }}>
            <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                selectedTeamId={selectedTeamId}
                setSelectedTeamId={setSelectedTeamId}
            />
            <Layout>
                <HeaderBar
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    teamId={teamId}
                />
                <Content
                    style={{
                        overflow: "scroll",
                    }}
                >
                    {content}
                </Content>
            </Layout>
        </Layout>
    );
};

export default SidebarLayout;
