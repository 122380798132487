import { Tooltip } from "antd";

import { ReactComponent as BullseyeIcon } from "components/atoms/BullseyeIcon/bullseye.svg";
import { ReactComponent as BullseyedIcon } from "components/atoms/BullseyeIcon/bullseyed.svg";
import { ReactComponent as BullseyedTeamIcon } from "components/atoms/BullseyeIcon/bullseyed_team.svg";
import { useGetFeedbackUsers } from "reactQuery/hooks/useOpportunityFeedbackUsers";

import OpportunityFeedbackUserTooltip from "../../molecules/OpportunityFeedback/OpportunityFeedbackUserTooltip";
import { useStyles } from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    bullseyeButton: (bullseyed: boolean) =>
        `bullseye button ${bullseyed ? "bullseyed" : "not bullseyed"}`,
    bullseyeCount: "bullseye count",
};

const Bullseye = ({
    teamId,
    opportunityId,
    retrieveFeedbackUsers,
    setRetrieveFeedbackUsers,
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    teamId: number;
    opportunityId: number;
    retrieveFeedbackUsers: boolean;
    setRetrieveFeedbackUsers: (_: boolean) => void;
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const getOpportunityFeedbackUsers = useGetFeedbackUsers({
        opportunityId,
        teamId,
        enabled: retrieveFeedbackUsers,
    });
    const styles = useStyles();

    const IconComponent = currentFeedback?.is_bullseyed
        ? BullseyedIcon
        : currentFeedback?.total_bullseye > 0
        ? BullseyedTeamIcon
        : BullseyeIcon;

    return (
        <>
            <Tooltip
                title={
                    <OpportunityFeedbackUserTooltip
                        title="Bullseye"
                        users={getOpportunityFeedbackUsers.data?.bullseye}
                    />
                }
                onOpenChange={(open) =>
                    open && !retrieveFeedbackUsers && setRetrieveFeedbackUsers(true)
                }
            >
                <IconComponent
                    className={[
                        styles.feedbackSvgSize,
                        allowFeedback
                            ? styles.feedbackIconEnabledStyle
                            : styles.feedbackIconDisabledStyle,
                    ].join(" ")}
                    onClick={(event) => handleFeedbackClicked(event, "bullseye")}
                    data-testid={testIds.bullseyeButton(
                        currentFeedback?.is_bullseyed ? true : false
                    )}
                />
            </Tooltip>
            <span data-testid={testIds.bullseyeCount}>
                {currentFeedback?.total_bullseye}
            </span>
        </>
    );
};

export default Bullseye;
