import { Form, withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { StyledButton } from "components/atoms";
import { EditModalBody } from "components/molecules/EditModalBody";
import FormSearchField from "components/molecules/FormSearchField";
import { FormModal } from "components/molecules/Modal";
import { Company, InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import {
    removeCompanySelector,
    insightForCompanyLinkageSelector,
} from "stores/companies/companySlice";
import { isUpdatingInsightSelector, patchInsight } from "stores/insights/insightSlice";
import { closeAllOverlays, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import {
    clearCompanySearchResults,
    searchForCompany,
} from "stores/universeStore/actionTypes";
import {
    companySearchIsSearchingSelector,
    companySearchResultsSelector,
} from "stores/universeStore/selectors";

export const competitorHelperText = "Try searching by company name";

export const ERROR_MESSAGES = {
    competitorMissing: "A competitor is required",
};

const CompetitorSchema = Yup.object().shape({
    selectedCompetitor: Yup.object()
        .nullable()
        .required(ERROR_MESSAGES.competitorMissing),
});

export const UnconnectedCompetitorModal = ({
    linkedCompetitors,
    clearCompanySearchResults,
    closeAllOverlays,
    companySearchIsSearching,
    companySearchResults,
    currentCompetitor,
    insight,
    editInsight,
    isLoading,
    isRemoveModeActive,
    isVisible,
    searchForCompany,
    toggleOverlay,
    ...formikProps
}) => {
    const bodyItems = [
        {
            label: "Competitor:",
            value: currentCompetitor ? currentCompetitor.name : "",
        },
    ];

    return (
        <FormModal
            handleClose={() => {
                closeAllOverlays({});
                clearCompanySearchResults();
            }}
            handleSubmit={formikProps.handleSubmit}
            hasSecondaryButton={currentCompetitor}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeCompetitorModal });
                toggleOverlay({ overlay: overlayNames.competitorModal });
            }}
            isSubmitDisabled={currentCompetitor ? !editInsight : isLoading}
            isSubmitLoading={isLoading}
            isVisible={isVisible || isRemoveModeActive}
            isRemoveModeActive={isRemoveModeActive}
            isEditModeActive={currentCompetitor ? true : false}
            title={
                isRemoveModeActive
                    ? "Remove Competitor"
                    : currentCompetitor
                    ? "Edit Competitor"
                    : "Add Competitor"
            }
        >
            {!isRemoveModeActive ? (
                <FormSearchField
                    name="selectedCompetitor"
                    label="Competitor"
                    isFetchingResults={companySearchIsSearching}
                    value={formikProps.values.selectedCompetitor}
                    initialOptions={linkedCompetitors}
                    onChange={(e, v) => {
                        formikProps.setFieldValue("selectedCompetitor", v);
                    }}
                    onInputDelayComplete={searchForCompany}
                    errors={formikProps.errors}
                    allOptions={companySearchResults || []}
                    noOptions={
                        <StyledButton
                            variant="admin-link"
                            url="admin/universe/company/add/"
                            target="_blank"
                        >
                            Add New Competitor
                        </StyledButton>
                    }
                />
            ) : (
                <EditModalBody
                    isLoading={!editInsight}
                    isRemoveModeActive={true}
                    removalMessage="Are you sure that you would like to remove this competitor from this insight? Once removed, this action cannot be reversed."
                    items={bodyItems}
                />
            )}
        </FormModal>
    );
};

export interface EnhancedProps {
    isRemoveModeActive: boolean;
    currentCompetitor: Company;
    insight: InsightInOpportunity;
    editInsight: any;
    clearCompanySearchResults: any;
    patchInsight: any;
}
export interface FormProps {
    selectedCompetitor: Company;
}

export const EnhancedCompetitorModal = withFormik<EnhancedProps, FormProps>({
    enableReinitialize: true,
    handleSubmit: (
        { selectedCompetitor },
        {
            props: {
                isRemoveModeActive,
                currentCompetitor,
                insight,
                editInsight,
                clearCompanySearchResults,
                patchInsight,
            },
            resetForm,
        }
    ) => {
        const competitors = currentCompetitor
            ? editInsight.competitors
                  .filter((competitor) => competitor.id !== currentCompetitor.id)
                  .map((competitor) => competitor.id)
            : insight.competitors.map((competitor) => competitor.id);

        if (isRemoveModeActive && currentCompetitor) {
            patchInsight({
                competitors,
                insightId: editInsight.id,
            });
        } else {
            if (editInsight) {
                patchInsight({
                    competitors: [...competitors, selectedCompetitor.id],
                    insightId: editInsight.id,
                });
            } else {
                patchInsight({
                    competitors: [...competitors, selectedCompetitor.id],
                    insightId: insight.id,
                });
            }
        }
        clearCompanySearchResults();
        resetForm();
    },
    mapPropsToValues: ({ currentCompetitor }) => ({
        selectedCompetitor: currentCompetitor ?? null,
    }),
    validationSchema: () => CompetitorSchema,
    validateOnBlur: false,
    validateOnChange: false,
})(UnconnectedCompetitorModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    const removeCompany = removeCompanySelector(state);
    return {
        linkedCompetitors: [],
        companySearchIsSearching: companySearchIsSearchingSelector(state as never),
        companySearchResults: companySearchResultsSelector(state as never),
        currentCompetitor: removeCompany.company,
        isVisible: isShowing.competitorModal,
        isRemoveModeActive: isShowing.removeCompetitorModal,
        isLoading: isUpdatingInsightSelector(state as never),
        insight: insightForCompanyLinkageSelector(state as never),
        editInsight: removeCompany.insight,
    };
};

const ConnectedCompetitorModal = connect(mapStateToProps, {
    closeAllOverlays,
    clearCompanySearchResults,
    searchForCompany,
    toggleOverlay,
    patchInsight,
})(EnhancedCompetitorModal);

export default ConnectedCompetitorModal;
