import { MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export const FormDropdownField = ({
    selectedOption = "",
    allOptions,
    setSelectedOption,
    disabled = false,
}) => {
    return (
        <FormControl fullWidth>
            <Select
                disabled={disabled}
                variant="outlined"
                name="selectedAction"
                value={selectedOption}
                data-testid="dropdown-test-id"
                onChange={(e) => {
                    setSelectedOption(e.target.value as string);
                }}
            >
                {allOptions.map((action) => (
                    <MenuItem key={action} value={action}>
                        {action}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default FormDropdownField;
