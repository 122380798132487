import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const rowStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1.5, 0),
        borderBottom: `1px ${theme.palette.grey[300]} solid`,
        display: "flex",
        justifyContent: "space-between",
    },
    label: {
        color: theme.palette.grey[500],
        fontSize: "14px",
    },
    value: {
        textAlign: "right",
        fontSize: "14px",
    },
}));

export const ContactCardRow = ({
    label,
    value,
    contactName,
}: {
    label: string;
    value: string;
    contactName?: string;
}) => {
    const styles = rowStyles();
    return value ? (
        <div className={styles.container}>
            <Typography className={styles.label}>{label}</Typography>
            {label === "Email" || label === "Alternate Email" ? (
                <p className={styles.value} style={{ margin: "0" }}>
                    <Typography
                        component="a"
                        href={"mailto:" + value}
                        style={{ fontSize: "14px" }}
                    >
                        {value}
                    </Typography>
                </p>
            ) : label === "LinkedIn" ? (
                <p className={styles.value} style={{ margin: "0" }}>
                    <Typography
                        component="a"
                        href={value}
                        target="_blank"
                        style={{ fontSize: "14px" }}
                    >
                        {contactName}
                    </Typography>
                </p>
            ) : (
                <Typography className={styles.value}>{value}</Typography>
            )}
        </div>
    ) : null;
};
