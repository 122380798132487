import { useQuery } from "@tanstack/react-query";

import { FEED_TYPES } from "constants/feedTypes";
import { nautilusGetRequest } from "network";
import { tableFiltersKeys, tableOpportunityKeys, tableDataKeys } from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { PaginatedSingleReportPageInsightsList } from "../apiTypes";

const feedType = FEED_TYPES.SINGLE_REPORT;

async function getSingleReport(
    queryParams?: string,
    urlAction: string = ""
): Promise<PaginatedSingleReportPageInsightsList> {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/single_report/${urlAction}`,
        queryParams
    );
    return data;
}

export function useGetSingleReport({ enabled = true }: { enabled?: boolean }) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
    });
    return useQuery({
        queryKey: tableDataKeys.createKey(feedType, queryParams),
        queryFn: (): Promise<PaginatedSingleReportPageInsightsList> =>
            getSingleReport(queryParams),
        enabled,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
}

export function useGetSingleReportFilters({
    report_id,
    enabled = true,
}: {
    report_id?: number;
    enabled?: boolean;
}) {
    return useQuery({
        queryKey: tableFiltersKeys.singleReport(report_id),
        queryFn: async () => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/single_report/${report_id}/filters/`
            );
            return data;
        },
        enabled,
    });
}

export function useGetSingleReportOpportunities({
    report_id,
    enabled = true,
}: {
    report_id?: number;
    enabled?: boolean;
}) {
    const queryParams = queryParamBuilder({ includeBaseParams: true });
    return useQuery({
        queryKey: [
            tableOpportunityKeys.singleReportOpportunities(report_id),
            queryParams,
        ],
        queryFn: (): Promise<number[]> =>
            //@ts-ignore
            getSingleReport(queryParams, "opportunities/"),
        enabled,
    });
}
