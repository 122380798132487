import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";

const RenderFacility = (facility) => {
    let mgdValue = facility.design_flow
        ? parseFloat(facility.design_flow).toFixed(2)
        : "Unknown";
    return (
        <PDFView>
            <PDFText>{facility.facility_name}</PDFText>
            <PDFText>{`MGD: ${mgdValue}`}</PDFText>
            <PDFText>
                {`NPDESID: ${
                    facility.npdes_id ? facility.npdes_id.toLocaleString() : "Unknown"
                }`}
            </PDFText>
        </PDFView>
    );
};

export default RenderFacility;
