import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React from "react";

import { brandColors } from "constants/colors";

export const SnackbarProviderWrapper = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const useStyles = makeStyles((theme) => ({
        error: { backgroundColor: brandColors.red, color: brandColors.darkBlue },
        success: { backgroundColor: brandColors.green, color: brandColors.darkBlue },
    }));

    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={3}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
            }}
        >
            {children}
        </SnackbarProvider>
    );
};
