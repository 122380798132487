import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { nautilusPostRequest } from "network";

export interface SyncToCRMWriteResponse {
    [key: number]: string;
}

interface SyncToCRMPostArguments {
    teamId: number;
    opportunityIds: number[];
    indicatorGroupId?: number;
}

async function postSyncToCRM({
    teamId,
    opportunityIds,
    indicatorGroupId,
}: SyncToCRMPostArguments): Promise<SyncToCRMWriteResponse> {
    const { data } = await nautilusPostRequest("/api/target_reports/sync_to_crm/", {
        team_id: teamId,
        opportunity_ids: opportunityIds,
        indicator_group_id: indicatorGroupId,
    });
    return data;
}

export function usePostSyncToCRM(): Partial<UseMutationResult<SyncToCRMWriteResponse>> {
    return useMutation((args: SyncToCRMPostArguments) => {
        return postSyncToCRM(args);
    });
}
