import { Checkbox, DatePicker, Divider, Form, Input, Select, InputNumber } from "antd";
import { useEffect, useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { FEED_TYPES } from "constants/feedTypes";
import { useGetCountyDemographicsByQuery } from "reactQuery/hooks/useCounties";
import { useGetCountries } from "reactQuery/hooks/useCountries";
import { useGetIndicatorIndustries } from "reactQuery/hooks/useIndicatorIndustries";
import { useGetNonLegacyIndicators } from "reactQuery/hooks/useIndicators";
import { useGetIndicatorTagsByQuery } from "reactQuery/hooks/useIndicatorTags";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";
import { useGetInsightStatuses } from "reactQuery/hooks/useInsights";
import { useGetOwnersByQuery } from "reactQuery/hooks/useOwners";
import { useGetOwnerTypes } from "reactQuery/hooks/useOwnerTypes";
import { useSearchQueries } from "reactQuery/hooks/useQueries";
import { useGetStates } from "reactQuery/hooks/useStates";
import {
    useGetIPLResultStatusChoices,
    useGetIPLResultAssigneeActionedStatusChoices,
} from "reactQuery/hooks/useTableAssociatedData";
import { useGetStaffUsers } from "reactQuery/hooks/useUsers";
import { useGetWarningLabelTypes } from "reactQuery/hooks/useWarningLabels";
const queryMinChars = 1;
const enterQueryPrompt = `Please enter at least ${queryMinChars} character`;

export const IPLResultFiltersTemplate = ({
    handleClose,
    feedType,
    filters,
    form,
    setFilters,
}: {
    handleClose: () => void;
    feedType: string;
    filters: any;
    form: any;
    setFilters: (filters: any) => void;
}) => {
    useEffect(() => {
        if (filters) {
            form.resetFields();
            form.setFieldsValue(filters);
        }
    }, [filters]);

    const { RangePicker } = DatePicker;

    const [countiesSearchQuery, setCountiesSearchQuery] = useState<string>("");
    const countiesQueryEnabled = countiesSearchQuery.length >= queryMinChars;
    const [currentDocumentCreationDate, setCurrentDocumentCreationDate] =
        useState<boolean>(null);
    const [indicatorTagsSearchQuery, setIndicatorTagsSearchQuery] =
        useState<string>("");
    const indicatorTagsQueryEnabled = indicatorTagsSearchQuery.length >= queryMinChars;
    const [ownerSearchQuery, setOwnerSearchQuery] = useState<string>("");
    const ownersQueryEnabled = ownerSearchQuery.length >= queryMinChars;
    const [querySearch, setQuerySearch] = useState<string>("");
    const searchQueriesQueryEnabled = querySearch.length >= queryMinChars;

    const countriesQuery = useGetCountries();
    const countiesQuery = useGetCountyDemographicsByQuery(
        countiesSearchQuery,
        countiesQueryEnabled
    );
    const indicatorsQuery = useGetNonLegacyIndicators();
    const indicatorIndustriesQuery = useGetIndicatorIndustries();
    const indicatorTagsQuery = useGetIndicatorTagsByQuery(
        indicatorTagsSearchQuery,
        indicatorTagsQueryEnabled
    );
    const ownersQuery = useGetOwnersByQuery(ownerSearchQuery, ownersQueryEnabled);
    const initiativeTypesQuery = useGetAllInitiativeTypes();
    const insightStatusesQuery = useGetInsightStatuses();
    const ownerTypesQuery = useGetOwnerTypes();
    const reviewStatusesQuery = useGetIPLResultAssigneeActionedStatusChoices();
    const staffUsersQuery = useGetStaffUsers();
    const statesQuery = useGetStates();
    const statusesQuery = useGetIPLResultStatusChoices();
    const searchQueriesQuery = useSearchQueries(querySearch, searchQueriesQueryEnabled);
    const warningLabelTypesQuery = useGetWarningLabelTypes();

    let timeout: ReturnType<typeof setTimeout> | null;

    const setRelevantState = (searchType: string, newValue: string) => {
        switch (searchType) {
            case "county":
                setCountiesSearchQuery(newValue);
                break;
            case "indicatorTag":
                setIndicatorTagsSearchQuery(newValue);
                break;
            case "owner":
                setOwnerSearchQuery(newValue);
                break;
            case "query":
                setQuerySearch(newValue);
                break;
        }
    };
    const debounceSearch = useSearchDebounced(setRelevantState);

    const handleValuesChanged = (_, allValues) => {
        if (
            allValues.document_creation_date &&
            (!currentDocumentCreationDate || currentDocumentCreationDate === null)
        ) {
            setCurrentDocumentCreationDate(true);
            if (form.getFieldValue("document_creation_date") !== null) {
                form.setFieldValue("exclude_document_creation_date_null", null);
            }
        } else if (
            !allValues.document_creation_date &&
            (currentDocumentCreationDate || currentDocumentCreationDate === null)
        ) {
            setCurrentDocumentCreationDate(false);
            if (form.getFieldValue("document_creation_date") === null) {
                form.setFieldValue("include_document_creation_date_null", null);
            }
        }
    };

    const onFinish = () => {
        setFilters({
            ...form.getFieldsValue(true),
            exclude_document_creation_date_null:
                form.getFieldValue("exclude_document_creation_date_null") === false
                    ? null
                    : form.getFieldValue("exclude_document_creation_date_null"),
            include_document_creation_date_null:
                form.getFieldValue("include_document_creation_date_null") === false
                    ? null
                    : form.getFieldValue("include_document_creation_date_null"),
        });
        handleClose();
    };

    const initialValues = {
        ...filters,
        min_population: filters.population?.[0]?.min,
        max_population: filters.population?.[0]?.max,
    };

    const documentCreationDateSpecified =
        currentDocumentCreationDate === true || currentDocumentCreationDate === false
            ? currentDocumentCreationDate
            : !!form.getFieldValue("document_creation_date");

    return (
        <>
            <Form
                initialValues={initialValues}
                id="table-filters-form"
                form={form}
                name="table-filters-form"
                labelWrap={true}
                layout="vertical"
                onFinish={onFinish}
                style={{ width: "100%" }}
                preserve={true}
                onValuesChange={handleValuesChanged}
            >
                <Divider plain style={{ fontWeight: "700" }}>
                    Result Filters
                </Divider>
                <Form.Item name="industry_ids" label="Industry">
                    <Select
                        placeholder="Filter by industry..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                    >
                        {indicatorIndustriesQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : indicatorIndustriesQuery.isSuccess ? (
                            indicatorIndustriesQuery.data.map((industry) => (
                                <Select.Option
                                    key={industry.id}
                                    value={industry.id}
                                    title={industry.value}
                                >
                                    {industry.value}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading industry options
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="indicator_ids" label="Indicator">
                    <Select
                        placeholder="Filter by indicator..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            const indicator = option.title.toLowerCase();
                            return indicator.includes(input.toLowerCase());
                        }}
                    >
                        {indicatorsQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : indicatorsQuery.isSuccess ? (
                            indicatorsQuery.data.map((indicator) => (
                                <Select.Option
                                    key={indicator.id}
                                    value={indicator.id}
                                    title={`${
                                        indicator.category
                                            ? indicator.category + " - "
                                            : ""
                                    }${indicator.value}`}
                                >
                                    {`${
                                        indicator.category
                                            ? indicator.category + " - "
                                            : ""
                                    }${indicator.value}`}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading indicator options
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="indicator_tag_id" label="Indicator Tag">
                    <Select
                        placeholder="Filter by indicator tag..."
                        onSearch={(query) => debounceSearch("indicatorTag", query)}
                        showSearch
                        filterOption={false}
                        allowClear={true}
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {!indicatorTagsQueryEnabled ? (
                            <Select.Option value="disabled" disabled>
                                {enterQueryPrompt}
                            </Select.Option>
                        ) : indicatorTagsQuery.isSuccess ? (
                            (indicatorTagsQuery.data || []).map((indicatorTag) => (
                                <Select.Option
                                    key={indicatorTag.id}
                                    value={indicatorTag.id}
                                    title={indicatorTag.value}
                                >
                                    {indicatorTag.value}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="query_id" label="Query">
                    <Select
                        placeholder="Filter by query..."
                        onSearch={(query) => debounceSearch("query", query)}
                        showSearch
                        filterOption={false}
                        allowClear={true}
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {!searchQueriesQueryEnabled ? (
                            <Select.Option value="disabled" disabled>
                                {enterQueryPrompt}
                            </Select.Option>
                        ) : searchQueriesQuery.isSuccess ? (
                            (searchQueriesQuery.data || []).map((query) => (
                                <Select.Option
                                    key={query.id}
                                    value={query.id}
                                    title={query.query_text}
                                >
                                    {query.query_text}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="metadata_score" label="Minimum Metadata Score">
                    <InputNumber min={-100} max={100} />
                </Form.Item>
                <Form.Item name="initiative_types" label="Initiative Type">
                    <Select
                        placeholder="Filter by initiative type..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                    >
                        {initiativeTypesQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : initiativeTypesQuery.isSuccess ? (
                            initiativeTypesQuery.data.map((initiativeType) => (
                                <Select.Option
                                    key={initiativeType.id}
                                    value={initiativeType.value}
                                    title={initiativeType.value}
                                >
                                    {initiativeType.value}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading initiative types
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="assignee_ids" label="Assignee">
                    <Select
                        placeholder="Filter by assignee..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                        disabled={[
                            FEED_TYPES.LW_MY_ACTIONED,
                            FEED_TYPES.LW_MY_QUEUE,
                        ].includes(feedType)}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            const fullName = option.title.toLowerCase();
                            return fullName.includes(input.toLowerCase());
                        }}
                    >
                        {staffUsersQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : staffUsersQuery.isSuccess ? (
                            staffUsersQuery.data.map((user) => (
                                <Select.Option
                                    key={user.id}
                                    value={user.id}
                                    title={`${user.first_name} ${user.last_name}`}
                                >
                                    {`${user.first_name} ${user.last_name}`}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading assignees
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="statuses" label="IPL Status">
                    <Select
                        mode="multiple"
                        options={
                            statusesQuery.isSuccess
                                ? [
                                      ...Object.keys(statusesQuery.data).map((key) => ({
                                          value: key,
                                          label: statusesQuery.data[key],
                                      })),
                                  ]
                                : [{ value: "Loading...", label: "Loading..." }]
                        }
                        disabled={
                            statusesQuery.isLoading ||
                            !statusesQuery.isSuccess ||
                            feedType === FEED_TYPES.LW_MY_QUEUE
                        }
                    />
                </Form.Item>
                <Form.Item name="review_statuses" label="Review Status">
                    <Select
                        mode="multiple"
                        options={
                            reviewStatusesQuery.isSuccess
                                ? [
                                      ...Object.keys(reviewStatusesQuery.data).map(
                                          (key) => ({
                                              value: key,
                                              label: reviewStatusesQuery.data[key],
                                          })
                                      ),
                                  ]
                                : [{ value: "Loading...", label: "Loading..." }]
                        }
                        disabled={
                            reviewStatusesQuery.isLoading ||
                            !reviewStatusesQuery.isSuccess
                        }
                    />
                </Form.Item>
                <Form.Item name="insight_statuses" label="Insight Status">
                    <Select
                        mode="multiple"
                        options={
                            insightStatusesQuery.isSuccess
                                ? [
                                      ...Object.keys(insightStatusesQuery.data).map(
                                          (key) => ({
                                              value: key,
                                              label: insightStatusesQuery.data[key],
                                          })
                                      ),
                                  ]
                                : [{ value: "Loading...", label: "Loading..." }]
                        }
                    />
                </Form.Item>
                <Form.Item name="linked_insight" label="Linked Insight">
                    <Select
                        defaultValue="All"
                        options={[
                            { value: "All", label: "All" },
                            { value: "True", label: "Yes" },
                            { value: "False", label: "No" },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="warning_label_types"
                    label="Warning Label Types"
                    extra="Note: Currently only supports IPL Warnings"
                >
                    <Select
                        mode="multiple"
                        options={
                            warningLabelTypesQuery.isSuccess
                                ? [
                                      ...Object.keys(warningLabelTypesQuery.data).map(
                                          (key) => ({
                                              value: key,
                                              label: warningLabelTypesQuery.data[key],
                                          })
                                      ),
                                  ]
                                : [{ value: "Loading...", label: "Loading..." }]
                        }
                    />
                </Form.Item>
                <Divider plain style={{ fontWeight: "700" }}>
                    Owner Filters
                </Divider>
                <Form.Item name="owner" label="Owner">
                    <Select
                        placeholder="Filter by owner..."
                        onSearch={(query) => debounceSearch("owner", query)}
                        showSearch
                        filterOption={false}
                        allowClear={true}
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {!ownersQueryEnabled ? (
                            <Select.Option value="disabled" disabled>
                                {enterQueryPrompt}
                            </Select.Option>
                        ) : ownersQuery.isSuccess ? (
                            (ownersQuery.data || []).map((owner) => (
                                <Select.Option
                                    key={owner.id}
                                    value={owner.legal_name}
                                    title={owner.legal_name}
                                >
                                    {owner.legal_name}, {owner.state_code}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="countries" label="Country">
                    <Select
                        placeholder="Filter by country..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                    >
                        {countriesQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : countriesQuery.isSuccess ? (
                            countriesQuery.data.map((country) => (
                                <Select.Option
                                    key={country.code}
                                    value={country.code}
                                    title={country.code}
                                >
                                    {country.code}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading industry options
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="states" label="State">
                    <Select
                        placeholder="Filter by state..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                    >
                        {statesQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : statesQuery.isSuccess ? (
                            statesQuery.data.map((state) => (
                                <Select.Option
                                    key={state.code}
                                    value={state.code}
                                    title={state.code}
                                >
                                    {state.code}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading industry options
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="county" label="County">
                    <Select
                        placeholder="Filter by county..."
                        onSearch={(query) => debounceSearch("county", query)}
                        showSearch
                        filterOption={false}
                        allowClear={true}
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {!countiesQueryEnabled ? (
                            <Select.Option value="disabled" disabled>
                                {enterQueryPrompt}
                            </Select.Option>
                        ) : countiesQuery.isSuccess ? (
                            (countiesQuery.data || []).map((county) => (
                                <Select.Option
                                    key={county.name}
                                    value={county.name}
                                    title={county.name}
                                >
                                    {county.name}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="owner_types" label="Owner Type">
                    <Select
                        placeholder="Filter by owner type..."
                        showSearch
                        mode="multiple"
                        allowClear={true}
                    >
                        {ownerTypesQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : ownerTypesQuery.isSuccess ? (
                            ownerTypesQuery.data.map((ownerType) => (
                                <Select.Option
                                    key={ownerType.owner_type}
                                    value={ownerType.owner_type}
                                    title={ownerType.owner_type}
                                >
                                    {ownerType.owner_type}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading industry options
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label="Population">
                    <Input.Group compact>
                        <Form.Item name="min_population" noStyle>
                            <Input
                                style={{
                                    width: 100,
                                    textAlign: "center",
                                    borderRight: 0,
                                }}
                                placeholder="Minimum"
                            />
                        </Form.Item>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: "none",
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item name="max_population" noStyle>
                            <Input
                                className="site-input-right"
                                style={{
                                    width: 100,
                                    textAlign: "center",
                                    borderLeft: 0,
                                }}
                                placeholder="Maximum"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Divider plain style={{ fontWeight: "700" }}>
                    Date Filters
                </Divider>
                <Form.Item name="document_creation_date" label="Document Creation Date">
                    <RangePicker />
                </Form.Item>
                {documentCreationDateSpecified ? (
                    <Form.Item
                        name="include_document_creation_date_null"
                        label="Document Creation Date Null"
                        valuePropName="checked"
                        wrapperCol={{ offset: 1, span: 16 }}
                        extra="Selecting this checkbox will include results with no document creation date in addition to those within the document creation date range selected above."
                    >
                        <Checkbox>Include</Checkbox>
                    </Form.Item>
                ) : null}
                {!documentCreationDateSpecified ? (
                    <Form.Item
                        name="exclude_document_creation_date_null"
                        label="Document Creation Date Null"
                        valuePropName="checked"
                        wrapperCol={{ offset: 1, span: 16 }}
                        extra="Selecting this checkbox will exclude results with no document creation date, which are included by default."
                    >
                        <Checkbox>Exclude</Checkbox>
                    </Form.Item>
                ) : null}
                <Form.Item name="date_created" label="IPL Result Creation Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="date_assigned" label="IPL Assigned Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="date_actioned" label="Date Actioned">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="insight_creation_date" label="Insight Creation Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="date_updated" label="Last Updated Date">
                    <RangePicker />
                </Form.Item>
            </Form>
        </>
    );
};
