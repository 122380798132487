// Can be found here: https://drive.google.com/file/d/1_529cxcHe2GXzTZVgMOgu7m8Rpyc72AP/view
export const brandColors = {
    darkBlue: "#163B5E",
    green: "#00EFA1",
    lightBlue: "#009BEE",
    neonGreen: "#AFF33B",
    orange: "#FF9C00",
    red: "#FE1F67",
    teal: "#00D2CF",
};

export const additionalColors = {
    aquaBlue: "#00b6e7",
    blue500Primary: "#2196f3",
    body: "rgba(0, 0, 0, 0.87)",
    body54: "rgba(0, 0, 0, 0.54)",
    green500: "#4caf50",
    grey300: "#e0e0e0",
    grey500: "#9e9e9e",
    grey600: "#7e7e7e",
    grey900: "#212121",
    grey999: "#999999",
    marigold: "#ffc107",
    marineBlue: "#002b5a",
    orangeyRed: "#f16133",
    pink500Secondary: "#f50057",
    red900: "#b71c1c",
    white: "#ffffff",
    black: "#000000",
    lightOrange: "#ffc069",
    reallyLightOrange: "#ffe7cd",
    mediumGrey: "#cccccc",
};
