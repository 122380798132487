import { Skeleton } from "antd";

import { Modal } from "components/molecules/Modal";
import { LW_VIEWS } from "constants/feedTypes";
import { useGetDetailsData } from "reactQuery/hooks/useDetailsData";

import { IPLResultInteractionBar } from "./interactions/IPLResultInteractionBar";
import { IPLResultTemplate } from "./templates/IPLResultTemplate";

interface ViewDetailsModalProps {
    defaultPageSize?: number;
    handleClose: () => void;
    selectedItemId: number;
    setSelectedItemId: (number) => void;
    itemTitle?: string;
    itemIds?: number[];
    itemIdsLoading?: boolean;
    feedType: string;
    teamId: number;
    visible: boolean;
}

const ViewDetailsModal = ({
    defaultPageSize,
    handleClose,
    selectedItemId,
    setSelectedItemId,
    itemTitle,
    itemIds,
    itemIdsLoading,
    feedType,
    visible,
}: ViewDetailsModalProps) => {
    const detailsDataQuery = useGetDetailsData({
        feedType,
        itemId: selectedItemId,
        enabled: visible && !!selectedItemId,
    });

    return (
        <Modal
            actions={null}
            isVisible={visible}
            extraActions={null}
            handleClose={() => {
                handleClose();
            }}
            title={detailsDataQuery.isLoading ? "Loading..." : itemTitle}
            width={1000}
        >
            {detailsDataQuery.isSuccess ? (
                <>
                    {LW_VIEWS.includes(feedType) ? (
                        <IPLResultTemplate result={detailsDataQuery.data} />
                    ) : null}
                    {LW_VIEWS.includes(feedType) ? (
                        <IPLResultInteractionBar
                            defaultPageSize={defaultPageSize}
                            feedType={feedType}
                            itemIds={itemIds}
                            itemIdsLoading={itemIdsLoading}
                            result={detailsDataQuery.data}
                            selectedItemId={selectedItemId}
                            setSelectedItemId={setSelectedItemId}
                        />
                    ) : null}
                </>
            ) : (
                <Skeleton active paragraph={{ rows: 10 }} />
            )}
        </Modal>
    );
};

export default ViewDetailsModal;
