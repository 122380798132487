import React from "react";
import { connect } from "react-redux";

import { Modal } from "components/molecules/Modal";
import ModalButton from "components/molecules/ModalButton";
import { clearQueryResults } from "stores/targets/actions";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const UnconnectedTargetSurfacerClearQueryResultsModal = ({
    clearQueryResults,
    isVisible,
    toggleOverlay,
}) => {
    return (
        <Modal
            width={600}
            isVisible={isVisible}
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.clearQueryResultsModal });
            }}
            title="Clear Results"
        >
            <div>
                <p>
                    Are you sure that you would want to clear the query results? Once
                    cleared, this action cannot be reversed.
                </p>
                <ModalButton
                    data-testid="clearBtn"
                    danger={true}
                    isVisible={true}
                    onClick={() => {
                        clearQueryResults({});
                        toggleOverlay({ overlay: overlayNames.clearQueryResultsModal });
                    }}
                >
                    Clear
                </ModalButton>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isVisible: isShowing.clearQueryResultsModal,
    };
};

const ConnectedTargetSurfacerClearQueryResultsModal = connect(mapStateToProps, {
    clearQueryResults,
    toggleOverlay: toggleOverlay,
})(UnconnectedTargetSurfacerClearQueryResultsModal);

export default ConnectedTargetSurfacerClearQueryResultsModal;
