import { withRouter } from "react-router-dom";

import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import AllReports from "pages/AllReports";

export const IntelligenceReportsAllReportsPathname = "/intrpt/all_reports";

const IntelligenceReportsAllReports = () => {
    const AuthenticatedAllReportsPage = withRouter(withAuthenticationCheck(AllReports));
    return <AuthenticatedAllReportsPage />;
};

export default IntelligenceReportsAllReports;
