import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";

import { nautilusGetRequest, nautilusPatchRequest, nautilusPostRequest } from "network";
import { opportunityKeys } from "reactQuery/keys";

import { PatchedOpportunityPartialUpdate, OpportunityRead } from "./apiTypes";

interface PatchOpportunityQueryPayloadType {
    opportunityId: number;
    opportunityPayload: PatchedOpportunityPartialUpdate;
    teamId: number;
}

interface PostOpportunityQueryPayloadType {
    name: string;
    ownerId: number;
    teamId: number;
}

async function getOpportunity(
    opportunityId: number,
    feedType: string,
    teamId: number,
    indicatorGroupId: number
): Promise<OpportunityRead> {
    const queryString =
        `?feed_type=${feedType}` +
        (teamId ? `&team_id=${teamId}` : "") +
        (indicatorGroupId ? `&indicator_group_id=${indicatorGroupId}` : "");
    const { data } = await nautilusGetRequest(
        `/api/target_reports/report_opportunities/${opportunityId}/`,
        queryString
    );
    return data;
}

async function patchOpportunity(
    opportunityPayload: PatchedOpportunityPartialUpdate,
    opportunityId: number,
    teamId: number
): Promise<OpportunityRead> {
    const queryString = teamId ? `/?team_id=${teamId}` : "/";
    const { data } = await nautilusPatchRequest(
        `/api/target_reports/report_opportunities/${opportunityId}${queryString}`,
        opportunityPayload,
        false
    );
    return data;
}

async function postOpportunity(
    name: string,
    ownerId: number,
    teamId: number
): Promise<OpportunityRead> {
    const { data } = await nautilusPostRequest(`/api/target_reports/opportunities/`, {
        owner: ownerId,
        name,
        team: teamId,
    });
    return data;
}

export function useGetOpportunity({
    opportunityId,
    feedType,
    teamId,
    indicatorGroupId,
    enabled = true,
}: {
    opportunityId: number;
    feedType?: string;
    teamId: number;
    indicatorGroupId?: number;
    enabled?: boolean;
}) {
    const queryKey = opportunityKeys.single_opportunity(opportunityId);
    return useQuery({
        queryKey: queryKey,
        queryFn: (): Promise<OpportunityRead> =>
            getOpportunity(opportunityId, feedType, teamId, indicatorGroupId),

        enabled: enabled && !!opportunityId,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
}

export function usePatchOpportunity(): UseMutationResult<OpportunityRead> {
    const queryClient = useQueryClient();
    return useMutation(
        (opportunityPatchQueryPayload: PatchOpportunityQueryPayloadType) => {
            return patchOpportunity(
                opportunityPatchQueryPayload.opportunityPayload,
                opportunityPatchQueryPayload.opportunityId,
                opportunityPatchQueryPayload.teamId
            );
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(
                    opportunityKeys.single_opportunity(data.id)
                );
            },
        }
    );
}

export function useCreateOpportunity(): UseMutationResult<OpportunityRead> {
    return useMutation(
        (opportunityPostQueryPayload: PostOpportunityQueryPayloadType) => {
            return postOpportunity(
                opportunityPostQueryPayload.name,
                opportunityPostQueryPayload.ownerId,
                opportunityPostQueryPayload.teamId
            );
        }
    );
}
