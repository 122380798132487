import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { OwnerTypeKeys } from "reactQuery/keys";

import { OwnerType } from "./apiTypes";

export const useGetOwnerTypes = (
    enabled: boolean = true
): UseQueryResult<OwnerType[]> => {
    const queryKey = OwnerTypeKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<OwnerType[]> =>
            (await nautilusGetRequest("/api/universe/owner_types/")).data,
        enabled,
    });
};
