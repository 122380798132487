export const FILTER_TYPES = {
    DEFAULT: "DEFAULT",
    DISABLED: "DISABLED",
    EXPLICIT_LOAD: "EXPLICIT_LOAD",
    MODAL: "MODAL",
    STAFF_EXPLICIT_LOAD: "STAFF_EXPLICIT_LOAD",
} as const;

export const FILTER_TEMPLATES = {
    IPL_RESULTS: "IPL_RESULTS",
} as const;

export type filterTypesType = keyof typeof FILTER_TYPES;
