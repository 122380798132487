import { Modal, Button, Typography, Select, Form, Space } from "antd";
import { connect } from "react-redux";

import { DeepInsight } from "reactQuery/hooks/apiTypes";
import { setInsightForCompanyRemoval } from "stores/companies/actions";
import { setInsightForCompanyLinkage } from "stores/companies/companySlice";
import { setInsightForLocationLinkage } from "stores/demographics/demographicSlice";
import { setInsightForDrinkingWaterSystemLinkage } from "stores/drinkingWaterSystems/actions";
import {
    insightForModificationSelector,
    setInsightForModification,
    setInsightForEdit,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { setInsightForWasteWaterSystemLinkage } from "stores/wastewaterFacilities/actions";
import { useModalStore, ModalNames } from "stores/zustandStore";
import { isIntelligenceFeedsRoute } from "utils/redirect";

const { Option } = Select;

interface IntelligenceOption {
    readonly label: string;
    readonly value: string;
}

export const intelligenceOptionsForIntelligenceFeeds: Array<IntelligenceOption> = [
    {
        label: "Drinking Water System",
        value: overlayNames.addDrinkingWaterSystemModal,
    },
    {
        label: "Location",
        value: overlayNames.addLocationModal,
    },
    {
        label: "Facility",
        value: overlayNames.addWasteWaterFacilityModal,
    },
    {
        label: "Indicator",
        value: overlayNames.addIndicatorModal,
    },
    {
        label: "Tags",
        value: overlayNames.teamCustomerTagsModal,
    },
];

export const intelligenceOptions: Array<IntelligenceOption> = [
    {
        label: "Consultant",
        value: overlayNames.consultantModal,
    },
    {
        label: "Competitor Presence",
        value: overlayNames.competitorModal,
    },
    {
        label: "Drinking Water System",
        value: overlayNames.addDrinkingWaterSystemModal,
    },
    {
        label: "Intelligence notes",
        value: overlayNames.addIntelligenceNotesModal,
    },
    {
        label: "Location",
        value: overlayNames.addLocationModal,
    },
    {
        label: "Facility",
        value: overlayNames.addWasteWaterFacilityModal,
    },
    {
        label: "Indicator",
        value: overlayNames.addIndicatorModal,
    },
    {
        label: "Tags",
        value: overlayNames.teamCustomerTagsModal,
    },
];

export interface EnhancedAddIntelligenceModalProps {
    setInsightForCompanyLinkage: any;
    setInsightForCompanyRemoval: any;
    setInsightForDrinkingWaterSystemLinkage: any;
    setInsightForLocationLinkage: any;
    setInsightForWasteWaterSystemLinkage: any;
    setInsightForEdit: any;
    toggleOverlay: any;
    insight: DeepInsight;
}

export const EnhancedAddIntelligenceModal = ({
    setInsightForCompanyLinkage: setInsightForCompanyLinkage,
    setInsightForCompanyRemoval: setInsightForCompanyRemoval,
    setInsightForDrinkingWaterSystemLinkage,
    setInsightForLocationLinkage,
    setInsightForWasteWaterSystemLinkage,
    setInsightForEdit,
    toggleOverlay,
    insight,
}: EnhancedAddIntelligenceModalProps) => {
    const [form] = Form.useForm();

    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const openModal = useModalStore((state) => state.openModal);

    const formId = `add-intelligence-modal`;

    const handleSubmit = (intelligence: string) => {
        setModalHidden();

        if (intelligence === overlayNames.addIntelligenceNotesModal) {
            setModalVisible(ModalNames.AddIntelligenceNotes, {
                insight: insight,
            });
        } else {
            toggleOverlay({ overlay: intelligence });
        }

        if (
            intelligence === overlayNames.consultantModal ||
            intelligence === overlayNames.competitorModal
        ) {
            setInsightForCompanyLinkage({ insight });
            setInsightForCompanyRemoval({
                insight,
                company: null,
            });
        } else if (intelligence === overlayNames.addDrinkingWaterSystemModal) {
            setInsightForDrinkingWaterSystemLinkage({ insight });
        } else if (intelligence === overlayNames.addLocationModal) {
            setInsightForLocationLinkage({ insight });
        } else if (intelligence === overlayNames.addWasteWaterFacilityModal) {
            setInsightForWasteWaterSystemLinkage({ insight });
        } else if (intelligence === overlayNames.addIndicatorModal) {
            setInsightForEdit({ insightId: insight.id });
        } else if (intelligence === overlayNames.teamCustomerTagsModal) {
            setInsightForEdit({ insightId: insight.id });
        }
    };

    const handleCancel = () => {
        setModalHidden();
    };

    return (
        <Modal
            open={openModal === ModalNames.AddIntelligence}
            onCancel={handleCancel}
            forceRender
            centered
            destroyOnClose={true}
            footer={
                <Button form={formId} key="submit" htmlType="submit" type="primary">
                    Submit
                </Button>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="horizontal"
                style={{ width: "80%", margin: "auto" }}
                onFinish={({ intelligence }) => {
                    return handleSubmit(intelligence);
                }}
            >
                <>
                    <div style={{ textAlign: "center", marginBottom: "25px" }}>
                        <Space direction="vertical">
                            <Typography.Title level={3} style={{ margin: 0 }}>
                                Add Intelligence
                            </Typography.Title>
                        </Space>
                    </div>

                    <Typography style={{ marginBottom: "20px" }}>
                        Please select what intelligence you would like to add to this
                        insight
                    </Typography>

                    <Form.Item
                        name="intelligence"
                        label="Intelligence Option"
                        rules={[
                            {
                                required: true,
                                message: "Please select an intelligence option",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select an intelligence option"
                            style={{ width: "100%" }}
                        >
                            {isIntelligenceFeedsRoute()
                                ? intelligenceOptionsForIntelligenceFeeds.map(
                                      (option) => (
                                          <Option
                                              key={option.value}
                                              value={option.value}
                                          >
                                              {option.label}
                                          </Option>
                                      )
                                  )
                                : intelligenceOptions.map((option) => (
                                      <Option key={option.value} value={option.value}>
                                          {option.label}
                                      </Option>
                                  ))}
                        </Select>
                    </Form.Item>
                </>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        insight: insightForModificationSelector(state),
    };
};

const ConnectedAddIntelligenceModal = connect(mapStateToProps, {
    setInsightForCompanyLinkage: setInsightForCompanyLinkage,
    setInsightForCompanyRemoval: setInsightForCompanyRemoval,
    setInsightForDrinkingWaterSystemLinkage,
    setInsightForLocationLinkage,
    setInsightForWasteWaterSystemLinkage,
    setInsightForModification,
    setInsightForEdit,
    toggleOverlay,
})(EnhancedAddIntelligenceModal as any);

export default ConnectedAddIntelligenceModal;
