import { Tooltip } from "antd";
import { ReactNode } from "react";

const ConditionalTooltip: any = ({
    showTooltip,
    title,
    children,
}: {
    showTooltip: boolean;
    title: ReactNode;
    children: ReactNode;
}) =>
    showTooltip ? (
        <Tooltip placement="right" title={title}>
            {children}
        </Tooltip>
    ) : (
        children
    );

export default ConditionalTooltip;
