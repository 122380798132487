import { useLocation } from "react-router-dom";

import DashboardView from "components/organisms/DashboardView";
import SidebarLayout from "components/organisms/SidebarLayout";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";

export const IntelligenceReportsDashboardPathname = "/intrpt/dashboard";

const IntelligenceReportsDashboard = () => {
    const { search } = useLocation();

    const teamId = Number(new URLSearchParams(search).get("team_id"));

    return (
        <SidebarLayout
            content={
                <DashboardView
                    style={{ padding: "10px" }}
                    teamId={teamId}
                    productTypes={[
                        ProductTypeEnum.INTELLIGENCE_REPORTS,
                        ProductTypeEnum.INTELLIGENCE_REPORTS_CUSTOM_DASHBOARD,
                    ]}
                />
            }
        />
    );
};

export default IntelligenceReportsDashboard;
