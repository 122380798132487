import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import StyledButton from "components/atoms/StyledButton";
import CreateReportModal from "components/cdv/CreateReportModal";
import OverviewCard from "components/cdv/OverviewCard";
import SidebarLayout from "components/organisms/SidebarLayout";
import { additionalColors } from "constants/colors";
import { ShallowReportRead } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { PriorityInsightsPathname } from "routes/IntelligenceFeeds";
import {
    fetchReportsForTeam,
    setActiveTeam,
    reportsForActiveTeamSelector,
} from "stores/reports/reportSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { fetchTeamsData } from "stores/userStore/actionTypes";
import { userDataSelector } from "stores/userStore/selectors";
import { redirectIfTeamHasIntelligenceFeedsProduct } from "utils/redirect";

const AllReportsStyles = makeStyles(() => ({
    page: {
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: "25px",
    },
    createReportBtn: {
        marginLeft: "auto",
        marginRight: 0,
    },
    title: {
        color: additionalColors.body,
        fontSize: "24px",
        marginBottom: "20px",
    },
    fullWidth: {
        width: "100%",
    },
}));

export const UnconnectedAllReportsPage = ({
    fetchTeamsData,
    setActiveTeam,
    fetchReportsForTeam,
    toggleOverlay,
    reports,
}) => {
    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user?.is_staff ?? false;

    const { search } = useLocation();

    const teamId = new URLSearchParams(search).get("team_id");
    const teamIdFromUrl = teamId ? parseInt(teamId) : null;

    redirectIfTeamHasIntelligenceFeedsProduct(PriorityInsightsPathname, teamIdFromUrl);

    useEffect(() => {
        setActiveTeam({ teamId: teamIdFromUrl });
        fetchTeamsData();
        fetchReportsForTeam({ teamId: teamIdFromUrl });
    }, []);

    const classes = AllReportsStyles();

    const reportCards =
        reports?.length > 0
            ? reports
                  .sort(
                      (a: ShallowReportRead, b: ShallowReportRead) =>
                          new Date(b.date).valueOf() - new Date(a.date).valueOf()
                  )
                  .map((report: ShallowReportRead, index: number) => {
                      return (
                          <OverviewCard
                              cardType="report"
                              date={report.date}
                              id={report.id}
                              isStaff={isStaff}
                              key={index}
                              status={report.status}
                              teamId={teamIdFromUrl}
                          />
                      );
                  })
            : null;

    const body = (
        <>
            {reports ? (
                <div className={classes.page}>
                    <div>
                        {isStaff ? (
                            <StyledButton
                                className={classes.createReportBtn}
                                handleClick={(e) => {
                                    e.stopPropagation();
                                    toggleOverlay({
                                        overlay: overlayNames.createReportModal,
                                    });
                                }}
                                variant="add-primary"
                                data-testid="create-report-btn"
                            >
                                Create Report
                            </StyledButton>
                        ) : null}
                    </div>
                    <div>
                        {reportCards ? (
                            <>
                                <Typography className={classes.title}>
                                    All Reports
                                </Typography>
                                <Grid
                                    container
                                    spacing={4}
                                    style={{ marginBottom: "50px" }}
                                >
                                    {reportCards}
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                container
                                spacing={4}
                                style={{ marginBottom: "50px" }}
                            >
                                <LinearProgress className={classes.fullWidth} />
                            </Grid>
                        )}
                    </div>
                </div>
            ) : (
                <Typography>Loading reports</Typography>
            )}
        </>
    );

    return (
        <div>
            <CreateReportModal />

            <SidebarLayout content={body} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        reports: reportsForActiveTeamSelector(state as never),
        user: userDataSelector(state),
    };
};

const ConnectedAllReportsPage = connect(mapStateToProps, {
    setActiveTeam,
    fetchReportsForTeam,
    fetchTeamsData,
    toggleOverlay: toggleOverlay,
})(UnconnectedAllReportsPage);

export default ConnectedAllReportsPage;
