import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";

import { nautilusGetRequest, nautilusPatchRequest } from "network";
import { searchPlanFormKeys } from "reactQuery/keys";

import {
    SearchPlanFormIntelligenceScopeDetail,
    SearchPlanFormSearchPlanOverviewRead,
} from "./apiTypes";

export const useGetSearchPlanOverview = (
    searchPlanId: number
): UseQueryResult<SearchPlanFormSearchPlanOverviewRead> => {
    const queryKey = searchPlanFormKeys.searchPlanOverview(searchPlanId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SearchPlanFormSearchPlanOverviewRead> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/search_plan_form/search_plan_overview/${searchPlanId}/`
            );
            return data;
        },
    });
};

export const useGetIntelligenceScopeDetail = (
    intelligenceScopeId: number
): UseQueryResult<SearchPlanFormIntelligenceScopeDetail> => {
    const queryKey = searchPlanFormKeys.intelligenceScopeDetail(intelligenceScopeId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<SearchPlanFormIntelligenceScopeDetail> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/search_plan_form/intelligence_scope_detail/${intelligenceScopeId}/`
            );
            return data;
        },
    });
};

interface IntelligenceScopePatchRequest {
    industry: number;
}

async function patchIntelligenceScope(
    payload: Partial<IntelligenceScopePatchRequest>,
    intelligenceScopeId: number
): Promise<SearchPlanFormIntelligenceScopeDetail> {
    const { data } = await nautilusPatchRequest(
        `/api/target_reports/search_plan_form/intelligence_scope_detail/${intelligenceScopeId}/`,
        { ...payload }
    );
    return data;
}

export function usePatchIntelligenceScope(
    intelligenceScopeId: number
): Partial<UseMutationResult<SearchPlanFormIntelligenceScopeDetail>> {
    return useMutation((payload: IntelligenceScopePatchRequest) => {
        return patchIntelligenceScope(payload, intelligenceScopeId);
    });
}
