import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderConstraints from "../RenderConstraints";

const RenderIndicatorConstraints = (intelligenceScope: IntelligenceScope) => (
    <PDFView
        style={pdfStyle.constraintContainer}
        key={intelligenceScope.id}
        wrap={false}
    >
        <PDFView style={pdfStyle.constraintTitleContainer}>
            <PDFText style={pdfStyle.constraintTitle}>Indicator-Level</PDFText>
        </PDFView>
        {RenderConstraints({
            constraints: intelligenceScope.indicatortag_constraints,
            name: "Indicator Tags",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.initiativetype_constraints,
            name: "Initiative Type",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.timing_status_constraints,
            name: "Timing Status",
        })}
    </PDFView>
);

export default RenderIndicatorConstraints;
