import { LikeFilled, LikeOutlined, LikeTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

import { additionalColors, brandColors } from "constants/colors";
import { useGetFeedbackUsers } from "reactQuery/hooks/useOpportunityFeedbackUsers";

import OpportunityFeedbackUserTooltip from "../../molecules/OpportunityFeedback/OpportunityFeedbackUserTooltip";
import { useStyles } from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    likeButton: (liked: boolean) => `like button ${liked ? "liked" : "not liked"}`,
    likeCount: "like count",
};

const Like = ({
    teamId,
    opportunityId,
    retrieveFeedbackUsers,
    setRetrieveFeedbackUsers,
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    teamId: number;
    opportunityId: number;
    retrieveFeedbackUsers: boolean;
    setRetrieveFeedbackUsers: (_: boolean) => void;
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const getOpportunityFeedbackUsers = useGetFeedbackUsers({
        opportunityId,
        teamId,
        enabled: retrieveFeedbackUsers,
    });
    const styles = useStyles();

    const IconComponent = currentFeedback?.is_liked
        ? LikeFilled
        : currentFeedback?.total_likes > 0
        ? LikeTwoTone
        : LikeOutlined;

    return (
        <>
            <Tooltip
                title={
                    <OpportunityFeedbackUserTooltip
                        title="Like"
                        users={getOpportunityFeedbackUsers.data?.like}
                    />
                }
                onOpenChange={(open) =>
                    open && !retrieveFeedbackUsers && setRetrieveFeedbackUsers(true)
                }
            >
                <IconComponent
                    className={[
                        styles.feedbackIconFontSize,
                        allowFeedback
                            ? styles.feedbackIconEnabledStyle
                            : styles.feedbackIconDisabledStyle,
                    ].join(" ")}
                    onClick={(event) => handleFeedbackClicked(event, "like")}
                    style={
                        currentFeedback?.is_liked
                            ? { color: brandColors.green }
                            : { color: additionalColors.grey600 }
                    }
                    data-testid={testIds.likeButton(
                        currentFeedback?.is_liked ? true : false
                    )}
                    twoToneColor={
                        currentFeedback?.total_likes > 0 ? brandColors.green : undefined
                    }
                />
            </Tooltip>
            <span data-testid={testIds.likeCount}>{currentFeedback?.total_likes}</span>
        </>
    );
};

export default Like;
