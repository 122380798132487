import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import StyledButton from "components/atoms/StyledButton";
import { additionalColors } from "constants/colors";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { setSalesStageForEdit } from "stores/opportunities/actions";
import { opportunitySalesStageSelectorList } from "stores/opportunities/selectors";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isEditModeActiveSelector } from "stores/uiStore/selectors";

import SalesStageTimelineModule from "../SalesStageTimelineModule";

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

const rowStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1.5, 0),
        borderBottom: `1px ${theme.palette.grey[300]} solid`,
    },
    label: {
        color: theme.palette.grey[500],
        fontSize: "14px",
    },
    labelDate: {
        fontSize: "16px",
        textAlign: "center",
    },
    value: {
        textAlign: "left",
        fontSize: "17px",
    },
    mainText: {
        textSize: "23px",
        fontWeight: "900",
        textAlign: "left",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    editButton: {
        border: "2px solid",
        color: additionalColors.grey999,
    },
    editIcon: {
        color: theme.palette.grey[400],
        height: 16,
        width: 16,
    },
}));

const cardStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.primary.main,
        marginTop: -12,
    },
    card: {
        marginBottom: 32,
        width: "100%",
    },
    cardContent: {
        padding: theme.spacing(2),
        paddingBottom: 0,
        maxHeight: "200px",
        overflow: "auto",
    },
    cardHeader: {
        display: "flex",
        borderBottom: `1px ${theme.palette.grey[300]} solid`,
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2, 1, 2),
    },
    name: {
        flexGrow: 1,
    },
}));

const StageCardRow = ({ label, value }) => {
    const styles = rowStyles();
    return value ? (
        <Grid container direction="row" className={styles.container}>
            <Grid item xs={2}>
                <Typography className={styles.label}>{label}</Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography className={styles.value}>{value}</Typography>
            </Grid>
        </Grid>
    ) : null;
};

const SalesStageItemRow = ({
    date,
    sales_stage_label,
    notes,
    user_name,
    time_ago,
    setSalesStageForEdit,
    sales_stage,
    toggleOverlay,
}) => {
    const styles = rowStyles();
    return date ? (
        <>
            <Grid
                container
                direction="row"
                className={styles.container}
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={3}>
                    <Typography className={styles.labelDate}>{date}</Typography>
                    <Box style={{ padding: "10px" }}>
                        <StyledButton
                            className={styles.editButton}
                            onClick={() => {
                                setSalesStageForEdit({
                                    sales_stage,
                                });
                                toggleOverlay({
                                    overlay: overlayNames.editSalesStageModal,
                                });
                            }}
                        >
                            Edit
                        </StyledButton>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Typography className={styles.mainText}>
                        {sales_stage_label}
                    </Typography>
                    <Typography className={styles.value}>
                        {notes ? notes : null}
                    </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={5}>
                    <Typography className={styles.label}>{user_name} </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ textAlign: "right" }} className={styles.label}>
                        {time_ago}
                    </Typography>
                </Grid>
            </Grid>
        </>
    ) : null;
};

const SalesStage = ({
    salesStages,
    isEditModeActive,
    toggleOverlay,
    setSalesStageForEdit,
}) => {
    const styles = cardStyles();

    const timeAgo = new TimeAgo("en-US");

    const addSalesStageButton = (
        <StyledButton
            handleClick={() => {
                toggleOverlay({ overlay: overlayNames.addSalesStageModal });
            }}
            variant="add-secondary"
        >
            Update
        </StyledButton>
    );

    return (
        <>
            <CardContent className={styles.cardContent}>
                <StageCardRow label="Sales Stage" value={addSalesStageButton} />

                <SalesStageTimelineModule
                    style={{ paddingBottom: 0 }}
                    current_stage={
                        salesStages &&
                        salesStages[0] &&
                        salesStages[0].sales_stage &&
                        salesStages[0].sales_stage.stage
                            ? salesStages[0].sales_stage.stage
                            : "Prospecting"
                    }
                ></SalesStageTimelineModule>
            </CardContent>

            <CardContent className={styles.cardContent}>
                {salesStages && salesStages.length > 0
                    ? salesStages.map((sales_stage) => (
                          <SalesStageItemRow
                              date={formatDayHyphenShortenedMonthHyphenYear(
                                  sales_stage.stage_date
                              )}
                              key={sales_stage.id}
                              sales_stage_label={sales_stage.sales_stage.label}
                              notes={
                                  sales_stage &&
                                  sales_stage.notes &&
                                  sales_stage.notes.notes
                                      ? sales_stage.notes.notes
                                      : ""
                              }
                              user_name={
                                  sales_stage.author?.user?.first_name +
                                  " " +
                                  sales_stage.author?.user?.last_name
                              }
                              time_ago={timeAgo.format(
                                  new Date(
                                      moment(sales_stage.last_updated).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                      )
                                  )
                              )}
                              sales_stage={sales_stage}
                              setSalesStageForEdit={setSalesStageForEdit}
                              toggleOverlay={toggleOverlay}
                          />
                      ))
                    : null}
            </CardContent>
        </>
    );
};

const panelStyle = makeStyles((theme) => ({
    button: {
        marginTop: -32,
    },
    content: {
        marginTop: 24,
    },
    header: {
        color: additionalColors.body54,
    },
    text: {
        margin: 32,
    },
}));

export const UnconnectedSalesStageCard = ({
    salesStages,
    isLoading,
    isEditModeActive,
    toggleOverlay,
    setSalesStageForEdit,
}) => {
    const styles = panelStyle();
    const cardSty = cardStyles();

    const content = (
        <SalesStage
            salesStages={salesStages}
            isEditModeActive={isEditModeActive}
            toggleOverlay={toggleOverlay}
            setSalesStageForEdit={setSalesStageForEdit}
        />
    );

    return (
        <>
            <Typography className={styles.header}>ACTIVITY</Typography>
            <div className={styles.content}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <Card className={cardSty.card}>{content}</Card>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    salesStages: opportunitySalesStageSelectorList(state),
    isLoading: false,
    isEditModeActive: isEditModeActiveSelector(state),
});
const ConnectedSalesStageCard = connect(mapStateToProps, {
    toggleOverlay,
    setSalesStageForEdit,
})(UnconnectedSalesStageCard);

export default ConnectedSalesStageCard;
