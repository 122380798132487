type StringRecord = Record<string, string>;

const ensureURLProtocol = (url) => {
    if (!/^(?:http|https):\/\//i.test(url)) {
        // Prepend "https://" if no protocol is found
        return `https://${url}`;
    }
    return url;
};

export const createLink = ({
    url,
    query_param_obj,
    hash,
}: {
    url: string;
    query_param_obj?: StringRecord;
    hash?: string;
}) => {
    let linkURL;
    try {
        linkURL = new URL(ensureURLProtocol(url));
    } catch {
        return "";
    }
    if (query_param_obj) {
        for (const [k, v] of Object.entries(query_param_obj)) {
            linkURL.searchParams.set(k, v);
        }
    }
    if (hash) {
        linkURL.hash = hash;
    }
    return linkURL.href;
};
