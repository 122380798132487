import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import IntelligenceReportsAllInsights, {
    IntelligenceReportsAllInsightsPathname,
} from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import IntelligenceReportsAllReports, {
    IntelligenceReportsAllReportsPathname,
} from "pages/IntelligenceReports/IntelligenceReportsAllReports";
import IntelligenceReportsDashboard, {
    IntelligenceReportsDashboardPathname,
} from "pages/IntelligenceReports/IntelligenceReportsDashboard";
import IntelligenceReportsSingleOpportunity, {
    IntelligenceReportsSingleOpportunityPathname,
} from "pages/IntelligenceReports/IntelligenceReportsSingleOpportunity";
import IntelligenceReportsSingleReport, {
    IntelligenceReportsSingleReportPathname,
} from "pages/IntelligenceReports/IntelligenceReportsSingleReport";

const IntelligenceReportsRoutes = () => {
    return (
        <Switch>
            <Route exact path={IntelligenceReportsAllReportsPathname}>
                <IntelligenceReportsAllReports />
            </Route>
            <Route exact path={IntelligenceReportsAllInsightsPathname}>
                <IntelligenceReportsAllInsights />
            </Route>
            <Route exact path={IntelligenceReportsDashboardPathname}>
                <IntelligenceReportsDashboard />
            </Route>
            <Route exact path={IntelligenceReportsSingleReportPathname}>
                <IntelligenceReportsSingleReport />
            </Route>
            <Route exact path={IntelligenceReportsSingleOpportunityPathname}>
                <IntelligenceReportsSingleOpportunity />
            </Route>

            <Route exact path={"/intrpt"}>
                <p>Intelligence Reports Landing Page</p>
            </Route>

            <Route path="*">
                <Redirect to={"/intrpt"} />
            </Route>
        </Switch>
    );
};

export default IntelligenceReportsRoutes;
