import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

import { nautilusPostRequest, nautilusGetRequest } from "network";
import {
    iplResultDetailsAISummaryKeys,
    tableDataKeys,
    viewDetailsKeys,
} from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

async function postIPLResult(requestData) {
    const { data } = await nautilusPostRequest(
        `/api/target_reports/ipl_results/`,
        requestData
    );
    return data;
}

async function updateIPLResults({
    assignee = null,
    assigneeObj = null,
    iplResultIds,
    feedType,
    queryParams,
    queryClient,
    review_status = null,
    status = null,
}) {
    const tableQueryKey = tableDataKeys.createKey(feedType, queryParams);
    const detailsQueryKey = viewDetailsKeys.all();

    await queryClient.cancelQueries(tableQueryKey);

    const paginatedIPLResults = queryClient.getQueryData(tableQueryKey);
    const currentPaginatedIPLResults = paginatedIPLResults as any;

    const currentIPLResults = currentPaginatedIPLResults.results;
    const currentDetailsData = queryClient.getQueriesData(detailsQueryKey) as any;

    const newIPLResults = currentIPLResults.map((iplResult) =>
        iplResultIds.includes(iplResult.id)
            ? {
                  ...iplResult,
                  assignee: assignee || assigneeObj?.title || iplResult.assignee,
                  status: status || iplResult.status,
                  review_status: review_status || iplResult.review_status,
              }
            : iplResult
    );

    // Optimisitcally update table data
    const newPaginatedIPLResults = {
        ...currentPaginatedIPLResults,
        results: newIPLResults,
    };
    queryClient.setQueryData(tableQueryKey, newPaginatedIPLResults);

    // Optimistically update view details data
    if (currentDetailsData) {
        currentDetailsData.map((currentDetailsDataItem) => {
            const queryKey = currentDetailsDataItem[0];
            const detailsItem = currentDetailsDataItem[1];
            if (iplResultIds.includes(detailsItem?.id)) {
                queryClient.setQueryData(queryKey, {
                    ...detailsItem,
                    assignee: assignee || assigneeObj?.title || detailsItem.assignee,
                    status: status || detailsItem.status,
                    review_status: review_status || detailsItem.review_status,
                });
            }
        });
    }

    return {
        currentPaginatedIPLResults,
        currentDetailsData,
    };
}

async function resetIPLResults({ queryClient, feedType, queryParams, context }) {
    // Revert table data
    queryClient.setQueryData(
        tableDataKeys.createKey(feedType, queryParams),
        context.currentPaginatedIPLResults
    );

    // Revert view details data
    if (context.currentDetailsData) {
        context.currentDetailsData.map((currentDetailsDataItem) => {
            const queryKey = currentDetailsDataItem[0];
            const detailsItem = currentDetailsDataItem[1];
            queryClient.setQueryData(queryKey, detailsItem);
        });
    }
}

export function useUpdateIPLResults({
    feedType,
    defaultPageSize,
}: {
    feedType: string;
    defaultPageSize: number;
}) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
        defaultPageSize,
    });
    const queryClient = useQueryClient();

    return useMutation(
        (requestData) => {
            return postIPLResult(requestData);
        },
        {
            onMutate: async (requestData: any) => {
                let iplResultIds;
                if (["review_status", "status"].includes(requestData.field)) {
                    iplResultIds = [requestData.id];
                } else {
                    iplResultIds = requestData.ids;
                }
                const { currentPaginatedIPLResults, currentDetailsData } =
                    await updateIPLResults({
                        assigneeObj: requestData.assigneeObj,
                        iplResultIds,
                        feedType,
                        queryParams,
                        queryClient,
                        review_status: requestData.review_status,
                        status: requestData.status,
                    });

                return {
                    currentPaginatedIPLResults,
                    iplResultIds,
                    currentDetailsData,
                };
            },
            onError: (_err, _, context) => {
                resetIPLResults({ queryClient, feedType, queryParams, context });
            },
            onSettled: async (response, _, requestData, context) => {
                if (response.ipl_result) {
                    const iplResultIds = [response.ipl_result.id];
                    await updateIPLResults({
                        iplResultIds,
                        feedType,
                        queryParams,
                        queryClient,
                        review_status: response.ipl_result.review_status,
                        status: response.ipl_result.status,
                    });
                } else if (response.failed_ipl_result_ids?.length) {
                    await resetIPLResults({
                        queryClient,
                        feedType,
                        queryParams,
                        context,
                    });
                    const assignedResultIds = requestData.ids.filter(
                        (id) => !response.failed_ipl_result_ids.includes(id)
                    );
                    await updateIPLResults({
                        assigneeObj: requestData.assigneeObj,
                        iplResultIds: assignedResultIds,
                        feedType,
                        queryParams,
                        queryClient,
                    });
                }
            },
        }
    );
}

export const useGetIPLResultDetailsAISummary = (iplResultId) => {
    const queryKey = iplResultDetailsAISummaryKeys.single(iplResultId);
    return useQuery({
        queryKey,
        queryFn: async () => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/ipl_result_details_ai_summary/${iplResultId}`
            );
            return data;
        },
    });
};
