import React from "react";
import { connect } from "react-redux";

import { fetchUserData } from "stores/userStore/actionTypes";

export const AuthenticationCheck = class extends React.Component {
    componentDidMount() {
        this.props.fetchUserData();
    }
    render() {
        const ComponentToRender = this.props.componentToRender;
        return <ComponentToRender {...this.props} />;
    }
};

const withAuthenticationCheck = (WrappedComponent) => {
    const mapStateToProps = () => {
        return {
            componentToRender: WrappedComponent,
        };
    };
    return connect(mapStateToProps, {
        fetchUserData,
    })(AuthenticationCheck);
};

export default withAuthenticationCheck;
