import { useLocation } from "react-router-dom";

import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { useGetUserNameById } from "reactQuery/hooks/useUsers";
import { AssignedInsightsSingleAssigneePathname } from "routes/IntelligenceFeeds";
import { redirectIfUserHasNoAccessToAssignedInsights } from "utils/redirect";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";
import { assignedInsightsBreadcrumbs } from "../AssignedInsights";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    let userIdString = new URLSearchParams(search).get("user_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;
    const userId = userIdString ? Number(userIdString) : undefined;

    const userNameQuery = useGetUserNameById(userId, !!userId);
    const userNameData = userNameQuery.data || null;
    const userName = userNameData
        ? `${userNameData.first_name} ${userNameData.last_name}`
        : undefined;

    const queryParams = queryParamBuilder({
        excludeParams: [
            "feedback",
            "indicator_id",
            "opportunity_id",
            "owner_id",
            "user_id",
        ],
        includeBaseParams: true,
        includeExistingParams: false,
        startChar: "&",
    });

    return { teamId, userId, queryParams, userName };
};

export const singleAssigneeBreadcrumbs = () => {
    const { userId, queryParams, userName } = pageParams();

    const breadcrumbs = assignedInsightsBreadcrumbs().concat({
        name: userName,
        url: `${AssignedInsightsSingleAssigneePathname}?user_id=${userId}${queryParams}`,
    });

    return breadcrumbs;
};

const SingleAssignee = () => {
    const { teamId, userName } = pageParams();
    redirectIfUserHasNoAccessToAssignedInsights("/");

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title={`Insights Assigned to ${userName}`}
                    breadcrumbs={singleAssigneeBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.SINGLE_ASSIGNEE}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default SingleAssignee;
