import { LinearProgress } from "@material-ui/core";
import { AxiosError } from "axios";
import { Redirect, Route, useLocation } from "react-router-dom";

import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";

export const ProtectedRoute = ({ children, ...props }) => {
    const whoamiQuery = useGetWhoami();
    const { pathname, search } = useLocation();

    // if the whoami request returns unauthorized (not logged in),
    // redirect to login page with next parameter set for user to continue path after login
    if (
        whoamiQuery.isError &&
        (whoamiQuery.error as AxiosError).request.status === 401
    ) {
        return <Redirect to={`/login?next=${pathname}${search}`} replace />;
    } else if (whoamiQuery.isSuccess) {
        return <Route {...props}>{children}</Route>;
    } else {
        return <LinearProgress />;
    }
};
