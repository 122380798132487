import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import MarkdownTextField from "components/atoms/MarkdownTextField";
import { FormModal } from "components/molecules/Modal";
import {
    createSource,
    isCreatingSourceSelector,
    newSourceSelector,
} from "stores/sources/sourceSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const ERROR_MESSAGES = {
    urlMissing: "A url is required",
    urlInvalid: "Please enter a valid website URL",
    titleMissing: "A title is required",
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
};

export const CreateSourceSchema = Yup.object().shape({
    sourceUrl: Yup.string()
        .required(ERROR_MESSAGES.urlMissing)
        .test("url-valid", "Please enter a valid website URL", (value) => {
            if (value) {
                if (value && !value.includes("%") && value.includes(" ")) {
                    return true;
                } else return !(value.includes("%") && value.includes(" "));
            }
        }),
    sourceTitle: Yup.string().required(ERROR_MESSAGES.titleMissing),
    sourceDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
});

const formStyle = makeStyles(() => ({
    formControl: {
        width: "100%",
        marginBottom: "20px",
    },
    textInput: {
        width: "100%",
    },
}));

export const CreateSourceForm = ({
    errors,
    setFieldValue,
    handleChange,
    values: { sourceUrl, sourceTitle, triggerExtract, sourceDate },
}) => {
    const classes = formStyle();

    return (
        <Form>
            <FormControl error={"sourceUrl" in errors} className={classes.formControl}>
                <TextField
                    className={classes.textInput}
                    id="source-url"
                    label="Source URL"
                    name="sourceUrl"
                    onChange={handleChange}
                    value={sourceUrl}
                    variant="outlined"
                    error={"sourceUrl" in errors}
                    helperText={errors.sourceUrl || null}
                />
            </FormControl>
            <FormControl
                error={"sourceTitle" in errors}
                className={classes.formControl}
            >
                <TextField
                    className={classes.textInput}
                    id="source-title"
                    label="Source Title"
                    name="sourceTitle"
                    onChange={handleChange}
                    value={sourceTitle}
                    variant="outlined"
                    error={"sourceTitle" in errors}
                    helperText={errors.sourceTitle || null}
                />
            </FormControl>
            <MarkdownTextField
                //errors={errors}
                id="trigger-extract"
                name="triggerExtract"
                onChange={handleChange}
                label="Trigger Extract"
                value={triggerExtract}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"sourceDate" in errors}
                    className={classes.formControl}
                >
                    <InputLabel shrink>Source date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="date-picker-inline"
                        inputProps={{ "data-testid": "test-date-picker-unique" }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="sourceDate"
                        onChange={(e) => setFieldValue("sourceDate", e)}
                        value={sourceDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid="test-source-date-error">
                        {errors.sourceDate ? ERROR_MESSAGES.dateMissing : null}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
        </Form>
    );
};

export const UnconnectedCreateSourceModal = ({
    isLoading,
    isVisible,
    toggleOverlay,
    ...formikProps
}) => (
    <FormModal
        handleClose={() => {
            toggleOverlay({ overlay: overlayNames.createSourceModal });
            formikProps.handleReset();
        }}
        handleSubmit={() => {
            if (formikProps.handleSubmit()) {
                toggleOverlay({ overlay: overlayNames.createSourceModal });
            }
        }}
        isSubmitDisabled={isLoading}
        isSubmitLoading={isLoading}
        isVisible={isVisible}
        submitLabel="Submit"
        title="Add Source"
    >
        <CreateSourceForm {...formikProps} />
    </FormModal>
);

export const EnhancedCreateSourceModal = withFormik({
    enableReinitialize: false,
    handleSubmit: (
        { sourceUrl, sourceTitle, triggerExtract, sourceDate },
        { resetForm, props: { createSource, newSource } }
    ) => {
        const date = moment(sourceDate).format("YYYY-MM-DD");
        /* Not quite sure why, but placing resetForm() here is OK since it
           doesn't affect createSource and placing it after doesn't reset the form.
        */
        resetForm();
        createSource({
            insightId: newSource.insightId,
            sourceUrl:
                sourceUrl.includes(" ") && !sourceUrl.includes("%")
                    ? encodeURI(sourceUrl)
                    : sourceUrl,
            sourceTitle: sourceTitle,
            triggerExtract: triggerExtract || "",
            sourceDate: date,
        });
        return true;
    },
    mapPropsToValues: () => ({
        sourceUrl: "",
        sourceTitle: "",
        triggerExtract: "",
        sourceDate: new Date(),
    }),
    validationSchema: () => CreateSourceSchema,
    validateOnChange: false,
    validateOnBlur: false,
})(UnconnectedCreateSourceModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        newSource: newSourceSelector(state),
        isLoading: isCreatingSourceSelector(state),
        isVisible: isShowing.createSourceModal,
    };
};

export const ConnectedCreateSourceModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    createSource,
})(EnhancedCreateSourceModal);

export default ConnectedCreateSourceModal;
