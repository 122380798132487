import { useEffect } from "react";
import { Route, Redirect, Switch, useLocation, useHistory } from "react-router-dom";

import DocumentCompareUpload from "pages/DocumentCompare/Upload";
import DocumentCompareViewer from "pages/DocumentCompare/Viewer";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { useGetSingleTeam } from "reactQuery/hooks/pages/useSidebarLayout";

export const DocumentComparePathname = "/document_compare";
export const DocumentCompareUploadPathname = `${DocumentComparePathname}/upload`;
export const DocumentCompareViewerPathname = `${DocumentComparePathname}/viewer/:identifier`;

const DocumentCompareRoutes = () => {
    const { search } = useLocation();

    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const singleTeamQuery = useGetSingleTeam(teamId);
    const { push } = useHistory();

    useEffect(() => {
        if (!singleTeamQuery.isSuccess) return;

        if (
            !singleTeamQuery.data.products
                .map((product) => product.product_type)
                .includes(ProductTypeEnum.DOCUMENT_COMPARE)
        ) {
            push("/product-not-found"); // Redirect to product-not-found page if the team does not have document compare
        }
    }, [singleTeamQuery.isSuccess]);

    return (
        <Switch>
            <Route exact path={DocumentCompareViewerPathname}>
                <DocumentCompareViewer />
            </Route>
            <Route exact path={DocumentCompareUploadPathname}>
                <DocumentCompareUpload />
            </Route>
            <Route path="*">
                <Redirect to={"/"} />
            </Route>
        </Switch>
    );
};

export default DocumentCompareRoutes;
