import { useLocation } from "react-router-dom";

import Text from "components/atoms/Text";
import SubscriptionsSection from "components/cdv/SubscriptionsSection";
import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
export const SettingsPathname = "/settings";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;

    return { teamId };
};

const Settings = ({}) => {
    const { teamId } = pageParams();

    const teamActivitySection = (
        <>
            <Text variant="pageHeader">Team Activity</Text>
            <PaginatedTable feedType={FEED_TYPES.TEAM_ACTIVITY} teamId={teamId} />
        </>
    );

    const settingsPage = (
        <div style={{ paddingLeft: 20, paddingRight: 10, paddingTop: "25px" }}>
            <SubscriptionsSection />
            {teamActivitySection}
        </div>
    );

    return <SidebarLayout content={settingsPage} />;
};

export default Settings;
