import { Link } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

import { Text } from "components/atoms";
import StyledButton from "components/atoms/StyledButton";

const cardStyles = makeStyles(() => ({
    cardActions: {
        justifyContent: "space-between",
        padding: "16px",
        height: "auto",
    },
    cardSubheading: {
        marginTop: "-20px",
    },
}));

const isExternalLink = (link: string) => {
    return link.includes("http");
};

const isMailtoLink = (link: string) => {
    return link.includes("mailto");
};

export const OverviewCard = ({
    title,
    subtitle,
    primaryActionText,
    primaryActionUrl,
    secondaryActionText,
    secondaryActionUrl,
}: {
    title: string;
    subtitle: string;
    primaryActionText: string;
    primaryActionUrl: string;
    secondaryActionText?: string;
    secondaryActionUrl?: string;
}) => {
    const styles = cardStyles();

    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card raised={true}>
                <CardHeader title={title} />
                <CardContent className={styles.cardSubheading}>{subtitle}</CardContent>
                <CardActions className={styles.cardActions}>
                    {secondaryActionUrl ? (
                        <Link
                            component={
                                isExternalLink(secondaryActionUrl) ||
                                isMailtoLink(secondaryActionUrl)
                                    ? "a"
                                    : RouterLink
                            }
                            href={
                                isExternalLink(secondaryActionUrl) ||
                                isMailtoLink(secondaryActionUrl)
                                    ? secondaryActionUrl
                                    : undefined
                            }
                            to={
                                !isExternalLink(secondaryActionUrl) ||
                                !isMailtoLink(secondaryActionUrl)
                                    ? secondaryActionUrl
                                    : undefined
                            }
                            target={
                                isExternalLink(secondaryActionUrl)
                                    ? "_blank"
                                    : undefined
                            }
                        >
                            <Text variant="underline">{secondaryActionText}</Text>
                        </Link>
                    ) : null}
                    {primaryActionUrl ? (
                        <StyledButton
                            variant="add-primary"
                            component={
                                isExternalLink(primaryActionUrl) ? "a" : RouterLink
                            }
                            href={
                                isExternalLink(primaryActionUrl) ||
                                isMailtoLink(primaryActionUrl)
                                    ? primaryActionUrl
                                    : undefined
                            }
                            to={
                                !isExternalLink(primaryActionUrl) ||
                                !isMailtoLink(primaryActionUrl)
                                    ? primaryActionUrl
                                    : undefined
                            }
                            target={
                                isExternalLink(primaryActionUrl) ? "_blank" : undefined
                            }
                        >
                            {primaryActionText}
                        </StyledButton>
                    ) : null}
                </CardActions>
            </Card>
        </Grid>
    );
};
