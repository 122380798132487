import { Tag } from "antd";
import { ReactNode } from "react";
import tinycolor from "tinycolor2";

import { additionalColors } from "../../../constants/colors";
import ConditionalTooltip from "../../atoms/ConditionalTooltip";
import Text from "../../atoms/Text";

export const getMostReadableColor = (color: string) => {
    const mostReadable = tinycolor
        .mostReadable(color, [additionalColors.black, additionalColors.white], {
            includeFallbackColors: true,
            level: "AA",
            size: "small",
        })
        .toHexString();
    return mostReadable;
};

export const lightenByLevel = (color: string, level: number) => {
    return tinycolor(color)
        .brighten(level * 8)
        .lighten(level * 8)
        .toHexString();
};

const FormattedTag = ({
    color,
    level,
    children: title,
    showTooltip,
    searchTextValue,
}: {
    color?: string;
    level?: number;
    children: ReactNode;
    showTooltip?: boolean;
    searchTextValue?: string;
}) => {
    const backgroundColor = color && lightenByLevel(color, level);

    return (
        <ConditionalTooltip showTooltip={showTooltip} title={title}>
            <Tag
                style={{
                    color: backgroundColor && getMostReadableColor(backgroundColor),
                    marginBottom: "10px",
                    verticalAlign: "top",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                color={backgroundColor}
            >
                <Text searchTextValue={searchTextValue}>{title}</Text>
            </Tag>
        </ConditionalTooltip>
    );
};

export default FormattedTag;
