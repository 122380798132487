import { Button, Form, Input, Modal, Select, Space, Typography } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { useSearchDebounced } from "components/helpers/debounce";
import { OpportunityRead } from "reactQuery/hooks/apiTypes";
import { useCreateOpportunity } from "reactQuery/hooks/useOpportunity";
import { useGetOwnersByQuery } from "reactQuery/hooks/useOwners";
import { setOpportunityForInsightCreation } from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { ModalNames, useModalStore, useSnackStore } from "stores/zustandStore";

export const UnconnectedAddOpportunityModal = ({
    setOpportunityForInsightCreation,
    toggleOverlay,
}) => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const openModal = useModalStore((state) => state.openModal);

    const [searchQuery, setSearchQuery] = useState("");
    const debounceSearch = useSearchDebounced(setSearchQuery);

    const createOpportunityQuery = useCreateOpportunity();
    const ownersQuery = useGetOwnersByQuery(searchQuery, searchQuery.length >= 1);

    const { search } = useLocation();
    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? parseInt(teamIdString) : null;

    const [form] = Form.useForm();
    const formId = "add-opportunity-modal-form";

    const onModalCancel = () => {
        setModalHidden();
    };

    const onFinish = async () => {
        createOpportunityQuery.mutate(
            {
                ownerId: form.getFieldValue("owner"),
                name: form.getFieldValue("name"),
                teamId,
            },
            {
                onSuccess: (opportunity: OpportunityRead) => {
                    setModalHidden();
                    displaySuccessSnack({
                        message: "New opportunity created successfully",
                    });

                    setOpportunityForInsightCreation({
                        opportunityId: opportunity.id,
                    });
                    toggleOverlay({ overlay: overlayNames.createInsightModal });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Failed to create new opportunity",
                    });
                },
            }
        );
    };

    return (
        <Modal
            open={openModal === ModalNames.AddOpportunity}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={
                <>
                    <Button key="cancel" type="default" onClick={onModalCancel}>
                        Cancel
                    </Button>
                    <Button
                        loading={createOpportunityQuery.isLoading}
                        disabled={createOpportunityQuery.isLoading}
                        form={formId}
                        key="submit"
                        htmlType="submit"
                        type="primary"
                    >
                        Submit
                    </Button>
                </>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="vertical"
                style={{ width: "80%", margin: "auto" }}
                preserve={false}
                onFinish={onFinish}
            >
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <Space>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            Create Opportunity
                        </Typography.Title>
                    </Space>
                </div>

                <Form.Item
                    name="owner"
                    label="Owner"
                    rules={[
                        {
                            required: true,
                            message: "Please select an owner",
                        },
                    ]}
                >
                    <Select
                        placeholder="Please search for an owner"
                        onSearch={(value) => debounceSearch(value)}
                        showSearch
                        filterOption={false}
                        allowClear={true}
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {!searchQuery ? (
                            <Select.Option value="disabled" disabled>
                                Please enter at least one character
                            </Select.Option>
                        ) : ownersQuery.isSuccess ? (
                            (ownersQuery.data || []).map((owner) => (
                                <Select.Option
                                    key={owner.id}
                                    value={owner.id}
                                    title={owner.id}
                                >
                                    {owner.legal_name} ({owner.state_code})
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter a name for the opportunity",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ConnectedAddOpportunityModal = connect(() => ({}), {
    toggleOverlay: toggleOverlay,
    setOpportunityForInsightCreation: setOpportunityForInsightCreation,
})(UnconnectedAddOpportunityModal);

export default ConnectedAddOpportunityModal;
