import { useLocation, useRouteMatch } from "react-router-dom";

import { IntelligenceReportsAllInsightsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import { IntelligenceReportsAllReportsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllReports";
import { IntelligenceReportsSingleOpportunityPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleOpportunity";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";
import {
    DocumentComparePathname,
    DocumentCompareUploadPathname,
} from "routes/DocumentCompare";
import {
    IndicatorFeedsAllFeedsPathname,
    IndicatorFeedsSingleFeedPathname,
    IndicatorFeedsSingleOpportunityPathname,
} from "routes/IndicatorFeeds";
import {
    AllFeedbackPathname,
    AllFeedbackSingleFeedbackPathname,
    AllFeedbackSingleOpportunityPathname,
    AllIndicatorsPathname,
    AllIndicatorsSingleIndicatorPathname,
    AllIndicatorsSingleOpportunityPathname,
    AllInsightsPathname,
    AllInsightsSingleOpportunityPathname,
    AllOwnersPathname,
    AllOwnersSingleOpportunityPathname,
    AllOwnersSingleOwnerPathname,
    AssignedInsightsPathname,
    AssignedInsightsSingleAssigneePathname,
    AssignedInsightsSingleOpportunityPathname,
    MyInsightsPathname,
    MyInsightsSingleOpportunityPathname,
    PriorityInsightsPathname,
    PriorityInsightsSingleOpportunityPathname,
} from "routes/IntelligenceFeeds";

export const useGetBasePath = () => {
    let { pathname, search } = useLocation();
    let { path: routePath } = useRouteMatch();

    if (pathname === IndicatorFeedsSingleFeedPathname) {
        pathname = IndicatorFeedsAllFeedsPathname;
    } else if (pathname === IntelligenceReportsSingleReportPathname) {
        pathname = IntelligenceReportsAllReportsPathname;
    } else if (pathname === IndicatorFeedsSingleOpportunityPathname) {
        pathname = IndicatorFeedsAllFeedsPathname;
    } else if (pathname === IntelligenceReportsSingleOpportunityPathname) {
        const reportId = Number(new URLSearchParams(search).get("report_id"));
        if (reportId) {
            pathname = IntelligenceReportsAllReportsPathname;
        } else {
            pathname = IntelligenceReportsAllInsightsPathname;
        }
    }

    if (
        routePath === AllOwnersSingleOpportunityPathname ||
        routePath === AllOwnersSingleOwnerPathname
    ) {
        pathname = AllOwnersPathname;
    }
    if (
        routePath === AllIndicatorsSingleOpportunityPathname ||
        routePath === AllIndicatorsSingleIndicatorPathname
    ) {
        pathname = AllIndicatorsPathname;
    }
    if (routePath === MyInsightsSingleOpportunityPathname) {
        pathname = MyInsightsPathname;
    }
    if (
        routePath === AssignedInsightsSingleOpportunityPathname ||
        routePath === AssignedInsightsSingleAssigneePathname
    ) {
        pathname = AssignedInsightsPathname;
    }
    if (
        routePath === AllFeedbackSingleOpportunityPathname ||
        routePath === AllFeedbackSingleFeedbackPathname
    ) {
        pathname = AllFeedbackPathname;
    }
    if (routePath === AllInsightsSingleOpportunityPathname) {
        pathname = AllInsightsPathname;
    }
    if (routePath === PriorityInsightsSingleOpportunityPathname) {
        pathname = PriorityInsightsPathname;
    }
    if (routePath.startsWith(DocumentComparePathname)) {
        pathname = DocumentCompareUploadPathname;
    }

    return pathname;
};
