import { getColorForIndicator } from "./utils";
import { IndicatorGroup } from "../../../reactQuery/hooks/apiTypes";
import { IndicatorFeedItem } from "../../../types/additionalTypes";
import FormattedTag from "../../molecules/FormattedTag";

export interface TagsListProps {
    group?: IndicatorGroup | IndicatorFeedItem;
    showTagsInitiativeTypes?: boolean;
    hasMarginBottom?: boolean;
    maxWidth?: string;
    indicatorType: string;
    tags?: string[];
    searchTextValue?: string;
}

const TagsList = ({
    group,
    showTagsInitiativeTypes = true,
    hasMarginBottom = true,
    maxWidth,
    indicatorType,
    tags,
    searchTextValue,
}: TagsListProps) => {
    const color = group?.indicator && getColorForIndicator(group.indicator);
    const showTooltip = !!maxWidth;

    const style: {
        marginBottom?: string;
        maxWidth?: string;
    } = {};

    if (hasMarginBottom) style.marginBottom = "10px";
    if (maxWidth) style.maxWidth = maxWidth;

    return (
        <div style={style}>
            {indicatorType === "indicatorGroup" ? (
                <>
                    {group.indicator?.industry ? (
                        <FormattedTag
                            searchTextValue={searchTextValue}
                            color={color}
                            level={0}
                            showTooltip={showTooltip}
                        >
                            {group.indicator.industry}
                        </FormattedTag>
                    ) : group.indicator?.category ? (
                        <FormattedTag
                            searchTextValue={searchTextValue}
                            color={color}
                            level={0}
                            showTooltip={showTooltip}
                        >
                            {group.indicator.category}
                        </FormattedTag>
                    ) : null}

                    <FormattedTag
                        searchTextValue={searchTextValue}
                        color={color}
                        level={1}
                        showTooltip={showTooltip}
                    >
                        {group.indicator?.value}
                    </FormattedTag>

                    {showTagsInitiativeTypes ? (
                        <>
                            {group.indicator_tags.map((indicator_tag, index) => {
                                return (
                                    <FormattedTag
                                        searchTextValue={searchTextValue}
                                        key={index}
                                        color={color}
                                        level={2}
                                        showTooltip={showTooltip}
                                    >
                                        {indicator_tag?.value}
                                    </FormattedTag>
                                );
                            })}
                            {group.initiative_types.map((initiative_type, index) => {
                                return (
                                    <FormattedTag
                                        searchTextValue={searchTextValue}
                                        key={index}
                                        color={color}
                                        level={2}
                                        showTooltip={showTooltip}
                                    >
                                        {initiative_type?.value}
                                    </FormattedTag>
                                );
                            })}
                        </>
                    ) : null}
                </>
            ) : indicatorType === "teamCustomerTags" ? (
                <>
                    {tags.map((tag: string, index: number) => {
                        return (
                            <div key={index}>
                                <FormattedTag
                                    searchTextValue={searchTextValue}
                                    showTooltip={showTooltip}
                                >
                                    {tag}
                                </FormattedTag>
                            </div>
                        );
                    })}
                </>
            ) : null}
        </div>
    );
};

export default TagsList;
