import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";

import { brandColors } from "constants/colors";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: brandColors.darkBlue,
        },
        secondary: {
            main: brandColors.lightBlue,
        },
        success: {
            main: brandColors.green,
        },
        error: {
            main: brandColors.red,
        },
        warning: {
            main: brandColors.orange,
        },
    },
    typography: {
        fontFamily: "Halyard",
    },
});

export const ThemeProviderWrapper = ({ children }: { children: React.ReactNode }) => {
    return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};
