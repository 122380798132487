import { QueryClient } from "@tanstack/react-query";

import { FIVE_MINUTES } from "./constants";

const appQueryClient = () => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: FIVE_MINUTES,
                cacheTime: FIVE_MINUTES,
            },
        },
    });
};

export default appQueryClient;
