import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { nautilusPostRequest } from "network";
import { analyticsEvent } from "network/tracking";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";

import { RestAuthDetail } from "../apiTypes";

const postLogoutResponse = async (): Promise<RestAuthDetail> => {
    return (await nautilusPostRequest(`/api/rest-auth/logout/`)).data;
};

export function useAuthLogout(): UseMutationResult<RestAuthDetail> {
    return useMutation((): Promise<RestAuthDetail> => {
        return postLogoutResponse();
    });
}

export function logOut(
    logOutMutation: UseMutationResult<RestAuthDetail>,
    onComplete: Function
) {
    return logOutMutation.mutate(undefined, {
        onSuccess: () => {
            analyticsEvent({
                action: eventActions.CLICKED,
                category: eventCategories.FORMS,
                label: "Sign out - Sign out",
                executeOnceComplete: () => {
                    // @ts-ignore
                    window.heap && window.heap.resetIdentity();
                    onComplete();
                },
            });
        },
    });
}
