import ReactGA from "react-ga";

const debug = process.env.NODE_ENV === "development";

const trackPageView = (location) => {
    ReactGA.set({
        page: location.pathname,
    });
    ReactGA.pageview(location.pathname);
};

export const analyticsEvent = ({
    action,
    category,
    label,
    value,
    executeOnceComplete,
}: {
    action: string;
    category: string;
    label: string;
    value?: any;
    executeOnceComplete?: Function;
}) => {
    try {
        ReactGA.event({
            action,
            category,
            label,
            value,
        });
    } catch (e) {
    } finally {
        if (executeOnceComplete) {
            executeOnceComplete();
        }
    }
};

const initTracking = (history) => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
        debug,
    });
    if (debug) {
        ReactGA.ga("set", "sendHitTask", null);
    }
    trackPageView(history.location);
    history.listen(trackPageView);
};

export default initTracking;
