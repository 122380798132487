import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, FormikProps, withFormik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import * as Yup from "yup";

import { FormModal } from "components/molecules/Modal";
import { STATUS_CHOICES } from "constants/reports";
import { SidebarLayoutAllTeams } from "reactQuery/hooks/apiTypes";
import { useGetAllTeams } from "reactQuery/hooks/pages/useSidebarLayout";
import { createReport, isCreatingReportSelector } from "stores/reports/reportSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const ERROR_MESSAGES = {
    teamMissing: "A team is required",
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
};

export const CreateReportSchema = Yup.object().shape({
    selectedTeam: Yup.string().required(ERROR_MESSAGES.teamMissing).nullable(),
    reportDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
});

const formStyle = makeStyles((theme) => ({
    teamSelect: {
        paddingBottom: theme.spacing(2),
        width: "100%",
    },
    fullWidth: {
        width: "100%",
    },
}));

export const getTeamOptionLabel = (team: SidebarLayoutAllTeams) => `${team.name}`;

interface FormProps {
    reportDate: Date;
    selectedTeam: SidebarLayoutAllTeams;
}

export const CreateReportForm = ({
    errors,
    setFieldValue,
    values: { reportDate, selectedTeam },
}: FormikProps<FormProps>) => {
    const classes = formStyle();

    const getAllTeamsQuery = useGetAllTeams();

    return (
        <Form>
            <Autocomplete
                debug
                className={classes.teamSelect}
                loading={getAllTeamsQuery.isLoading}
                options={getAllTeamsQuery.data ?? []}
                getOptionLabel={getTeamOptionLabel}
                onChange={(_, value) => {
                    setFieldValue("selectedTeam", value);
                }}
                value={selectedTeam ? selectedTeam : null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        id="selected-team"
                        label="Team"
                        variant="outlined"
                        error={"selectedTeam" in errors}
                        helperText={(errors.selectedTeam as string) || null}
                    />
                )}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"reportDate" in errors}
                    className={classes.fullWidth}
                >
                    <InputLabel shrink>Report date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="date-picker-inline"
                        inputProps={{ "data-testid": "test-date-picker" }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="reportDate"
                        onChange={(e) => setFieldValue("reportDate", e)}
                        value={reportDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid="test-report-date-error">
                        {errors.reportDate ? ERROR_MESSAGES.dateMissing : null}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
        </Form>
    );
};

export const UnconnectedCreateReportModal = ({
    isLoading,
    isVisible,
    toggleOverlay,
    ...formikProps
}: {
    isLoading: boolean;
    isVisible: boolean;
    toggleOverlay: (_: {}) => void;
} & FormikProps<FormProps>) => (
    <FormModal
        handleClose={() => {
            toggleOverlay({ overlay: overlayNames.createReportModal });
        }}
        handleSubmit={formikProps.handleSubmit}
        isSubmitDisabled={isLoading}
        isSubmitLoading={isLoading}
        isVisible={isVisible}
        hasSecondaryButton={false}
        title="Create New Report"
        extraActions={null}
        isSecondaryDisabled={null}
        handleSecondaryClick={null}
    >
        <CreateReportForm {...formikProps} />
    </FormModal>
);

export const EnhancedCreateReportModal = withFormik<{ createReport: any }, FormProps>({
    enableReinitialize: false,
    handleSubmit: ({ reportDate, selectedTeam }, { props: { createReport } }) => {
        const date = moment(reportDate).format("YYYY-MM-DD");
        createReport({
            date,
            statusMessage: STATUS_CHOICES.IN_PROGRESS,
            teamId: selectedTeam.id,
        });
    },
    mapPropsToValues: () => ({
        reportDate: new Date(),
        selectedTeam: undefined,
    }),
    validationSchema: () => CreateReportSchema,
    validateOnChange: false,
    validateOnBlur: false,
})(UnconnectedCreateReportModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isLoading: isCreatingReportSelector(state as never),
        isVisible: isShowing.createReportModal,
    };
};

export const ConnectedCreateReportModal = connect(mapStateToProps, {
    createReport,
    toggleOverlay: toggleOverlay,
})(EnhancedCreateReportModal);

export default ConnectedCreateReportModal;
