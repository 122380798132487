import {
    StyledCheckBox,
    useStyles,
} from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    addToActionList: "add to action list",
};

const AddToActionList = ({
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    const styles = useStyles();

    return (
        <div className={styles.checkboxContainer}>
            <span style={{ marginRight: "5px" }}>Add to Action List</span>
            <StyledCheckBox
                checked={currentFeedback?.add_to_action_list}
                disabled={allowFeedback ? false : true}
                onChange={(event) => handleFeedbackClicked(event, "add_to_action_list")}
                data-testid={testIds.addToActionList}
            />
        </div>
    );
};

export default AddToActionList;
