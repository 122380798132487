import {
    useQuery,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";

import { nautilusGetRequest, nautilusPatchRequest, nautilusPostRequest } from "network";
import { emailGenerationInputFormKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";

interface EmailGenerationOutputPatchRequest {
    copied: boolean;
    output_id: number;
}

import {
    EmailGenerationInputPayload,
    EmailGenerationInputPostResponse,
    EmailGenerationOutputPostRequest,
    EmailGenerationOutputPostResponse,
} from "./apiTypes";

async function postEmailGenerationInputForm(
    requestData: Partial<EmailGenerationInputPayload>
): Promise<EmailGenerationInputPostResponse> {
    const { data } = await nautilusPostRequest(
        "/api/target_reports/email_generation_input_form/",
        requestData
    );
    return data;
}

async function postEmailGenerationOutputForm(
    requestData: Partial<EmailGenerationOutputPostRequest>
): Promise<EmailGenerationOutputPostResponse> {
    const { data } = await nautilusPostRequest(
        "/api/target_reports/email_generation_output_form/",
        requestData
    );
    return data;
}

async function patchEmailGenerationOutputForm(
    requestData: Partial<EmailGenerationOutputPatchRequest>,
    outputId: number
): Promise<EmailGenerationOutputPostResponse> {
    const { data } = await nautilusPatchRequest(
        `/api/target_reports/email_generation_output_form/${outputId}`,
        requestData
    );
    return data;
}

export function usePostEmailGenerationInputForm(
    teamId: number
): Partial<UseMutationResult<EmailGenerationInputPostResponse>> {
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const queryClient = useQueryClient();
    return useMutation(
        (requestData: EmailGenerationInputPayload) => {
            return postEmailGenerationInputForm(requestData);
        },
        {
            onMutate: async (requestData: EmailGenerationInputPayload) => {
                return { requestData };
            },
            onSuccess: (_data, _variables, context) => {
                const queryKey = emailGenerationInputFormKeys.single(teamId);
                queryClient.invalidateQueries(queryKey);
            },
            onError: () => {
                displayErrorSnack({ message: "Failed to save responses" });
            },
        }
    );
}

export const useGetEmailGenerationInputForm = (
    teamId: number,
    enabled: boolean
): Partial<UseQueryResult<EmailGenerationInputPayload>> => {
    const queryKey = emailGenerationInputFormKeys.single(teamId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<EmailGenerationInputPayload> =>
            (
                await nautilusGetRequest(
                    `/api/target_reports/email_generation_input_form/${teamId}`
                )
            ).data,
        enabled,
        retry: 0,
    });
};

export function usePostEmailGenerationOutputForm(): Partial<
    UseMutationResult<EmailGenerationOutputPostResponse>
> {
    return useMutation((args: EmailGenerationOutputPostRequest) => {
        return postEmailGenerationOutputForm(args);
    });
}

export function usePatchEmailGenerationOutputForm(): Partial<
    UseMutationResult<EmailGenerationOutputPostResponse>
> {
    return useMutation((args: EmailGenerationOutputPatchRequest) => {
        const { output_id, ...rest } = args;
        return patchEmailGenerationOutputForm(rest, output_id);
    });
}
