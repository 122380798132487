import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { useEffect } from "react";
import { connect } from "react-redux";

import StyledButton from "components/atoms/StyledButton";
import { opportunityCardStyles } from "components/cdv/OpportunityCard/styles";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { setInsightForCompanyRemoval } from "stores/companies/actions";
import {
    fetchDemographicsForOwner,
    selectActiveOpportunityOwnerPopulation,
    setInsightForLocationRemoval,
} from "stores/demographics/demographicSlice";
import {
    fetchDrinkingWaterSystemsForOwner,
    setInsightForDrinkingWaterSystemRemoval,
} from "stores/drinkingWaterSystems/actions";
import {
    fetchInsightsByFilter,
    intelligenceNotesSelector,
    isLoadingInsightSelector,
    selectInsightsForActiveOpportunity,
    setInsightForEdit,
    setInsightForModification,
    setOpportunityForInsightCreation,
} from "stores/insights/insightSlice";
import { activeOpportunitySelector } from "stores/opportunities/selectors";
import {
    fetchReportsForTeam,
    isLoadingReportsForTeamSelector,
    reportsSelectors,
    setActiveReport,
} from "stores/reports/reportSlice";
import {
    fetchSourcesForOpportunity,
    selectSourcesForActiveOpportunity,
    setInsightForSourceCreation,
    setSourceForEdit,
} from "stores/sources/sourceSlice";
import { displaySnack, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isEditModeActiveSelector } from "stores/uiStore/selectors";
import { isStaffSelector } from "stores/userStore/selectors";
import {
    fetchWastewaterFacilitiesForOwner,
    setInsightForWasteWaterSystemRemoval,
} from "stores/wastewaterFacilities/actions";

import InsightList from "./InsightList";
import OpportunityCardHeader from "./OpportunityCardHeader";
import { OpportunityFeedbackSection } from "./OpportunityFeedbackSection";
import OpportunityOwnerInfo from "./OpportunityOwnerInfo";

export const buttonTestIds = {
    addDrinkingWaterSystem: (id) => `add drinking water system ${id}`,
    addIntelligence: (id) => `add intelligence ${id}`,
    addLocation: (id) => `add location ${id}`,
    addSource: (id) => `add source ${id}`,
    addWastewaterFacility: (id) => `add waste water facility ${id}`,
    competitor: "competitor",
    consultant: "consultant",
    editOpportunity: "edit opportunity",
    editOpportunityOwner: "edit opportunity owner",
    editDeliveryStatus: (id) => `edit delivery status ${id}`,
    editTeamCustomerTags: (id) => `edit team customer tags ${id}`,
    editSummary: (id) => `edit summary ${id}`,
    editSource: (insightId, sourceId) => `insight ${insightId} source ${sourceId}`,
    editLocation: (insightId, demographicId) =>
        `insight ${insightId} location ${demographicId}`,
    editDrinkingWaterSystem: (insightId, systemId) =>
        `insight ${insightId} dw system ${systemId}`,
    editWastewaterFacility: (insightId, facilityId) =>
        `insight ${insightId} ww facility ${facilityId}`,
    toggleSource: (id) => `toggle source ${id}`,
};

export const UnconnectedOpportunityCard = ({
    allowEdit,
    reportInsights,
    isEditModeActive,
    isLoadingInsight,
    isLoadingReportsForTeam,
    isStaff,
    fetchDemographicsForOwner,
    fetchDrinkingWaterSystemsForOwner,
    fetchInsightsByFilter,
    fetchReportsForTeam,
    fetchSourcesForOpportunity,
    fetchWastewaterFacilitiesForOwner,
    reportId,
    reports,
    sources,
    setInsightForCompanyRemoval,
    setInsightForDrinkingWaterSystemRemoval,
    setInsightForEdit,
    setInsightForIntelligenceAddition,
    setInsightForLocationRemoval,
    setInsightForSourceCreation,
    setInsightForWasteWaterSystemRemoval,
    setOpportunityForInsightCreation,
    setActiveReport,
    setSourceForEdit,
    teamId,
    toggleOverlay,
    opportunity,
    opportunityType,
    ownerPopulation,
    intelNotes,
}) => {
    const styles = opportunityCardStyles();

    const insights =
        opportunityType === OpportunityTypeEnum.REPORT
            ? reportInsights
            : opportunityType === OpportunityTypeEnum.INDICATOR &&
              opportunity?.insights?.length > 0
            ? opportunity?.insights
            : [];

    useEffect(() => {
        if (opportunity) {
            fetchWastewaterFacilitiesForOwner({
                ownerId: opportunity.owner.id,
            });
            fetchDemographicsForOwner({
                ownerId: opportunity.owner.id,
            });
            fetchDrinkingWaterSystemsForOwner({
                ownerId: opportunity.owner.id,
            });
            fetchReportsForTeam({
                teamId,
            });
            fetchSourcesForOpportunity({
                opportunityId: opportunity.id,
            });
            fetchInsightsByFilter({
                opportunityId: opportunity.id,
                teamId: teamId,
            });
            setActiveReport({ reportId });
        }
    }, [
        fetchInsightsByFilter,
        fetchReportsForTeam,
        fetchDemographicsForOwner,
        fetchSourcesForOpportunity,
        opportunity,
        opportunityType,
        intelNotes.isChanging,
        fetchDrinkingWaterSystemsForOwner,
        fetchWastewaterFacilitiesForOwner,
        reportId,
        setActiveReport,
        teamId,
    ]);

    if (opportunity) {
        return (
            <Paper className={styles.cardWrapper}>
                <OpportunityCardHeader
                    isEditModeActive={allowEdit ? isEditModeActive : false}
                    opportunityTitle={opportunity.name}
                    opportunity={opportunity}
                />
                <OpportunityOwnerInfo
                    isEditModeActive={allowEdit ? isEditModeActive : false}
                    isStaff={isStaff}
                    opportunity={opportunity}
                    ownerPopulation={
                        opportunityType === OpportunityTypeEnum.REPORT
                            ? ownerPopulation
                            : opportunityType === OpportunityTypeEnum.INDICATOR
                            ? opportunity.owner?.demographics?.[0]?.population
                            : null
                    }
                    toggleOverlay={toggleOverlay}
                    teamId={teamId}
                />
                <OpportunityFeedbackSection
                    isStaff={isStaff}
                    opportunity={opportunity}
                    opportunityType={opportunityType}
                    teamId={teamId}
                />
                <InsightList
                    insights={insights}
                    isEditModeActive={allowEdit ? isEditModeActive : false}
                    isLoadingInsight={isLoadingInsight}
                    isLoadingReportsForTeam={isLoadingReportsForTeam}
                    opportunityType={opportunityType}
                    reports={reports}
                    sources={sources}
                    setInsightForCompanyRemoval={setInsightForCompanyRemoval}
                    setInsightForDrinkingWaterSystemRemoval={
                        setInsightForDrinkingWaterSystemRemoval
                    }
                    setInsightForEdit={setInsightForEdit}
                    setInsightForIntelligenceAddition={
                        setInsightForIntelligenceAddition
                    }
                    setInsightForLocationRemoval={setInsightForLocationRemoval}
                    setInsightForSourceCreation={setInsightForSourceCreation}
                    setInsightForWasteWaterSystemRemoval={
                        setInsightForWasteWaterSystemRemoval
                    }
                    setSourceForEdit={setSourceForEdit}
                    toggleOverlay={toggleOverlay}
                    isStaff={isStaff}
                />
                {isEditModeActive && allowEdit ? (
                    <div className={styles.createContainer}>
                        <StyledButton
                            handleClick={() => {
                                setOpportunityForInsightCreation({
                                    opportunityId: opportunity.id,
                                });
                                toggleOverlay({
                                    overlay: overlayNames.createInsightModal,
                                });
                            }}
                            variant="add-secondary"
                        >
                            Create New Insight
                        </StyledButton>
                    </div>
                ) : null}
            </Paper>
        );
    } else {
        return (
            <Paper>
                <LinearProgress />
            </Paper>
        );
    }
};

const mapStateToProps = (state, props) => ({
    demographics: [], // supposed to be the owner's set of demographics
    reportInsights: selectInsightsForActiveOpportunity(state as never),
    isEditModeActive: isEditModeActiveSelector(state),
    isLoadingInsight: isLoadingInsightSelector(state),
    //@ts-ignore
    isLoadingReportsForTeam: isLoadingReportsForTeamSelector(state, props),
    isStaff: isStaffSelector(state as never),
    reportOpportunity: activeOpportunitySelector(state as never),
    reports: reportsSelectors.selectAll(state),
    sources: selectSourcesForActiveOpportunity(state as never),
    ownerPopulation: selectActiveOpportunityOwnerPopulation(state),
    intelNotes: intelligenceNotesSelector(state),
});
const ConnectedOpportunityCard = connect(mapStateToProps, {
    displaySnack,
    fetchDemographicsForOwner,
    fetchDrinkingWaterSystemsForOwner,
    fetchInsightsByFilter,
    fetchReportsForTeam,
    fetchSourcesForOpportunity,
    fetchWastewaterFacilitiesForOwner,
    setInsightForCompanyRemoval,
    setInsightForDrinkingWaterSystemRemoval,
    setInsightForEdit,
    setInsightForIntelligenceAddition: setInsightForModification,
    setInsightForLocationRemoval,
    setInsightForSourceCreation,
    setInsightForWasteWaterSystemRemoval,
    setOpportunityForInsightCreation,
    setSourceForEdit,
    setActiveReport,
    toggleOverlay,
})(UnconnectedOpportunityCard);

export default ConnectedOpportunityCard;
