export const selectIdFromUrl = (_, props) => {
    if (props?.location?.search) {
        const indicatorGroupIdParam = new URLSearchParams(props.location.search).get(
            "indicator_group_id"
        );
        const reportIdParam = new URLSearchParams(props.location.search).get(
            "report_id"
        );
        return indicatorGroupIdParam
            ? parseInt(indicatorGroupIdParam)
            : parseInt(reportIdParam);
    } else {
        return parseInt(props?.match?.params?.id) || null;
    }
};

export const teamIdSelector = (_, props) => (props.team ? props.team.id : null);

export const queriesFromUrl = (props) => {
    let queryObj = {};
    const queries = props.location.search.match(/[^&=?]+=[^&=?]+/g);
    if (queries?.length > 0) {
        for (let i = 0; i < queries.length; i++) {
            let key = queries[i].split("=")?.[0];
            let value = queries[i].split("=")?.[1];
            queryObj[key] = value;
        }
    }
    return queryObj;
};
