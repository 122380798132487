// TODO: change wasteWaterFacility variables to facility
import { createSelector } from "@reduxjs/toolkit";

import { activeOpportunitySelector } from "stores/opportunities/selectors";
import { wastewaterFacilitiesAdapter } from "stores/wastewaterFacilities/adapter";
import { wastewaterFacilitiesReducerName } from "stores/wastewaterFacilities/constants";

export const wastewaterFacilitiesStoreSelector = (state) =>
    state[wastewaterFacilitiesReducerName];

export const wastewaterFacilitiesSelectors = wastewaterFacilitiesAdapter.getSelectors(
    wastewaterFacilitiesStoreSelector
);

export const newWastewaterFacilityLinkageSelector = createSelector(
    [wastewaterFacilitiesStoreSelector],
    (store) => store.newInsightLinkage
);

export const insightToCreateWastewaterFacilityLinkageSelector = createSelector(
    [newWastewaterFacilityLinkageSelector],
    (newLinkage) => newLinkage.insight
);

export const selectWastewaterFacilitiesForActiveOpportunity = createSelector(
    [wastewaterFacilitiesSelectors.selectAll, activeOpportunitySelector],
    (waterSystems, activeOpportunity) => {
        if (!activeOpportunity) {
            return [];
        }
        return waterSystems.filter((facility) =>
            activeOpportunity.owner["facilities"].includes(facility.id)
        );
    }
);

export const removeInsightLinkageSelector = createSelector(
    [wastewaterFacilitiesStoreSelector],
    (wastewaterFacilitiesStore) => wastewaterFacilitiesStore.removeInsightLinkage
);

export const insightIdToUnlinkWastewaterFacilityFromSelector = createSelector(
    [removeInsightLinkageSelector],
    (removeInsightLinkage) => removeInsightLinkage.insightId
);

export const wastewaterFacilityToUnlinkFromInsightSelector = createSelector(
    [removeInsightLinkageSelector],
    (removeInsightLinkage) => removeInsightLinkage.wastewaterFacility
);
