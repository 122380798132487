import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderConstraints from "../RenderConstraints";

const RenderGeographyConstraints = (intelligenceScope: IntelligenceScope) => (
    <PDFView
        style={pdfStyle.constraintContainer}
        key={intelligenceScope.id}
        wrap={false}
    >
        <PDFView style={pdfStyle.constraintTitleContainer}>
            <PDFText style={pdfStyle.constraintTitle}>Geography-Level</PDFText>
        </PDFView>
        {RenderConstraints({
            constraints: intelligenceScope.country_constraints,
            name: "Country",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.state_constraints,
            name: "State",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.county_constraints,
            name: "County",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.population_constraints,
            name: "Population",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.owner_constraints,
            name: "Owner Mention",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.owner_type_constraints,
            name: "Owner Types",
        })}
    </PDFView>
);

export default RenderGeographyConstraints;
