import StyledButton from "components/atoms/StyledButton";

import { pathBuilder } from "../utils";

const buttonColumn = ({
    buttonPath,
    buttonPathQueryParam,
    buttonTitle = "VIEW",
    columnTitle,
    excludeQueryParams,
    history,
    key,
    setQueryParam = false,
}: {
    buttonPath: string;
    buttonPathQueryParam: string;
    buttonTitle?: string;
    columnTitle?: string;
    excludeQueryParams?: string[];
    history: any;
    key: string;
    setQueryParam?: boolean;
}) => {
    return {
        title: columnTitle ?? null,
        dataIndex: key,
        key: key,
        render: (id) => (
            <StyledButton
                variant="add-primary"
                handleClick={() => {
                    const path = pathBuilder({
                        excludeQueryParams: excludeQueryParams,
                        id,
                        path: buttonPath,
                        queryParam: buttonPathQueryParam,
                        setQueryParam,
                    });
                    history.push(path);
                }}
            >
                {buttonTitle}
            </StyledButton>
        ),
    };
};

export default buttonColumn;
