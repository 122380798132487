import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Highlighter from "react-highlight-words";

import { additionalColors } from "constants/colors";

interface TextProps {
    children: any;
    searchTextValue?: string;
    variant?: string;
}

const labelStyles = makeStyles((theme: any) => ({
    labelHeader: {
        color: theme.palette.grey[500],
        fontSize: "14px",
    },
    label: {
        color: theme.palette.grey[500],
        fontSize: "14px",
    },
    labelDate: {
        fontSize: "16px",
        textAlign: "center",
    },
    value: {
        textAlign: "left",
        fontSize: "17px",
    },
    mainText: {
        textSize: "23px",
        fontWeight: 900,
        textAlign: "left",
    },
    underline: {
        textAlign: "end",
        textDecoration: "underline",
        fontWeight: 500,
    },
    pageHeader: {
        color: additionalColors.body,
        fontSize: "24px",
        paddingTop: "4px",
        marginBottom: "20px",
    },
}));
const Text = ({ children, searchTextValue, variant }: TextProps) => {
    const styles = labelStyles();

    return (
        <Typography className={variant ? styles[variant] : undefined}>
            {searchTextValue ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: additionalColors.lightOrange,
                        padding: 0,
                    }}
                    searchWords={[searchTextValue]}
                    autoEscape
                    textToHighlight={children ?? ""}
                />
            ) : (
                children
            )}
        </Typography>
    );
};

export default Text;
