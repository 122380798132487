import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Fragment } from "react";

import StyledButton from "components/atoms/StyledButton";
import { factoidStyles } from "components/cdv/OpportunityCard/styles";
import { DeepInsight } from "reactQuery/hooks/apiTypes";

export interface InsightFactoidListType {
    getEditTestId?: (insight: DeepInsight, value: any) => void;
    insight: DeepInsight;
    isEditModeActive: boolean;
    label: string;
    onEditClick?: (insight: DeepInsight, value: any) => void;
    renderItem: (value: any) => JSX.Element;
    values: any[];
}

const InsightFactoidList = ({
    getEditTestId,
    insight,
    isEditModeActive,
    label,
    onEditClick,
    renderItem,
    values,
}: InsightFactoidListType) => {
    const styles = factoidStyles();
    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));
    if (!isEditModeActive && values && values.length === 0) {
        return null;
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={3}>
                <Typography className={styles.label}>{label}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Grid container>
                    {values
                        ? values.map((value, index) => {
                              const editInsightButton = isEditModeActive ? (
                                  <StyledButton
                                      data-testid={getEditTestId(insight, value)}
                                      handleClick={() => {
                                          onEditClick(insight, value);
                                      }}
                                      variant="edit-secondary"
                                  >
                                      Edit
                                  </StyledButton>
                              ) : null;
                              return (
                                  <Fragment key={index}>
                                      <Grid item xs={10}>
                                          <div className={styles.value}>
                                              {renderItem(value)}
                                          </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <div
                                              style={{
                                                  marginRight: isNotMobileScreen
                                                      ? null
                                                      : "-80px",
                                              }}
                                          >
                                              {" "}
                                              {editInsightButton}
                                          </div>
                                      </Grid>
                                  </Fragment>
                              );
                          })
                        : null}
                </Grid>
            </Grid>
        </>
    );
};

export default InsightFactoidList;
