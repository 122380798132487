import StatusTag from "components/atoms/StatusTag";
import OpportunityFeedback from "components/molecules/OpportunityFeedback";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { OpportunityRead } from "reactQuery/hooks/apiTypes";
import {
    useGetIsTierEnabledForTeam,
    SYNC_TO_CRM_ENABLED_TIER_NAME,
} from "reactQuery/hooks/pages/useSidebarLayout";

import { feedbackStyles } from "../styles";

export interface OpportunityFeedbackSectionProps {
    isStaff: boolean;
    opportunity: OpportunityRead;
    opportunityType: OpportunityTypeEnum;
    teamId: number;
}

export const OpportunityFeedbackSection = ({
    isStaff,
    opportunity,
    opportunityType,
    teamId,
}: OpportunityFeedbackSectionProps) => {
    const styles = feedbackStyles();

    const syncOpportunitiesEnabled = useGetIsTierEnabledForTeam({
        teamId: teamId,
        tierName: SYNC_TO_CRM_ENABLED_TIER_NAME,
    });

    if (!opportunity) return null;

    return (
        <div className={styles.container}>
            {syncOpportunitiesEnabled && opportunity.crm_sync_status ? (
                <div style={{ marginRight: "30px" }}>
                    <StatusTag status={opportunity.crm_sync_status} />
                </div>
            ) : null}

            <OpportunityFeedback
                showAddToCRM={
                    syncOpportunitiesEnabled !== undefined && !syncOpportunitiesEnabled
                }
                allowFeedback={!isStaff}
                opportunityId={opportunity.id}
                teamId={teamId}
                viewType={`${opportunityType}Opportunity`}
            />
        </div>
    );
};
