import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { nautilusPostRequest } from "network";

import { Login } from "../apiTypes";

// backend view also must accept `username` parameter, but we only take in `email` in frontend
type UserSuppliedLoginPayload = Pick<Login, "email" | "password"> & { otp: string };

export interface LoginResponse {
    ["non_field_errors"]?: string[];
    ["key"]?: string;
    ["otp_required"]?: boolean;
}

const postLoginResponse = async (payload: Login): Promise<LoginResponse> => {
    return (await nautilusPostRequest(`/api/rest-auth/login/`, payload)).data;
};

export function useAuthLogin(): UseMutationResult<LoginResponse> {
    return useMutation((payload: UserSuppliedLoginPayload): Promise<LoginResponse> => {
        return postLoginResponse({ ...payload, username: "" });
    });
}
