import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";

let numberFormat: Intl.NumberFormat;
if (typeof Intl !== "undefined" && Intl.NumberFormat) {
    numberFormat = new Intl.NumberFormat("en-CA");
}

export const formatNumber = (number: number | null): string => {
    if (number === null) return "";

    if (!numberFormat) return String(number);

    return numberFormat.format(number);
};

export const formatShortMonthYear = (date: string): string => {
    if (!date) {
        return null;
    }
    return moment(date).format("MMM YYYY");
};

export const formatDayHyphenMonthHyphenYear = (date: string | Date): string => {
    if (!date) {
        return null;
    }
    return moment(date).format("DD-MMMM-YYYY");
};

export const formatMonthDayCommaYear = (date: string | Date): string => {
    if (!date) {
        return null;
    }
    return moment(date).format("MMMM DD, YYYY");
};

export const formatDayHyphenShortenedMonthHyphenYear = (
    date: string | Date
): string => {
    if (!date) {
        return null;
    }
    return moment(date).format("DD-MMM-YYYY");
};

export const formatDayHyphenShortenedMonthHyphenYearWithTime = (
    date: string | Date
): string => {
    if (!date) {
        return null;
    }
    return moment(date).format("DD-MMM-YYYY HH:mm");
};

export const formatTimeAgoDayHyphenShortenedMonthHyphenYearWithTime = (
    date: string
) => {
    TimeAgo.setDefaultLocale(en.locale);
    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo("en-US");
    if (!date) {
        return null;
    }
    return timeAgo.format(new Date(moment(date).format("YYYY/MM/DD HH:mm:ss")));
};

export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedPhoneNumber) {
        return null;
    }
    const nationalNumber = parsedPhoneNumber && parsedPhoneNumber.nationalNumber;
    const ext = parsedPhoneNumber && parsedPhoneNumber.ext;

    return `${nationalNumber.substring(0, 3)}-${nationalNumber.substring(
        3,
        6
    )}-${nationalNumber.substring(6, 10)}${ext ? " ext " + ext : ""}`;
};

export const formatTextWithoutConsecutiveSpaces = (text) => {
    if (!text) {
        return "";
    }
    return text.replace(/ +/g, " ").trim();
};
