import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Form, Select, Space, Button } from "antd";
import { useEffect } from "react";

import StatusTag from "components/atoms/StatusTag";
import { useGetAllDeliveryStatuses } from "reactQuery/hooks/useDeliveryStatuses";
import { usePatchInsight } from "reactQuery/hooks/useInsights";
import { insightsKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore } from "stores/zustandStore";

const { Option } = Select;

export const DeliveryStatusModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const deliveryStatusModalParams = useModalStore(
        (state) => state.deliveryStatusModalParams
    );
    const setDeliveryStatusModalParams = useModalStore(
        (state) => state.setDeliveryStatusModalParams
    );

    const statusOptions = useGetAllDeliveryStatuses().data ?? {};

    const patchInsightQuery = usePatchInsight();
    const queryClient = useQueryClient();

    const [form] = Form.useForm();
    const formId = "delivery-status-modal-form";

    useEffect(() => {
        form.setFieldValue("deliveryStatus", deliveryStatusModalParams.deliveryStatus);
    }, [deliveryStatusModalParams.deliveryStatus]);

    const onModalOk = () => {
        const payload = {
            delivery_status: form.getFieldValue("deliveryStatus"),
        };

        patchInsightQuery.mutate(
            { payload: payload, insightId: deliveryStatusModalParams.insightId },
            {
                onSuccess: () => {
                    const key = insightsKeys.single(
                        deliveryStatusModalParams.insightId
                    );
                    queryClient.invalidateQueries(key);

                    displaySuccessSnack({
                        message: "Delivery status updated successfully",
                    });

                    setDeliveryStatusModalParams({
                        ...deliveryStatusModalParams,
                        deliveryStatus: form.getFieldValue("deliveryStatus"),
                        visibility: false,
                    });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Delivery status failed to update",
                    });
                },
            }
        );
    };
    const onModalCancel = () => {
        setDeliveryStatusModalParams({
            ...deliveryStatusModalParams,
            visibility: false,
        });
        form.setFieldValue("deliveryStatus", deliveryStatusModalParams.deliveryStatus);
    };

    return (
        <Modal
            open={deliveryStatusModalParams.visibility}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={
                <>
                    <Button key="cancel" type="default" onClick={onModalCancel}>
                        Cancel
                    </Button>
                    <Button
                        loading={patchInsightQuery.isLoading}
                        disabled={patchInsightQuery.isLoading}
                        form={formId}
                        onClick={onModalOk}
                        key="submit"
                        htmlType="submit"
                        type="primary"
                    >
                        Submit
                    </Button>
                </>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="vertical"
                style={{ width: "80%", margin: "auto" }}
                preserve={false}
            >
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <Space>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            Edit Delivery Status
                        </Typography.Title>
                    </Space>
                </div>
                <Form.Item
                    name="deliveryStatus"
                    label="Delivery Status"
                    rules={[
                        {
                            required: true,
                            message: "Please select a delivery status",
                        },
                    ]}
                >
                    <Select
                        dropdownRender={(menu) => <>{menu}</>}
                        optionLabelProp="children"
                        onChange={(value) => {
                            form.setFieldsValue({ deliveryStatus: value });
                        }}
                    >
                        {Object.keys(statusOptions).map((status) => (
                            <Option key={status} value={status} title={status}>
                                <StatusTag status={status} />
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
