import { makeStyles } from "@material-ui/core/styles";

import { additionalColors } from "constants/colors";

export const factoidStyles = makeStyles(() => ({
    editIcon: {
        height: "14px",
        width: "14px",
    },
    label: {
        color: additionalColors.body54,
        paddingBottom: "20px",
    },
    value: {
        paddingBottom: "20px",
    },
}));

export const feedbackStyles = makeStyles(() => ({
    container: {
        borderBottom: "1px solid #D6D7D9",
        padding: "20px",

        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

export const insightStyles = makeStyles((theme) => ({
    container: {
        padding: "10px",
        paddingLeft: "20px",
    },
    insightGrid: {
        padding: "10px",
        paddingLeft: "20px",
    },
    insightInfoContainer: {
        borderBottom: "2px solid #000000",
    },
    newSourceButton: {
        float: "right",
    },
    sourcesContainer: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    extract: {
        marginTop: "-10px",
    },
    team_customer_tag: {
        maxWidth: "250px",
        overflowX: "auto",
    },
}));
export const ownerStyles = makeStyles(() => ({
    editButton: {
        border: "2px solid",
        color: additionalColors.grey999,
        float: "right",
        marginTop: "-50px",
    },
    ownerInfo: {
        padding: "20px 20px 0 20px",
        display: "flex",
        justifyContent: "space-between",
    },
    ownerLabel: {
        color: additionalColors.body54,
        paddingBottom: "18px",
        whiteSpace: "nowrap",
    },
    opportunityValue: {
        padding: "0px 20px 0 20px",
    },
    populationContainer: {
        float: "right",
    },
    populationLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    populationValue: {
        color: additionalColors.body,
    },
    addressLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    addressValue: {
        color: additionalColors.body,
    },
    zipCodeLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    zipCodeValue: {
        color: additionalColors.body,
    },
    countyLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    countyValue: {
        color: additionalColors.body,
    },
    fiscalLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    fiscalValue: {
        color: additionalColors.body,
    },
}));

export const headerStyles = makeStyles(() => ({
    headerContainer: {
        borderBottom: "2px solid #D6D7D9",
        paddingBottom: "14px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "15px",
        display: "flex",
        justifyContent: "space-between",
    },
}));
export const editOpportunityIconStyles = makeStyles(() => ({
    button: {
        border: "2px solid",
        color: additionalColors.grey999,
        float: "right",
        marginTop: "-32px",
    },
    icon: {
        height: "14px",
        width: "14px",
    },
}));

export const sourceStyles = makeStyles(() => ({
    editIcon: {
        height: "14px",
        width: "14px",
    },
    extract: {
        marginTop: "0",
    },
    label: {
        color: additionalColors.body54,
        paddingBottom: "20px",
    },
    sourcesContainer: {
        borderTop: "1px solid #D6D7D9",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
}));

export const loadingStyles = makeStyles(() => ({
    loadingBar: {
        marginTop: "14px",
    },
}));

export const opportunityCardStyles = makeStyles(() => ({
    addInfoToInsightButton: {
        float: "right",
        marginBottom: "10px",
    },
    cardWrapper: {
        paddingBottom: "32px",
    },
    editOpportunityButton: {
        float: "right",
        height: "14px",
        width: "14px",
        marginTop: "-24px",
    },
    editInsightButton: {
        float: "right",
        height: "14px",
        width: "14px",
    },
    createContainer: {
        padding: "20px",
    },
    editOpportunityIcon: {
        height: "14px",
        width: "14px",
    },
    editInsightIcon: {
        height: "14px",
        width: "14px",
    },
    insightContainer: {
        padding: "10px",
        paddingLeft: "20px",
    },
    insightLabel: {
        color: additionalColors.body54,
        paddingBottom: "20px",
    },
    insightValue: {
        paddingBottom: "20px",
    },
    opportunityHeader: {
        borderBottom: "2px solid #D6D7D9",
        paddingBottom: "14px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    opportunityName: {},
    ownerInfo: {
        borderBottom: "1px solid #D6D7D9",
        padding: "20px",
    },
    ownerLabel: {
        color: additionalColors.body54,
        paddingBottom: "18px",
    },
    populationContainer: {
        float: "right",
    },
    populationLabel: {
        color: additionalColors.body54,
        paddingRight: "16px",
    },
    populationValue: {
        color: additionalColors.red900,
    },
    insightRightGrid: {
        borderBottom: "1px solid black",
    },
}));
