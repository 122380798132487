import PDFDocument from "components/atoms/PDFDocument";

import RenderOpportunity from "../RenderOpportunity";

const SharedPDF = ({ sharedOpportunities }) => {
    return (
        // @ts-ignore
        <PDFDocument title="Citylitics Shared Opportunities">
            {sharedOpportunities.opportunities?.map((opportunity) =>
                RenderOpportunity(opportunity)
            )}
        </PDFDocument>
    );
};

export default SharedPDF;
