import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { nautilusGetRequest, nautilusPostRequest } from "network";
import { drinkingWaterSystemReducerName } from "stores/drinkingWaterSystems/constants";
import { displayErrorSnack, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";

const formatName = (name) => `${drinkingWaterSystemReducerName}/${name}`;

export const setInsightForDrinkingWaterSystemLinkage = createAction(
    formatName("setInsightForDrinkingWaterSystemLinkage")
);

export const setInsightForDrinkingWaterSystemRemoval = createAction(
    formatName("setInsightForDrinkingWaterSystemRemoval")
);

export const createDrinkingWaterSystem = createAsyncThunk(
    formatName("createDrinkingWaterSystem"),
    async ({ insightId, waterSystem }, { dispatch, rejectWithValue }) => {
        const requestBody = {
            insightId: insightId,
            name: waterSystem.name,
            population: waterSystem.population,
        };

        try {
            const response = await nautilusPostRequest(
                "/api/universe/drinking_water_systems/",
                requestBody
            );
            setInsightForDrinkingWaterSystemLinkage({});
            dispatch(
                toggleOverlay({ overlay: overlayNames.createDrinkingWaterSystemModal })
            );
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to create water system" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const fetchDrinkingWaterSystemsForOwner = createAsyncThunk(
    formatName("fetchDrinkingWaterSystemsForOwner"),
    async ({ ownerId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest(
                "/api/universe/drinking_water_systems/",
                `?owner=${ownerId}`
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch Drinking Water Systems.`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);
