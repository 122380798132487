export function retrieveNestedField(fields, obj) {
    let value = obj;
    fields.forEach((field) => (value = value[field]));
    return value;
}

export function combineTwoArraysWithNoDuplicates(array1, array2) {
    let array3 = array1.concat(array2);
    array3 = [...new Set([...array1, ...array2])];
    return array3;
}
const fileTypes = {
    csv: {
        type: "octet-stream",
        extension: ".csv",
    },
    json: {
        type: "json",
        extension: ".json",
    },
};

export function saveFile(content, type, fileName) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
        new Blob([content], { type: `application/${fileTypes[type].type}` })
    );
    const d = new Date();
    link.value = "download";
    link.download = `${fileName}-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}${
        fileTypes[type].extension
    }`;
    link.click();
}

export const findProductName = (teamProducts, productType) => {
    return teamProducts?.find((product) => product.product_type === productType)?.name;
};
