import { useLocation } from "react-router-dom";

import { BreadcrumbProps } from "components/molecules/Breadcrumbs";
import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { AllOwnersPathname } from "routes/IntelligenceFeeds";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;

    return { teamId };
};

export const allOwnersBreadcrumbs = () => {
    const { teamId } = pageParams();

    const breadcrumbs: BreadcrumbProps[] = [
        { name: "Intelligence Feeds" },
        { name: "All Feeds" },
        { name: "All Owners", url: `${AllOwnersPathname}?team_id=${teamId}` },
    ];

    return breadcrumbs;
};

const AllOwners = () => {
    const { teamId } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title="All Owners"
                    breadcrumbs={allOwnersBreadcrumbs()}
                >
                    <PaginatedTable feedType={FEED_TYPES.ALL_OWNERS} teamId={teamId} />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default AllOwners;
