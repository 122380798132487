import { createSelector } from "@reduxjs/toolkit";

import { targetsAdapter } from "stores/targets/adapter";
import { targetsReducerName } from "stores/targets/constants";

const _ = require("lodash");
export const targetsStoreSelector = (state) => state[targetsReducerName];

export const targetsSelectors = targetsAdapter.getSelectors(targetsStoreSelector);

export const queryHistorySelector = createSelector(
    [targetsSelectors.selectAll, targetsStoreSelector],
    (targets, targetStore) => {
        const selectedUser = targetStore.searchRecords.selectedUser;
        const selectedTeam = targetStore.searchRecords.selectedTeam;

        let filteredResults = [];
        filteredResults = selectedUser
            ? _.filter(targets, { profile: { id: selectedUser.id } })
            : filteredResults;
        filteredResults = selectedTeam
            ? _.filter(targets, { team: { id: selectedTeam.id } })
            : filteredResults;
        return selectedUser || selectedTeam ? filteredResults : targets.slice(0, 100);
    }
);

export const hasAttachedQueryWatcherSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.hasAttachedQueryWatcher
);

export const queryWatcherIdSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.queryWatcherId
);

export const pendingQueriesSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.pendingQueries
);

export const completedQueriesSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.completedQueries
);

export const enqueuedCountSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.enqueuedCount
);

export const isQueryingSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.isQuerying
);

export const resultsSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.results
);

export const uniqueUtilitiesCountSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.unique_utilities_count
);

export const isGeneratingSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.staticLink.isGenerating
);

export const generatedLinkSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.staticLink.url
);

export const previousQuerySelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.previousQuery
);

export const selectedSearchRecordSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.selectedSearchRecord
);

export const searchRecordsSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.searchRecords
);

export const searchRecordsErrorSelector = createSelector(
    [searchRecordsSelector],
    (searchRecords) => searchRecords.error
);

export const isLoadingSearchRecordsSelector = createSelector(
    [searchRecordsSelector],
    (searchRecords) => searchRecords.isLoading
);
export const newBatchSelector = createSelector(
    [targetsStoreSelector],
    (targetsStore) => targetsStore.newBatch
);

export const isCreatingBatchSelector = createSelector(
    [newBatchSelector],
    (newBatch) => newBatch.isCreating
);

export const newBatchErrorSelector = createSelector(
    [newBatchSelector],
    (newBatch) => newBatch.error
);

export const batchIdSelector = createSelector(
    [newBatchSelector],
    (newBatch) => newBatch.batchId
);
