import { Redirect, Route, Switch } from "react-router-dom";

import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import AllFeedback from "pages/IntelligenceFeeds/AllFeedback";
import AllIndicators from "pages/IntelligenceFeeds/AllIndicators";
import AllInsights from "pages/IntelligenceFeeds/AllInsights";
import AllOwners from "pages/IntelligenceFeeds/AllOwners";
import AssignedInsights from "pages/IntelligenceFeeds/AssignedInsights";
import IntelligenceFeedsDashboard from "pages/IntelligenceFeeds/IntelligenceFeedsDashboard";
import MyInsights from "pages/IntelligenceFeeds/MyInsights";
import PriorityInsights from "pages/IntelligenceFeeds/PriorityInsights";
import SingleAssignee from "pages/IntelligenceFeeds/SingleAssignee";
import SingleFeedback from "pages/IntelligenceFeeds/SingleFeedback";
import SingleIndicator from "pages/IntelligenceFeeds/SingleIndicator";
import SingleOpportunity from "pages/IntelligenceFeeds/SingleOpportunity";
import SingleOwner from "pages/IntelligenceFeeds/SingleOwner";

export const IntelligenceFeedsBasePathname = "/intfeeds";

export const AllInsightsPathname = `${IntelligenceFeedsBasePathname}/all_insights`;
export const AllInsightsSingleOpportunityPathname = `${AllInsightsPathname}/opportunity/:opportunity_id`;

export const AllFeedbackPathname = `${IntelligenceFeedsBasePathname}/all_feedback`;
export const AllFeedbackSingleFeedbackPathname = `${AllFeedbackPathname}/feedback`;
export const AllFeedbackSingleOpportunityPathname = `${AllFeedbackSingleFeedbackPathname}/opportunity/:opportunity_id`;

export const PriorityInsightsPathname = `${IntelligenceFeedsBasePathname}/priority`;
export const PriorityInsightsSingleOpportunityPathname = `${PriorityInsightsPathname}/opportunity/:opportunity_id`;

export const AssignedInsightsPathname = `${IntelligenceFeedsBasePathname}/assigned_insights`;
export const AssignedInsightsSingleAssigneePathname = `${AssignedInsightsPathname}/user`;
export const AssignedInsightsSingleOpportunityPathname = `${AssignedInsightsSingleAssigneePathname}/opportunity/:opportunity_id`;

export const AllIndicatorsPathname = `${IntelligenceFeedsBasePathname}/all_indicators`;
export const AllIndicatorsSingleIndicatorPathname = `${AllIndicatorsPathname}/indicator`;
export const AllIndicatorsSingleOpportunityPathname = `${AllIndicatorsSingleIndicatorPathname}/opportunity/:opportunity_id`;

export const AllOwnersPathname = `${IntelligenceFeedsBasePathname}/all_owners`;
export const AllOwnersSingleOwnerPathname = `${AllOwnersPathname}/owner`;
export const AllOwnersSingleOpportunityPathname = `${AllOwnersSingleOwnerPathname}/opportunity/:opportunity_id`;

export const MyInsightsPathname = `${IntelligenceFeedsBasePathname}/my_insights`;
export const MyInsightsSingleOpportunityPathname = `${MyInsightsPathname}/opportunity/:opportunity_id`;

export const IntelligenceFeedsDashboardPathname = `${IntelligenceFeedsBasePathname}/dashboard`;

const IntelligenceFeedsRoutes = () => {
    const AuthenticatedSingleOpportunity = withAuthenticationCheck(SingleOpportunity);
    return (
        <Switch>
            <Route exact path={PriorityInsightsPathname}>
                <PriorityInsights />
            </Route>

            <Route exact path={AllInsightsPathname}>
                <AllInsights />
            </Route>

            <Route exact path={AllFeedbackPathname}>
                <AllFeedback />
            </Route>

            <Route exact path={AllFeedbackSingleFeedbackPathname}>
                <SingleFeedback />
            </Route>

            <Route exact path={AssignedInsightsPathname}>
                <AssignedInsights />
            </Route>

            <Route exact path={AssignedInsightsSingleAssigneePathname}>
                <SingleAssignee />
            </Route>

            <Route exact path={AllIndicatorsPathname}>
                <AllIndicators />
            </Route>

            <Route exact path={AllIndicatorsSingleIndicatorPathname}>
                <SingleIndicator />
            </Route>

            <Route exact path={AllOwnersPathname}>
                <AllOwners />
            </Route>

            <Route exact path={AllOwnersSingleOwnerPathname}>
                <SingleOwner />
            </Route>

            <Route exact path={MyInsightsPathname}>
                <MyInsights />
            </Route>

            <Route exact path={IntelligenceFeedsDashboardPathname}>
                <IntelligenceFeedsDashboard />
            </Route>

            <Route
                exact
                path={[
                    AllOwnersSingleOpportunityPathname,
                    AllIndicatorsSingleOpportunityPathname,
                    MyInsightsSingleOpportunityPathname,
                    AssignedInsightsSingleOpportunityPathname,
                    AllFeedbackSingleOpportunityPathname,
                    AllInsightsSingleOpportunityPathname,
                    PriorityInsightsSingleOpportunityPathname,
                ]}
            >
                <AuthenticatedSingleOpportunity />
            </Route>

            <Route exact path={IntelligenceFeedsBasePathname}>
                <p>Intelligence Feeds Landing Page</p>
            </Route>

            <Route path="*">
                <Redirect to={IntelligenceFeedsBasePathname} />
            </Route>
        </Switch>
    );
};

export default IntelligenceFeedsRoutes;
