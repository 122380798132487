import { createSelector } from "@reduxjs/toolkit";

import { drinkingWaterSystemsAdapter } from "stores/drinkingWaterSystems/adapter";
import { drinkingWaterSystemReducerName } from "stores/drinkingWaterSystems/constants";
import { activeOpportunitySelector } from "stores/opportunities/selectors";

export const drinkingWaterSystemStoreSelector = (state) =>
    state[drinkingWaterSystemReducerName];

export const waterSystemsSelectors = drinkingWaterSystemsAdapter.getSelectors(
    drinkingWaterSystemStoreSelector
);

export const newDrinkingWaterSystemSelector = createSelector(
    [drinkingWaterSystemStoreSelector],
    (drinkingWaterSystemStore) => drinkingWaterSystemStore.newWaterSystem
);

export const removeDrinkingWaterSystemSelector = createSelector(
    [drinkingWaterSystemStoreSelector],
    (drinkingWaterSystemStore) => drinkingWaterSystemStore.removeWaterSystem
);

export const isShowingAddDrinkingWaterSystemModalSelector = createSelector(
    [newDrinkingWaterSystemSelector],
    (newDrinkingWaterSystem) => newDrinkingWaterSystem.isCreateModeActive
);

export const selectWaterSystemsForActiveOpportunity = createSelector(
    [waterSystemsSelectors.selectAll, activeOpportunitySelector],
    (waterSystems, activeOpportunity) => {
        if (!activeOpportunity) {
            return [];
        }
        return waterSystems.filter((waterSystem) =>
            activeOpportunity.owner.dw_systems.includes(waterSystem.id)
        );
    }
);

export const insightIdToRemoveDrinkingWaterSystemFromSelector = createSelector(
    [removeDrinkingWaterSystemSelector],
    (removeDrinkingWaterSystem) => removeDrinkingWaterSystem.insightId
);
