import "antd/dist/antd.css";
import { Grid, useMediaQuery } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import StatusTag from "components/atoms/StatusTag";
import StyledButton from "components/atoms/StyledButton";
import PaginatedTable from "components/organisms/PaginatedTable";
import { FEED_TYPES } from "constants/feedTypes";
import { formatMonthDayCommaYear } from "constants/formatters";
import { findProductName } from "helpers";
import { IntelligenceReportsAllReportsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllReports";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";
import {
    ProductTypeEnum,
    DeepReportRead,
    IndicatorGroup,
    SidebarLayoutSingleTeam,
} from "reactQuery/hooks/apiTypes";
import { IndicatorFeedsAllFeedsPathname } from "routes/IndicatorFeeds";
import { overlayNames } from "stores/uiStore/constants";
import { ModalNames, useModalStore } from "stores/zustandStore";

import { useStyles } from "./styles.js";

interface ReportDetailsTemplateProps {
    feedType: string;
    indicatorGroupData: IndicatorGroup;
    isEditModeActive: boolean;
    isStaff: boolean;
    reportData: DeepReportRead;
    teamId?: number;
    toggleEditMode: Function;
    toggleOverlay: Function;
    team: SidebarLayoutSingleTeam;
}

export const ReportDetailsTemplate = ({
    feedType,
    indicatorGroupData,
    isEditModeActive,
    reportData,
    teamId,
    toggleEditMode,
    toggleOverlay,
    isStaff,
    team,
}: ReportDetailsTemplateProps) => {
    const styles = useStyles();
    const editModeEnabled = isEditModeActive && feedType === FEED_TYPES.SINGLE_REPORT;

    const setModalVisible = useModalStore((state) => state.setModalVisible);

    const indicatorGroupHeader =
        (indicatorGroupData?.indicator?.industry ||
            indicatorGroupData?.indicator?.value) &&
        Object.keys(indicatorGroupData.indicator)
            .filter(
                (key) =>
                    ["industry", "value"].includes(key) &&
                    indicatorGroupData.indicator[key]
            )
            .map((key) => indicatorGroupData.indicator[key])
            .join(", ");

    const prevId = reportData?.prev_report_id || null;
    const nextId = reportData?.next_report_id || null;
    const reportStatus = reportData?.status || null;

    const reportDate =
        feedType === FEED_TYPES.SINGLE_REPORT && reportData
            ? formatMonthDayCommaYear(reportData.date)
            : formatMonthDayCommaYear(new Date());

    const editButton =
        isStaff && feedType === FEED_TYPES.SINGLE_REPORT ? (
            <StyledButton
                variant="page-action"
                handleClick={() => {
                    toggleEditMode();
                }}
            >
                {editModeEnabled ? "Preview" : "Edit"}
            </StyledButton>
        ) : null;

    const createButton = editModeEnabled ? (
        <StyledButton
            variant="add-primary"
            handleClick={() => {
                setModalVisible(ModalNames.AddOpportunity);
            }}
        >
            Add Opportunity
        </StyledButton>
    ) : null;

    const teamProducts = team?.products;
    const indicatorFeedProductName = findProductName(
        teamProducts,
        ProductTypeEnum.INDICATOR_FEEDS
    );
    const intelligenceReportsProductName = findProductName(
        teamProducts,
        ProductTypeEnum.INTELLIGENCE_REPORTS
    );

    const toolBar = (
        <div className={styles.toolbar}>
            <div className={styles.searchBarContainer}>
                <Typography className={styles.toolbarTitle}>
                    {feedType === FEED_TYPES.SINGLE_REPORT && reportData
                        ? `${reportDate} Report`
                        : feedType === FEED_TYPES.INDICATOR_FEED
                        ? indicatorGroupHeader
                            ? `Feed: ${indicatorGroupHeader}`
                            : indicatorFeedProductName
                        : feedType === FEED_TYPES.ALL_INSIGHTS
                        ? "All Insights"
                        : null}
                </Typography>
                {feedType === FEED_TYPES.SINGLE_REPORT ? createButton : null}
            </div>
        </div>
    );

    const header = (
        <div>
            {editModeEnabled && (
                <div className={styles.managementHeader}>
                    <Typography className={styles.managementText}>
                        MANAGEMENT
                    </Typography>
                    <StatusTag status={reportStatus} />
                </div>
            )}
            <div className={styles.bodyHeader}>
                <div className={styles.bodyHeaderLeft}>
                    <div className={styles.titleContainer}>
                        <Breadcrumbs separator="/">
                            <Typography className={styles.reportsLink}>
                                {feedType === FEED_TYPES.SINGLE_REPORT && reportData
                                    ? reportData.team.name
                                    : feedType === FEED_TYPES.INDICATOR_FEED &&
                                      indicatorGroupData
                                    ? indicatorGroupData?.team?.name
                                    : feedType === FEED_TYPES.ALL_INSIGHTS && team
                                    ? team.name
                                    : null}
                            </Typography>
                            {feedType === FEED_TYPES.INDICATOR_FEED &&
                            indicatorGroupHeader ? (
                                <Link
                                    className={styles.reportsLink}
                                    to={
                                        IndicatorFeedsAllFeedsPathname +
                                        "?team_id=" +
                                        indicatorGroupData.team.id
                                    }
                                    component={RouterLink}
                                >
                                    {indicatorFeedProductName}
                                </Link>
                            ) : null}
                            {feedType === FEED_TYPES.SINGLE_REPORT && reportData ? (
                                <Link
                                    className={styles.reportsLink}
                                    component={RouterLink}
                                    to={
                                        IntelligenceReportsAllReportsPathname +
                                        "?team_id=" +
                                        reportData.team.id
                                    }
                                >
                                    All Reports
                                </Link>
                            ) : null}
                            {feedType === FEED_TYPES.SINGLE_REPORT && reportData ? (
                                <Typography color="textPrimary">
                                    {reportDate}
                                </Typography>
                            ) : feedType === FEED_TYPES.INDICATOR_FEED &&
                              indicatorGroupHeader ? (
                                <Typography color="textPrimary">
                                    {indicatorGroupHeader}
                                </Typography>
                            ) : feedType === FEED_TYPES.ALL_INSIGHTS ? (
                                <Typography color="textPrimary">
                                    {intelligenceReportsProductName}
                                </Typography>
                            ) : null}
                        </Breadcrumbs>
                    </div>
                </div>

                <div className={styles.previewButtonContainer}>{editButton}</div>
            </div>
        </div>
    );

    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const float_none = { float: "none" as const };

    const contentId =
        feedType === FEED_TYPES.SINGLE_REPORT
            ? reportData?.id
            : feedType === FEED_TYPES.INDICATOR_FEED
            ? indicatorGroupData.id
            : null;

    return (
        <div>
            <div>{header}</div>
            <div>{toolBar}</div>
            <div className={styles.wrapper}>
                {feedType === FEED_TYPES.SINGLE_REPORT ? (
                    nextId ? (
                        <>
                            <ChevronLeft className={styles.navArrow} />
                            <Link
                                className={styles.navigatePrev}
                                to={`${IntelligenceReportsSingleReportPathname}?team_id=${team?.id}&report_id=${nextId}`}
                                component={RouterLink}
                            >
                                VIEW NEXT
                            </Link>
                        </>
                    ) : null
                ) : null}
                {feedType === FEED_TYPES.SINGLE_REPORT ? (
                    prevId ? (
                        <>
                            <Link
                                className={styles.navigateNext}
                                to={`${IntelligenceReportsSingleReportPathname}?team_id=${team?.id}&report_id=${prevId}`}
                                component={RouterLink}
                            >
                                VIEW PREVIOUS
                            </Link>
                            <ChevronRight className={styles.navArrow} />
                        </>
                    ) : null
                ) : null}
            </div>
            <PaginatedTable contentId={contentId} feedType={feedType} teamId={teamId} />
            <Grid container className={styles.editButtonOuterContainer} spacing={3}>
                <Grid item className={styles.editButtonInnerContainer}>
                    {editModeEnabled ? (
                        <StyledButton
                            style={isSmallScreen ? float_none : null}
                            variant="edit-secondary"
                            handleClick={() => {
                                toggleOverlay({
                                    overlay: overlayNames.editReportModal,
                                });
                            }}
                        >
                            Edit
                        </StyledButton>
                    ) : null}
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </div>
    );
};

export default ReportDetailsTemplate;
