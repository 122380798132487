import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";

import { pdfStyle } from "../pdfStyle";
import RenderTimelineItem from "../RenderTimelineItem";

const RenderTimeline = (timeline) => (
    <PDFView style={pdfStyle.sectionContainer}>
        <PDFText style={pdfStyle.sectionTitle}>Comments & Timeline</PDFText>
        <PDFView style={pdfStyle.timelineContainer}>
            {timeline.map((timelineItem, index) =>
                RenderTimelineItem(timelineItem, index)
            )}
        </PDFView>
    </PDFView>
);

export default RenderTimeline;
