import { Alert, Button, Divider, InputNumber, Slider } from "antd";
import _ from "lodash";
import React from "react";
import Highlighter from "react-highlight-words";

import { additionalColors } from "constants/colors";

export const RangeFilterProps = (
    dataIndex,
    minValue,
    maxValue,
    minInputValue,
    maxInputValue,
    keys,
    setMinInputValue,
    setMaxInputValue,
    setKeys,
    searchTextValue,
    filteredInfo,
    handleMultiSelectFilterReset
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <>
            <Slider
                range
                defaultValue={[0, maxValue / 2]}
                onChange={(value) => {
                    setMinInputValue(value[0]);
                    setMaxInputValue(value[1]);
                }}
                onAfterChange={(value) => {
                    setMinInputValue(value[0]);
                    setMaxInputValue(value[1]);
                    setSelectedKeys([{ min: value[0], max: value[1] }]);
                }}
                min={0}
                max={maxValue}
                step={dataIndex === "facility_size" ? 0.01 : 1}
            />
            <InputNumber
                min={0}
                addonBefore={"min"}
                max={maxValue}
                style={{ margin: "0 16px" }}
                value={minInputValue}
                onChange={(value) => {
                    setMinInputValue(value);
                    setSelectedKeys([
                        {
                            min: value != null ? value : 0,
                            max: maxInputValue != null ? maxInputValue : maxValue,
                        },
                    ]);
                }}
            />
            <InputNumber
                min={0}
                addonBefore={"max"}
                max={maxValue}
                style={{ margin: "0 16px" }}
                value={maxInputValue}
                onChange={(value) => {
                    setMaxInputValue(value);
                    setSelectedKeys([
                        {
                            min: minInputValue !== null ? minInputValue : 0,
                            max: value !== null ? value : maxValue,
                        },
                    ]);
                }}
            />
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    type="link"
                    onClick={() =>
                        handleMultiSelectFilterReset(
                            clearFilters,
                            setSelectedKeys,
                            dataIndex
                        )
                    }
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
                {minInputValue !== null &&
                    maxInputValue !== null &&
                    minInputValue > maxInputValue && (
                        <Alert
                            message="Max number needs to be higher than min number"
                            type="error"
                            style={{ height: 32 }}
                        />
                    )}
                <Button
                    type="primary"
                    style={{ margin: "0 16px 12px" }}
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        setKeys({ min: minInputValue, max: maxInputValue });
                    }}
                    disabled={
                        (minInputValue !== null &&
                            maxInputValue != null &&
                            minInputValue > maxInputValue) ||
                        (minInputValue == null && maxInputValue == null)
                    }
                >
                    OK
                </Button>
            </div>
        </>
    ),
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            setMinInputValue(keys["min"]);
            setMaxInputValue(keys["max"]);
        }
    },
    onFilter: (value, record) => {
        return record[dataIndex] || record[dataIndex] === 0
            ? _.inRange(record[dataIndex], value.min, value.max) ||
                  record[dataIndex] === value.max
            : false;
    },
    filteredValue: filteredInfo[dataIndex] || null,
    render: (text) => (
        <div data-testid={dataIndex}>
            {searchTextValue ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: additionalColors.lightOrange,
                        padding: 0,
                    }}
                    searchWords={[searchTextValue]}
                    autoEscape
                    textToHighlight={text !== "0" ? text.toString() : ""}
                />
            ) : text !== "0" ? (
                text
            ) : (
                ""
            )}
        </div>
    ),
});
