import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

const useStyle = makeStyles((theme) => ({
    buttons: {
        marginBottom: "10px",
    },
    formControl: {
        width: "100%",
        height: "100%",
        marginBottom: "20px",
    },
    guideBtn: {
        float: "right",
    },
    textInput: {
        width: "100%",
    },
}));

const MarkdownTextField = ({ errors, label, name, onChange, value, ...rest }) => {
    const styles = useStyle();
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const md = (
        <div data-testid="md" style={{ minWidth: "100%", maxWidth: "100%" }}>
            <ReactMarkdown>{value}</ReactMarkdown>
        </div>
    );
    return (
        <>
            <ButtonGroup
                color="primary"
                size="small"
                variant="contained"
                className={styles.buttons}
            >
                <Button onClick={() => setIsPreviewMode(false)}>Write</Button>
                <Button onClick={() => setIsPreviewMode(true)}>Preview</Button>
            </ButtonGroup>
            <Link
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                rel="noreferrer"
                className={styles.guideBtn}
            >
                Markdown Syntax
            </Link>
            <FormControl
                error={errors ? name in errors : null}
                className={styles.formControl}
            >
                <TextField
                    className={styles.textInput}
                    disabled={isPreviewMode}
                    error={errors ? name in errors : null}
                    helperText={errors ? errors[name] || null : null}
                    label={isPreviewMode ? `${label} Preview` : label}
                    multiline
                    name={name}
                    onChange={onChange}
                    value={isPreviewMode ? "" : value}
                    variant="outlined"
                    InputProps={{
                        startAdornment: isPreviewMode ? md : null,
                    }}
                    {...rest}
                />
            </FormControl>
        </>
    );
};

export default MarkdownTextField;
