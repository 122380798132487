import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form, withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { StyledButton } from "components/atoms";
import FormSearchField from "components/molecules/FormSearchField";
import { FormModal } from "components/molecules/Modal";
import { InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import {
    newDrinkingWaterSystemSelector,
    selectWaterSystemsForActiveOpportunity,
} from "stores/drinkingWaterSystems/selectors";
import { patchInsight } from "stores/insights/actions";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { searchForDrinkingWaterSystem } from "stores/universeStore/actionTypes";
import {
    drinkingWaterSystemSearchIsSearchingSelector,
    drinkingWaterSystemSearchResultsSelector,
} from "stores/universeStore/selectors";

export const tooManyResultsTestId = "too-many-results";
export const drinkingWaterSystemDropdownTestId = "drinkingwatersystem-dropdown";
export const drinkingWaterSystemInputTestId = "drinkingwatersystem-input";
export const drinkingWaterSystemNameTestId = "drinkingwatersystem-name";
export const populationTestId = "population";
export const nameTestId = "name";

export const createTooManyDrinkingWaterSystemsText = (systemsCount) =>
    `Too many drinking water systems found (${systemsCount}) - please continue searching`;

export const getDrinkingWaterSystemOptionLabel = (option) => `${option.name}`;

const formStyle = makeStyles((theme) => ({
    errorMsg: {
        marginTop: "-4px",
        marginBottom: "4px",
    },
    textInput: {
        width: "100%",
    },
}));

const drinkingWaterSystemHelperText = "Try searching by drinking water system name";
export const ERROR_MESSAGES = {
    selectedDrinkingWaterSystemMissing: "Water system is required",
    populationMissing: "Population is required",
    waterSystemNameMissing: "Water system name is required",
};

const CreateDrinkingWaterSystemSchema = Yup.object().shape({
    selectedDrinkingWaterSystem: Yup.object().when("isShowingAddCustomSystem", {
        is: false,
        then: Yup.object()
            .nullable()
            .required(ERROR_MESSAGES.selectedDrinkingWaterSystemMissing),
        otherwise: Yup.object().nullable().notRequired(),
    }),
    population: Yup.number().when("isShowingAddCustomSystem", {
        is: true,
        then: Yup.number().nullable().required(ERROR_MESSAGES.populationMissing),
        otherwise: Yup.number().nullable().notRequired(),
    }),
    waterSystemName: Yup.string().when("isShowingAddCustomSystem", {
        is: true,
        then: Yup.string().nullable().required(ERROR_MESSAGES.waterSystemNameMissing),
        otherwise: Yup.string().nullable().notRequired(),
    }),
});

export const CustomDrinkingWaterSystem = ({
    classes,
    errors,
    handleChange,
    values,
}) => {
    const { waterSystemName, population } = values;

    return (
        <div>
            <FormControl
                error={"waterSystemName" in errors}
                className={classes.formControl}
            >
                <TextField
                    className={classes.textInput}
                    error={"waterSystemName" in errors}
                    helperText={errors.waterSystemName}
                    id="waterSystemName"
                    inputProps={{ "data-testid": nameTestId }}
                    label="Drinking Water System"
                    name="waterSystemName"
                    onChange={handleChange}
                    value={waterSystemName}
                    variant="outlined"
                />
            </FormControl>
            <FormControl error={"population" in errors} className={classes.formControl}>
                <TextField
                    className={classes.textInput}
                    error={"population" in errors}
                    helperText={errors.population}
                    id="population"
                    inputProps={{ "data-testid": populationTestId }}
                    label="Population Served"
                    name="population"
                    onChange={handleChange}
                    value={population}
                    variant="outlined"
                />
            </FormControl>
        </div>
    );
};

export const CreateDrinkingWaterSystemForm = ({
    drinkingWaterSystemSearchResults,
    errors,
    handleChange,
    isSearching,
    isShowingAddCustomSystem,
    linkedDrinkingWaterSystems,
    setFieldValue,
    searchForDrinkingWaterSystem,
    values,
}) => {
    const classes = formStyle();

    return (
        <Form>
            {isShowingAddCustomSystem ? (
                <CustomDrinkingWaterSystem
                    classes={classes}
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                />
            ) : (
                <FormSearchField
                    name="selectedDrinkingWaterSystem"
                    label="Drinking Water System"
                    isFetchingResults={isSearching}
                    value={values.selectedDrinkingWaterSystem}
                    initialOptions={linkedDrinkingWaterSystems}
                    onChange={(e, v) => {
                        setFieldValue("selectedDrinkingWaterSystem", v);
                    }}
                    onInputDelayComplete={(searchText) => {
                        searchForDrinkingWaterSystem(searchText);
                    }}
                    errors={errors}
                    allOptions={drinkingWaterSystemSearchResults || []}
                    noOptions={
                        <StyledButton
                            variant="admin-link"
                            url="admin/universe/drinkingwatersystem/add/"
                            target="_blank"
                        >
                            Add New Drinking Water System
                        </StyledButton>
                    }
                    helperText={drinkingWaterSystemHelperText}
                />
            )}
        </Form>
    );
};

export const UnconnectedAddDrinkingWaterSystemModal = ({
    drinkingWaterSystemSearchResults,
    isCreating,
    isCreateModeActive,
    isSearching,
    linkedDrinkingWaterSystems,
    searchForDrinkingWaterSystem,
    toggleOverlay,
    values,
    ...formikProps
}) => {
    if (!isCreateModeActive) {
        return null;
    }

    const showAddCustomSystem = values ? values.isShowingAddCustomSystem : false;
    const hasPendingAction = isCreating;

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.addDrinkingWaterSystemModal });
                formikProps.resetForm();
            }}
            handleSubmit={formikProps.handleSubmit}
            handleSecondaryClick={() => {
                formikProps.resetForm();
                formikProps.setFieldValue("isShowingAddCustomSystem", true);
            }}
            isSubmitDisabled={hasPendingAction}
            isSubmitLoading={hasPendingAction}
            isSecondaryDisabled={hasPendingAction}
            isVisible={isCreateModeActive}
            hasSecondaryButton={false}
            secondaryLabel="Add New System"
            submitLabel="Submit"
            title="Add Drinking Water System"
        >
            <CreateDrinkingWaterSystemForm
                drinkingWaterSystemSearchResults={drinkingWaterSystemSearchResults}
                isSearching={isSearching}
                isShowingAddCustomSystem={showAddCustomSystem}
                linkedDrinkingWaterSystems={linkedDrinkingWaterSystems}
                searchForDrinkingWaterSystem={searchForDrinkingWaterSystem}
                values={values}
                errors={formikProps.errors}
                handleChange={formikProps.handleChange}
                setFieldValue={formikProps.setFieldValue}
                {...formikProps}
            />
        </FormModal>
    );
};
export interface EnhancedProps {
    insight: InsightInOpportunity;
    patchInsight: any;
}
export interface FormProps {
    selectedDrinkingWaterSystem: any;
}
export const EnhancedAddDrinkingWaterSystemModal = withFormik<EnhancedProps, FormProps>(
    {
        enableReinitialize: true,
        handleSubmit: (
            { selectedDrinkingWaterSystem },
            { resetForm, props: { insight, patchInsight } }
        ) => {
            resetForm();
            const existingSystems = insight.drinking_water_systems.map(
                (system) => system.id
            );
            patchInsight({
                insightId: insight.id,
                drinking_water_systems: [
                    ...existingSystems,
                    selectedDrinkingWaterSystem.id,
                ],
            });
        },
        mapPropsToValues: () => ({
            selectedDrinkingWaterSystem: null,
            drinkingWaterSystemName: null,
            population: null,
            isShowingAddCustomSystem: false,
        }),
        validationSchema: () => CreateDrinkingWaterSystemSchema,
        validateOnBlur: false,
        validateOnChange: false,
    }
)(UnconnectedAddDrinkingWaterSystemModal);

const mapStateToProps = (state) => {
    const newDrinkingWaterSystem = newDrinkingWaterSystemSelector(state);
    const isShowing = isShowingSelector(state);
    return {
        drinkingWaterSystemSearchResults: drinkingWaterSystemSearchResultsSelector(
            state as never
        ),
        isCreating: newDrinkingWaterSystem.isCreating,
        isCreateModeActive: isShowing.addDrinkingWaterSystemModal,
        insight: newDrinkingWaterSystem.insight,
        isSearching: drinkingWaterSystemSearchIsSearchingSelector(state as never),
        linkedDrinkingWaterSystems: selectWaterSystemsForActiveOpportunity(state),
    };
};

const ConnectedCreateDrinkingWaterSystemModal = connect(mapStateToProps, {
    searchForDrinkingWaterSystem,
    toggleOverlay: toggleOverlay,
    patchInsight,
})(EnhancedAddDrinkingWaterSystemModal);

export default ConnectedCreateDrinkingWaterSystemModal;
