import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { subscriptionKeys } from "reactQuery/keys";

import { PaginatedSearchPlanReadList } from "./apiTypes";

export const useGetAllSearchPlans = (
    teamId: number
): UseQueryResult<PaginatedSearchPlanReadList> => {
    const queryKey = subscriptionKeys.team(teamId);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<PaginatedSearchPlanReadList> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/search_plan_list/`,
                `?team=${teamId}`
            );
            return data;
        },
    });
};
