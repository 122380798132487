import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import PDFTag from "components/molecules/PDFTag";
import { additionalColors } from "constants/colors";
import { formatTextWithoutConsecutiveSpaces } from "constants/formatters";
import { IntelligenceScope, RankEnum } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";

const RenderIntelligenceScopeDetails = (intelligenceScope: IntelligenceScope) => (
    <PDFView style={pdfStyle.innerSectionTitleContainer} wrap={false}>
        <PDFText style={pdfStyle.innerSectionTitle}>
            {`${intelligenceScope.industry ? `${intelligenceScope.industry} | ` : ""}${
                intelligenceScope.indicator?.value
            }`}
        </PDFText>
        {intelligenceScope.rank ? (
            <PDFView style={pdfStyle.innerSectionTagContainer}>
                <PDFTag
                    color={
                        intelligenceScope.rank === RankEnum.PriorityHigh
                            ? additionalColors.green500
                            : intelligenceScope.rank === RankEnum.PriorityMedium
                            ? additionalColors.blue500Primary
                            : intelligenceScope.rank === RankEnum.PriorityLow
                            ? additionalColors.lightOrange
                            : "black"
                    }
                    level={0}
                    textColorMatchesBorder={true}
                >
                    {intelligenceScope.rank}
                </PDFTag>
            </PDFView>
        ) : null}
        {intelligenceScope.indicator?.description ? (
            <PDFText style={pdfStyle.innerSectionTitleDescriptionText}>
                {formatTextWithoutConsecutiveSpaces(
                    intelligenceScope.indicator.description
                )}
            </PDFText>
        ) : null}
        {intelligenceScope.additional_notes ? (
            <PDFView style={pdfStyle.constraintContainer} key={intelligenceScope.id}>
                <PDFView style={pdfStyle.constraintTitleContainer}>
                    <PDFText style={pdfStyle.constraintTitle}>Notes</PDFText>
                </PDFView>
                <PDFText style={pdfStyle.additionalNotesText}>
                    {formatTextWithoutConsecutiveSpaces(
                        intelligenceScope.additional_notes
                    )}
                </PDFText>
            </PDFView>
        ) : null}
    </PDFView>
);

export default RenderIntelligenceScopeDetails;
