import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";

import StyledButton from "components/atoms/StyledButton";
import { AIEmailAssistantButton } from "components/cdv/AIEmailAssistantButton";
import { formatPhoneNumber, formatShortMonthYear } from "constants/formatters";
import { ShallowContactRead, InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import { useModalStore, ModalNames } from "stores/zustandStore";

import { ContactCardRow } from "../ContactCardRow";

const cardStyles = makeStyles((theme) => ({
    card: {
        marginBottom: 12,
        width: "100%",
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    cardHeader: {
        display: "flex",
        borderBottom: `1px ${theme.palette.grey[300]} solid`,
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2, 1, 2),
    },
    editIcon: {
        color: theme.palette.grey[400],
        height: 16,
        width: 16,
    },
    name: {
        flexGrow: 1,
    },
}));

export const ContactCard = ({
    contact,
    isEditModeActive,
    insight,
    opportunityId,
    setAIEmailAssistantInputModalVisibility,
    setAIEmailAssistantInputModalContactId,
}: {
    contact: ShallowContactRead;
    isEditModeActive: boolean;
    insight: InsightInOpportunity;
    opportunityId: number;
    setAIEmailAssistantInputModalVisibility: Function;
    setAIEmailAssistantInputModalContactId: Function;
}) => {
    const styles = cardStyles();
    const { search } = useLocation();
    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? Number(teamIdString) : undefined;

    const setModalVisible = useModalStore((state) => state.setModalVisible);

    return (
        <Card className={styles.card}>
            <CardContent className={styles.cardContent}>
                <div className={styles.cardHeader}>
                    <Typography className={styles.name}>
                        {contact.first_name} {contact.last_name}{" "}
                    </Typography>
                    <AIEmailAssistantButton
                        teamId={teamId}
                        text="Email"
                        onClick={() => {
                            setAIEmailAssistantInputModalVisibility(true);
                            setAIEmailAssistantInputModalContactId(contact.id);
                        }}
                    />
                    {isEditModeActive && (
                        <StyledButton
                            aria-label={`edit ${contact.first_name} ${contact.last_name}`}
                            data-testid={`edit ${contact.whid}`}
                            variant="edit-secondary"
                            handleClick={() => {
                                setModalVisible(ModalNames.EditContact, {
                                    contact: contact,
                                    insight: insight,
                                    opportunityId: opportunityId,
                                });
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Edit
                        </StyledButton>
                    )}
                </div>
                <ContactCardRow
                    label="Company"
                    value={
                        contact.company && contact.company.name
                            ? contact.company.name
                            : contact.owner && contact.owner.legal_name
                            ? contact.owner.legal_name
                            : "Unknown"
                    }
                />
                <ContactCardRow label="Position" value={contact.position} />
                <ContactCardRow
                    label="Phone Number"
                    value={formatPhoneNumber(contact.phone_primary)}
                />
                <ContactCardRow
                    label="Secondary Phone Number"
                    value={formatPhoneNumber(contact.phone_secondary)}
                />
                <ContactCardRow label="Email" value={contact.email} />
                <ContactCardRow
                    label="Alternate Email"
                    value={contact.email_alternate}
                />
                <ContactCardRow
                    label="LinkedIn"
                    value={contact.linkedin}
                    contactName={contact.first_name + " " + contact.last_name}
                />
                {isEditModeActive ? (
                    <ContactCardRow
                        label="Last Manual Verification Date"
                        value={formatShortMonthYear(
                            contact.last_manual_verification_date
                        )}
                    />
                ) : null}
            </CardContent>
        </Card>
    );
};
