import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { IntelligenceScope } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderConstraints from "../RenderConstraints";

const RenderOtherConstraints = (intelligenceScope: IntelligenceScope) => (
    <PDFView
        style={pdfStyle.constraintContainer}
        key={intelligenceScope.id}
        wrap={false}
    >
        <PDFView style={pdfStyle.constraintTitleContainer}>
            <PDFText style={pdfStyle.constraintTitle}>Others</PDFText>
        </PDFView>
        {RenderConstraints({
            constraints: intelligenceScope.mgd_constraints,
            name: "Facility Size",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.project_budget_constraints,
            name: "Opportunity Value",
        })}
        {RenderConstraints({
            constraints: intelligenceScope.source_timeframe_constraints,
            name: "Source Document Date",
        })}
    </PDFView>
);

export default RenderOtherConstraints;
