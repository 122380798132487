import { Form, Select } from "antd";

const RankSelect = ({}) => {
    return (
        <Form.Item name="rank" label="Rank">
            <Select
                placeholder="Select a rank"
                options={[
                    { value: "Feeds", label: "Feeds" },
                    { value: "Priority - High", label: "Priority - High" },
                    { value: "Priority - Medium", label: "Priority - Medium" },
                    { value: "Priority - Low", label: "Priority - Low" },
                ]}
            />
        </Form.Item>
    );
};

export default RankSelect;
