import {
    useQuery,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";

import {
    nautilusGetRequest,
    nautilusPatchRequest,
    nautilusPostRequest,
    nautilusDeleteRequest,
} from "network";
import { actionsTakenKeys, opportunityKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";

import {
    OpportunityActionsTakenRead,
    OpportunityActionsTakenWrite,
    PatchedOpportunityActionsTakenWrite,
} from "./apiTypes";

const postActionsTaken = async (requestData: OpportunityActionsTakenWrite) => {
    const { data } = await nautilusPostRequest(
        "/api/target_reports/actions_taken/",
        requestData
    );
    return data;
};
export const usePostActionsTaken = () => {
    const queryClient = useQueryClient();
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    return useMutation(
        (requestData: OpportunityActionsTakenWrite) => {
            return postActionsTaken(requestData);
        },
        {
            onSuccess: (responseData) => {
                displaySuccessSnack({ message: "Activity has been logged" });
                queryClient.invalidateQueries(
                    actionsTakenKeys.filter({
                        opportunityId: responseData.opportunity,
                        teamId: responseData.team,
                    })
                );
            },
            onError: () => {
                displayErrorSnack({ message: "Failed to add action" });
            },
        }
    );
};

const getFilteredActionsTaken = async ({
    opportunityId,
    teamId,
}: {
    opportunityId: number;
    teamId: number;
}) => {
    let queryString = `?opportunity=${opportunityId}`;
    if (teamId) queryString += `&team=${teamId}`;

    return (await nautilusGetRequest("/api/target_reports/actions_taken/", queryString))
        .data;
};

export function useGetFilteredActionsTaken({
    opportunityId,
    teamId,
}: {
    opportunityId: number;
    teamId: number;
}) {
    const queryKey = actionsTakenKeys.filter({
        opportunityId: opportunityId,
        teamId: teamId,
    });
    return useQuery({
        queryKey,
        queryFn: () => getFilteredActionsTaken({ opportunityId, teamId }),
    });
}

const patchActionsTaken = async (
    action_taken: OpportunityActionsTakenRead,
    id: number
): Promise<PatchedOpportunityActionsTakenWrite> => {
    return (
        await nautilusPatchRequest(
            `/api/target_reports/actions_taken/${id}`,
            action_taken
        )
    ).data;
};

export function usePatchActionsTaken(): UseMutationResult<PatchedOpportunityActionsTakenWrite> {
    const queryClient = useQueryClient();
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    return useMutation(
        (
            actions_taken: OpportunityActionsTakenRead
        ): Promise<PatchedOpportunityActionsTakenWrite> => {
            const { id } = actions_taken;
            return patchActionsTaken(actions_taken, id);
        },
        {
            onSuccess: (responseData) => {
                displaySuccessSnack({
                    message: "Logged activity has been updated",
                });
                queryClient.invalidateQueries(
                    actionsTakenKeys.filter({
                        opportunityId: responseData.opportunity,
                        teamId: responseData.team,
                    })
                );
            },

            onError: () => {
                displayErrorSnack({
                    message: "Failed to update logged activity",
                });
            },
        }
    );
}

const removeActionsTaken = async (id: number) => {
    return (await nautilusDeleteRequest(`/api/target_reports/actions_taken/${id}`))
        .data;
};

export function useRemoveActionsTaken() {
    const queryClient = useQueryClient();
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    return useMutation(
        (variables: { id: number; opportunityId: number; teamId: number }) => {
            return removeActionsTaken(variables.id);
        },
        {
            onMutate: (variables: {
                id: number;
                opportunityId: number;
                teamId: number;
            }) => {
                return {
                    opportunityId: variables.opportunityId,
                    teamId: variables.teamId,
                };
            },
            onSuccess: (data, variables, context) => {
                displaySuccessSnack({
                    message: "Logged activity has been removed",
                });
                queryClient.invalidateQueries(
                    actionsTakenKeys.filter({
                        opportunityId: context.opportunityId,
                        teamId: context.teamId,
                    })
                );
            },
            onError: () => {
                displayErrorSnack({
                    message: "Failed to delete logged activity",
                });
            },
        }
    );
}
