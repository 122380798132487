import { Theme, createStyles } from "@material-ui/core";

import { additionalColors } from "./colors";

export const authFormTheme = (theme: Theme) =>
    createStyles({
        fullWidth: {
            width: "100%",
        },
        loadingSpinner: {
            color: additionalColors.white,
            marginLeft: "1em",
        },
        root: {
            width: "100%",
        },
        textField: {
            margin: theme.spacing(1, 0),
            width: "100%",
        },
        helperText: {
            marginLeft: 0,
        },
    });

export const authPageTheme = (theme: Theme) =>
    createStyles({
        actionLinks: {
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            margin: theme.spacing(1, 0),
        },
        button: {
            margin: theme.spacing(1, 0),
            width: "100%",
        },
        container: {
            marginTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        descriptionText: {
            margin: theme.spacing(2, 0),
        },
        copyright: {
            marginTop: theme.spacing(2),
        },
        infoText: {
            paddingBottom: "2vh",
        },
        logo: {
            height: "50px",
            width: "285px",
            marginBottom: theme.spacing(1),
        },
        title: {
            margin: theme.spacing(2, 0),
        },
    });
