import "fonts/fonts.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { QueryClientProvider } from "@tanstack/react-query";
import { ConnectedRouter } from "connected-react-router";
import { Provider as ReduxProvider } from "react-redux";

import { SnackbarProviderWrapper } from "components/molecules/SnackBarProviderWrapper";
import SnackMiddleman from "components/nautilus/SnackMiddleman";
import ZustandSnackMiddleman from "components/nautilus/ZustandSnackMiddleman";
import { ThemeProviderWrapper } from "components/shared/ThemeProviderWrapper";
import initTracking from "network/tracking";
import appQueryClient from "reactQuery/client";
import configureStore, { history } from "stores/store";
import UnconnectedApp from "UnconnectedApp";

const store = configureStore();

initTracking(history);

function ConnectedApp() {
    return (
        <ThemeProviderWrapper>
            <SnackbarProviderWrapper>
                <QueryClientProvider client={appQueryClient()}>
                    {/* @ts-ignore */}
                    <ReduxProvider store={store}>
                        <ZustandSnackMiddleman />
                        <SnackMiddleman />
                        <CssBaseline />
                        {/* @ts-ignore */}
                        <ConnectedRouter history={history}>
                            <UnconnectedApp />
                        </ConnectedRouter>
                    </ReduxProvider>
                </QueryClientProvider>
            </SnackbarProviderWrapper>
        </ThemeProviderWrapper>
    );
}

export default ConnectedApp;
