import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";

import StyledButton from "components/atoms/StyledButton";
import { additionalColors } from "constants/colors";
import { DeepInsight, InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import { useModalStore, ModalNames } from "stores/zustandStore";

const cardStyles = makeStyles<Theme>((theme) => ({
    button: {
        marginTop: 12,
    },
    card: {
        width: "85%",
        height: "auto",
        marginLeft: "5%",
        marginBottom: 32,
    },
    editButton: {
        border: "2px solid",
        color: additionalColors.grey999,
        marginTop: theme.spacing(1),
    },
    text: {
        color: additionalColors.grey999,
        marginTop: theme.spacing(1),
    },
}));

interface TimelineStylesProps {
    hasTimeline: boolean;
    isRelated: boolean;
}

const timelineStyles = makeStyles<Theme, TimelineStylesProps>(() => ({
    connector: ({ isRelated }) => ({
        backgroundColor: isRelated
            ? additionalColors.green500
            : additionalColors.blue500Primary,
        width: "4px",
    }),
    dot: ({ isRelated }) => ({
        backgroundColor: isRelated
            ? additionalColors.green500
            : additionalColors.blue500Primary,
        height: "20px",
        width: "20px",
    }),
    loadingConnector: {
        backgroundColor: additionalColors.grey500,
        width: "4px",
    },
    loadingDot: {
        backgroundColor: additionalColors.grey500,
        height: "20px",
        width: "20px",
    },
    timeline: ({ hasTimeline }) => ({
        height: hasTimeline ? "55%" : "100%",
        marginTop: "-20px",
    }),
    timelineItem: {
        marginLeft: "45%",
        display: "flex",
        height: "100%",
    },
}));

export interface TimelineCardProps {
    formattedDisplayDate: string;
    hasTimeline: boolean;
    insight: DeepInsight;
    isEditModeActive: boolean;
}
const TimelineCard = ({
    formattedDisplayDate,
    hasTimeline,
    insight,
    isEditModeActive,
}: TimelineCardProps) => {
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const { stage, status } = insight;
    const styles = cardStyles();
    const addTimelineBtn = isEditModeActive ? (
        <StyledButton
            className={styles.button}
            color="primary"
            onClick={() => {
                setModalVisible(ModalNames.AddTimeline, { insight: insight });
            }}
            variant="contained"
        >
            Add Timeline
        </StyledButton>
    ) : null;

    const editTimelineBtn = isEditModeActive ? (
        <StyledButton
            className={styles.editButton}
            onClick={() => {
                setModalVisible(ModalNames.EditTimeline, { insight: insight });
            }}
            variant="outlined"
        >
            Edit
        </StyledButton>
    ) : null;

    return hasTimeline ? (
        <Card className={styles.card}>
            <CardContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid data-testid={`display-date-unique`} item>
                        {formattedDisplayDate}
                    </Grid>
                    <Typography className={styles.text} align="center">
                        {stage} - {status}
                    </Typography>
                    {editTimelineBtn}
                </Grid>
            </CardContent>
        </Card>
    ) : (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>{formattedDisplayDate}</Grid>
            <Grid item>{addTimelineBtn}</Grid>
        </Grid>
    );
};

export interface InsightTimelineProps {
    formattedDisplayDate: string;
    insight: DeepInsight;
    index: number;
    isLoading: boolean;
    isEditModeActive: boolean;
    isNotMobileScreen: boolean;
}
export const InsightTimeline = ({
    formattedDisplayDate,
    insight,
    index,
    isLoading,
    isEditModeActive,
    isNotMobileScreen,
}: InsightTimelineProps) => {
    const { stage, status } = insight;
    const isRelated = index === 0;
    const hasTimeline = Boolean(stage && status);

    const styles = timelineStyles({ isRelated, hasTimeline });
    return (
        <>
            <TimelineCard
                formattedDisplayDate={formattedDisplayDate}
                isEditModeActive={isEditModeActive}
                hasTimeline={hasTimeline}
                insight={insight}
            />
            {isNotMobileScreen ? (
                <Timeline align="right" className={styles.timeline}>
                    {hasTimeline && (
                        <TimelineItem
                            classes={{ missingOppositeContent: styles.timelineItem }}
                        >
                            {isNotMobileScreen ? (
                                <TimelineSeparator>
                                    <TimelineDot
                                        className={
                                            isLoading ? styles.loadingDot : styles.dot
                                        }
                                        data-testid={`tl-dot-${index}`}
                                    />
                                    <TimelineConnector
                                        className={
                                            isLoading
                                                ? styles.loadingConnector
                                                : styles.connector
                                        }
                                    />
                                </TimelineSeparator>
                            ) : null}
                        </TimelineItem>
                    )}
                </Timeline>
            ) : null}
        </>
    );
};

export default InsightTimeline;
