import { useQuery } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { reportKeys } from "reactQuery/keys";

import { PaginatedShallowReportReadList, DeepReportRead } from "./apiTypes";

async function getAllReports(teamId: number): Promise<PaginatedShallowReportReadList> {
    const queryString = `?team=${teamId}&limit=999999999`;
    const { data } = await nautilusGetRequest(
        "/api/target_reports/reports/",
        queryString
    );
    return data;
}

export function useGetAllReports(teamId: number) {
    return useQuery({
        queryKey: reportKeys.team_reports(teamId),
        queryFn: (): Promise<PaginatedShallowReportReadList> => getAllReports(teamId),
    });
}

export function useGetReport(reportId: number, enabled: boolean) {
    return useQuery({
        queryKey: reportKeys.team_reports(reportId),
        queryFn: async (): Promise<DeepReportRead> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/reports/${reportId}`
            );
            return data;
        },
        enabled,
    });
}
