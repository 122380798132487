import {
    AuditOutlined,
    CheckCircleOutlined,
    DiffOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

export const CRM_SYNC_COMPLETED = "Completed";
export const QUEUED_FOR_CRM_SYNC = "Queued";
export const CRM_SYNC_FAILED = "Failed";

const StatusTag = ({ status }: { status: string }) => {
    const style = {
        marginRight: 0,
    };

    let icon: any,
        color: string,
        message: string,
        testId: string = status;

    switch (status) {
        case "Delivered":
            color = "success";
            icon = <CheckCircleOutlined />;
            message = "Delivered";
            break;
        case "In Progress":
            color = "default";
            icon = <EditOutlined />;
            message = "In Progress";
            break;
        case "Peer-reviewed":
            color = "processing";
            icon = <DiffOutlined />;
            message = "Peer Reviewed";
            break;
        case "ready":
            color = "success";
            icon = <CheckCircleOutlined />;
            message = "Ready";
            break;
        case "review_required":
            color = "processing";
            icon = <DiffOutlined />;
            message = "Review Required";
            break;
        case "freelancer_submitted":
            color = "warning";
            icon = <UserOutlined />;
            message = "Freelancer Submitted";
            break;
        case "revisions_needed":
            color = "error";
            icon = <AuditOutlined />;
            message = "Revisions Needed";
            break;
        case "Self-reviewed":
            color = "processing";
            icon = <DiffOutlined />;
            message = "Self Reviewed";
            break;
        case "wip":
            color = "default";
            icon = <EditOutlined />;
            message = "Work in Progress";
            break;
        case CRM_SYNC_FAILED:
        case QUEUED_FOR_CRM_SYNC:
            color = "processing";
            icon = <FileAddOutlined />;
            message = "Queued for Sync to CRM";
            break;
        case CRM_SYNC_COMPLETED:
            color = "success";
            icon = <FileDoneOutlined />;
            message = "Synced to CRM";
            break;
        default:
            icon = <ExclamationCircleOutlined />;
            color = "error";
            message = "Unknown";
            testId = "Unknown";
    }

    return (
        <Tag data-testid={testId} color={color} icon={icon} style={style}>
            {message}
        </Tag>
    );
};

export default StatusTag;
