import { useEffect } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";

import AllResults from "pages/LWFeeds/AllResults";
import MyActioned from "pages/LWFeeds/MyActioned";
import MyQueue from "pages/LWFeeds/MyQueue";
import ReadyForQC from "pages/LWFeeds/ReadyForQC";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";

export const LWFeedsBasePathname = "/lw";

export const LWAllResultsPathname = `${LWFeedsBasePathname}/all_results`;
export const LWMyActionedPathname = `${LWFeedsBasePathname}/my_actioned`;
export const LWMyQueuePathname = `${LWFeedsBasePathname}/my_queue`;
export const LWReadyForQCPathname = `${LWFeedsBasePathname}/ready_for_qc`;

const LWRoutes = () => {
    const getWhoamiQuery = useGetWhoami();
    const { push } = useHistory();

    useEffect(() => {
        if (!getWhoamiQuery.isSuccess) return;

        if (
            !getWhoamiQuery.data.user.is_staff &&
            !getWhoamiQuery.data.user.is_datacore
        ) {
            push("/product-not-found"); // Redirect to product-not-found page if the team does not have document compare
        }
    }, [getWhoamiQuery.isSuccess]);

    return (
        <Switch>
            <Route exact path={LWAllResultsPathname}>
                <AllResults />
            </Route>

            <Route exact path={LWMyActionedPathname}>
                <MyActioned />
            </Route>

            <Route exact path={LWMyQueuePathname}>
                <MyQueue />
            </Route>

            <Route exact path={LWReadyForQCPathname}>
                <ReadyForQC />
            </Route>

            <Route exact path={LWFeedsBasePathname}>
                <p>Labelling Workflow Landing Page</p>
            </Route>

            <Route path="*">
                <Redirect to={LWFeedsBasePathname} />
            </Route>
        </Switch>
    );
};

export default LWRoutes;
