import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import StyledButton from "components/atoms/StyledButton";
import { ownerStyles } from "components/cdv/OpportunityCard/styles";
import { OpportunityValueFactoid } from "components/cdv/OpportunityValue/Factoid";
import { AllOwnersSingleOwnerPathname } from "routes/IntelligenceFeeds";
import { overlayNames } from "stores/uiStore/constants";
import { isIntelligenceFeedsRoute } from "utils/redirect";

import { buttonTestIds } from "..";

const OpportunityOwnerInfo = ({
    isEditModeActive,
    isStaff,
    opportunity,
    ownerPopulation,
    toggleOverlay,
    teamId,
}) => {
    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const styles = ownerStyles();
    const isIntelligenceFeeds = isIntelligenceFeedsRoute();

    const editOppOwnerButton = isEditModeActive ? (
        <StyledButton
            data-testid={buttonTestIds.editOpportunityOwner}
            handleClick={() =>
                toggleOverlay({ overlay: overlayNames.editOpportunityOwnerModal })
            }
            variant="edit-secondary"
        >
            Edit
        </StyledButton>
    ) : null;
    const isFieldUnknown = (field) => {
        return (
            opportunity?.owner?.demographics.length >= 1 &&
            opportunity.owner.demographics[0][field]
        );
    };

    return (
        <>
            <div className={styles.ownerInfo}>
                <Grid container>
                    <Grid item xs={isNotMobileScreen ? 2 : 4}>
                        <span className={styles.ownerLabel}>Opportunity Owner:</span>
                    </Grid>
                    <Grid item xs={9}>
                        {isIntelligenceFeeds ? (
                            <Link
                                to={
                                    AllOwnersSingleOwnerPathname +
                                    "?owner_id=" +
                                    opportunity.owner.id +
                                    "&team_id=" +
                                    teamId
                                }
                            >
                                <span>{`${opportunity?.owner?.legal_name}, ${opportunity?.owner?.state_code}`}</span>
                            </Link>
                        ) : (
                            <span>{`${opportunity?.owner?.legal_name}, ${opportunity?.owner?.state_code}`}</span>
                        )}
                    </Grid>
                    {(isStaff ? isStaff : ownerPopulation) ? (
                        <Grid item xs={isNotMobileScreen ? 2 : 4}>
                            <span className={styles.populationLabel}>Population:</span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : ownerPopulation) ? (
                        <Grid item xs={9}>
                            <span
                                className={styles.populationValue}
                                data-testid="owner-population"
                            >
                                {ownerPopulation
                                    ? ownerPopulation.toLocaleString()
                                    : "Unknown"}
                            </span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("address")) ? (
                        <Grid item xs={isNotMobileScreen ? 2 : 4}>
                            <span className={styles.addressLabel}>Address:</span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("address")) ? (
                        <Grid item xs={9}>
                            <span className={styles.addressValue}>
                                {isFieldUnknown("address")
                                    ? opportunity.owner.demographics[0].address
                                    : "Unknown"}
                            </span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("zip")) ? (
                        <Grid item xs={isNotMobileScreen ? 2 : 4}>
                            <span className={styles.zipCodeLabel}>Zip Code:</span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("zip")) ? (
                        <Grid item xs={9}>
                            <span className={styles.zipCodeValue}>
                                {isFieldUnknown("zip")
                                    ? opportunity.owner.demographics[0].zip
                                    : "Unknown"}
                            </span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("county")) ? (
                        <Grid item xs={isNotMobileScreen ? 2 : 4}>
                            <span className={styles.countyLabel}>County:</span>
                        </Grid>
                    ) : null}
                    {(isStaff ? isStaff : isFieldUnknown("county")) ? (
                        <Grid item xs={9}>
                            <span className={styles.countyValue}>
                                {isFieldUnknown("county")
                                    ? opportunity.owner.demographics[0].county
                                    : "Unknown"}
                            </span>
                        </Grid>
                    ) : null}
                    {isStaff || opportunity.owner.fiscal ? (
                        <>
                            <Grid item xs={isNotMobileScreen ? 2 : 4}>
                                <span className={styles.fiscalLabel}>
                                    Fiscal Year Start Date:
                                </span>
                            </Grid>
                            <Grid item xs={9}>
                                <span className={styles.fiscalValue}>
                                    {opportunity.owner.fiscal
                                        ? opportunity.owner.fiscal
                                        : "Unknown"}
                                </span>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
                <Grid item xs={2}>
                    <div>{editOppOwnerButton}</div>
                </Grid>
            </div>
            <div className={styles.opportunityValue}>
                <OpportunityValueFactoid
                    opportunity={opportunity}
                    isStaff={isStaff}
                    styles={styles}
                    isEditModeActive={isEditModeActive}
                />
            </div>
        </>
    );
};

export default OpportunityOwnerInfo;
