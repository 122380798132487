import { useLocation } from "react-router-dom";

import { BreadcrumbProps } from "components/molecules/Breadcrumbs";
import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { AllInsightsPathname } from "routes/IntelligenceFeeds";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    const teamId = teamIdString ? Number(teamIdString) : undefined;

    return { teamId };
};

export const allInsightsBreadcrumbs = () => {
    const { teamId } = pageParams();

    const breadcrumbs: BreadcrumbProps[] = [
        { name: "Intelligence Feeds" },
        { name: "All Feeds" },
        { name: "All Insights", url: `${AllInsightsPathname}?team_id=${teamId}` },
    ];

    return breadcrumbs;
};

const AllInsights = () => {
    const { teamId } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title="All Insights"
                    breadcrumbs={allInsightsBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.ALL_TEAM_INSIGHTS}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default AllInsights;
