import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Button } from "antd";

import { formatDayHyphenMonthHyphenYear } from "constants/formatters";
import { openNewInternalLink } from "constants/navigation";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { usePatchInsight } from "reactQuery/hooks/useInsights";
import { opportunityKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore, ModalNames } from "stores/zustandStore";

const EditRemoveContactModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const setModalHidden = useModalStore((state) => state.setModalHidden);

    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    const patchInsightQuery = usePatchInsight();
    const queryClient = useQueryClient();

    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user.is_staff ?? false;

    const isRefetchingOpportunity = Boolean(
        useIsFetching({
            queryKey: opportunityKeys.single_opportunity(
                modalInitialValues?.opportunityId
            ),
        })
    );

    const onModalCancel = () => {
        setModalHidden();
    };

    const onOpenRemoveModal = () => {
        setModalVisible(ModalNames.RemoveContact, {
            insight: modalInitialValues.insight,
            contact: modalInitialValues.contact,
            opportunityId: modalInitialValues.opportunityId,
        });
    };

    const onOpenEditModal = () => {
        setModalVisible(ModalNames.EditContact, {
            insight: modalInitialValues.insight,
            contact: modalInitialValues.contact,
            opportunityId: modalInitialValues.opportunityId,
        });
    };

    const onModalRemove = () => {
        const contactsWithoutSelected = modalInitialValues.insight.contacts
            .filter((contact) => contact.id !== modalInitialValues.contact.id)
            .map((contact) => contact.id);

        const payload = {
            contacts: contactsWithoutSelected,
        };
        patchInsightQuery.mutate(
            { payload: payload, insightId: modalInitialValues.insight.id },
            {
                onSuccess: () => {
                    queryClient
                        .invalidateQueries(
                            opportunityKeys.single_opportunity(
                                modalInitialValues.opportunityId
                            )
                        )
                        .then(() => {
                            setModalHidden();
                            displaySuccessSnack({
                                message: "Contact removed successfully",
                            });
                        });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({
                        message: "Error removing contact",
                    });
                },
            }
        );
    };

    return (
        <Modal
            open={
                openModal === ModalNames.EditContact ||
                openModal === ModalNames.RemoveContact
            }
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={null}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                {openModal === ModalNames.RemoveContact ? (
                    <Typography.Title
                        level={5}
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                    >
                        Are you sure that you would like to remove this contact from
                        this insight? Once removed, this action cannot be reversed.
                    </Typography.Title>
                ) : null}

                {openModal === ModalNames.EditContact ? (
                    <Typography.Title
                        level={3}
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                    >
                        Edit Contact
                    </Typography.Title>
                ) : null}

                <Typography>
                    <b>Insight:</b>{" "}
                    {formatDayHyphenMonthHyphenYear(
                        modalInitialValues?.insight?.display_date ??
                            modalInitialValues?.insight?.report?.date
                    )}
                </Typography>
                <Typography>
                    <b>Contact:</b> {modalInitialValues?.contact?.first_name}{" "}
                    {modalInitialValues?.contact?.last_name}
                </Typography>
                <Typography>
                    <b>Company:</b>{" "}
                    {modalInitialValues?.contact?.company?.name
                        ? modalInitialValues?.contact?.company.name
                        : modalInitialValues?.contact?.owner &&
                          modalInitialValues?.contact?.owner.legal_name
                        ? modalInitialValues?.contact?.owner.legal_name
                        : "Unknown"}
                </Typography>
                <Typography>
                    <b>Position:</b> {modalInitialValues?.contact?.position}
                </Typography>
                <Typography>
                    <b>Phone Number:</b> {modalInitialValues?.contact?.phone_primary}
                </Typography>
                <Typography>
                    <b>Email:</b> {modalInitialValues?.contact?.email}
                </Typography>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        gap: "10px",
                    }}
                >
                    {openModal === ModalNames.RemoveContact ? (
                        <>
                            <Button
                                key="remove"
                                type="default"
                                onClick={onOpenEditModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="link"
                                type="primary"
                                htmlType="submit"
                                onClick={onModalRemove}
                                loading={
                                    patchInsightQuery.isLoading ||
                                    isRefetchingOpportunity
                                }
                            >
                                Submit
                            </Button>{" "}
                        </>
                    ) : (
                        <>
                            <Button
                                key="remove"
                                type="default"
                                onClick={onOpenRemoveModal}
                            >
                                Remove
                            </Button>

                            {isStaff ? (
                                <Button
                                    key="link"
                                    type="primary"
                                    onClick={() => {
                                        openNewInternalLink(
                                            `/admin/universe/contact/${modalInitialValues?.contact?.id}/change/`,
                                            false
                                        );
                                        setModalHidden();
                                    }}
                                >
                                    Edit Contact Details
                                </Button>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default EditRemoveContactModal;
