import { useLocation } from "react-router-dom";

import SidebarLayout from "components/organisms/SidebarLayout";
import AllFeeds from "pages/AllFeeds";

const IndicatorFeedsAllFeeds = () => {
    const { search } = useLocation();

    const teamId = new URLSearchParams(search).get("team_id");
    const teamIdFromUrl = teamId ? parseInt(teamId) : null;

    return <SidebarLayout content={<AllFeeds teamId={teamIdFromUrl} />} />;
};

export default IndicatorFeedsAllFeeds;
