import { useLocation } from "react-router-dom";

import PaginatedTable from "components/organisms/PaginatedTable";
import SidebarLayout from "components/organisms/SidebarLayout";
import { FEED_TYPES } from "constants/feedTypes";
import { useGetIndicatorById } from "reactQuery/hooks/useIndicators";
import { AllIndicatorsSingleIndicatorPathname } from "routes/IntelligenceFeeds";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";
import { allIndicatorsBreadcrumbs } from "../AllIndicators";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");
    let indicatorIdString = new URLSearchParams(search).get("indicator_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;
    const indicatorId = indicatorIdString ? Number(indicatorIdString) : undefined;

    const indicatorQuery = useGetIndicatorById(indicatorId, !!indicatorId);
    const indicatorData = indicatorQuery.data || null;

    const indicatorName =
        (indicatorData?.industry || indicatorData?.value) &&
        Object.keys(indicatorData)
            .filter((key) => ["industry", "value"].includes(key) && indicatorData[key])
            .map((key) => indicatorData[key])
            .join(" - ");

    const queryParams = queryParamBuilder({
        excludeParams: [
            "feedback",
            "indicator_id",
            "opportunity_id",
            "owner_id",
            "user_id",
        ],
        includeBaseParams: true,
        includeExistingParams: false,
        startChar: "&",
    });

    return { indicatorId, indicatorName, queryParams, teamId };
};

export const singleIndicatorBreadcrumbs = () => {
    const { indicatorId, indicatorName, queryParams } = pageParams();

    const breadcrumbs = allIndicatorsBreadcrumbs().concat({
        name: indicatorName,
        url: `${AllIndicatorsSingleIndicatorPathname}?indicator_id=${indicatorId}${queryParams}`,
    });

    return breadcrumbs;
};

const SingleIndicator = () => {
    const { teamId, indicatorName } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title={indicatorName}
                    breadcrumbs={singleIndicatorBreadcrumbs()}
                >
                    <PaginatedTable
                        feedType={FEED_TYPES.SINGLE_INDICATOR}
                        teamId={teamId}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default SingleIndicator;
