import {
    StyledCheckBox,
    useStyles,
} from "../../molecules/OpportunityFeedback/styles.js";

export const testIds = {
    addToCrm: "add to crm",
};

const AddToCRM = ({
    currentFeedback,
    allowFeedback,
    handleFeedbackClicked,
}: {
    currentFeedback: any;
    allowFeedback: boolean;
    handleFeedbackClicked: any;
}) => {
    return (
        <>
            <span style={{ marginRight: "5px" }}>Add to CRM</span>
            <StyledCheckBox
                checked={currentFeedback?.add_to_crm}
                disabled={allowFeedback ? false : true}
                onChange={(event) => handleFeedbackClicked(event, "add_to_crm")}
                data-testid={testIds.addToCrm}
            />
        </>
    );
};

export default AddToCRM;
