import { useLocation } from "react-router-dom";

import { BreadcrumbProps } from "components/molecules/Breadcrumbs";
import DashboardView from "components/organisms/DashboardView";
import SidebarLayout from "components/organisms/SidebarLayout";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { IntelligenceFeedsDashboardPathname } from "routes/IntelligenceFeeds";

import { IntelligenceFeedsTemplate } from "../../../components/templates/IntelligenceFeedsTemplate";

const pageParams = () => {
    const { search } = useLocation();

    let teamIdString = new URLSearchParams(search).get("team_id");

    const teamId = teamIdString ? Number(teamIdString) : undefined;

    return { teamId };
};

export const intelligenceFeedsDashboardBreadcrumbs = () => {
    const { teamId } = pageParams();

    const breadcrumbs: BreadcrumbProps[] = [
        { name: "Intelligence Feeds" },
        {
            name: "Dashboard",
            url: `${IntelligenceFeedsDashboardPathname}?team_id=${teamId}`,
        },
    ];

    return breadcrumbs;
};

const IntelligenceFeedsDashboard = () => {
    const { teamId } = pageParams();

    return (
        <SidebarLayout
            content={
                <IntelligenceFeedsTemplate
                    title="Dashboard"
                    breadcrumbs={intelligenceFeedsDashboardBreadcrumbs()}
                >
                    <DashboardView
                        teamId={teamId}
                        productTypes={[ProductTypeEnum.INTELLIGENCE_FEEDS]}
                    />
                </IntelligenceFeedsTemplate>
            }
        />
    );
};

export default IntelligenceFeedsDashboard;
