import { LinearProgress } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
const cardStyles = makeStyles((theme: any) => ({
    card: {
        marginBottom: 32,
        width: "100%",
        padding: theme.spacing(2),
        paddingBottom: 0,
    },
    cardHeader: {
        display: "flex",
        borderBottom: `1px ${theme.palette.grey[300]} solid`,
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2, 1, 2),
    },
    name: {
        flexGrow: 1,
    },
}));

export const StyledCard = ({ loading = false, maxHeight = null, children }) => {
    const styles = cardStyles();
    return (
        <Card className={styles.card} style={{ maxHeight: maxHeight ?? null }}>
            {loading ? <LinearProgress /> : children}
        </Card>
    );
};

export default StyledCard;
