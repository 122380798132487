import { withRouter } from "react-router-dom";

import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import OpportunityDetails from "pages/OpportunityDetails";

const IndicatorFeedsSingleOpportunity = () => {
    const AuthenticatedOpportunityDetailsPage = withRouter(
        withAuthenticationCheck(OpportunityDetails)
    );

    return <AuthenticatedOpportunityDetailsPage />;
};

export default IndicatorFeedsSingleOpportunity;
