import { Spin } from "antd";
import { useParams, useLocation } from "react-router-dom";

import SidebarLayout from "components/organisms/SidebarLayout";
import { useGetDocumentCompare } from "reactQuery/hooks/useDocumentCompare";

const DocumentCompareViewer = () => {
    const { identifier } = useParams();
    const { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));

    const documentCompareQuery = useGetDocumentCompare(identifier, teamId);

    const loadingContent = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
        >
            <Spin data-testid="spinner" size="large" />
        </div>
    );

    const errorContent = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
        >
            <h1>There was a problem loading this document comparison.</h1>
        </div>
    );

    const successContent = (
        <iframe
            src={documentCompareQuery.data?.signed_viewer_url}
            data-testid={documentCompareQuery.data?.signed_viewer_url}
            allow="clipboard-write"
            style={{
                width: "100%",
                height: "100%",
                border: "none",
            }}
        />
    );

    if (documentCompareQuery.isError) {
        return <SidebarLayout content={errorContent} />;
    }

    if (documentCompareQuery.isLoading) {
        return <SidebarLayout content={loadingContent} />;
    }

    return <SidebarLayout content={successContent} />;
};

export default DocumentCompareViewer;
