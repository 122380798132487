import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { timingStatusesKeys } from "reactQuery/keys";

import { TimingStatuses } from "./apiTypes";

export const useGetAllTimingStatuses = (): Partial<
    UseQueryResult<TimingStatuses[]>
> => {
    const queryKey = timingStatusesKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<TimingStatuses[]> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/timing_statuses`
            );
            return data?.results;
        },
    });
};
