import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Form, Select, Space, Button } from "antd";
import { useEffect, useState } from "react";

import { usePatchInsight } from "reactQuery/hooks/useInsights";
import { useGetAllTimingStatuses } from "reactQuery/hooks/useTimingStatuses";
import { opportunityKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore, ModalNames } from "stores/zustandStore";

export const TimelineModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    const statusOptions = useGetAllTimingStatuses().data || [];
    const stageOptions = statusOptions.reduce((unique, option) => {
        return unique.includes(option.stage) ? unique : [...unique, option.stage];
    }, []);
    const [selectedStage, setSelectedStage] = useState();

    const [formIsComplete, setFormIsComplete] = useState(false);

    const patchInsightQuery = usePatchInsight();
    const queryClient = useQueryClient();

    const [form] = Form.useForm();
    const formId = "timeline-modal-form";

    useEffect(() => {
        if (openModal === ModalNames.EditTimeline) {
            form.setFieldValue("stage", modalInitialValues.insight.stage);
            form.setFieldValue("status", modalInitialValues.insight.status);
            setSelectedStage(modalInitialValues.insight.stage);
        }
    }, [modalInitialValues]);

    const onModalOk = () => {
        if (!formIsComplete) {
            return;
        }
        const payload = {
            timing_status: statusOptions.find((option) => {
                return (
                    option.status === form.getFieldValue("status") &&
                    option.stage === form.getFieldValue("stage")
                );
            })?.id,
        };
        patchInsightQuery.mutate(
            { payload: payload, insightId: modalInitialValues.insight.id },
            {
                onSuccess: () => {
                    queryClient
                        .invalidateQueries(
                            opportunityKeys.single_opportunity(
                                modalInitialValues.insight.opportunity.id
                            )
                        )
                        .then(() => {
                            setModalHidden();
                            displaySuccessSnack({
                                message: "Timeline updated successfully",
                            });
                        });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({
                        message: "Error updating timeline",
                    });
                },
            }
        );
        setSelectedStage(null);
        setFormIsComplete(false);
    };
    const onModalCancel = () => {
        setModalHidden();
        setSelectedStage(null);
        setFormIsComplete(false);
        form.resetFields();
    };

    const onOpenRemoveModal = () => {
        setModalVisible(ModalNames.RemoveTimeline, {
            insight: modalInitialValues.insight,
        });
    };

    const onOpenEditModal = () => {
        setModalVisible(ModalNames.EditTimeline, {
            insight: modalInitialValues.insight,
        });
    };

    const onModalRemove = () => {
        const payload = {
            timing_status: null,
        };
        patchInsightQuery.mutate(
            { payload: payload, insightId: modalInitialValues.insight.id },
            {
                onSuccess: () => {
                    queryClient
                        .invalidateQueries(
                            opportunityKeys.single_opportunity(
                                modalInitialValues.insight.opportunity.id
                            )
                        )
                        .then(() => {
                            setModalHidden();
                            displaySuccessSnack({
                                message: "Timeline removed successfully",
                            });
                        });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({
                        message: "Error removing timeline",
                    });
                },
            }
        );
        setSelectedStage(null);
        setFormIsComplete(false);
    };

    return (
        <Modal
            open={
                openModal === ModalNames.EditTimeline ||
                openModal === ModalNames.AddTimeline ||
                openModal === ModalNames.RemoveTimeline
            }
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={null}
        >
            {openModal === ModalNames.RemoveTimeline ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <Typography.Title
                        level={5}
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                    >
                        Are you sure that you would like to remove this timeline from
                        this insight? Once removed, this action cannot be reversed.
                    </Typography.Title>
                    <Typography>
                        <b>Stage:</b> {modalInitialValues.insight.stage}
                    </Typography>
                    <Typography>
                        <b>Status:</b> {modalInitialValues.insight.status}
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            gap: "10px",
                        }}
                    >
                        <Button key="remove" type="default" onClick={onOpenEditModal}>
                            Cancel
                        </Button>

                        <Button
                            key="link"
                            type="primary"
                            htmlType="submit"
                            onClick={onModalRemove}
                            loading={patchInsightQuery.isLoading}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            ) : (
                <Form
                    id={formId}
                    form={form}
                    name={formId}
                    layout="vertical"
                    style={{ width: "80%", margin: "auto" }}
                    preserve={false}
                >
                    <div style={{ textAlign: "center", marginBottom: "25px" }}>
                        <Space>
                            <Typography.Title level={3} style={{ margin: 0 }}>
                                {openModal === ModalNames.AddTimeline
                                    ? "Add Timeline"
                                    : "Edit Timeline"}
                            </Typography.Title>
                        </Space>
                    </div>
                    <Form.Item
                        name="stage"
                        label="Stage"
                        rules={[
                            {
                                required: true,
                                message: "Please select a stage",
                            },
                        ]}
                    >
                        <Select
                            dropdownRender={(menu) => <>{menu}</>}
                            onChange={(value) => {
                                form.setFieldsValue({ status: null });
                                setSelectedStage(value);
                            }}
                        >
                            {stageOptions.map((stage) => (
                                <Select.Option key={stage} value={stage} title={stage}>
                                    {stage}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: "Please select a status",
                            },
                        ]}
                    >
                        <Select
                            dropdownRender={(menu) => <>{menu}</>}
                            disabled={!selectedStage}
                            onChange={(value) => {
                                setFormIsComplete(!!value && selectedStage);
                            }}
                        >
                            {statusOptions.map((option) => {
                                if (option.stage === form.getFieldValue("stage")) {
                                    return (
                                        <Select.Option
                                            key={option?.status}
                                            value={option?.status}
                                            title={option?.status}
                                        >
                                            {option?.status}
                                        </Select.Option>
                                    );
                                }
                                return null;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <div
                            style={{
                                display: "flex",
                                justifyContent:
                                    openModal === ModalNames.EditTimeline
                                        ? "space-between"
                                        : "right",
                                marginTop: "20px",
                            }}
                        >
                            {openModal === ModalNames.EditTimeline ? (
                                <Button
                                    key="remove"
                                    type="default"
                                    onClick={onOpenRemoveModal}
                                >
                                    Remove
                                </Button>
                            ) : null}

                            <div>
                                <Button
                                    key="submit"
                                    type="default"
                                    onClick={onModalCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    key="link"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onModalOk}
                                    style={{ marginLeft: "10px" }}
                                    loading={patchInsightQuery.isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};
