interface lwExpandableRowOptionsProps {
    expandedRowKeys: number[];
    feedType: string;
    viewResultOnClick: any;
}

const lwExpandableRowOptions = ({
    expandedRowKeys,
    feedType,
    viewResultOnClick,
}: lwExpandableRowOptionsProps) => {
    return {
        expandedRowRender: (record: any) => {
            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignContent: "center",
                        gap: "10px",
                        marginLeft: "45px",
                    }}
                >
                    <div>
                        <a onClick={() => viewResultOnClick(record.id)}>View Result</a>
                    </div>
                    <div>
                        <a target="_blank" href={record.url}>
                            Source
                        </a>
                    </div>
                    {record.last_insight_link_details ? (
                        <div>
                            <a
                                target="_blank"
                                href={`/intrpt/opportunity?team_id=${record.last_insight_link_details.team_id}&opportunity_id=${record.last_insight_link_details.opportunity_id}`}
                            >
                                Linked Insight
                            </a>
                        </div>
                    ) : null}
                </div>
            );
        },
        defaultExpandAllRows: true,
        expandedRowKeys: expandedRowKeys,
    };
};

export default lwExpandableRowOptions;
