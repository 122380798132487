import actionTypes from "./actionTypes";

export const userReducerName = "user";
export const initialState = {
    passwordChange: {
        changedSuccessfully: false,
        errors: {},
        isAttemptingToChange: false,
    },
    passwordReset: {
        isSubmitting: false,
        errors: {},
        completed: false,
    },
    signupLinkVerification: {
        isVerifying: false,
        errors: {},
        completed: false,
    },
    login: {
        isAttemptingToLogIn: false,
        providedInvalidCredentials: null,
        retryLoginAfterSeconds: null,
    },
    requestPasswordReset: {
        failedServerValidation: null,
        isAttemptingToReset: false,
        hasSentEmail: false,
        hasUnknownError: false,
        email: null,
    },
    isAuthenticated: null,
    userData: null,
    teams: null,
    profiles: null,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
            };
        case actionTypes.PASSWORD_RESET:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    isSubmitting: true,
                    errors: {},
                    completed: false,
                },
            };
        case actionTypes.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    isSubmitting: false,
                    errors: {},
                    completed: true,
                },
            };
        case actionTypes.PASSWORD_RESET_FAILURE:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    isSubmitting: false,
                    errors: action.payload,
                    completed: false,
                },
            };
        case actionTypes.PASSWORD_CHANGE_CLEAR_ERRORS:
            delete state.passwordChange.errors[action.payload.fieldToClear];
            return {
                ...state,
            };
        case actionTypes.PASSWORD_CHANGE_REQUEST:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    changedSuccessfully: false,
                    errors: {},
                    isAttemptingToChange: true,
                },
            };
        case actionTypes.PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    changedSuccessfully: true,
                    errors: {},
                    isAttemptingToChange: false,
                },
            };

        case actionTypes.PASSWORD_CHANGE_FAILURE:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    changedSuccessfully: false,
                    errors: action.payload,
                    isAttemptingToChange: false,
                },
            };
        case actionTypes.REQUEST_PASSWORD_RESET:
            return {
                ...state,
                requestPasswordReset: {
                    ...state.requestPasswordReset,
                    failedServerValidation: false,
                    isAttemptingToReset: true,
                    email: action.payload.email,
                },
            };
        case actionTypes.REQUEST_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                requestPasswordReset: {
                    ...state.requestPasswordReset,
                    failedServerValidation: false,
                    isAttemptingToReset: false,
                    hasSentEmail: true,
                },
            };
        case actionTypes.REQUEST_PASSWORD_RESET_FAILURE:
            const hasUnknownError = action.payload.isUnknownError;
            return {
                ...state,
                requestPasswordReset: {
                    ...state.requestPasswordReset,
                    isAttemptingToReset: false,
                    failedServerValidation: !hasUnknownError,
                    hasUnknownError: hasUnknownError,
                },
            };
        case actionTypes.SET_USER_DATA:
            return {
                ...state,
                isAuthenticated: true,
                userData: action.payload,
            };
        case actionTypes.SET_TEAMS_DATA:
            return {
                ...state,
                teams: action.payload,
            };
        case actionTypes.SET_PROFILES_DATA:
            return {
                ...state,
                profiles: action.payload,
            };
        case actionTypes.LOG_IN:
            return {
                ...state,
                login: {
                    ...state.login,
                    isAttemptingToLogIn: true,
                    providedInvalidCredentials: null,
                },
            };
        case actionTypes.LOG_IN_SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    isAttemptingToLogIn: false,
                    providedInvalidCredentials: null,
                    retryLoginAfterSeconds: null,
                },
            };
        case actionTypes.LOG_IN_FAILURE:
            return {
                ...state,
                login: {
                    ...state.login,
                    isAttemptingToLogIn: false,
                    providedInvalidCredentials:
                        action.payload.providedInvalidCredentials,
                    retryLoginAfterSeconds: action.payload.retryLoginAfterSeconds,
                },
            };
        case actionTypes.LOG_OUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                userData: null,
                teams: null,
            };
        case actionTypes.SIGNUP_LINK_VERIFICATION:
            return {
                ...state,
                signupLinkVerification: {
                    ...state.signupLinkVerification,
                    isVerifying: true,
                    errors: {},
                    completed: false,
                },
            };
        case actionTypes.SIGNUP_LINK_VERIFICATION_SUCCESS:
            return {
                ...state,
                signupLinkVerification: {
                    ...state.signupLinkVerification,
                    isVerifying: false,
                    errors: {},
                    completed: true,
                },
            };
        case actionTypes.SIGNUP_LINK_VERIFICATION_FAILURE:
            return {
                ...state,
                signupLinkVerification: {
                    ...state.signupLinkVerification,
                    isVerifying: false,
                    errors: action.payload,
                    completed: false,
                },
            };
        default:
            return state;
    }
};

export default user;
