import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { brandColors } from "constants/colors";
import { compareFieldByAlpha, compareFieldByNumericDesc } from "helpers/comparators";
import {
    fetchSearchRecords,
    setSelectedSearchRecord,
    setSelectedTeamSearchHistory,
    setSelectedUserSearchHistory,
} from "stores/targets/actions";
import {
    isLoadingSearchRecordsSelector,
    queryHistorySelector,
    searchRecordsErrorSelector,
} from "stores/targets/selectors";
import { displayErrorSnack, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import {
    profileDataSelector,
    teamDataSelector,
    userDataSelector,
} from "stores/userStore/selectors";

export const loadingTestId = "loading";

const isDevelopMode =
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
const _ = require("lodash");
const useStyles = makeStyles({
    chip: {
        marginRight: "5px",
        marginBottom: "5px",
    },
    list: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "450px",
    },
    root: {
        width: "400px",
        margin: "auto",
        marginBottom: "10px",
    },
    search: {
        width: "75%",
        marginLeft: "10px",
        marginBottom: "10px",
        marginRight: "10px",
    },
    title: {
        cursor: "pointer",
    },
});

const TagsList = ({ title, tags }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    if (tags.length === 0) return null;

    const expandableTag = !isExpanded ? (
        <Chip
            label={` + ${tags.length - 1} others`}
            clickable
            onClick={() => setIsExpanded(!isExpanded)}
            className={classes.chip}
        />
    ) : (
        <>
            {tags.slice(1).map((tag, index) => {
                return (
                    <Chip
                        key={index}
                        label={tag.value}
                        color="primary"
                        className={classes.chip}
                    />
                );
            })}
            <Chip
                label="Hide"
                clickable
                onClick={() => setIsExpanded(!isExpanded)}
                className={classes.chip}
            />
        </>
    );

    return (
        <>
            <Typography>{title}</Typography>
            {tags.slice(0, 1).map((tag, index) => {
                return (
                    <Chip
                        key={index}
                        label={tag.value}
                        color="primary"
                        className={classes.chip}
                    />
                );
            })}
            {tags.length >= 2 ? expandableTag : null}
        </>
    );
};

export function summarizeText(text) {
    if (text.length > 40) {
        return text.substring(0, 40) + "...";
    }
    return text;
}

const QueryCard = ({
    closeDrawer,
    displayErrorSnack,
    index,
    record,
    setSelectedSearchRecord,
}) => {
    const classes = useStyles();
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const queryText = isTextExpanded ? record.queries : summarizeText(record.queries);
    const isExpandable = record.queries.length > 40;
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={10}>
                        <Typography
                            variant="h5"
                            component="h2"
                            className={isExpandable ? classes.title : ""}
                        >
                            {queryText}
                            {isExpandable && (
                                <IconButton
                                    data-testid={`expand-btn-${index}`}
                                    onClick={() => setIsTextExpanded(!isTextExpanded)}
                                >
                                    {isTextExpanded ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </IconButton>
                            )}
                        </Typography>
                    </Grid>
                    {isDevelopMode && (
                        <Grid item xs={2}>
                            <IconButton
                                data-testid={`favourite-btn-${index}`}
                                onClick={() =>
                                    displayErrorSnack({
                                        message: "Favouriting is not implemented yet",
                                    })
                                }
                            >
                                <StarBorderIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                <TagsList title="Included Tags: " tags={record.included_tags} />
                <TagsList title="Excluded Tags: " tags={record.excluded_tags} />
                <Typography variant="body2" component="p">
                    {record.indices}
                    <br />
                    From: {record.date_range_start}
                    <br />
                    To: {record.date_range_end}
                    <br />
                    Results: {record.number_of_results}
                    <br />
                    Team: {record.team && record.team.name ? record.team.name : "Other"}
                    <br />
                    User:{" "}
                    {record.profile.user && record.profile.user.username
                        ? record.profile.user.username
                        : "Other"}
                </Typography>
                <Typography color="textSecondary">
                    {moment(record.when).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    style={{ float: "right" }}
                    onClick={() => {
                        closeDrawer();
                        setSelectedSearchRecord({
                            selectedSearchRecord: {
                                ...record,
                                included_tags: record.included_tags.map(
                                    (tag) => tag.id
                                ),
                                excluded_tags: record.excluded_tags.map(
                                    (tag) => tag.id
                                ),
                            },
                        });
                    }}
                >
                    Use This Query
                </Button>
            </CardActions>
        </Card>
    );
};

function getFilteredSearchResults(
    selectedTeam,
    selectedUser,
    searchRecords,
    searchTerm
) {
    const loweredSearchTerm = searchTerm.toLowerCase();
    let filteredByTeam = [];
    let filteredByUser = [];
    if (selectedTeam && !selectedTeam.id) {
        filteredByTeam = searchRecords.filter((record) => record && !record.team);
    } else {
        filteredByTeam =
            selectedTeam && selectedTeam.name
                ? searchRecords.filter(
                      (record) =>
                          record &&
                          record.team &&
                          record.team.name
                              .toLowerCase()
                              .includes(selectedTeam.name.toLowerCase())
                  )
                : searchRecords;
    }

    if (selectedUser && !selectedUser.user) {
        filteredByUser = filteredByTeam.filter(
            (record) => record && !record.profile.user.username
        );
    } else {
        filteredByUser =
            selectedUser && selectedUser.user.username
                ? filteredByTeam.filter(
                      (record) =>
                          record &&
                          record.profile.user &&
                          record.profile.user.username
                              .toLowerCase()
                              .includes(selectedUser.user.username.toLowerCase())
                  )
                : filteredByTeam;
    }

    return loweredSearchTerm.length > 0
        ? filteredByUser.filter(
              (record) =>
                  record.queries.toLowerCase().includes(loweredSearchTerm) ||
                  record.indices.toLowerCase().includes(loweredSearchTerm) ||
                  record.included_tags.includes(loweredSearchTerm)
          )
        : filteredByUser;
}

const OTHER_TEAM_ID = 0;

export const UnconnectedQueryHistory = ({
    displayErrorSnack,
    fetchSearchRecords,
    isLoading,
    isVisible,
    searchRecords,
    searchRecordsError,
    setSelectedSearchRecord,
    setSelectedUserSearchHistory,
    setSelectedTeamSearchHistory,
    teams,
    profiles,
    userData,
    toggleOverlay,
}) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);

    const pageSize = 10;
    const quotient = Math.trunc(searchRecords.length / pageSize);
    const maxPages = searchRecords.length % pageSize === 0 ? quotient : quotient + 1;
    useEffect(() => {
        if (!searchRecordsError && selectedUser) {
            fetchSearchRecords({ selectedUser: _.get(selectedUser, ["id"], null) });
        }

        if (!searchRecordsError && selectedTeam) {
            fetchSearchRecords({ selectedTeamId: _.get(selectedTeam, ["id"], null) });
        }

        if (!searchRecordsError && pageIndex !== 0 && pageIndex + 1 >= maxPages) {
            fetchSearchRecords({ offset: searchRecords.length });
        }
    }, [
        fetchSearchRecords,
        maxPages,
        pageIndex,
        searchRecordsError,
        searchRecords.length,
        selectedUser,
        selectedTeam,
    ]);

    const filteredTeams = teams ? teams.filter((team) => !team.is_deleted) : [];
    const teamOptions = filteredTeams
        ? [
              { id: OTHER_TEAM_ID, name: "Other", whid: "" },
              ...filteredTeams.sort(compareFieldByAlpha()),
          ]
        : [];

    const userOptions = _.filter(profiles, (profile) => {
        return _.get(profile, ["user", "is_staff"], false);
    }).sort(compareFieldByAlpha(["user", "username"]));

    const allResults = getFilteredSearchResults(
        selectedTeam,
        selectedUser,
        searchRecords,
        searchTerm
    ).sort(compareFieldByNumericDesc());
    const results = allResults.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

    const closeDrawer = () => {
        toggleOverlay({ overlay: overlayNames.queryHistory });
        setSelectedUser(null);
    };
    return (
        <Drawer anchor="right" open={isVisible} onClose={closeDrawer}>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <h2>Search History</h2>
                <Grid container direction="row" justifyContent="center">
                    <Autocomplete
                        debug
                        className={classes.search}
                        options={teamOptions}
                        getOptionLabel={(option) => option.name}
                        defaultValue={
                            selectedTeam
                                ? _.find(teamOptions, function (item) {
                                      return item.name === selectedTeam.name;
                                  })
                                : null
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Team"
                                onChange={(e) => setSelectedTeam(e.target.value)}
                            />
                        )}
                        onChange={(e, v) => {
                            setSelectedTeamSearchHistory({ team: v });
                            setSelectedTeam(v);
                        }}
                    />
                    <Autocomplete
                        debug
                        className={classes.search}
                        options={userOptions}
                        getOptionLabel={(option) => option.user.username}
                        defaultValue={
                            selectedUser
                                ? _.find(userOptions, function (item) {
                                      return item.id === selectedUser.id;
                                  })
                                : null
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select User"
                                onChange={(e) => {
                                    setSelectedUser(e.target.value);
                                }}
                                defaultValue={
                                    selectedUser && selectedUser.user
                                        ? selectedUser.user.username
                                        : null
                                }
                            />
                        )}
                        onChange={(e, v) => {
                            setSelectedUserSearchHistory({ user: v });
                            setSelectedUser(v);
                        }}
                    />
                </Grid>

                <FormControlLabel
                    label="Only show my history"
                    control={
                        <Checkbox
                            style={{
                                color: brandColors.darkBlue,
                            }}
                            onChange={(e) => {
                                setSelectedUser(e.target.checked ? userData : null);
                            }}
                        />
                    }
                />
                <Grid container direction="row" justifyContent="center">
                    <TextField
                        className={classes.search}
                        id="search-query"
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <List className={classes.list}>
                {!isLoading ? (
                    results.map((record, index) => {
                        return (
                            <QueryCard
                                closeDrawer={closeDrawer}
                                displayErrorSnack={displayErrorSnack}
                                index={index}
                                key={index}
                                record={record}
                                setSelectedSearchRecord={setSelectedSearchRecord}
                            />
                        );
                    })
                ) : (
                    <CircularProgress data-testid={loadingTestId} />
                )}
            </List>
            <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-end"
            >
                <Typography>
                    {pageIndex * pageSize + 1}-{pageIndex * pageSize + results.length}{" "}
                    of {allResults.length} records
                </Typography>
                <IconButton
                    aria-label="left"
                    data-testid="page-left-btn"
                    disabled={pageIndex === 0}
                    onClick={() => setPageIndex(pageIndex - 1)}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    aria-label="right"
                    data-testid="page-right-btn"
                    disabled={pageIndex + 1 >= maxPages}
                    onClick={() => setPageIndex(pageIndex + 1)}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Grid>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isLoading: isLoadingSearchRecordsSelector(state),
        isVisible: isShowing[overlayNames.queryHistory],
        searchRecords: queryHistorySelector(state),
        searchRecordsError: searchRecordsErrorSelector(state),
        teams: teamDataSelector(state),
        profiles: profileDataSelector(state),
        userData: userDataSelector(state),
    };
};

const ConnectedQueryHistory = connect(mapStateToProps, {
    displayErrorSnack,
    fetchSearchRecords,
    setSelectedSearchRecord,
    setSelectedUserSearchHistory,
    setSelectedTeamSearchHistory,
    toggleOverlay,
})(UnconnectedQueryHistory);

export default ConnectedQueryHistory;
