import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile } from "antd";
import { Button, Form, Upload, Typography } from "antd";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { useLocation, useHistory } from "react-router-dom";

import SidebarLayout from "components/organisms/SidebarLayout";
import { usePostDocumentCompare } from "reactQuery/hooks/useDocumentCompare";
import { DocumentCompareViewerPathname } from "routes/DocumentCompare";

const fileExtensionAcceptList =
    ".doc, .docx, .docm, .rft, .ppt, .pptx, .pptm, .pdf, .txt";

const DocumentCompareUpload = () => {
    const { search } = useLocation();
    const [leftSideDocument, setLeftSideDocument] = useState<UploadFile | null>(null);
    const [rightSideDocument, setRightSideDocument] = useState<UploadFile | null>(null);
    const [submittable, setSubmittable] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const documentCompareMutation = usePostDocumentCompare();
    const { push } = useHistory();

    const onSubmit = () => {
        documentCompareMutation.mutate(
            {
                teamId: String(teamId),
                leftSideDocument: leftSideDocument,
                rightSideDocument: rightSideDocument,
            },
            {
                onSuccess: ({ identifier }) => {
                    const viewerPath = `${generatePath(DocumentCompareViewerPathname, {
                        identifier,
                    })}?team_id=${teamId}`;
                    push(viewerPath);
                },
                onError: ({ response }: AxiosError) => {
                    // handle special case of file size too large which is an nginx error, rather than an error
                    // from our API
                    if (response.status === 413) {
                        setErrorMessage(
                            "File size is too large. Please upload files that are less than 10MB."
                        );
                        return;
                    }
                    // human readable error message is the first part of the error message up to the colon
                    setErrorMessage(response.data.error.split(":")[0]);
                },
            }
        );
    };

    useEffect(() => {
        if (leftSideDocument && rightSideDocument) {
            setSubmittable(true);
        } else {
            setSubmittable(false);
        }
    }, [leftSideDocument, rightSideDocument]);

    const { Dragger } = Upload;

    const uploadForm = (
        <div style={{ marginTop: "60px" }}>
            <Typography.Title
                level={2}
                style={{ textAlign: "center", marginBottom: "20px" }}
            >
                Compare Documents
            </Typography.Title>
            <Form
                name="basic"
                autoComplete="off"
                style={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <div style={{ display: "flex", gap: "16px" }}>
                    <Form.Item valuePropName="fileList">
                        <Dragger
                            name="leftSideDocument"
                            data-testid="leftSideDocument"
                            beforeUpload={() => false}
                            maxCount={1}
                            multiple={false}
                            style={{ padding: "8px" }}
                            accept={fileExtensionAcceptList}
                            onChange={({ file }) => {
                                if (file.status !== "removed") {
                                    setLeftSideDocument(file);
                                }
                            }}
                            onRemove={() => {
                                setLeftSideDocument(null);
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                1. Select or drop an older version file here
                            </p>
                            <p className="ant-upload-hint">
                                (Word, PowerPoint or PDF, max 10MB)
                            </p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item valuePropName="fileList">
                        <Dragger
                            name="rightSideDocument"
                            data-testid="rightSideDocument"
                            beforeUpload={() => false}
                            multiple={false}
                            style={{ padding: "8px" }}
                            accept={fileExtensionAcceptList}
                            maxCount={1}
                            onChange={({ file }) => {
                                if (file.status !== "removed") {
                                    setRightSideDocument(file);
                                }
                            }}
                            onRemove={() => {
                                setRightSideDocument(null);
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                2. Select or drop a newer version file here
                            </p>
                            <p className="ant-upload-hint">
                                (Word, PowerPoint or PDF, max 10MB)
                            </p>
                        </Dragger>
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button
                        onClick={onSubmit}
                        type="primary"
                        htmlType="submit"
                        disabled={!submittable}
                        loading={documentCompareMutation.isLoading}
                    >
                        3. Compare
                    </Button>
                </Form.Item>
                {errorMessage ? (
                    <Typography.Text type="danger">{errorMessage}</Typography.Text>
                ) : null}
            </Form>
        </div>
    );

    return <SidebarLayout content={uploadForm} />;
};

export default DocumentCompareUpload;
