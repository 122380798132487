import { MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export const FormDropdownObjectsField = ({
    selectedOption = {} as any,
    allOptions,
    setSelectedOption,
    disabled = false,
}) => {
    return (
        <FormControl fullWidth>
            <Select
                disabled={disabled}
                variant="outlined"
                name="selectedAction"
                value={selectedOption.value}
                data-testid="dropdown-test-id"
                onChange={(e) => {
                    setSelectedOption(e.target.value as string);
                }}
            >
                {allOptions.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default FormDropdownObjectsField;
