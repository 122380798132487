import DateFnsUtils from "@date-io/date-fns";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { compareAsc } from "date-fns";
import { Form, withFormik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { FormModal } from "components/molecules/Modal";
import HelpPanel from "components/target_surfacer/HelpPanel";
import QueryHistory from "components/target_surfacer/QueryHistory";
import { additionalColors } from "constants/colors";
import { saveFile } from "helpers";
import { compareFieldByAlpha } from "helpers/comparators";
import { isLoadingTagsSelector, tagsSelectors } from "stores/tags/tagSlice";
import {
    clearBatchId,
    createBatch,
    enqueueTargetSurfacerQuery,
    fetchSearchRecords,
    setPreviousQuery,
    setSelectedSearchRecord,
} from "stores/targets/actions";
import {
    enqueuedCountSelector,
    isQueryingSelector,
    pendingQueriesSelector,
    previousQuerySelector,
    selectedSearchRecordSelector,
} from "stores/targets/selectors";
import {
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
    toggleOverlay,
} from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { fetchProfilesData, fetchTeamsData } from "stores/userStore/actionTypes";
import {
    defaultExcludedTagsSelector,
    isStaffSelector,
    profileDataSelector,
    teamDataSelector,
} from "stores/userStore/selectors";

import {
    ERROR_MESSAGES,
    externalUniverses,
    loadingText,
    staffUniverses,
    testIds,
} from "./constants";

export const TargetSurfacerSearchSchema = Yup.object().shape({
    universe: Yup.string().required(ERROR_MESSAGES.universeMissing),
    includedTags: Yup.array().of(Yup.number()),
    excludedTags: Yup.array().of(Yup.number()),
    fromDate: Yup.date(ERROR_MESSAGES.dateInvalid)
        .nullable()
        .required(ERROR_MESSAGES.fromDateMissing),
    toDate: Yup.date()
        .nullable()
        .required(ERROR_MESSAGES.toDateMissing)
        .test("toDate-after-test", ERROR_MESSAGES.toDateLessThan, function (value) {
            const { fromDate } = this.parent;
            return value === null || compareAsc(value, fromDate) !== -1;
        }),
    query: Yup.string().required(ERROR_MESSAGES.queryMissing),
    selectedTeamId: Yup.number().nullable().required(ERROR_MESSAGES.teamMissing),
});

const useStyle = makeStyles((theme) => ({
    fullWidth: {
        width: "100%",
    },
    quarterWidth: {
        width: 250,
        margin: theme.spacing(1),
    },
    eighthWidth: {
        width: 155,
        margin: theme.spacing(1),
    },
    modal: {
        width: "100%",
    },
    errorText: {
        color: "red",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
        color: "grey",
    },
}));

function getSelectedTeam(selectedTeamId, teamOptions) {
    if (selectedTeamId === null) {
        return null;
    }
    return teamOptions.find((teamOption) => teamOption.id === selectedTeamId);
}

const OTHER_TEAM_ID = 0;

function sortTagByTypes(tags, tag_type_name) {
    return tags
        ? tags.filter(
              (tag) =>
                  tag &&
                  tag.tag_type &&
                  tag.tag_type.name &&
                  tag.tag_type.name === tag_type_name
          )
        : [];
}

function sortTagByIds(tag_type_obj, exclude_ids) {
    return tag_type_obj.filter((tag) => exclude_ids.indexOf(tag.id) > -1);
}

const TagsComponent = ({
    tags,
    defaultTags,
    label,
    tag_state,
    tag_state_str,
    setFieldValue,
    classes,
    test_id,
}) => {
    return (
        <>
            <Grid item lg={4} xl={4}>
                <FormControl className={classes.fullWidth} disabled={tags.length === 0}>
                    <Autocomplete
                        multiple
                        data-testid={test_id}
                        options={tags}
                        value={defaultTags}
                        getOptionLabel={(option) => option.value}
                        renderTags={(option, getTagProps) =>
                            option.map((item, index) => (
                                <Tooltip
                                    title={item.value}
                                    placement="bottom"
                                    key={index}
                                >
                                    <Chip
                                        variant="outlined"
                                        label={item.value}
                                        {...getTagProps({ index })}
                                        style={{
                                            backgroundColor: additionalColors.grey300,
                                            border: "none",
                                        }}
                                    />
                                </Tooltip>
                            ))
                        }
                        onChange={(_event, _value, reason, detail) => {
                            if (reason === "select-option") {
                                if (tag_state.indexOf(detail.option.id) === -1) {
                                    tag_state = [...tag_state, detail.option.id];
                                }
                                setFieldValue(tag_state_str, tag_state);
                            } else if (reason === "remove-option") {
                                setFieldValue(
                                    tag_state_str,
                                    tag_state.filter((tag) => tag !== detail.option.id)
                                );
                            } else if (reason === "clear") {
                                const tag_ids = tags.map((tag) => tag.id);
                                setFieldValue(
                                    tag_state_str,
                                    tag_state.filter((tag) => tag_ids.indexOf(tag) < 0)
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label={label} />
                        )}
                    />
                </FormControl>
            </Grid>
        </>
    );
};

export const SearchForm = ({
    errors,
    handleChange,
    isStaff,
    setFieldValue,
    tags,
    teams,
    resetValues,
    setResetValues,
    initialValues,
    values: {
        universe,
        includedTags,
        excludedTags,
        fromDate,
        toDate,
        query,
        selectedTeamId,
    },
}) => {
    const classes = useStyle();
    const universeOptions = isStaff ? staffUniverses : externalUniverses;
    const sortedTags = tags.sort(compareFieldByAlpha(["value"]));
    const teamOptions = teams
        ? [{ id: OTHER_TEAM_ID, name: "Other" }, ...teams.sort(compareFieldByAlpha())]
        : [];

    const selectedTeam = getSelectedTeam(selectedTeamId, teamOptions);
    const stateTags = sortTagByTypes(sortedTags, "State");
    const countyTags = sortTagByTypes(sortedTags, "County");

    const otherTags = sortedTags
        ? sortedTags.filter((tag) =>
              tag.tag_type ? (tag.tag_type ? tag.tag_type.name === "Other" : null) : tag
          )
        : [];

    const defaultIncludedStateTags = sortTagByIds(stateTags, includedTags);
    const defaultIncludedCountyTags = sortTagByIds(countyTags, includedTags);
    const defaultIncludedOtherTags = sortTagByIds(otherTags, includedTags);

    const defaultExcludedStateTags = sortTagByIds(stateTags, excludedTags);
    const defaultExcludedCountyTags = sortTagByIds(countyTags, excludedTags);
    const defaultExcludedOtherTags = sortTagByIds(otherTags, excludedTags);

    const resetValuesToDefault = useCallback(() => {
        var fields = ["fromDate", "query", "selectedTeamId", "toDate", "universe"];
        if (resetValues) {
            fields.forEach((value) => setFieldValue(value, initialValues[value]));
            setFieldValue("includedTags", []);
            setFieldValue("excludedTags", [74, 76, 80, 81, 86, 3272]);
            setResetValues(false);
        }
    }, [resetValues, setResetValues, setFieldValue, initialValues]);

    useEffect(() => {
        resetValuesToDefault();
    }, [resetValues, resetValuesToDefault]);

    return (
        <Form className={classes.fullWidth}>
            <FormControl className={classes.quarterWidth}>
                <InputLabel>Select Search Universe</InputLabel>
                <Select
                    value={universe}
                    defaultValue="us-indices"
                    onChange={(event) => {
                        setFieldValue("universe", event.target.value);
                    }}
                >
                    {universeOptions.map((universe) => (
                        <MenuItem key={universe.value} value={universe.value}>
                            {universe.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl
                className={classes.quarterWidth}
                error={"selectedTeamId" in errors}
            >
                <Autocomplete
                    options={teamOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                        setFieldValue("selectedTeamId", v ? v.id : "");
                    }}
                    value={selectedTeam}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Team" />
                    )}
                />
                <FormHelperText>{errors.selectedTeamId}</FormHelperText>
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"fromDate" in errors}
                    className={classes.eighthWidth}
                >
                    <InputLabel shrink>Search From Date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="from-date-picker-inline"
                        inputProps={{ "data-testid": testIds.from }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="fromDate"
                        onChange={(e) => setFieldValue("fromDate", e)}
                        value={fromDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid={testIds.fromError}>
                        {errors.fromDate &&
                        errors.fromDate === ERROR_MESSAGES.fromDateMissing
                            ? ERROR_MESSAGES.fromDateMissing
                            : null}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                    id="date-form-control"
                    error={"toDate" in errors}
                    className={classes.eighthWidth}
                >
                    <InputLabel shrink>Search To Date</InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        format="yyyy-MM-dd"
                        id="to-date-picker-inline"
                        inputProps={{ "data-testid": testIds.to }}
                        invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        margin="normal"
                        name="toDate"
                        onChange={(e) => setFieldValue("toDate", e)}
                        value={toDate}
                        variant="inline"
                    />
                    <FormHelperText data-testid={testIds.toError}>
                        {errors.toDate}
                    </FormHelperText>
                </FormControl>
            </MuiPickersUtilsProvider>
            {isStaff && (
                <>
                    <Grid container spacing={3}>
                        <TagsComponent
                            tags={stateTags}
                            defaultTags={defaultIncludedStateTags}
                            label="States to include"
                            tag_state={includedTags}
                            tag_state_str="includedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.includeStateTags}
                        />
                        <TagsComponent
                            tags={countyTags}
                            defaultTags={defaultIncludedCountyTags}
                            label="Counties to include"
                            tag_state={includedTags}
                            tag_state_str="includedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.includeCountyTags}
                        />

                        <TagsComponent
                            tags={otherTags}
                            defaultTags={defaultIncludedOtherTags}
                            label="Other Tags to include"
                            tag_state={includedTags}
                            tag_state_str="includedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.includeOtherTags}
                        />
                        <TagsComponent
                            tags={stateTags}
                            defaultTags={defaultExcludedStateTags}
                            label="States to exclude"
                            tag_state={excludedTags}
                            tag_state_str="excludedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.excludeStateTags}
                        />

                        <TagsComponent
                            tags={countyTags}
                            defaultTags={defaultExcludedCountyTags}
                            label="Counties to exclude"
                            tag_state={excludedTags}
                            tag_state_str="excludedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.excludeCountyTags}
                        />

                        <TagsComponent
                            tags={otherTags}
                            defaultTags={defaultExcludedOtherTags}
                            label="Other tags to exclude"
                            tag_state={excludedTags}
                            tag_state_str="excludedTags"
                            setFieldValue={setFieldValue}
                            classes={classes}
                            test_id={testIds.excludeOtherTags}
                        />
                    </Grid>
                </>
            )}
            <HelpPanel />
            <TextField
                className={classes.fullWidth}
                FormHelperTextProps={{
                    className: classes.errorText,
                }}
                helperText={errors.query}
                id="query"
                inputProps={{ "data-testid": testIds.query }}
                multiline
                name="query"
                onChange={handleChange}
                minRows={15}
                value={query}
                variant="outlined"
            />
        </Form>
    );
};

export const UnconnectedTargetSurfacerSearchModal = ({
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
    enqueuedCount,
    fetchSearchRecords,
    fetchTeamsData,
    fetchProfilesData,
    isLoading,
    isQuerying,
    isStaff,
    isSubmitting,
    isVisible,
    numPendingQueries,
    setSelectedSearchRecord,
    tags,
    teams,
    profiles,
    toggleOverlay,
    ...formikProps
}) => {
    useEffect(() => {
        if (!profiles) {
            fetchProfilesData();
        }
    }, [profiles, fetchProfilesData]);

    useEffect(() => {
        if (!teams) {
            fetchTeamsData();
        }
    }, [teams, fetchTeamsData]);

    useEffect(() => {
        if (enqueuedCount === 0) {
            closeAllOverlays({});
        }
    }, [closeAllOverlays, enqueuedCount]);
    const fileUpload = useRef(null);

    const filteredTags = tags ? tags.filter((tag) => !tag.is_deleted) : [];

    const filteredTeams = teams ? teams.filter((team) => !team.is_deleted) : [];

    const [resetValues, setResetValues] = useState(false);
    return (
        <FormModal
            extraActions={[
                {
                    label: "Import Query",
                    onSelect: () => fileUpload.current.click(),
                    isDisabled: tags.length <= 0,
                },
                {
                    label: "Export Query",
                    onSelect: () =>
                        saveFile(
                            JSON.stringify(formikProps.values, null, 2),
                            "json",
                            "Query"
                        ),
                    isDisabled: !formikProps.isValid,
                },
                {
                    label: "Reset to Default",
                    onSelect: () => setResetValues(true),
                },
            ]}
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.targetSurfacerSearchModal });
                setSelectedSearchRecord({ selectedSearchRecord: null });
            }}
            handleSubmit={formikProps.handleSubmit}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.queryHistory });
                fetchSearchRecords({});
            }}
            isSubmitDisabled={isLoading || isSubmitting}
            isSubmitLoading={isLoading || isSubmitting}
            isVisible={isVisible}
            hasSecondaryButton={true}
            secondaryLabel="Open History"
            submitLabel="Submit"
            title="Submit Query"
            width={800}
        >
            <QueryHistory />
            <SearchForm
                {...formikProps}
                isStaff={isStaff}
                tags={filteredTags}
                teams={filteredTeams}
                resetValues={resetValues}
                setResetValues={setResetValues}
            />
            {isSubmitting || numPendingQueries > 0 || isQuerying ? (
                <>
                    <LinearProgress
                        variant="determinate"
                        value={parseInt(
                            ((enqueuedCount - numPendingQueries) / enqueuedCount) * 100
                        )}
                    />
                    <Grid
                        alignItems="center"
                        container
                        direction="column"
                        justifyContent="center"
                    >
                        <Typography style={{ marginTop: "10px" }}>
                            {loadingText}
                        </Typography>
                        <Typography>{`Pending queries: ${numPendingQueries}`}</Typography>
                        <CircularProgress />
                    </Grid>
                </>
            ) : null}
            <input
                id="file"
                type="file"
                hidden
                ref={fileUpload}
                onChange={(e) => {
                    const fileReader = new FileReader();
                    const fileName = e.target.files[0].name;
                    if (!fileName.endsWith(".json")) {
                        displayErrorSnack({
                            message: "Query file uploaded must be a .json",
                        });
                        return;
                    }
                    fileReader.readAsText(e.target.files[0], "UTF-8");
                    fileReader.onload = (e) => {
                        try {
                            const obj = JSON.parse(e.target.result);
                            formikProps.setValues(obj);
                            displaySuccessSnack({
                                message: "Query successfully imported",
                            });
                        } catch (e) {
                            displayErrorSnack({
                                message: "Provided file is incorrectly formatted",
                            });
                        }
                    };
                }}
            />
        </FormModal>
    );
};

export const splitQueryIntoLines = (query) =>
    query.split(/[\r\n]+/).filter((query) => query !== "");

export const EnhancedTargetSurfacerSearchModal = withFormik({
    enableReinitialize: true,
    handleSubmit: (
        {
            universe,
            includedTags,
            excludedTags,
            fromDate,
            toDate,
            query,
            selectedTeamId,
        },
        {
            setSubmitting,
            props: {
                enqueueTargetSurfacerQuery,
                createBatch,
                clearBatchId,
                setPreviousQuery,
            },
        }
    ) => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const queries = splitQueryIntoLines(query);
        clearBatchId();
        createBatch({ number_of_queries: queries.length });

        queries.map((query) =>
            enqueueTargetSurfacerQuery({
                fromDate: startDate,
                toDate: endDate,
                query,
                includedTags,
                excludedTags,
                universe,
                selectedTeamId,
            })
        );
        setPreviousQuery({
            previousQuery: {
                universe,
                includedTags,
                excludedTags,
                fromDate: startDate,
                toDate: endDate,
                query,
                selectedTeamId,
            },
        });
        setTimeout(() => {
            setSubmitting(false);
        }, 1000);
    },
    mapPropsToValues: (props) => {
        const defaultExcludedTags = props.defaultExcludedTags.map((tag) => tag.id);
        const previousQuery = props.previousQuery;
        const selectedSearchRecord = props.selectedSearchRecord;

        let values = {
            universe: "us-indices",
            includedTags: [],
            excludedTags: defaultExcludedTags,
            fromDate: new Date(),
            toDate: new Date(),
            query: "",
            selectedTeamId: null,
        };

        if (selectedSearchRecord) {
            values = {
                universe:
                    selectedSearchRecord && selectedSearchRecord.indices
                        ? selectedSearchRecord.indices
                        : "us-indices",
                includedTags:
                    selectedSearchRecord && selectedSearchRecord.included_tags
                        ? selectedSearchRecord.included_tags
                        : [],
                excludedTags:
                    selectedSearchRecord && selectedSearchRecord.excluded_tags
                        ? selectedSearchRecord.excluded_tags
                        : defaultExcludedTags,
                fromDate:
                    selectedSearchRecord && selectedSearchRecord.date_range_start
                        ? new Date(selectedSearchRecord.date_range_start + " EST")
                        : new Date(),
                toDate:
                    selectedSearchRecord && selectedSearchRecord.date_range_end
                        ? new Date(selectedSearchRecord.date_range_end + " EST")
                        : new Date(),
                query:
                    selectedSearchRecord && selectedSearchRecord.queries
                        ? selectedSearchRecord.queries
                        : "",
                selectedTeamId: selectedSearchRecord.team
                    ? selectedSearchRecord.team.id
                    : OTHER_TEAM_ID,
            };
        } else if (previousQuery) {
            values = {
                universe:
                    previousQuery && previousQuery.universe
                        ? previousQuery.universe
                        : "us-indices",
                includedTags:
                    previousQuery && previousQuery.includedTags
                        ? previousQuery.includedTags
                        : [],
                excludedTags:
                    previousQuery && previousQuery.excludedTags
                        ? previousQuery.excludedTags
                        : defaultExcludedTags,
                fromDate:
                    previousQuery && previousQuery.fromDate
                        ? new Date(previousQuery.fromDate + " EST")
                        : new Date(),
                toDate:
                    previousQuery && previousQuery.toDate
                        ? new Date(previousQuery.toDate + " EST")
                        : new Date(),
                query: previousQuery && previousQuery.query ? previousQuery.query : "",
                selectedTeamId:
                    previousQuery && previousQuery.selectedTeamId
                        ? previousQuery.selectedTeamId
                        : OTHER_TEAM_ID,
            };
        }
        values.excludedTags = [...new Set([...values.excludedTags])];
        return values;
    },
    validationSchema: () => TargetSurfacerSearchSchema,
    validateOnChange: false,
    validateOnBlur: false,
})(UnconnectedTargetSurfacerSearchModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        defaultExcludedTags: defaultExcludedTagsSelector(state),
        enqueuedCount: enqueuedCountSelector(state),
        isLoading: isLoadingTagsSelector(state),
        isQuerying: isQueryingSelector(state),
        isStaff: isStaffSelector(state),
        isVisible: isShowing.targetSurfacerSearchModal,
        numPendingQueries: Object.keys(pendingQueriesSelector(state)).length,
        previousQuery: previousQuerySelector(state),
        selectedSearchRecord: selectedSearchRecordSelector(state),
        tags: tagsSelectors.selectAll(state),
        teams: teamDataSelector(state),
        profiles: profileDataSelector(state),
    };
};

export const ConnectedTargetSurfacerSearchModal = connect(mapStateToProps, {
    createBatch,
    clearBatchId,
    closeAllOverlays,
    enqueueTargetSurfacerQuery,
    displaySuccessSnack,
    displayErrorSnack,
    fetchSearchRecords,
    fetchTeamsData,
    fetchProfilesData,
    setPreviousQuery,
    setSelectedSearchRecord,
    toggleOverlay,
})(EnhancedTargetSurfacerSearchModal);

export default ConnectedTargetSurfacerSearchModal;
