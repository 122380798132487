import LinearProgress from "@material-ui/core/LinearProgress";
import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";

import { IntelligenceScope } from "reactQuery/hooks/apiTypes";
import { useGetSearchPlan } from "reactQuery/hooks/useSearchPlan";

import RenderDocument from "./RenderDocument";

export interface CombinedIntelligenceScope extends IntelligenceScope {
    combinedIntelligenceScopes: IntelligenceScope[];
}

const SearchPlanSummaryPage = () => {
    const { id } = useParams();

    const searchPlanQuery = useGetSearchPlan(id);

    if (!searchPlanQuery.isSuccess) {
        return (
            <div style={{ padding: "20px 0" }}>
                <LinearProgress />
            </div>
        );
    }

    return (
        <PDFViewer
            style={{ height: "100vh", width: "100vw", border: "0" }}
            showToolbar={true}
        >
            <RenderDocument searchPlanSummary={searchPlanQuery.data} />
        </PDFViewer>
    );
};

export default SearchPlanSummaryPage;
