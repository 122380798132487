import InsightFactoidList from "../InsightFactoidList";

const InsightFactoid = ({
    getEditTestId,
    insight,
    isEditModeActive,
    label,
    onEditClick,
    renderItem,
    value,
}) => {
    let values = [value];
    if (value === "") {
        values = [];
    }
    return (
        <InsightFactoidList
            insight={insight}
            getEditTestId={getEditTestId}
            isEditModeActive={isEditModeActive}
            label={label}
            onEditClick={onEditClick}
            renderItem={renderItem}
            values={values}
        />
    );
};

export default InsightFactoid;
