import { Select, Layout } from "antd";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { createRedirectBasedOnProductType } from "pages/Home";
import { Product, SidebarLayoutSingleTeam } from "reactQuery/hooks/apiTypes";
import {
    useGetWhoami,
    useGetAllTeams,
    useGetSingleTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";

import CityliticsLogo from "../../../atoms/CityliticsLogo";
import ProductMenu from "../ProductMenu";

const { Sider } = Layout;
const { Option } = Select;

export const teamProducts = (team: SidebarLayoutSingleTeam) => {
    const products = team?.products ?? [];
    team?.dashboard_links?.forEach((dashboardLink) => {
        if (!products.map((product) => product.id).includes(dashboardLink.product.id)) {
            products.push(dashboardLink.product);
        }
    });
    return products.sort((productA, productB) => {
        return productB.rank - productA.rank;
    });
};

const Sidebar = ({
    collapsed,
    setCollapsed,
    selectedTeamId,
    setSelectedTeamId,
}: {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    selectedTeamId: number;
    setSelectedTeamId: (selectedTeamId: number) => void;
}) => {
    const getAllTeams = useGetAllTeams();
    const getSingleTeam = useGetSingleTeam(selectedTeamId, true);
    const getWhoami = useGetWhoami();

    const { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));

    const { push } = useHistory();
    const [selectValue, setSelectValue] = useState(0);
    const [productOfferings, setProductOfferings] = useState<Product[]>([]);

    const [selectOpen, setSelectOpen] = useState(false);

    useEffect(() => {
        if (!getSingleTeam.isSuccess || !teamId) return;

        const products = teamProducts(getSingleTeam.data);
        setProductOfferings(products);

        if (selectedTeamId !== teamId) {
            const redirect = createRedirectBasedOnProductType(
                products?.[0],
                getSingleTeam.data.id
            );
            return push(redirect);
        }
    }, [getSingleTeam.isSuccess, selectedTeamId]);

    useEffect(() => {
        if (getSingleTeam.isError) return push("/"); // redirect to home page because team id is invalid
    }, [getSingleTeam.isError]);

    useEffect(() => {
        if (getWhoami.isSuccess && !selectedTeamId) {
            setSelectedTeamId(getWhoami.data.team.id);
            setSelectValue(getWhoami.data.team.id);
            setProductOfferings(teamProducts(getWhoami.data.team));
            push(`?team_id=${getWhoami.data.team.id}`);
        }
    }, [getWhoami.isSuccess, selectedTeamId]);

    let options = [{ value: 0, label: "Loading..." }];
    if (getWhoami.isSuccess) {
        options.unshift({
            value: getWhoami.data.team.id,
            label: getWhoami.data.team.name,
        });
    }
    if (getAllTeams.isSuccess) {
        options = getAllTeams.data.map((team) => ({
            value: team.id,
            label: team.name,
        }));
    }

    useEffect(() => {
        if (getAllTeams.isSuccess && selectedTeamId) {
            setSelectValue(selectedTeamId);
        }
    }, [selectedTeamId, getAllTeams.isSuccess]);

    return (
        <Sider collapsible collapsed={collapsed} trigger={null}>
            <CityliticsLogo collapsed={collapsed} />

            <div style={{ margin: "15px" }}>
                <Select
                    placeholder="..."
                    onChange={(id) => {
                        setSelectedTeamId(id);
                    }}
                    onClick={() => {
                        if (collapsed) setCollapsed(false);

                        if (!selectOpen) {
                            if (collapsed) {
                                setTimeout(() => {
                                    setSelectOpen(true);
                                }, 250);
                            } else {
                                setSelectOpen(true);
                            }
                        }
                    }}
                    open={selectOpen}
                    onDropdownVisibleChange={(visible) => {
                        if (!visible) setSelectOpen(false);
                    }}
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                        ((option?.label as string) ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    loading={getWhoami.isLoading && getAllTeams.isLoading}
                    disabled={getWhoami.isLoading && getAllTeams.isLoading}
                    value={selectValue}
                >
                    {options.map((option) => {
                        return (
                            <Option
                                data-testid={`option-${option.value}`}
                                key={option.value}
                                value={option.value}
                                label={option.label}
                            >
                                {option.label}
                            </Option>
                        );
                    })}
                </Select>
            </div>

            {productOfferings.length ? (
                <ProductMenu
                    productOfferings={productOfferings}
                    selectedTeamId={selectedTeamId}
                />
            ) : undefined}
        </Sider>
    );
};

export default Sidebar;
