import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Table } from "antd";
import styled from "styled-components";

export const useStyles = makeStyles(() => ({
    filtersContainer: {
        marginBottom: "16px",
    },
    filtersButtonContainer: {
        marginBottom: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    feedbackContainer: {
        display: "flex",
        margin: "0 30px",
        alignItems: "center",
        "& div:not(:last-child)": {
            marginRight: "20px",
        },
    },
    feedbackIconStyle: {
        fontSize: "28px !important",
    },
    feedbackIconDisabledStyle: {
        cursor: "default !important",
    },
    feedbackIconEnabledStyle: {
        cursor: "pointer",
    },
    "@global": {
        "@keyframes ellipsis": {
            to: {
                width: "1.25em",
            },
        },
    },
    loadTableFilters: {
        display: "flex",
        padding: "0 0 10px 10px",
    },
    loading: {
        "&:after": {
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "bottom",
            animation: "ellipsis steps(4,end) 900ms infinite",
            content: "'…'",
            width: "0px",
        },
    },
    iconsContainer: {
        display: "flex",
        alignItems: "center",
    },
    sortingIcon: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
    },
    caretDown: {
        marginTop: "-0.3em",
    },
}));

export const StyledTable = styled(Table)`
    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > .ant-table-row > td:first-child {
        display: none;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none !important;
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
        border-bottom: none;
    }
    .ant-table-tbody > tr.ant-table-expanded-row-level-1 > td {
        background-color: white;
    }
`;

export const StyledCheckBox = styled(Checkbox)`
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
`;
