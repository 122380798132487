import { Redirect, Route, Switch } from "react-router-dom";

import IndicatorFeedsAllFeeds from "pages/IndicatorFeeds/IndicatorFeedsAllFeeds";
import IndicatorFeedsSingleFeed from "pages/IndicatorFeeds/IndicatorFeedsSingleFeed";
import IndicatorFeedsSingleOpportunity from "pages/IndicatorFeeds/IndicatorFeedsSingleOpportunity";

export const IndicatorFeedsBasePathname = "/ifds";
export const IndicatorFeedsSingleOpportunityPathname = `${IndicatorFeedsBasePathname}/opportunity`;
export const IndicatorFeedsSingleFeedPathname = `${IndicatorFeedsBasePathname}/feed`;
export const IndicatorFeedsAllFeedsPathname = `${IndicatorFeedsBasePathname}/all_feeds`;

const IndicatorFeedsRoutes = () => {
    return (
        <Switch>
            <Route exact path={IndicatorFeedsAllFeedsPathname}>
                <IndicatorFeedsAllFeeds />
            </Route>
            <Route exact path={IndicatorFeedsSingleFeedPathname}>
                <IndicatorFeedsSingleFeed />
            </Route>
            <Route exact path={IndicatorFeedsSingleOpportunityPathname}>
                <IndicatorFeedsSingleOpportunity />
            </Route>

            <Route exact path={IndicatorFeedsBasePathname}>
                <p>Indicator Feeds Landing Page</p>
            </Route>

            <Route path="*">
                <Redirect to={IndicatorFeedsBasePathname} />
            </Route>
        </Switch>
    );
};

export default IndicatorFeedsRoutes;
