// TODO: update variable names and imports to say facility instead of wastewaterFacility

import LinearProgress from "@material-ui/core/LinearProgress";
import { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import AddContactModal from "components/cdv/AddContactModal";
import AddDrinkingWaterSystemModal from "components/cdv/AddDrinkingWaterSystemModal";
import AddIndicatorModal from "components/cdv/AddIndicatorModal";
import AddIntelligenceModal from "components/cdv/AddIntelligenceModal";
import AddLocationModal from "components/cdv/AddLocationModal";
import AddSalesStageModal from "components/cdv/AddSalesStageModal";
import AddWastewaterFacilityModal from "components/cdv/AddWastewaterFacilityModal";
import CompetitorModal from "components/cdv/CompetitorModal";
import ConsultantModal from "components/cdv/ConsultantModal";
import CreateInsightModal from "components/cdv/CreateInsightModal";
import CreateSourceModal from "components/cdv/CreateSourceModal";
import { DeliveryStatusModal } from "components/cdv/DeliveryStatusModal";
import EditDrinkingWaterSystemModal from "components/cdv/EditDrinkingWaterSystemModal";
import EditIndicatorModal from "components/cdv/EditIndicatorModal";
import EditInsightModal from "components/cdv/EditInsightModal";
import EditOpportunityModal from "components/cdv/EditOpportunityModal";
import EditOpportunityOwnerModal from "components/cdv/EditOpportunityOwnerModal";
import EditRemoveContactModal from "components/cdv/EditRemoveContactModal";
import EditSalesStageModal from "components/cdv/EditSalesStageModal";
import EditSourceModal from "components/cdv/EditSourceModal";
import EditWastewaterFacilityModal from "components/cdv/EditWastewaterFacilityModal";
import { IntelligenceNotesModal } from "components/cdv/IntelligenceNotesModal";
import { LogActivityModal } from "components/cdv/LogActivityModal";
import { OpportunityAssigneeModal } from "components/cdv/OpportunityAssigneeModal";
import RemoveLocationModal from "components/cdv/RemoveLocationModal";
import RemoveOpportunityModal from "components/cdv/RemoveOpportunityModal";
import TeamCustomerTagsModal from "components/cdv/TeamCustomerTagsModal";
import { TimelineModal } from "components/cdv/TimelineModal";
import SidebarLayout from "components/organisms/SidebarLayout";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { isErrorDueTo404 } from "network";
import { useGetAllActions } from "reactQuery/hooks/useActions";
import { useGetIndicatorGroupById } from "reactQuery/hooks/useIndicatorGroups";
import { useGetOpportunity } from "reactQuery/hooks/useOpportunity";
import { selectInsightsForActiveOpportunity } from "stores/insights/selectors";
import {
    fetchOpportunityActionsTakenByFilter,
    fetchOpportunityById,
    fetchOpportunitySalesStagesByFilter,
    setActiveOpportunity,
} from "stores/opportunities/actions";
import {
    activeOpportunityIdSelector,
    activeOpportunitySelector,
    isChangingOpportunitySalesStageSelector,
    isChangingOpportunityActionsTakenSelector,
    selectFetchErrorMap,
} from "stores/opportunities/selectors";
import { activeReportIdSelector, setActiveTeam } from "stores/reports/reportSlice";
import { selectIdFromUrl } from "stores/shared/browserSelectors";
import { toggleEditMode } from "stores/uiStore/actionTypes";
import { isEditModeActiveSelector } from "stores/uiStore/selectors";
import { fetchTeamById } from "stores/userStore/actionTypes";
import { isStaffSelector } from "stores/userStore/selectors";

import { OpportunityBody } from "./OpportunityBody";

export const TEST_IDS = {
    linearProgress: "linear-progress",
};

export const UnconnectedOpportunityDetailsPage = ({
    activeOpportunityId,
    activeReportId,
    canEditReport,
    isEditModeActive,
    indicatorGroupId,
    isChangingSalesStagesHistory,
    isChangingActionsTakenHistory,
    setActiveTeam,
    toggleEditMode,
    opportunityId,
    opportunityType,
    setActiveOpportunity,
    fetchOpportunityById,
    fetchOpportunitySalesStagesByFilter,
    fetchOpportunityActionsTakenByFilter,
    fetchOpportunitiesForReport,
    fetchTeamById,
    didFetchResultIn404,
    viewType,
    activeInsights,
}) => {
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const teamId = Number(searchParams.get("team_id"));

    const indicatorGroupQuery = useGetIndicatorGroupById(
        indicatorGroupId,
        opportunityType === OpportunityTypeEnum.INDICATOR && indicatorGroupId
            ? true
            : false
    );

    const opportunityQuery = useGetOpportunity({
        opportunityId: opportunityId,
        feedType: viewType,
        teamId: teamId,
        indicatorGroupId: indicatorGroupId,
        enabled: viewType ? true : false,
    });

    const opportunity = opportunityQuery.data;

    const team =
        opportunityType === OpportunityTypeEnum.REPORT
            ? opportunity?.team
            : opportunityType === OpportunityTypeEnum.INDICATOR
            ? indicatorGroupQuery.data?.team
            : null;

    const actionsQuery = useGetAllActions();
    const actionsData = actionsQuery.data?.results ?? null;

    useEffect(() => {
        if (opportunityType === OpportunityTypeEnum.REPORT) {
            if (activeOpportunityId && activeOpportunityId !== opportunityId) {
                setActiveOpportunity({
                    opportunityId: opportunityId,
                });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                });
            }
            if (!activeOpportunityId && opportunityId) {
                setActiveOpportunity({ opportunityId: opportunityId });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                });
            }
            if (activeOpportunityId) {
                fetchOpportunitySalesStagesByFilter({
                    opportunityId: activeOpportunityId,
                });
            }
        } else if (opportunityType === OpportunityTypeEnum.INDICATOR) {
            if (activeOpportunityId && activeOpportunityId !== opportunityId) {
                setActiveOpportunity({
                    opportunityId: opportunityId,
                });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                    indicatorGroupId: indicatorGroupId,
                });
            }
            if (!activeOpportunityId && opportunityId) {
                setActiveOpportunity({ opportunityId: opportunityId });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                    indicatorGroupId: indicatorGroupId,
                });
            }
        }
        if (team?.id) {
            if (
                searchParams.get("team_id") &&
                parseInt(searchParams.get("team_id")) !== team.id
            ) {
                history.push("/");
            }
            setActiveTeam({ teamId: team.id });
            fetchTeamById(team.id);
        }
    }, [
        activeOpportunityId,
        fetchOpportunityById,
        fetchTeamById,
        fetchOpportunitySalesStagesByFilter,
        fetchOpportunityActionsTakenByFilter,
        fetchOpportunitiesForReport,
        opportunityType,
        indicatorGroupQuery,
        isChangingSalesStagesHistory,
        isChangingActionsTakenHistory,
        setActiveTeam,
        opportunityId,
        setActiveOpportunity,
        activeReportId,
        opportunity,
        indicatorGroupId,
        searchParams,
    ]);

    const notFoundMessage =
        didFetchResultIn404 || opportunityQuery.status === "error" ? (
            <p>Opportunity not found!</p>
        ) : null;
    const isCorrectOpportunitySelected = opportunity
        ? parseInt(opportunityId) === opportunity.id
        : false;

    const opportunityBody = !didFetchResultIn404 ? (
        !isCorrectOpportunitySelected ? (
            <LinearProgress data-testid={TEST_IDS.linearProgress} />
        ) : (
            <OpportunityBody
                opportunityType={opportunityType}
                indicatorGroupId={indicatorGroupId}
                activeReportId={activeReportId}
                opportunity={opportunity}
                team={team}
                viewType={viewType}
                opportunityId={opportunityId}
                indicatorGroupQuery={indicatorGroupQuery}
                canEditReport={canEditReport}
                isEditModeActive={isEditModeActive}
                toggleEditMode={toggleEditMode}
                activeInsights={activeInsights}
            />
        )
    ) : null;
    const body = notFoundMessage ? notFoundMessage : opportunityBody;
    return (
        <>
            {/* TODO: Hide modals behind canEditReport/isStaff flag to speed up loading for external users */}
            <CompetitorModal />
            <IntelligenceNotesModal />
            {/* @ts-ignore */}
            <LogActivityModal allActions={actionsData} opportunityId={opportunityId} />
            <TimelineModal />
            <AddIntelligenceModal />
            <AddLocationModal />
            <AddWastewaterFacilityModal />
            <AddIndicatorModal />
            <AddSalesStageModal />
            <AddContactModal />
            <EditRemoveContactModal />
            <CreateInsightModal />
            <CreateSourceModal />
            <AddDrinkingWaterSystemModal />
            <ConsultantModal />
            <EditDrinkingWaterSystemModal />
            <EditInsightModal />
            <EditIndicatorModal />
            <DeliveryStatusModal />
            <OpportunityAssigneeModal
                opportunity={opportunity}
                opportunityType={opportunityType}
            />
            <EditOpportunityOwnerModal />
            <EditOpportunityModal />
            <RemoveOpportunityModal />
            <EditSourceModal />
            {/* @ts-ignore */}
            <TeamCustomerTagsModal teamId={team?.id} />
            <EditWastewaterFacilityModal />
            <EditSalesStageModal />
            <RemoveLocationModal />
            <SidebarLayout content={body} />
        </>
    );
};

const mapStateToProps = (state, props) => {
    let indicatorGroupId = null;
    let opportunityId = null;
    let reportId = null;
    if (props?.location?.search) {
        const queryParams = new URLSearchParams(props.location.search);
        indicatorGroupId = parseInt(queryParams.get("indicator_group_id"));
        opportunityId = parseInt(queryParams.get("opportunity_id"));
        reportId = parseInt(queryParams.get("report_id"));
    } else if (props?.match?.params?.indicatorGroupId) {
        indicatorGroupId = props.match.params.indicatorGroupId;
    }
    return {
        activeInsights: selectInsightsForActiveOpportunity(state as never),
        activeOpportunityId: activeOpportunityIdSelector(state),
        canEditReport: isStaffSelector(state as never),
        didFetchResultIn404: isErrorDueTo404(
            selectFetchErrorMap(state)[activeOpportunitySelector(state as never)]
        ),
        isEditModeActive: isEditModeActiveSelector(state),
        opportunityId: opportunityId || selectIdFromUrl(state, props),
        opportunityType: indicatorGroupId
            ? OpportunityTypeEnum.INDICATOR
            : OpportunityTypeEnum.REPORT,
        indicatorGroupId: indicatorGroupId,
        isChangingSalesStagesHistory: isChangingOpportunitySalesStageSelector(state),
        isChangingActionsTakenHistory: isChangingOpportunityActionsTakenSelector(state),
        activeReportId:
            reportId ||
            activeReportIdSelector(state) ||
            parseInt(localStorage.getItem("activeReport")) ||
            null,
        viewType: indicatorGroupId
            ? "indicatorFeeds"
            : reportId
            ? "intelligenceReports"
            : "allInsights",
    };
};

const ConnectedOpportunityDetailsPage = connect(mapStateToProps, {
    toggleEditMode,
    setActiveTeam,
    setActiveOpportunity,
    fetchOpportunityById,
    fetchTeamById,
    fetchOpportunitySalesStagesByFilter,
    fetchOpportunityActionsTakenByFilter,
})(UnconnectedOpportunityDetailsPage);

export default ConnectedOpportunityDetailsPage;
