import {
    DownOutlined,
    ShareAltOutlined,
    SyncOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";

import StyledButton from "components/atoms/StyledButton";
import {
    SYNC_TO_CRM_ENABLED_TIER_NAME,
    useGetIsTierEnabledForTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";

const getMenuItems = (selectedCount: number, teamId: number, options: string[]) => {
    const items: MenuProps["items"] = options.includes("share_opportunities")
        ? [
              {
                  label:
                      selectedCount > 1 ? "Share Opportunities" : "Share Opportunity",
                  key: "share_opportunities",
                  icon: <ShareAltOutlined />,
              },
          ]
        : [];

    const syncOpportunitiesEnabled = useGetIsTierEnabledForTeam({
        teamId: teamId,
        tierName: SYNC_TO_CRM_ENABLED_TIER_NAME,
    });

    if (syncOpportunitiesEnabled && options.includes("sync_opportunities_crm"))
        items.push({
            label:
                selectedCount > 1
                    ? "Sync Opportunities to CRM"
                    : "Sync Opportunity to CRM",
            key: "sync_opportunities_crm",
            icon: <SyncOutlined />,
        });

    if (options.includes("assign_ipl_results"))
        items.push({
            label: selectedCount > 1 ? "Assign IPL Results" : "Assign IPL Result",
            key: "assign_ipl_results",
            icon: <UserAddOutlined />,
        });

    return items;
};

export interface SelectedItemsButtonProps {
    selectedCount?: number;
    disabled?: boolean;
    assignIPLResultOnClick?: () => void;
    shareOpportunitiesOnClick: () => void;
    syncOpportunitiesToCRMOnClick: () => void;
    MUIButtonStyle?: boolean;
    teamId: number;
    itemName?: string;
    itemNamePlural?: string;
    options?: string[];
}

export const SelectedItemsButton = ({
    selectedCount,
    disabled = false,
    assignIPLResultOnClick,
    shareOpportunitiesOnClick,
    syncOpportunitiesToCRMOnClick,
    MUIButtonStyle = false,
    teamId,
    itemName = "Insight",
    itemNamePlural = "Insights",
    options = ["share_opportunities", "sync_opportunities_crm"],
}: SelectedItemsButtonProps) => {
    const handleMenuClick: MenuProps["onClick"] = (event) => {
        if (event.key === "share_opportunities") {
            shareOpportunitiesOnClick();
        } else if (event.key === "sync_opportunities_crm") {
            syncOpportunitiesToCRMOnClick();
        } else if (event.key === "assign_ipl_results") {
            assignIPLResultOnClick();
        }
    };

    const items = getMenuItems(selectedCount, teamId, options);

    return (
        <div style={{ float: "right", marginLeft: 5 }}>
            <Dropdown
                menu={{
                    items,
                    onClick: handleMenuClick,
                }}
                disabled={disabled}
            >
                {MUIButtonStyle ? (
                    <StyledButton variant="page-action" icon={<ShareAltOutlined />}>
                        Actions
                    </StyledButton>
                ) : (
                    <Button disabled={disabled}>
                        <Space>
                            {selectedCount > 1
                                ? `${selectedCount} ${itemNamePlural} Selected`
                                : selectedCount === 1
                                ? `${selectedCount} ${itemName} Selected`
                                : "Bulk Actions"}
                            <DownOutlined />
                        </Space>
                    </Button>
                )}
            </Dropdown>
        </div>
    );
};
