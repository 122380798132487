import { createReducer } from "@reduxjs/toolkit";

import {
    setInsightForCompanyLinkage,
    setInsightForCompanyRemoval,
} from "stores/companies/actions";
import { companiesAdapter } from "stores/companies/adapter";

export const initialState = companiesAdapter.getInitialState({
    error: null,
    isLoading: false,
    insight: null,
    removeCompany: {
        insight: null,
        company: null,
    },
});

const companiesReducer = createReducer(initialState, (builder) => {
    builder.addCase(setInsightForCompanyLinkage, (state, { payload: { insight } }) => {
        state.insight = insight;
    });
    builder.addCase(
        setInsightForCompanyRemoval,
        (state, { payload: { insight, company } }) => {
            state.removeCompany.insight = insight;
            state.removeCompany.company = company;
        }
    );
});

export default companiesReducer;
