import {
    UseMutationResult,
    useIsFetching,
    useQueryClient,
} from "@tanstack/react-query";
import { Button, Form, Modal, Select } from "antd";
import { useState } from "react";

import StatusTag from "components/atoms/StatusTag";
import {
    DeepInsight,
    PaginatedSingleReportPageInsightsList,
} from "reactQuery/hooks/apiTypes";
import { useGetAllDeliveryStatuses } from "reactQuery/hooks/useDeliveryStatuses";
import { usePatchInsight } from "reactQuery/hooks/useInsights";

import ConditionalTooltip from "../../atoms/ConditionalTooltip";

const { Option } = Select;

export const DeliveryStatusTableRowTag = ({ record }) => {
    const [form] = Form.useForm();

    const [modalOpen, setModalOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const queryClient = useQueryClient();
    const getAllDeliveryStatuses = useGetAllDeliveryStatuses();
    const patchInsight = usePatchInsight();
    const isFetchingInsights = useIsFetching({ queryKey: ["single_report"] });

    const insightId = record.key;
    const formId = `edit-delivery-status-form-insight-${insightId}`;

    const showModal = () => {
        if (isFetchingInsights) return;
        setModalOpen(true);
    };
    const handleCancel = () => {
        form.resetFields();
        setModalOpen(false);
    };
    const handleOnSuccess = (responseData: DeepInsight) => {
        queryClient.setQueriesData(
            ["single_report"],
            (previousData: PaginatedSingleReportPageInsightsList) => {
                if (!previousData) return;

                let index = previousData.results.findIndex(
                    (result) => result.id === responseData.id
                );

                const updatedData = { ...previousData };
                if (index !== -1) {
                    updatedData.results[index].delivery_status =
                        responseData.delivery_status;
                }

                return updatedData;
            }
        );

        record.delivery_status = responseData.delivery_status;

        setModalOpen(false);
    };
    const onFinish = (
        values: { deliveryStatus: string },
        patchInsight: Partial<UseMutationResult<DeepInsight>>
    ) => {
        patchInsight.mutate(
            {
                payload: {
                    delivery_status: values.deliveryStatus,
                },
                insightId,
            },
            {
                onSuccess: handleOnSuccess,
            }
        );
    };

    return (
        <>
            <ConditionalTooltip
                showTooltip={!!isFetchingInsights}
                title="Please wait until all records have loaded"
            >
                <Button type="text" onClick={showModal} data-testid="row-status-tag">
                    <StatusTag status={record.delivery_status} />
                </Button>
            </ConditionalTooltip>
            <Modal
                title="Edit Delivery Status"
                open={modalOpen}
                onCancel={handleCancel}
                footer={
                    <Button
                        loading={patchInsight.isLoading}
                        disabled={patchInsight.isLoading}
                        form={formId}
                        key="submit"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                }
            >
                <Form
                    id={formId}
                    form={form}
                    name={formId}
                    layout="vertical"
                    onFinish={(values) => onFinish(values, patchInsight)}
                    initialValues={{
                        deliveryStatus: record.delivery_status,
                    }}
                >
                    <Form.Item name="deliveryStatus" label="Delivery Status">
                        <Select
                            open={dropdownOpen}
                            optionLabelProp="children"
                            onDropdownVisibleChange={(visible) =>
                                setDropdownOpen(visible)
                            }
                            dropdownRender={(menu) => <>{menu}</>}
                        >
                            {Object.keys(getAllDeliveryStatuses.data ?? {}).map(
                                (status) => (
                                    <Option key={status} value={status} title={status}>
                                        <StatusTag status={status} />
                                    </Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
