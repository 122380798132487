import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Form, Select, Space, Button } from "antd";

import { formatDayHyphenMonthHyphenYear } from "constants/formatters";
import { openNewInternalLink } from "constants/navigation";
import { ShallowContactRead, InsightInOpportunity } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { usePatchInsight } from "reactQuery/hooks/useInsights";
import { opportunityKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore, ModalNames } from "stores/zustandStore";

const AddContactModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const setModalHidden = useModalStore((state) => state.setModalHidden);

    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    const patchInsightQuery = usePatchInsight();
    const queryClient = useQueryClient();

    const isRefetchingOpportunity = Boolean(
        useIsFetching({
            queryKey: opportunityKeys.single_opportunity(
                modalInitialValues?.opportunityId
            ),
        })
    );

    const [form] = Form.useForm();
    const formId = "add-contact-modal-form";

    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user.is_staff ?? false;

    const createNewContact = () => {
        openNewInternalLink(`/admin/universe/contact/add/`, false);
        setModalHidden();
    };

    const onFinish = () => {
        let existingContactIds = modalInitialValues.insights
            .find(
                (insight: InsightInOpportunity) =>
                    insight.id === form.getFieldValue("insight")
            )
            .contacts.map((contact: ShallowContactRead) => contact.id);
        let newContactId = form.getFieldValue("contact");

        const payload = {
            contacts: [newContactId, ...existingContactIds],
        };

        patchInsightQuery.mutate(
            { payload: payload, insightId: form.getFieldValue("insight") },
            {
                onSuccess: () => {
                    queryClient
                        .invalidateQueries(
                            opportunityKeys.single_opportunity(
                                modalInitialValues.opportunityId
                            )
                        )
                        .then(() => {
                            setModalHidden();
                            displaySuccessSnack({
                                message: "Contacts added successfully",
                            });
                            form.resetFields();
                        });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({
                        message: "Error updating contacts",
                    });
                },
            }
        );
    };
    const onModalCancel = () => {
        form.resetFields();
        setModalHidden();
    };

    return (
        <Modal
            open={openModal === ModalNames.AddContact}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                    }}
                >
                    <div>
                        {isStaff ? (
                            <Button
                                type="link"
                                onClick={createNewContact}
                                style={{ marginTop: "10px" }}
                            >
                                Create New Contact
                            </Button>
                        ) : null}
                    </div>

                    <div>
                        <Button
                            key="submit"
                            form={formId}
                            type="default"
                            onClick={onModalCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            key="link"
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: "10px" }}
                            loading={
                                patchInsightQuery.isLoading || isRefetchingOpportunity
                            }
                            form={formId}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="vertical"
                style={{ width: "80%", margin: "auto" }}
                preserve={false}
                onFinish={onFinish}
            >
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <Space>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            Add Contact
                        </Typography.Title>
                    </Space>
                </div>
                <Form.Item
                    name="insight"
                    label="Insight"
                    rules={[
                        {
                            required: true,
                            message: "Please select an insight",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select an insight"
                        dropdownRender={(menu) => <>{menu}</>}
                    >
                        {modalInitialValues?.insights?.map(
                            (insight: InsightInOpportunity) => (
                                <Select.Option
                                    key={insight.id}
                                    value={insight.id}
                                    title={insight.report.date}
                                >
                                    {formatDayHyphenMonthHyphenYear(
                                        insight.report.date
                                    )}
                                </Select.Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Contact"
                    name="contact"
                    rules={[
                        {
                            required: true,
                            message: "Please select a contact",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Search for a contact"
                        options={
                            modalInitialValues?.contactOptions?.map(
                                (contact: ShallowContactRead) => ({
                                    label: (
                                        <>
                                            <strong>Name:&nbsp;</strong>
                                            {`${contact.first_name} ${contact.last_name}`}
                                            <br />
                                            {contact.position ? (
                                                <>
                                                    <strong>Position:&nbsp;</strong>
                                                    {`${contact.position}`}
                                                    <br />
                                                </>
                                            ) : null}
                                            {contact.email ? (
                                                <>
                                                    <strong>Email:&nbsp;</strong>
                                                    {`${contact.email}`}
                                                    <br />
                                                </>
                                            ) : null}
                                            <strong>Company:</strong>{" "}
                                            {`${
                                                contact.company?.name
                                                    ? contact.company.name
                                                    : contact.owner &&
                                                      contact.owner.legal_name
                                                    ? contact.owner.legal_name
                                                    : "Unknown"
                                            }`}
                                            <br />
                                            <strong>
                                                Last Manual Verification Date:
                                            </strong>{" "}
                                            {`${formatDayHyphenMonthHyphenYear(
                                                contact.last_manual_verification_date
                                            )}`}
                                        </>
                                    ),
                                    value: contact.id,
                                })
                            ) || []
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddContactModal;
