import DateFnsUtils from "@date-io/date-fns";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import SalesStageTimeline from "components/cdv/SalesStageTimeline";
import { EditModalBody } from "components/molecules/EditModalBody";
import { FormModal } from "components/molecules/Modal";
import { deleteSalesStage, patchSalesStage } from "stores/opportunities/actions";
import {
    activeOpportunityIdSelector,
    editSalesStageSelector,
    salesStageBeingEditedSelector,
} from "stores/opportunities/selectors";
import { closeAllOverlays, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { userDataSelector } from "stores/userStore/selectors";

export const stageOptions = [
    { label: "Prospecting - Research", value: 1 },
    { label: "Prospecting - Qualification", value: 2 },
    { label: "Prospecting - Other", value: 3 },
    { label: "Utility Response - Needs Analysis", value: 4 },
    { label: "Utility Response - Value Proposition", value: 5 },
    { label: "Utility Response - Sent Product Materials", value: 6 },
    { label: "Utility Response - Webinar or Demo Presentation", value: 7 },
    { label: "Utility Response - Other", value: 8 },
    { label: "Opportunity Development - Identify Decision Makers", value: 9 },
    { label: "Opportunity Development - Perception Analysis", value: 10 },
    { label: "Opportunity Development - Pilot Project", value: 11 },
    { label: "Opportunity Development - Other", value: 12 },
    { label: "Approval - Proposal or Price Quote", value: 13 },
    { label: "Approval - Negotiation or Review", value: 14 },
    { label: "Approval - Other", value: 15 },
    { label: "Closed - Won", value: 16 },
    { label: "Closed - Lost", value: 17 },
];

export const TEST_IDS = {
    date: "date-test-id",
    dropdown: "dropdown-test-id",
};

export const ERROR_MESSAGES = {
    dateMissing: "A date is required",
    dateInvalid: "Invalid date",
    stageMissing: "A stage option is required",
};

export const EditSalesStageSchema = Yup.object().shape({
    stageDate: Yup.date().nullable().required(ERROR_MESSAGES.dateMissing),
    selectedStage: Yup.object().nullable().required(ERROR_MESSAGES.stageMissing),
    stageNotes: Yup.string(),
});

const formStyle = makeStyles(() => ({
    formControl: {
        // width: "100%",
        // display: "block"
    },
    autocomplete: {
        width: "100%",
    },
}));

export const EditSalesStageModalForm = ({
    errors,
    handleChange,
    setFieldValue,
    values,
}) => {
    const classes = formStyle();
    const { selectedStage, stageNotes, stageDate, currentStage } = values;
    return (
        <Form>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                alignContent="center"
                spacing={1}
            >
                <Grid item xs={12} style={{ marginRight: "65px" }}>
                    <SalesStageTimeline
                        current_stage={currentStage ? currentStage : "Prospecting"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            blurOnSelect={false}
                            className={classes.autocomplete}
                            data-testid={TEST_IDS.dropdown}
                            debug
                            disableClearable
                            getOptionLabel={(option) => option.label}
                            label="Select Sales Stage"
                            multiple={false}
                            name="selectedStage"
                            options={stageOptions}
                            value={selectedStage}
                            defaultValue={selectedStage}
                            onChange={(e, v) => {
                                setFieldValue("selectedStage", v ? v : null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sales Stage Options"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingBottom: "9px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl
                            id="date-form-control"
                            error={"stageDate" in errors}
                            className={classes.formControl}
                            style={{ paddingLeft: "17px" }}
                        >
                            <InputLabel shrink style={{ paddingLeft: "30px" }}>
                                Date
                            </InputLabel>
                            <KeyboardDatePicker
                                disableToolbar
                                format="yyyy-MM-dd"
                                id="date-picker-inline"
                                inputProps={{
                                    "data-testid": TEST_IDS.date,
                                }}
                                invalidDateMessage={ERROR_MESSAGES.dateInvalid}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                margin="normal"
                                defaultValue={stageDate}
                                name="stageDate"
                                onChange={(e) => setFieldValue("stageDate", e)}
                                value={stageDate}
                                variant="inline"
                                inputVariant="outlined"
                            />
                            <FormHelperText data-testid="test-stage-date-error">
                                {errors.stageDate ? ERROR_MESSAGES.dateMissing : null}
                            </FormHelperText>
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="stage-notes"
                        label="Add a Note (Optional)"
                        multiline
                        name="stageNotes"
                        onChange={handleChange}
                        value={stageNotes}
                        error={"stageNotes" in errors}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

export const UnconnectedEditSalesStageModal = ({
    isLoading,
    isVisible,
    isUpdating,
    isRemoving,
    toggleOverlay,
    closeAllOverlays,
    isRemoveModeActive,
    sales_stage,
    ...formikProps
}) => {
    const bodyItems = [
        {
            label: "Stage:",
            value:
                sales_stage && sales_stage.sales_stage.label
                    ? sales_stage.sales_stage.label
                    : "Unknown",
        },
        {
            label: "Date:",
            value: sales_stage
                ? moment(sales_stage.stage_date).format("DD-MM-YYYY")
                : "",
        },
        {
            label: "Notes: ",
            value: sales_stage && sales_stage.notes ? sales_stage.notes.notes : "",
        },
    ];
    return (
        <FormModal
            handleClose={() => closeAllOverlays({})}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeSalesStageModal });
                toggleOverlay({ overlay: overlayNames.editSalesStageModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSubmitDisabled={isLoading}
            isSecondaryDisabled={isUpdating || isRemoving}
            isSubmitLoading={isLoading}
            hasSecondaryButton={true}
            isVisible={isVisible || isRemoveModeActive}
            isRemoveModeActive={isRemoveModeActive}
            isEditModeActive={true}
            title={isRemoveModeActive ? "Remove Sales Stage" : "Edit Sales Stage"}
        >
            {isRemoveModeActive ? (
                <EditModalBody
                    isRemoveModeActive={true}
                    removalMessage="Are you sure that you would like to remove this sales stage this opportunity? Once removed, this action cannot be reversed."
                    items={bodyItems}
                />
            ) : (
                <EditSalesStageModalForm {...formikProps} />
            )}
        </FormModal>
    );
};

export const EnhancedEditSalesStageModal = withFormik({
    enableReinitialize: true,
    handleSubmit: (
        { currentStage, stageDate, stageNotes, selectedStage },
        {
            props: {
                patchSalesStage,
                deleteSalesStage,
                isRemoveModeActive,
                opportunityId,
                user,
                sales_stage,
                editSalesStageId,
            },
        }
    ) => {
        if (!isRemoveModeActive) {
            patchSalesStage({
                oppSalesStageId: editSalesStageId,
                opportunityId,
                salesStageId: selectedStage.value,
                notes: stageNotes ? stageNotes : "",
                userId: user.id,
                stage_date: moment(stageDate).format("YYYY-MM-DD HH:mm:ss"),
            });
        } else {
            deleteSalesStage({
                oppSalesStageId: editSalesStageId,
            });
        }
    },

    mapPropsToValues: ({ sales_stage }) => {
        return {
            currentStage: sales_stage ? sales_stage.sales_stage.stage : "",
            stageDate: sales_stage ? sales_stage.stage_date : "",
            stageNotes: sales_stage && sales_stage.notes ? sales_stage.notes.notes : "",
            selectedStage:
                sales_stage && sales_stage.sales_stage
                    ? stageOptions.filter(
                          (stage_option) =>
                              stage_option.label === sales_stage.sales_stage.label
                      )[0]
                    : "",
        };
    },

    validationSchema: () => EditSalesStageSchema,
    validateOnBlur: false,
    validateOnChange: false,
})(UnconnectedEditSalesStageModal);

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    const editSalesStage = editSalesStageSelector(state);
    return {
        user: userDataSelector(state),
        editSalesStageId: editSalesStage.saleStageId,
        sales_stage: salesStageBeingEditedSelector(state),
        isVisible: isShowing.editSalesStageModal,
        isUpdating: editSalesStage.isChanging,
        isRemoveModeActive: isShowing.removeSalesStageModal,
        opportunityId: activeOpportunityIdSelector(state),
    };
};

const ConnectedEditSalesStage = connect(mapStateToProps, {
    toggleOverlay,
    closeAllOverlays,
    patchSalesStage,
    deleteSalesStage,
})(EnhancedEditSalesStageModal);

export default ConnectedEditSalesStage;
