import logo from "citylitics-logo-dark.png";
import PDFImage from "components/atoms/PDFImage";
import PDFPage from "components/atoms/PDFPage";
import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { InsightInOpportunity, OpportunityRead } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderContact from "../RenderContact";
import RenderInsight from "../RenderInsight";

const RenderOpportunity = (opportunity: OpportunityRead) => (
    // @ts-ignore
    <PDFPage size="LETTER" style={pdfStyle.page} key={opportunity.id}>
        <PDFView style={pdfStyle.headerContainer} fixed>
            <PDFImage src={logo} style={pdfStyle.logo} />
            <PDFText
                render={({ pageNumber, totalPages }) =>
                    pageNumber === 1
                        ? `Document Generated: ${formatDayHyphenShortenedMonthHyphenYear(
                              new Date()
                          )}`
                        : `${pageNumber} / ${totalPages}`
                }
            />
        </PDFView>
        <PDFView style={{ ...pdfStyle.separatorContainer, fontSize: 13 }}>
            <PDFText>
                {opportunity.owner?.legal_name}, {opportunity.owner?.state_code}
            </PDFText>
        </PDFView>
        {opportunity.owner ? (
            <PDFView
                style={[
                    pdfStyle.separatorContainer,
                    opportunity.opportunity_value?.source_opportunity_value
                        ? { borderBottom: "none" }
                        : {},
                ]}
            >
                {opportunity.owner?.legal_name ? (
                    <PDFView style={pdfStyle.infoChart}>
                        <PDFText style={pdfStyle.infoChartTitle}>
                            Opportunity Owner
                        </PDFText>
                        <PDFText>{`${opportunity.owner?.legal_name}${
                            opportunity.owner.state_code
                                ? `, ${opportunity.owner.state_code}`
                                : ""
                        }`}</PDFText>
                    </PDFView>
                ) : null}
                {opportunity.owner?.demographics?.[0]?.population ? (
                    <PDFView style={pdfStyle.infoChart}>
                        <PDFText style={pdfStyle.infoChartTitle}>Population</PDFText>
                        <PDFText>
                            {opportunity.owner.demographics?.[0]?.population?.toLocaleString()}
                        </PDFText>
                    </PDFView>
                ) : null}
                {opportunity.owner?.demographics?.[0]?.address ? (
                    <PDFView style={pdfStyle.infoChart}>
                        <PDFText style={pdfStyle.infoChartTitle}>Address</PDFText>
                        <PDFText>
                            {opportunity.owner.demographics?.[0]?.address}
                        </PDFText>
                    </PDFView>
                ) : null}
                {opportunity.owner?.demographics?.[0]?.zip ? (
                    <PDFView style={pdfStyle.infoChart}>
                        <PDFText style={pdfStyle.infoChartTitle}>Zip Code</PDFText>
                        <PDFText>{opportunity.owner.demographics?.[0]?.zip}</PDFText>
                    </PDFView>
                ) : null}
                {opportunity.owner?.demographics?.[0]?.county ? (
                    <PDFView style={pdfStyle.infoChart}>
                        <PDFText style={pdfStyle.infoChartTitle}>County</PDFText>
                        <PDFText>{opportunity.owner.demographics?.[0]?.county}</PDFText>
                    </PDFView>
                ) : null}
            </PDFView>
        ) : null}
        {opportunity.opportunity_value?.customer_opportunity_value !== 0 &&
        (opportunity.opportunity_value?.customer_opportunity_value ??
            opportunity.opportunity_value?.source_opportunity_value) ? (
            <PDFView style={pdfStyle.separatorContainer}>
                <PDFView style={pdfStyle.infoChart}>
                    <PDFText style={pdfStyle.infoChartTitle}>Opportunity Value</PDFText>
                    <PDFText style={{ fontWeight: "bold" }}>
                        {opportunity.opportunity_value.customer_opportunity_value
                            ? `$${opportunity.opportunity_value.customer_opportunity_value.toLocaleString()} ${
                                  opportunity.opportunity_value
                                      .customer_opportunity_value_currency
                              }`
                            : `$${opportunity.opportunity_value.source_opportunity_value.toLocaleString()} ${
                                  opportunity.opportunity_value
                                      .source_opportunity_value_currency
                              }`}
                    </PDFText>
                </PDFView>
            </PDFView>
        ) : null}

        {opportunity.insights?.map((insight: InsightInOpportunity) =>
            RenderInsight(insight, opportunity.is_intelligence_feeds)
        )}

        {!!opportunity.contacts?.length ? (
            <PDFView style={pdfStyle.insightItemContainer}>
                <PDFView style={pdfStyle.insightItemTitle}>
                    <PDFText>Contacts</PDFText>
                </PDFView>
                <PDFView style={pdfStyle.insightItemValue}>
                    <PDFView style={pdfStyle.contactsContainer}>
                        {opportunity.contacts.map((contact, index) => (
                            <PDFView key={index} style={pdfStyle.contactItem}>
                                {RenderContact(contact)}
                            </PDFView>
                        ))}
                    </PDFView>
                </PDFView>
            </PDFView>
        ) : null}
    </PDFPage>
);

export default RenderOpportunity;
