import moment from "moment";

import { OpportunityTypeEnum } from "constants/opportunityTypes";

import InsightCard from "../InsightCard";

const InsightList = ({
    insights,
    isEditModeActive,
    isLoadingInsight,
    isLoadingReportsForTeam,
    opportunityType,
    reports,
    sources,
    setInsightForCompanyRemoval,
    setInsightForDrinkingWaterSystemRemoval,
    setInsightForEdit,
    setInsightForIntelligenceAddition,
    setInsightForLocationRemoval,
    setInsightForSourceCreation,
    setInsightForWasteWaterSystemRemoval,
    setSourceForEdit,
    toggleOverlay,
    isStaff,
}) => {
    const isLoading =
        isLoadingInsight ||
        isLoadingReportsForTeam ||
        reports.length === 0 ||
        insights.length === 0;

    if (
        insights.length === 0 ||
        (opportunityType === OpportunityTypeEnum.REPORT && reports.length === 0)
    ) {
        return null;
    }

    const dateSortedInsights =
        opportunityType === OpportunityTypeEnum.REPORT
            ? insights.sort((firstInsight, secondInsight) => {
                  const firstReport = reports.find(
                      (report) => report.id === firstInsight.report
                  );
                  const secondReport = reports.find(
                      (report) => report.id === secondInsight.report
                  );
                  const firstDate = moment(firstReport?.date);
                  const secondDate = moment(secondReport?.date);
                  if (firstDate === secondDate) {
                      return 0;
                  }
                  if (firstDate > secondDate) {
                      return -1;
                  }
                  return 1;
              })
            : insights;

    return dateSortedInsights.map((insight, index) => {
        return (
            <InsightCard
                key={insight.id}
                isLoading={isLoading}
                setInsightForEdit={setInsightForEdit}
                toggleOverlay={toggleOverlay}
                setInsightForIntelligenceAddition={setInsightForIntelligenceAddition}
                insight={insight}
                insights={insights}
                index={index}
                reports={reports}
                opportunityType={opportunityType}
                sources={sources}
                isStaff={isStaff}
                isEditModeActive={isEditModeActive}
                setSourceForEdit={setSourceForEdit}
                setInsightForLocationRemoval={setInsightForLocationRemoval}
                setInsightForWasteWaterSystemRemoval={
                    setInsightForWasteWaterSystemRemoval
                }
                setInsightForDrinkingWaterSystemRemoval={
                    setInsightForDrinkingWaterSystemRemoval
                }
                setInsightForCompanyRemoval={setInsightForCompanyRemoval}
                setInsightForSourceCreation={setInsightForSourceCreation}
            />
        );
    });
};

export default InsightList;
