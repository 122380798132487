import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import OverviewCard from "components/cdv/OverviewCard";
import { additionalColors } from "constants/colors";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { useGetSingleTeam } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetFilteredIndicatorGroups } from "reactQuery/hooks/useIndicatorGroups";
import { AllIndicatorsPathname } from "routes/IntelligenceFeeds";
import { IndicatorFeedItem } from "types/additionalTypes";
import { redirectIfTeamHasIntelligenceFeedsProduct } from "utils/redirect";

const styles = makeStyles(() => ({
    page: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: "25px",
    },
    createReportBtn: {
        marginLeft: "auto",
        marginRight: 0,
    },
    title: {
        color: additionalColors.body,
        fontSize: "24px",
        marginBottom: "20px",
    },
    fullWidth: {
        width: "100%",
    },
}));

export const AllFeeds = ({ teamId }: { teamId: number }) => {
    const classes = styles();

    redirectIfTeamHasIntelligenceFeedsProduct(AllIndicatorsPathname, teamId);

    const getFilteredIndicatorGroups = useGetFilteredIndicatorGroups(
        { content_type: "team", object_id: teamId },
        true
    );

    const indicatorFeedCards =
        getFilteredIndicatorGroups.isSuccess &&
        getFilteredIndicatorGroups.data.results?.length > 0
            ? getFilteredIndicatorGroups.data.results.map(
                  (feed: IndicatorFeedItem, index: number) => {
                      return (
                          <OverviewCard
                              cardType="indicator"
                              feed={feed}
                              id={feed.id}
                              key={index}
                              teamId={teamId}
                          />
                      );
                  }
              )
            : null;

    const teamQuery = useGetSingleTeam(teamId);
    const teamWithProducts = teamQuery?.data;
    const indicatorFeedProductName = teamWithProducts?.products?.find(
        (product) => product.product_type === ProductTypeEnum.INDICATOR_FEEDS
    )?.name;

    return (
        <div className={classes.page}>
            <div>
                {getFilteredIndicatorGroups.isLoading ? (
                    <Grid container spacing={4} style={{ marginBottom: "50px" }}>
                        <LinearProgress className={classes.fullWidth} />
                    </Grid>
                ) : indicatorFeedCards ? (
                    <>
                        <Typography className={classes.title}>
                            {indicatorFeedProductName}
                        </Typography>
                        <Grid container spacing={4} style={{ marginBottom: "50px" }}>
                            {indicatorFeedCards}
                        </Grid>
                    </>
                ) : (
                    <Typography>No Feeds found</Typography>
                )}
            </div>
        </div>
    );
};

export default AllFeeds;
