import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { ownerKeys } from "reactQuery/keys";

import { Owner } from "./apiTypes";

export const useGetOwnerById = (
    id: number,
    enabled: boolean
): UseQueryResult<Owner> => {
    const queryKey = ownerKeys.single(id);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Owner> =>
            (await nautilusGetRequest(`/api/universe/owners/${id}`)).data,
        enabled,
    });
};

export const useGetOwnersByQuery = (
    query: string,
    enabled: boolean
): UseQueryResult<Owner[]> => {
    const queryKey = ownerKeys.search(query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Owner[]> =>
            (
                await nautilusGetRequest(
                    "/api/universe/owners/",
                    `?limit=100&search=${query}`
                )
            ).data?.results,
        enabled,
    });
};
