import React from "react";
import Highlighter from "react-highlight-words";

import { additionalColors } from "constants/colors";

function ColumnData({ searchTextValue, fieldValue }) {
    return (
        <div style={{ fontSize: "10pt" }}>
            {searchTextValue ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: additionalColors.lightOrange,
                        padding: 0,
                    }}
                    searchWords={[searchTextValue]}
                    autoEscape
                    textToHighlight={fieldValue}
                />
            ) : (
                fieldValue
            )}
        </div>
    );
}

export default ColumnData;
