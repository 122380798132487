// TODO: change wasteWaterFacility variables to facility
import { all } from "redux-saga/effects";

import {
    searchForCompanySaga,
    searchForContactSaga,
    searchForDrinkingWaterSystemSaga,
    searchForLocationSaga,
    searchForWasteWaterFacilitySaga,
} from "./universeStore/sagas";
import {
    analyticsEventSaga,
    analyticsPageSaga,
    logInSaga,
    logOutSaga,
    passwordChangeRequestSaga,
    passwordResetConfirmRequestSaga,
    ProfileDataSaga,
    requestPasswordResetSaga,
    signupLinkVerificationSaga,
    teamByIdDataSaga,
    teamDataSaga,
    userDataSaga,
} from "./userStore/sagas";

export default function* rootSaga() {
    yield all([
        userDataSaga(),
        teamByIdDataSaga(),
        teamDataSaga(),
        ProfileDataSaga(),
        logInSaga(),
        logOutSaga(),
        requestPasswordResetSaga(),
        passwordChangeRequestSaga(),
        passwordResetConfirmRequestSaga(),
        searchForCompanySaga(),
        searchForContactSaga(),
        searchForDrinkingWaterSystemSaga(),
        searchForLocationSaga(),
        searchForWasteWaterFacilitySaga(),
        analyticsEventSaga(),
        analyticsPageSaga(),
        signupLinkVerificationSaga(),
    ]);
}
