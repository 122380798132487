import { useEffect, useRef } from "react";

export const useSearchDebounced = (
    action: (...args: any[]) => void,
    timeoutDelay: number = 800
) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
    useEffect(() => {
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    const debounceSearch = (...args: any[]) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        if (args) {
            timeoutRef.current = setTimeout(() => {
                action(...args);
            }, timeoutDelay);
        }
    };
    return debounceSearch;
};
