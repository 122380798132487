import { Tag } from "antd";

import Text from "components/atoms/Text";
import TagsList from "components/organisms/TagsList";
import { IndicatorGroup } from "reactQuery/hooks/apiTypes";

import HeaderIcons from "./HeaderIcons";
import { PaginatedTableCommonColumnProps } from "../types";
import { pathBuilder } from "../utils";

interface TagsColumnProps extends PaginatedTableCommonColumnProps {
    clickParams?: any;
    colors?: { [key: string]: string };
    displayMap?: {
        [key: string]: string;
    };
    filtersMap?: any;
}

const tagsColumn = ({
    allowFiltering = true,
    allowSorting = true,
    clickParams,
    colors,
    displayMap,
    filteredInfo,
    filters,
    filtersMap,
    history,
    isLoading,
    key,
    searchTextValue,
    sortedInfo,
    styles,
    title,
}: TagsColumnProps) => {
    const filterOptions = filtersMap
        ? filtersMap
        : filters
        ? filters.map((filter) => ({ text: filter, value: String(filter) }))
        : undefined;

    return {
        title: title,
        dataIndex: key,
        key: key,
        render: (_, record) => {
            switch (key) {
                case "indicator":
                case "indicator_groups":
                    return (
                        <div
                            className="scrollbox"
                            style={{
                                maxHeight: "150px",
                                overflowY: "auto",
                                overflowX: "clip",
                                scrollBehavior: "smooth",
                            }}
                        >
                            <>
                                {record[key].map(
                                    (group: IndicatorGroup, index: number) => {
                                        return (
                                            <div
                                                key={index}
                                                style={
                                                    clickParams
                                                        ? { cursor: "pointer" }
                                                        : {}
                                                }
                                                onClick={() => {
                                                    if (clickParams) {
                                                        const path = pathBuilder({
                                                            excludeQueryParams:
                                                                clickParams.excludeQueryParams,
                                                            id: group.indicator.id,
                                                            path: clickParams.path,
                                                            queryParam:
                                                                clickParams.queryParam,
                                                            setQueryParam:
                                                                clickParams.setQueryParam,
                                                        });
                                                        history.push(path);
                                                    }
                                                }}
                                            >
                                                <TagsList
                                                    group={group}
                                                    key={index}
                                                    showTagsInitiativeTypes={
                                                        key !== "indicator"
                                                    }
                                                    hasMarginBottom={
                                                        index <
                                                            record[key].length - 1 ||
                                                        record.team_customer_tags
                                                            ?.length > 0
                                                    }
                                                    maxWidth="200px"
                                                    indicatorType="indicatorGroup"
                                                    searchTextValue={searchTextValue}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                                {record.team_customer_tags?.length > 0 ? (
                                    <TagsList
                                        maxWidth="200px"
                                        searchTextValue={searchTextValue}
                                        hasMarginBottom={false}
                                        indicatorType="teamCustomerTags"
                                        tags={record.team_customer_tags}
                                    />
                                ) : null}
                            </>
                        </div>
                    );
                default:
                    let color = colors?.[record[key]];
                    return (
                        <>
                            <Tag color={color}>
                                <Text searchTextValue={searchTextValue}>
                                    {displayMap?.[record?.[key]] ??
                                        String(record?.[key])}
                                </Text>
                            </Tag>
                        </>
                    );
            }
        },
        filterIcon: (
            <HeaderIcons
                styles={styles}
                isLoading={isLoading}
                showFilteringIcon={true}
            />
        ),
        filterDropdown: isLoading ? <></> : undefined,
        filteredValue: filteredInfo[key] || null,
        filterMultiple: filterOptions?.length > 2 ? true : false,
        filters: isLoading ? undefined : allowFiltering ? filterOptions : null,
        filterSearch: filterOptions?.length > 2 ? true : false,
        sorter: isLoading ? undefined : allowSorting ? () => 0 : null,
        sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
    };
};

export default tagsColumn;
