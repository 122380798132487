export const FEED_TYPES = {
    ALL_FEEDBACK: "allFeedback",
    ALL_INDICATORS: "allIndicators",
    ALL_INSIGHTS: "allInsights",
    ALL_OWNERS: "allOwners",
    ALL_TEAM_INSIGHTS: "allTeamInsights",
    ALL_ASSIGNEES: "allAssignees",
    INDICATOR_FEED: "indicator",
    LW_ALL_RESULTS: "LW_ALL_RESULTS",
    LW_MY_QUEUE: "LW_MY_QUEUE",
    LW_MY_ACTIONED: "LW_MY_ACTIONED",
    LW_READY_FOR_QC: "LW_READY_FOR_QC",
    MY_INSIGHTS: "myInsights",
    PRIORITY_INSIGHTS: "priorityInsights",
    SINGLE_ASSIGNEE: "singleAssignee",
    SINGLE_FEEDBACK: "singleFeedback",
    SINGLE_INDICATOR: "singleIndicator",
    SINGLE_OWNER: "singleOwner",
    SINGLE_REPORT: "report",
    TEAM_ACTIVITY: "teamActivity",
};

export const INSIGHT_VIEWS = [
    FEED_TYPES.ALL_INSIGHTS,
    FEED_TYPES.ALL_TEAM_INSIGHTS,
    FEED_TYPES.INDICATOR_FEED,
    FEED_TYPES.MY_INSIGHTS,
    FEED_TYPES.PRIORITY_INSIGHTS,
    FEED_TYPES.SINGLE_ASSIGNEE,
    FEED_TYPES.SINGLE_FEEDBACK,
    FEED_TYPES.SINGLE_INDICATOR,
    FEED_TYPES.SINGLE_OWNER,
    FEED_TYPES.SINGLE_REPORT,
];

export const LEGACY_FEED_TYPES = [
    FEED_TYPES.ALL_INSIGHTS,
    FEED_TYPES.INDICATOR_FEED,
    FEED_TYPES.SINGLE_REPORT,
];

export const LW_VIEWS = [
    FEED_TYPES.LW_ALL_RESULTS,
    FEED_TYPES.LW_MY_QUEUE,
    FEED_TYPES.LW_MY_ACTIONED,
    FEED_TYPES.LW_READY_FOR_QC,
];
