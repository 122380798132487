import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import {
    useGetSingleTeam,
    useGetWhoami,
} from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetIndicatorGroupById } from "reactQuery/hooks/useIndicatorGroups";
import { IndicatorFeedsBasePathname } from "routes/IndicatorFeeds";
import { IntelligenceFeedsBasePathname } from "routes/IntelligenceFeeds";

export const redirectIfTeamHasIntelligenceFeedsProduct = (
    redirect: string,
    teamId: number,
    indicatorGroupId?: number
) => {
    const { push } = useHistory();

    const teamHasIntelligenceFeeds = hasIntelligenceFeeds(teamId);
    const indicatorGroupQuery = useGetIndicatorGroupById(
        indicatorGroupId,
        !!indicatorGroupId
    );

    let pushURL = `${redirect}?team_id=${teamId}`;
    if (indicatorGroupQuery?.data?.indicator?.id >= 0)
        pushURL = `${pushURL}&indicator_id=${indicatorGroupQuery.data.indicator.id}`;

    useEffect(() => {
        if (
            teamHasIntelligenceFeeds &&
            (!!indicatorGroupId ? indicatorGroupQuery.isSuccess : true)
        ) {
            push(pushURL);
        }
    }, [teamHasIntelligenceFeeds, indicatorGroupQuery.isSuccess]);
};

export const redirectIfUserHasNoAccessToMyInsights = (redirect: string) => {
    const { push } = useHistory();

    const access = userHasAccessToMyInsights();

    useEffect(() => {
        if (!access) push(redirect);
    }, [access]);
};

export const redirectIfUserHasNoAccessToAssignedInsights = (redirect: string) => {
    const { push } = useHistory();

    const access = userHasAccessToAssignedInsights();

    useEffect(() => {
        if (!access) push(redirect);
    }, [access]);
};

export const hasIntelligenceFeeds = (teamId: number): boolean => {
    const teamQuery = useGetSingleTeam(teamId);
    const hasIntelligenceFeeds = !!teamQuery.data?.products?.find(
        (product) => product.product_type === ProductTypeEnum.INTELLIGENCE_FEEDS
    );
    return hasIntelligenceFeeds;
};

export const isIntelligenceFeedsRoute = (): boolean => {
    const { path: routePath } = useRouteMatch();
    return routePath.startsWith(IntelligenceFeedsBasePathname);
};

export const isIndicatorFeedsRoute = (): boolean => {
    const { path: routePath } = useRouteMatch();
    return routePath.startsWith(IndicatorFeedsBasePathname);
};

export const userHasAccessToMyInsights = (): boolean => {
    const getWhoamiQuery = useGetWhoami();
    const { data } = getWhoamiQuery;
    const userType = data?.user?.user_type;

    const userTypesForMyInsights = ["Sales User"];
    return userTypesForMyInsights.includes(userType);
};

export const userHasAccessToAssignedInsights = (): boolean => {
    const getWhoamiQuery = useGetWhoami();
    const { data } = getWhoamiQuery;
    const userType = data?.user?.user_type;

    const userTypesNotForAssignedInsights = ["Sales User"];
    return !userTypesNotForAssignedInsights.includes(userType);
};

export const extractNextUrlParameter = () => {
    const href = window.location.href;
    const url = new URL(href);
    return url.searchParams.has("next") ? url.search.split("?next=")?.[1] : "/";
};
