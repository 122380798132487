import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import StyledButton from "components/atoms/StyledButton";
import { authFormTheme } from "constants/themes";
import {
    analyticsEvent,
    passwordChangeClearErrors,
    passwordChangeRequest,
} from "stores/userStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import {
    passwordChangeErrorsSelector,
    passwordChangeIsAttemptingSelector,
    passwordChangeSuccessSelector,
} from "stores/userStore/selectors";

export const ERROR_MESSAGES = {
    currentPasswordMissing: "Current password is required",
    currentPasswordIncorrect: "Current password is incorrect.",
    newPasswordMissing: "New password is required",
    confirmPasswordMissing: "Password confirmation is required",
    passwordRequirements:
        "Password must contain at least 9 characters, must include at least 1 letter, and must not contain your name or work email address.",
    passwordsDoNotMatch: "Passwords do not match",
};

let passwordChangeFormSchema = Yup.object().shape({
    currentPassword: Yup.string().required(ERROR_MESSAGES.currentPasswordMissing),
    newPassword: Yup.string()
        .required(ERROR_MESSAGES.passwordsDoNotMatch)
        .min(9, ERROR_MESSAGES.passwordRequirements),
    confirmPassword: Yup.string()
        .required(ERROR_MESSAGES.confirmPasswordMissing)
        .oneOf([Yup.ref("newPassword")], ERROR_MESSAGES.passwordsDoNotMatch),
});

const useStyles = makeStyles(authFormTheme);

export const PasswordChangeForm = ({
    errors,
    handleChange,
    handleSubmit,
    isLoading,
    passwordChangeClearErrors,
    responseErrors,
    values: { currentPassword, newPassword, confirmPassword },
}) => {
    const classes = useStyles();
    return (
        <Form className={classes.root}>
            <div className={classes.fullWidth}>
                <TextField
                    className={classes.textField}
                    error={
                        "currentPassword" in errors ||
                        "currentPassword" in responseErrors
                    }
                    helperText={
                        errors.currentPassword || responseErrors.currentPassword || null
                    }
                    id="currentPassword-input"
                    label="Current Password"
                    name="currentPassword"
                    onChange={(e) => {
                        if ("currentPassword" in responseErrors) {
                            passwordChangeClearErrors({
                                fieldToClear: "currentPassword",
                            });
                        }
                        handleChange(e);
                    }}
                    type="password"
                    value={currentPassword}
                    variant="outlined"
                />
            </div>
            <div>
                <TextField
                    className={classes.textField}
                    error={"newPassword" in errors || "newPassword" in responseErrors}
                    helperText={
                        responseErrors.newPassword ||
                        ERROR_MESSAGES.passwordRequirements
                    }
                    id="newPassword-input"
                    label="New Password"
                    name="newPassword"
                    onChange={(e) => {
                        if ("newPassword" in responseErrors) {
                            passwordChangeClearErrors({ fieldToClear: "newPassword" });
                        }
                        handleChange(e);
                    }}
                    type="password"
                    value={newPassword}
                    variant="outlined"
                />
            </div>
            <div className={classes.fullWidth}>
                <TextField
                    className={classes.textField}
                    error={
                        "confirmPassword" in errors ||
                        "confirmPassword" in responseErrors
                    }
                    helperText={
                        errors.confirmPassword || responseErrors.confirmPassword || null
                    }
                    id="confirmPassword-input"
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={handleChange}
                    type="password"
                    value={confirmPassword}
                    variant="outlined"
                />
            </div>
            <StyledButton
                color="primary"
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                data-testid="password-change-form-btn"
            >
                <Typography>Change Password</Typography>
                {isLoading ? (
                    <CircularProgress
                        className={classes.loadingSpinner}
                        id="loading-spinner"
                        size={24}
                    />
                ) : null}
            </StyledButton>
        </Form>
    );
};

export const EnhancedPasswordChangeForm = ({
    analyticsEvent,
    passwordChangeRequest,
    passwordChangeClearErrors,
    changedSuccessfully,
    errors = {},
    isAttempting,
}) => {
    const onSubmit = (formData) => {
        analyticsEvent({
            action: eventActions.CLICKED,
            category: eventCategories.FORMS,
            label: "Change Password - Change Password",
        });
        passwordChangeRequest(formData);
    };
    return (
        <Formik
            initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            }}
            onSubmit={onSubmit}
            validationSchema={passwordChangeFormSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formikProps) => (
                <PasswordChangeForm
                    {...formikProps}
                    isLoading={isAttempting}
                    responseErrors={errors}
                    passwordChangeClearErrors={passwordChangeClearErrors}
                />
            )}
        </Formik>
    );
};

const mapStateToProps = (state: any) => {
    return {
        changedSuccessfully: passwordChangeSuccessSelector(state as never),
        errors: passwordChangeErrorsSelector(state as never),
        isAttempting: passwordChangeIsAttemptingSelector(state as never),
    };
};

const ConnectedEnhancedPasswordChangeForm = connect(mapStateToProps, {
    analyticsEvent,
    passwordChangeRequest,
    passwordChangeClearErrors,
})(EnhancedPasswordChangeForm);

export default ConnectedEnhancedPasswordChangeForm;
