import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Form, Input, Space, Button } from "antd";
import { useEffect, useState } from "react";

import { Notes } from "reactQuery/hooks/apiTypes";
import { usePostNote, usePatchNote, useRemoveNote } from "reactQuery/hooks/useNotes";
import { noteKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore, ModalNames } from "stores/zustandStore";
export const IntelligenceNotesModal = () => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const setModalVisible = useModalStore((state) => state.setModalVisible);
    const setModalHidden = useModalStore((state) => state.setModalHidden);
    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);
    const [note, setNote] = useState(modalInitialValues?.note?.notes || null);

    const [formIsComplete, setFormIsComplete] = useState(false);

    const usePostNoteQuery = usePostNote();
    const usePatchNoteQuery = usePatchNote();
    const useRemoveNoteQuery = useRemoveNote();
    const queryClient = useQueryClient();

    const [form] = Form.useForm();
    const formId = "intelligence-notes-modal-form";

    useEffect(() => {
        if (openModal === ModalNames.EditIntelligenceNotes) {
            form.setFieldValue("note", modalInitialValues.note.notes);
        }
    }, [modalInitialValues]);

    const onModalOk = () => {
        if (!formIsComplete) {
            return;
        }
        if (openModal === ModalNames.EditIntelligenceNotes) {
            const patchBody = {
                notes: note,
                id: modalInitialValues.note.id,
            };
            usePatchNoteQuery.mutate(patchBody as Notes, {
                onSuccess: () => {
                    setModalHidden();
                    displaySuccessSnack({ message: "Note was successfully updated" });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({ message: "Failed to update note" });
                },
            });
        } else {
            const postBody = {
                notes: note,
                insight: modalInitialValues.insight.id,
            };

            usePostNoteQuery.mutate(postBody as Notes, {
                onSuccess: () => {
                    setModalHidden();
                    displaySuccessSnack({ message: "Note was successfully created" });
                },
                onError: () => {
                    setModalHidden();
                    displayErrorSnack({ message: "Failed to create a note" });
                },
            });
        }

        setFormIsComplete(false);
    };
    const onModalCancel = () => {
        setModalHidden();
        setFormIsComplete(false);
        form.resetFields();
    };

    const onOpenRemoveModal = () => {
        setModalVisible(ModalNames.RemoveIntelligenceNotes, {
            note: modalInitialValues.note,
            insight: modalInitialValues.insight,
        });
    };

    const onOpenEditModal = () => {
        setModalVisible(ModalNames.EditIntelligenceNotes, {
            note: modalInitialValues.note,
        });
    };

    const onModalRemove = () => {
        const removeBody = modalInitialValues.note.id;
        useRemoveNoteQuery.mutate(removeBody, {
            onSuccess: () => {
                const queryKey = noteKeys.filter({
                    insightId: modalInitialValues.insight.id,
                });
                queryClient.invalidateQueries(queryKey).then(() => {
                    setModalHidden();
                    displaySuccessSnack({ message: "Note removed successfully" });
                });
            },
            onError: () => {
                setModalHidden();
                displayErrorSnack({ message: "Error removing note" });
            },
        });
        setFormIsComplete(false);
    };

    return (
        <Modal
            open={
                openModal === ModalNames.EditIntelligenceNotes ||
                openModal === ModalNames.AddIntelligenceNotes ||
                openModal === ModalNames.RemoveIntelligenceNotes
            }
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            footer={null}
        >
            {openModal === ModalNames.RemoveIntelligenceNotes ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <Typography.Title
                        level={5}
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                    >
                        Are you sure that you would like to remove this note from this
                        insight? Once removed, this action cannot be reversed.
                    </Typography.Title>
                    <Typography>
                        <b>Note:</b> {modalInitialValues.note?.notes}
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            gap: "10px",
                        }}
                    >
                        <Button key="remove" type="default" onClick={onOpenEditModal}>
                            Cancel
                        </Button>

                        <Button
                            key="link"
                            type="primary"
                            htmlType="submit"
                            onClick={onModalRemove}
                            loading={usePatchNoteQuery.isLoading}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            ) : (
                <Form
                    id={formId}
                    form={form}
                    name={formId}
                    layout="vertical"
                    style={{ width: "80%", margin: "auto" }}
                    preserve={false}
                >
                    <div style={{ textAlign: "center", marginBottom: "25px" }}>
                        <Space>
                            <Typography.Title level={3} style={{ margin: 0 }}>
                                {openModal === ModalNames.AddIntelligenceNotes
                                    ? "Add Intelligence Note"
                                    : "Edit Intelligence Note"}
                            </Typography.Title>
                        </Space>
                    </div>
                    <div style={{ marginBottom: "4px" }}>
                        Intelligence Note. See{" "}
                        <a
                            target="_blank"
                            href="https://www.markdownguide.org/cheat-sheet/"
                        >
                            markdown
                        </a>{" "}
                        for formatting.
                    </div>
                    <Form.Item
                        name="note"
                        rules={[{ required: true, message: "Please input a note" }]}
                    >
                        <Input.TextArea
                            name="intelligence note"
                            rows={4}
                            onChange={(e) => {
                                setNote(e.target.value);
                                if (e.target.value.length > 0) {
                                    setFormIsComplete(true);
                                } else {
                                    setFormIsComplete(false);
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item shouldUpdate>
                        <div
                            style={{
                                display: "flex",
                                justifyContent:
                                    openModal === ModalNames.EditIntelligenceNotes
                                        ? "space-between"
                                        : "right",
                                marginTop: "20px",
                            }}
                        >
                            {openModal === ModalNames.EditIntelligenceNotes ? (
                                <Button
                                    key="remove"
                                    type="default"
                                    onClick={onOpenRemoveModal}
                                >
                                    Remove
                                </Button>
                            ) : null}

                            <div>
                                <Button
                                    key="submit"
                                    type="default"
                                    onClick={onModalCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    key="link"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onModalOk}
                                    style={{ marginLeft: "10px" }}
                                    loading={usePatchNoteQuery.isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};
