import {
    UseMutationResult,
    useQuery,
    UseQueryResult,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";

import {
    nautilusDeleteRequest,
    nautilusGetRequest,
    nautilusPostRequest,
    nautilusPatchRequest,
} from "network";
import { indicatorGroupsKeys } from "reactQuery/keys";

import {
    IndicatorGroupWrite,
    PatchedIndicatorGroupWrite,
    IndicatorGroup,
} from "./apiTypes";

interface deleteIndicatorGroupPayloadType {
    indicatorGroupId: number;
    insightId: number;
}

export interface ExclusionIndicatorGroup extends IndicatorGroup {
    exclusions: string[];
}

export interface PaginatedExclusionIndicatorGroup {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ExclusionIndicatorGroup[];
}

const createIndicatorGroupRequest = async (
    indicatorGroup: IndicatorGroupWrite
): Promise<IndicatorGroupWrite> => {
    return (
        await nautilusPostRequest(
            `/api/target_reports/indicator_groups/`,
            indicatorGroup
        )
    ).data;
};

export function useCreateIndicatorGroup(): UseMutationResult<IndicatorGroupWrite> {
    const queryClient = useQueryClient();
    return useMutation(
        (indicatorGroup: IndicatorGroupWrite): Promise<IndicatorGroupWrite> =>
            createIndicatorGroupRequest(indicatorGroup),
        {
            onSuccess: async (data) => {
                const key = indicatorGroupsKeys.filter({
                    contentType: "insight",
                    objectId: data.object_id,
                });
                await queryClient.invalidateQueries(key);
            },
        }
    );
}

const patchIndicatorGroupRequest = async (
    indicatorGroup: PatchedIndicatorGroupWrite,
    id: number
): Promise<IndicatorGroupWrite> => {
    return (
        await nautilusPatchRequest(
            `/api/target_reports/indicator_groups/${id}/`,
            indicatorGroup
        )
    ).data;
};

export function usePatchIndicatorGroup(): UseMutationResult<IndicatorGroupWrite> {
    const queryClient = useQueryClient();
    return useMutation(
        (modifyIndicatorGroup: IndicatorGroupWrite): Promise<IndicatorGroupWrite> => {
            const { id, ...indicatorGroup } = modifyIndicatorGroup;
            return patchIndicatorGroupRequest(indicatorGroup, id);
        },
        {
            onSuccess: async (data) => {
                const key = indicatorGroupsKeys.filter({
                    contentType: "insight",
                    objectId: data.object_id,
                });
                await queryClient.invalidateQueries(key);
            },
        }
    );
}

const deleteIndicatorGroupRequest = async (id: number): Promise<void> => {
    await nautilusDeleteRequest(`/api/target_reports/indicator_groups/${id}/`);
};

export function useDeleteIndicatorGroup(): UseMutationResult<void> {
    const queryClient = useQueryClient();
    return useMutation(
        (
            deleteIndicatorGroupPayload: deleteIndicatorGroupPayloadType
        ): Promise<void> => {
            return deleteIndicatorGroupRequest(
                deleteIndicatorGroupPayload.indicatorGroupId
            );
        },
        {
            onSuccess: async (_, data) => {
                const key = indicatorGroupsKeys.filter({
                    contentType: "insight",
                    objectId: data.insightId,
                });
                await queryClient.invalidateQueries(key);
            },
        }
    );
}

// TODO: Type file suggests to use PaginatedIndicatorGroupList as the return type for these 2 functions
const getFilteredIndicatorGroups = async ({
    content_type,
    object_id,
}: PatchedIndicatorGroupWrite) => {
    const queryString = `?content_type=${content_type}&object_id=${object_id}`;
    return (
        await nautilusGetRequest("/api/target_reports/indicator_groups/", queryString)
    ).data;
};

export const useGetFilteredIndicatorGroups = (
    { content_type, object_id }: PatchedIndicatorGroupWrite,
    enabled: boolean = true
) => {
    const queryKey = indicatorGroupsKeys.filter({
        contentType: content_type,
        objectId: object_id,
    });
    return useQuery({
        queryKey,
        queryFn: () => getFilteredIndicatorGroups({ content_type, object_id }),
        enabled,
    });
};

export const useGetIndicatorGroupById = (
    id: number,
    enabled: boolean
): UseQueryResult<IndicatorGroup> => {
    const queryKey = indicatorGroupsKeys.single(id);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<IndicatorGroup> =>
            (await nautilusGetRequest(`/api/target_reports/indicator_groups/${id}`))
                .data,
        enabled,
    });
};
