import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
    DeliveryStatusEnum,
    OpportunityActionsTakenRead,
} from "reactQuery/hooks/apiTypes";

export interface Snack {
    key?: number;
    message: string;
    options?: {};
}
interface SnackStore {
    snacks: Snack[];
    displaySuccessSnack: (snack: Snack) => void;
    displayErrorSnack: (snack: Snack) => void;
}
export const useSnackStore = create<SnackStore>((set) => ({
    snacks: [],
    displaySuccessSnack: (snack) =>
        set((state) => ({
            snacks: [
                ...state.snacks,
                {
                    key: snack.key ? snack.key : Math.random(),
                    message: snack.message,
                    options: { variant: "success" },
                },
            ],
        })),
    displayErrorSnack: (snack) =>
        set((state) => ({
            snacks: [
                ...state.snacks,
                {
                    key: snack.key ? snack.key : Math.random(),
                    message: snack.message,
                    options: { variant: "error" },
                },
            ],
        })),
}));

export enum ModalModeOptions {
    Remove = "Remove",
    Add = "Add",
    Edit = "Edit",
}

export enum ModalNames {
    EditTimeline = "EditTimeline",
    AddTimeline = "AddTimeline",
    RemoveTimeline = "RemoveTimeline",
    AddOpportunity = "AddOpportunity",
    RemoveOpportunity = "RemoveOpportunity",
    EditOpportunity = "EditOpportunity",
    AddContact = "AddContact",
    EditContact = "EditContact",
    RemoveContact = "RemoveContact",
    AddIntelligenceNotes = "AddIntelligenceNotes",
    EditIntelligenceNotes = "EditIntelligenceNotes",
    RemoveIntelligenceNotes = "RemoveIntelligenceNotes",
    AddIntelligence = "AddIntelligence",
}

interface ModalStore {
    // Revised modal visibility system
    openModal: ModalNames | null;
    modalInitialValues: any;
    setModalVisible: (modalName: ModalNames, initalValues?: Object) => void;
    setModalHidden: () => void;

    // The following fields will be deprecated and use the new modal visibility system
    opportunityAssigneeModalVisibility: boolean;
    setOpportunityAssigneeModalVisibility: (visibility: boolean) => void;
    logProgressModalVisibility: boolean;
    logProgressModalActionTaken: OpportunityActionsTakenRead;
    logProgressModalMode: ModalModeOptions;
    setLogProgressModalVisibility: (visibility: boolean) => void;
    setLogProgressModalActionTaken: (logProgressModalActionTaken: any) => void;
    setLogProgressModalMode: (logProgressModalMode: ModalModeOptions) => void;

    deliveryStatusModalParams: {
        visibility: boolean;
        deliveryStatus?: DeliveryStatusEnum;
        insightId?: number;
    };
    setDeliveryStatusModalParams: ({
        visibility,
        deliveryStatus,
        insightId,
    }: {
        visibility: boolean;
        deliveryStatus?: DeliveryStatusEnum;
        insightId?: number;
    }) => void;
}

export const useModalStore = create<ModalStore>((set) => ({
    openModal: null,
    modalInitialValues: {},
    setModalVisible: (modalName, modalInitialValues) =>
        set(() => ({
            openModal: modalName,
            modalInitialValues: modalInitialValues,
        })),
    setModalHidden: () =>
        set(() => ({
            openModal: null,
        })),

    // To be deprecated fields
    opportunityAssigneeModalVisibility: false,
    setOpportunityAssigneeModalVisibility: (visibility) =>
        set(() => ({
            opportunityAssigneeModalVisibility: visibility,
        })),
    logProgressModalVisibility: false,
    logProgressModalActionTaken: null,
    logProgressModalMode: null,
    setLogProgressModalVisibility: (visibility) =>
        set(() => ({
            logProgressModalVisibility: visibility,
        })),
    setLogProgressModalActionTaken: (logProgressModalActionTaken) =>
        set(() => ({
            logProgressModalActionTaken: logProgressModalActionTaken,
        })),
    setLogProgressModalMode: (logProgressModalMode) =>
        set(() => ({
            logProgressModalMode: logProgressModalMode,
        })),

    deliveryStatusModalParams: {
        visibility: false,
        deliveryStatus: undefined,
        insightId: undefined,
    },
    setDeliveryStatusModalParams: ({ visibility, deliveryStatus, insightId }) =>
        set(() => ({
            deliveryStatusModalParams: { visibility, deliveryStatus, insightId },
        })),
}));

interface UserEventStoreType {
    data: { [key: number]: Array<string> };
    setEventForUser: ({
        userId,
        eventName,
    }: {
        userId: number;
        eventName: string;
    }) => void;
    getEventForUser: ({
        userId,
        eventName,
    }: {
        userId: number;
        eventName: string;
    }) => boolean;
}

export const INSIGHT_HIDDEN_BY_USER = "INSIGHT_HIDDEN_BY_USER";

export const useUserEventsStore = create<UserEventStoreType>()(
    persist(
        (set, get) => ({
            data: {},
            setEventForUser: ({ userId, eventName }) =>
                set((state) => {
                    let hasValue = state.data[userId]?.includes(eventName);

                    if (!hasValue) {
                        state.data[userId] = state.data[userId]
                            ? [...state.data[userId], eventName]
                            : [eventName];
                    }
                    return { data: state.data };
                }),
            getEventForUser: ({ userId, eventName }) => {
                return get().data[userId]?.includes(eventName) ? true : false;
            },
        }),
        {
            name: "user-events-storage",
        }
    )
);
