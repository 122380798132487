export const actionTypes = {
    CLOSE_ALL_OVERLAYS: "CLOSE_ALL_OVERLAYS",
    CLOSE_ALL_SNACKS: "CLOSE_ALL_SNACKS",
    CLOSE_SNACK: "CLOSE_SNACK",
    DISPLAY_SNACK: "DISPLAY_SNACK",
    TOGGLE_EDIT_MODE: "TOGGLE_EDIT_MODE",
    TOGGLE_OVERLAY: "TOGGLE_OVERLAY",
};

export const displaySnack = ({ key, message, options }) => ({
    type: actionTypes.DISPLAY_SNACK,
    payload: {
        key: key ? key : Math.random(),
        message,
        options: options ? options : {},
    },
});

export const displaySuccessSnack = ({ message }) =>
    displaySnack({ message, options: { variant: "success" } });

export const displayErrorSnack = ({ message }) =>
    displaySnack({ message, options: { variant: "error" } });

export const closeSnack = ({ key }) => ({
    type: actionTypes.CLOSE_SNACK,
    payload: {
        key,
    },
});

export const closeAllSnacks = () => ({
    type: actionTypes.CLOSE_ALL_SNACKS,
    payload: {},
});

// togglers

export const toggleEditMode = () => ({
    type: actionTypes.TOGGLE_EDIT_MODE,
    payload: {},
});

export const toggleOverlay = ({ overlay }) => ({
    type: actionTypes.TOGGLE_OVERLAY,
    payload: { overlay },
});

export const closeAllOverlays = () => ({
    type: actionTypes.CLOSE_ALL_OVERLAYS,
    payload: {},
});

export default actionTypes;
