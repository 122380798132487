import { serverUrl } from "network";

export function openNewInternalLink(destination: string, isReact: boolean) {
    const url = isReact
        ? `${serverUrl}:3000${destination}`
        : `${serverUrl}${destination}`;
    const win = window.open(url, "_blank");
    win.focus();
}

export function openNewExternalLink(url: string) {
    const win = window.open(url, "_blank");
    win.focus();
}
