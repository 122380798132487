import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { Tag } from "antd";
import ReactMarkdown from "react-markdown";

import { StyledButton } from "components/atoms";
import StatusTag from "components/atoms/StatusTag";
import IndicatorGroupFactoid from "components/cdv/IndicatorGroupFactoid/index";
import InsightTimeline from "components/cdv/InsightTimeline";
import IntelligenceNotesList from "components/cdv/IntelligenceNotesList/index";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { DeepInsight, Source } from "reactQuery/hooks/apiTypes";
import { useGetSingleInsight } from "reactQuery/hooks/useInsights";
import { indicatorGroupsKeys, insightsKeys, noteKeys } from "reactQuery/keys";
import { overlayNames } from "stores/uiStore/constants";
import { useModalStore, ModalNames } from "stores/zustandStore";
import { isIndicatorFeedsRoute, isIntelligenceFeedsRoute } from "utils/redirect";

import { buttonTestIds } from "..";
import InsightFactoid from "../../../molecules/InsightFactoid";
import InsightFactoidList from "../../../molecules/InsightFactoidList";
import CompetitorsConsultantsFactoids from "../CompetitorsConsultantsFactoids";
import SourceInfo from "../SourceInfo";
import { insightStyles } from "../styles";

export const formatDemographicAsString = (demographic) =>
    `${demographic.name}` +
    (demographic.state_code ? `, ${demographic.state_code}` : "");

const InsightCard = ({
    insight,
    isLoading,
    setInsightForEdit,
    toggleOverlay,
    setInsightForIntelligenceAddition,
    reports,
    opportunityType,
    sources,
    index,
    insights,
    isStaff,
    isEditModeActive,
    setSourceForEdit,
    setInsightForLocationRemoval,
    setInsightForWasteWaterSystemRemoval,
    setInsightForDrinkingWaterSystemRemoval,
    setInsightForCompanyRemoval,
    setInsightForSourceCreation,
}: {
    insight: DeepInsight;
    isLoading: boolean;
    setInsightForEdit: Function;
    toggleOverlay: Function;
    setInsightForIntelligenceAddition: Function;
    reports: any[];
    opportunityType: OpportunityTypeEnum;
    sources: Source[];
    index: number;
    insights: any;
    isStaff: boolean;
    isEditModeActive: boolean;
    setSourceForEdit: Function;
    setInsightForLocationRemoval: Function;
    setInsightForWasteWaterSystemRemoval: Function;
    setInsightForDrinkingWaterSystemRemoval: Function;
    setInsightForCompanyRemoval: Function;
    setInsightForSourceCreation: Function;
}) => {
    const queryClient = useQueryClient();
    const isIntelligenceFeeds = isIntelligenceFeedsRoute();

    const setModalVisible = useModalStore((state) => state.setModalVisible);

    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const styles = insightStyles();

    const setDeliveryStatusModalParams = useModalStore(
        (state) => state.setDeliveryStatusModalParams
    );
    const setModalStore = useModalStore((state) => state.setModalVisible);

    const singleInsightQuery = useGetSingleInsight(insight.id, isStaff);

    let showDisplayDate = isIntelligenceFeedsRoute() || isIndicatorFeedsRoute();
    const formattedDisplayDate = formatDayHyphenShortenedMonthHyphenYear(
        showDisplayDate ? insight.display_date : insight.report_date
    );

    const relevantSources =
        opportunityType === OpportunityTypeEnum.REPORT
            ? sources?.filter((source) => source.insight === insight.id) || []
            : opportunityType === OpportunityTypeEnum.INDICATOR
            ? insight.sources
            : [];

    const onEditInsight = () => {
        setInsightForEdit({ insightId: insight.id });
        toggleOverlay({ overlay: overlayNames.editInsightModal });
    };
    const onEditIndicator = (group_id) => {
        queryClient.setQueryData(indicatorGroupsKeys.active(), group_id);

        setInsightForEdit({ insightId: insight.id });
        toggleOverlay({ overlay: overlayNames.editIndicatorModal });
    };
    const onEditDeliveryStatus = () => {
        setDeliveryStatusModalParams({
            visibility: true,
            insightId: insight.id,
            deliveryStatus: singleInsightQuery.data?.delivery_status,
        });
    };
    const onEditTeamCustomerTags = () => {
        const key = insightsKeys.single(insight.id);
        queryClient.invalidateQueries(key);

        setInsightForEdit({ insightId: insight.id });
        toggleOverlay({ overlay: overlayNames.teamCustomerTagsModal });
    };
    const addIntelligenceButton = (
        <StyledButton
            data-testid={buttonTestIds.addIntelligence(insight.id)}
            handleClick={() => {
                setInsightForIntelligenceAddition({ insight: insight });
                setModalVisible(ModalNames.AddIntelligence);
            }}
            variant="add-secondary"
        >
            Add Intelligence
        </StyledButton>
    );

    return (
        <Grid className={styles.insightGrid} container key={insight.id}>
            {isNotMobileScreen ? (
                <Grid item xs={3}>
                    <InsightTimeline
                        isEditModeActive={isEditModeActive}
                        formattedDisplayDate={formattedDisplayDate}
                        insight={insight}
                        isLoading={isLoading}
                        index={index}
                        isNotMobileScreen={isNotMobileScreen}
                    />
                </Grid>
            ) : null}
            <Grid className={styles.insightInfoContainer} item xs={9}>
                <Grid container>
                    <InsightFactoid
                        getEditTestId={(insight, _) =>
                            buttonTestIds.editSummary(insight.id)
                        }
                        onEditClick={onEditInsight}
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        label="Trigger Summary"
                        renderItem={(item) => (
                            <ReactMarkdown className={styles.extract}>
                                {item}
                            </ReactMarkdown>
                        )}
                        value={insight.summary}
                    />
                    {isStaff && singleInsightQuery.isSuccess ? (
                        <InsightFactoid
                            getEditTestId={(insight) =>
                                buttonTestIds.editDeliveryStatus(insight.id)
                            }
                            onEditClick={onEditDeliveryStatus}
                            insight={insight}
                            isEditModeActive={isEditModeActive}
                            label="Delivery Status"
                            renderItem={(delivery_status: string) => (
                                <StatusTag status={delivery_status} />
                            )}
                            value={singleInsightQuery.data?.delivery_status}
                        />
                    ) : null}

                    <IndicatorGroupFactoid
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        onEditClick={onEditIndicator}
                    />
                    {opportunityType !== OpportunityTypeEnum.INDICATOR &&
                    insight.team_customer_tags?.length > 0 &&
                    !isIntelligenceFeeds ? (
                        <InsightFactoid
                            getEditTestId={(insight) =>
                                buttonTestIds.editTeamCustomerTags(insight.id)
                            }
                            onEditClick={onEditTeamCustomerTags}
                            insight={insight}
                            isEditModeActive={isEditModeActive}
                            label="Tags"
                            renderItem={(team_customer_tags) => (
                                <div>
                                    {team_customer_tags.map((team_customer_tag) => (
                                        <Tag
                                            key={team_customer_tag.id}
                                            className={styles.team_customer_tag}
                                        >
                                            {team_customer_tag.name}
                                        </Tag>
                                    ))}
                                </div>
                            )}
                            value={insight.team_customer_tags}
                        />
                    ) : null}

                    <InsightFactoidList
                        getEditTestId={(insight, demographic) =>
                            buttonTestIds.editLocation(insight.id, demographic.id)
                        }
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        label="Locations"
                        onEditClick={(insight, demographic) => {
                            setInsightForLocationRemoval({
                                demographicId: demographic.id,
                                insightId: insight.id,
                            });
                            toggleOverlay({
                                overlay: overlayNames.removeLocationModal,
                            });
                        }}
                        renderItem={(demographic) => {
                            return (
                                <Typography>
                                    {formatDemographicAsString(demographic)}
                                </Typography>
                            );
                        }}
                        values={insight.demographics}
                    />
                    <InsightFactoidList
                        getEditTestId={(insight, wastewaterFacility) =>
                            buttonTestIds.editWastewaterFacility(
                                insight.id,
                                wastewaterFacility.id
                            )
                        }
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        label="Facilities"
                        onEditClick={(insight, wastewaterFacility) => {
                            setInsightForWasteWaterSystemRemoval({
                                insightId: insight.id,
                                wastewaterFacility: wastewaterFacility,
                            });
                            toggleOverlay({
                                overlay: overlayNames.editWasteWaterFacilityModal,
                            });
                        }}
                        renderItem={(wastewaterFacility) => {
                            let mgdValue = wastewaterFacility.design_flow
                                ? parseFloat(wastewaterFacility.design_flow).toFixed(2)
                                : "Unknown";
                            return (
                                <div>
                                    <Typography>
                                        {wastewaterFacility.facility_name}
                                    </Typography>
                                    <Typography>{`MGD: ${mgdValue}`}</Typography>
                                    <Typography>
                                        {`NPDESID: ${
                                            wastewaterFacility.npdes_id
                                                ? wastewaterFacility.npdes_id.toLocaleString()
                                                : "Unknown"
                                        }`}
                                    </Typography>
                                </div>
                            );
                        }}
                        values={insight["facilities"]}
                    />
                    <InsightFactoidList
                        getEditTestId={(insight, drinkingWaterSystem) =>
                            buttonTestIds.editDrinkingWaterSystem(
                                insight.id,
                                drinkingWaterSystem.id
                            )
                        }
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        onEditClick={(insight, drinkingWaterSystem) => {
                            setInsightForDrinkingWaterSystemRemoval({
                                insightId: insight.id,
                                waterSystem: drinkingWaterSystem,
                            });
                            toggleOverlay({
                                overlay: overlayNames.editDrinkingWaterSystemModal,
                            });
                        }}
                        label="Drinking Water Systems"
                        renderItem={(drinkingWaterSystem) => {
                            return (
                                <div>
                                    <Typography>{drinkingWaterSystem.name}</Typography>
                                    <Typography>
                                        {`Pop Served: ${
                                            drinkingWaterSystem.population_served
                                                ? drinkingWaterSystem.population_served.toLocaleString()
                                                : "Unknown"
                                        }`}
                                    </Typography>
                                    <Typography>
                                        {`PWSID: ${
                                            drinkingWaterSystem.public_water_system_id
                                                ? drinkingWaterSystem.public_water_system_id.toLocaleString()
                                                : "Unknown"
                                        }`}
                                    </Typography>
                                </div>
                            );
                        }}
                        values={insight.drinking_water_systems}
                    />
                    {opportunityType !== OpportunityTypeEnum.INDICATOR &&
                    !isIntelligenceFeeds ? (
                        <IntelligenceNotesList
                            insight={insight}
                            isEditModeActive={isEditModeActive}
                            onEditClick={(insight, note) => {
                                queryClient.setQueryData(noteKeys.active(), note.id);
                                setModalStore(ModalNames.EditIntelligenceNotes, {
                                    insight: insight,
                                    note: note,
                                });
                            }}
                        />
                    ) : null}

                    <CompetitorsConsultantsFactoids
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        setInsightForCompanyRemoval={setInsightForCompanyRemoval}
                        toggleOverlay={toggleOverlay}
                        opportunityType={opportunityType}
                    />

                    {isEditModeActive ? (
                        <Grid item xs={12}>
                            {addIntelligenceButton}
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container className={styles.sourcesContainer}>
                    <SourceInfo
                        insight={insight}
                        isEditModeActive={isEditModeActive}
                        opportunityType={opportunityType}
                        sources={relevantSources}
                        setInsightForSourceCreation={setInsightForSourceCreation}
                        setSourceForEdit={setSourceForEdit}
                        toggleOverlay={toggleOverlay}
                        insights={insights}
                        reports={reports}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InsightCard;
