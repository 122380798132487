import { createReducer } from "@reduxjs/toolkit";

import {
    fetchWastewaterFacilitiesForOwner,
    setInsightForWasteWaterSystemLinkage,
    setInsightForWasteWaterSystemRemoval,
} from "stores/wastewaterFacilities/actions";
import { wastewaterFacilitiesAdapter } from "stores/wastewaterFacilities/adapter";

export const initialState = wastewaterFacilitiesAdapter.getInitialState({
    isFetchingForOwner: false,
    newInsightLinkage: {
        insight: null,
    },
    ownerFetchError: null,
    removeInsightLinkage: {
        insightId: null,
        wastewaterFacility: null,
    },
});

const wastewaterFacilitiesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchWastewaterFacilitiesForOwner.pending, (state) => {
            state.isFetchingForOwner = true;
            state.ownerFetchError = null;
        })
        .addCase(fetchWastewaterFacilitiesForOwner.fulfilled, (state, { payload }) => {
            wastewaterFacilitiesAdapter.upsertMany(state, payload);
            state.isFetchingForOwner = false;
            state.ownerFetchError = null;
        })
        .addCase(fetchWastewaterFacilitiesForOwner.rejected, (state, { payload }) => {
            state.ownerFetchError = payload;
            state.isFetchingForOwner = false;
        })
        .addCase(setInsightForWasteWaterSystemLinkage, (state, { payload }) => {
            state.newInsightLinkage.insight = payload && payload.insight;
        })
        .addCase(setInsightForWasteWaterSystemRemoval, (state, { payload }) => {
            state.removeInsightLinkage.insightId = payload && payload.insightId;
            state.removeInsightLinkage.wastewaterFacility =
                payload && payload.wastewaterFacility;
        });
});

export default wastewaterFacilitiesReducer;
