import { makeStyles } from "@material-ui/core/styles";

import { additionalColors, brandColors } from "constants/colors";

export const useStyles = makeStyles((theme) => ({
    darkLine: {
        borderLeft: `6px solid ${brandColors.darkBlue}`,
        height: "100%",
        marginLeft: "-16px",
    },
    lightLine: {
        borderLeft: "6px solid #349bee",
        height: "100%",
        marginLeft: "-16px",
    },
    bodyHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down("920")]: {
            flexDirection: "column",
            alignItems: "start",
        },
    },
    bodyHeaderLeft: {
        flexGrow: 1,
    },
    managementHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
    },
    managementText: {
        color: additionalColors.body54,
        fontSize: "16px",
    },
    teamName: {
        color: additionalColors.body,
        fontSize: "24px",
        paddingRight: "30px",
    },
    toolbar: {
        display: "flex",
        flexDirection: "column",
        margin: "12px 0",
        scrollbarWidth: "thin",
    },
    searchBarContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    toolbarTitle: {
        color: additionalColors.body,
        fontSize: "24px",
        paddingTop: "4px",
    },
    reportsLink: {
        color: additionalColors.marineBlue,
    },
    viewButton: {
        width: "35px",
    },
    editButton: {
        border: "2px solid",
        color: additionalColors.grey999,
    },
    legendContainer: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.down("920")]: {
            margin: "8px 0px 8px 0px",
            justifyContent: "normal",
        },
    },
    previewButtonContainer: {
        [theme.breakpoints.down("920")]: {
            width: "100%",
        },
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down("320")]: {
            flexDirection: "column",
            alignItems: "start",
        },
    },
    wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        alignItems: "center",
        paddingBottom: "10px",
    },
    navigateNext: {
        marginLeft: "30px",
        color: theme.palette.grey[500],
        fontFamily: "Montserrat",
    },
    navigatePrev: {
        marginRight: "30px",
        color: theme.palette.grey[500],
        fontFamily: "Montserrat",
    },
    navArrow: {
        fill: "grey",
        float: "right",
    },
    editButtonOuterContainer: {
        marginLeft: "5px",
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    editButtonInnerContainer: {
        display: "flex",
    },
}));
