import PDFImage from "components/atoms/PDFImage";
import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import checkbox from "icons/checkbox.png";

import { pdfStyle } from "../pdfStyle";

const RenderCheckItem = ({ name, subItems = null }) => (
    <PDFView wrap={true}>
        <PDFView style={pdfStyle.checkItemContainer}>
            <PDFView style={pdfStyle.checkItemIconContainer}>
                <PDFImage src={checkbox} style={pdfStyle.checkItemIcon} />
            </PDFView>
            <PDFText>{name}</PDFText>
        </PDFView>
        {subItems?.length ? (
            <PDFView style={pdfStyle.subItemContainer}>
                {subItems.map((subItem, index) => (
                    <PDFText style={pdfStyle.subItemText} key={index}>
                        {subItem}
                    </PDFText>
                ))}
            </PDFView>
        ) : null}
    </PDFView>
);

export default RenderCheckItem;
