import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useLocation, Link } from "react-router-dom";

import { factoidStyles } from "components/cdv/OpportunityCard/styles";
import TagsList from "components/organisms/TagsList/index";
import { IndicatorGroup } from "reactQuery/hooks/apiTypes";
import { useGetFilteredIndicatorGroups } from "reactQuery/hooks/useIndicatorGroups";
import { AllIndicatorsSingleIndicatorPathname } from "routes/IntelligenceFeeds";
import { isIntelligenceFeedsRoute } from "utils/redirect";

import StyledButton from "../../atoms/StyledButton";

export const buttonTestIds = {
    editIndicatorGroup: (id: number) => `edit indicator group ${id}`,
};

const IndicatorGroupFactoid = ({ insight, isEditModeActive, onEditClick }) => {
    const styles = factoidStyles();
    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const { search } = useLocation();
    const teamId = new URLSearchParams(search).get("team_id");

    const isIntelligenceFeeds = isIntelligenceFeedsRoute();

    const indicatorGroupsQuery = useGetFilteredIndicatorGroups({
        content_type: "insight",
        object_id: insight.id,
    });
    if (!indicatorGroupsQuery.isSuccess) return null;

    return indicatorGroupsQuery.data.results.map(
        (group: IndicatorGroup, index: number) => {
            let ordinal = index + 1;

            return (
                <React.Fragment key={index}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Typography className={styles.label}>
                            {indicatorGroupsQuery.data.results.length > 1
                                ? `Indicator Group ${ordinal}`
                                : "Indicator Group"}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9}>
                        <Grid container>
                            <Grid item xs={10}>
                                {isIntelligenceFeeds ? (
                                    <Link
                                        to={
                                            AllIndicatorsSingleIndicatorPathname +
                                            "?indicator_id=" +
                                            group.indicator.id +
                                            "&team_id=" +
                                            teamId
                                        }
                                    >
                                        <TagsList
                                            group={group}
                                            indicatorType="indicatorGroup"
                                        />
                                    </Link>
                                ) : (
                                    <TagsList
                                        group={group}
                                        indicatorType="indicatorGroup"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    style={{
                                        marginRight: isNotMobileScreen ? null : "-80px",
                                    }}
                                >
                                    {" "}
                                    {isEditModeActive ? (
                                        <StyledButton
                                            variant="edit-secondary"
                                            data-testid={buttonTestIds.editIndicatorGroup(
                                                index
                                            )}
                                            handleClick={() => onEditClick(group.id)}
                                        >
                                            Edit
                                        </StyledButton>
                                    ) : null}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    );
};

export default IndicatorGroupFactoid;
