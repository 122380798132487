import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import StyledButton from "components/atoms/StyledButton";
import { sourceStyles } from "components/cdv/OpportunityCard/styles";
import { overlayNames } from "stores/uiStore/constants";

import { buttonTestIds } from "..";
import IndividualSource from "../IndividualSource";

const SourceInfo = ({
    insight,
    isEditModeActive,
    opportunityType,
    sources,
    setInsightForSourceCreation,
    setSourceForEdit,
    toggleOverlay,
    insights,
    reports,
}) => {
    const relevantSources =
        sources?.filter((source) => source.insight === insight.id) || [];
    const styles = sourceStyles();
    const addSourceButton = (
        <StyledButton
            data-testid={buttonTestIds.addSource(insight.id)}
            handleClick={() => {
                setInsightForSourceCreation({
                    insightId: insight.id,
                });
                toggleOverlay({ overlay: overlayNames.createSourceModal });
            }}
            variant="add-secondary"
        >
            Add Source
        </StyledButton>
    );

    if (relevantSources.length === 0) {
        return isEditModeActive ? (
            <>
                <Grid item xs={3}>
                    <Typography className={styles.label}>Source</Typography>
                </Grid>
                {isEditModeActive ? (
                    <Grid item xs={9}>
                        {addSourceButton}
                    </Grid>
                ) : null}
            </>
        ) : null;
    }
    const renderedSources = relevantSources.map((source) => {
        return (
            <IndividualSource
                isLoadingInsight={undefined}
                isLoadingReportsForTeam={undefined}
                key={source.id}
                insight={insight}
                isEditModeActive={isEditModeActive}
                opportunityType={opportunityType}
                source={source}
                setSourceForEdit={setSourceForEdit}
                toggleOverlay={toggleOverlay}
                insights={insights}
                reports={reports}
            />
        );
    });
    return (
        <>
            {renderedSources}
            {isEditModeActive ? (
                <Grid item xs={12}>
                    {addSourceButton}
                </Grid>
            ) : null}
        </>
    );
};

export default SourceInfo;
