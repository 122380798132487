import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";
import { Subscription } from "reactQuery/hooks/apiTypes";

import { pdfStyle } from "../pdfStyle";
import RenderCheckItem from "../RenderCheckItem";

const RenderSubscriptionDetails = (subscription: Subscription) => (
    <PDFView style={pdfStyle.sectionContainer}>
        <PDFText style={pdfStyle.sectionTitle}>Subscription Details</PDFText>
        <PDFView>
            {subscription?.basic_facility ? (
                <RenderCheckItem name="Basic Facility Information" />
            ) : null}
            {subscription?.owner_office ? (
                <RenderCheckItem name="Owner Office Location" />
            ) : null}
            {subscription?.project_budget ? (
                <RenderCheckItem name="Project Budget" />
            ) : null}
            {subscription?.bid_check ? <RenderCheckItem name="Bid Check" /> : null}
            {subscription?.contacts?.length ? (
                <RenderCheckItem
                    name="Contacts"
                    subItems={subscription.contacts.map(
                        (contact, index) =>
                            `${index + 1}. ${contact.contact_level} | ${
                                contact.contact_department
                            }`
                    )}
                />
            ) : null}
            {subscription?.competitors?.length ? (
                <RenderCheckItem
                    name="Competitor Mentions"
                    subItems={subscription.competitors.map(
                        (competitor, index) => `${index + 1}. ${competitor.name}`
                    )}
                />
            ) : null}
            {subscription?.consultants?.length ? (
                <RenderCheckItem
                    name="Project Consultants"
                    subItems={subscription.consultants.map(
                        (consultant, index) => `${index + 1}. ${consultant.name}`
                    )}
                />
            ) : null}
            {subscription?.priority_owners?.length ? (
                <RenderCheckItem
                    name="Priority Owners"
                    subItems={subscription.priority_owners.map(
                        (owner, index) =>
                            `${index + 1}. ${owner.legal_name}, ${owner.state_code}`
                    )}
                />
            ) : null}
        </PDFView>
    </PDFView>
);

export default RenderSubscriptionDetails;
