import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "antd";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
    },
    feedbackIconFontSize: {
        fontSize: "21px",
    },
    feedbackSvgSize: {
        height: "21px",
        width: "21px",
    },
    feedbackIconEnabledStyle: {
        cursor: "pointer !important",
    },
    feedbackIconDisabledStyle: {
        cursor: "default !important",
    },
    rightContainer: {
        display: "flex",
        alignItems: "center",
    },
    itemContainer: {
        marginRight: "10px",
    },
}));

export const StyledCheckBox = styled(Checkbox)`
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
`;
