export const actionTypes = {
    ANALYTICS_PAGE: "ANALYTICS_PAGE",
    ANALYTICS_EVENT: "ANALYTICS_EVENT",
    FETCH_USER_DATA: "FETCH_USER_DATA",
    FETCH_FEATURE_FLAGS: "FETCH_FEATURE_FLAGS",
    FETCH_TEAM_BY_ID: "FETCH_TEAM_BY_ID",
    FETCH_TEAM_BY_ID_FAILED: "FETCH_TEAM_BY_ID_FAILED",
    FETCH_TEAMS_DATA: "FETCH_TEAMS_DATA",
    FETCH_TEAMS_FAILED: "FETCH_TEAMS_FAILED",
    FETCH_PROFILES_DATA: "FETCH_PROFILES_DATA",
    FETCH_PROFILES_FAILED: "FETCH_PROFILES_FAILED",
    FETCH_FEATURE_FLAGS_FAILED: "FETCH_FEATURE_FLAGS_FAILED",
    LOG_IN: "LOG_IN",
    LOG_IN_FAILURE: "LOG_IN_FAILURE",
    LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
    LOG_OUT: "LOG_OUT",
    LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
    PASSWORD_CHANGE_CLEAR_ERRORS: "PASSWORD_CHANGE_CLEAR_ERRORS",
    PASSWORD_CHANGE_REQUEST: "PASSWORD_CHANGE_REQUEST",
    PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
    PASSWORD_CHANGE_FAILURE: "PASSWORD_CHANGE_FAILURE",
    REQUEST_PASSWORD_RESET: "REQUEST_PASSWORD_RESET",
    REQUEST_PASSWORD_RESET_SUCCESS: "REQUEST_PASSWORD_RESET_SUCCESS",
    REQUEST_PASSWORD_RESET_FAILURE: "REQUEST_PASSWORD_RESET_FAILURE",
    PASSWORD_RESET: "PASSWORD_RESET",
    PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
    PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
    SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
    SET_USER_DATA: "SET_USER_DATA",
    SET_TEAMS_DATA: "SET_TEAMS_DATA",
    SET_PROFILES_DATA: "SET_PROFILES_DATA",
    SET_FEATURE_FLAGS: "SET_FEATURE_FLAGS",
    SIGNUP_LINK_VERIFICATION: "SIGNUP_LINK_VERIFICATION",
    SIGNUP_LINK_VERIFICATION_SUCCESS: "SIGNUP_LINK_VERIFICATION_SUCCESS",
    SIGNUP_LINK_VERIFICATION_FAILURE: "SIGNUP_LINK_VERIFICATION_FAILURE",
};

export const analyticsPage = ({ path, title }) => ({
    type: actionTypes.ANALYTICS_PAGE,
    payload: {
        path,
        title,
    },
});

export const analyticsEvent = ({
    action,
    category,
    executeOnceComplete,
    label,
    value,
}) => ({
    type: actionTypes.ANALYTICS_EVENT,
    payload: {
        action,
        category,
        executeOnceComplete,
        label,
        value,
    },
});

export const passwordReset = ({
    confirmPassword,
    newPassword,
    token,
    uid,
    ga_event_name,
}) => ({
    type: actionTypes.PASSWORD_RESET,
    payload: {
        new_password1: newPassword,
        new_password2: confirmPassword,
        token,
        uid,
        ga_event_name: ga_event_name,
    },
});

export const passwordResetSuccess = () => ({
    type: actionTypes.PASSWORD_RESET_SUCCESS,
    payload: {},
});

export const passwordResetFailure = (errors) => ({
    type: actionTypes.PASSWORD_RESET_FAILURE,
    payload: errors,
});

export const passwordChangeClearErrors = ({ fieldToClear }) => ({
    type: actionTypes.PASSWORD_CHANGE_CLEAR_ERRORS,
    payload: {
        fieldToClear,
    },
});

export const passwordChangeRequest = ({
    confirmPassword,
    currentPassword,
    newPassword,
}) => ({
    type: actionTypes.PASSWORD_CHANGE_REQUEST,
    payload: {
        new_password1: newPassword,
        new_password2: confirmPassword,
        old_password: currentPassword,
    },
});

export const passwordChangeSuccess = () => ({
    type: actionTypes.PASSWORD_CHANGE_SUCCESS,
    payload: {},
});

export const passwordChangeFailure = (errors) => ({
    type: actionTypes.PASSWORD_CHANGE_FAILURE,
    payload: errors,
});

export const requestPasswordReset = ({ email }) => ({
    type: actionTypes.REQUEST_PASSWORD_RESET,
    payload: {
        email,
    },
});

export const requestPasswordResetSuccess = () => ({
    type: actionTypes.REQUEST_PASSWORD_RESET_SUCCESS,
    payload: {},
});

export const requestPasswordResetFailure = ({ isUnknownError }) => ({
    type: actionTypes.REQUEST_PASSWORD_RESET_FAILURE,
    payload: {
        isUnknownError,
    },
});

export const setUserData = (userData) => ({
    type: actionTypes.SET_USER_DATA,
    payload: userData,
});

export const fetchUserData = (shouldRedirect) => ({
    type: actionTypes.FETCH_USER_DATA,
    payload: {
        shouldRedirect: shouldRedirect === undefined ? true : shouldRedirect,
    },
});

export const setIsAuthenticated = (isAuthenticated) => ({
    type: actionTypes.SET_IS_AUTHENTICATED,
    payload: {
        isAuthenticated,
    },
});

export const fetchTeamById = (teamId) => ({
    type: actionTypes.FETCH_TEAM_BY_ID,
    payload: { teamId },
});

export const fetchTeamByIdFailed = () => ({
    type: actionTypes.FETCH_TEAM_BY_ID_FAILED,
    payload: {},
});

export const fetchTeamsData = () => ({
    type: actionTypes.FETCH_TEAMS_DATA,
    payload: {},
});

export const setTeamsData = (teams) => ({
    type: actionTypes.SET_TEAMS_DATA,
    payload: teams,
});

export const fetchTeamsFailed = () => ({
    type: actionTypes.FETCH_TEAMS_FAILED,
    payload: {},
});

export const fetchProfilesData = () => ({
    type: actionTypes.FETCH_PROFILES_DATA,
    payload: {},
});

export const setProfilesData = (profiles) => ({
    type: actionTypes.SET_PROFILES_DATA,
    payload: profiles,
});

export const fetchProfilesFailed = () => ({
    type: actionTypes.FETCH_PROFILES_FAILED,
    payload: {},
});

export const logInAttempt = ({ username, password }) => ({
    type: actionTypes.LOG_IN,
    payload: {
        username,
        password,
    },
});

export const logInFailure = ({
    providedInvalidCredentials,
    retryLoginAfterSeconds,
}) => ({
    type: actionTypes.LOG_IN_FAILURE,
    payload: {
        providedInvalidCredentials,
        retryLoginAfterSeconds,
    },
});

export const logInSuccess = () => ({
    type: actionTypes.LOG_IN_SUCCESS,
    payload: {},
});

export const logOutAttempt = () => ({
    type: actionTypes.LOG_OUT,
    payload: {},
});

export const logOutSuccess = () => ({
    type: actionTypes.LOG_OUT_SUCCESS,
    payload: {},
});

export const signupLinkVerification = ({ uid, token }, shouldRedirect) => ({
    type: actionTypes.SIGNUP_LINK_VERIFICATION,
    payload: {
        uid,
        token,
        shouldRedirect: shouldRedirect === undefined ? true : shouldRedirect,
    },
});

export const signupLinkVerificationSuccess = () => ({
    type: actionTypes.SIGNUP_LINK_VERIFICATION_SUCCESS,
    payload: {},
});

export const signupLinkVerificationFailure = (errors) => ({
    type: actionTypes.SIGNUP_LINK_VERIFICATION_FAILURE,
    payload: errors,
});

export default actionTypes;
