import { ClockCircleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Button, Checkbox, Form, Input, Space, Typography } from "antd";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { additionalColors } from "constants/colors";
import { refreshConfig } from "network";
import { LoginResponse, useAuthLogin } from "reactQuery/hooks/userAuth/useLogin";
import { useSnackStore } from "stores/zustandStore";
import { extractNextUrlParameter } from "utils/redirect";
import useCountdownTimer from "utils/useCountdownTimer";

const { Text } = Typography;

export const ERROR_MESSAGES = {
    emailMissing: "Work email address is required",
    emailInvalid: "Invalid email address",
    invalidCredentials: "The email or password provided is not correct",
    otpInvalid: "One time code is invalid. Please enter the code sent to your email",
    passwordMissing: "Password is required",
    tooManyAttemps: (minutes: number) =>
        `Too many login attempts. Please try again in ${minutes} minutes.`,
    genericError: "Unknown error",
};

export const LoginForm = ({ otpRequired, setOtpRequired }) => {
    const [form] = Form.useForm();
    const { push } = useHistory();

    const [formErrorMessage, setFormErrorMessage] = useState<string>();
    const [otpEmailResent, setOTPEmailResent] = useState<boolean>(false);
    const otpEmailResendTimeout = useCountdownTimer({
        initialTime: 60,
        startTimer: otpEmailResent,
    });

    useEffect(() => {
        if (otpEmailResendTimeout === 0 && otpEmailResent === true) {
            setOTPEmailResent(false);
        }
    }, [otpEmailResendTimeout]);

    const queryClient = useQueryClient();
    const authLoginQuery = useAuthLogin();
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);

    const onResendOTPEmail = () => {
        setOTPEmailResent(true);
        onFinish(form.getFieldsValue(), authLoginQuery);
    };

    const onFinish = (
        values: {
            email: string;
            password: string;
            otp?: string;
            otpRememberDevice?: boolean;
        },
        authLoginQuery: Partial<UseMutationResult<LoginResponse>>
    ) => {
        authLoginQuery.mutate(
            {
                email: values.email,
                password: values.password,
                otp: values.otp,
                otp_remember_device: values.otpRememberDevice,
            },
            {
                onSuccess: (data) => {
                    if (data.otp_required) {
                        setOtpRequired(true);
                        return;
                    }
                    // @ts-ignore
                    window.heap && window.heap.identify(values.email);
                    queryClient.clear();

                    displaySuccessSnack({ message: "Successfully logged in" });
                    refreshConfig(); // refresh the CSRF token
                    push(extractNextUrlParameter());
                },
                onError: (error: AxiosError) => {
                    let errorMessage: string;
                    if (error.response.status === 400) {
                        if (error.response.data?.detail) {
                            errorMessage = error.response.data.detail;
                        } else {
                            if (otpRequired) {
                                errorMessage = ERROR_MESSAGES.otpInvalid;
                            } else {
                                errorMessage = ERROR_MESSAGES.invalidCredentials;
                            }
                        }
                    } else if (error.response.status === 429) {
                        const retryLoginAfterSeconds =
                            error.response.headers["retry-after"];
                        const retryLoginAfterMinutes =
                            retryLoginAfterSeconds &&
                            Math.round(retryLoginAfterSeconds / 60);
                        errorMessage =
                            ERROR_MESSAGES.tooManyAttemps(retryLoginAfterMinutes);
                    } else {
                        errorMessage = ERROR_MESSAGES.genericError;
                    }
                    setFormErrorMessage(errorMessage);
                },
            }
        );
    };

    const emailInputRef = useRef(null);
    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, [emailInputRef.current]);

    return (
        <>
            <Form
                id="login-form"
                form={form}
                name="login-form"
                labelWrap={true}
                layout="vertical"
                onFinish={(values) => onFinish(values, authLoginQuery)}
                validateTrigger="onBlur"
                style={{ width: "100%" }}
                size="small"
            >
                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                    <Form.Item
                        hidden={otpRequired}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: ERROR_MESSAGES.emailMissing,
                            },
                            {
                                type: "email",
                                message: ERROR_MESSAGES.emailInvalid,
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            ref={emailInputRef}
                            data-testid="email"
                            prefix={
                                <UserOutlined
                                    style={{ color: additionalColors.grey500 }}
                                />
                            }
                            placeholder="Work Email Address"
                        />
                    </Form.Item>

                    <Form.Item
                        hidden={otpRequired}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: ERROR_MESSAGES.passwordMissing,
                            },
                        ]}
                    >
                        <Input.Password
                            size="large"
                            data-testid="password"
                            prefix={
                                <LockOutlined
                                    style={{ color: additionalColors.grey500 }}
                                />
                            }
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item
                        hidden={!otpRequired}
                        name="otp"
                        label="Your team requires Multi-Factor Authentication. Please check your email for a one time code, and enter it below"
                        rules={[
                            {
                                required: otpRequired,
                                message: "One time code required",
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            data-testid="otp"
                            prefix={
                                <ClockCircleOutlined
                                    style={{ color: additionalColors.grey500 }}
                                />
                            }
                            placeholder="One Time Code"
                        />
                    </Form.Item>

                    <div style={!otpRequired ? { display: "none" } : {}}>
                        <Button
                            disabled={authLoginQuery.isLoading || otpEmailResent}
                            type="link"
                            htmlType="button"
                            onClick={onResendOTPEmail}
                            data-testid="resend-email-button"
                        >
                            {otpEmailResent
                                ? `Email sent. Please check your spam folder! You can resend in ${otpEmailResendTimeout}s`
                                : "Didn't receive an email? Click here to resend it"}
                        </Button>
                    </div>

                    <Form.Item style={{ marginBottom: "10px" }}>
                        <Button
                            size="middle"
                            style={{ width: "100%" }}
                            type="primary"
                            htmlType="submit"
                            data-testid="submit"
                            loading={authLoginQuery.isLoading}
                            disabled={authLoginQuery.isLoading}
                        >
                            Sign In
                        </Button>
                    </Form.Item>

                    <Form.Item
                        hidden={!otpRequired}
                        name="otpRememberDevice"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox>Remember this device for 30 days</Checkbox>
                    </Form.Item>

                    {formErrorMessage ? (
                        <Space style={{ width: "100%", justifyContent: "center" }}>
                            <Text type="danger" data-test-id="error-message">
                                {formErrorMessage}
                            </Text>
                        </Space>
                    ) : undefined}
                </Space>
            </Form>
        </>
    );
};

export default LoginForm;
