import { Form, Select } from "antd";
import { useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { IndicatorTag } from "reactQuery/hooks/apiTypes";
import { useGetIndicatorTagsByQuery } from "reactQuery/hooks/useIndicatorTags";

import { RecordProps } from "../columnInfo";

const IndicatorTagsSelect = ({ constraintName, constraintId, number }: RecordProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryMinChars = 1;
    const enterQueryPrompt = `Enter at least ${queryMinChars} characters to search for an indicator tag`;

    const indicatorTagsQueryEnabled = searchQuery.length >= queryMinChars;
    const indicatorTagsQuery = useGetIndicatorTagsByQuery(
        searchQuery,
        indicatorTagsQueryEnabled
    );
    const debounceSearch = useSearchDebounced(setSearchQuery);

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[
                constraintName,
                `constraint_id_${constraintId}`,
                `number_${number}`,
                "indicator_tags",
            ]}
        >
            <Select
                placeholder="Select one or more indicator tags"
                showSearch
                filterOption={false}
                allowClear={true}
                mode="multiple"
                style={{ width: "100%" }}
                onSearch={(value) => {
                    debounceSearch(value);
                }}
                onClear={() => {
                    debounceSearch("");
                }}
            >
                {!indicatorTagsQueryEnabled ? (
                    <Select.Option value="disabled" disabled>
                        {enterQueryPrompt}
                    </Select.Option>
                ) : indicatorTagsQuery.isSuccess ? (
                    (indicatorTagsQuery.data || []).map(
                        (indicatorTag: IndicatorTag) => {
                            return (
                                <Select.Option
                                    key={indicatorTag.id}
                                    value={indicatorTag.id}
                                    title={indicatorTag.value}
                                >
                                    {indicatorTag.value}
                                </Select.Option>
                            );
                        }
                    )
                ) : indicatorTagsQuery.isError ? (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading indicator tag options
                    </Select.Option>
                ) : (
                    <Select.Option value="disabled" disabled>
                        Loading indicator tags...
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default IndicatorTagsSelect;
