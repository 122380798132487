import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Modal,
    Typography,
    Input,
    Form,
    Select,
    Space,
    Tooltip,
    Skeleton,
} from "antd";
import { useEffect, useState } from "react";
const { confirm } = Modal;

import { additionalColors } from "constants/colors";
import AIAssistantIcon from "icons/AI Assistant_icon.png";
import { ShallowContactRead } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import {
    useGetEmailGenerationInputForm,
    usePostEmailGenerationInputForm,
} from "reactQuery/hooks/useAIGenEmail";
import { useSnackStore } from "stores/zustandStore";

import { AIEmailAssistantOutputModal } from "../AIEmailAssistantOutputModal";

export interface AIEmailAssistantInputModalProps {
    setAIEmailAssistantInputModalVisibility: Function;
    visible: boolean;
    contacts?: ShallowContactRead[];
    contactId: number;
    opportunityId: number;
    teamId: number;
}

export const AIEmailAssistantInputModal = ({
    setAIEmailAssistantInputModalVisibility,
    visible,
    contacts,
    contactId,
    opportunityId,
    teamId,
}: AIEmailAssistantInputModalProps) => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const postEmailGenerationInputFormQuery = usePostEmailGenerationInputForm(teamId);
    const whoamiQuery = useGetWhoami();
    const {
        data: emailGenerationInputFormData,
        isSuccess: emailGenerationInputFormIsSuccess,
    } = useGetEmailGenerationInputForm(teamId, visible);

    const [
        AIEmailAssistantOutputModalVisibility,
        setAIEmailAssistantOutputModalVisibility,
    ] = useState(false);

    const uniqueContacts = [
        ...new Map(contacts.map((contact) => [contact.id, contact])).values(),
    ];

    const [inputId, setInputId] = useState<number>();

    const toneOptions = [
        "Business Persuasive",
        "Informative",
        "Solution-Oriented",
        "Formal Professional",
    ];

    useEffect(() => {
        if (uniqueContacts.length > 0 && !form.getFieldValue("contactId") && visible) {
            form.setFieldValue("contactId", uniqueContacts[0].id);
        }
    }, [uniqueContacts, visible]);

    useEffect(() => {
        if (contactId) {
            form.setFieldValue("contactId", contactId);
        }
    }, [contactId]);

    const onFinish = (values: any) => {
        const payload = {
            user: whoamiQuery?.data?.user.id,
            team: teamId,
            products_services_and_expertise: values.productsServicesExpertise,
            case_study_description: values.caseStudyDescription,
            primary_goal: values.primaryGoal,
            call_to_action: values.callToAction,
            tone: values.tone,
            contact: values.contactId,
            saved: values.submitType === "save" ? true : false,
        };

        form.setFields([
            { name: "productsServicesExpertise", touched: false },
            { name: "caseStudyDescription", touched: false },
            { name: "primaryGoal", touched: false },
            { name: "callToAction", touched: false },
            { name: "tone", touched: false },
            { name: "contactId", touched: false },
        ]);

        postEmailGenerationInputFormQuery.mutate(payload, {
            onSuccess: (response) => {
                if (values.submitType === "generate") {
                    setAIEmailAssistantInputModalVisibility(false);
                    setAIEmailAssistantOutputModalVisibility(true);
                    setInputId(response.email_gen_input_object_id);
                } else {
                    displaySuccessSnack({ message: "Your responses have been saved" });
                }
            },
        });
    };

    const onFinishFailed = () => {
        displayErrorSnack({
            message: "Error validating fields",
        });
    };

    const handleSave = () => {
        form.setFieldValue("submitType", "save");
    };
    const handleGenerate = () => {
        form.setFieldValue("submitType", "generate");
    };

    const onModalCancel = () => {
        setAIEmailAssistantInputModalVisibility(false);
        form.resetFields();
    };

    const submitDisabled = false;

    const optionalLabel = (
        <span
            style={{
                color: additionalColors.grey600,
                fontStyle: "italic",
                marginLeft: "5px",
            }}
        >
            (Optional)
        </span>
    );

    const [form] = Form.useForm();
    const formId = "ai-email-assistant-form";

    return (
        <>
            <Modal
                open={visible}
                width={1000}
                centered
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        confirm({
                            centered: true,
                            title: "You have unsaved changes.",
                            icon: <ExclamationCircleOutlined />,
                            content:
                                "You will lose them if you proceed. Do you want to exit?",
                            onOk() {
                                onModalCancel();
                            },
                            onCancel() {
                                return false;
                            },
                        });
                    } else {
                        setAIEmailAssistantInputModalVisibility(false);
                    }
                }}
                forceRender
                footer={
                    <div style={{ margin: "auto", textAlign: "center" }}>
                        <Space size="large">
                            <Button
                                key="save"
                                type="default"
                                htmlType="submit"
                                form={formId}
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            <Button
                                key="generate"
                                type="primary"
                                htmlType="submit"
                                form={formId}
                                onClick={() => handleGenerate()}
                                disabled={submitDisabled}
                                loading={false}
                            >
                                Generate Email
                            </Button>
                        </Space>
                    </div>
                }
            >
                {emailGenerationInputFormIsSuccess ? (
                    <Form
                        id={formId}
                        form={form}
                        name={formId}
                        layout="vertical"
                        initialValues={{
                            tone: emailGenerationInputFormData?.tone ?? toneOptions[0],
                            productsServicesExpertise:
                                emailGenerationInputFormData?.products_services_and_expertise,
                            caseStudyDescription:
                                emailGenerationInputFormData?.case_study_description,
                            primaryGoal: emailGenerationInputFormData?.primary_goal,
                            callToAction: emailGenerationInputFormData?.call_to_action,
                        }}
                        style={{ width: "80%", margin: "auto" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div style={{ textAlign: "center", marginBottom: "25px" }}>
                            <Space>
                                <img width="40px" height="40px" src={AIAssistantIcon} />
                                <Typography.Title level={3} style={{ margin: 0 }}>
                                    Complete the Input Form for Personalized Email
                                    Generation
                                </Typography.Title>
                            </Space>
                        </div>

                        <Form.Item hidden={true} name="submitType">
                            <Input />
                        </Form.Item>

                        {uniqueContacts.length ? (
                            <Form.Item
                                name="contactId"
                                label={
                                    <Tooltip title="Select one of the contacts from the list provided. If there is no contacts available leave this field unselected.">
                                        <span style={{ fontWeight: "bold" }}>
                                            Select Contact
                                        </span>
                                    </Tooltip>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a contact",
                                    },
                                ]}
                            >
                                <Select dropdownRender={(menu) => <>{menu}</>}>
                                    {uniqueContacts.map((contact) => (
                                        <Select.Option
                                            key={contact.id}
                                            value={contact.id}
                                            title={contact.id}
                                        >
                                            {contact.first_name} {contact.last_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : null}

                        <Form.Item
                            name="productsServicesExpertise"
                            label={
                                <Tooltip title="Provide details about the exact product and services your company can provide which will help solve the challenge in hand for the customer.">
                                    <span style={{ fontWeight: "bold" }}>
                                        My company specializes in providing products and
                                        services related to
                                    </span>
                                </Tooltip>
                            }
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please provide details about related products and services",
                                },
                            ]}
                        >
                            <Input.TextArea rows={2} />
                        </Form.Item>

                        <Form.Item
                            name="caseStudyDescription"
                            label={
                                <Tooltip title="Provide a summary of different case studies or similar projects your company has completed. These projects will be used as reference in the email to help to engage the email receiver.">
                                    <span style={{ fontWeight: "bold" }}>
                                        My company has completed similar project(s) in
                                        the past such as
                                    </span>
                                </Tooltip>
                            }
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please provide details about similar projects",
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="primaryGoal"
                            label={
                                <Tooltip title="Provide details about the primary goal of your company which you would like to be considered when generating the email.">
                                    <span style={{ fontWeight: "bold" }}>
                                        My company's primary goal is
                                    </span>
                                    {optionalLabel}
                                </Tooltip>
                            }
                        >
                            <Input.TextArea rows={2} />
                        </Form.Item>

                        <Form.Item
                            name="callToAction"
                            label={
                                <Tooltip title="Provide details on how you would like to further communicate with the recipient of this email.">
                                    <span style={{ fontWeight: "bold" }}>
                                        Call to Action
                                    </span>
                                    {optionalLabel}
                                </Tooltip>
                            }
                        >
                            <Input.TextArea rows={1} />
                        </Form.Item>

                        <Form.Item
                            name="tone"
                            label={
                                <Tooltip title="Select your preference fort the tone of the email being generated.">
                                    <span style={{ fontWeight: "bold" }}>
                                        Tone of the Email
                                    </span>
                                </Tooltip>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a tone",
                                },
                            ]}
                        >
                            <Select dropdownRender={(menu) => <>{menu}</>}>
                                {toneOptions.map((tone) => (
                                    <Select.Option key={tone} value={tone} title={tone}>
                                        {tone}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : (
                    <Skeleton />
                )}
            </Modal>

            <AIEmailAssistantOutputModal
                setAIEmailAssistantInputModalVisibility={
                    setAIEmailAssistantInputModalVisibility
                }
                visible={AIEmailAssistantOutputModalVisibility}
                setAIEmailAssistantOutputModalVisibility={
                    setAIEmailAssistantOutputModalVisibility
                }
                inputId={inputId}
                opportunityId={opportunityId}
            />
        </>
    );
};
