import { ShallowContactRead, InsightInOpportunity } from "reactQuery/hooks/apiTypes";

import { ContactCard } from "../ContactCard";

export const ContactsList = ({
    insightsAndContacts,
    opportunityId,
    isEditModeActive,
    setAIEmailAssistantInputModalVisibility,
    setAIEmailAssistantInputModalContactId,
}: {
    insightsAndContacts: {
        contact: ShallowContactRead;
        insight: InsightInOpportunity;
    }[];
    isEditModeActive: boolean;
    opportunityId: number;
    setAIEmailAssistantInputModalVisibility: Function;
    setAIEmailAssistantInputModalContactId: Function;
}) => {
    const filteredInsightsAndContacts = isEditModeActive
        ? insightsAndContacts
        : insightsAndContacts.filter((insightAndContact, index, array) => {
              return (
                  array.findIndex(
                      (potentialInsightAndContact) =>
                          potentialInsightAndContact.contact.id ===
                          insightAndContact.contact.id
                  ) === index
              );
          });
    return (
        <>
            {filteredInsightsAndContacts.map(({ contact, insight }) => {
                return (
                    <ContactCard
                        contact={contact}
                        insight={insight}
                        opportunityId={opportunityId}
                        isEditModeActive={isEditModeActive}
                        key={contact.id + " " + insight.id}
                        setAIEmailAssistantInputModalVisibility={
                            setAIEmailAssistantInputModalVisibility
                        }
                        setAIEmailAssistantInputModalContactId={
                            setAIEmailAssistantInputModalContactId
                        }
                    />
                );
            })}
        </>
    );
};
