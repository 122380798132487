import Link from "@material-ui/core/Link";
import React from "react";

export const HelpEmail = ({ emailSubject, onClick, helpText }) => (
    <Link
        href={`mailto:help@citylitics.com?subject=${emailSubject}`}
        onClick={onClick}
        variant="body2"
    >
        {helpText}
    </Link>
);

export default HelpEmail;
