import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { nautilusGetRequest, nautilusPostRequest } from "network";
import { targetsReducerName } from "stores/targets/constants";
import {
    batchIdSelector,
    isQueryingSelector,
    pendingQueriesSelector,
    queryWatcherIdSelector,
} from "stores/targets/selectors";
import { displayErrorSnack, displaySuccessSnack } from "stores/uiStore/actionTypes";

const formatName = (name) => `${targetsReducerName}/${name}`;

export const clearQueryResults = createAction(formatName("clearQueryResults"));

export const enqueueTargetSurfacerQuery = createAction(
    formatName("enqueueTargetSurfacerQuery")
);
export const clearBatchId = createAction(formatName("clearBatchId"));
export const setPreviousQuery = createAction(formatName("setPreviousQuery"));

export const setSelectedUserSearchHistory = createAction(
    formatName("setSelectedUserSearchHistory")
);
export const setSelectedTeamSearchHistory = createAction(
    formatName("setSelectedTeamSearchHistory")
);
export const setSelectedSearchRecord = createAction(
    formatName("setSelectedSearchRecord")
);

export const searchTargetSurfacer = createAsyncThunk(
    formatName("searchTargetSurfacer"),
    async (payload, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const queryToSearch = payload.query;
        const queryIdToSearch = payload.queryId;
        const batchId = batchIdSelector(state);
        const endpointURL = "/api/target_surfacer/v2/search";
        const short_query = queryToSearch.query.substring(0, 50);
        try {
            const response = await nautilusPostRequest(endpointURL, {
                batch_id: batchId,
                from_date: queryToSearch.fromDate,
                to_date: queryToSearch.toDate,
                query_text: queryToSearch.query,
                indices: queryToSearch.universe,
                included_tag_ids: queryToSearch.includedTags,
                excluded_tag_ids: queryToSearch.excludedTags,
                team_id: queryToSearch.selectedTeamId,
            });

            if (response.data.results.length > 0) {
                if (response.data.is_timeout === true) {
                    dispatch(
                        displayErrorSnack({
                            message: `Your search results for ${short_query} took too long to process so 
                            the results you see may not be complete. Consider modifying queries to get better results.`,
                        })
                    );
                } else {
                    dispatch(
                        displaySuccessSnack({
                            message: "Query Complete: " + short_query,
                        })
                    );
                }
            } else {
                dispatch(
                    displayErrorSnack({
                        message: "No results found for keyword:" + short_query,
                    })
                );
            }
            return {
                ...response.data,
                queryId: queryIdToSearch,
            };
        } catch (e) {
            let message = e.response.data.message + " " + short_query;

            dispatch(
                displayErrorSnack({
                    message: message,
                })
            );
            return rejectWithValue({
                query: queryToSearch,
                queryId: queryIdToSearch,
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const createBatch = createAsyncThunk(
    formatName("createBatch"),
    async ({ number_of_queries }, { dispatch, rejectWithValue }) => {
        const requestBody = {
            number_of_queries: number_of_queries,
        };
        try {
            const response = await nautilusPostRequest(
                "/api/target_surfacer/batch/",
                requestBody
            );

            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to create batch" }));
            return rejectWithValue({
                status: e.response.status,
                message: e.response.data,
            });
        }
    }
);

export const WATCH_FOR_TARGET_SURFACER_QUERIES_TIMEOUT = 2000;
export const watchForTargetSurfacerQueries = createAsyncThunk(
    formatName("watchForTargetSurfacerQueries"),
    async (payload, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const pendingQueries = pendingQueriesSelector(state);
        if (
            Object.keys(pendingQueries).length &&
            !isQueryingSelector(state) &&
            batchIdSelector(state)
        ) {
            const queryIdToSearch = Object.keys(pendingQueries)[0];
            const queryToSearch = pendingQueries[queryIdToSearch];
            dispatch(
                searchTargetSurfacer({
                    query: queryToSearch,
                    queryId: queryIdToSearch,
                })
            );
        }
        await new Promise((r) =>
            setTimeout(r, WATCH_FOR_TARGET_SURFACER_QUERIES_TIMEOUT)
        );
        dispatch(watchForTargetSurfacerQueries({}));
    }
);

export const registerQueryWatcher = createAsyncThunk(
    formatName("registerQueryWatcher"),
    async ({ watcherId }, { dispatch, rejectWithValue }) => {
        dispatch(watchForTargetSurfacerQueries({}));
        return { watcherId };
    },
    {
        condition: ({ watcherId }, { getState }) => {
            try {
                const currentWatcherId = queryWatcherIdSelector(getState());
                return currentWatcherId === null;
            } catch (e) {
                return false;
            }
        },
    }
);

export const generateStaticLink = createAsyncThunk(
    formatName("generateStaticLink"),
    async ({ bucket, path }, { dispatch, rejectWithValue }) => {
        try {
            const responseBody = {
                bucket,
                path,
            };
            const response = await nautilusPostRequest(
                "/api/links/link/",
                responseBody
            );
            dispatch(
                displaySuccessSnack({
                    message: "Successfully generated static link",
                })
            );
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to generate static link",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchSearchRecords = createAsyncThunk(
    formatName("fetchSearchRecords"),
    async ({ offset, selectedUser, selectedTeamId }, { dispatch, rejectWithValue }) => {
        try {
            let queryString = offset ? `?offset=${offset}` : "";

            if (selectedUser) {
                queryString +=
                    queryString === ""
                        ? `?user=${selectedUser}`
                        : `&user=${selectedUser}`;
            }

            if (selectedTeamId) {
                queryString +=
                    queryString === ""
                        ? `?team=${selectedTeamId}`
                        : `&team=${selectedTeamId}`;
            }
            //const optionalParams = offset ? `?offset=${offset}` : "";
            const response = await nautilusGetRequest(
                "/api/target_surfacer/search_records/",
                queryString
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to fetch search records",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);
