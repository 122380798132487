import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LessIcon from "@material-ui/icons/ExpandLess";
import MoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

import StyledButton from "components/atoms/StyledButton";
import InsightTimeline from "components/cdv/InsightTimeline";
import { sourceStyles } from "components/cdv/OpportunityCard/styles";
import {
    formatDayHyphenMonthHyphenYear,
    formatDayHyphenShortenedMonthHyphenYear,
} from "constants/formatters";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { DeepInsight, ShallowReportRead, Source } from "reactQuery/hooks/apiTypes";
import { overlayNames } from "stores/uiStore/constants";
import { createLink } from "utils/createLink";
import { isIndicatorFeedsRoute, isIntelligenceFeedsRoute } from "utils/redirect";

import { buttonTestIds } from "..";

const IndividualSource = ({
    insights,
    reports,
    insight,
    isEditModeActive,
    source,
    setSourceForEdit,
    toggleOverlay,
    isLoadingInsight,
    isLoadingReportsForTeam,
}: {
    insights: DeepInsight[];
    reports: ShallowReportRead[];
    insight: DeepInsight;
    isEditModeActive: boolean;
    opportunityType: OpportunityTypeEnum;
    source: Source;
    setSourceForEdit: Function;
    toggleOverlay: Function;
    isLoadingInsight: boolean;
    isLoadingReportsForTeam: boolean;
}) => {
    const theme = useTheme();
    const isNotMobileScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [isCollapsed, setIsCollapsed] = useState(false);
    const styles = sourceStyles();
    const onToggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    };

    const linkURL = createLink({
        url: source.url,
        ...(source.page_number ? { hash: `page=${source.page_number}` } : {}),
    });

    let showDisplayDate = isIntelligenceFeedsRoute() || isIndicatorFeedsRoute();
    const formattedDisplayDate = formatDayHyphenShortenedMonthHyphenYear(
        showDisplayDate ? insight.display_date : insight.report_date
    );

    const isLoading =
        isLoadingInsight ||
        isLoadingReportsForTeam ||
        reports.length === 0 ||
        insights.length === 0;
    const collapseButton = !isEditModeActive ? (
        <StyledButton
            variant="collapse-button"
            data-testid={buttonTestIds.toggleSource(source.id)}
            endIcon={isCollapsed ? <MoreIcon /> : <LessIcon />}
            handleClick={onToggleCollapsed}
        >
            View {isCollapsed ? "More" : "Less"}
        </StyledButton>
    ) : null;
    const testId = buttonTestIds.editSource(insight.id, source.id);
    const editSourceIcon = isEditModeActive ? (
        <StyledButton
            data-testid={testId}
            handleClick={() => {
                setSourceForEdit({
                    source: source,
                });
                toggleOverlay({ overlay: overlayNames.editSourceModal });
            }}
            variant="edit-secondary"
        >
            Edit
        </StyledButton>
    ) : null;
    return (
        <Grid className={styles.sourcesContainer} container key={source.id}>
            {isNotMobileScreen ? null : (
                <InsightTimeline
                    isEditModeActive={isEditModeActive}
                    formattedDisplayDate={formattedDisplayDate}
                    insight={insight}
                    isLoading={isLoading}
                    index={0}
                    isNotMobileScreen={isNotMobileScreen}
                />
            )}
            <Grid item xs={12} sm={12} md={3}>
                <Typography className={styles.label}>Source</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Typography>
                    <a href={linkURL} target="_blank">
                        {source.title}
                    </a>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                {editSourceIcon}
                {collapseButton}
            </Grid>
            {!isCollapsed || isEditModeActive ? (
                <>
                    {source.extract ? (
                        <>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography className={styles.label}>
                                    Trigger Extract
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <ReactMarkdown className={styles.extract}>
                                    {source.extract}
                                </ReactMarkdown>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12} sm={12} md={3}>
                        <Typography className={styles.label}>Source Date</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Typography>
                            {formatDayHyphenMonthHyphenYear(source.date)}
                        </Typography>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default IndividualSource;
