import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { actionsKeys } from "reactQuery/keys";

import { PaginatedActionsList } from "./apiTypes";

export const useGetAllActions = (): Partial<UseQueryResult<PaginatedActionsList>> => {
    const queryKey = actionsKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<PaginatedActionsList> => {
            const { data } = await nautilusGetRequest(`/api/target_reports/actions`);
            return data;
        },
    });
};
