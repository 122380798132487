import TextField from "@material-ui/core/TextField";

export const FormTextField = ({ labelText, name, helperText, formikProps }) => {
    const { handleChange, values } = formikProps;
    return (
        <TextField
            name={name}
            label={labelText}
            helperText={helperText}
            onChange={handleChange}
            value={values.actionNotes}
            defaultValue=""
            variant="outlined"
            fullWidth={true}
        />
    );
};

export default FormTextField;
