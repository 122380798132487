import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";

import { FormModal } from "components/molecules/Modal";
import { openNewInternalLink } from "constants/navigation";
import { selectActiveOpportunityOwnerPopulation } from "stores/demographics/demographicSlice";
import {
    activeOpportunityOwnerSelector,
    activeOpportunitySelector,
} from "stores/opportunities/selectors";
import { closeAllOverlays } from "stores/uiStore/actionTypes";
import { isShowingSelector } from "stores/uiStore/selectors";

const editOpportunityOwnerBodyStyle = makeStyles(() => ({
    fieldRow: {
        height: "3vh",
    },
    label: {
        color: "grey",
    },
}));

const EditOpportunityOwnerBody = ({
    opportunityOwner,
    ownerPopulation,
    opportunity,
}) => {
    const classes = editOpportunityOwnerBodyStyle();
    const isFieldUnknown = (field) => {
        return (
            opportunity?.owner?.demographics.length >= 1 &&
            opportunity.owner.demographics[0][field]
        );
    };
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.fieldRow}
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Opportunity Owner:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>
                            {" "}
                            {`${opportunityOwner.legal_name}, ${opportunityOwner.state_code}`}
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.fieldRow}
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Population:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>
                            {ownerPopulation
                                ? ownerPopulation.toLocaleString()
                                : "Unknown"}
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.fieldRow}
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Address:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>
                            {isFieldUnknown("address")
                                ? opportunity.owner.demographics[0].address
                                : "Unknown"}
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.fieldRow}
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Zip Code:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>
                            {isFieldUnknown("zip")
                                ? opportunity.owner.demographics[0].zip
                                : "Unknown"}
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.fieldRow}
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>County:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>
                            {isFieldUnknown("county")
                                ? opportunity.owner.demographics[0].county
                                : "Unknown"}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const UnconnectedEditOpportunityOwnerModal = ({
    closeAllOverlays,
    isVisible,
    opportunityOwner,
    ownerPopulation,
    opportunity,
}) => {
    if (!opportunityOwner) {
        return null;
    }

    return (
        <FormModal
            handleClose={() => {
                closeAllOverlays();
            }}
            handleSecondaryClick={() => {
                openNewInternalLink(
                    `/admin/universe/owner/${opportunityOwner.id}/change/`
                );
                closeAllOverlays();
            }}
            handleSubmit={() => {
                const demographics = opportunityOwner.demographics;
                openNewInternalLink(
                    `/admin/universe/demographic/${demographics[0].id}/change/`
                );
                closeAllOverlays();
            }}
            isVisible={isVisible}
            submitLabel="Edit Opportunity Owner Details"
            title="Edit Opportunity Owner"
            hasSubmitButton={
                opportunityOwner.demographics &&
                opportunityOwner.demographics.length === 1
            }
            hasSecondaryButton={true}
            secondaryLabel="Edit Owner"
            isEditModeActive={true}
        >
            <EditOpportunityOwnerBody
                opportunityOwner={opportunityOwner}
                ownerPopulation={ownerPopulation}
                opportunity={opportunity}
            />
        </FormModal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        isVisible: isShowing.editOpportunityOwnerModal,
        opportunityOwner: activeOpportunityOwnerSelector(state),
        ownerPopulation: selectActiveOpportunityOwnerPopulation(state),
        opportunity: activeOpportunitySelector(state),
    };
};

const ConnectedEditOpportunityOwnerModal = connect(mapStateToProps, {
    closeAllOverlays,
})(UnconnectedEditOpportunityOwnerModal);

export default ConnectedEditOpportunityOwnerModal;
