import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import logo from "citylitics-logo-dark.png";
import StyledButton from "components/atoms/StyledButton";
import Copyright from "components/nautilus/Copyright";
import HelpEmail from "components/nautilus/HelpEmail";
import SignupForm from "components/nautilus/SignupForm";
import { authPageTheme } from "constants/themes";
import {
    fetchUserData,
    analyticsEvent,
    signupLinkVerification,
} from "stores/userStore/actionTypes";
import { eventActions, eventCategories } from "stores/userStore/analyticsEvents";
import {
    isAuthenticatedSelector,
    passwordResetCompletedSelector,
    passwordResetErrorsSelector,
    signupLinkVerificationCompletedSelector,
    signupLinkVerificationErrorsSelector,
} from "stores/userStore/selectors";
import { extractNextUrlParameter } from "utils/redirect";

const useStyles = makeStyles(authPageTheme);

export const InvalidTokenMessage = () => {
    const classes = useStyles();
    return (
        <>
            <Typography
                data-testid="invalid-token-message"
                className={classes.descriptionText}
            >
                It appears that you have followed an invalid link - please confirm the
                address listed in your email.
            </Typography>
        </>
    );
};

export const PasswordResetConfirmation = ({ analyticsEvent, push }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.descriptionText}>
                Your password has been successfully setup.
            </Typography>
            <StyledButton
                href="/"
                className={classes.button}
                color="primary"
                onClick={() => {
                    analyticsEvent({
                        action: eventActions.CLICKED,
                        category: eventCategories.FORMS,
                        executeOnceComplete: () => {
                            push("/login?next=/");
                        },
                        label: "Sign In - Reset Password Success",
                    });
                }}
                variant="contained"
            >
                Sign In
            </StyledButton>
        </>
    );
};

export const UnconnectedSignUp = ({
    analyticsEvent,
    fetchUserData,
    signupLinkVerification,
    responseErrors,
    signupLinkVerificationResponseErrors,
    isAuthenticated,
    hasCompleted,
    signupLinkVerificationHasCompleted,
    location,
    push,
}) => {
    const split_location = location.pathname.split("/");
    const uid = split_location[2];
    const token = split_location[3];
    const email = split_location[4];
    const company = split_location[5];
    const hasInvalidToken =
        signupLinkVerificationResponseErrors.invalidSecret ||
        responseErrors.invalidSecret ||
        !uid ||
        !token ||
        !company;
    const invalidTokenMessage = hasInvalidToken && <InvalidTokenMessage />;
    const passwordChangedMessage = hasCompleted && (
        <PasswordResetConfirmation analyticsEvent={analyticsEvent} push={push} />
    );

    useEffect(() => {
        if (isAuthenticated) {
            push(extractNextUrlParameter());
        }
        if (isAuthenticated === null) {
            fetchUserData(false);
        }
        if (isAuthenticated === false) {
            signupLinkVerification({ uid, token }, true);
        }
    }, [isAuthenticated, fetchUserData, push, signupLinkVerification, uid, token]);

    const classes = useStyles();

    const helpEmailTrackClick = () => {
        analyticsEvent({
            action: eventActions.CLICKED,
            category: eventCategories.FORMS,
            label: "Need Help Sign Up",
        });
    };

    return signupLinkVerificationHasCompleted ||
        signupLinkVerificationResponseErrors.invalidSecret ? (
        <Container maxWidth="xs">
            <div className={classes.container}>
                <img alt="WatrHub" className={classes.logo} src={logo} />
                <Typography className={classes.title} component="h1" variant="h5">
                    Sign Up
                </Typography>
                {invalidTokenMessage}
                {passwordChangedMessage}
                {!hasInvalidToken && !hasCompleted ? (
                    <>
                        <Typography className={classes.infoText} component="p">
                            You have been invited to join the {`${company}`} team on
                            Citylitics. Please create a password to access your account.
                        </Typography>
                        <SignupForm uid={uid} token={token} email={email} />
                    </>
                ) : null}
            </div>
            <div className={classes.actionLinks}>
                <HelpEmail
                    emailSubject="Sign Up Help"
                    helpText="Need help?"
                    onClick={helpEmailTrackClick}
                />
            </div>
            <Copyright className={classes.copyright} />
        </Container>
    ) : null;
};

const mapStateToProps = (state) => {
    return {
        hasCompleted: passwordResetCompletedSelector(state as never),
        responseErrors: passwordResetErrorsSelector(state as never),
        signupLinkVerificationHasCompleted: signupLinkVerificationCompletedSelector(
            state as never
        ),
        signupLinkVerificationResponseErrors: signupLinkVerificationErrorsSelector(
            state as never
        ),
        isAuthenticated: isAuthenticatedSelector(state),
    };
};

const ConnectedSignUp = connect(mapStateToProps, {
    analyticsEvent,
    fetchUserData,
    signupLinkVerification,
    push,
})(UnconnectedSignUp);

export default ConnectedSignUp;
