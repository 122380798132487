import { useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { Modal, Button, Typography, InputNumber, Select, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { OpportunityRead } from "reactQuery/hooks/apiTypes";
import { usePatchOpportunity } from "reactQuery/hooks/useOpportunity";
import { opportunityKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";

const { Option } = Select;

export const OpportunityValueModal = ({
    opportunity,
    editModalOpen,
    setEditModalOpen,
}: {
    opportunity: OpportunityRead;
    editModalOpen: boolean;
    setEditModalOpen: (open: boolean) => void;
}) => {
    const [form] = Form.useForm();

    const [removeOpportunityValue, setRemoveOpportunityValue] = useState(false);

    const patchOpportunity = usePatchOpportunity();
    const queryClient = useQueryClient();
    const { search } = useLocation();

    const teamId = new URLSearchParams(search).get("team_id");

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const formId = `edit-opportunity-value-opp-id-${opportunity.id}`;

    const handleCancel = () => {
        form.resetFields();
        setEditModalOpen(false);
        setTimeout(() => {
            setRemoveOpportunityValue(false);
        }, 200);
    };

    useEffect(() => {
        form.setFieldsValue(initialValues());
    }, [opportunity.id]);

    const handleSubmit = (
        values: { amount: number; currency: string },
        patchOpportunity: Partial<UseMutationResult<OpportunityRead>>
    ) => {
        const opportunityId = opportunity.id;

        if (removeOpportunityValue) {
            form.setFieldValue("amount", undefined);
        }

        let opportunityValue = removeOpportunityValue
            ? { currency: initialValues().currency, value: 0 }
            : {
                  value: values.amount,
                  currency: values.currency,
              };

        patchOpportunity.mutate(
            {
                opportunityPayload: {
                    opportunity_value: opportunityValue,
                },
                opportunityId: opportunityId,
                teamId: teamId,
            },
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(
                        opportunityKeys.single_opportunity(opportunityId),
                        {
                            ...opportunity,
                            opportunity_value: data.opportunity_value,
                        }
                    );
                    setEditModalOpen(false);
                    setTimeout(() => {
                        setRemoveOpportunityValue(false);
                    }, 200);

                    removeOpportunityValue
                        ? displaySuccessSnack({
                              message: "Opportunity Value successfully removed",
                          })
                        : displaySuccessSnack({
                              message: "Opportunity Value successfully updated",
                          });
                },
                onError: () => {
                    removeOpportunityValue
                        ? displayErrorSnack({
                              message: "Failed to remove Opportunity Value",
                          })
                        : displayErrorSnack({
                              message: "Failed to update Opportunity Value",
                          });
                },
            }
        );
    };
    const initialValues = () => {
        const {
            source_opportunity_value: sourceOppValue,
            source_opportunity_value_currency: sourceOppCurrency,
        } = opportunity.opportunity_value;
        const {
            customer_opportunity_value: customerOppValue,
            customer_opportunity_value_currency: customerOppCurrency,
        } = opportunity.opportunity_value;

        if (customerOppValue) {
            return {
                amount: customerOppValue,
                currency: customerOppCurrency,
            };
        } else if (customerOppValue === 0) {
            return {
                amount: null,
                currency: customerOppCurrency,
            };
        } else {
            const demographicsCountryCode =
                opportunity?.owner?.demographics?.[0]?.country_code;

            const defaultCurrency = demographicsCountryCode === "CA" ? "CAD" : "USD";

            return {
                amount: sourceOppValue,
                currency: sourceOppCurrency ?? defaultCurrency,
            };
        }
    };

    return (
        <Modal
            title="Opportunity Value"
            open={editModalOpen}
            onCancel={handleCancel}
            forceRender
            footer={
                <>
                    {removeOpportunityValue ? (
                        <Row justify="space-between">
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setRemoveOpportunityValue(false);
                                }}
                                type="default"
                            >
                                Cancel
                            </Button>

                            <Button
                                form={formId}
                                key="removesubmit"
                                htmlType="submit"
                                type="primary"
                                danger
                            >
                                Remove
                            </Button>
                        </Row>
                    ) : (
                        <Row
                            justify="space-between"
                            style={{ flexDirection: "row-reverse" }}
                        >
                            <Button
                                form={formId}
                                key="submit"
                                htmlType="submit"
                                type="primary"
                            >
                                Submit
                            </Button>

                            {opportunity.opportunity_value
                                .customer_opportunity_value ? (
                                <Button
                                    key="remove"
                                    style={{ marginLeft: 0 }}
                                    onClick={() => {
                                        setRemoveOpportunityValue(true);
                                    }}
                                    danger
                                    type="default"
                                >
                                    Remove
                                </Button>
                            ) : undefined}
                        </Row>
                    )}
                </>
            }
        >
            <Form
                id={formId}
                form={form}
                name={formId}
                layout="horizontal"
                onFinish={(values) => {
                    return handleSubmit(values, patchOpportunity);
                }}
                initialValues={initialValues()}
                style={{ width: "100%" }}
            >
                {removeOpportunityValue ? (
                    <>
                        <Typography style={{ marginBottom: "20px" }}>
                            Are you sure that you would like to remove this opportunity
                            value from this opportunity?
                        </Typography>

                        <Row>
                            <Typography>Opportunity Value:</Typography>
                            <Typography
                                data-testid="confirm-removal-value"
                                style={{ paddingLeft: "6px", fontWeight: "bold" }}
                            >
                                $
                                {opportunity.opportunity_value.customer_opportunity_value?.toLocaleString()}{" "}
                                {
                                    opportunity.opportunity_value
                                        .customer_opportunity_value_currency
                                }
                            </Typography>
                        </Row>
                    </>
                ) : (
                    <>
                        <Typography style={{ marginBottom: "20px" }}>
                            Please provide the monetary value of this opportunity
                        </Typography>

                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid amount",
                                },
                            ]}
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                                addonAfter={
                                    <Form.Item name="currency" label="Currency" noStyle>
                                        <Select>
                                            <Option
                                                data-testid="CAD"
                                                key="CAD"
                                                value="CAD"
                                                title="CAD"
                                            >
                                                CAD
                                            </Option>
                                            <Option
                                                data-testid="USD"
                                                key="USD"
                                                value="USD"
                                                title="USD"
                                            >
                                                USD
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};
