import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";

import {
    nautilusDeleteRequest,
    nautilusGetRequest,
    nautilusPatchRequest,
    nautilusPostRequest,
} from "network";
import { insightsSelectors } from "stores/insights/insightSlice";
import { activeOpportunityIdSelector } from "stores/opportunities/selectors";
import {
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
} from "stores/uiStore/actionTypes";
export const sourcesAdapter = createEntityAdapter({});

export const sourceReducerName = "sources";
export const initialState = sourcesAdapter.getInitialState({
    error: null,
    isLoading: false,
    newSource: {
        error: null,
        isCreating: false,
        insightId: null,
    },
    editSource: {
        error: null,
        isUpdating: false,
        sourceId: null,
    },
    removeSource: {
        error: null,
        isRemoving: false,
    },
});

export const createSource = createAsyncThunk(
    `${sourceReducerName}/createSource`,
    async (
        { sourceUrl, sourceTitle, triggerExtract, sourceDate, insightId },
        { dispatch, rejectWithValue }
    ) => {
        const requestBody = {
            url: sourceUrl,
            title: sourceTitle,
            extract: triggerExtract,
            date: sourceDate,
            insight: insightId,
        };

        try {
            const response = await nautilusPostRequest(
                "/api/target_reports/sources/",
                requestBody
            );
            dispatch(closeAllOverlays());
            dispatch(
                displaySuccessSnack({ message: "Source was successfully created" })
            );
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to create source" }));
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchSourcesForOpportunity = createAsyncThunk(
    `${sourceReducerName}/fetchSourcesForOpportunity`,
    async ({ opportunityId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest(
                "/api/target_reports/sources/",
                `?opportunity=${opportunityId}`
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch sources for opportunity ${opportunityId}`,
                })
            );

            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const patchSource = createAsyncThunk(
    `${sourceReducerName}/patchSource`,
    async (
        { sourceUrl, sourceTitle, triggerExtract, sourceDate, sourceId },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const requestBody = {
                url: sourceUrl,
                title: sourceTitle,
                extract: triggerExtract,
                date: sourceDate,
            };

            const response = await nautilusPatchRequest(
                `/api/target_reports/sources/${sourceId}`,
                requestBody
            );
            dispatch(
                displaySuccessSnack({ message: "Source was successfully updated" })
            );
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to update source",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const removeSource = createAsyncThunk(
    `${sourceReducerName}/removeSource`,
    async ({ sourceId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusDeleteRequest(
                `/api/target_reports/sources/${sourceId}`
            );
            dispatch(
                displaySuccessSnack({ message: "Source was successfully deleted" })
            );
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to delete source" }));
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

const sourceSlice = createSlice({
    name: sourceReducerName,
    initialState,
    reducers: {
        setInsightForSourceCreation: (state, { payload }) => {
            state.newSource.insightId = payload.insightId;
        },
        setSourceForEdit: (state, { payload }) => {
            sourcesAdapter.upsertOne(state, payload.source);
            state.editSource.sourceId = payload && payload.source.id;
        },
    },
    extraReducers: {
        [createSource.pending]: (state) => {
            state.newSource.error = null;
            state.newSource.isCreating = true;
            state.newSource.source = null;
        },
        [createSource.fulfilled]: (state, { payload }) => {
            state.newSource.error = null;
            state.newSource.isCreating = false;
            state.newSource.source = payload;
            sourcesAdapter.upsertOne(state, payload);
            state.newSource.insightId = null;
        },
        [createSource.rejected]: (state, { payload }) => {
            state.newSource.error = payload;
            state.newSource.isCreating = false;
            state.newSource.source = null;
        },
        [fetchSourcesForOpportunity.pending]: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        [fetchSourcesForOpportunity.fulfilled]: (state, action) => {
            sourcesAdapter.upsertMany(state, action.payload);
            state.error = null;
            state.isLoading = false;
        },
        [fetchSourcesForOpportunity.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        [removeSource.pending]: (state) => {
            state.removeSource.error = null;
            state.removeSource.isRemoving = true;
        },
        [removeSource.fulfilled]: (
            state,
            {
                meta: {
                    arg: { sourceId },
                },
            }
        ) => {
            state.removeSource.error = null;
            state.removeSource.isRemoving = false;
            sourcesAdapter.removeOne(state, sourceId);
        },
        [removeSource.rejected]: (state, { payload }) => {
            state.removeSource.error = payload;
            state.removeSource.isRemoving = false;
            state.removeSource.source = null;
        },
        [patchSource.pending]: (state) => {
            state.editSource.error = null;
            state.editSource.isUpdating = true;
        },
        [patchSource.fulfilled]: (state, { payload }) => {
            sourcesAdapter.upsertOne(state, payload);
            state.editSource.error = null;
            state.editSource.isUpdating = false;
        },
        [patchSource.rejected]: (state, { payload }) => {
            state.editSource.error = payload;
            state.editSource.isUpdating = false;
        },
    },
});

const { actions, reducer } = sourceSlice;

export default reducer;

export const { setInsightForSourceCreation, setSourceForEdit } = actions;

// Selectors
export const sourceStoreSelector = (state) => state[sourceReducerName];

export const sourcesSelectors = sourcesAdapter.getSelectors(sourceStoreSelector);

export const newSourceSelector = createSelector(
    [sourceStoreSelector],
    (sourceStore) => sourceStore.newSource
);

export const selectSourcesForActiveOpportunity = createSelector(
    [
        activeOpportunityIdSelector,
        insightsSelectors.selectAll,
        sourcesSelectors.selectAll,
    ],
    (activeOpportunityId, insights, sources) => {
        const relevantInsights = insights
            .filter((insight) => insight.opportunity.id === activeOpportunityId)
            .reduce((result, insight) => {
                result.push(insight.id);
                return result;
            }, []);
        return sources.filter((source) => relevantInsights.includes(source.insight));
    }
);

export const editSourceSelector = createSelector(
    [sourceStoreSelector],
    (sourceStore) => sourceStore.editSource
);

export const removeSourceSelector = createSelector(
    [sourceStoreSelector],
    (sourceStore) => sourceStore.removeSource
);

export const sourceBeingEditedSelector = createSelector(
    [editSourceSelector, sourcesSelectors.selectEntities],
    (editSource, sourceMap) => sourceMap[editSource.sourceId]
);

export const isCreatingSourceSelector = createSelector(
    [newSourceSelector],
    (newSource) => newSource.isCreating
);

export const isLoadingSelector = createSelector(
    [sourceStoreSelector],
    (sourceStore) => sourceStore.isLoading
);
