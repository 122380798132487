// TODO: rename modal to "EditFacilityModal" and make necessary changes to other files
// TODO: change wasteWaterFacility variables to facility

import React from "react";
import { connect } from "react-redux";

import { EditModalBody } from "components/molecules/EditModalBody";
import { FormModal } from "components/molecules/Modal";
import { openNewInternalLink } from "constants/navigation";
import { patchInsight } from "stores/insights/actions";
import {
    insightToUnlinkWastewaterFacilityFromSelector,
    isUpdatingInsightSelector,
} from "stores/insights/selectors";
import { closeAllOverlays, toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";
import { wastewaterFacilityToUnlinkFromInsightSelector } from "stores/wastewaterFacilities/selectors";

export const EDIT_FACILITY_MODAL_TITLE = "Edit Facility";
export const REMOVE_FACILITY_MODAL_TITLE = "Remove Facility";
export const UnconnectedEditWastewaterFacilityModal = ({
    closeAllOverlays,
    insight,
    isRemoveModeActive,
    isRemoving,
    isVisible,
    patchInsight,
    toggleOverlay,
    wastewaterFacility,
}) => {
    if (!isVisible) {
        return null;
    }
    const removeWastewaterFacilityAndPatchInsight = () => {
        const updatedFacilities = insight["facilities"]
            .reduce((ids, facility) => {
                ids.push(facility.id);
                return ids;
            }, [])
            .filter((id) => id !== wastewaterFacility.id);
        patchInsight({
            insightId: insight.id,
            facilities: updatedFacilities,
        });
    };

    const bodyItems = [
        {
            label: "Facility:",
            value:
                wastewaterFacility && wastewaterFacility.facility_name
                    ? wastewaterFacility.facility_name
                    : "Unknown",
        },
        {
            label: "MGD:",
            value:
                wastewaterFacility && wastewaterFacility.design_flow
                    ? parseFloat(wastewaterFacility.design_flow).toFixed(2)
                    : "Unknown",
        },
    ];

    return (
        <FormModal
            handleClose={() => {
                closeAllOverlays();
            }}
            handleSecondaryClick={() => {
                toggleOverlay({ overlay: overlayNames.removeWasteWaterFacilityModal });
            }}
            handleSubmit={() => {
                if (isRemoveModeActive) {
                    removeWastewaterFacilityAndPatchInsight();
                } else {
                    openNewInternalLink(
                        `/admin/universe/facility/${wastewaterFacility.id}/change/`,
                        false
                    );
                    closeAllOverlays();
                }
            }}
            hasSecondaryButton={true}
            isSubmitDisabled={isRemoving}
            isSubmitLoading={isRemoving}
            isRemoveModeActive={isRemoveModeActive}
            isEditModeActive={true}
            isVisible={isVisible}
            submitLabel="Edit Facility Details"
            title={
                isRemoveModeActive
                    ? REMOVE_FACILITY_MODAL_TITLE
                    : EDIT_FACILITY_MODAL_TITLE
            }
        >
            <EditModalBody
                isLoading={isRemoving}
                isRemoveModeActive={isRemoveModeActive}
                removalMessage="Are you sure that you would like to remove this facility from this insight? Once removed, this action cannot be
                    reversed."
                items={bodyItems}
            />
        </FormModal>
    );
};

const mapStateToProps = (state) => {
    const isShowing = isShowingSelector(state);
    return {
        insight: insightToUnlinkWastewaterFacilityFromSelector(state),
        isRemoveModeActive: isShowing.removeWasteWaterFacilityModal,
        isRemoving: isUpdatingInsightSelector(state as never),
        isVisible: isShowing.editWasteWaterFacilityModal,
        wastewaterFacility: wastewaterFacilityToUnlinkFromInsightSelector(
            state as never
        ),
    };
};

export const ConnectedEditWastewaterFacilityModal = connect(mapStateToProps, {
    closeAllOverlays,
    patchInsight,
    toggleOverlay,
})(UnconnectedEditWastewaterFacilityModal);

export default ConnectedEditWastewaterFacilityModal;
