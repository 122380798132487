import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import StyledButton from "components/atoms/StyledButton";
import { authFormTheme } from "constants/themes";
import { requestPasswordReset } from "stores/userStore/actionTypes";
import {
    requestPasswordFailedServerValidationSelector,
    requestPasswordResetEmailSelector,
    requestPasswordResetHasSentEmailSelector,
    requestPasswordResetIsAttemptingSelector,
} from "stores/userStore/selectors";

export const ERROR_MESSAGES = {
    emailInvalid: "Invalid email address",
    emailMissing: "Work email address is required",
};

let requestPasswordResetFormSchema = Yup.object().shape({
    email: Yup.string()
        .email(ERROR_MESSAGES.emailInvalid)
        .required(ERROR_MESSAGES.emailMissing),
});

const useStyles = makeStyles(authFormTheme);

export const RequestPasswordResetForm = ({
    errors,
    failedServerValidation,
    handleChange,
    handleSubmit,
    isLoading,
    values: { email },
}) => {
    const classes = useStyles();
    const hasError = "email" in errors || failedServerValidation;
    const helpText =
        errors.email || (failedServerValidation ? ERROR_MESSAGES.emailInvalid : null);
    return (
        <Form className={classes.root}>
            <div className={classes.fullWidth}>
                <TextField
                    className={classes.textField}
                    error={hasError}
                    helperText={helpText}
                    id="email-input"
                    label="Work Email Address"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    variant="outlined"
                />
            </div>
            <StyledButton
                color="primary"
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                data-testid="request-password-reset-btn"
            >
                <Typography>Send email</Typography>
                {isLoading ? (
                    <CircularProgress
                        className={classes.loadingSpinner}
                        id="loading-spinner"
                        size={24}
                    />
                ) : null}
            </StyledButton>
        </Form>
    );
};

export const EnhancedRequestPasswordResetForm = ({
    failedServerValidation,
    requestPasswordReset,
    isAttemptingToResetPassword,
}) => {
    const onSubmit = (formData) => {
        requestPasswordReset({ email: formData.email });
    };
    return (
        <Formik
            initialValues={{ email: "" }}
            onSubmit={onSubmit}
            validationSchema={requestPasswordResetFormSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formikProps) => (
                <RequestPasswordResetForm
                    {...formikProps}
                    failedServerValidation={failedServerValidation}
                    isLoading={isAttemptingToResetPassword}
                />
            )}
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        failedServerValidation: requestPasswordFailedServerValidationSelector(
            state as never
        ),
        submittedEmail: requestPasswordResetEmailSelector(state as never),
        sentEmail: requestPasswordResetHasSentEmailSelector(state as never),
        isAttemptingToResetPassword: requestPasswordResetIsAttemptingSelector(
            state as never
        ),
    };
};

const ConnectedEnhancedRequestPasswordResetForm = connect(mapStateToProps, {
    requestPasswordReset,
})(EnhancedRequestPasswordResetForm);

export default ConnectedEnhancedRequestPasswordResetForm;
