import PDFText from "components/atoms/PDFText";
import PDFView from "components/atoms/PDFView";

const RenderDrinkingWaterSystems = (drinkingWaterSystem) => (
    <PDFView>
        <PDFText>{drinkingWaterSystem.name}</PDFText>
        <PDFText>
            {`Pop Served: ${
                drinkingWaterSystem.population_served
                    ? drinkingWaterSystem.population_served.toLocaleString()
                    : "Unknown"
            }`}
        </PDFText>
        <PDFText>
            {`PWSID: ${
                drinkingWaterSystem.public_water_system_id
                    ? drinkingWaterSystem.public_water_system_id.toLocaleString()
                    : "Unknown"
            }`}
        </PDFText>
    </PDFView>
);

export default RenderDrinkingWaterSystems;
