import { Form, Select } from "antd";

import { Indicator } from "reactQuery/hooks/apiTypes";
import { useGetNonLegacyIndicators } from "reactQuery/hooks/useIndicators";

const IndicatorSelect = ({}) => {
    const indicatorsQuery = useGetNonLegacyIndicators();
    return (
        <Form.Item name="indicator" label="Indicator">
            <Select
                placeholder="Select an indicator"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const indicator = option.title.toLowerCase();
                    return indicator.includes(input.toLowerCase());
                }}
            >
                {indicatorsQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : indicatorsQuery.isSuccess ? (
                    indicatorsQuery.data.map((indicator: Indicator) => {
                        const title = indicator.category
                            ? `${indicator.category} - ${indicator.value}`
                            : indicator.value;

                        return (
                            <Select.Option
                                key={indicator.id}
                                value={indicator.id}
                                title={title}
                            >
                                {title}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading indicator options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default IndicatorSelect;
