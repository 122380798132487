import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createSelector } from "reselect";

import { nautilusGetRequest, nautilusPatchRequest, nautilusPostRequest } from "network";
import { selectIdFromUrl, teamIdSelector } from "stores/shared/browserSelectors";
import {
    closeAllOverlays,
    displayErrorSnack,
    displaySuccessSnack,
} from "stores/uiStore/actionTypes";

export const reportsAdapter = createEntityAdapter({});

export const reportsReducerName = "reports";
export const initialState = reportsAdapter.getInitialState({
    error: null,
    isLoading: false,
    activeReport: null,
    activeTeam: null,
    newReport: {
        error: null,
        isCreating: false,
        relevantTeam: null,
    },
    errorIds: {},
    loadingIds: {}, //Key: Team ID, Value: Object with error and isLoading boolean properties
});

// Thunks

export const createReport = createAsyncThunk(
    `${reportsReducerName}/createReport`,
    async ({ teamId, statusMessage, date }, { dispatch, rejectWithValue }) => {
        const requestBody = {
            team: teamId,
            date: date,
            status: statusMessage,
        };
        try {
            const response = await nautilusPostRequest(
                "/api/target_reports/reports/",
                requestBody
            );
            dispatch(push(`/reports/${response.data.id}`));
            dispatch(closeAllOverlays());
            return response.data;
        } catch (e) {
            dispatch(displayErrorSnack({ message: "Failed to create report" }));
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchReports = createAsyncThunk(
    `${reportsReducerName}/fetchReports`,
    async ({ reportId }, { dispatch, rejectWithValue }) => {
        if (reportId) {
            const queryString = reportId;
            try {
                const response = await nautilusGetRequest(
                    "/api/target_reports/reports",
                    queryString
                );
                const results = [response.data];
                return results;
            } catch (e) {
                const browserHistory = createBrowserHistory({ forceRefresh: true });
                browserHistory.push("/");
            }
        }
    }
);

export const patchReport = createAsyncThunk(
    `${reportsReducerName}/patchReport`,
    async ({ reportId, date }, { dispatch, rejectWithValue }) => {
        const requestBody = {
            date,
        };
        try {
            const response = await nautilusPatchRequest(
                `/api/target_reports/reports/${reportId}`,
                requestBody
            );
            dispatch(
                displaySuccessSnack({ message: "Report was successfully updated" })
            );
            dispatch(closeAllOverlays());
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to patch report",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const fetchReportsForTeam = createAsyncThunk(
    `${reportsReducerName}/fetchReportsForTeam`,
    async ({ teamId }, { dispatch, rejectWithValue }) => {
        try {
            const response = await nautilusGetRequest(
                "/api/target_reports/reports/",
                `?team=${teamId}`
            );
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: `Failed to fetch reports for team ${teamId}`,
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

export const deleteReport = createAsyncThunk(
    `${reportsReducerName}/deleteReport`,
    async ({ reportId }, { dispatch, rejectWithValue }) => {
        const requestBody = {
            is_deleted: true,
        };
        try {
            const response = await nautilusPatchRequest(
                `/api/target_reports/reports/${reportId}`,
                requestBody
            );
            const browserHistory = createBrowserHistory({ forceRefresh: true });
            browserHistory.push("/");
            dispatch(
                displaySuccessSnack({ message: "Report was successfully updated" })
            );
            dispatch(closeAllOverlays());
            return response.data.results;
        } catch (e) {
            dispatch(
                displayErrorSnack({
                    message: "Failed to patch report",
                })
            );
            return rejectWithValue({
                status: e.response && e.response.status,
                message: e.response && e.response.data,
            });
        }
    }
);

// Slice

const reportSlice = createSlice({
    name: reportsReducerName,
    initialState,
    reducers: {
        setActiveReport: (state, { payload }) => {
            state.activeReport = payload.reportId;
            localStorage.setItem("activeReport", payload.reportId);
        },
        setActiveTeam: (state, { payload }) => {
            state.activeTeam = payload.teamId;
        },
        setRelevantTeam: (state, { payload }) => {
            state.newReport.relevantTeam = payload.team;
        },
    },
    extraReducers: {
        [createReport.pending]: (state) => {
            state.newReport.error = null;
            state.newReport.isCreating = true;
        },
        [createReport.fulfilled]: (state, { payload }) => {
            state.newReport.error = null;
            state.newReport.isCreating = false;
            state.activeReport = payload.id;
            // Close the modal
            state.newReport.opportunityId = null;
        },
        [createReport.rejected]: (state, { payload }) => {
            state.newReport.error = payload;
            state.newReport.isCreating = false;
        },
        [fetchReports.pending]: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        [fetchReports.fulfilled]: (state, action) => {
            reportsAdapter.upsertMany(state, action.payload);
            state.error = null;
            state.isLoading = false;
        },
        [fetchReports.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        [fetchReportsForTeam.pending]: (state, action) => {
            state.errorIds[action.meta.arg.teamId] = null;
            state.loadingIds[action.meta.arg.teamId] = true;
        },
        [fetchReportsForTeam.fulfilled]: (state, action) => {
            reportsAdapter.upsertMany(state, action.payload);
            state.errorIds[action.meta.arg.teamId] = null;
            state.loadingIds[action.meta.arg.teamId] = false;
        },
        [fetchReportsForTeam.rejected]: (state, action) => {
            state.errorIds[action.meta.arg.teamId] = action.payload;
            state.loadingIds[action.meta.arg.teamId] = false;
        },
        [patchReport.pending]: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        [patchReport.fulfilled]: (state, action) => {
            state.error = null;
            state.isLoading = false;
        },
        [patchReport.rejected]: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

const { actions, reducer } = reportSlice;

export default reducer;

export const { setActiveReport, setActiveTeam, setRelevantTeam } = actions;

// Selectors

export const reportStoreSelector = (state) => state[reportsReducerName];

export const reportsSelectors = reportsAdapter.getSelectors(reportStoreSelector);

export const isLoadingReportsSelector = createSelector(
    reportStoreSelector,
    (reportStore) => reportStore.isLoading
);

export const activeReportIdSelector = createSelector(
    [reportStoreSelector, selectIdFromUrl],
    (reportStore, reportId) => {
        return reportStore.activeReport ? reportStore.activeReport : reportId;
    }
);

export const activeTeamIdSelector = createSelector(
    [reportStoreSelector],
    (reportStore) => reportStore.activeTeam
);

export const relevantTeamSelector = createSelector(
    [reportStoreSelector],
    (reportStore) => reportStore.newReport.relevantTeam
);

export const newReportSelector = createSelector(
    [reportStoreSelector],
    (reportStore) => reportStore.newReport
);

export const isCreatingReportSelector = createSelector(
    [newReportSelector],
    (newReport) => newReport.isCreating
);

export const specificReportFromMatchPropsSelector = createSelector(
    [reportsSelectors.selectAll, selectIdFromUrl],
    (allReports, reportId) => {
        return allReports
            ? allReports.find((report) => {
                  return report.id === reportId;
              })
            : null;
    }
);

export const activeReportSelector = createSelector(
    [reportsSelectors.selectAll, activeReportIdSelector],
    (allReports, reportId) =>
        allReports ? allReports.find((report) => report.id === reportId) : null
);

export const reportsForActiveTeamSelector = createSelector(
    [reportsSelectors.selectAll, activeTeamIdSelector],
    (allReports, teamId) => {
        return allReports.filter((report) => report.team && report.team.id === teamId);
    }
);

export const isLoadingReportsForTeamSelector = createSelector(
    [reportStoreSelector, teamIdSelector],
    (reportStore, teamId) => {
        return reportStore.loadingIds[teamId];
    }
);

export const reportsForTeamSelector = createSelector(
    [reportsSelectors.selectAll, teamIdSelector],
    (allReports, teamId) => {
        return allReports.filter((report) => report.team && report.team.id === teamId);
    }
);
