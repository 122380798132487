import { createSelector } from "reselect";

import { uiReducerName } from "./reducer";

const uiStoreSelector = (state) => state[uiReducerName];

export const snacksSelector = createSelector(
    uiStoreSelector,
    (uiStore) => uiStore.snacks
);

export const isEditModeActiveSelector = createSelector([uiStoreSelector], (uiStore) => {
    return uiStore.isEditModeActive;
});

export const isAllowedToChangeOpportunitySelector = createSelector(
    [uiStoreSelector],
    (uiStore) => {
        return uiStore.isAllowedToChangeOpportunity;
    }
);

export const isShowingSelector = createSelector(
    [uiStoreSelector],
    (uiStore) => uiStore.isShowing
);
