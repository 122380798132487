import { Breadcrumb } from "antd";

import ConditionalLink from "components/atoms/ConditionalLink";

export interface BreadcrumbProps {
    name: string;
    url?: string;
}

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbProps[] }) => {
    return (
        <Breadcrumb data-testid="breadcrumb">
            {breadcrumbs.map((breadcrumb) => (
                <Breadcrumb.Item key={breadcrumb.name + ":" + breadcrumb.url}>
                    <ConditionalLink to={breadcrumb.url}>
                        {breadcrumb.name}
                    </ConditionalLink>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
